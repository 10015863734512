/* eslint-disable @typescript-eslint/no-explicit-any */
export const processSiteMapListLinks = (data: any, prefix = '', grid: number): any => {
  const finalSitemapArr = [];
  const linkGridCount = grid;
  const linksPerGrid = Math.ceil(data.length / linkGridCount);

  const linkItems = data.reduce((resultArray: any, item: any, index: number) => {
    const arrayIndex = Math.floor(index / linksPerGrid);
    if (!resultArray[arrayIndex]) {
      resultArray[arrayIndex] = [];
    }
    resultArray[arrayIndex].push({
      code: item.code,
      linkLabel: prefix + ' ' + item.title,
      linkURL: item.uri,
      openInNewTab: false,
    });
    return resultArray;
  }, []);
  for (let index = 0; index < linkGridCount; index++) {
    if (linkItems[index] !== undefined) {
      const obj = {
        header: '',
        linkItems: linkItems[index],
      };
      finalSitemapArr.push(obj);
    }
  }
  return finalSitemapArr;
};

export const processSiteMapGrpLinks = (props: any) => {
  const { groupingMap } = props;
  return groupingMap?.map((obj: any) => ({
    brandTierCode: obj.brandTierCode,
    brandTierTitle: obj.brandTierTitle,
    model: {
      className: 'aem-GridColumn aem-GridColumn--default--4',
      hideColumDividers: false,
      noOfColumns: 4,
      columnDetails: obj?.brands?.map((item: any) => ({
        header: item?.brandTitle,
        linkItems: item?.properties?.map((prop: any) => ({
          code: prop?.marsha,
          linkLabel: prop?.title,
          linkURL: prop?.url,
          openInNewTab: true,
        })),
      })),
      trackingProperties: props?.trackingProperties,
    },
  }));
};

export const processBreadcrumbLinks = (links: any) => {
  return links?.map((obj: any) => ({ linkLabel: obj.title, linkURL: obj.url, openInNewTab: false }));
};
