import moment, { Moment } from 'moment';
import {
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_VALUE,
  IS_LOCALE_THAI,
  BUDDHIST_YEAR_VALUE,
} from '../constants/ApplicationConstants';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';
import { logger } from '@marriott/mi-headless-utils';

export type DateObjectType = Moment;

export const getCurrentDateObject = (): DateObjectType => {
  return moment();
};

export const getDateFormatted = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? DATE_FORMAT_DEFAULT) ?? '';
};

export const getValueFromDate = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? DATE_FORMAT_VALUE) ?? '';
};

export const getNextDateObject = (date: DateObjectType): DateObjectType => {
  return moment(date).add(1, 'days');
};

export const getPrevDateObject = (date: DateObjectType): DateObjectType => {
  return moment(date).add(-1, 'days');
};

export const getDateObject = (date: string, format = moment.defaultFormat): DateObjectType => {
  return moment(date, format).utc();
};

export const getDateObjectCurrent = (date: string, format = moment.defaultFormat): DateObjectType => {
  return moment(date, format).utc(true);
};

// to get the dates based on the localization
export const getLocalizedDate = (date: DateObjectType | undefined, dateFormat: string, locale?: string): string => {
  moment.locale('locale');
  // CNWEB-2933 Add a check to load the zh-cn locale to get correct localized date
  if (isCNLocale(locale)) {
    try {
      require(`moment/locale/zh-cn`);
      moment.locale('zh-cn');
    } catch (e) {
      const { log } = logger({})('DateUtils');
      log.warn('Failed to load locale - zh-cn');
    }
  }
  if (date) {
    if (locale?.replace('_', '-') === 'ja-JP') {
      moment.locale(locale?.slice(0, 2));
    } else {
      moment.locale(locale);
    }
    //initially date will be in the english format we need to change that to the localized value
    const formattedDate = moment(date).format();
    return moment(formattedDate).format(dateFormat ?? DATE_FORMAT_DEFAULT);
  }
  return '';
};

export const getNumberOfDays = (startDate: DateObjectType | undefined, endData: DateObjectType | undefined): number => {
  moment.locale('locale');
  if (startDate && endData) {
    return Math.abs(moment.duration(startDate.diff(endData)).asDays() ?? 1);
  }
  return 1;
};

export const checkLocaleToAddYears = (currentLocale: string, year: number): number => {
  switch (currentLocale) {
    case IS_LOCALE_THAI:
      return Number(year + BUDDHIST_YEAR_VALUE);
    default:
      return year;
  }
};

export const modifyDateStringForThai = (dateString: string): string => {
  let modifiedDateString = '';
  let i = 0;

  while (i < dateString.length) {
    // Check if the current character is a numeric digit
    if (dateString[i] >= '0' && dateString[i] <= '9') {
      // Found the start of a sequence of numeric digits
      const start = i;
      const end = i + 3;

      // Ensure we have at least 4 characters left in the string
      if (end < dateString.length && dateString[end] >= '0' && dateString[end] <= '9') {
        // Extract the 4 consecutive numeric digits
        const numericDigits = dateString.slice(start, end + 1);

        // Convert the numeric string to a number, add the given value
        const newValue = parseInt(numericDigits) + BUDDHIST_YEAR_VALUE;

        // Append the modified numeric value to the result string
        modifiedDateString += newValue.toString();

        // Move the index past the 4 consecutive numeric digits
        i = end + 1;
      } else {
        // If not enough characters left, just append the current character
        modifiedDateString += dateString[i];
        i++;
      }
    } else {
      // Append non-numeric characters directly to the result string
      modifiedDateString += dateString[i];
      i++;
    }
  }

  return modifiedDateString;
};
