/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useState, useEffect } from 'react';
import { decodeHtml, getLocalizedDate, processAcceptLang, validateDetailsField } from '../../../utils/OfferUtils';
//import { MiModal } from '../MiModal';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { OfferDetailsMultiPropertyProps } from './OfferDetailsMultiProperty.types';
import { StyledOfferDetailsMultiProperty } from './OfferDetailsMultiProperty.styles';
import { DATE_FORMAT_YEAR_MONTH_DAY } from '../../../constants/OfferDetailsConstants';
import { Modal, Text, Types, Heading, Link } from '@marriott/mi-ui-library';
import moment from 'moment';
import { LocaleUtils } from 'react-day-picker';
import { makeDomainSpecificContent } from '../../../utils/OfferUtils';
import {
  INTERNAL_CTA_TYPE,
  MONTHSHORTVALUE,
  WEEKDAYSLONGNAME,
  WEEKSDAYSSHORT,
} from '../../../constants/CommonConstants';
// import {
//   MONTHSHORTVALUE,
//   WEEKDAYSLONGNAME,
//   WEEKSDAYSSHORT,
// } from 'libs/mi-offers-components/src/constants/CommonConstants';
// Use named rather than default exports.
export const OfferDetailsMultiProperty: FC<OfferDetailsMultiPropertyProps> = ({ offerDetailsProp }) => {
  const isSBO = offerDetailsProp?.model?.variation === 'single-brand-multi-property-offer';
  const OfferObj = offerDetailsProp?.offersData?.responseObject?.edges[0].node;
  const model = offerDetailsProp?.model;
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const [description, setDescription] = useState('');
  const modalProps = {
    className: 'm-modal d-flex justify-content-center modal-body-offers',
    secondaryClassName: 'terms-modal-content',
  };

  const acceptLanguage = processAcceptLang(offerDetailsProp?.acceptLanguage ?? '');
  const toggleModal = (event: any) => {
    const body = document.querySelector('body');
    if (body) {
      if (!popupOpenState) {
        event.preventDefault();
        body.style.overflow = 'hidden';
        body.style.paddingRight = `15px`;
      } else {
        body.style.overflow = '';
        body.style.paddingRight = '';
      }
    }
    setPopupOpenState(!popupOpenState);
  };
  //localized dates implementation

  let monthsValue = model?.months?.split(',');
  // set default month if i18 months value are invalid
  if (monthsValue?.length !== 12) {
    monthsValue = LocaleUtils.getMonths();
  }

  let monthsShortValue = model?.monthsShort?.split(',');
  // set default month if i18 months value are invalid
  if (monthsShortValue?.length !== 12) {
    monthsShortValue = MONTHSHORTVALUE;
  }

  let weeksdaysShort = model?.weekdaysShort?.split(',');
  // set default weekdays if i18 weekdays value are invalid
  if (weeksdaysShort?.length !== 7) {
    weeksdaysShort = WEEKSDAYSSHORT;
  }

  let weekdaysLongName = model?.weekdaysLong?.split(',');
  if (weekdaysLongName?.length !== 7) {
    weekdaysLongName = WEEKDAYSLONGNAME;
  }

  const setLanguageLocalization = (): void => {
    moment.locale('locale', {
      months: monthsValue,
      monthsShort: monthsShortValue,
      weekdays: weekdaysLongName,
      weekdaysShort: weeksdaysShort,
    });
  };

  useEffect(() => {
    setLanguageLocalization();
  }, [monthsValue, monthsShortValue, weekdaysLongName, weeksdaysShort]);

  useEffect(() => {
    setDescription(decodeHtml(OfferObj?.description));
  }, []);

  setLanguageLocalization();

  return (
    <StyledOfferDetailsMultiProperty className="brand-css">
      <div
        className={`mpo-detail-section ${offerDetailsProp?.model?.appliedCssClassNames ?? ''} ${
          isSBO && 'color-scheme3'
        }`}
      >
        <div className="container">
          <div className="details-left">
            <div className="title">
              <div>
                {OfferObj?.segment !== null ? (
                  <Heading
                    titleText={model?.exclusiveOffer ?? 'Enjoy your Exclusive Offer'}
                    variation={Types.headingType.title}
                    fontSize={Types.size.medium}
                    element={Types.tags.h2}
                  />
                ) : (
                  <>
                    <Heading
                      titleText={`${model?.bookBy ?? 'Book By '}${' '}${
                        OfferObj?.bookingEndDate
                          ? acceptLanguage !== 'ja-JP'
                            ? moment(OfferObj?.bookingEndDate, DATE_FORMAT_YEAR_MONTH_DAY).format('dddd').trim()
                            : ''
                          : ''
                      }${acceptLanguage === 'it-IT' || acceptLanguage === 'ja-JP' ? '' : ','}`}
                      variation={Types.headingType.title}
                      fontSize={Types.size.medium}
                      element={Types.tags.h3}
                      customClass={'mpo-details-title'}
                    />
                    <Heading
                      titleText={`${
                        OfferObj?.bookingEndDate &&
                        getLocalizedDate(
                          OfferObj.bookingEndDate,
                          acceptLanguage,
                          model?.year ?? 'year',
                          model?.of ?? 'of'
                        )
                      }${
                        acceptLanguage === 'ja-JP'
                          ? ', ' + moment(OfferObj?.bookingEndDate, DATE_FORMAT_YEAR_MONTH_DAY).format('dddd').trim()
                          : ''
                      }`}
                      variation={Types.headingType.title}
                      fontSize={Types.size.medium}
                      element={Types.tags.h3}
                      customClass={'mpo-details-title'}
                    />
                  </>
                )}
              </div>
            </div>

            <div className={`desc m-details-offer-description`}>
              <div dangerouslySetInnerHTML={{ __html: description ?? '' }}></div>
            </div>
            <div className={`m-details-page-reserv-msg`}>
              <Heading
                titleText={model?.howToBook}
                variation={Types.headingType.title}
                fontSize={Types.size.medium}
                element={Types.tags.h3}
                customClass={'t-subtitle-l'}
              />
              <p
                dangerouslySetInnerHTML={{ __html: makeDomainSpecificContent(OfferObj?.reservationMessage) ?? '' }}
              ></p>
            </div>
            {validateDetailsField(OfferObj?.termsAndConditions ?? OfferObj?.additionalTerms) && (
              <>
                <div className="terms-link">
                  <Link
                    callback={event => toggleModal(event)}
                    text={model?.termsAndConditions ?? ''}
                    linkClassName={'m-details-page-TnC m-link-tertiary-button-external custom_click_track'}
                    linkHref={'#'}
                    linkType="internal"
                    target="_self"
                    custom_click_track_value={`${model?.id}|${model?.termsAndConditions}|${INTERNAL_CTA_TYPE}`}
                    trackingProperties={model?.trackingProperties}
                  ></Link>
                </div>
                {popupOpenState && (
                  <Modal {...modalProps} show={true}>
                    <Modal.Header
                      labelText={model?.termsAndConditions ?? 'Terms & Conditions'}
                      popupHeaderOnCLoseFunc={event => toggleModal(event)}
                      className="terms-modal-header"
                    />
                    <Modal.Body>
                      <div className="offers-terms-details">
                        <Text
                          copyText={`${decodeHtml(OfferObj?.additionalTerms)} ${decodeHtml(
                            OfferObj.termsAndConditions
                          )}`}
                          fontSize={Types.size.medium}
                          customClass={'offers-additional-terms'}
                          element={Types.tags.paragraph}
                        />
                      </div>
                    </Modal.Body>
                  </Modal>
                )}
              </>
            )}
          </div>
          <div className="details-right">
            <div className="title">
              <Text
                copyText={`${model?.needToKnow}`}
                fontSize={Types.size.small}
                customClass={'need-to-know-header'}
                element={Types.tags.paragraph}
              />
            </div>
            <ul role="list">
              {OfferObj?.needToKnow?.map((item: any) => (
                <li role="listitem">{item}</li>
              ))}
              {OfferObj?.clusterCode && !(OfferObj?.clusterCode === '0' || OfferObj.segment) && (
                <li role="listitem" className="promo-code-list">
                  {model?.promotionalCode ?? 'Promotional Code'} : {OfferObj?.clusterCode}
                  <a href="javascript:void(0)" className="m-whats">
                    {model?.whatsThis ?? ''}? <span className="icon-alert"></span>
                  </a>
                  <Text
                    copyText={model?.promoCodeInfo ?? ''}
                    fontSize={Types.size.small}
                    customClass={'m-tool-tip-hint t-font-s t-box-shadow t-font-weight-normal'}
                    element={Types.tags.span}
                  />
                </li>
              )}
              <li role="listitem">
                {model?.validStayDates} :{' '}
                {OfferObj.stayStartDate &&
                  getLocalizedDate(
                    OfferObj.stayStartDate,
                    acceptLanguage,
                    model?.year ?? 'year',
                    model?.of ?? 'of'
                  )}{' '}
                -{' '}
                {OfferObj.stayEndDate &&
                  getLocalizedDate(OfferObj.stayEndDate, acceptLanguage, model?.year ?? 'year', model?.of ?? 'of')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </StyledOfferDetailsMultiProperty>
  );
};
