/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BrandFilter } from '@marriott/mi-shop-components';
// import { BrandFilter } from 'libs/mi-rnb-components/src/organisms/SearchResults/BrandFilter';
import { StyledSearchAllFilters } from './PropertiesListFilter.styles';
import clsx from 'clsx';
import { Button, Modal } from '@marriott/mi-ui-library';
import { useEffect, useState } from 'react';
import {
  BrandProps,
  availableBrandsType,
  brandCategoriesDataType,
  brandCategoryTitleMappingType,
  brandFilterProps,
} from './PropertiesListFilter.types';
import { INTERNAL_CTA_TYPE } from 'libs/mi-offers-components/src/constants/CommonConstants';

export const PropertiesListFilter: React.FC<any> = props => {
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const [selectedBrands, setSelectedBrands] = useState<BrandProps[]>([]);
  const [isCategoryFilterModal, setIsCategoryFilterModal] = useState<boolean>(false);
  const isFilterSelected = props?.brandFacets;
  const model = props.model;
  const brandCategoriesData: brandCategoriesDataType = [];
  useEffect(() => {
    setSelectedBrands(props.selectedBrands);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedBrands.length]);

  const getBrandCategorySelectAllLabel = (category: string): string => {
    switch (category) {
      case 'luxuryBrands':
        return 'storefrontPropertySelectAllLuxuryBrands';

      case 'premiumBrands':
        return 'storefrontPropertySelectAllPremiumBrands';

      case 'selectBrands':
        return 'storefrontPropertySelectAllSelectBrands';

      case 'longerStayBrands':
        return 'storefrontPropertySelectAllLongerStaysBrands';

      default:
        return '';
    }
  };
  const brandCategoryTitleMapping: brandCategoryTitleMappingType = {
    luxuryBrands: model?.storefrontPropertyBrandFilterLuxury ?? 'Luxury',
    premiumBrands: model?.storefrontPropertyBrandFilterPremium ?? 'Premium',
    selectBrands: model?.storefrontPropertyBrandFilterSelect ?? 'Select',
    longerStayBrands: model?.storefrontPropertyBrandFilterLongerStay ?? 'Longer Stay',
  };

  const getBrandCategoriesData = (brandCategory: string) => {
    const brandsList = model?.brands[brandCategory].map((brand: { code: string; title: string }) => {
      const isDisabled = props.availableBrands?.some((item: availableBrandsType) => {
        return item.code === brand.code;
      });
      return {
        code: brand?.code,
        brandTagTitle: brand?.title,
        description: '',
        brandTagId: brand?.code,
        isDisabled: !isDisabled,
      };
    });
    const key = brandCategory as keyof typeof brandCategoryTitleMapping; // to allow indexing on object
    const category = {
      categoryTitle: `${brandCategoryTitleMapping[key]} ${model?.storefrontPropertyBrandFilter ?? 'Brands'}`,
      categorySelectLabel: model[getBrandCategorySelectAllLabel(key)],
      categoryClearLabel: `${model?.storefrontPropertyFilterClear ?? 'Clear All'} ${brandCategoryTitleMapping[key]} ${
        model?.storefrontPropertyBrandFilter ?? 'Brands'
      }`,
      brands: brandsList,
    };
    brandCategoriesData.push(category);
  };
  getBrandCategoriesData('luxuryBrands');
  getBrandCategoriesData('premiumBrands');
  getBrandCategoriesData('selectBrands');
  getBrandCategoriesData('longerStayBrands');

  const handleBrandFilter = (selectedBrandAr: any) => {
    setSelectedBrands(selectedBrandAr);
  };
  const handleClearBtn = () => {
    setSelectedBrands([]);
    props.handleClearAllFilters();
  };
  //props received by BrandFilter
  const BrandFilterProps: brandFilterProps = {
    title: `${model?.storefrontPropertyBrandFilter ?? 'Brands'}`,
    brandCategories: brandCategoriesData,
    showSeparator: false,
    // defaultSelected includes all those brands which are selected and highlited for filtering the properties.
    defaultSelected: selectedBrands,
    onChange: handleBrandFilter,
  };

  return (
    <StyledSearchAllFilters className="offer-filters">
      <div className="filter-btns-wrapper">
        <Button
          callback={(): void => {
            setPopupOpenState(!popupOpenState);
          }}
          className={clsx(
            `button custom_click_track button--medium button-secondary m-button-primary-inverse t-label-alt-m filter-option-button ${
              isFilterSelected?.length && 'active'
            }`
          )}
          custom_click_track_value={`${model?.id}|${model?.storefrontPropertyBrandFilter}-${model?.storefrontPropertyFilters}|${INTERNAL_CTA_TYPE}`}
          trackingProperties={model?.trackingProperties}
        >
          <span className={`icon-s ${isFilterSelected.length ? ' all-filter-badge' : 'icon-dining-filter'}`}>
            {' '}
            {`${isFilterSelected?.length ? isFilterSelected.length : ''}`}
          </span>
          {model?.allFilters ?? 'All Filters'}
        </Button>
        {/* removing the brands filter pill as we have only one filter criteria */}
        {/* <Button
          callback={(): void => {
            setPopupOpenState(!popupOpenState);
            setIsCategoryFilterModal(true);
          }}
          className={clsx(
            `${isFilterSelected?.length && 'active'} button m-button-primary-inverse t-label-alt-m filter-option-button`
          )}
          custom_click_track_value={``}
        >
          <span>{`${model?.storefrontPropertyBrandFilter ?? 'Brands'}`}</span>
        </Button> */}
      </div>
      {popupOpenState && (
        <Modal
          className={`custom-scrollbar modal-main-container ${model?.variation === 'sbo' && 'sbo-filters'} ${
            popupOpenState && 'close-popup-header'
          } ${isCategoryFilterModal && 'category-filter-modal'}`}
          show={true}
          handleBlur={true}
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
          allFilterPopUp={true}
          onClose={(): void => {
            setPopupOpenState(false);
          }}
        >
          <Modal.Header
            className="modal-header t-subtitle-xl"
            labelText={
              isCategoryFilterModal
                ? model?.storefrontPropertyBrandFilter ?? 'Brands'
                : model?.storefrontPropertyFilters ?? 'Filters'
            }
            popupHeaderOnCLoseFunc={(): void => {
              setIsCategoryFilterModal(false);
              setPopupOpenState(false);
            }}
            closeIconClickTrackValue={`${model?.id}|${model?.close ?? 'CloseModal'}|${INTERNAL_CTA_TYPE}`}
          />
          <Modal.Body className="all-filters custom-scrollbar">
            <div>
              <BrandFilter {...BrandFilterProps} />
            </div>
          </Modal.Body>
          <Modal.Footer
            clearClickHandler={handleClearBtn}
            setPopUpOpenState={setPopupOpenState}
            applyBtnClickHandler={() => {
              props.handleApplyFilter(selectedBrands);
            }}
            clearButtonLabel={model?.clearFilters ?? 'Clear'}
            applyLabel={model?.storefrontPropertyFilterApply}
            className="modal-footer"
            clickTrackingLoc={'SEARCH_FILTER_MODAL'}
            applyBtnClickTrackValue={`${model?.id}|${model?.storefrontPropertyFilterApply}|${INTERNAL_CTA_TYPE}`}
            clearBtnClickTrackValue={`${model?.id}|${model?.clearFilters}|${INTERNAL_CTA_TYPE}`}
          />
        </Modal>
      )}
    </StyledSearchAllFilters>
  );
};
