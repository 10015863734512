export default {
  SM_ARIES_DOMAIN:
    process.env['NODE_ENV'] === 'development'
      ? 'https://ram-dev23.ose-dev39-vxbyr.cloud.marriott.com'
      : process.env['NGINX_URL'],
  SM_HYBRID_URL:
    process.env['NODE_ENV'] === 'development'
      ? 'https://ram-dev23.ose-dev39-vxbyr.cloud.marriott.com'
      : process.env['HYBRID_APP_URL'],
  NODE_ENV: process.env['NODE_ENV'],

  SM_REVIEW_URL: '/hotels/hotel-reviews/',

  GOOGLE_MAP_API_KEY: process.env['GOOGLE_MAP_API_KEY'] ? process.env['GOOGLE_MAP_API_KEY'] : '',
  SHOP_RESULT_NO_OFFSET: process.env['SHOP_RESULT_NO_OFFSET'] ? process.env['SHOP_RESULT_NO_OFFSET'] : '40',
  SM_COMPONENT_RESOLVER_ENDPOINT:
    process.env['NODE_ENV'] === 'development'
      ? 'http://localhost:9090'
      : process.env['UXL_RESOLVER_ENDPOINT'] ??
        'https://phoenix-searchtest1.ose-dev39-red.aws-use1.cloud.marriott.com/mi-presentation-shop',
  SM_UXL_CALL_ENDPOINT:
    process.env['SM_UXL_CALL_ENDPOINT'] ??
    'https://upl-gateway-green-uat-experiencelayer-uat2.ose-perf39-vxbyr.cloud.marriott.com',
  DEFAULT_API_ACCEPT_LANGUAGE: process.env['DEFAULT_API_ACCEPT_LANGUAGE'] ?? 'en-US',
  CLIENT_AUTHORIZATION_HEADER:
    process.env['CLIENT_AUTHORIZATION_HEADER'] ?? 'Basic cmFtOjJjR3FtdGhIWWg2eEZ6NXJSc3lBcmxvUWZ6SXJDd05zeWpHSUFOZg==',
  SHOP_UXL_CLIENT_NAME: process.env['SHOP_UXL_CLIENT_NAME'] ?? 'phoenix_shop',
  SHOP_LAR_SEARCH_GEO_LOCATIONS_SIGNATURE: '7daef5a6e1c08f425849ee4fedd9ac73112455a5a74c69c4ee6f1becbd793e73',
  SHOP_SUGGESTED_PLACE_OPERATION_SIGNATURE: '7bbc510240e7007c1082885619de4c1058ab9ad1f719f256a0582c1f3af0e4f4',
  SHOP_CURRENT_CURRENCY_CONVERT_OPERATION_SIGNATURE: '3e2d1199bbf669f93d51468caa1b7b6a7435b339c76ab765411d13bb1b991589',
  SHOP_LAR_SEARCH_KEYWORD_SIGNATURE: 'cc3cbd171749c10d00301eedf454fbf90a99003e78abf16676b3486678d551c9',
  SHOP_LAR_SEARCH_DESTINATION_SIGNATURE: '9529b8b088816eac065866076d55f5a148f9db7dec45b68763ff1092d99bdbd1',
  SHOP_SAVE_HOTEL_BUTTON_SIGNATURE: '',
  SHOP_UPDATE_SAVED_PROPERTIES_SIGNATURE: '',
  APOLLOGRAPHQL_CLIENT_VERSION: process.env['APOLLOGRAPHQL_CLIENT_VERSION'] ?? 'v1',
  DEFAULT_MAP_ZOOM_LEVEL: process.env['DEFAULT_MAP_ZOOM_LEVEL'] ?? '10',
  SHOP_GEO_LOCATION_QUERY_SIGNATURE: '',
  SHOP_PROP_AVAIL_DESTINATION: '',
  //this is to specify allowed domains for domain specific features like mandatory fee
  ALLOWED_DOMAINS: ['en-US'],
};

export const NAVIGATION_URL_CONSTANTS = {
  SM_SAVE_HOTEL_URL: '/loyalty/myAccount/saveProperties.mi?',
  SM_VIEW_RATES_URL: '?propertyCode=',
  SM_VIEW_RATES_ADF_URL: '?isRateCalendar=true&propertyCode=',
  SM_HOTEL_QUICK_VIEW_URL: '/search/hotelQuickView.mi',
  SM_AVAILABILITY_URL: '?isRateCalendar=true&propertyCode=',
};
