/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledSpecialRatesDiv = styled.div`
  ${rtl`
  padding-bottom: 0 !important;
  .m-dropdown-children {
    cursor: default;
    overflow:unset;
    overflow-y: unset;
}
  .desktop-font-text-class{
     font-size: ${toRem(18)} !important;
  }
  .icon-arrow-down:before{
    height:${toRem(16)}
  }
  .icon-arrow-up:before{
    height:${toRem(16)}
  }
  .m-dropdown-action-bar {
    padding: ${toRem(24)} !important;
    position: fixed !important;
    bottom: 0;
    background: ${baseVariables.color['base20']} !important;
    @media ${baseVariables.mediaQuery.md} {
      padding-right: ${toRem(32)} !important;
      padding-left: ${toRem(32)} !important;
    }
  }
  @media ${baseVariables.mediaQuery.md} {
    .icon-clear {
      padding-right: ${toRem(8)};
    }
  }
  label {
    margin-bottom: 0;
  }
  label: before {
    height: ${toRem(14)};
    width: ${toRem(14)};
  }
  label: after {
    background: none;
  }

.special-rates-text{
    font-weight: 700 !important;
    padding: ${toRem(26)} ${toRem(40)} ${toRem(10)} ${toRem(16)} !important;
    cursor: pointer;
    text-overflow: ellipsis;
}
.m-input-text-field {
  label {
    cursor: pointer;
  }
}
.input-with-border {
  border: ${toRem(1.5)} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.base10} !important;
}
.tabbed-radio-label {
  label {
    padding-left: ${toRem(28)};
  }
}

//mobie first approach
.dropdown-container{
  margin:0 auto;
}

.m-input-text-field-no-border input[type='text'] {
  line-height: ${toRem(20)} !important;
  padding-top: ${toRem(10)} !important;
}
.m-dropdown-children .t-label-xs {
  letter-spacing: ${toRem(1.2)};
}
.m-icon-button-secondary {
  padding: 0;
}
.m-dropdown-children{
  position: relative;
  padding: ${toRem(8)} 0 ${toRem(8.5)}  ${toRem(1)};
}
input[type=radio]:checked~label:before {
  outline:none !important;
}
input[type=radio]:checked~label {
  font-weight:${baseVariables.font.fontWeightMedium};
}
input[type=radio] + label {
  font-weight:${baseVariables.font.fontWeightRegular};
}

.m-dropdown-header{
  position: sticky;
  top: 0;
  background: ${baseVariables.color['base20']};
  z-index: 1;
  border-bottom:unset;
  padding: ${toRem(16)} 0 ${toRem(1)} 0;
  &:after{
    content: "";
    position: absolute;
    left: 50%;
    top: ${toRem(66)};
    transform: translateX(-50%);
    width: 100%;
    border-bottom: 0;
  }
}
.m-dropdown-cont {
  .m-dropdown-header{
    padding: 0;
  }
}

.dropdown-to-modal{
  height:${(props: any) => (props.$isTabbedSearchForm !== true ? `` : '80vh')};
  padding: ${toRem(24)};
  position:fixed;
}

.dropdown-to-modal .m-dropdown-action-bar {
  bottom:env(safe-area-inset-bottom);
}

.tabbed-dropdown-container .dropdown-to-modal-background .dropdown-to-modal .m-dropdown-content {
  height: calc(80vh - ${toRem(60)});
}

@media ${baseVariables.mediaQuery.md} {
  .tabbed-dropdown-container {
    .dropdown-to-modal-background {
      .dropdown-to-modal {
        padding-left: ${toRem(32)};
      }
    }
  }
}

@media ${baseVariables.mediaQuery.desktop} {
  
  .dropdown-container{
    z-index:1;
    position:absolute;
    width:${toRem(348)};
    margin: ${toRem(-20)} auto;
    border-radius: ${toRem(8)};
    & >div:first-child {
      z-index:1001;
      bottom: unset;
      width: unset;
      height: fit-content;

      & >div:first-child {
        height:fit-content;
      }
    }
  }

  .tabbed-dropdown-container {
    margin: ${toRem(4)} auto;
  }
  
  .m-dropdown-children{
    padding: ${toRem(24)} ${toRem(24)} ${toRem(24)} ${toRem(24)};
  }
  .apply-button-desktop{
    margin-top: ${toRem(26)};
    display: flex;
    justify-content: flex-end;
  }
  .apply-btn {
    margin-top: ${toRem(20)};
  }
  .tabbed-promo-code .m-input-text-field-no-border .trailing-element {
    bottom: ${toRem(2)};
  }
  .tabbed-promo-code .m-input-text-field-no-border {
    &:after{
      top: ${toRem(42)};
    }
  }
  .top-spacing-class{
    margin-top: ${toRem(13)};
  }

}
  .availability-corp-code {
    .m-input-text-field-no-border {
      display: flex;
      flex-direction: column;
    }
    .trailing-element {
      bottom: ${toRem(30)};
    }
    .error-message {
      color: ${baseVariables.color.alert40} !important;
      padding-top: ${toRem(8)}
    }
  }
`}
`;
