/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Styledcontainer } from './PromotionBanner.styles';
import { Button, Image, Heading, Text, Types } from '@marriott/mi-ui-library';
import { useGetBreakpoint } from '../../../modules/utils/helpers';
import clsx from 'clsx';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const PromotionBanner = (props: any) => {
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Styledcontainer className={clsx(isMobileViewPort ? 'mt-4 mb-4' : 'mt-5 mb-5')}>
      <div className="image-container" data-testid="promotional-image">
        {props?.fileReferenceImage && (
          <Image
            title={props?.title}
            defaultImageURL={props?.fileReferenceImage}
            alt={props?.imgAltText}
            customClass="promotional-image"
            dynamic={props?.dynamicMedia}
          />
        )}
      </div>
      <div className={clsx('detail-container', isMobileViewPort ? '' : ' mt-3')}>
        <div className="eyebrow-class">
          {props?.eyebrow && <h3 className="t-overline-normal ">{props?.eyebrow}</h3>}
        </div>
        {props?.title && (
          <Heading
            titleText={props?.title}
            variation={Types.headingType.title}
            fontSize={Types.size.small}
            customClass="discover t-title-s"
          />
        )}

        {props?.description && (
          <Text
            copyText={props?.description}
            fontSize={Types.size.small}
            element={Types.tags.span}
            customClass="explore"
          />
        )}

        <div className="button1 mt-4 mb-3">
          {props?.linkText && (
            <Button href={props?.linkUrl} className="m-button-s m-button-secondary" isLink={true}>
              {props?.linkText}
            </Button>
          )}
        </div>
      </div>
    </Styledcontainer>
  );
};

export const PromotionBannerConfig = {
  emptyLabel: 'promotionalbanner',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/promotionalbanner`,
};

export const PromotionBannerEditable = (props: any) => (
  <EditableComponent config={PromotionBannerConfig} {...props}>
    <PromotionBanner {...props} />
  </EditableComponent>
);
