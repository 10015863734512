import { Container } from '@adobe/aem-react-editable-components';
import { NavigationContainer as NavigationContainerREACT } from './NavigationContainer';
import { NavigationContainerProps } from './NavigationContainer.types';
import { AemContainerConfig } from '../../molecules/Navigation';
import clsx from 'clsx';

export const NavigationContainerWrapper = (props: NavigationContainerProps) => {
  const { cqPath = '', isAuthorMode = false } = props;
  const NavigationContainerConfig: AemContainerConfig = {
    emptyLabel: 'Marriott Navigation Container',
    isEmpty: () => false,
    resourceType: `mi-aem-homepage-spa/components/content/global-navigation`,
    cqPath: cqPath,
    isInEditor: !!isAuthorMode,
  };
  return (
    <div data-testid="navigation-container-wrapper">
      <Container
        {...NavigationContainerConfig}
        className={clsx('m-container-fullbleed', { 'py-0': isAuthorMode })}
        childPages={<NavigationContainerREACT {...props} />}
      />
    </div>
  );
};
