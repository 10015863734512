import { FC, useState, useRef, ReactElement, useEffect, Suspense, lazy } from 'react';
import clsx from 'clsx';

import { Heading, Text, Types, baseVariables, Image, Icon, useWindowSize, constants } from '@marriott/mi-ui-library';
import { canUseDOM, convertToCommaSeparatedNumber } from '@marriott/shared/mi-helper-utils';
import { useDCADynamicModel } from '@marriott/mi-headless-utils';
import { MerchandisingHeroBannerAssetProps } from './MerchandisingHeroBannerAsset/MerchandisingHeroBannerAsset.types';
import { MerchandisingHeroBannerSkeletonLoader } from './MerchandisingHeroBannerSkeletonLoader';
import {
  PrimaryButton,
  SecondaryButton,
  LocationButton,
  StyledMerchandisingHeroBannerContainer,
  CoBrandWrapper,
  StyledMerchandisingHeroBannerImage,
} from './MerchandisingHeroBanner.styles';
import { MerchandisingHeroBannerProps } from './MerchandisingHeroBanner.types';
import { useMediaQuery } from '@marriott/shared/mi-helper-utils';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { TrackingPropsInterface } from '../MerchandisingSmallBanner/MerchandisingSmallBanner.types';
import { URL_CONSTANTS } from '@marriott/shared/mi-helper-utils';
import { getEVAR48 } from '@marriott/mi-headless-utils';
import { getSPID } from '@marriott/mi-headless-utils';
import { getACDLObject } from '@marriott/mi-headless-utils';

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>;
    mvpOffers?: {
      specialMessage?: string;
      rpcCode?: string;
      offerCode?: string;
    };
  }
}
const MerchandisingHeroBannerVideo = lazy(() => import('./MerchandisingHeroBannerVideo/MerchandisingHeroBannerVideo'));
const MerchandisingHeroBannerAsset = lazy(() => import('./MerchandisingHeroBannerAsset/MerchandisingHeroBannerAsset'));
const { HeaderTextStyle, ImageAspectRatio, AssetVariation, Target, LinkTargetType, ScrimStyle, HeaderCopyWidth } =
  Types;

export const MerchandisingHeroBanner: FC<MerchandisingHeroBannerProps> = props => {
  const { DEFAULT, EXTRATHIN, THIN, WIDE } = HeaderCopyWidth;
  const { targetData } = useDCADynamicModel(props.mboxParameter);
  const { mboxParameter } = props;
  const {
    callToActionText1,
    destinationUrl1,
    primaryopeninanewtab,
    callToActionText2,
    destinationUrl2,
    secondaryopeninanewtab,
    caption,
    captionUrl,
    locationopeninanewtab,
    hideLocationCtaMobile,
    headerMedium,
    descriptionLong,
    eyebrowText,
    headertextstyle,
    heroSize,
    cobrandmathhero,
    mathLabel1,
    mathLabel2,
    mathLabel3,
    mathValue,
    mathType,
    assetsVariation,
    logo,
    font_icon,
    enableMaskingOnDesktop,
    imageLoading,
    appliedCssClassNames,
    enableTarget,
    trackingProperties,
    opensInNewTab,
    playerId,
    videoaccountId,
    videoIdDesktop,
    videoIdMobile,
    videoEnabledDesktop = Boolean(videoIdDesktop?.trim()),
    videoEnabledMobile = Boolean(videoIdMobile?.trim()),
    enableAutoPlay,
    enableVideoLoop,
    enableMute,
    fileReferenceVideoCoverImage,
    playerDomain = URL_CONSTANTS.BRIGHTCOVE_PLAYER_DOMAIN,
    aspectRatioMobileVideo,
    fileReferenceImageVertical,
    verticalImageAltText,
    fontIconAltText,
    image,
    componentId,
    copyBlockWidth = DEFAULT,
    isTargetContentLoaded = mboxParameter && !AuthoringUtils.isInEditor() ? false : true,
    isTargetContentError = false,
  } = props.mboxParameter ? targetData : props;
  const { width } = useWindowSize();
  const MerchandisingHeroBannerRef = useRef(null);
  const { NO_OPENER, NO_REFERER, TARGET_BLANK, COBRAND_MATH_HERO_CTA_CLASS } = constants;

  const { trackingTag1, trackingTag2 } = trackingProperties ?? {};
  const { assetPath, altText, dynamic, renditions } = image ?? {};

  const [hasMobileVideoError, setHasMobileVideoError] = useState(false);
  const [hasDesktopVideoError, setHasDesktopVideoError] = useState(false);

  const showVideoOnDesktopOnly =
    videoEnabledDesktop && videoEnabledMobile && !hasDesktopVideoError && hasMobileVideoError;

  const showVideoOnMobileOnly =
    (videoEnabledDesktop && videoEnabledMobile && hasDesktopVideoError && !hasMobileVideoError) ||
    (!videoEnabledDesktop && videoEnabledMobile && !hasMobileVideoError);

  const showVideoOnDesktopAndMobile =
    (videoEnabledDesktop && !videoEnabledMobile && !hasDesktopVideoError) ||
    (videoEnabledDesktop && videoEnabledMobile && !hasDesktopVideoError && !hasMobileVideoError);

  const { INTERNAL, EXTERNAL } = LinkTargetType;
  const { SIZE_16x9, SIZE_3x1, SIZE_2X1 } = ImageAspectRatio;
  const { WITHOUT_CO_BRAND, H1, H3, H4, H5, H6 } = HeaderTextStyle;
  const { headingType, size, tags } = Types;
  const { LIGHT_SCRIM } = ScrimStyle;
  const { ENABLE_TARGET, DISABLE_TARGET } = Target;
  const { VERTICAL_IMAGE } = AssetVariation;
  const isDesktopViewPort = useMediaQuery(baseVariables.mediaQuery.lg);
  const commonButtonClassname = isDesktopViewPort ? 'm-button-m' : 'm-button-s';
  const isVerticalImage = !!(assetsVariation && assetsVariation === VERTICAL_IMAGE && fileReferenceImageVertical);
  const isVerticalImageFromProps = !!(
    props.assetsVariation &&
    props.assetsVariation === VERTICAL_IMAGE &&
    props.fileReferenceImageVertical
  );
  const isCopyBlockWidthWide = !!(copyBlockWidth === WIDE);
  const isLightScrim = targetData?.metaData?.theme
    ? targetData?.metaData?.theme !== 'Dark'
    : appliedCssClassNames?.includes(LIGHT_SCRIM);
  const shouldEnableDesktopMasking = enableMaskingOnDesktop && (heroSize === SIZE_16x9 || heroSize === SIZE_2X1);
  const eyebrowTextClassName = clsx(
    isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal',
    'mb-1 mb-lg-2'
  );
  const headingTextClassName = clsx(
    'hb__heading',
    isVerticalImage ? 'vertical__image__hero-' + heroSize?.replace(':', 'x') : '',
    isLightScrim ? '' : 't-color-inverse',
    heroSize === SIZE_16x9 || heroSize === SIZE_2X1 ? '' : ' mb-0',
    heroSize === SIZE_16x9 && headertextstyle === WITHOUT_CO_BRAND && !isVerticalImage ? ' hb__heading-cobrand' : '',
    (assetsVariation === AssetVariation.IMAGE && logo?.imageSrc && !cobrandmathhero) ||
      (assetsVariation === AssetVariation.FONT_ICON && font_icon && !cobrandmathhero)
      ? ' hb__heading__assetsVariation '
      : '',
    !isVerticalImage && cobrandmathhero ? ' hb__heading__cobrandMathHero ' : ''
  );
  const subHeadingTextClassName = clsx(
    'hb__subheading',
    isVerticalImage ? 'vertical__image__hero-' + heroSize?.replace(':', 'x') : '',
    isLightScrim ? '' : 't-color-inverse',
    heroSize === SIZE_16x9 || heroSize === SIZE_2X1 ? '' : ' mt-1 mb-0',
    (assetsVariation === AssetVariation.IMAGE && logo?.imageSrc && !cobrandmathhero) ||
      (assetsVariation === AssetVariation.FONT_ICON && font_icon && !cobrandmathhero)
      ? ' hb__subheading__assetsVariation '
      : '',
    !isVerticalImage && cobrandmathhero ? ' hb__subheading__cobrandMathHero ' : ''
  );
  const primaryButtonClassName = clsx(
    commonButtonClassname,
    isLightScrim ? 'm-button-primary' : 'm-button-primary-inverse'
  );
  const secondaryButtonClassName = clsx(
    commonButtonClassname,
    isLightScrim ? 'm-button-secondary' : ' m-button-secondary-inverse',
    'no-impression'
  );

  const copyBlockWidthContainerClass: string =
    copyBlockWidth === DEFAULT || (width && width < 576)
      ? 'col-12 col-xl-5 col-sm-6'
      : clsx('break-word col-12', {
          'col-xl-3 col-sm-4': copyBlockWidth === EXTRATHIN,
          'col-xl-4 col-sm-5': copyBlockWidth === THIN,
          'col-xl-6 col-sm-7': copyBlockWidth === WIDE && !isVerticalImage,
          'col-xl-7 col-lg-8 col-md-7 pr-lg-0 pr-xl-2': copyBlockWidth === WIDE && isVerticalImage,
        });
  const merchandisingHeroBannerAssetProps: MerchandisingHeroBannerAssetProps = {
    assetsVariation,
    fileReferenceImageVertical,
    verticalImageAltText,
    fontIcon: font_icon || '',
    fontIconAltText: fontIconAltText || '',
    heroSize,
    imgAltText: logo?.altText,
    logoImage: logo?.imageSrc,
    cobrandmathhero,
    isCopyBlockWidthWide,
  };

  const isPointsMath = mathType?.toLowerCase() === 'points';

  function createCobrandContent() {
    //TODO: Revalidate the value once Datalayer is implemented for Logged In state.
    const dataLayerObj = getACDLObject() ?? {};
    const balancePoints = parseInt(`${dataLayerObj?.['mr_prof_points_balance'] ?? '0'}`, 10);
    const bonusPoint = mathValue ? parseInt(mathValue.replace(/,/g, ''), 10) : 0;
    const totalPoints: number = balancePoints + bonusPoint;
    const cobrandLabelClassName = clsx(
      isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal',
      'hb__cobrand-label'
    );
    const cobrandBalanceClassName = clsx(
      'hb__cobrand-balance-points',
      isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal'
    );
    const cobrandBonusClassName = clsx(
      'hb__cobrand-bonus-points',
      isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal'
    );
    const cobrandTotalClassName = clsx(
      'hb__cobrand-total-points',
      isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal'
    );

    return (
      isTargetContentLoaded &&
      isPointsMath && (
        <CoBrandWrapper className="row">
          <div className="col-lg-7 hb__cobrand">
            <div className="hb__cobrand-balance">
              <Text
                copyText={mathLabel1 ?? ''}
                customClass={cobrandLabelClassName}
                fontSize={size.small}
                element={tags.div}
              />
              <div>
                <Text
                  copyText={convertToCommaSeparatedNumber(balancePoints)}
                  customClass={cobrandBalanceClassName}
                  fontSize={size.small}
                  element={tags.div}
                />
              </div>
            </div>
            <Icon iconClass="icon-plus"></Icon>
            <div className="hb__cobrand-bonus">
              <Text
                copyText={mathLabel2 ?? ''}
                customClass={cobrandLabelClassName}
                fontSize={size.small}
                element={tags.div}
              />
              <div>
                <Text
                  copyText={convertToCommaSeparatedNumber(bonusPoint) ?? '0'}
                  customClass={cobrandBonusClassName}
                  fontSize={size.small}
                  element={tags.div}
                />
              </div>
            </div>
            <div className="d-sm-flex d-none equal-icon"> = </div>
            <div className="hb__cobrand-total">
              <Text
                copyText={mathLabel3 ?? ''}
                customClass={cobrandLabelClassName}
                fontSize={size.small}
                element={tags.div}
              />
              <div className="hb__cobrand-total__points-wrapper">
                <Text
                  copyText={convertToCommaSeparatedNumber(totalPoints)}
                  customClass={cobrandTotalClassName}
                  fontSize={size.small}
                  element={tags.div}
                />
              </div>
            </div>
          </div>
        </CoBrandWrapper>
      )
    );
  }

  // form custom click track value for hero cta's
  const formCustomClickTrackValue = (label: string, openInNewtab?: boolean): string => {
    return trackingProperties?.clickTrack && !trackingProperties?.impressionTrack
      ? `${headerMedium ? headerMedium : descriptionLong}|${label}|${openInNewtab ? EXTERNAL : INTERNAL}`
      : '';
  };

  // for rendering open in new tab screen reader content for button
  const renderOpenInNewTabSRContent = (openInNewtab?: boolean): ReactElement | null => {
    return openInNewtab ? <span className="sr-only">{opensInNewTab}</span> : null;
  };

  useEffect(() => {
    window.addEventListener('load', () => {
      const parentContainer = document?.querySelector('.herobanner-fullbleed');
      parentContainer?.classList.remove('target-background');
      parentContainer?.classList.remove('herobanner-ratio-' + heroSize?.replace(':', 'x'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCobrand =
    isTargetContentError || !props.mboxParameter
      ? props?.trackingProperties?.merchandisingCategory === 'cobrandNonPointsMath'
      : targetData?.metaData?.type === 'CoBrand';

  const trackingPropertiesDynamic: TrackingPropsInterface = {
    trackingContentPosition: props?.trackingProperties?.trackingContentPosition || 'banner',
    trackingDescription: props?.trackingProperties?.trackingDescription || 'dca',
    trackingOfferType: props?.trackingProperties?.trackingOfferType || 'dca',
    trackingTag: targetData?.metaData?.merTag || props?.trackingProperties?.trackingTag1,
    additionalTrackingVariables: targetData?.metaData?.optionalFields ? 'true' : 'false',
    atCCeVar48: canUseDOM
      ? isCobrand // If type in MBOP response is cobrand then construct evar48
        ? isTargetContentError || !mboxParameter
          ? props?.trackingProperties?.atCCeVar48
          : getEVAR48(trackingProperties, targetData)
        : '' // if type in MBOP response is non cobrand then pass empty in evar48
      : '',
    clickTrack: props?.trackingProperties?.clickTrack ?? true,
    impressionTrack: props?.trackingProperties?.impressionTrack ?? true,
    impressionCount: props?.trackingProperties?.impressionCount ?? true,
    isAuth: getACDLObject()?.['mr_prof_authentication_state'] === 'authenticated' ?? false,
    merchandisingCategory: props?.trackingProperties?.merchandisingCategory,
    isDCAEnabled: true,
    spid: getSPID(targetData),
    rpcCode: targetData?.metaData?.rpcCode || '',
    cell: targetData?.metaData?.cell || '',
    offerCode: targetData?.metaData?.offerCode || '',
    type: targetData?.metaData?.type || '',
  };

  return (
    <Suspense fallback={''}>
      <StyledMerchandisingHeroBannerContainer
        data-component-name="o-merch-MerchandisingHeroBanner"
        className={clsx({
          hb__masking: shouldEnableDesktopMasking,
          'hb__video-enabled-desktop': showVideoOnDesktopAndMobile,
          'hb__video-enabled-desktop-only': showVideoOnDesktopOnly,
          'hb__video-enabled-mobile-only': showVideoOnMobileOnly,
        })}
        id={componentId}
        shouldClipPathFor2x1={!showVideoOnDesktopAndMobile && !showVideoOnDesktopOnly} //we use clip-path for 2x1 hero (for min-width: 768px) but we should not apply it when video is enabled on desktop
      >
        {!isTargetContentLoaded ? (
          <MerchandisingHeroBannerSkeletonLoader
            width={width}
            copyBlockWidth={props.copyBlockWidth} //explicily to be read from props as initial width of elements depends on initial props
            copyBlockWidthContainerClass={copyBlockWidthContainerClass}
            isVerticalImage={isVerticalImageFromProps}
            eyebrowText={props.eyebrowText}
            heroSize={props.heroSize}
            cobrandmathhero={props.cobrandmathhero}
            descriptionLong={props.descriptionLong}
            callToActionText2={props.callToActionText2}
            callToActionText1={props.callToActionText1}
            headerMedium={props.headerMedium}
            appliedCssClassNames={props.appliedCssClassNames}
          />
        ) : (
          <div
            className={clsx(
              'hero-banner hb__scrim',
              appliedCssClassNames,
              enableTarget ? ENABLE_TARGET : DISABLE_TARGET,
              isLightScrim ? 'light-scrim' : 'dark-scrim'
            )}
          >
            <div className={clsx('hb-item hero-' + heroSize?.replace(':', 'x'), assetsVariation)}>
              <StyledMerchandisingHeroBannerImage>
                <Image
                  customClass={clsx('hb__img', heroSize !== SIZE_16x9 ? 'hb-3x1-ratio' : '')}
                  defaultImageURL={assetPath}
                  altText={altText}
                  dynamic={dynamic}
                  renditions={renditions}
                  title={altText || 'Hero Image'}
                  loading={imageLoading}
                />
              </StyledMerchandisingHeroBannerImage>
              <MerchandisingHeroBannerVideo
                playerId={playerId}
                videoaccountId={videoaccountId}
                videoIdDesktop={videoIdDesktop}
                videoIdMobile={videoIdMobile}
                videoEnabledDesktop={videoEnabledDesktop}
                videoEnabledMobile={videoEnabledMobile}
                enableAutoPlay={enableAutoPlay}
                enableVideoLoop={enableVideoLoop}
                enableMute={enableMute}
                fileReferenceVideoCoverImage={fileReferenceVideoCoverImage}
                playerDomain={playerDomain}
                location={headerMedium ? headerMedium : descriptionLong}
                aspectRatioMobileVideo={aspectRatioMobileVideo}
                clickTrack={trackingProperties?.clickTrack}
                heroSize={heroSize}
                hasDesktopVideoError={hasDesktopVideoError}
                hasMobileVideoError={hasMobileVideoError}
                setHasDesktopVideoError={setHasDesktopVideoError}
                setHasMobileVideoError={setHasMobileVideoError}
              />
              <div
                className={clsx(
                  'hb__cnt-sec gradient',
                  !(isLightScrim || appliedCssClassNames?.includes('no-scrim')) ? copyBlockWidth : '',
                  { 'overlay-text-hero3x1': heroSize === SIZE_3x1 }
                )}
              >
                <div
                  className={clsx('container hb__cnt-ctr hero-' + heroSize?.replace(':', 'x'), {
                    vertical__image__Container: isVerticalImage,
                  })}
                >
                  {logo?.imageSrc && <MerchandisingHeroBannerAsset {...merchandisingHeroBannerAssetProps} />}
                  <div
                    className={clsx('hb__text-overlay', {
                      'col-12 col-lg-5 col-md-7 col-sm-12 vertical__image__centeraligned': isVerticalImage,
                    })}
                  >
                    {!isVerticalImage && eyebrowText && !logo?.imageSrc ? (
                      <div className="row">
                        <div className={copyBlockWidthContainerClass}>
                          <Text
                            copyText={eyebrowText}
                            fontSize={size.extraSmall}
                            customClass={eyebrowTextClassName}
                            element={tags.div}
                          />
                        </div>
                      </div>
                    ) : null}

                    {/* Header Text code starts here  */}

                    {headerMedium ? (
                      <div className="row">
                        <div className={clsx(copyBlockWidthContainerClass)}>
                          <Heading
                            variation={headingType.title}
                            element={
                              headertextstyle === H1
                                ? tags.h1
                                : headertextstyle === H3
                                ? tags.h3
                                : headertextstyle === H4
                                ? tags.h4
                                : headertextstyle === H5
                                ? tags.h5
                                : headertextstyle === H6
                                ? tags.h6
                                : tags.h2
                            }
                            titleText={headerMedium}
                            customClass={headingTextClassName}
                          />
                        </div>
                      </div>
                    ) : null}
                    {/* Subheading text code starts here  */}
                    {descriptionLong ? (
                      <div className="row">
                        <div className={clsx(copyBlockWidthContainerClass)}>
                          <Heading
                            variation={headingType.subtitle}
                            element={tags.paragraph}
                            fontSize={size.medium}
                            titleText={descriptionLong}
                            customClass={subHeadingTextClassName}
                          />
                        </div>
                      </div>
                    ) : null}
                    {isCopyBlockWidthWide && !isVerticalImage ? createCobrandContent() : null}
                    {/* Primary and Secondary Cta Button */}
                    <div className="row">
                      <div className="col-12">
                        <div
                          className={clsx({
                            flexLayout: !isVerticalImage,
                          })}
                        >
                          {callToActionText1 ? (
                            <PrimaryButton
                              buttonCopy={callToActionText1}
                              className={clsx(primaryButtonClassName, {
                                'm-button-external': primaryopeninanewtab,
                                [COBRAND_MATH_HERO_CTA_CLASS]: cobrandmathhero,
                                'primaryButton-3x1': heroSize === SIZE_3x1,
                              })}
                              isLink={true}
                              href={destinationUrl1}
                              ref={MerchandisingHeroBannerRef}
                              target={primaryopeninanewtab ? TARGET_BLANK : ''}
                              rel={primaryopeninanewtab ? `${NO_OPENER} ${NO_REFERER}` : undefined}
                              linkType={primaryopeninanewtab ? EXTERNAL : INTERNAL}
                              externalArrow={primaryopeninanewtab}
                              isTextBeforeChildren={true}
                              compName={props?.trackingProperties?.trackingContentPosition}
                              linkAriaLabelOpenNewWindow={opensInNewTab}
                              trackingProperties={{
                                ...trackingPropertiesDynamic,
                                trackingTag: targetData?.metaData?.merTag || props?.trackingProperties?.trackingTag1,
                              }}
                            >
                              {renderOpenInNewTabSRContent(primaryopeninanewtab)}
                            </PrimaryButton>
                          ) : null}
                          {!isVerticalImage && callToActionText2 ? (
                            <SecondaryButton
                              buttonCopy={callToActionText2}
                              className={clsx(secondaryButtonClassName, {
                                'm-button-external': secondaryopeninanewtab,
                                [COBRAND_MATH_HERO_CTA_CLASS]: cobrandmathhero,
                                'secondaryButton-3x1': heroSize === SIZE_3x1,
                              })}
                              isLink={true}
                              href={destinationUrl2}
                              target={secondaryopeninanewtab ? TARGET_BLANK : ''}
                              rel={secondaryopeninanewtab ? `${NO_OPENER} ${NO_REFERER}` : undefined}
                              linkType={secondaryopeninanewtab ? EXTERNAL : INTERNAL}
                              externalArrow={secondaryopeninanewtab}
                              isTextBeforeChildren={true}
                              compName={props?.trackingProperties?.trackingContentPosition}
                              linkAriaLabelOpenNewWindow={opensInNewTab}
                              trackingProperties={{
                                ...trackingPropertiesDynamic,
                                trackingTag: trackingTag2,
                              }}
                              custom_click_track_value={formCustomClickTrackValue(
                                callToActionText2,
                                secondaryopeninanewtab
                              )}
                            >
                              {renderOpenInNewTabSRContent(secondaryopeninanewtab)}
                            </SecondaryButton>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Location Cta Button */}
                {caption ? (
                  <LocationButton
                    buttonCopy={caption}
                    className={clsx('t-label-xs ml-3 ml-md-0', { 'd-none d-md-block': hideLocationCtaMobile })}
                    isLink={true}
                    href={captionUrl}
                    target={locationopeninanewtab ? TARGET_BLANK : ''}
                    rel={locationopeninanewtab ? `${NO_OPENER} ${NO_REFERER}` : undefined}
                    linkType={locationopeninanewtab ? EXTERNAL : INTERNAL}
                    externalArrow={locationopeninanewtab}
                    isTextBeforeChildren={false}
                    compName={props?.trackingProperties?.trackingContentPosition}
                    linkAriaLabelOpenNewWindow={opensInNewTab}
                    custom_click_track_value={formCustomClickTrackValue(caption, locationopeninanewtab)}
                  >
                    <span className="icon-location"></span>
                    {renderOpenInNewTabSRContent(locationopeninanewtab)}
                  </LocationButton>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </StyledMerchandisingHeroBannerContainer>
    </Suspense>
  );
};
