/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import clsx from 'clsx';

import { Heading, Types } from '@marriott/mi-ui-library';
import { StyledGroupViewContainer } from './HtmlSitemap.styles';
import { LinkBanner } from '@marriott/mi-ui-library';

// Use named rather than default exports.
export const SitemapGroupView: FC<any> = (props): any => {
  const { groupData } = props;

  return (
    <StyledGroupViewContainer>
      {groupData?.map((obj: any) => {
        return (
          <>
            <Heading
              titleText={obj?.brandTierTitle}
              variation={Types.headingType.body}
              fontSize={Types.size.medium}
              element={Types.tags.h2}
              customClass={clsx('brandTierTitle m-0')}
            />
            <LinkBanner {...obj} />
          </>
        );
      })}
    </StyledGroupViewContainer>
  );
};
