/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useState, useRef, useEffect } from 'react';
import { InputTextField, getCurrentDateObject, getValueFromDate } from '@marriott/mi-ui-library';
import { RadiobuttonControlsList } from '@marriott/mi-ui-library';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { HotelsAndEventsProps, RadioOptionsType, EventType } from './HotelsAndEvents.types';
import { StyledHotelsAndEvents } from './HotelsAndEvents.styles';
import clsx from 'clsx';
import { Button } from '@marriott/mi-ui-library';
import { formSubmitMeetingsRequest } from '../../modules/utils';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import {
  EVENTS_ERROR_MESSAGE,
  EVENT_PURPOSE,
  EVENT_SPACE,
  GUEST_ROOMS,
  SELECTED_TAB,
} from '../../modules/store/store.constants';
import { usePageContext } from '../../context';
import {
  meetingsSubmitURL,
  clickTrackMeetingEventsTitle,
  clickTrackMeetingEventsFindBtn,
  clickTrackMeetingEventsPurpose,
} from '../../modules/constants';
import { returnApplicableAlert } from '../../utils/src/utils';
import { tabSelect } from '../../constants/lib/constants';

// Use named rather than default exports.
export const HotelsAndEvents: FC<HotelsAndEventsProps> = ({ model }) => {
  const alertsListArray = model?.alertsListArray;
  const meetingsDestinationHiddenFields = useSearchFormStore((state: any) => state?.meetingsDestinationHiddenFields);
  const meetingsDestination = useSearchFormStore((state: any) => state.meetingsDestination);
  const eventsDates = useSearchFormStore((state: any) => state?.eventsDates);
  const eventPurpose = useSearchFormStore((state: any) => state?.eventPurpose);
  const guestRooms = useSearchFormStore((state: any) => state?.guestRooms);
  const eventSpace = useSearchFormStore((state: any) => state?.eventSpace);
  const meetingsSearchformRef = useRef(null);
  const [eventSpaceValue, setEventSpaceValue] = useState<string>('');
  const [guestCountValue, setGuestCountValue] = useState<string>('');
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);

  const today = getCurrentDateObject();
  const from: moment.Moment | null | undefined = today;
  const currentDate = getValueFromDate(from);
  const fromDate = getValueFromDate(eventsDates?.eventsFromDate);
  const { DEPLOYED_ENV_CONFIG, GROUPS_DEV_URL, GROUPS_TEST_URL, currentLocale } = usePageContext();
  let radioOptions: Array<RadioOptionsType> = [];
  if (model?.listOfEventTypes?.length) {
    radioOptions = model.listOfEventTypes.map((eventType: EventType) => ({
      label: eventType.eventType,
      value: eventType.eventType,
    }));
  }
  useEffect(() => {
    setEventSpaceValue(eventSpace?.noOfAttendees);
  }, [eventSpace]);
  useEffect(() => {
    setGuestCountValue(guestRooms?.noOfRooms);
  }, [guestRooms]);
  const [showEventSpaceUtility, setShowEventSpaceUtility] = useState(false);
  const [eventSpaceUtility, setEventSpaceUtility] = useState(0);

  const handleRadioButtonSelection = (option: Array<RadioOptionsType>) => {
    const selectedOption = option?.length && option[0]?.value;
    setSearchFormState([EVENT_PURPOSE], {
      [EVENT_PURPOSE]: {
        purpose: selectedOption,
      },
    });
  };
  const handleGuestInputChange = (inputValue: string) => {
    // Allow only numeric input
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    // Update the state with the numeric value
    setGuestCountValue(numericValue);
    setSearchFormState([GUEST_ROOMS], {
      [GUEST_ROOMS]: {
        noOfRooms: numericValue,
      },
    });
  };

  const handleEventInputChange = (inputValue: string) => {
    // Allow only numeric input
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    // Update the state with the numeric value
    setEventSpaceValue(numericValue);
    setSearchFormState([EVENT_SPACE], {
      [EVENT_SPACE]: {
        noOfAttendees: numericValue,
      },
    });
  };

  const handleBlur = () => {
    /**
     * on Event Blur set the eventSpaceValue * 10
     * also show the eventSpaceUtility only if eventSpaceValue > 0
     */

    setEventSpaceUtility(parseInt(eventSpaceValue) * 10);
    if (parseInt(eventSpaceValue) > 0) {
      setShowEventSpaceUtility(true);
    } else {
      setShowEventSpaceUtility(false);
    }
  };

  const handleSubmit = () => {
    formSubmitMeetingsRequest(
      meetingsSubmitURL,
      meetingsDestination,
      meetingsDestinationHiddenFields,
      eventsDates,
      meetingsSearchformRef,
      eventPurpose?.purpose,
      guestRooms?.noOfRooms,
      eventSpace?.noOfAttendees,
      DEPLOYED_ENV_CONFIG,
      GROUPS_DEV_URL,
      GROUPS_TEST_URL,
      currentLocale
    );
  };

  return (
    <div className="standard" data-component-name="m-shop-HotelsAndEvents" data-testid="shop-HotelsAndEvents">
      {!model?.disableEventDetails && <h5 className={clsx('t-subtitle-m', 'mb-3')}>{model?.eventDetailsSubheading}</h5>}
      <StyledHotelsAndEvents className={clsx('event-details', 'standard', 'd-flex', 'flex-column flex-lg-row')}>
        <div className={clsx('events-input-wrapper', 'd-flex', 'mb-md-4 mb-lg-0 mb-0', 'flex-column flex-md-row')}>
          <div className={clsx('guest-room-div mr-0 mr-md-2 mr-lg-3 mb-3 mb-md-0 mb-lg-0 ')}>
            <div className={clsx('col-12 col-md-12 col-lg-12')}>
              <InputTextField
                label={model?.guestRoomsEyebrowText}
                showIcon={false}
                variation="default"
                placeHolderText={model?.guestRoomsHelperText}
                assignInputValue={true}
                inputValue={guestCountValue}
                getInputValue={handleGuestInputChange}
                inputMaxLength={5}
              />
            </div>
          </div>
          <div
            className={clsx(
              'guest-room-div mr-lg-2 ml-md-2 ml-lg-0 d-flex flex-column align-items-end mb-3 mb-md-0 mb-lg-0'
            )}
          >
            <div className={clsx('col-12 col-md-12 col-lg-12')}>
              <InputTextField
                label={model?.eventSpaceEyebrowText}
                showIcon={false}
                variation="default"
                placeHolderText={model?.eventSpaceHelperText}
                assignInputValue={true}
                inputValue={eventSpaceValue}
                getInputValue={handleEventInputChange}
                inputMaxLength={5}
                onBlur={handleBlur}
              />
            </div>
            {showEventSpaceUtility && (
              <div className="eventSpaceUtilityLabel">{model?.eventSpaceUtilityLabel + ' ' + eventSpaceUtility}</div>
            )}
          </div>
        </div>

        <div className={clsx('d-flex  submit-block-wrapper', 'flex-column flex-md-row flex-lg-row')}>
          <div className={clsx('eventOptionSelection', 'mb-4 mb-md-0', 'col-md-6', 'col-lg-auto', 'ml-n2')}>
            <RadiobuttonControlsList
              eyebrowText={model?.eventTypePurposeTitleCopy}
              name="eventDetailsRadioButtonOptions"
              onChange={handleRadioButtonSelection}
              controls={radioOptions}
              colLength={4}
              custom_click_track_value={`${clickTrackMeetingEventsTitle} | ${clickTrackMeetingEventsPurpose}: ${eventPurpose.purpose} | internal`}
              defaultSelected={[
                {
                  label: eventPurpose.purpose,
                },
              ]}
              tabIndexForInput={-1}
            />
          </div>
          <div className="submit-btn-wrapper d-flex justify-content-end ml-lg-n3 ml-xl-0">
            <Button
              className="event-details-button m-button-l  m-button-primary-icon "
              custom_click_track_value={clickTrackMeetingEventsFindBtn}
              // On click calls this function to generate params for form submit action
              callback={(): void => {
                //clear error msgs from Hotel Searchform
                setSearchFormState([SELECTED_TAB], {
                  [SELECTED_TAB]: {
                    selectedTab: tabSelect?.meetingsTab,
                    roomCountAlertCode: '',
                  },
                });
                //handle client side error msgs
                if (!meetingsDestination.eventsDisplayText?.trim()) {
                  setSearchFormState([EVENTS_ERROR_MESSAGE], {
                    [EVENTS_ERROR_MESSAGE]: {
                      meetingsErrorMessages: returnApplicableAlert('destinationEmptyErrorCode', alertsListArray),
                    },
                  });
                } else if (currentDate === fromDate) {
                  setSearchFormState([EVENTS_ERROR_MESSAGE], {
                    [EVENTS_ERROR_MESSAGE]: {
                      meetingsErrorMessages: returnApplicableAlert('moreThanTenRoomCountErrorCode', alertsListArray),
                    },
                  });
                } else if (
                  !fromDate &&
                  !guestRooms.noOfRooms &&
                  (!eventSpace.noOfAttendees || eventSpace?.noOfAttendees === '0')
                ) {
                  setSearchFormState([EVENTS_ERROR_MESSAGE], {
                    [EVENTS_ERROR_MESSAGE]: {
                      meetingsErrorMessages: returnApplicableAlert('eventSpaceErrorCode', alertsListArray),
                    },
                  });
                } else if (!guestRooms.noOfRooms && (!eventSpace.noOfAttendees || eventSpace?.noOfAttendees === '0')) {
                  setSearchFormState([EVENTS_ERROR_MESSAGE], {
                    [EVENTS_ERROR_MESSAGE]: {
                      meetingsErrorMessages: returnApplicableAlert('eventSpaceErrorCode', alertsListArray),
                    },
                  });
                } else if (
                  guestRooms.noOfRooms < 10 &&
                  (!eventSpace.noOfAttendees || eventSpace?.noOfAttendees === '0')
                ) {
                  setSearchFormState([EVENTS_ERROR_MESSAGE], {
                    [EVENTS_ERROR_MESSAGE]: {
                      meetingsErrorMessages: returnApplicableAlert('lessThanTenRoomsErrorCode', alertsListArray),
                    },
                  });
                } else {
                  setSearchFormState([EVENTS_ERROR_MESSAGE], {
                    [EVENTS_ERROR_MESSAGE]: {
                      meetingsErrorMessages: '',
                    },
                  });
                  handleSubmit();
                }
              }}
            >
              <span className="icon-search" />
              {model?.submitCTA}
            </Button>
            {/* The below form is use to submit the input data as params to the URL */}
            <form
              ref={meetingsSearchformRef}
              id={Date.now().toString() + '-submit-meeting-search-form-action'}
              method="get"
            ></form>
          </div>
        </div>
      </StyledHotelsAndEvents>
    </div>
  );
};
