/* eslint-disable @typescript-eslint/no-explicit-any */
import { TabIconNavigationProps } from './TabIconNavigation.types';

// Imports for external libraries go here.
import { FC, useEffect, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';
import { Eyebrow, Heading, Types } from '@marriott/mi-ui-library';
import { StyledTabComponentContainer } from './TabIconNavigation.styles';
import { TabComponent } from './Tabs';
export const TabCompConfig = {
  emptyLabel: 'TabComponentContainer',
  isEmpty: () => false,
  resourceType: `mi-aem-leisure-spa/components/tabbedNavigationMenu`,
};

export interface TabList {
  tabTitle: string;
  tabSubtitle: string;
  tabValue: string;
  tabIcon: string;
  tabAction: string;
}
// Use named rather than default exports.
export const TabIconNavigation: FC<TabIconNavigationProps> = (props: TabIconNavigationProps) => {
  const {
    model: { title, subtitle, themes, styleclass, trackingProperties },
    isAuthorMode,
  } = props;

  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    const originPath = window?.location?.origin;
    const pathName = window?.location?.href?.replace(originPath ? originPath : '', '');
    const actualPath = pathName.includes('?') ? pathName.substring(0, pathName.indexOf('?')) : pathName;
    const getSelectedTab = themes?.find(tab => actualPath === tab.ctaLink);
    const selectedValue = getSelectedTab ? getSelectedTab.themeTitle : '';
    setSelectedTab(selectedValue);
  }, [themes]);

  const tabContainer = themes;
  const getTabList = () => {
    const tabList: TabList[] = tabContainer?.map((tabItem: any) => {
      const tab = {
        ...tabItem,
        tabTitle: tabItem.themeTitle,
        tabSubtitle: tabItem.themeTitle,
        tabValue: tabItem.themeTitle,
        tabIcon: tabItem.iconTag,
        tabAction: tabItem.ctaLink,
      };
      return tab;
    });
    return tabList?.length ? tabList : [];
  };

  const header = () =>
    title && (
      <Heading
        customClass={clsx('mt-2 mb-2')}
        titleText={title}
        variation={Types.headingType.title}
        fontSize={Types.size.medium}
        element={Types.tags.h1}
      />
    );

  const eyebrowContent = () => subtitle && <Eyebrow text={subtitle} />;

  const tabbedMenuHeader = () => {
    return (
      <div className={clsx('tabMenu_header')}>
        <div>
          {header()}
          {eyebrowContent()}
        </div>
      </div>
    );
  };
  return (
    <StyledTabComponentContainer data-testid="TabIconNavigation" data-component-name="o-leisure-TabIconNavigation">
      <div
        className={clsx(
          'column-container-tabs',
          styleclass?.includes('inverse') ? 'inverse' : '',
          props?.isAuthorMode ? 'tab_container_authmode' : ''
        )}
      >
        {tabbedMenuHeader()}
        <TabComponent
          tabList={getTabList()}
          componentId={props?.componentId}
          setSelectedTab={selectedTab}
          trackingProperties={trackingProperties}
          isAuthorMode={isAuthorMode}
        ></TabComponent>
      </div>
    </StyledTabComponentContainer>
  );
};
export const TabComponentContainer = (props: any) => {
  return (
    <EditableComponent config={TabCompConfig} {...props}>
      <TabIconNavigation {...props} />
    </EditableComponent>
  );
};
