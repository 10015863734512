/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET,
  trackingDetailArr,
  outletprimaryType,
  outletsecondaryType,
  RnBFILTERS,
  multipleCuisineLabel,
} from '../../modules/utils/constants';
import Cookies from 'js-cookie';
import {
  CampaignParameters,
  IMAGE_URL_DOMAIN,
  OUTLET_HERO_FALLBACK_IMAGE,
  SEARCH_RESULTS_FALLBACK_IMAGE,
  bibGourmand,
  greenStar,
  itemList,
  listItem,
  oneStar,
  richResultImageVariations,
  threeStar,
  twoStar,
} from '../utils/constants/constants';

declare global {
  interface Window {
    impressionArr: string[];
    impressionTrack: (value: string) => Record<string, string>;
    atCArray: Array<string>;
    atMArray: Array<string>;
    dataLayer?: Record<string, unknown>;
  }
}

type ldJsonPropertiesType = {
  '@context'?: string;
  '@type'?: string;
  name?: string;
  servesCuisine?: string;
  image?: string;
  description?: string;
  location?: string;
  address?: object;
  telephone?: string;
  url?: string;
  email?: string;
  acceptsReservations?: string;
  hasMap?: string;
};

export const canUseDOM = !!(typeof window !== 'undefined' && window.document);
let ldJsonProperties: ldJsonPropertiesType = {};

//metthod to check if Object is empty
export const isEmpty = (value: object): boolean => {
  return value && Object.keys(value).length === 0;
};

export function getCurrentLocation(callback: CallableFunction): void {
  let currentLocation: Record<string, string>;

  // fetching the latitude and longitude
  navigator.geolocation.getCurrentPosition(
    (location: GeolocationPosition) => {
      const lat = location.coords.latitude;
      const lng = location.coords.longitude;

      if (window.google) {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat, lng };
        // using Goggle Maps API reverse geocoding service to convert latlng to location details
        geocoder.geocode({ location: latlng }, (results: any, status: any) => {
          if (status === 'OK') {
            if (results[0]) {
              // only store the data required for hidden fields and input field
              currentLocation = {
                placeId: results[0].place_id,
                address: results[0].formatted_address,
                types: results[0].types.join(),
              };
              // using callback to pass the data
              callback(currentLocation);
            } else {
              window.alert('No location available.');
            }
          }
        });
      }
    },
    (error: GeolocationPositionError) => {
      // when location is disabled error block is called as null
      if (error) {
        callback(null);
      }
    }
  );
}

export const useGetBreakpoint = (): string | undefined => {
  if (typeof window !== 'undefined' && window?.screen && window?.screen?.width) {
    return window.innerWidth > BREAKPOINT_DESKTOP
      ? 'desktop'
      : window.innerWidth > BREAKPOINT_TABLET
      ? 'tablet'
      : 'mobile';
  } else {
    return undefined;
  }
};

export const getClassForBreakpoint = (
  breakpoint: string | undefined,
  desktopClass: string,
  tabletClass: string,
  mobileClass: string
): string => {
  if (!breakpoint) return desktopClass;
  if (breakpoint === 'tablet') {
    return tabletClass;
  } else if (breakpoint === 'mobile') {
    return mobileClass;
  } else return desktopClass;
};

export function getDataLayerScript(dataLayer: { data: any; mvpOffersData: string }, outletId: string | number) {
  if (outletId && dataLayer?.data) {
    dataLayer.data[0] = {
      ...dataLayer.data[0],
      rnb_outlet_id: outletId,
    };
  }
  return `var dataLayer = ${dataLayer?.data ? JSON.stringify(dataLayer?.data[0]) : '{}'}; var mvpOffers = ${
    dataLayer?.mvpOffersData && dataLayer?.mvpOffersData !== 'null' ? dataLayer?.mvpOffersData : '{}'
  };`;
}

export const getLdJsonScript = (outletData: any = {}, fallbackDescription = '') => {
  ldJsonProperties = {};
  ldJsonProperties['@context'] = 'https://schema.org';
  ldJsonProperties['@type'] = 'FoodEstablishment';

  if (outletData?.basicInformation?.name) {
    ldJsonProperties['name'] = `${outletData?.basicInformation?.name}`;
  }

  if (outletData?.diningExperience?.cuisines?.length) {
    if (outletData?.diningExperience?.cuisines?.length > 1)
      ldJsonProperties['servesCuisine'] = `${multipleCuisineLabel}`;
    else ldJsonProperties['servesCuisine'] = `${outletData?.diningExperience?.cuisines?.[0]}`;
  }

  if (outletData?.media?.imageConnection?.edges[0]?.node?.imageUrls?.panoramicHorizontal) {
    ldJsonProperties[
      'image'
    ] = `${IMAGE_URL_DOMAIN}${outletData?.media?.imageConnection?.edges[0]?.node?.imageUrls?.panoramicHorizontal}`;
  }

  if (outletData?.basicInformation?.description || fallbackDescription) {
    if (outletData?.basicInformation?.shortDescription) {
      ldJsonProperties['description'] = `${outletData?.basicInformation?.shortDescription}`;
    } else {
      let fallbackDesc = `${fallbackDescription}`;
      fallbackDesc = fallbackDesc.replace(/\[[A-Za-z ]+\]/, outletData?.basicInformation?.name);
      const hotelName = outletData?.properties?.edges[0]?.node?.property?.basicInformation?.brand?.name;
      fallbackDesc = fallbackDesc.replace(/\[[A-Za-z ]+\]/, hotelName);
      ldJsonProperties['description'] = `${fallbackDesc}`;
    }
  }

  if (outletData?.properties?.edges?.[0]?.node?.property?.basicInformation?.name) {
    ldJsonProperties['location'] = `${outletData?.properties?.edges?.[0]?.node?.property?.basicInformation?.name}`;
  }

  if (outletData?.contactInfo?.address) {
    ldJsonProperties['address'] = {
      '@type': 'PostalAddress',
      streetAddress: outletData?.contactInfo?.address?.line1,
      addressLocality: outletData?.contactInfo?.address?.city,
      addressRegion: outletData?.contactInfo?.address?.stateProvince,
      addressCountry: outletData?.contactInfo?.address?.country?.description,
      postalCode: outletData?.contactInfo?.address?.postalCode,
    };
  }

  if (
    outletData?.contactInfo?.contactNumbers &&
    outletData?.contactInfo?.contactNumbers[0]?.phoneNumber &&
    outletData?.contactInfo?.contactNumbers[0]?.phoneNumber?.display
  ) {
    ldJsonProperties['telephone'] = `${outletData?.contactInfo?.contactNumbers?.[0]?.phoneNumber?.display}`;
  }

  if (outletData?.basicInformation?.url) {
    ldJsonProperties['url'] = `${outletData?.basicInformation?.url}`;
  }

  if (outletData?.contactInfo?.emails && outletData?.contactInfo?.emails[0]) {
    ldJsonProperties['email'] = `${outletData?.contactInfo?.emails[0]}`;
  }

  if (
    outletData?.properties?.edges[0]?.node?.property?.basicInformation?.latitude &&
    outletData?.properties?.edges[0]?.node?.property?.basicInformation?.longitude
  ) {
    ldJsonProperties[
      'hasMap'
    ] = `https://www.google.com/maps/search/?api=1&query=${outletData?.properties?.edges[0]?.node?.property?.basicInformation?.latitude},${outletData?.properties?.edges[0]?.node?.property?.basicInformation?.longitude}`;
  }

  if (outletData?.basicInformation?.reservationURL) {
    ldJsonProperties['acceptsReservations'] = `${outletData?.basicInformation?.reservationURL ? 'Y' : 'N'}`;
  }

  return JSON.stringify(ldJsonProperties);
};

export const getErrorMessage = (errrorCode: number) => {
  switch (errrorCode) {
    case 404:
    case 422:
      return 'mileError';
    case 400:
    case 403:
    case 411:
      return 'apiError';
    case 500:
    case 503:
    case 502:
      return 'apiErrorLabel';
    default:
      return 'apiErrorLabel';
  }
};

export const getHttpStatusCode = (error: any) => {
  if (
    error?.graphQLErrors?.length &&
    !error?.protocolErrors?.length &&
    !error?.clientErrors?.length &&
    error?.networkError === null &&
    !error?.[0]?.extensions
  ) {
    return error.graphQLErrors[0].extensions.httpStatus;
  } else if (
    !error?.graphQLErrors?.length &&
    error?.protocolErrors?.length &&
    !error?.clientErrors?.length &&
    error?.networkError === null &&
    !error[0]?.extensions
  ) {
    return error.protocolErrors[0].extensions.httpStatus;
  } else if (
    !error?.graphQLErrors?.length &&
    !error?.protocolErrors?.length &&
    error?.clientErrors?.length &&
    error?.networkError === null &&
    !error[0]?.extensions
  ) {
    return error?.clientErrors[0]?.extensions?.httpStatus;
  } else if (
    error?.networkError !== null &&
    !error?.graphQLErrors?.length &&
    !error?.protocolErrors?.length &&
    !error?.clientErrors?.length &&
    !error?.[0]?.extensions
  ) {
    return error?.networkError?.statusCode;
  } else if (
    error?.networkError === null &&
    !error?.graphQLErrors?.length &&
    !error?.protocolErrors?.length &&
    !error?.clientErrors?.length &&
    error[0]?.extensions
  ) {
    return error[0]?.extensions?.httpStatus;
  }
};

export const trackImpression = (
  trackingProperties: any,
  text: string,
  preTrackVal?: string,
  rnb_outlet_id?: string
) => {
  if (trackingProperties?.isCoBrand) {
    return;
  }
  const tracking = getTrackingProperties(trackingProperties || {}, ',', rnb_outlet_id);
  const preTrackValue = preTrackVal ? preTrackVal + '|' : '';
  if (trackingProperties?.impressionTrack && !window?.impressionArr?.includes(`${tracking.trackingString}${text}`)) {
    const ImpressionEventType = trackingProperties?.['impressionEventType'] || 'event168';
    if (window?.impressionTrack) {
      window.impressionTrack(
        `${preTrackValue}${ImpressionEventType}|${tracking?.payloadType}=${tracking.trackingString}`
      );
      if (typeof window.impressionArr === 'object') {
        window.impressionArr?.push(`${tracking.trackingString}${text}`);
      } else {
        window.impressionArr = [`${tracking.trackingString}${text}`];
      }
    }
  }
};

export const getTrackingProperties = (trackingProperties: any, seperator: string, rnb_outlet_id?: string) => {
  const trackingArr: Array<string> = [];
  trackingDetailArr.forEach(item => {
    if (item.text) {
      // Added condition to get the specific Outlet ID
      if (item.text === 'rnb_outlet_id') {
        if (rnb_outlet_id) trackingArr.push(`${item.pre}${rnb_outlet_id}`);
      } else {
        trackingArr.push(`${item.pre}${trackingProperties?.[item.text]}`);
      }
    }
  });
  return {
    trackingString: trackingArr.join(seperator),
    payloadType: trackingProperties?.payloadType || 'list3',
  };
};

export const getUserLoginStatus = () => {
  if (Cookies.get('UserIdToken') !== null && Cookies.get('UserIdToken') !== undefined) {
    return true;
  } else return false;
};
// Get Full Outlet Address
export const getOutletAddress = (contactInformation: any) => {
  let address = '';
  address += contactInformation?.address?.address1 || '';

  if (contactInformation?.address?.address2) {
    address += ' ' + contactInformation.address.address2;
    if (!(address.endsWith(',') || address.endsWith(' '))) address += ', ';
    else address += ' ';
  }

  if (address.endsWith(', ')) address += getOutletShortAddress(contactInformation);
  else if (address) address += ', ' + getOutletShortAddress(contactInformation);
  else address += getOutletShortAddress(contactInformation);

  return address;
};

// Get Short Outlet Address: City, State, Country
export const getOutletShortAddress = (contactInformation: any) => {
  let address = '';

  if (contactInformation?.address?.city) {
    address += contactInformation.address.city;
    if (!(address.endsWith(',') || address.endsWith(' '))) address += ', ';
    else address += ' ';
  }

  if (contactInformation?.address?.stateProvince) {
    address += contactInformation.address.stateProvince;
    if (!(address.endsWith(',') || address.endsWith(' '))) address += ', ';
    else address += ' ';
  }
  address += contactInformation?.address?.country?.description || '';
  return address;
};

export function getTitleIconOutlet(restaurant: { primaryType: string; secondaryType: string }) {
  if (
    restaurant?.primaryType?.toLowerCase() === outletprimaryType?.toLowerCase() &&
    restaurant?.secondaryType?.toLowerCase() === outletprimaryType?.toLowerCase()
  ) {
    return true;
  } else if (
    restaurant?.primaryType?.toLowerCase() === outletprimaryType?.toLowerCase() &&
    restaurant?.secondaryType?.toLowerCase() === outletsecondaryType?.toLowerCase()
  ) {
    return true;
  } else if (!restaurant?.primaryType && !restaurant?.secondaryType) {
    return true;
  } else if (
    restaurant?.primaryType?.toLowerCase() === outletprimaryType?.toLowerCase() &&
    !restaurant?.secondaryType
  ) {
    return true;
  } else if (
    !restaurant?.primaryType &&
    restaurant?.secondaryType?.toLowerCase() === outletprimaryType?.toLowerCase()
  ) {
    return true;
  } else if (
    restaurant?.primaryType?.toLowerCase() === outletsecondaryType?.toLowerCase() &&
    restaurant?.secondaryType?.toLowerCase() === outletsecondaryType?.toLowerCase()
  ) {
    return false;
  } else if (
    restaurant?.primaryType?.toLowerCase() === outletsecondaryType?.toLowerCase() &&
    restaurant?.secondaryType?.toLowerCase() === outletprimaryType?.toLowerCase()
  ) {
    return false;
  } else if (
    restaurant?.primaryType?.toLowerCase() === outletsecondaryType?.toLowerCase() &&
    !restaurant?.secondaryType
  ) {
    return false;
  } else if (
    !restaurant?.primaryType &&
    restaurant?.secondaryType?.toLowerCase() === outletsecondaryType?.toLowerCase()
  ) {
    return false;
  }
  return false;
}

export function getCuisine(diningExperience: any) {
  if (diningExperience?.cuisines?.length > 1) {
    return multipleCuisineLabel;
  } else return diningExperience?.cuisines?.length ? diningExperience?.cuisines[0] : '';
}

export function getEarnRedeemDetails(restaurant: any) {
  if (restaurant?.loyalty?.earnBonvoyPointsOffFolio === true && restaurant?.payments?.payWithBonvoyPoints === true) {
    return 'Earn & Redeem Points';
  } else if (
    (restaurant?.loyalty?.earnBonvoyPointsOffFolio === '' ||
      restaurant?.loyalty?.earnBonvoyPointsOffFolio === undefined) &&
    (restaurant?.payments?.payWithBonvoyPoints === '' || restaurant?.payments?.payWithBonvoyPoints === undefined)
  ) {
    return 'Do not show';
  } else if (
    restaurant?.loyalty?.earnBonvoyPointsOffFolio === true &&
    restaurant?.payments?.payWithBonvoyPoints === false
  ) {
    return 'Earn Points';
  } else if (
    restaurant?.loyalty?.earnBonvoyPointsOffFolio === false &&
    restaurant?.payments?.payWithBonvoyPoints === true
  ) {
    return 'Redeem Points';
  } else if (
    restaurant?.loyalty?.earnBonvoyPointsOffFolio === true &&
    (restaurant?.payments?.payWithBonvoyPoints === '' || restaurant?.payments?.payWithBonvoyPoints === undefined)
  ) {
    return 'Earn Points';
  } else if (
    (restaurant?.loyalty?.earnBonvoyPointsOffFolio === '' ||
      restaurant?.loyalty?.earnBonvoyPointsOffFolio === undefined) &&
    restaurant?.payments?.payWithBonvoyPoints === true
  ) {
    return 'Redeem Points';
  }
  return '';
}

export function getMichelinData(restaurant: any, props: any) {
  if (restaurant?.awards?.michelinStar === oneStar) {
    return {
      title: props?.oneStarLabel,
      starCount: Number(restaurant?.awards?.michelinStar.replace(' Star', '')),
      iconPath: props?.iconPathOneStar || '',
    };
  } else if (restaurant?.awards?.michelinStar === twoStar) {
    return {
      title: props?.twoStarLabel,
      starCount: Number(restaurant?.awards?.michelinStar.replace(' Star', '')),
      iconPath: props?.iconPathTwoStar || '',
    };
  } else if (restaurant?.awards?.michelinStar === threeStar) {
    return {
      title: props?.threeStarLabel,
      starCount: Number(restaurant?.awards?.michelinStar.replace(' Star', '')),
      iconPath: props?.iconPathThreeStar || '',
    };
  } else if (restaurant?.awards?.michelinStar === bibGourmand) {
    return { title: props?.bibGourmandLabel, starCount: 0, iconPath: props?.iconPathMichelinBibGourmand || '' };
  } else if (restaurant?.awards?.michelinStar === greenStar) {
    return { title: props?.greenStarLabel, starCount: 0, iconPath: props?.iconPathGreenStar || '' };
  }
  return null;
}

export const getCategorizedBrands = (currentFilterData: any, categorizedBrandsArray: any) => {
  const categorizedArr = categorizedBrandsArray;
  const facetsData = currentFilterData?.filter((key: any) => key.type.code === RnBFILTERS.brands)[0];
  const updatedCategorizedArr = categorizedArr?.map((item: any) => {
    const updatedBrandListItem = item.brandsLabelListItems.map((brand: any) => {
      const matchingFacet = facetsData.buckets.find((facet: any) => facet.label === brand.brandTagId);
      if (matchingFacet) {
        return { ...brand, ...matchingFacet, isDisabled: false };
      } else {
        return { ...brand, ...matchingFacet, isDisabled: true };
      }
    });
    return { ...item, brands: updatedBrandListItem };
  });
  return updatedCategorizedArr;
};

export const getProcessedFilterData = (currFilterData: any, filterType: string, props: any) => {
  if (filterType === 'distance') {
    const desiredKeys = ['count', 'end', 'start'];
    const updatedDistanceOptions = currFilterData?.map((obj: any) => {
      const newDistanceObj: any = {};
      desiredKeys.forEach(key => {
        newDistanceObj[key] = obj[key];
      });
      return newDistanceObj;
    });
    const modifiedDistanceOptions = updatedDistanceOptions.map((item: any) => ({
      count: item.count,
      label: `${parseFloat(item.start)} - ${parseFloat(item.end)} miles`,
    }));
    return modifiedDistanceOptions;
  } else {
    const desiredKeys = ['code', 'count', 'label'];
    const updatedFilterOptions = currFilterData
      ?.filter((key: any) => key.type.code === RnBFILTERS[filterType as keyof typeof RnBFILTERS])?.[0]
      ?.buckets?.map((obj: { [x: string]: any }) => {
        const newFilterOptionsObj: any = {};
        desiredKeys.forEach(key => {
          newFilterOptionsObj[key] = obj[key];
        });
        return newFilterOptionsObj;
      });
    if (filterType === 'hour' || filterType === 'bonvoyMember') {
      const modifiedFilterValueOptions = updatedFilterOptions?.map((item: any) => {
        const modifiedLabel = props?.[item.label] || item.label;
        return { ...item, label: modifiedLabel };
      });
      return modifiedFilterValueOptions;
    } else return updatedFilterOptions;
  }
};

export function getOutletCardImagesFeature(restaurants: any, fallbackmedia?: any) {
  const viewPort = window.matchMedia('(max-width: 991px)').matches ? 'mobile' : 'desktop';
  const imagePath = SEARCH_RESULTS_FALLBACK_IMAGE[viewPort].split('?')[0];
  const dimensions = SEARCH_RESULTS_FALLBACK_IMAGE[viewPort].split('?')[1];

  const restaurantsUpdated = restaurants?.map((restaurant: any) => {
    const cardImages: any = [];
    if (restaurant?.node?.media?.imageConnection && restaurant?.node?.media?.imageConnection?.edges?.length === 1) {
      const element = restaurant?.node?.media?.imageConnection?.edges?.map((element: any) => {
        if (element?.node?.imageUrls?.[imagePath]) {
          cardImages.push({
            defaultImageUrl: `https://cache.marriott.com${element.node.imageUrls[imagePath]}?${dimensions}`,
            isFallBackImage: false,
          });
        } else {
          cardImages.push({
            dynamicMedia: fallbackmedia,
            isFallBackImage: true,
          });
        }
        element = { ...element, node: { ...element.node, cardImages: cardImages } };
        return element;
      });
      restaurant = {
        ...restaurant,
        node: {
          ...restaurant.node,
          media: {
            ...restaurant.node.media,
            imageConnection: {
              ...restaurant.node.media.imageConnection,
              edges: element,
            },
          },
        },
      };
      return restaurant;
    } else if (
      restaurant?.node?.media?.imageConnection &&
      restaurant?.node?.media?.imageConnection?.edges?.length > 1
    ) {
      let element1 = restaurant?.node?.media?.imageConnection?.edges?.filter((element: any) => {
        return element?.node?.imageUrls[imagePath] !== '' && element?.node?.imageUrls[imagePath] !== null;
      });

      if (element1?.length === 1) {
        cardImages.push({
          defaultImageUrl: `https://cache.marriott.com${element1[0].node.imageUrls[imagePath]}?${dimensions}`,
          isFallBackImage: false,
        });

        const element2 = [
          {
            node: {
              cardImages: cardImages,
            },
          },
        ];
        restaurant = {
          ...restaurant,
          node: {
            ...restaurant?.node,
            media: {
              ...restaurant?.node?.media,
              imageConnection: {
                ...restaurant?.node?.media?.imageConnection,
                edges: element2,
              },
            },
          },
        };
        return restaurant;
      } else if (element1?.length > 0) {
        element1 = element1.slice(0, 5);

        restaurant = {
          ...restaurant,
          node: {
            ...restaurant?.node,
            media: {
              ...restaurant?.node?.media,
              imageConnection: {
                ...restaurant?.node?.media?.imageConnection,
                edges: element1,
              },
            },
          },
        };
      } else {
        cardImages.push({
          dynamicMedia: fallbackmedia,
          isFallBackImage: true,
        });
        const element2 = [
          {
            node: {
              cardImages: cardImages,
            },
          },
        ];
        restaurant = {
          ...restaurant,
          node: {
            ...restaurant?.node,
            media: {
              ...restaurant?.node?.media,
              imageConnection: {
                ...restaurant?.node?.media?.imageConnection,
                edges: element2,
              },
            },
          },
        };
        return restaurant;
      }
      return restaurant;
    } else {
      cardImages.push({
        dynamicMedia: fallbackmedia,
        isFallBackImage: true,
      });
      const element2 = [
        {
          node: {
            cardImages: cardImages,
          },
        },
      ];
      restaurant = {
        ...restaurant,
        node: {
          ...restaurant?.node,
          media: {
            ...restaurant?.node?.media,
            imageConnection: {
              ...restaurant?.node?.media?.imageConnection,
              edges: element2,
            },
          },
        },
      };
      return restaurant;
    }
  });
  return restaurantsUpdated;
}

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatCityName = (url: string): string => {
  return url?.split('/')[2]?.split('?')?.[0]?.split('-')?.map(capitalizeFirstLetter)?.join(' ');
};

export const getAEMKeyFromProps = (props: any, arr: any) => {
  const AEMKeys = arr.map((obj: any) => Object.keys(props).find(filterValue => props[filterValue] === obj) || obj);
  return AEMKeys;
};

export const scrollSection = (section: React.RefObject<HTMLElement>) => {
  const slider: HTMLElement | null = section?.current;
  let isDown = false;
  let startX: number;
  let scrollLeft: number;

  slider?.addEventListener('mousedown', (e: MouseEvent) => {
    const rect = slider.getBoundingClientRect();
    isDown = true;
    slider.classList.add('active');
    // Get initial mouse position
    startX = e.pageX - rect.left;
    // Get initial scroll position in pixels from left
    scrollLeft = slider.scrollLeft;
  });

  slider?.addEventListener('mouseleave', () => {
    isDown = false;
    slider.dataset['dragging'] = 'false';
    slider.classList.remove('active');
  });

  slider?.addEventListener('mouseup', () => {
    isDown = false;
    slider.dataset['dragging'] = 'false';
    slider.classList.remove('active');
  });

  slider?.addEventListener('mousemove', (e: MouseEvent) => {
    if (!isDown) return;
    const rect = slider.getBoundingClientRect();
    e.preventDefault();
    slider.dataset['dragging'] = 'true';
    // Get new mouse position
    const x = e.pageX - rect.left;
    // Get distance mouse has moved (new mouse position minus initial mouse position)
    const walk = x - startX;
    // Update scroll position of slider from left (amount mouse has moved minus initial scroll position)
    slider.scrollLeft = scrollLeft - walk;
  });
};

export const recommendationFilters = (props: any) => {
  const keys = ['city', 'state', 'country', 'cuisine', 'seating', 'brandId'];
  const filterObjects: { [key: string]: string }[] = [];

  keys.forEach(key => {
    if (props[key]) {
      const filterObject = { [key]: props[key] };
      filterObjects.push(filterObject);
    }
  });
  return filterObjects;
};

type richResultPropertiesType = {
  '@context'?: string;
  '@type'?: string;
  itemListElement?: listItemType[];
};

type listItemType = {
  '@type'?: string;
  position?: string;
  item: {
    '@type'?: string;
    name?: string;
    image?: string[];
    url?: string;
    servesCuisine?: string;
  };
};

export const generateRichResultsData = (
  recommendationData: any,
  pageDomain: string,
  recommendedFallBackImage: string
) => {
  const richResultProperties: richResultPropertiesType = {};
  richResultProperties['@context'] = 'https://schema.org';
  richResultProperties['@type'] = itemList;
  richResultProperties['itemListElement'] = recommendationData.map((recommendationItem: any, index: number) => {
    const images: string[] = [];
    richResultImageVariations.forEach(variation => {
      if (recommendationItem?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls[variation]) {
        images.push(
          `${IMAGE_URL_DOMAIN}${recommendationItem?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls[variation]}`
        );
      }
    });
    if (images?.length === 0) images.push(recommendedFallBackImage);

    return {
      '@type': listItem,
      position: index + 1,
      item: {
        '@type': recommendationItem?.node?.primaryType || 'Restaurant',
        name: recommendationItem?.node?.name,
        image: images,
        url: `${pageDomain}/${recommendationItem?.node?.seoUrl}.mi`,
        servesCuisine: recommendationItem?.node?.cuisine,
      },
    };
  });

  return JSON.stringify(richResultProperties);
};

export const getHeroBannerImages = (outletData: any = {}, fallbackHeroImgPath: any = {}) => {
  let viewPort = 'mobile';
  if (typeof window !== 'undefined') {
    if (window.matchMedia('(max-width: 567px)').matches) {
      viewPort = 'xmobile';
    } else if (window.matchMedia('(min-width: 768px and max-width: 992px)').matches) {
      viewPort = 'tablet';
    } else if (window.matchMedia('(min-width: 993px)').matches) {
      viewPort = 'desktop';
    }
  }

  let heroBannerImages: any = [];
  if (outletData && outletData?.media?.imageConnection) {
    const imagePath = OUTLET_HERO_FALLBACK_IMAGE[viewPort as keyof typeof OUTLET_HERO_FALLBACK_IMAGE].split('?')[0];
    const dimensions = OUTLET_HERO_FALLBACK_IMAGE[viewPort as keyof typeof OUTLET_HERO_FALLBACK_IMAGE].split('?')[1];

    if (outletData?.media?.imageConnection?.edges?.length === 1) {
      if (
        outletData?.media?.imageConnection?.edges[0]?.node?.imageUrls[imagePath] !== '' &&
        outletData?.media?.imageConnection?.edges[0]?.node?.imageUrls[imagePath] !== null
      ) {
        heroBannerImages.push({
          defaultImageUrl: `${IMAGE_URL_DOMAIN}${outletData?.media?.imageConnection?.edges[0].node?.imageUrls[imagePath]}?${dimensions}`,
          isFallBackImage: false,
        });
      } else {
        heroBannerImages.push({
          dynamicMedia: fallbackHeroImgPath,
          isFallBackImage: true,
        });
      }
    } else if (outletData?.media?.imageConnection?.edges?.length > 1) {
      let sortedImages = [...outletData.media.imageConnection.edges];
      sortedImages = sortedImages.sort(function (a: any, b: any) {
        return (
          Number(b.node.isPrimaryArchitectural) - Number(a.node.isPrimaryArchitectural) ||
          Number(b.node.isPrimaryServices) - Number(a.node.isPrimaryServices) ||
          Number(b.node.isSecondaryArchitectural) - Number(a.node.isSecondaryArchitectural) ||
          Number(b.node.isSecondaryServices) - Number(a.node.isSecondaryServices)
        );
      });
      sortedImages?.forEach((element: any) => {
        if (element?.node?.imageUrls[imagePath] !== '' && element?.node?.imageUrls[imagePath] !== null) {
          heroBannerImages.push({
            defaultImageUrl: `${IMAGE_URL_DOMAIN}${element?.node?.imageUrls[imagePath]}?${dimensions}`,
            isFallBackImage: false,
          });
        }
      });
      if (heroBannerImages.length === 0) {
        heroBannerImages.push({
          dynamicMedia: fallbackHeroImgPath,
          isFallBackImage: true,
        });
      } else heroBannerImages = heroBannerImages.slice(0, 4);
    }
  } else {
    heroBannerImages.push({
      dynamicMedia: fallbackHeroImgPath,
      isFallBackImage: true,
    });
  }

  return heroBannerImages;
};

export const getIsPartOfList = (campaignParams: CampaignParameters, outletId: string) => {
  const outletIds = campaignParams?.outletList?.split(',');
  return outletIds?.includes(outletId);
};

export const getUpdatedReservationUrl = (campaignParams: CampaignParameters, reservationUrl: string) => {
  const url = new URL(reservationUrl);
  const search_params = url.searchParams;
  if (
    campaignParams?.param1 &&
    campaignParams?.param1 !== '' &&
    campaignParams?.param1Value &&
    campaignParams?.param1Value !== ''
  )
    url.searchParams.set(campaignParams?.param1, campaignParams?.param1Value);
  if (
    campaignParams?.param2 &&
    campaignParams?.param2 !== '' &&
    campaignParams?.param2Value &&
    campaignParams?.param2Value !== ''
  )
    url.searchParams.set(campaignParams?.param2, campaignParams?.param2Value);
  url.search = search_params.toString();

  return url?.href;
};
