import { FC } from 'react';
import { ErrorHandlerAlert } from '../../molecules';
import { HVMIBannerComponent } from './component';
import { ComponentProps } from './HvmiBannerComponent.type';

const HvmiBanner: FC<ComponentProps> = props => {
  const { id } = props;
  return (
    <ErrorHandlerAlert alertMessage={props?.componentErrorMessage}>
      <div id={id}>
        <div className="color-scheme3 banner-container py-5" id="serp-hvmi-banner-component-container">
          <HVMIBannerComponent {...props?.model} />
        </div>
      </div>
    </ErrorHandlerAlert>
  );
};
export default HvmiBanner;
