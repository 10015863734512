/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';
import { SearchFacetsList, amenityCodeToIconMap } from '../../../constants/Filters.constant';
import {
  countryParamURL,
  destinationParamURL,
  destinationRedirectURL,
  restParamsURL,
  stateProvinceParamURL,
  submitSearchURL,
  destinationRegionURL,
  SESSION_KEYS,
  CANADA_REGION_CODE,
  MEXICO_REGION_CODE,
  CHINA_REGION_CODE,
  TAIWAN_REGION_CODE,
  MACAU_REGION_CODE,
  HONG_KONG_REGION_CODE,
  CHINA_LABEL_FOR_MAP,
  ASIA_AEM_CODE,
  USA_CODE,
  facetTypeMapping,
  AFRICA_CUSTOM_LABEL,
  ASIA_CUSTOM_LABEL,
  EUROPE_CUSTOM_LABEL,
  MIDDLE_EAST_CUSTOM_LABEL,
  SOUTH_AMERICA_CUSTOM_LABEL,
  CARIBBEAN_CUSTOM_LABEL,
  CENTRAL_AMERICA_CUSTOM_LABEL,
  AUSTRALIA_CUSTOM_LABEL,
  USA_CUSTOM_LABEL,
  CANADA_CUSTOM_LABEL,
  MEXICO_CUSTOM_LABEL,
  MIDDLE_EAST_CODE,
  SOUTH_AMERICA_CODE,
  CENTRAL_AMERICA_CODE,
  AUSTRALIA_CODE,
} from '../../../modules/constants/HotelDirectory.constants';
import Axios from 'axios';
import { getDeployedEnvType } from '@marriott/mi-apollo-client-utils';
import { updateDataLayerOnInteractions } from '../../../modules/utils';
import { HotelDirectoryDataList, HotelDirectoryDataType, RegionItems, SubRegionItems } from './HotelDirectoryMap.types';
import { addSubDirectoryPrefix } from '../../../utils/src/helper';
export const clusterLabel = (markers: any) => {
  const labelText = markers.reduce((pre: any, item: any) => {
    return pre?.label?.text
      ? Number(pre.label?.text) + Number(item.label?.text)
      : Number(pre) + Number(item.label?.text);
  });
  return String(labelText);
};
export const customClusterCalculator = (markers: any) => {
  const index = 0;
  return {
    text: clusterLabel(markers),
    index,
    url: '',
  };
};
export const findCenterOfCoordinates = (coordinatesProp: any): any => {
  const coordinates = [...coordinatesProp];
  if (coordinates) {
    if (coordinates?.length === 0) {
      throw new Error('No coordinates provided');
    }

    // Convert latitude and longitude from degrees to radians
    const coordinatesInRadians = coordinates?.map((coord: any) => ({
      lat: (Math.PI * coord.position.lat()) / 180,
      lng: (Math.PI * coord.position.lng()) / 180,
    }));

    let x = 0;
    let y = 0;
    let z = 0;

    for (const coord of coordinatesInRadians) {
      const lat = coord?.lat;
      const lng = coord?.lng;

      x += Math.cos(lat) * Math.cos(lng);
      y += Math.cos(lat) * Math.sin(lng);
      z += Math.sin(lat);
    }

    const totalCoords = coordinatesInRadians?.length;

    x /= totalCoords;
    y /= totalCoords;
    z /= totalCoords;

    // Calculate the longitude and latitude in radians
    const lng = Math.atan2(y, x);
    const hyp = Math.sqrt(x * x + y * y);
    const lat = Math.atan2(z, hyp);

    // Convert latitude and longitude back to degrees
    const centerLat = (lat * 180) / Math.PI;
    const centerLng = (lng * 180) / Math.PI;

    return { lat: centerLat, lng: centerLng };
  }
};

//function to assign set boundaries
export const fitBounds = (mapRef: any, bounds: any) => {
  if (mapRef && bounds) {
    mapRef.fitBounds(bounds);
  }
};

//function to set boundaries for maps based on the markers latitude and longitude
export const callFitBounds = (uniqueMarkersState: any, isLoaded: any, setBounds: any) => {
  if (uniqueMarkersState?.length > 0 && isLoaded) {
    const bounds = new window.google.maps.LatLngBounds();
    uniqueMarkersState?.forEach((marker: any) => {
      if (marker?.latitude && marker?.longitude) {
        bounds?.extend({ lat: Number(marker?.latitude), lng: Number(marker?.longitude) });
      }
    });
    setBounds(bounds);

    //to set the bounds for map with less markers so that map stays zoom out
    if (bounds?.getNorthEast()?.equals(bounds?.getSouthWest())) {
      const extendPoint = new window.google.maps.LatLng(
        bounds?.getNorthEast()?.lat() + 8,
        bounds?.getNorthEast()?.lng() + 8
      );
      bounds?.extend(extendPoint);
      setBounds(bounds);
    }
  }
};

export const customSort = (a: any, b: any) => {
  if (a?.code === USA_CODE) {
    return -1;
  } else if (b?.code === USA_CODE) {
    return 1;
  } else {
    return a?.code?.localeCompare(b?.code, 'en', { numeric: true, sensitivity: 'base' });
  }
};

export const extractMarkerData = (
  data: any,
  selectedRegion: any,
  subRegionName: Array<any>,
  labelForChina: string,
  australiaPILabel: string,
  mexicoCode: string,
  canadaCode: string,
  australiaAndOceaniaLabel: string
) => {
  let results: any = [];
  let chinaLatitude: string | number;
  let chinaLongitude: string | number;
  data?.searchPropertiesByDestination?.facets?.forEach((response: { type: { code: string }; buckets: any[] }) => {
    if (response?.type?.code === 'states' || response?.type?.code === 'countries') {
      if (response?.type?.code === 'countries') {
        response.buckets?.forEach(subResponse => {
          subRegionName?.forEach(item => {
            let regionName = Object.keys(item).length ? Object.keys(item)[0] : '';

            if (regionName === selectedRegion?.label && selectedRegion?.customLabel !== USA_CUSTOM_LABEL) {
              const isMexicoPresent = results?.findIndex((item: any) => {
                return item?.code === MEXICO_REGION_CODE;
              });
              const isCanadaPresent = results?.findIndex((item: any) => {
                return item?.code === CANADA_REGION_CODE;
              });
              if (
                regionName.toUpperCase() === mexicoCode?.toUpperCase() &&
                isMexicoPresent < 0 &&
                selectedRegion?.count > 0
              ) {
                results.push({
                  code: MEXICO_REGION_CODE,
                  label: selectedRegion?.label,
                  count: selectedRegion?.count,
                  latitude: Number(selectedRegion?.latitude),
                  longitude: Number(selectedRegion?.longitude),
                });
              }
              if (
                regionName.toUpperCase() === canadaCode?.toUpperCase() &&
                isCanadaPresent < 0 &&
                selectedRegion?.count > 0
              ) {
                results.push({
                  code: CANADA_REGION_CODE,
                  label: selectedRegion?.label,
                  count: selectedRegion?.count,
                  latitude: Number(selectedRegion?.latitude),
                  longitude: Number(selectedRegion?.longitude),
                });
              }
              if (regionName === australiaPILabel) {
                regionName = australiaAndOceaniaLabel;
              }
              item?.[regionName]?.forEach((subRItem: SubRegionItems) => {
                //below part of code is very specific to China , Honkgonk, Taiwan and Macau
                if (subRItem?.subRegionCode === CHINA_REGION_CODE) {
                  chinaLatitude = subRItem?.subRegionLatitude;
                  chinaLongitude = subRItem?.subRegionLongitude;
                }
                if (subResponse?.code === subRItem?.subRegionCode && selectedRegion?.customLabel !== USA_CUSTOM_LABEL) {
                  results.push({
                    code: subRItem?.subRegionCode,
                    label: subRItem?.subRegionTagTitle,
                    count: subResponse?.count,
                    latitude: Number(subRItem?.subRegionLatitude),
                    longitude: Number(subRItem?.subRegionLongitude),
                  });
                }
                // }
              });
            }
          });
        });
      } else {
        response.buckets?.forEach(subResponse => {
          subRegionName?.forEach(item => {
            const regionName = Object.keys(item).length ? Object.keys(item)[0] : '';

            if (regionName === selectedRegion?.label && selectedRegion?.customLabel === USA_CUSTOM_LABEL) {
              item?.[regionName]?.forEach((subRItem: SubRegionItems) => {
                if (subResponse?.code === subRItem?.subRegionCode) {
                  results.push({
                    code: subRItem?.subRegionCode,
                    label: subRItem?.subRegionTagTitle,
                    count: subResponse?.count,
                    latitude: Number(subRItem?.subRegionLatitude),
                    longitude: Number(subRItem?.subRegionLongitude),
                  });
                }
                // }
              });
            }
          });
        });
      }
    }
  });
  //below part of code is very specific to China , Honkgonk, Taiwan and Macau
  const newCountsObjectForChina = {
    count: 0,
    label: labelForChina ?? CHINA_LABEL_FOR_MAP,
    longitude: 0,
    latitude: 0,
    code: CHINA_REGION_CODE,
  };
  results = results?.filter((obj: { code: string; count: number; latitude: number; longitude: number }) => {
    if (
      [TAIWAN_REGION_CODE, MACAU_REGION_CODE, HONG_KONG_REGION_CODE, CHINA_REGION_CODE].includes(obj.code) &&
      selectedRegion?.code === ASIA_AEM_CODE
    ) {
      newCountsObjectForChina.count += obj.count;
      newCountsObjectForChina.latitude = Number(chinaLatitude);
      newCountsObjectForChina.longitude = Number(chinaLongitude);

      return false;
    }
    return true;
  });
  if (selectedRegion?.code === ASIA_AEM_CODE) {
    results.push(newCountsObjectForChina);
  }
  return results;
};

// to extract the facets data from UXl response
export const extractFiltersData = (data: any) => {
  const results: Array<{
    filterName: string;
    data: Array<{ code: string; label: string; count: number }>;
    type: string;
    code: string;
  }> = [];

  const getBucketsResponse = (response: any) => {
    const bucketData: any = [];
    response.buckets?.forEach((subResponse: any) => {
      bucketData.push({
        code: subResponse?.code,
        label: subResponse?.label,
        count: subResponse?.count,
        iconClass:
          response?.type?.enumCode === SearchFacetsList?.amenities
            ? `icon-${amenityCodeToIconMap[subResponse?.code] ?? subResponse?.code}`
            : '',
      });
    });

    return bucketData;
  };

  data?.searchPropertiesByDestination?.facets?.forEach((response: any) => {
    if (
      response?.type?.enumCode !== SearchFacetsList?.leisureRegion &&
      response?.type?.enumCode !== SearchFacetsList?.city &&
      response?.type?.enumCode !== SearchFacetsList?.hotelType &&
      response?.type?.enumCode !== SearchFacetsList.country &&
      response?.type?.enumCode !== SearchFacetsList.state
    ) {
      results.push({
        filterName: response?.type?.label,
        data: getBucketsResponse(response),
        type: response?.type?.enumCode === SearchFacetsList?.brands ? 'brand-filter' : 'checkbox-controlled-list',
        code: response?.type?.enumCode,
      });
    }
  });

  return results;
};

// to get all the brands in enabled and disabled state based on the UXL response
export const getCategorizedBrands = (currentFilterData: any, categorizedBrandsArray: any) => {
  const updatedCategorizedArr = categorizedBrandsArray?.map((item: any) => {
    const updatedBrandListItem = item?.brandsLabelListItems?.map((brand: any) => {
      const matchingFacet = currentFilterData?.find((facet: any) => facet?.code === brand?.brandTagId);
      if (matchingFacet) {
        return { ...brand, ...matchingFacet, isDisabled: false };
      } else {
        return { ...brand, ...matchingFacet, isDisabled: true };
      }
    });
    return { ...item, brands: updatedBrandListItem };
  });
  return updatedCategorizedArr;
};

//function to call exit points URL from Hotel directory page
export const formatExitURL = (
  destinationCode: string,
  destinationName: string,
  regionCode: string,
  isFiltersApplied: string,
  isDestinationRedirect?: boolean,
  viewAllRedirect?: boolean
) => {
  let redirectURL = '';

  //call submitsearch url
  if (!isDestinationRedirect) {
    redirectURL = addSubDirectoryPrefix(submitSearchURL);
    //call destination specific submitSearch url
    if (!viewAllRedirect) {
      if (regionCode === 'united-states') {
        //call uited-states specific submitSearch url
        redirectURL =
          redirectURL +
          destinationParamURL +
          destinationCode +
          ', USA' +
          countryParamURL +
          'US' +
          stateProvinceParamURL +
          destinationCode +
          restParamsURL +
          isFiltersApplied;
      } else {
        redirectURL =
          redirectURL +
          destinationParamURL +
          destinationName +
          countryParamURL +
          destinationCode +
          restParamsURL +
          isFiltersApplied;
      }
    } else {
      //call region specific submitSearch url
      redirectURL =
        redirectURL +
        destinationParamURL +
        regionCode +
        destinationRegionURL +
        regionCode +
        restParamsURL +
        isFiltersApplied;
    }
  } else {
    //call destination page url
    redirectURL = addSubDirectoryPrefix(destinationRedirectURL) + destinationName?.split(' ')?.join('-') + '.mi';
  }

  return redirectURL;
};

export const searchCounterValue = (
  headersData: { referer: string },
  hotelDirectoryCounter: number,
  setHotelDirectoryCounter: Dispatch<SetStateAction<number>>
) => {
  /**
   * To get and set Counter value in sessionStorage
   * and later updating hash in the url
   * */
  if (typeof window !== 'undefined') {
    if (sessionStorage.getItem('hotelDirectoryCounter')) {
      const maxValue = parseInt(sessionStorage.getItem('hotelDirectoryCounter') as string);
      /**
       * set the previous counter value with +1 on update search
       */
      sessionStorage.setItem('hotelDirectoryCounter', (maxValue + 1).toString());
      setHotelDirectoryCounter(maxValue + 1);
    } else {
      sessionStorage.setItem('hotelDirectoryCounter', `${hotelDirectoryCounter}`);
    }
    const counterValue = sessionStorage.getItem('hotelDirectoryCounter');

    // Update the window.location.hash with the update search counter
    window.history.replaceState({}, headersData?.referer, `#/${counterValue}/`);
  }
};

// Function to process filters and facets
export const processFiltersAndFacets = (obj: any, ariesSearchPayload: any) => {
  // Initialize hotelDirectoryFilterCriteria in the ariesSearchPayload if not present
  ariesSearchPayload.hotelDirectoryFilterCriteria = {
    facets: {},
    selectionOrder: [],
  };

  // Create sets to track selected dimensions and selection order items
  const selectedDimensions: Set<string> = new Set();
  const selectedSelectionOrderItems: Set<string> = new Set();

  const transformType = (type: string) => {
    return type?.toLowerCase()?.replaceAll('_', '-') || '';
  };
  // Iterate through each item in the input array 'obj'
  obj?.forEach((item: any) => {
    const { type, dimensions } = item || {};
    const typeLowerCase = transformType(type);
    if (dimensions?.length > 0 && type !== 'LEISURE_REGIONS') {
      dimensions?.forEach((dimension: string) => {
        if (
          !selectedDimensions?.has(dimension) &&
          !isDimensionInPayload(ariesSearchPayload, typeLowerCase, dimension)
        ) {
          selectedDimensions?.add(dimension);
          const facetKey = facetTypeMapping[type] || typeLowerCase;
          if (!ariesSearchPayload?.hotelDirectoryFilterCriteria?.facets[facetKey]) {
            ariesSearchPayload.hotelDirectoryFilterCriteria.facets[facetKey] = [];
          }
          //push the dimensions into the respective facets
          ariesSearchPayload?.hotelDirectoryFilterCriteria?.facets[facetKey]?.push(dimension);

          // Selection Order
          const selectionOrderItem = {
            bucketName: typeLowerCase,
            code: dimension,
            label: `${dimension} (${dimensions?.length})`,
          };
          const selectionOrderKey = `${selectionOrderItem?.bucketName}-${selectionOrderItem?.code}`;

          // Check if the selection order item has not been added already
          if (!selectedSelectionOrderItems?.has(selectionOrderKey)) {
            selectedSelectionOrderItems?.add(selectionOrderKey);
            ariesSearchPayload?.hotelDirectoryFilterCriteria?.selectionOrder?.push(selectionOrderItem);
          }
        }
      });
    }
  });

  // Return the updated ariesSearchPayload
  return ariesSearchPayload;
};

// Function to check if a dimension is in ariesSearchPayload
export const isDimensionInPayload = (payload: any, type: string, dimension: string) => {
  return (
    payload.hotelDirectoryFilterCriteria &&
    payload.hotelDirectoryFilterCriteria?.facets[type] &&
    payload.hotelDirectoryFilterCriteria?.facets[type]?.includes(dimension)
  );
};

/**
 * Function to update session data with filters and facets
 * Store the facets in propertyFilterCriteria too after interaction
 */
export const updateFiltersRelatedSessionVariables = (sessionData: any, obj: any, AriesSearchPayloadObject: any) => {
  // Process filters and facets using the input object 'obj'
  const payload = processFiltersAndFacets(obj, AriesSearchPayloadObject);

  if (sessionData) {
    // If it doesn't exist, create 'propertyFilterCriteria' and populate its facets
    payload.propertyFilterCriteria = {
      facets: payload?.hotelDirectoryFilterCriteria?.facets,
    };
  }

  // Return the updated 'payload' that includes the processed filters and facets
  return payload;
};

// function to get the logger api url
export const getLoggerApiURL = (NEXT_PUBLIC_PREFIX: string) => {
  let loggerApiURL = '/api/logger';
  if (getDeployedEnvType() === 'higher') {
    loggerApiURL = `${NEXT_PUBLIC_PREFIX}/api/logger`;
  }

  return loggerApiURL;
};

/**
 * Client side session call on filter interactions
 * @param sessionID |
 * @param SESSION_APP_GET_CALL_URL | session Get call URL
 * @param SESSION_APP_POST_CALL_URL | session post calll URL
 * @param NEXT_PUBLIC_PREFIX | variable to append for higher environment
 * @param interactions | filterPayload data after interactions
 */
export async function updateSessionProperties(
  sessionID: string,
  SESSION_APP_GET_CALL_URL: string,
  SESSION_APP_POST_CALL_URL: string,
  NEXT_PUBLIC_PREFIX: string,
  interactions: any,
  resolvedUrl: string,
  headersData: any,
  query: any,
  currentLocale: string,
  datalayerParams: any,
  datalayerProperties: any
) {
  // Define URLs and variables
  const sessionUrl: any = SESSION_APP_GET_CALL_URL;
  const postSessionUrl: any = SESSION_APP_POST_CALL_URL;
  const removeBlock: any = [];
  let response: any = {};

  try {
    // Check if interactions are provided and not empty
    if (interactions && interactions.length > 0) {
      // Send a POST request to retrieve session data
      response = await Axios.post(
        sessionUrl,
        {
          keys: SESSION_KEYS,
        },
        {
          headers: {
            Cookie: `sessionID=${sessionID}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      );

      // Extract session data from the response
      const sessionDoc = response?.data;
      const AriesSearchPayloadObject = sessionDoc?.cacheData?.data?.AriesSearch;

      // Update session variables related to filters
      updateFiltersRelatedSessionVariables(sessionDoc, interactions, AriesSearchPayloadObject);

      // Prepare data for updating the session
      const AriesUpdateSessionPayloadObject: any = {};
      AriesUpdateSessionPayloadObject['AriesSearch'] = AriesSearchPayloadObject;

      // Send a POST request to update the session
      await Axios.post(
        postSessionUrl,
        {
          createOrUpdate: AriesUpdateSessionPayloadObject,
          remove: removeBlock,
        },
        {
          headers: {
            Cookie: `sessionID=${sessionID}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      );

      // call datalayer on filter interactions
      await updateDataLayerOnInteractions(resolvedUrl, headersData, query, datalayerParams, currentLocale, sessionID);
      // call the function below to update specific datalayer properties
      updateDatalayerObjForHotelDirectory(window?.dataLayer ?? {}, interactions, datalayerProperties, sessionDoc);
    }
  } catch (error) {
    // Handle and return any errors
    const loggerApiURL = getLoggerApiURL(NEXT_PUBLIC_PREFIX);
    Axios.post(loggerApiURL, {
      message: `Error while making session call with sessionId ${sessionID}: ${JSON.stringify(error)}`,
    });
  }
}

/**
 * Function to determine the screen size's
 */
export function getScreenCategory() {
  let screenCategory = '';
  if (window.innerWidth < 240) {
    screenCategory = 'XXS';
  } else if (window.innerWidth >= 240 && window.innerWidth < 320) {
    screenCategory = 'XS';
  } else if (window.innerWidth >= 320 && window.innerWidth < 480) {
    screenCategory = 'S';
  } else if (window.innerWidth >= 480 && window.innerWidth < 560) {
    screenCategory = 'SM';
  } else if (window.innerWidth >= 560 && window.innerWidth < 672) {
    screenCategory = 'M';
  } else if (window.innerWidth >= 672 && window.innerWidth < 768) {
    screenCategory = 'MML';
  } else if (window.innerWidth >= 768 && window.innerWidth < 864) {
    screenCategory = 'ML';
  } else if (window.innerWidth >= 864 && window.innerWidth < 960) {
    screenCategory = 'MLL';
  } else if (window.innerWidth >= 960 && window.innerWidth < 1024) {
    screenCategory = 'L';
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
    screenCategory = 'XL';
  } else if (window.innerWidth >= 1200 && window.innerWidth < 1440) {
    screenCategory = 'XXL';
  } else if (window.innerWidth >= 1440) {
    screenCategory = 'XXXL';
  } else {
    screenCategory = 'Unknown';
  }
  return screenCategory;
}

/**
 * Client side datalayer call to update specific attributes of datalayer
 * @param dataLayer | datalayer object
 * @param _filtersPayload | filters payload
 * @param datalayerProperties | datalayer properties received from page context
 * @param sessionDoc | session data received from get session call
 */
export function updateDatalayerObjForHotelDirectory(
  dataLayer: Record<string, unknown>,
  _filtersPayload: any,
  datalayerProperties: any,
  sessionDoc?: any
) {
  let selectedFilters = '';

  Object.keys(_filtersPayload)?.forEach((filter: any) => {
    if (
      _filtersPayload[filter] &&
      _filtersPayload[filter]?.type !== 'LEISURE_REGIONS' &&
      Object.values(_filtersPayload[filter]?.dimensions).length > 0
    ) {
      const selectedFilter = Object.values(_filtersPayload[filter]?.dimensions).toString();
      selectedFilters = selectedFilters + selectedFilter + ',';
    }
  });
  const screenCategory = getScreenCategory();
  const deviceOrientation = datalayerProperties?.NEXT_PUBLIC_ORIENTATION_LANDSCAPE;

  selectedFilters !== ''
    ? (dataLayer['search_criteria_filter_selected_ids'] = selectedFilters?.replaceAll(',', '.'))
    : delete dataLayer['search_criteria_filter_selected_ids'];
  dataLayer[
    'page_breakpt_orientation'
  ] = `${datalayerProperties?.NEXT_PUBLIC_PAGE_BREAKPT}${screenCategory} - ${window?.innerWidth}w: ${deviceOrientation}`;
  dataLayer['ram_tile_names'] = datalayerProperties?.NEXT_PUBLIC_RAM_TILE_NAME;
  dataLayer['previous_page'] = window?.document?.referrer;
  dataLayer['page_type'] = window?.location?.href;
  dataLayer['ensightenEnabled'] = true;
  dataLayer['page_data_layer_ready'] = 'true';

  if (window.location.search !== '') {
    dataLayer['page_url_query_string'] = window.location.search.substring(1);
  }

  if (sessionDoc?.cacheData?.data?.AriesCommon?.search_is_weekend_stay !== null) {
    dataLayer['search_is_weekend_stay'] = sessionDoc?.cacheData?.data?.AriesCommon?.search_is_weekend_stay;
  } else {
    delete dataLayer['search_is_weekend_stay'];
  }

  if (sessionDoc?.cacheData?.data?.AriesCommon?.search_brands !== null) {
    dataLayer['search_brands'] = sessionDoc?.cacheData?.data?.AriesCommon?.search_brands;
  } else {
    delete dataLayer['search_brands'];
  }

  delete dataLayer['invokeFlushErrors'];
  delete dataLayer['search_currency_type'];
  delete dataLayer['search_date_check_in_day_of_week'];
  delete dataLayer['search_date_type'];
  delete dataLayer['mr_prof_address_state'];
  delete dataLayer['mr_prof_address_country'];
  delete dataLayer['mr_prof_address_city'];
  delete dataLayer['search_advance_purchase_days'];
  delete dataLayer['mr_prof_address_state_abbr'];
  delete dataLayer['mr_prof_address_country_abbr'];
  delete dataLayer['ue'];
  dataLayer['memState'] === 'unauthenticated' && delete dataLayer['mr_id_alternate'];
  delete dataLayer['env_api_tile_error_info'];
  delete dataLayer['res_rooms_per_night'];
  delete dataLayer['search_destination_city'];
  delete dataLayer['search_impression_criteria3'];
  delete dataLayer['search_previous_destination_city'];
  delete dataLayer['search_list_page_current'];
  delete dataLayer['search_list_records_total'];
  delete dataLayer['search_list_setting_sort_order'];
}

// Function to extract search query data from session data
export function getSearchQueryDataFromSession(data: any): any {
  const { AriesSearch } = data || {};

  // If 'AriesSearch' is not present, return null
  if (!AriesSearch) {
    return null;
  }

  // Initialize objects to store amenities, activities, brands, transportation types, hotel types, events, states, and cities
  const amenitiesObj: Record<string, string> = {};
  const activitiesObj: Record<string, string> = {};
  const brandsObj: Record<string, string> = {};
  const transportationTypesObj: Record<string, string> = {};
  const hotelTypesObj: Record<string, string> = {};
  const eventsObj: Record<string, string> = {};
  const statesObj: Record<string, string> = {};
  const citiesObj: Record<string, string> = {};
  const propertyTypesObj: Record<string, string> = {};

  // Extract and map amenities, activities, brands, transportation types, hotel types, events, states, and cities
  // from the 'AriesSearch.hotelDirectoryFilterCriteria.facets' object
  AriesSearch?.hotelDirectoryFilterCriteria?.facets?.amenities?.forEach((amenity: string) => {
    amenitiesObj[amenity] = amenity;
  });
  AriesSearch?.hotelDirectoryFilterCriteria?.facets?.['activities-on-site']?.forEach((activity: string) => {
    activitiesObj[activity] = activity;
  });
  AriesSearch?.hotelDirectoryFilterCriteria?.facets?.brands?.forEach((brand: string) => {
    brandsObj[brand] = brand;
  });
  AriesSearch?.hotelDirectoryFilterCriteria?.facets?.['transportation-types']?.forEach((transportationType: string) => {
    transportationTypesObj[transportationType] = transportationType;
  });
  AriesSearch?.hotelDirectoryFilterCriteria?.facets?.hotel_types?.forEach((hotelType: string) => {
    hotelTypesObj[hotelType] = hotelType;
  });
  AriesSearch?.hotelDirectoryFilterCriteria?.facets?.['property-types']?.forEach((propertyType: string) => {
    propertyTypesObj[propertyType] = propertyType;
  });
  AriesSearch?.hotelDirectoryFilterCriteria?.facets?.['meetings-and-events']?.forEach((event: string) => {
    eventsObj[event] = event;
  });
  AriesSearch?.hotelDirectoryFilterCriteria?.facets?.states?.forEach((state: string) => {
    statesObj[state] = state;
  });
  AriesSearch?.hotelDirectoryFilterCriteria?.facets?.cities?.forEach((city: string) => {
    citiesObj[city] = city;
  });

  // Create an object with the extracted data and session token
  const defaultObj = {
    activities: activitiesObj,
    amenities: amenitiesObj,
    brands: brandsObj,
    transportationTypes: transportationTypesObj,
    hotelTypes: hotelTypesObj,
    events: eventsObj,
    states: statesObj,
    cities: citiesObj,
    propertyType: propertyTypesObj,
  };

  // Return the object with search query data
  return defaultObj;
}

// function to get the stored filters from session to pass in query payload
export const transformFilterProperties = (obj: any, propertiesToProcess: any) => {
  obj &&
    propertiesToProcess?.forEach((property: any) => {
      if (obj[property]) {
        const propertyObj = obj[property];
        if (Object.keys(propertyObj)?.length > 0) {
          const propertyValues = Object.values(propertyObj);
          obj[property] = propertyValues;
        }
      }
    });
  return obj;
};

// get count of selected filters on load from session
export const getAppliedFiltersCount = (tempFilterPayload: any) => {
  let selectedFiltersCount = 0;
  tempFilterPayload?.forEach((filter: { type: string; dimensions: Array<string> }) => {
    selectedFiltersCount = selectedFiltersCount + filter?.dimensions?.length;
  });
  return selectedFiltersCount;
};

export const getRegionDataToStore = (
  regionName: RegionItems[],
  subRegionName: any,
  dataFromResponse: any,
  mexicoCode: string,
  canadaCode: string
) => {
  const regionDataToStore: HotelDirectoryDataList = [];

  regionName?.forEach((item: RegionItems) => {
    let regionParsed = false;
    let customLabel = '';
    if (item.regionCode === AFRICA_CUSTOM_LABEL) {
      customLabel = AFRICA_CUSTOM_LABEL;
    } else if (item.regionCode === ASIA_CUSTOM_LABEL) {
      customLabel = ASIA_CUSTOM_LABEL;
    } else if (item.regionCode === EUROPE_CUSTOM_LABEL) {
      customLabel = EUROPE_CUSTOM_LABEL;
    } else if (item.regionCode === MIDDLE_EAST_CODE) {
      customLabel = MIDDLE_EAST_CUSTOM_LABEL;
    } else if (item.regionCode === SOUTH_AMERICA_CODE) {
      customLabel = SOUTH_AMERICA_CUSTOM_LABEL;
    } else if (item.regionCode === CARIBBEAN_CUSTOM_LABEL) {
      customLabel = CARIBBEAN_CUSTOM_LABEL;
    } else if (item.regionCode === CENTRAL_AMERICA_CODE) {
      customLabel = CENTRAL_AMERICA_CUSTOM_LABEL;
    } else if (item.regionCode === AUSTRALIA_CODE) {
      customLabel = AUSTRALIA_CUSTOM_LABEL;
    } else if (item.regionCode === USA_CODE) {
      customLabel = USA_CUSTOM_LABEL;
    }
    subRegionName?.subRegion?.forEach((subRArray: { [x: string]: any[] }) => {
      const regionKeyName = Object.keys(subRArray)?.[0];
      if (item?.regionTagTitle === regionKeyName) {
        let count = 0;
        let regionsDataFromResponse = [];
        if (item?.regionTagTitle?.toLowerCase() === canadaCode?.toLowerCase()) {
          regionsDataFromResponse = dataFromResponse?.searchPropertiesByDestination?.facets.filter(
            (bucket: { type: { code: string } }) => {
              return bucket.type.code === 'countries';
            }
          );
          regionsDataFromResponse?.[0]?.buckets?.forEach((specificItem: { code: string; count: number }) => {
            if (specificItem?.code === CANADA_REGION_CODE) {
              regionParsed = true;
              regionDataToStore.push({
                label: item?.regionTagTitle,
                count: specificItem?.count,
                code: item?.regionCode,
                latitude: Number(item?.regionLatitude),
                longitude: Number(item?.regionLongitude),
                customLabel: CANADA_CUSTOM_LABEL,
              });
            }
          });
        } else if (item?.regionTagTitle?.toLowerCase() === mexicoCode?.toLowerCase()) {
          regionsDataFromResponse = dataFromResponse?.searchPropertiesByDestination?.facets.filter(
            (bucket: { type: { code: string } }) => {
              return bucket.type.code === 'countries';
            }
          );
          regionsDataFromResponse?.[0]?.buckets?.forEach((specificItem: { code: string; count: number }) => {
            if (specificItem?.code === MEXICO_REGION_CODE) {
              regionParsed = true;
              regionDataToStore.push({
                label: item?.regionTagTitle,
                count: specificItem?.count,
                code: item?.regionCode,
                latitude: Number(item?.regionLatitude),
                longitude: Number(item?.regionLongitude),
                customLabel: MEXICO_CUSTOM_LABEL,
              });
            }
          });
        }
        subRArray[regionKeyName]?.forEach(subRItems => {
          if (item?.regionCode === USA_CODE) {
            regionsDataFromResponse = dataFromResponse?.searchPropertiesByDestination?.facets.filter(
              (bucket: { type: { code: string } }) => {
                return bucket.type.code === 'states';
              }
            );
          } else {
            regionsDataFromResponse = dataFromResponse?.searchPropertiesByDestination?.facets.filter(
              (bucket: { type: { code: string } }) => {
                return bucket.type.code === 'countries';
              }
            );
          }
          regionsDataFromResponse?.[0]?.buckets?.forEach(
            (subRItemsFromResponse: { code: any; count: number; label: string }) => {
              if (subRItemsFromResponse?.code === subRItems?.subRegionCode) {
                count += subRItemsFromResponse?.count;
              }
            }
          );
        });
        if (
          item?.regionTagTitle?.toLowerCase() !== canadaCode?.toLowerCase() &&
          item?.regionTagTitle?.toLowerCase() !== mexicoCode?.toLowerCase()
        ) {
          regionParsed = true;
          const regionLabel = item?.regionTagTitle;

          regionDataToStore.push({
            label: regionLabel,
            count: count,
            code: item?.regionCode,
            latitude: Number(item?.regionLatitude),
            longitude: Number(item?.regionLongitude),
            customLabel: customLabel,
          });
        }
      }
    });
    if (regionParsed === false) {
      regionDataToStore.push({
        label: item?.regionTagTitle,
        count: 0,
        code: item?.regionCode,
        latitude: Number(item?.regionLatitude),
        longitude: Number(item?.regionLongitude),
        customLabel: customLabel,
      });
    }
  });
  return regionDataToStore;
};

export const changeRegionNames = (
  regionItem: HotelDirectoryDataType,
  unitedStatesLabel: string,
  australiaPILabel: string
) => {
  if (regionItem?.customLabel === USA_CUSTOM_LABEL) {
    return unitedStatesLabel;
  } else if (regionItem?.customLabel === AUSTRALIA_CUSTOM_LABEL) {
    return australiaPILabel;
  } else {
    return regionItem?.label;
  }
};

export const processFiltersFromQuery = (queryParams: any) => {
  const paramObj: any = {};
  for (const [key, value] of queryParams.entries()) {
    // Split the value by comma and trim each item
    paramObj[key] = value
      ?.replace(/\//g, '')
      ?.split(',')
      .map((item: string) => item.trim());
  }

  // Remove 'filtersApplied' from paramObj
  delete paramObj['filtersApplied'];
  // Replace keys as requested
  paramObj['activities'] = paramObj['activities-on-site'];
  delete paramObj['activities-on-site'];

  paramObj['propertyType'] = paramObj['property-types'];
  delete paramObj['property-types'];
  return paramObj;
};

export const updateFiltersPayload = (filtersSessionVal: any) => {
  const filtersPayload = [
    {
      type: SearchFacetsList?.brands,
      dimensions:
        filtersSessionVal && filtersSessionVal?.brands && Object.keys(filtersSessionVal?.brands)?.length > 0
          ? filtersSessionVal.brands
          : [],
    },
    {
      type: SearchFacetsList?.amenities,
      dimensions:
        filtersSessionVal && filtersSessionVal?.amenities && Object.keys(filtersSessionVal?.amenities)?.length > 0
          ? filtersSessionVal.amenities
          : [],
    },
    {
      type: SearchFacetsList?.propertyType,
      dimensions:
        filtersSessionVal && filtersSessionVal?.propertyType && Object.keys(filtersSessionVal?.propertyType)?.length > 0
          ? filtersSessionVal.propertyType
          : [],
    },
    {
      type: SearchFacetsList?.activities,
      dimensions:
        filtersSessionVal && filtersSessionVal?.activities && Object.keys(filtersSessionVal?.activities)?.length > 0
          ? filtersSessionVal.activities
          : [],
    },
    {
      type: SearchFacetsList?.state,
      dimensions:
        filtersSessionVal && filtersSessionVal?.state && Object.keys(filtersSessionVal?.state)?.length > 0
          ? filtersSessionVal.states
          : [],
    },
    {
      type: SearchFacetsList?.country,
      dimensions: [],
    },
    {
      type: SearchFacetsList?.events,
      dimensions:
        filtersSessionVal && filtersSessionVal?.events && Object.keys(filtersSessionVal?.events)?.length > 0
          ? filtersSessionVal.events
          : [],
    },
    {
      type: SearchFacetsList?.transportation,
      dimensions:
        filtersSessionVal &&
        filtersSessionVal?.transportationTypes &&
        Object.keys(filtersSessionVal?.transportationTypes)?.length > 0
          ? filtersSessionVal.transportationTypes
          : [],
    },
    {
      type: SearchFacetsList?.leisureRegion,
      dimensions: [],
    },
  ];
  return filtersPayload;
};

export const hasHDQueryParams = (urlParams: any, queryParamsToCheck: string[]): boolean => {
  for (const param of queryParamsToCheck) {
    if (urlParams.has(param)) {
      return true;
    }
  }
  return false;
};

//Function to process filters from query and update session
export const updateSessionObjWithQueryParams = (queryParams: any, sessionVal: any, firstLoad: boolean) => {
  if (!(queryParams instanceof URLSearchParams) || queryParams.size === 0) {
    return sessionVal; // If queryParams is empty or not an instance of URLSearchParams, return sessionVal unchanged
  }
  const paramObj: any = {};
  // Iterate over each parameter in queryParams
  for (const [key, value] of queryParams.entries()) {
    // Split the value by comma and trim each item
    paramObj[key] = value
      ?.replace(/\//g, '')
      ?.split(',')
      .map(item => item.trim());
  }

  // Remove 'filtersApplied' from paramObj
  delete paramObj['filtersApplied'];

  // Ensure 'AriesSearch' exists in sessionVal
  sessionVal['AriesSearch'] = firstLoad ? {} : sessionVal['AriesSearch'] || {};

  // Merge queryParams with existing criteria
  ['hotelDirectoryFilterCriteria', 'propertyFilterCriteria'].forEach(criteria => {
    if (!sessionVal['AriesSearch'][criteria]) {
      sessionVal['AriesSearch'][criteria] = { facets: { ...paramObj } };
    } else {
      const currentFacets = sessionVal['AriesSearch'][criteria]['facets'];
      for (const key in paramObj) {
        if (Object.prototype.hasOwnProperty.call(paramObj, key)) {
          if (!currentFacets[key]) {
            currentFacets[key] = paramObj[key];
          } else {
            const uniqueValues = new Set([...currentFacets[key], ...paramObj[key]]);
            currentFacets[key] = [...uniqueValues];
          }
        }
      }
      sessionVal['AriesSearch'][criteria]['facets'] = currentFacets;
    }
  });
  return sessionVal; // Return updated sessionVal
};
