import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '@marriott/mi-ui-library-shop';

export const StyledSearchSummaryContainerDiv = styled.div`
  ${rtl`
  &.result-summary-de{
    min-width:${toRem(100)};
    line-height:${toRem(16)}
  }
  &.results-summary{
    display:flex;
    align-items:center;
    line-height:${toRem(24)};
  }
  `}
`;
