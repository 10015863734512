import { RichText } from '@marriott/mi-ui-library';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledRnBRichText } from './RnBRichText.styles';
import { earnRedeemStore } from '../../modules/store/earnRedeemStore';
import { useEffect } from 'react';

const { NEXT_PUBLIC_AEM_SITE } = process.env;
export type RichTextProps = {
  bodyText: string;
  faqLabel?: string;
  faqEnabled?: boolean;
  faqModelHeader: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RnBRichText = (props: RichTextProps) => {
  const setShowFaqModal = earnRedeemStore((state: earnRedeemStore) => state.setShowFaqModal);
  const setFaqModelHeader = earnRedeemStore((state: earnRedeemStore) => state.setFaqModelHeader);

  const bodyText = props?.faqEnabled
    ? props?.bodyText?.replace('[]', `<span id="faq_link_click" class="faq-label-class" >${props?.faqLabel}</span>`)
    : props?.bodyText?.replace('[]', '');

  useEffect(() => {
    setFaqModelHeader(props?.faqModelHeader);
    const faqButton = document?.getElementById('faq_link_click');

    const handleModalFunction = (event: MouseEvent) => {
      event.preventDefault();
      setShowFaqModal(true);
    };

    faqButton?.addEventListener('click', handleModalFunction);
  }, []);

  return props?.bodyText ? (
    <StyledRnBRichText data-component-name="m-rnb-RnBRichText" data-testid="rnb-RnBRichText" className="container">
      <RichText componentId="terms-condition-message" customClass="t-font-m rich-text-link" text={bodyText} />
    </StyledRnBRichText>
  ) : null;
};

export const RnBRichTextConfig = {
  emptyLabel: 'RnBRichText',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/termandcondition`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RnBRichTextEditable = (props: any) => (
  <EditableComponent config={RnBRichTextConfig} {...props}>
    <RnBRichText {...props} />
  </EditableComponent>
);
