// Styles for OffersSearchResults go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { mediaQuery } from '../../utils/styles';
export const StyledOffersSearchResults = styled.div`
  .container-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  }
  .sort-by-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .offer-search-filter {
    .header-heading {
      @media ${mediaQuery.mobileOnly} {
        font-size: 18px;
      }
    }
  }
`;

export const StyledOfferSearchAllFilters = styled.div`
  &.offer-filters {
    .modal-main-container {
      .modal-footer {
        .clear {
          padding: 7px;
          &:focus {
            outline: auto;
            border: 0.125rem solid rgb(28, 28, 28);
            box-shadow: rgb(28, 28, 28) 0px 0px 0.125rem;
            padding: 7px;
            border-radius: 3px;
            }
          }
        }
      }
    }
    .brand-filter__category_label {
      span.t-label-xs {
        text-transform: uppercase;
        letter-spacing: ${toRem(1.3)};
      }
    }
    .brand-filter__category_label_btn {
      &:focus {
        outline: auto;
        }
      }
    }
    .filter-btns-wrapper {
      display: flex;
    }
    .filter-option-button {
      font-weight: 500;
      margin: 0 ${toRem(8)} 0 0;
      border: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-radius: ${toRem(8)};
      background-color: ${baseVariables.color.base20};
      padding: ${toRem(8)} ${toRem(12)};
      width: max-content;
      display: flex;
      height: ${toRem(38)};
    }
    .all-filters.custom-scrollbar {
      padding: 0 ${toRem(16)} 0 ${toRem(32)};
    }
    .brand-logo-container {
      margin-right: ${toRem(12)};
    }
    .brand-filter.px-2 {
      padding: 0 !important;
    }
    .category-filter-modal {
      .brand-filter .brand-filter__title {
        display: none;
      }
      .all-filters.custom-scrollbar {
        padding: ${toRem(36)} ${toRem(16)} 0 ${toRem(32)};
      }
    }
    .icon-dining-filter {
      margin: 0 ${toRem(5)} 0 0;
    }
    .all-filter-badge {
      height: ${toRem(20)};
      border-radius: 50%;
      background-color: ${baseVariables.color.base10};
      display: flex;
      -webkit-box-pack: center;
      place-content: center;
      -webkit-box-align: center;
      align-items: center;
      text-align: center;
      color: ${baseVariables.color.base20};
      padding: ${toRem(5)};
      width: max-content;
      min-width: ${toRem(20)};
      margin-right: ${toRem(6)};
    }
    .button.active {
      background-color: ${baseVariables.color.neutral30};
    }
    .sbo-filters {
      li,
      button {
        pointer-events: none;
      }
    }
  }
`;
