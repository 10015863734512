/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { ASPECT_RATIO, IMG_TYPE_JPG } from '../constants/OffersHeroConstants';
import {
  ASPECT_RATIO_UXL_FIELD_MAPPING,
  constants,
  IMAGE_CACHE_DOMAIN,
  PRIMARY_MEDIA,
  SECONDARY_MEDIA,
} from '../constants/CommonConstants';
import { generateI18Title, generateTitle, generateOfferUrl } from './CommonUtils';
import { useTranslation } from 'react-i18next';
import { MARRIOTT_DOMAIN_CN } from '../constants/OfferCardConstants';
import DOMPurify from 'isomorphic-dompurify';

//SEO dynamic expression pattern for AEM model values
const { MARRIOTT_DEALS, REQUIRED_ASPECT_RATIO, OFFER_IMAGE_CACHE_DOMAIN, NO_INDEX_NO_FOLLOW } = constants;

export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export const validateDetailsField = (value?: string): boolean => {
  if (value === null || value === '' || value === 'No Message Found') {
    return false;
  } else {
    return true;
  }
};

export const getFallbackImg = (url: string, imgAspectRatio: string): string => {
  const aspectRatio = imgAspectRatio.toUpperCase();
  switch (aspectRatio) {
    case 'WIDE':
      return `${url}-${ASPECT_RATIO['Wide']}${IMG_TYPE_JPG}`;

    case 'FEATURE':
      return `${url}-${ASPECT_RATIO['Feature']}${IMG_TYPE_JPG}`;

    case 'CLASSIC':
      return `${url}-${ASPECT_RATIO['Classic']}${IMG_TYPE_JPG}`;

    case 'PANO':
      return `${url}-${ASPECT_RATIO['Pano']}${IMG_TYPE_JPG}`;

    default:
      return `${url}-${ASPECT_RATIO['Square']}${IMG_TYPE_JPG}`;
  }
};

export const decodeHtml = (text: string): string => {
  if (typeof document !== 'undefined') {
    // const textArea = document.createElement('textarea');
    // textArea.value = text;
    // return textArea.value;

    //validating offers details links behavior
    const textArea = document.createElement('textarea');
    // No need to empty element as its newly created
    textArea.append(DOMPurify.sanitize(text, { RETURN_DOM_FRAGMENT: true }));
    return textArea.value;
  }
  return text;
};

export const returnDecodedString = (input: string): string => {
  return input.replaceAll('&amp;', ' &');
};

export const getLocalizedDate = (date: string, lang: string | undefined, yearText: string, OfText: string): string => {
  let day = moment(date).format('DD').trim();
  const year = moment(date).format('YYYY').trim();
  let month;
  let updatedDate = '';

  if (
    lang === 'en-US' ||
    lang === 'it-IT' ||
    lang === 'fr-FR' ||
    lang === 'de-DE' ||
    lang === 'ru-RU' ||
    lang === 'en-GB'
  ) {
    month = moment(date).format('MMMM').trim();
    if (lang === 'en-US') {
      updatedDate = `${month} ${day}, ${year}`;
    } else if (lang === 'de-DE') {
      updatedDate = `${day}. ${month} ${year}`;
    } else if (lang === 'ru-RU') {
      updatedDate = `${day} ${month} ${year} r.`;
    } else if (lang === 'it-IT' || lang === 'fr-FR' || lang === 'en-GB') {
      updatedDate = `${day} ${month} ${year}`;
    }
  } else if (lang === 'zh-CN' || lang === 'ja-JP') {
    month = moment(date).format('MMM').trim();
    updatedDate = `${year}${yearText}${month}${day}日`;
  } else if (lang === 'ko-KR') {
    day = moment(date).format('D').trim();
    month = moment(date).format('MMM').trim();
    updatedDate = `${year}${yearText} ${month} ${day}일`;
  } else if (lang === 'es-ES' || lang === 'pt-BR') {
    month = moment(date).format('MMMM').trim();
    updatedDate = `${day} ${OfText} ${month} ${OfText} ${year}`;
  }
  return updatedDate;
};

export const processDynamicProps = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const marriottDealsLocalisedValues = {
    marriottDeals: t(MARRIOTT_DEALS),
    inText: t('in'),
  };
  const seoData = props?.productSpecificResponse;
  const apiData = props.productSpecificResponse?.responseObject?.edges[0]?.node;
  const facetData = props.productSpecificResponse?.responseObject?.facets;
  const languageCodes = props?.isPreview
    ? []
    : facetData?.filter((obj: any) => obj?.type?.description === 'locale')[0]?.buckets?.map((obj: any) => obj.code);
  const titleFormat = props.model?.metaProperties?.filter(
    (obj: any) => obj.name === 'og:title' && obj?.value?.trim() !== ''
  );
  const title = titleFormat?.length
    ? generateI18Title(apiData, titleFormat[0])
    : generateTitle(apiData, marriottDealsLocalisedValues);
  const imageArr = apiData?.photos?.images ?? [];
  const image = imageArr.find((img: any) => img.aspectRatio.toUpperCase() === REQUIRED_ASPECT_RATIO);

  if (seoData?.marsha) {
    if (props?.sessionData?.cacheData?.data) {
      const sessionDataObj = props.sessionData.cacheData;
      const propHeaderObj = sessionDataObj.data;
      const ariesResObj = {
        propertyId: seoData.marsha,
        marshaRate: false,
        searchCriteriaChanged: false,
        numberOfRooms: 1,
        lengthOfStay: '1',
        rateListMenuViewType: 'rooms',
        singleDateLimit: 1728259199999,
        groupDateLimit: 1728259199999,
        availabilitySearchSourceHWS: false,
      };
      props['sessionData']['locale'] = props?.headersData[`accept-language`]
        ? props.headersData[`accept-language`].split(',')?.[0]
        : null;
      props['sessionData']['brandCode'] = props?.productSpecificResponse?.brand ?? '';
      propHeaderObj['AriesReservation'] = ariesResObj;
      sessionDataObj['data'] = propHeaderObj;
      props['sessionData']['cacheData'] = sessionDataObj;
    }
  }
  const updatedUrl: {
    url: string;
    hreflang: string;
  }[] = generateOfferUrl(
    apiData.urlTitle ?? '',
    apiData.id ?? '',
    apiData.seoNickname ?? '',
    apiData.numProperties ?? '',
    seoData?.marsha ?? '',
    [],
    seoData?.locale,
    seoData?.isPreview === 'true'
  );

  const hreflangList: {
    url: string;
    hreflang: string;
  }[] = generateOfferUrl(
    apiData.urlTitle ?? '',
    apiData.id ?? '',
    apiData.seoNickname ?? '',
    apiData.numProperties ?? '',
    seoData.marsha ?? '',
    languageCodes,
    seoData.locale,
    seoData?.isPreview === 'true'
  );

  //temp mocking seo metadata
  const metaProperties = props.model?.metaProperties ?? [
    {
      name: 'og:title',
      value: '$title',
    },
    {
      name: 'og:description',
      value: '',
    },
    {
      name: 'imageURL',
      value: '',
    },
    {
      name: 'og:url',
      value: '',
    },
    {
      name: 'og:type',
      value: 'website',
    },
    {
      name: 'og:site_name',
      value: 'Marriott International',
    },
    {
      name: 'fbAppId',
      value: '311568359040551',
    },
  ];

  const metaNames = props.model?.metaNames ?? [
    {
      name: 'keyword',
      value: '',
    },
    {
      name: 'msapplication-TileColor',
      value: '',
    },
    {
      name: 'msapplication-TileImage',
      value: '',
    },
    {
      name: 'onLoadLightboxURI',
      value: '',
    },
    {
      name: 'description',
      value: '',
    },
    {
      name: 'showLightboxOnLoad',
      value: '',
    },
    {
      name: 'robots',
      value: 'index,follow',
    },
  ];

  const mappedProperties = metaProperties.map((meta: { name: string; value: string }) => {
    if (meta.name.includes('og:title')) {
      meta.value = title;
      return meta;
    }
    if (/og:description|description/.test(meta.name)) {
      meta.value = apiData?.descriptionTeaser ?? '';
      return meta;
    }
    if (/og:image|imageURL/.test(meta.name)) {
      meta.value = `${OFFER_IMAGE_CACHE_DOMAIN}${image?.url}`;
      return meta;
    }
    if (/og:url/.test(meta.name)) {
      meta.value = updatedUrl ? updatedUrl[0]['url'] : '';
      return meta;
    }
    if (meta.value.trim() === '' && apiData[`${meta.name}`]) {
      meta.value = apiData[`${meta.name}`];
    }
    return meta;
  });

  const mappedMetaNames = metaNames.map((meta: { name: string; value: string }) => {
    //AEM send "index,follow" by default
    if (meta.name === 'robots' && !apiData.searchable) {
      meta.value = NO_INDEX_NO_FOLLOW;
      return meta;
    }
    if (/og:description|description/.test(meta.name)) {
      meta.value = apiData?.descriptionTeaser ?? '';
      return meta;
    }
    if (meta.value.trim() === '' && apiData[`${meta.name}`]) {
      meta.value = apiData[`${meta.name}`];
    }
    return meta;
  });
  const hreflangUrls = hreflangList?.map(item => ({
    href: item.url,
    hreflang: item.hreflang,
  }));
  props.model['metaProperties'] = mappedProperties;
  props.model['metaNames'] = mappedMetaNames;
  props.model['title'] = title;
  props.model['canonical'] = updatedUrl ? updatedUrl[0]['url'] : '';
  props.model['hreflangList'] = hreflangUrls;
};

export const updateDataLayer = (props: any) => {
  if (props?.dataLayer?.data[0]) {
    const dataLayer = props?.dataLayer?.data[0];
    const apiData = props?.productSpecificResponse?.responseObject?.edges[0]?.node;
    const uri = props?.productSpecificResponse?.uri ?? null;
    const partProperties = apiData?.participatingProperties;

    dataLayer['brndCode'] = props?.productSpecificResponse?.brand;
    dataLayer['hotelLoc'] = Object.keys(partProperties)?.length ? processLoc(partProperties, ',') : '';
    dataLayer['mrshaCode'] = props?.productSpecificResponse?.marsha;
    dataLayer['offer_title'] = apiData?.title;
    dataLayer['promo_single_or_multi_property'] = uri !== null ? checkOfferType(uri) : '';
    dataLayer['promo_special_offer_id'] = props?.productSpecificResponse?.offerId;

    dataLayer['prop_address_city'] = partProperties?.city;
    dataLayer['prop_address_city_state_country'] = Object.keys(partProperties)?.length
      ? processLoc(partProperties, '|')
      : '';
    dataLayer['prop_address_country_abbr'] = partProperties?.countryCode ?? '';
    dataLayer['prop_brand_code'] = props?.productSpecificResponse?.brand;
    dataLayer['prop_brand_name'] = Object.keys(partProperties)?.length ? partProperties.brandName : '';
    dataLayer['prop_marsha_code'] = props?.productSpecificResponse?.marsha;
    dataLayer['prop_name'] = partProperties?.properties[0]?.basicInformation?.name;
    dataLayer['prop_currency_type'] = partProperties?.properties[0]?.basicInformation?.currency;
    dataLayer['prop_address_state_abbr'] = partProperties?.stateCode ?? '';
    dataLayer['prop_address_state_name'] = partProperties?.stateProvince ?? '';

    dataLayer['search_cluster_code'] = apiData?.clusterCode;
    props.dataLayer.data[0] = dataLayer;
  }
};

const processLoc = (obj: any, separator: string) => {
  const locArr: string[] = [];
  ['city', 'state', 'country'].forEach(item => {
    if (obj[item] && obj[item].trim() !== '') {
      locArr.push(obj[item]);
    }
  });
  return locArr.join(separator);
};

const checkOfferType = (uri: string) => {
  if (uri.toLowerCase().indexOf('offers-spo') > -1) {
    return 'SPO_OFFER';
  }
  if (uri.toLowerCase().indexOf('offers-mpo') > -1) {
    return 'MPO_OFFER';
  }
  if (uri.toLowerCase().indexOf('offers-apo') > -1) {
    return 'ANC_OFFER';
  }
  return '';
};

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const processDomainUrl = (url: string) => {
  let modUrl = url;
  if (isValidUrl(url)) {
    if (url.includes('/offers/')) {
      modUrl = '/offers/' + url.split('/offers/').pop();
    }
  }
  return modUrl;
};

export const makeDomainSpecificContent = (content = '') => {
  // CNWEB-3177 To mitigate issue for .CN urls
  // needs to be revisited for a proper fix for all URLs
  if (content?.includes(MARRIOTT_DOMAIN_CN)) {
    return content.replace(MARRIOTT_DOMAIN_CN, '');
  }

  return content && content.includes('https://www.marriott.com')
    ? content.replace('https://www.marriott.com', '')
    : content;
};

let SUBDIRECTORY_PREFIX = '';

export const setOffersSubDirectoryPrefix = (prefix = '') => (SUBDIRECTORY_PREFIX = prefix);

export const addSubDirectoryPrefix = (url = '') =>
  SUBDIRECTORY_PREFIX &&
  url?.startsWith('/') &&
  url?.substring(1, findNthOccurence(url, 1, '/'))?.toLowerCase() !== SUBDIRECTORY_PREFIX?.toLowerCase()
    ? SUBDIRECTORY_PREFIX
      ? '/' + SUBDIRECTORY_PREFIX + url
      : url
    : url;

const findNthOccurence = (str: string, nth: number, char: string) => {
  let index = 0;
  for (let i = 0; i < nth; i += 1) {
    if (index !== -1) index = str.indexOf(char, index + 1);
  }
  return index;
};

export const setSessionKey = (key: string, value: string) => {
  if (canUseDOM) {
    sessionStorage.removeItem(key);
    sessionStorage.setItem(key, value);
  }
};

export const processAcceptLang = (locale: string) => {
  return locale ? (locale?.includes(',') ? locale?.split(',')[0] : locale?.replace('_', '-')) : locale;
};

export const checkEliteUser = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[OfferUtils ] Window Datalayer ', window?.dataLayer);
  }
  let rewardMemberLevel =
    window?.dataLayer?.['mr_id'] && window?.dataLayer?.['mr_prof_rewards_level']
      ? window.dataLayer['mr_prof_rewards_level']
      : '';
  let isEliteUser = false;
  if (rewardMemberLevel) {
    rewardMemberLevel = (rewardMemberLevel as string).split(' ')?.map((item: string) => item.trim())[0];
    isEliteUser = true;
  }
  return { isEliteUser, memberLevel: rewardMemberLevel };
};

const getPosition = (string: string, subString: string, index: number) => {
  return string.split(subString, index).join(subString).length;
};
const getImagePath = (ren: any, imagePath: string) => {
  let referencePath = ren?.renditionPath.slice(0, ren?.renditionPath.indexOf(','));
  referencePath = referencePath.includes('https') ? referencePath : `${IMAGE_CACHE_DOMAIN}${referencePath}`;
  const urlParams = new URL(referencePath).searchParams;
  const width = ren?.width && ren?.width !== 0 ? ren?.width : urlParams.get('wid');
  const height = ren?.height && ren?.height !== 0 ? ren?.height : urlParams.get('hei') ?? '';
  const fit = urlParams.get('fit');
  const rendition =
    IMAGE_CACHE_DOMAIN + imagePath + '?wid=' + width + (height ? '&hei=' + height : '') + (fit ? '&fit=' + fit : '');
  const rendition2x =
    IMAGE_CACHE_DOMAIN +
    imagePath +
    '?wid=' +
    2 * width +
    (height ? '&hei=' + 2 * height : '') +
    (fit ? '&fit=' + fit : '');
  return `${rendition} ${rendition2x} 2x`;
};

export const getMediaSource = (offersDataMedia: any, expectedSource: any, aspectRatio?: any): string => {
  //updated logic
  if (expectedSource == PRIMARY_MEDIA) {
    let primaryMatchCount = 0;
    for (let i = 0; i <= aspectRatio?.length; i++) {
      if (offersDataMedia?.primaryImage?.imageUrls?.[aspectRatio[i]]) {
        primaryMatchCount++;
      }
    }
    if (primaryMatchCount) {
      return PRIMARY_MEDIA;
    } else {
      return 'model';
    }
  } else {
    if (offersDataMedia?.secondaryImage?.imageSrc) {
      return SECONDARY_MEDIA;
    } else {
      return 'model';
    }
  }
};

export const getUXLMediaSource = (uxlMedia: any, expectedSource: string): string => {
  if (expectedSource == SECONDARY_MEDIA) {
    if (uxlMedia?.secondaryImage?.imageSrc) {
      return SECONDARY_MEDIA;
    } else if (uxlMedia?.primaryImage?.imageSrc) {
      return PRIMARY_MEDIA;
    } else {
      return 'model';
    }
  } else if (expectedSource == PRIMARY_MEDIA) {
    if (uxlMedia?.primaryImage?.imageSrc) {
      return PRIMARY_MEDIA;
    } else {
      return 'model';
    }
  } else {
    return 'model';
  }
};

export const getMediaDataSecondary = (media: any, model: any, mediaSource: string) => {
  return {
    renditions: [],
    fileReferenceBackgroundImage: mediaSource == SECONDARY_MEDIA ? media?.secondaryImage?.imageSrc : '',
    altText:
      mediaSource == PRIMARY_MEDIA
        ? media?.primaryImage?.alternateDescription
        : mediaSource == SECONDARY_MEDIA
        ? media?.secondaryImage?.alternateDescription
        : '',
    dynamic: true,
    assetPath: media?.secondaryImage?.imageSrc
      ? `${IMAGE_CACHE_DOMAIN}${media?.secondaryImage?.imageSrc}`
      : model?.assetPath,
  };
};

export const getMediaData = (media: any, model: any, mediaSource: string, expectedMedia: string) => {
  const mediaData =
    mediaSource == PRIMARY_MEDIA ? media?.primaryImage : mediaSource == SECONDARY_MEDIA ? media?.secondaryImage : null;
  const renditionsArr = [];
  let imgSrcPath = '';
  if (expectedMedia == PRIMARY_MEDIA) {
    for (let i = 0; i < model?.renditions?.length; i++) {
      const ren = model?.renditions[i];
      const aspectRatio = ren?.renditionPath?.slice(
        getPosition(ren?.renditionPath, ':', 2) + 1,
        ren?.renditionPath.indexOf('?')
      );
      const key = aspectRatio as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
      const imagePath = mediaData?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[key]];
      const renditionObj = {
        renditionPath: getImagePath(ren, imagePath),
        mediaValue: ren?.mediaValue,
        width: ren?.width,
        dynamic: ren?.dynamic,
        mediaQuery: ren?.mediaQuery,
        height: ren?.height,
        damPath: '',
      };
      renditionsArr.push(renditionObj);
    }
    for (const asptRatio in ASPECT_RATIO_UXL_FIELD_MAPPING) {
      const key = asptRatio as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
      if (mediaData?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[key]]) {
        imgSrcPath = mediaData?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[key]];
        break;
      }
    }
  }
  return {
    renditions: renditionsArr,
    fileReferenceBackgroundImage:
      mediaSource == PRIMARY_MEDIA
        ? media?.primaryImage?.imageSrc
        : mediaSource == SECONDARY_MEDIA
        ? media?.secondaryImage?.imageSrc
        : '',
    altText: PRIMARY_MEDIA
      ? media?.primaryImage?.alternateDescription
      : mediaSource == SECONDARY_MEDIA
      ? media?.secondaryImage?.alternateDescription
      : '',
    dynamic: true,
    assetPath: imgSrcPath
      ? `${IMAGE_CACHE_DOMAIN}${imgSrcPath}`
      : renditionsArr?.length
      ? renditionsArr[0]?.renditionPath?.slice(0, getPosition(renditionsArr[0]?.renditionPath, ':', 2))
      : mediaData?.imageSrc
      ? `${IMAGE_CACHE_DOMAIN}${mediaData?.imageSrc}`
      : model?.assetPath,
  };
};

export const getEdgeHost = (cookies: string) => {
  const cookieString = decodeURIComponent(cookies);
  return cookieString
    ?.match(new RegExp('PC#' + '(.*?)' + '#'))?.[1]
    ?.split('.')?.[1]
    ?.slice(0, 2);
};

export const getImageWithDomain = (imgSrc: string): string => {
  if (imgSrc) {
    return `${IMAGE_CACHE_DOMAIN + imgSrc}`;
  } else {
    return '';
  }
};

export const getParamValue = (urlParams: any, paramVal: string) => {
  return urlParams?.has(paramVal) && urlParams?.get(paramVal) !== null && urlParams?.get(paramVal) !== ''
    ? urlParams?.get(paramVal)
    : '';
};

export const getDeepLinkParams = (urlParams: any, ariesSearch?: any) => {
  let searchByGeoLoc: boolean = false;
  let searchBystate: boolean = false;
  let searchBycountry: boolean = false;
  let searchByOffersIds: boolean = false;
  let offerIds: string[] | undefined = [];
  let isEmptyOfferIds: boolean = false;
  let propertyCodes: string[] | undefined = [];
  let searchByPropertyCodes: boolean = false;
  let isEmptyPropertyCodes: boolean = false;
  let searchByStayBasedOfferFlag: boolean = false;
  let isEmptyStayBasedOfferFlag: boolean = false;
  let destinationCitySearched: string | null = '';
  let hasPlaceId: string | null = '';
  let destinationSearched: string | null = null;
  let notEligible: boolean = false;
  let invalidUser: boolean = false;
  let invalidDeal: boolean = false;
  let numAdultsPerRoom: string | null = '1';
  let numChildrenPerRoom: string | null = '0';
  let numGuestsPerRoom: string | null = '1';
  let numRooms: string | null = '1';
  notEligible = urlParams?.has('notEligible') ? true : false;
  invalidUser = urlParams?.has('invalidUserMsg') ? true : false;
  invalidDeal = urlParams?.has('invalidDealMsg') ? true : false;
  hasPlaceId = getParamValue(urlParams, 'destinationAddress.placeId');
  destinationSearched = getParamValue(urlParams, 'destinationAddress.destination');
  destinationCitySearched = getParamValue(urlParams, 'destinationAddress.city');
  searchByGeoLoc =
    (ariesSearch?.latitude && ariesSearch?.longitude) ||
    (getParamValue(urlParams, 'destinationAddress.latitude') &&
      getParamValue(urlParams, 'destinationAddress.longitude'));
  searchBystate = ariesSearch?.stateProvince || getParamValue(urlParams, 'destinationAddress.stateProvince');
  searchBycountry = ariesSearch?.country || getParamValue(urlParams, 'destinationAddress.country');
  offerIds = urlParams?.has('offers') && urlParams?.get('offers') !== '' ? urlParams?.get('offers')?.split(',') : [];
  searchByOffersIds =
    urlParams?.has('offers') && urlParams?.get('offers') !== '' && urlParams?.get('offers')?.split(',')?.length
      ? true
      : false;
  isEmptyOfferIds = urlParams?.has('offers') && urlParams?.get('offers') === '';
  propertyCodes =
    urlParams?.has('propertycodes') && urlParams?.get('propertycodes') !== ''
      ? urlParams?.get('propertycodes')?.split(',')
      : [];
  searchByPropertyCodes =
    urlParams?.has('propertycodes') &&
    urlParams?.get('propertycodes') !== '' &&
    urlParams?.get('propertycodes')?.split(',').length
      ? true
      : false;
  isEmptyPropertyCodes = urlParams?.has('propertycodes') && urlParams?.get('propertycodes') === '';
  searchByStayBasedOfferFlag = urlParams?.has('stayBasedOffer') && urlParams?.get('stayBasedOffer') == 'false';
  isEmptyStayBasedOfferFlag = urlParams?.has('stayBasedOffer') && urlParams?.get('stayBasedOffer') === '';
  numAdultsPerRoom = urlParams?.has('numAdultsPerRoom') ? urlParams?.get('numAdultsPerRoom') : '1';
  numChildrenPerRoom = urlParams?.has('childrenCount') ? urlParams?.get('childrenCount') : '0';
  numGuestsPerRoom = urlParams?.has('guestCountBox') ? urlParams?.get('guestCountBox') : '1';
  numRooms = urlParams?.has('roomCount') ? urlParams?.get('roomCount') : '1';
  const deepLinkParamObj = {
    isEmptyStayBasedOfferFlag: isEmptyStayBasedOfferFlag,
    searchByStayBasedOfferFlag: searchByStayBasedOfferFlag,
    isEmptyPropertyCodes: isEmptyPropertyCodes,
    searchByPropertyCodes: searchByPropertyCodes,
    propertyCodes: propertyCodes,
    isEmptyOfferIds: isEmptyOfferIds,
    searchByOffersIds: searchByOffersIds,
    offerIds: offerIds,
    searchBycountry: searchBycountry,
    searchBystate: searchBystate,
    searchByGeoLoc: searchByGeoLoc,
    destinationCitySearched: destinationCitySearched,
    destinationSearched: destinationSearched,
    hasPlaceId: hasPlaceId,
    notEligible: notEligible,
    invalidUser: invalidUser,
    invalidDeal: invalidDeal,
    numAdultsPerRoom: numAdultsPerRoom,
    numChildrenPerRoom: numChildrenPerRoom,
    numGuestsPerRoom: numGuestsPerRoom,
    numRooms: numRooms,
  };
  return deepLinkParamObj;
};

const brandLabelMappings: Record<string, string> = {
  luxuryBrands: 'offersSelectAllLuxuryBrands',
  premiumBrands: 'offersSelectAllPremiumBrands',
  selectBrands: 'offersSelectAllSelectBrands',
  longerStayBrands: 'offersSelectAllLongerStaysBrands',
};
export const getBrandCategorySelectAllLabel = (category: string): string => {
  return brandLabelMappings[category] || '';
};

export const getControlsData = (currentFilterData: any) => {
  const desiredKeys = ['code', 'count', 'label', 'description'];
  const updatedFilterOptions = currentFilterData?.map((obj: any) => {
    const newFilterOptionsObj: any = {};
    desiredKeys?.forEach(key => {
      newFilterOptionsObj[key] = obj[key];
    });
    const modifiedLabel = obj?.description;
    return { ...newFilterOptionsObj, label: modifiedLabel };
  });
  return updatedFilterOptions;
};
