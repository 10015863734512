import React, { useEffect, useState } from 'react';
import { Text, Eyebrow, Heading, Types, Image, Icon, Button } from '@marriott/mi-ui-library';
import { CardStaticProps } from './CardStatic.types';
import { useGetBreakpoint } from '../../../../src/modules/utils/helpers';
import { StyledCardStatic } from './CardStatic.styles';
import { StaticAriticleDescriptionLimit } from '../../../modules/utils/constants/constants';
import { TRACKING_CONSTANT } from '../../../modules/utils/constants';

export const CardStatic: React.FC<CardStaticProps> = ({
  eyebrow,
  imgAltText,
  dynamicMedia,
  title,
  iconPathMinuteRead,
  minuteRead,
  description,
  ctaLinkText,
  openInNewTab,
  ctaLink,
}) => {
  const [breakpoint, setBreakpoint] = useState(useGetBreakpoint());
  const { CITY_ARTICLE_COMPONENT, CITY_ARTICLE_CARD_BUTTON, EXTERNAL_LINK } = TRACKING_CONSTANT;

  // Reset breakpoint on window resize.
  useEffect(() => {
    const handleResize = () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setBreakpoint(useGetBreakpoint());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updatedDescription =
    description?.length >= StaticAriticleDescriptionLimit
      ? description?.slice(0, StaticAriticleDescriptionLimit) + ' [...]'
      : description;
  return (
    <StyledCardStatic className="container ">
      <div className="card-static-container py-xl-5 d-flex flex-column flex-md-row flex-lg-row flex-xl-row ">
        <div className="pb-3 pt-5 pt-lg-4 pt-xl-4 px-0 px-md-4 px-lg-4 px-xl-4 d-md-none">
          {eyebrow && <Eyebrow text={eyebrow} />}
        </div>
        <div className="col-12 col-sm-12 col-md-5 static-article-left-container d-flex justify-content-start justify-content-md-start flex-wrap align-items-center">
          <Image
            title="Article-image"
            defaultImageURL={dynamicMedia?.assetPath}
            altText={imgAltText}
            customClass=""
            renditions={dynamicMedia?.renditions}
            dynamic={dynamicMedia?.dynamic}
          />
        </div>
        <div className="static-article-right-container col-md-7 px-0 py-4 px-sm-0 px-md-5 py-md-4 p-lg-5 p-xl-5">
          <div className="pb-1 d-none d-md-block">{eyebrow && <Eyebrow text={eyebrow} />}</div>
          <Heading
            variation={Types.headingType.subtitle}
            titleText={title}
            element={Types.tags.h2}
            customClass="pt-1"
            fontSize={
              breakpoint === 'desktop'
                ? Types.size.extraLarge
                : breakpoint === 'tablet'
                ? Types.size.large
                : Types.size.medium
            }
          ></Heading>

          <div className="d-flex flex-row align-items-center">
            <Icon iconClass={iconPathMinuteRead} />
            <Text
              customClass="t-font-alt-s p-1 pb-1 p-md-0 ml-1"
              fontSize={Types.size.small}
              element={Types.tags.paragraph}
              copyText={minuteRead}
            />
          </div>
          <Text
            customClass="pt-2 pt-md-3"
            fontSize={Types.size.small}
            element={Types.tags.paragraph}
            copyText={updatedDescription}
          />

          <Button
            isLink={openInNewTab}
            href={ctaLink}
            target="_blank noreferror"
            className="m-link-tertiary-button pt-3 pb-2 pt-md-5"
            children={ctaLinkText}
            custom_click_track_value={`${CITY_ARTICLE_COMPONENT}|${CITY_ARTICLE_CARD_BUTTON}|${EXTERNAL_LINK}`}
          />
        </div>
      </div>
    </StyledCardStatic>
  );
};
