export const CarouselUXLMockJson = {
  searchPropertiesByKeyword: {
    total: 3,
    status: [
      {
        code: 'SUCCESS',
        httpStatus: 200,
        messages: [],
      },
    ],
    edges: [
      {
        node: {
          id: 'ABINE',
          basicInformation: {
            bookable: true,
            name: 'Courtyard Abilene Northeast',
            legalName: 'Courtyard® by Marriott® Abilene Northeast',
            brand: {
              id: 'CY',
              name: 'Courtyard',
            },
            resort: null,
            isAdultsOnly: null,
            newLobby: null,
            hasRenovatedRooms: null,
            isFullyRenovated: null,
            isRecentlyRenovated: null,
            newProperty: null,
          },
          contactInformation: {
            address: {
              city: 'Abilene',
              stateProvince: {
                code: 'TX',
                label: null,
                description: 'Texas',
              },
              country: {
                code: '1',
                label: null,
                description: 'USA',
              },
            },
          },
          media: {
            id: 'ABINE',
            primaryImage: {
              total: 1,
              edges: [
                {
                  node: {
                    caption: 'The Bistro Seating',
                    imageUrls: {
                      featureVertical:
                        '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Feature-Ver?wid=365&fit=constrain',
                      classicVertical:
                        '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Classic-Ver?wid=365&fit=constrain',
                      square: '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Square?wid=365&fit=constrain',
                    },
                    isPrimary: true,
                    title: 'The Bistro Seating 28212',
                  },
                },
              ],
            },
          },
          seoNickname: 'abine-courtyard-abilene-northeast',
        },
      },
      {
        node: {
          id: 'ABITS',
          basicInformation: {
            bookable: true,
            name: 'TownePlace Suites Abilene Northeast',
            legalName: 'TownePlace Suites by Marriott® Abilene Northeast',
            brand: {
              id: 'TP',
              name: 'TownePlace Suites',
            },
            resort: null,
            isAdultsOnly: null,
            newLobby: null,
            hasRenovatedRooms: null,
            isFullyRenovated: null,
            isRecentlyRenovated: null,
            newProperty: null,
          },
          contactInformation: {
            address: {
              city: 'Abilene',
              stateProvince: {
                code: 'TX',
                label: null,
                description: 'Texas',
              },
              country: {
                code: '1',
                label: null,
                description: 'USA',
              },
            },
          },
          media: {
            id: 'ABITS',
            primaryImage: {
              total: 1,
              edges: [
                {
                  node: {
                    caption: 'Communal Lounge',
                    imageUrls: {
                      featureVertical:
                        '/is/image/marriotts7prod/ts-abits-hotel-lobby-16703:Feature-Ver?wid=365&fit=constrain',
                      classicVertical:
                        '/is/image/marriotts7prod/ts-abits-hotel-lobby-16703:Classic-Ver?wid=365&fit=constrain',
                      square: '/is/image/marriotts7prod/ts-abits-hotel-lobby-16703:Square?wid=365&fit=constrain',
                    },
                    isPrimary: true,
                    title: 'Hotel Lobby-16703',
                  },
                },
              ],
            },
          },
          seoNickname: 'abits-towneplace-suites-abilene-northeast',
        },
      },
      {
        node: {
          id: 'ABINE',
          basicInformation: {
            bookable: true,
            name: 'Courtyard Abilene Northeast',
            legalName: 'Courtyard® by Marriott® Abilene Northeast',
            brand: {
              id: 'CY',
              name: 'Courtyard',
            },
            resort: null,
            isAdultsOnly: null,
            newLobby: null,
            hasRenovatedRooms: null,
            isFullyRenovated: null,
            isRecentlyRenovated: null,
            newProperty: null,
          },
          contactInformation: {
            address: {
              city: 'Abilene',
              stateProvince: {
                code: 'TX',
                label: null,
                description: 'Texas',
              },
              country: {
                code: '1',
                label: null,
                description: 'USA',
              },
            },
          },
          media: {
            id: 'ABINE',
            primaryImage: {
              total: 1,
              edges: [
                {
                  node: {
                    caption: 'The Bistro Seating',
                    imageUrls: {
                      featureVertical:
                        '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Feature-Ver?wid=365&fit=constrain',
                      classicVertical:
                        '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Classic-Ver?wid=365&fit=constrain',
                      square: '/is/image/marriotts7prod/cy-abine-the-bistro-seating-28212:Square?wid=365&fit=constrain',
                    },
                    isPrimary: true,
                    title: 'The Bistro Seating 28212',
                  },
                },
              ],
            },
          },
          seoNickname: 'abine-courtyard-abilene-northeast',
        },
      },
    ],
  },
};
