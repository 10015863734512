// Styles for HtmlSitemap go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledHtmlSitemap = styled.div.attrs({
  className: 'sitemapWrapper',
})`
  font-weight: 700;

  .listViewWrapper,
  .groupViewWrapper {
    .lb__wrapper {
      padding: 1.5rem 0 2rem;
    }
    .cl__item-link {
      text-decoration: underline;
    }
    .icon-arrow-right::before,
    .icon-arrow-right-external::before {
      display: none;
    }
  }

  .breadcrumbWrapper {
    .cl__list-item:last-child {
      a {
        margin-bottom: 0;
      }
    }
    a {
      padding-right: 1.5rem;
      @media only screen and (max-width: 767px) {
        padding-right: 0.5rem;
      }
      .icon-arrow-right:last-child {
        display: none;
      }
    }
  }
`;

export const StyledBreadcrumbContainer = styled.div.attrs({
  className: 'breadcrumbWrapper pt-5',
})`
  border-bottom: ${toRem(1)} solid ${baseVariables.color['merch30']};
  font-size: 1.5rem;
  .subTitle {
    font-size: 1.875rem;
    margin: 0;

    @media only screen and (max-width: 767px) {
      font-size: 1.125rem;
    }
  }
  a {
    font-weight: 500;
    font-size: 1.2rem;
    @media only screen and (max-width: 767px) {
      font-size: 1.125rem;
    }
  }
  .cl__list-item:last-child {
    a {
      color: ${baseVariables.color['neutral40']};
      margin-bottom: 0;
    }
    .cl__item-link:hover {
      text-decoration: none;
    }

    .icon-arrow-right {
      display: none;
    }
  }
  a.cl__item-link {
    .icon-arrow-right {
      margin: -0.1rem 1rem 0;
    }
    @media only screen and (max-width: 767px) {
      .icon-arrow-right {
        margin: -0.1rem 0.5rem 0;
      }
    }
  }
`;

export const StyledListViewContainer = styled.div.attrs({
  className: 'listViewWrapper',
})``;

export const StyledGroupViewContainer = styled.div.attrs({
  className: 'groupViewWrapper pt-5 pb-0',
})`
  font-size: 1.5rem;
  .brandTierTitle {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.375rem;
    @media only screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }
  .lb__wrapper {
    flex-wrap: wrap;

    .lb {
      border-right: none;
      margin-bottom: 0.5rem;
      padding: 0;

      .lb__container {
        padding-left: 0;
        @media only screen and (max-width: 767px) {
          .t-subtitle-l {
            font-size: 1rem;
          }
        }
      }
    }
  }
`;
