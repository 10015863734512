import clsx from 'clsx';
import React, { useState } from 'react';

export interface ratingCompProps {
  ratings?: number;
}

const Rating: React.FC<ratingCompProps> = ({ ratings }) => {
  const [afterDecimal] = useState(ratings ? (ratings * 10) % 10 : 0);

  return (
    <div className="circle-rating">
      <span
        className={clsx(
          'circle-rating__circle-inner-rating',
          ratings && ratings >= 1 ? 'full-star' : '',
          ratings && ratings >= 0 && ratings < 1 ? `star-${afterDecimal}` : ''
        )}
      ></span>
      <span
        className={clsx(
          'circle-rating__circle-inner-rating',
          ratings && ratings >= 2 ? 'full-star' : '',
          ratings && ratings >= 1 && ratings < 2 ? `star-${afterDecimal}` : ''
        )}
      ></span>
      <span
        className={clsx(
          'circle-rating__circle-inner-rating',
          ratings && ratings >= 3 ? 'full-star' : '',
          ratings && ratings >= 2 && ratings < 3 ? `star-${afterDecimal}` : ''
        )}
      ></span>
      <span
        className={clsx(
          'circle-rating__circle-inner-rating',
          ratings && ratings >= 4 ? 'full-star' : '',
          ratings && ratings >= 3 && ratings < 4 ? `star-${afterDecimal}` : ''
        )}
      ></span>
      <span
        className={clsx(
          'circle-rating__circle-inner-rating',
          ratings && ratings >= 5 ? ' full-star ' : '',
          ratings && ratings >= 4 && ratings < 5 ? `star-${afterDecimal}` : ''
        )}
      ></span>
    </div>
  );
};

export default Rating;
