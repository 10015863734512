import DOMPurify from 'isomorphic-dompurify';
import { domain } from '../constants/ApplicationConstants';

export const generateOfferUrl = (
  urlTitle: string,
  id: string,
  seoNickname: string,
  numProperties: string,
  language: string,
  marshaCode: string
): string => {
  if (numProperties && parseInt(numProperties) > 1) {
    return `${domain}/${language}/offers/${urlTitle}-${id}`;
  } else {
    return generateOfferDetailUrl(urlTitle, id, seoNickname, language, marshaCode);
  }
};

export const generateOfferDetailUrl = (
  urlTitle: string,
  id: string,
  seoNickname: string,
  language: string,
  marshaCode: string
): string => {
  return `${domain}/${language}/offers/${urlTitle}-${id}/${marshaCode}-${seoNickname}`;
};

export const decodeHtml = (text: string): string => {
  // console.log('Encoded String', text); // eslint-disable-line no-console
  const textArea = document.createElement('textarea');
  // No need to empty element as its newly created
  textArea.append(DOMPurify.sanitize(text, { RETURN_DOM_FRAGMENT: true }));
  // console.log('Decoded String', textArea.value); // eslint-disable-line no-console
  return textArea.value;
};

export const validateDetailsField = (value?: string): boolean => {
  if (value === null || value === '' || value === 'No Message Found') {
    return false;
  } else {
    return true;
  }
};

export const getUrlParam = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const deepLinkParam = ['selectedCity', 'selectedStateProvince', 'selectedCountry'];
  const deepLinkParamVal = [];
  let onloadInput = '';

  for (let i = 0; i < deepLinkParam.length; i++) {
    const value = urlParams
      .get(deepLinkParam[i])
      ?.replace('.', ' ')
      ?.replace(/\w+/g, function (w) {
        return (
          w[0].toUpperCase() +
          (deepLinkParam[i] === 'selectedCountry' || deepLinkParam[i] === 'selectedStateProvince'
            ? w.slice(1)
            : w.slice(1)?.toLowerCase())
        );
      });
    value && deepLinkParamVal.push(value);
  }
  for (let i = 0; i < deepLinkParamVal.length; i++) {
    onloadInput += `${deepLinkParamVal[i] ?? ''}${deepLinkParamVal[i + 1] ? ', ' : ''}`;
  }
  return onloadInput;
};
