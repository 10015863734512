/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Heading, Button, Icon } from '@marriott/mi-ui-library';
import { Types } from '@marriott/mi-ui-library';
import { BrandFilterProps, BrandCategoryProps, BrandProps } from './BrandFilter.types';
import { StyledBrandFilter, StyledBrandsList } from './BrandFilter.styles';
import { useCheckBreakpoint } from '@marriott/mi-ui-library';

const renderBrand = (
  brand: BrandProps,
  index: number,
  updateBrandSelection: (brand: BrandProps) => void,
  isSelected: boolean,
  custom_click_track_value?: string,
  clickTrackingVal?: string,
  clickTrackingLoc?: string,
  isTabbedSearchForm?: boolean
) => {
  const controlId = `${brand?.brandTagTitle?.replace(/ /g, '')}-${index}`;

  return (
    <li key={index} className={clsx('px-0', isTabbedSearchForm ? 'adv-search-brand-list' : '')}>
      <div
        role="checkbox"
        id={controlId}
        aria-checked={isSelected}
        onClick={() => updateBrandSelection(brand)}
        onKeyDown={ev => {
          if (ev.key === 'Enter' || ev.type === 'click') {
            updateBrandSelection(brand);
          }
        }}
        tabIndex={brand?.isDisabled ? -1 : 0}
        className={clsx(
          't-font-m',
          'brand-logo-container',
          'custom_click_track',
          'p-0',
          'd-flex align-items-center justify-content-center',
          brand?.isDisabled ? 'disabled' : '',
          isSelected && !brand?.isDisabled ? 'active' : '',
          isTabbedSearchForm ? 'adv-search-brand-pill' : ''
        )}
        data-testid={controlId}
        custom_click_track_value={
          custom_click_track_value
            ? custom_click_track_value
            : clickTrackingLoc &&
              `${clickTrackingLoc}| ${clickTrackingVal} ${brand?.brandTagTitle} ${
                isSelected ? 'selection' : 'de-selection'
              } | internal`
        }
      >
        <Icon
          iconClass={clsx(
            'brand-logo',
            brand?.brandTagId?.includes('portfolio') ? brand.brandTagId : `brand-logo-${brand.brandTagId}`,
            brand?.isDisabled ? 'brand-logo-disabled' : ''
          )}
        ></Icon>
        <span className="sr-only">{brand.brandTagTitle}</span>
      </div>
    </li>
  );
};

const renderBrandCategory = (
  category: BrandCategoryProps,
  index: number,
  updateBrandSelection: (brand: BrandProps) => void,
  updateAllBrandsSelection: (brands: BrandProps[], clear: boolean) => void,
  selectedBrands: BrandProps[],
  isHotelBrandSelectedFlag?: boolean,
  custom_click_track_value?: string,
  clickTrackingVal?: string,
  clickTrackingLoc?: string,
  isTabbedSearchForm?: boolean,
  categoryLabelFontClass?: string,
  hideLabelSeperator?: boolean
) => {
  const selectedCategoryBrands = selectedBrands.filter(
    brand => category?.brands?.findIndex(item => brand.brandTagId === item.brandTagId) !== -1
  );
  const isAllBrandsBtnSelected = selectedCategoryBrands?.length === category?.brands?.length;
  const disabledBrands = category?.brands?.filter(brand => brand.isDisabled) ?? [];
  const isAllBrandsBtnDisabled = disabledBrands?.length ? true : false;
  // get a list of all unique brands
  let uniqueBrandsForHotel = [...selectedCategoryBrands, ...disabledBrands];
  const isUnique = (value: any, index: number, self: any) => {
    return self?.findIndex((obj: any) => obj?.brandTagId === value?.brandTagId) === index;
  };
  uniqueBrandsForHotel = uniqueBrandsForHotel?.filter(isUnique);
  // to check if all the brands are selected of a particular category
  const isAllBrandsBtnSelectedForHotel = uniqueBrandsForHotel?.length === category?.brands?.length;
  // to check if all the brands are disabled of a particular category
  const isAllBrandsBtnDisabledForHotel = disabledBrands?.length === category?.brands?.length ? true : false;
  const btnLabel = isHotelBrandSelectedFlag
    ? isAllBrandsBtnSelectedForHotel
      ? category?.categoryClearLabel
      : category?.categorySelectLabel
    : isAllBrandsBtnSelected
    ? category?.categoryClearLabel
    : category?.categorySelectLabel;
  return (
    <div className="brand-filter__category" key={index}>
      <fieldset>
        <legend className={clsx('mb-3', ' brand-filter__category_label')}>
          <span
            className={clsx(
              categoryLabelFontClass,
              !hideLabelSeperator && 'label-seperator',
              'brand-filter__category_label_title',
              isTabbedSearchForm ? 'pr-2 adv-search-brand-filter-label' : ''
            )}
          >
            {category.categoryTitle}
          </span>
          <Button
            title={btnLabel}
            className={clsx(
              't-font-xs brand-filter__category_label_btn custom_click_track',
              isHotelBrandSelectedFlag
                ? isAllBrandsBtnDisabledForHotel
                  ? 'disabled'
                  : ''
                : isAllBrandsBtnDisabled
                ? 'disabled'
                : '',
              isTabbedSearchForm ? 'pl-2 t-font-weight-m tab-focus' : ''
            )}
            isDisabled={isHotelBrandSelectedFlag ? isAllBrandsBtnDisabledForHotel : isAllBrandsBtnDisabled}
            callback={() =>
              updateAllBrandsSelection(
                category?.brands,
                isHotelBrandSelectedFlag ? isAllBrandsBtnSelectedForHotel : isAllBrandsBtnSelected
              )
            }
            custom_click_track_value={
              clickTrackingLoc &&
              `${clickTrackingLoc} | ${
                isAllBrandsBtnSelectedForHotel ? category?.categorySelectLabel : category?.categoryClearLabel
              } | internal `
            }
          >
            {isTabbedSearchForm && <div className="btn-label-container">{btnLabel || ''}</div>}
            {!isTabbedSearchForm && <>{btnLabel || ''}</>}
          </Button>
        </legend>
        <StyledBrandsList
          className={clsx(
            'brand-filter__category_list col-12 d-flex flex-wrap flex-row p-0',
            isTabbedSearchForm ? 'tabbed-search-form-brand-category-list' : ''
          )}
        >
          {category?.brands?.map((brand, index) => {
            const isSelected = selectedBrands.some(selectedbrand => selectedbrand.brandTagId === brand.brandTagId);
            return renderBrand(
              brand,
              index,
              updateBrandSelection,
              isSelected,
              custom_click_track_value,
              clickTrackingVal,
              clickTrackingLoc,
              isTabbedSearchForm
            );
          })}
        </StyledBrandsList>
      </fieldset>
    </div>
  );
};

export const BrandFilter: React.FC<BrandFilterProps> = ({
  title,
  showSeparator,
  brandCategories,
  defaultSelected,
  onChange,
  isHotelBrandSelectedFlag,
  custom_click_track_value = '',
  clickTrackingVal = '',
  clickTrackingLoc = '',
  isTabbedSearchForm = false,
  shouldSelectionsBeReset = false,
  categoryLabelFontClass = 't-label-xs',
  hideLabelSeperator = false,
}) => {
  const [selectedBrands, setSelectedBrands] = useState<BrandProps[]>(defaultSelected);
  const isTabletView = useCheckBreakpoint('viewportM');
  const isDesktopView = useCheckBreakpoint('viewportL');

  useEffect(() => {
    if (selectedBrands?.length !== defaultSelected?.length) {
      setSelectedBrands(defaultSelected);
    }
  }, [shouldSelectionsBeReset]);

  useEffect(() => {
    setSelectedBrands(defaultSelected);
  }, [defaultSelected]);

  const updateBrandSelection = (brand: BrandProps) => {
    let updatedSelection: BrandProps[] = [];
    const isAvailable = selectedBrands.some(selectedbrand => selectedbrand.brandTagId === brand.brandTagId);
    if (isAvailable) {
      updatedSelection = selectedBrands.filter(selectedbrand => selectedbrand.brandTagId !== brand.brandTagId);
    } else {
      updatedSelection.push(...selectedBrands, brand);
    }
    setSelectedBrands(updatedSelection);
    onChange(updatedSelection);
  };

  const updateAllBrandsSelection = (brands: BrandProps[], clear: boolean) => {
    let updatedSelection: BrandProps[] = [];

    if (!clear) {
      updatedSelection.push(...selectedBrands, ...brands);
    } else {
      updatedSelection = selectedBrands.filter(
        brand => brands?.findIndex(item => brand.brandTagId === item.brandTagId) === -1
      );
    }
    setSelectedBrands(updatedSelection);
    onChange(updatedSelection);
  };

  return (
    <StyledBrandFilter
      data-component-name="m-shop-BrandFilter"
      data-testid="shop-BrandFilter"
      className={clsx(
        'brand-filter',
        isTabbedSearchForm
          ? isDesktopView
            ? 'col-12 pl-3 py-3 tabbed-searchform-brand-filters'
            : 'tabbed-searchform-brand-filters px-0'
          : isTabletView
          ? 'col-12 px-2'
          : ''
      )}
    >
      {title && (
        <Heading
          variation={Types.headingType.subtitle}
          titleText={title}
          fontSize={Types.size.large}
          element={Types.tags.h3}
          customClass={'brand-filter__title my-4 mt-md-5 mb-md-4 mt-lg-5 mb-lg-4 mt-xl-5 mb-xl-4'}
        ></Heading>
      )}
      {brandCategories?.map((category, index) => {
        return renderBrandCategory(
          category,
          index,
          updateBrandSelection,
          updateAllBrandsSelection,
          selectedBrands,
          isHotelBrandSelectedFlag,
          custom_click_track_value,
          clickTrackingVal,
          clickTrackingLoc,
          isTabbedSearchForm,
          categoryLabelFontClass,
          hideLabelSeperator
        );
      })}
      {showSeparator && <hr aria-hidden className="filter-separator mt-4 mt-md-5 mt-lg-5 mt-xl-5" />}
    </StyledBrandFilter>
  );
};
