export const RESORT_VIEW_GRID = 'GRID_VIEW';
export const RESORT_VIEW_GALLERY = 'CAROUSEL_VIEW';
export const NO_OF_CARDS_TABLET = 2;
export const RESORT_CAROUSEL_URL = 'https://www.marriott.com/resorts.mi';
export const RESORT_CAROUSEL_ERROR_POLICY = 'ignore';
export const IMAGE_ALT_TEXT = 'Sunrise over the clouds';
export const IMPRESSION_TRACK = false;
export const CLICK_TRACK = false;
export const MERCHANDISING_CATEGORY = 'nonCobrand';
export const DEFAULT_API_ACCEPT_LANGUAGE = 'en-US';
export const LEISURE_SEARCH_CLIENT_NAME = 'phoenix_leisure';
export const CARD_HORIZONTAL = 'horizontalFeatureList';
export const CARD_SQUARE = 'layeredSquare';
export const CARD_TALL = 'layeredTall';
export const IMAGE_CACHE_DOMAIN = 'https://cache.marriott.com';
export const RESORT_CONTENT_ROOT = '/content/mi-aem-leisure-spa/na/en-us/themes';
export const OFFER_CAUROSEL_DEFAULT_IMAGE = '/marriottassets/marriott/Stock/stock-woman-sunset-1069700-sq.jpg';
