export const OfferSearchResultUXLMockJson = {
  offersSearch: {
    total: 672,
    edges: [
      {
        node: {
          id: 'OFF-82669',
          title: 'Topgolf Swing Suite',
          descriptionTeaser: 'Topgolf Swing Suite is for sports fans and novices a like',
          memberLevel: [],
          url: 'https://www.marriott.com/offers/stop-by-the-topgolf-swing-suite-off-82669/mhtch-courtyard-boston-andover',
          numProperties: 1,
          participatingProperties: {
            properties: [
              {
                basicInformation: {
                  name: 'Courtyard Boston Andover',
                  __typename: 'HotelBasicInformation',
                },
                __typename: 'Hotel',
              },
            ],
            __typename: 'ParticipatingProperties',
          },
          photos: {
            images: [
              {
                url: '/is/image/marriotts7prod/cy-mhtch-topgolf-swing-suite-37166:Classic-Hor',
                aspectRatio: 'Classic',
                __typename: 'AdtImage',
              },
              {
                url: '/is/image/marriotts7prod/cy-mhtch-topgolf-swing-suite-37166:Feature-Hor',
                aspectRatio: 'Feature',
                __typename: 'AdtImage',
              },
              {
                url: '/is/image/marriotts7prod/cy-mhtch-topgolf-swing-suite-37166:Pano-Hor',
                aspectRatio: 'Pano',
                __typename: 'AdtImage',
              },
              {
                url: '/is/image/marriotts7prod/cy-mhtch-topgolf-swing-suite-37166:Square',
                aspectRatio: 'Square',
                __typename: 'AdtImage',
              },
              {
                url: '/is/image/marriotts7prod/cy-mhtch-topgolf-swing-suite-37166:Wide-Hor',
                aspectRatio: 'Wide',
                __typename: 'AdtImage',
              },
            ],
            captions: [
              {
                length: 'short',
                caption: 'Corporate outing with cocktails, food, and fun',
                __typename: 'AdtImageCaption',
              },
              {
                length: 'medium',
                caption: 'Topgolf Swing Suite',
                __typename: 'AdtImageCaption',
              },
              {
                length: 'large',
                caption:
                  "Designed to enhance gatherings with a massive screen and a selection of exciting virtual games, the suites deliver a one-of-a-kind simulation that's fun for golfers and non-golfers alike.",
                __typename: 'AdtImageCaption',
              },
            ],
            __typename: 'AdtImages',
          },
          callToActionLabel: 'Book your Topgolf Swing Suite ahead of time',
          callToActionLink: 'https://courtyardandover.247activities.com/',
          cardLabel: null,
          media: {
            primaryImage: {
              caption: null,
              id: null,
              imageSrc: null,
              imageUrls: null,
              title: null,
              __typename: 'OfferImage',
            },
            __typename: 'OfferMedia',
          },
          offerCategory: null,
          offerStatus: 'In Market',
          offerType: 'Ancillary Product Offer',
          seoTitle: 'Topgolf Swing Suite',
          urlTitle: 'stop-by-the-topgolf-swing-suite',
          parentOfferType: 'Non Stay Based Offer',
          __typename: 'Offer',
        },
        __typename: 'OffersSearchEdge',
      },
      {
        node: {
          id: 'OFF-121280',
          title: "Valentine's Day Dinner",
          descriptionTeaser: 'Enjoy a romantic night at our restaurant, O Lagar',
          memberLevel: [],
          url: 'https://www.marriott.com/offers/valentine-s-day-in-santos-off-121280/saoss-sheraton-santos-hotel',
          numProperties: 1,
          participatingProperties: {
            properties: [
              {
                basicInformation: {
                  name: 'Sheraton Santos Hotel',
                  __typename: 'HotelBasicInformation',
                },
                __typename: 'Hotel',
              },
            ],
            __typename: 'ParticipatingProperties',
          },
          photos: {
            images: [
              {
                url: '/marriottassets/marriott/Stock/stock-brand-278661-hor-wide.jpg',
                aspectRatio: 'Wide',
                __typename: 'AdtImage',
              },
              {
                url: '/marriottassets/marriott/Stock/stock-brand-278661-hor-feat.jpg',
                aspectRatio: 'Feature',
                __typename: 'AdtImage',
              },
              {
                url: '/marriottassets/marriott/Stock/stock-brand-278661-hor-clsc.jpg',
                aspectRatio: 'Classic',
                __typename: 'AdtImage',
              },
              {
                url: '/marriottassets/marriott/Stock/stock-brand-278661-hor-pano.jpg',
                aspectRatio: 'Pano',
                __typename: 'AdtImage',
              },
              {
                url: '/marriottassets/marriott/Stock/stock-brand-278661-sq.jpg',
                aspectRatio: 'Square',
                __typename: 'AdtImage',
              },
            ],
            captions: [
              {
                length: 'short',
                caption: 'Couple',
                __typename: 'AdtImageCaption',
              },
              {
                length: 'medium',
                caption: 'Couple',
                __typename: 'AdtImageCaption',
              },
              {
                length: 'large',
                caption:
                  '97970724 Dinner Restaurant Dating Date Night Romance Celebratory Toast Fine Dining Night Anniversary Champagne Eat Smiling Love Well-dressed Enjoy Retail Bonding Boyfriend Cape Town Drink Food Drink Girlfriend Happiness Hetero Couple Hungry Indoor Leisure Activity Listen South Africa Talk Together',
                __typename: 'AdtImageCaption',
              },
            ],
            __typename: 'AdtImages',
          },
          callToActionLabel: 'Reservations',
          callToActionLink: 'tel:+551332781416',
          cardLabel: null,
          media: {
            primaryImage: {
              caption: null,
              id: null,
              imageSrc: null,
              imageUrls: null,
              title: null,
              __typename: 'OfferImage',
            },
            __typename: 'OfferMedia',
          },
          offerCategory: null,
          offerStatus: 'In Market',
          offerType: 'Ancillary Product Offer',
          seoTitle: "Valentine's Day Dinner",
          urlTitle: 'valentine-s-day-in-santos',
          parentOfferType: 'Non Stay Based Offer',
          __typename: 'Offer',
        },
        __typename: 'OffersSearchEdge',
      },
    ],
    facets: [
      {
        buckets: [
          {
            code: 'Earn',
            count: 61,
            label: null,
            description: 'Earn',
            __typename: 'FacetBucket',
          },
          {
            code: 'Earn & Redeem',
            count: 16,
            label: null,
            description: 'Earn & Redeem',
            __typename: 'FacetBucket',
          },
          {
            code: 'Redeem',
            count: 26,
            label: null,
            description: 'Redeem',
            __typename: 'FacetBucket',
          },
        ],
        type: {
          code: '9000038',
          count: 3,
          enumCode: 'OFFER_FLAGS',
          __typename: 'OffersFacetType',
        },
        __typename: 'OffersFacet',
      },
    ],
    __typename: 'offersSearchConnection',
  },
};

export const OfferSearchResultUXLMockJsonByGeoLoc = {
  offersSearchByGeolocation: { ...OfferSearchResultUXLMockJson.offersSearch },
};

export const OfferSearchResultUXLMockJsonByArea = {
  offersSearchByArea: { ...OfferSearchResultUXLMockJson.offersSearch },
};

export const offerSearchPayload = {
  input: {
    facets: {
      terms: [
        { type: 'BRANDS', dimensions: [] },
        { type: 'OFFER_TAGS', dimensions: [] },
        { type: 'OFFER_FLAGS', dimensions: [] },
        { type: 'STATES', dimensions: [] },
        { type: 'CITIES', dimensions: [] },
        { type: 'COUNTRIES', dimensions: [] },
      ],
    },
  },
  limit: '40',
  offset: 0,
  sort: { field: 'BOOKING_END_DATE' },
};
