import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledComp = styled.div<{ alignElement: string; newTab: boolean; imgAlignment: string }>`
  border-radius: ${toRem(14)};
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  @media ${baseVariables.mediaQuery.md} {
    flex-direction: row;
  }
  .hide-component {
    visibility: hidden;
  }
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    order: 2;
    width: 100%;
    padding: ${toRem(32)} ${toRem(24)};
    align-items: ${props => (props.alignElement === 'left' ? 'flex-start' : 'center')};
    @media ${baseVariables.mediaQuery.md} {
      order: ${props => (props.imgAlignment === 'left' ? '2' : '1')};
      padding: ${toRem(32)};
    }
    @media ${baseVariables.mediaQuery.xl} {
      padding: ${toRem(48)};
    }
  }
  .feature-header {
    -webkit-line-clamp: 3;
  }
  .feature-text {
    -webkit-line-clamp: 7;
    padding-top: ${toRem(4)};
    @media ${baseVariables.mediaQuery.xl} {
      -webkit-line-clamp: 5;
    }
  }
  .feature-header,
  .feature-text {
    display: -webkit-box; /* stylelint-disable-line */
    -webkit-box-orient: vertical; /* stylelint-disable-line */
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .feature-img {
    width: 100%;
    height: 100%;
  }
  .img-container {
    order: 1;
    @media ${baseVariables.mediaQuery.md} {
      order: ${props => (props.imgAlignment === 'left' ? '1' : '2')};
    }
  }
  .feature-button {
    margin-top: ${toRem(24)};
  }
`;
