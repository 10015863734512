// Imports for external libraries go here.
import React, { FC } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledOffersHero, StyledOffersHeroPreviewMode } from './OffersHero.styles';
import { OfferHeroProps } from './OffersHero.types';
import {
  ANCILLARY_PRODUCT_OFFER,
  MULTI_BRAND_MULTI_PROP_OFFER,
  SINGLE_BRAND_MULTI_PROP_OFFER,
  SINGLE_PROPERTY_OFFER,
} from '../../constants/OffersHeroConstants';
import { OffersHeroMultiProperty } from './OffersHeroMultiProperty';
import { OffersHeroSingleProperty } from './OffersHeroSingleProperty';

// Use named rather than default exports.
const OffersHeroReactWrapper: FC<OfferHeroProps> = props => {
  const { model, offersData } = props;

  const OfferHeroComponent: React.FC = () => {
    switch (model?.variation) {
      case SINGLE_BRAND_MULTI_PROP_OFFER:
        return (
          <OffersHeroMultiProperty
            modelObj={model}
            offersHeroProps={offersData}
            variation={SINGLE_BRAND_MULTI_PROP_OFFER}
          />
        );
      case MULTI_BRAND_MULTI_PROP_OFFER:
        return (
          <OffersHeroMultiProperty
            modelObj={model}
            offersHeroProps={offersData}
            variation={MULTI_BRAND_MULTI_PROP_OFFER}
          />
        );
      case SINGLE_PROPERTY_OFFER:
        return (
          <OffersHeroSingleProperty modelObj={model} offersHeroProps={offersData} variation={SINGLE_PROPERTY_OFFER} />
        );
      case ANCILLARY_PRODUCT_OFFER:
        return <OffersHeroSingleProperty modelObj={model} offersHeroProps={offersData} />;
      default:
        return <OffersHeroSingleProperty modelObj={model} offersHeroProps={offersData} />;
    }
  };

  return offersData?.isPreview === 'true' ? (
    <StyledOffersHeroPreviewMode data-testid="offers-hero" data-component-name="o-offers-hero">
      <OfferHeroComponent />
    </StyledOffersHeroPreviewMode>
  ) : (
    <StyledOffersHero data-testid="offers-hero" data-component-name="o-offers-hero">
      <OfferHeroComponent />
    </StyledOffersHero>
  );
};

export const OffersHeroWrapperConfig = {
  emptyLabel: 'offersHero',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/offers-hero`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersHero = (props: any) => (
  <EditableComponent config={OffersHeroWrapperConfig} {...props}>
    <div className="m-container-fullbleed">
      <OffersHeroReactWrapper {...props} />
    </div>
  </EditableComponent>
);
