// Imports for external libraries go here.
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState } from 'react';
import { decodeHtml, getLocalizedDate, processAcceptLang, validateDetailsField } from '../../../utils/OfferUtils';
//import { MiModal } from '../MiModal';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { OfferDetailsSinglePropertyProps } from './OfferDetailsSingleProperty.types';
import { StyledOfferDetailsSingleProperty } from './OfferDetailsSingleProperty.styles';
import { ANCILLARY_PRODUCT_OFFER, DATE_FORMAT_YEAR_MONTH_DAY } from '../../../constants/OfferDetailsConstants';
import clsx from 'clsx';
import { Button, Link, Modal, Text, Types, Heading } from '@marriott/mi-ui-library';
import { makeDomainSpecificContent } from '../../../utils/OfferUtils';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import AlertNotification from 'libs/mi-ui-library/src/atoms/Alerts';
import moment from 'moment';
import { LocaleUtils } from 'react-day-picker';
import {
  EXTERNAL_CTA_TYPE,
  INTERNAL_CTA_TYPE,
  MONTHSHORTVALUE,
  WEEKDAYSLONGNAME,
  WEEKSDAYSSHORT,
} from 'libs/mi-offers-components/src/constants/CommonConstants';

// Use named rather than default exports.
export const OfferDetailsSingleProperty: FC<OfferDetailsSinglePropertyProps> = ({ offerDetailsProp }) => {
  const offerObj = offerDetailsProp?.offersData?.responseObject?.edges[0].node;
  const model = offerDetailsProp?.model;
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const modalProps = {
    className: 'm-modal d-flex justify-content-center modal-body-offers',
    secondaryClassName: 'terms-modal-content',
  };

  const toggleModal = (event: any) => {
    const body = document.querySelector('body');
    if (body) {
      if (!popupOpenState) {
        event.preventDefault();
        body.style.overflow = 'hidden';
        body.style.paddingRight = `15px`;
      } else {
        body.style.overflow = '';
        body.style.paddingRight = '';
      }
    }
    setPopupOpenState(!popupOpenState);
  };

  const isAncillaryOffer = offerDetailsProp?.model?.variation === ANCILLARY_PRODUCT_OFFER;
  const recurrencePattern = offerObj?.recurrencePattern;
  const offerAvailableDays = offerObj?.validDays?.split(',');
  const compClassNames = clsx('m-details-page-wrapper', model?.appliedCssClassNames);
  const isOutletAPO = isAncillaryOffer && offerObj?.numberOfOutlets > 0;

  //search form session date error msg handling
  const [isInvalidSessionDate, setIsInvalidSessionDate] = useState(false);
  const acceptLanguage = processAcceptLang(offerDetailsProp?.acceptLanguage ?? '');
  const OfferDetailsSPORef = useRef(null);

  useEffect(() => {
    eventUtil.on('invalidSessionDateErrFlag', flag => {
      setIsInvalidSessionDate(flag);
    });
    eventUtil.remove('invalidSessionDateErrFlag', () => {
      ('');
    });
  });
  let monthsValue = model?.months?.split(',');
  // set default month if i18 months value are invalid
  if (monthsValue?.length !== 12) {
    monthsValue = LocaleUtils.getMonths();
  }

  let monthsShortValue = model?.monthsShort?.split(',');
  // set default month if i18 months value are invalid
  if (monthsShortValue?.length !== 12) {
    monthsShortValue = MONTHSHORTVALUE;
  }

  let weeksdaysShort = model?.weekdaysShort?.split(',');
  // set default weekdays if i18 weekdays value are invalid
  if (weeksdaysShort?.length !== 7) {
    weeksdaysShort = WEEKSDAYSSHORT;
  }

  // const dummyWeekDays = 'Sonntag, Montag, Dienstag, Mittwoch, Donnerstag, Freitag, Samstag';
  let weekdaysLongName = model?.weekdaysLong?.split(',');
  if (weekdaysLongName?.length !== 7) {
    weekdaysLongName = WEEKDAYSLONGNAME;
  }

  const setLanguageLocalization = (): void => {
    moment.locale('locale', {
      months: monthsValue,
      monthsShort: monthsShortValue,
      weekdays: weekdaysLongName,
      weekdaysShort: weeksdaysShort,
    });
  };

  useEffect(() => {
    setLanguageLocalization();
  }, [monthsValue, monthsShortValue, weekdaysLongName, weeksdaysShort]);

  setLanguageLocalization();

  const trackingProperties = offerDetailsProp?.model?.trackingProperties;

  const getOfferRecurrencePattern = () => {
    let availableDays = offerDetailsProp?.model?.every ?? 'Every';
    if (recurrencePattern === 'Weekly') {
      const offerAvailableDays = mapLocaleSpecificRecurrencePattern();
      offerAvailableDays?.length === 7
        ? (availableDays = offerDetailsProp?.model?.everyday ?? 'Everyday')
        : offerAvailableDays?.map((day: string) => {
            availableDays += ` ${day},`;
          });
    } else if (recurrencePattern === 'Monthly') {
      availableDays = offerDetailsProp?.model?.the ?? 'The';
      availableDays += ` ${offerObj?.validDays} ${offerDetailsProp?.model?.eachMonth ?? 'each month'},`;
    }
    const offerEndDate = getLocalizedDate(
      offerObj?.stayEndDate,
      acceptLanguage,
      offerDetailsProp?.model?.year ?? 'year',
      offerDetailsProp?.model?.of ?? 'of'
    );
    const offerRecurrencePattern = `${availableDays} ${offerDetailsProp?.model?.through ?? 'through'} ${offerEndDate}`;
    return offerRecurrencePattern;
  };
  const mapLocaleSpecificRecurrencePattern = () => {
    const mapWeekDaysWithEng: { [key: string]: string } = {};
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    WEEKDAYSLONGNAME.map((engVal: string, i: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      weekdaysLongName?.map((localeVal: string, j: number) => {
        i === j && (mapWeekDaysWithEng[engVal.trim()] = localeVal.trim());
      });
    });
    const localeSpecificAvailableDays = offerAvailableDays?.map((val: string) => {
      return mapWeekDaysWithEng[val]?.trim();
    });
    return localeSpecificAvailableDays;
  };
  const [description, setDescription] = useState('');
  useEffect(() => {
    setDescription(decodeHtml(offerObj.description));
  }, []);
  return (
    <StyledOfferDetailsSingleProperty className={`brand-css ${model?.appliedCssClassNames}`}>
      {isInvalidSessionDate && (
        <AlertNotification
          errorMessage={'The special rate is not available for the dates you requested.'}
          className={'error-message alert'}
        />
      )}
      <div className={'m-details-page container ancillary-details color-scheme3'}>
        <div className={compClassNames}>
          <div className="m-details-page-description">
            <div className={`desc m-details-offer-description`}>
              <div dangerouslySetInnerHTML={{ __html: description ?? '' }}></div>
            </div>
            {/* <p
              role="presentation"
              className="details-desc-text"
              dangerouslySetInnerHTML={{ __html: offerObj?.description ?? '' }}
            ></p> */}
            {validateDetailsField(offerObj?.reservationMessage) && (
              <div className={`m-details-page-reserv-msg`}>
                <Heading
                  titleText={offerDetailsProp?.model?.howToBook ?? ''}
                  variation={Types.headingType.title}
                  fontSize={Types.size.medium}
                  customClass={'t-subtitle-xl offer-details-title'}
                  element={Types.tags.h3}
                />
                <p
                  dangerouslySetInnerHTML={{ __html: makeDomainSpecificContent(offerObj?.reservationMessage) ?? '' }}
                ></p>
              </div>
            )}

            {validateDetailsField(offerObj?.termsAndConditions ?? offerObj?.additionalTerms) && (
              <>
                <div className="m-details-page-TnC desktop-TnC">
                  <Link
                    callback={event => toggleModal(event)}
                    text={offerDetailsProp?.model?.termsAndConditions ?? ''}
                    linkClassName={'m-details-page-TnC m-link-tertiary-button-external custom_click_track'}
                    linkHref={'#'}
                    linkType="internal"
                    target="_self"
                    custom_click_track_value={`${model?.id}|${model?.termsAndConditions}|${INTERNAL_CTA_TYPE}`}
                    trackingProperties={trackingProperties}
                    ref={OfferDetailsSPORef}
                  ></Link>
                </div>
                {popupOpenState && (
                  <Modal {...modalProps} show={true}>
                    <Modal.Header
                      labelText={offerDetailsProp?.model?.termsAndConditions ?? 'Terms & Conditions'}
                      popupHeaderOnCLoseFunc={event => toggleModal(event)}
                      className="terms-modal-header"
                    />
                    <Modal.Body>
                      <div className="offers-terms-details">
                        <Text
                          copyText={`${decodeHtml(offerObj?.additionalTerms)} ${decodeHtml(
                            offerObj.termsAndConditions
                          )}`}
                          fontSize={Types.size.medium}
                          customClass={'offers-additional-terms'}
                          element={Types.tags.paragraph}
                        />
                      </div>
                    </Modal.Body>
                  </Modal>
                )}
              </>
            )}
            {isAncillaryOffer && (
              <div className={`m-details-page-shop-now desktop-shop-cta`}>
                <Button
                  href={offerObj?.callToActionLink ?? '#'}
                  target={'_blank'}
                  className={clsx(
                    'm-button-external m-details-button',
                    `${isOutletAPO ? 'apoOutletCTA' : 'apoOfferCTA'}`
                  )}
                  buttonCopy={offerObj?.callToActionLabel ?? offerDetailsProp?.model?.shopNow ?? ''}
                  custom_click_track_value={`${model?.id}|${
                    offerObj?.callToActionLabel ?? model?.shopNow
                  }|${EXTERNAL_CTA_TYPE}`}
                  isLink={true}
                  trackingProperties={trackingProperties}
                  ref={OfferDetailsSPORef}
                ></Button>
              </div>
            )}
          </div>
          <div className={`m-details-page-need-to-know-wrapper`}>
            <Heading
              titleText={offerDetailsProp?.model?.needToKnow ?? ''}
              variation={Types.headingType.title}
              fontSize={Types.size.medium}
              customClass={'t-subtitle-xl offer-details-title'}
              element={Types.tags.h3}
            />
            <div className="m-details-page-need-to-know-items" role="presentation">
              <ul role="list">
                {offerObj?.needToKnow?.map((item: any) => (
                  <li role="listitem">{item}</li>
                ))}
                {!isAncillaryOffer && (
                  <>
                    {offerObj?.clusterCode && !(offerObj?.clusterCode === '0' || offerObj?.segment) && (
                      <li role="listitem">
                        {offerDetailsProp?.model?.promotionalCode ?? 'Promotional Code'} : {offerObj?.clusterCode}
                        <a href="javascript:void(0)" className="m-whats">
                          {offerDetailsProp?.model?.whatsThis ?? ''}? <span className="icon-alert"></span>
                        </a>
                        <Text
                          copyText={offerDetailsProp?.model?.promoCodeInfo ?? ''}
                          fontSize={Types.size.small}
                          customClass={'m-tool-tip-hint t-font-s t-box-shadow t-font-weight-normal'}
                          element={Types.tags.span}
                        />
                      </li>
                    )}
                    <li role="listitem">
                      {offerDetailsProp?.model?.validStayDates} :{' '}
                      {offerObj?.stayStartDate &&
                        getLocalizedDate(
                          offerObj?.stayStartDate,
                          acceptLanguage,
                          offerDetailsProp?.model?.year ?? 'year',
                          offerDetailsProp?.model?.of ?? 'of'
                        )}{' '}
                      -{' '}
                      {offerObj?.stayEndDate &&
                        getLocalizedDate(
                          offerObj?.stayEndDate,
                          acceptLanguage,
                          offerDetailsProp?.model?.year ?? 'year',
                          offerDetailsProp?.model?.of ?? 'of'
                        )}
                    </li>
                  </>
                )}
                {isAncillaryOffer && (
                  <li role="listitem">
                    {offerDetailsProp?.model?.validThrough}:{' '}
                    {offerObj?.bookingStartDate &&
                      getLocalizedDate(
                        offerObj?.bookingStartDate,
                        acceptLanguage,
                        offerDetailsProp?.model?.year ?? 'year',
                        offerDetailsProp?.model?.of ?? 'of'
                      )}{' '}
                    -{' '}
                    {offerObj?.bookingEndDate &&
                      getLocalizedDate(
                        offerObj?.bookingEndDate,
                        acceptLanguage,
                        offerDetailsProp?.model?.year ?? 'year',
                        offerDetailsProp?.model?.of ?? 'of'
                      )}
                  </li>
                )}
                {offerObj?.offerType === 'Ancillary Product Offer' &&
                  (recurrencePattern === 'Weekly' || recurrencePattern === 'Monthly') && (
                    <li>{getOfferRecurrencePattern()}</li>
                  )}
              </ul>
            </div>
          </div>

          {/* Mobile/Tablet Terms & Condition and Shop CTA */}

          {validateDetailsField(offerObj?.termsAndConditions ?? offerObj?.additionalTerms) && (
            <div className="m-details-page-TnC mobile-TnC">
              <Link
                callback={event => toggleModal(event)}
                text={offerDetailsProp?.model?.termsAndConditions}
                linkClassName={'m-details-page-TnC m-link-tertiary-button-external custom_click_track'}
                linkHref={'#'}
                linkType="internal"
                target="_self"
                custom_click_track_value={`${model?.id}|${model?.termsAndConditions}|${INTERNAL_CTA_TYPE}`}
                trackingProperties={trackingProperties}
                ref={OfferDetailsSPORef}
              ></Link>
            </div>
          )}

          {isAncillaryOffer && (
            <div className={`m-details-page-shop-now mobile-shop-cta`}>
              <Button
                href={offerObj?.callToActionLink ?? '#'}
                target={'_blank'}
                className={clsx(
                  'm-button-external',
                  'm-details-button',
                  `${isOutletAPO ? 'apoOutletCTA' : 'apoOfferCTA'}`
                )}
                buttonCopy={offerObj?.callToActionLabel ?? offerDetailsProp?.model?.shopNow}
                custom_click_track_value={`${model?.id}|${
                  offerObj?.callToActionLabel ?? model?.shopNow
                }|${EXTERNAL_CTA_TYPE}`}
                isLink={true}
                trackingProperties={trackingProperties}
                ref={OfferDetailsSPORef}
              ></Button>
            </div>
          )}
          {/* Mobile/Tablet Terms & Condition and Shop CTA Ends*/}
        </div>
      </div>
    </StyledOfferDetailsSingleProperty>
  );
};
