/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { eventUtil } from 'libs/mi-ui-library/src/utils/eventUtil';
import { useLazyQuery } from '@apollo/client';
import {
  phoenixLowestAvailableRates,
  phoenixOfferParticipatingProperties,
  phoenixOfferParticipatingPropertiesMarsha,
} from '@marriott/mi-offers-graphql';
import { MARRIOTT_CACHE_URL, UXL_ERROR_POLICY } from 'libs/mi-offers-components/src/api/apiConstants';
import { getSearchResultQuery, getCommaSeparator } from '../utils/helper';
import { addDaysToDate, getDateFormatted, getMPOFormattedDateNew } from '../utils/dateUtils';
import { PropertyCard } from '@marriott/mi-shop-components/src/molecules';
import { APPLICATION_BREAKPOINTS, useWindowSize, Text, Types } from '@marriott/mi-ui-library';
import { SkeletonOutletCardLoader } from 'libs/mi-rnb-components/src/organisms/SearchResults/SkeletonOutletCardLoader';
import { StyledPropertiesListWrapper } from './PropertiesListWrapper.styles';
import clsx from 'clsx';
import { useGetBreakpoint } from '../../../../src/utils/CommonUtils';
import AlertNotification from 'libs/mi-ui-library/src/atoms/Alerts';
import { getUrlParam } from '../../OffersSearchForm/utils/OfferUtils';
import { RECENT_SEARCH_LIST_LIMIT } from '../../OffersSearchForm/constants/ApplicationConstants';
import { recentViewedPropertiesType } from './PropertiesListWrapper.types';
// import propertiesListUXLMockJson from '../__mock__/OfferpropertiesListUXL.mock.json';
import {
  outputInterpolation,
  outputQuality,
  SURCHARGE_ORDINANCE_CODE,
} from '../../../../src/constants/OfferPropertiesListConstant';
import { PaginationPanel } from '../Pagination';
import { imgDimensionType, propertyCardDataType, searchDataType } from '../OfferPropertiesList.types';
import { PropertiesListFilter } from '../PropertiesListFilter';
// import { offerPropertiesModelMock } from "../__mock__/OfferPropertiesList.model";
import { BrandProps, availableBrandsType } from '../PropertiesListFilter/PropertiesListFilter.types';
import moment from 'moment';
import { processAcceptLang } from '../../../utils/OfferUtils';
import { EXTERNAL_CTA_TYPE, HOTEL_VIEW } from 'libs/mi-offers-components/src/constants/CommonConstants';

// WEB-70169 - using this to excude the mock file from production build
const propertiesListUXLMockJson = async (isAuthorMode: boolean) => {
  if (process.env['NODE_ENV'] === 'development' || isAuthorMode) {
    const { default: propertiesListUXLMockJson } = await import('../__mock__/OfferpropertiesListUXL.mock.json'); //Load mock model dynamically only for dev mode
    return propertiesListUXLMockJson;
  }
  return {};
};

export const PropertiesListWrapper: React.FC<any> = props => {
  const isMobileViewPort = useGetBreakpoint() === 'mobile';
  const { width } = useWindowSize();
  const { offersData, isAuthorMode, model, customerId } = props;
  const isPreview = offersData?.isPreview === 'true' ? true : false;
  const acceptLanguage = processAcceptLang(props?.acceptLanguage ?? '');
  const requestId = props?.requestId;
  const offerId = props?.offersData?.offerId;
  const stayStartDate = offersData?.responseObject?.edges[0]?.node?.stayStartDate;
  const minimumStay = offersData?.responseObject?.edges[0]?.node?.minimumStay ?? 1;
  const defaultStartDate = getMPOFormattedDateNew(stayStartDate).currMPODate;
  const subdirectory = props?.subDirectoryVal ?? '';
  const variation = model?.variation;
  const clusterCode = props?.offersData?.responseObject?.edges[0].node.clusterCode;
  const showPoints = clusterCode == '39E' || clusterCode == 'MRW' || clusterCode == 'MW1' || clusterCode == 'P17';

  const [onloadInput, setOnloadInput] = useState<string>('');
  const [dataLoaderFlag, setDataLoaderFlag] = useState<boolean>(true);
  const [pageCardCountInfo, setPageCardCountInfo] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  //pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offsetValue, setOffsetValue] = useState<number>(0);
  const mandatoryFeeMessages = {
    title: model?.mandatoryFeeTitle ?? 'Surcharge For Ordinance Cost',
    feeDescription1: model?.feeDescription1 ?? 'Includes',
    feeDescription2: model?.feeDescription2 ?? 'Surcharge for Ordinance Cost',
    viewRatesLabel: model?.viewRatesLabel ?? 'View Rates',
  };

  const getImageSetup = (): imgDimensionType => {
    let imageDimensions = {
      height: 288,
      width: 512,
    };
    if (Number(width) < APPLICATION_BREAKPOINTS['viewportL']) {
      imageDimensions = {
        height: 240,
        width: 360,
      };
    }
    if (Number(width) > APPLICATION_BREAKPOINTS['viewportL'] || Number(width) < APPLICATION_BREAKPOINTS['viewportS']) {
      imageDimensions = {
        height: 222,
        width: 715,
      };
    }
    return imageDimensions;
  };
  const getImageProps = (): string => {
    const imageDimensions = getImageSetup();
    const imgProp =
      `?output-quality=${outputQuality}&interpolation=${outputInterpolation};*,*&downsize=` +
      imageDimensions.width +
      'px:*';
    return imgProp;
  };
  const imageProperties = getImageProps();

  const onPageItemClick = (page: number): void => {
    setDataLoaderFlag(true);
    const offset = (page - 1) * 40;
    setCurrentPage(page);
    setOffsetValue(offset);
    const targetSection = document.getElementById('offers-search-form')?.offsetTop;
    const isFixedHeader = document.body.classList.contains('fixed-header');
    window.scrollTo({
      top: targetSection ? (isFixedHeader ? targetSection - 50 : targetSection - 150) : 0,
      behavior: 'smooth',
    });
  };

  const [searchData, setSearchData] = useState<searchDataType>({
    latitude: '',
    longitude: '',
    startDate: defaultStartDate,
    endDate: addDaysToDate(defaultStartDate, minimumStay),
    countryCode: '',
    stateCode: '',
    childAges: [],
    numberInParty: 1,
    quantity: 1,
    includeMandatoryFees: true,
  });
  const [propertyCardData, setPropertyCardData] = useState<propertyCardDataType>({
    hotels: [],
    totalProperties: 0,
  });
  // brand filter state variables
  const [availableBrands, setAvailableBrands] = useState<availableBrandsType[]>([]); // brands which are cominng as facets from uxl & available for selection
  const [selectedBrands, setSelectedBrands] = useState<BrandProps[]>([]); // brands which are selected for filtering of list
  const [brandFacets, setBrandFacets] = useState<string[]>([]);
  const paginationPanelLabel = {
    previous: model?.previous ?? '',
    next: model?.next ?? '',
  };
  //get onloadInput value
  useEffect(() => {
    setOnloadInput(getUrlParam());
  }, []);

  const trackingProperties = model?.trackingProperties;

  // get properties list on page load
  useEffect(() => {
    const inputVariable = getSearchResultQuery(
      searchData,
      offerId,
      offsetValue,
      brandFacets,
      isPreview,
      customerId,
      [],
      clusterCode
    );
    const payLoad = {
      variables: {
        ...inputVariable,
      },
    };

    if (isAuthorMode) {
      (async () => {
        const getPropertiesListUXLMockJson = await propertiesListUXLMockJson(isAuthorMode);
        const modifiedPropertyCardData = modifyPropertyCardData(getPropertiesListUXLMockJson);
        setPropertyCardData({
          hotels: modifiedPropertyCardData?.hotels,
          totalProperties: modifiedPropertyCardData.totalProperties,
        });

        setDataLoaderFlag(false);
        setPageCardCountInfo(getPageCardCountInfo(modifiedPropertyCardData?.totalProperties));
        modifiedPropertyCardData?.totalProperties === 0
          ? setErrorMessage(model?.offerParticipatingPropertiesError)
          : setErrorMessage('');
      })();
    } else {
      if (isPreview) {
        getPropertiesMarsha({
          variables: {
            offerPreviewId: String(offerId),
          },
        });
      } else {
        getPropertiesListdata(payLoad);
      }
    }
    setOffsetValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData, currentPage, brandFacets]);

  //on click of apply filter button
  const handleApplyFilter = (selectedBrandsList: any) => {
    setDataLoaderFlag(true);
    setSelectedBrands(selectedBrandsList);
    const brandFacetList = selectedBrandsList.map((item: any) => item.code);
    setBrandFacets(brandFacetList);
    setCurrentPage(1);
  };
  const handleClearAllFilters = () => {
    setSelectedBrands([]);
    setCurrentPage(1);
  };

  // get properties list on search form update
  // Search Data Receive from Offers Search Form
  useEffect(() => {
    eventUtil.on('MPOSearchFormData', data => {
      setDataLoaderFlag(true);
      const searchFormData = data.searchFormData;
      setSearchData(searchFormData);
      setCurrentPage(1);
    });

    eventUtil.on('childAgeErrFlag', flag => {
      setErrorMessage(flag ? model?.childAgeEmptyError : '');
    });

    eventUtil.on('stayDateMissingErrFlag', flag => {
      setErrorMessage(flag ? model?.stayDateEmptyError : '');
    });

    eventUtil.remove('MPOSearchFormData', () => {
      ('');
    });
    eventUtil.remove('childAgeErrFlag', () => {
      ('');
    });
    eventUtil.remove('stayDateMissingErrFlag', () => {
      ('');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    eventUtil.on('DeepLinkUtil', data => {
      if (onloadInput) {
        setSearchData(data.searchFormData);
      }
    });
    eventUtil.remove('DeepLinkUtil', () => {
      ('');
    });
  }, [onloadInput]);

  const getPageCardCountInfo = (cardsTotalCount: number): string => {
    const total = Math.ceil(cardsTotalCount / 40);
    const start = total === 1 || currentPage === 1 ? 1 : (currentPage - 1) * 40 + 1;
    const end = total === currentPage || currentPage === cardsTotalCount ? cardsTotalCount : currentPage * 40;
    const countText = `${model?.showing ?? 'Showing'} ${start}-${end} ${model?.of} ${cardsTotalCount} ${model?.hotel}`;
    return countText;
  };

  const processPropertiesMarsha = (propertiesMarsha: any) => {
    const marshCodes = propertiesMarsha?.offerPreview?.offer?.participatingProperties?.properties
      ?.slice(0, 40)
      ?.map((obj: { id: string }) => {
        return obj.id;
      });
    const clusterCode = propertiesMarsha?.offerPreview?.offer?.clusterCode ?? '';

    const inputVariable = getSearchResultQuery(
      searchData,
      offerId,
      offsetValue,
      brandFacets,
      isPreview,
      customerId,
      marshCodes,
      clusterCode
    );
    const payLoad = {
      variables: {
        ...inputVariable,
      },
    };
    getPropertiesListdata(payLoad);
  };
  const processPropertiesList = (propertiesListdata: any) => {
    const modifiedPropertyCardData = modifyPropertyCardData(propertiesListdata);
    variation === 'sbo' &&
      setSelectedBrands([
        {
          code: modifiedPropertyCardData?.hotels[0]?.brandCode,
          brandTagId: modifiedPropertyCardData?.hotels[0]?.brandCode,
          brandTagTitle: '',
          isDisabled: false,
        },
      ]);
    setPropertyCardData({
      hotels: modifiedPropertyCardData?.hotels,
      totalProperties: modifiedPropertyCardData.totalProperties,
    });
    variation === 'sbo'
      ? setAvailableBrands([
          {
            code: modifiedPropertyCardData?.hotels[0]?.brandCode,
            description: modifiedPropertyCardData?.hotels[0]?.brandCode,
          },
        ])
      : setAvailableBrands(propertiesListdata?.offersSearch?.facets[0]?.buckets);
    setDataLoaderFlag(false);
    setPageCardCountInfo(getPageCardCountInfo(modifiedPropertyCardData?.totalProperties));
    modifiedPropertyCardData?.totalProperties === 0
      ? setErrorMessage(model?.offerParticipatingPropertiesError)
      : setErrorMessage('');
  };
  const handlePropertiesError = () => {
    setPageCardCountInfo(getPageCardCountInfo(0));
    setPropertyCardData({
      hotels: [],
      totalProperties: 0,
    });
    setDataLoaderFlag(false);
    setErrorMessage(model?.offerParticipatingPropertiesError);
  };

  //fetch marsha codes for preview flow

  const [getPropertiesMarsha, { data: _propertiesMarsha }] = useLazyQuery(phoenixOfferParticipatingPropertiesMarsha, {
    fetchPolicy: 'network-only',
    errorPolicy: UXL_ERROR_POLICY,
    context: {
      headers: {
        'x-request-id': requestId,
        'accept-language': acceptLanguage,
      },
    },
    onCompleted: processPropertiesMarsha,
    onError: handlePropertiesError,
  });

  //get properties list
  const [getPropertiesListdata, { data: _propertiesListdata }] = useLazyQuery(
    isPreview ? phoenixLowestAvailableRates : phoenixOfferParticipatingProperties,
    {
      fetchPolicy: 'network-only',
      errorPolicy: UXL_ERROR_POLICY,
      context: {
        headers: {
          'x-request-id': requestId,
          'accept-language': acceptLanguage,
        },
      },
      onCompleted: processPropertiesList,
      onError: handlePropertiesError,
    }
  );

  //modify card data helper functions
  const modifyPropertyCardData = (data: any) => {
    const propList = isPreview
      ? data?.searchLowestAvailableRatesByPropertyIds?.edges ?? []
      : data?.offersSearch?.edges[0]?.node.participatingProperties?.propertyRates ?? [];
    const clusterCode = isPreview
      ? data?.searchLowestAvailableRatesByPropertyIds?.edges[0]?.node?.rates[0]?.rateCategory
          ?.rateCategoryDescription[0]?.clusterCode ?? ''
      : data.offersSearch?.edges[0]?.node.clusterCode ?? '';
    const hotels = propList.length
      ? propList.map((hotel: any) => {
          if (isPreview) {
            return {
              id: hotel?.node?.property?.id ?? '',
              sid: hotel?.node?.property?.basicInformation?.marshaBrandCode ?? '',
              brandCode: hotel?.node?.property?.basicInformation?.brand?.id ?? '',
              titleDetails: getTitle(hotel?.node?.property),
              reviewsDetails: getReviewDetails(hotel?.node?.property),
              description: getAddress(hotel?.node?.property?.contactInformation?.address),
              images: getPropertyImages(hotel?.node?.property),
              tertiaryLinkDetails: generateDetailsPageURL(hotel?.node?.property?.id ?? ''),
              footerLinkDetails: getFooterDetails(hotel, clusterCode),
              mandatoryFeeObj: {
                amountPlusMandatoryFee: getAmountPlusMandatoryFeeDetails(hotel),
                mandatoryFee: getMandatoryFeeDetails(hotel),
                mandatoryFeeDescription: getMandatoryFeeDescription(hotel.property?.basicInformation?.descriptions),
                ...mandatoryFeeMessages,
              },
              brandDetails: {
                brandId: hotel?.node?.property?.basicInformation?.brand?.id,
                label: '',
              },
            };
          } else {
            return {
              id: hotel.property?.id ?? '',
              sid: hotel.property?.basicInformation?.marshaBrandCode ?? '',
              brandCode: hotel.property?.basicInformation?.brand?.id ?? '',
              titleDetails: getTitle(hotel.property),
              reviewsDetails: getReviewDetails(hotel.property),
              description: getAddress(hotel.property?.contactInformation?.address),
              images: getPropertyImages(hotel.property),
              tertiaryLinkDetails: generateDetailsPageURL(hotel.property?.id ?? ''),
              footerLinkDetails: getFooterDetails(hotel, clusterCode),
              mandatoryFeeObj: {
                amountPlusMandatoryFee: getAmountPlusMandatoryFeeDetails(hotel),
                mandatoryFee: getMandatoryFeeDetails(hotel),
                mandatoryFeeDescription: getMandatoryFeeDescription(hotel.property?.basicInformation?.descriptions),
                ...mandatoryFeeMessages,
              },
              brandDetails: {
                brandId: hotel.property?.basicInformation?.brand?.id,
                label: '',
              },
            };
          }
        })
      : [];
    return {
      hotels: hotels,
      totalProperties: isPreview
        ? data?.searchLowestAvailableRatesByPropertyIds?.total ?? 0
        : data?.offersSearch.edges[0]?.node?.numProperties ?? 0,
    };
  };
  const getTitle = (property: any) => {
    const titleDetails = {
      title: property?.basicInformation?.name,
      titleAriaLabel: property?.basicInformation?.name,
      isTitleLink: true,
      titleClickCallback: () => {
        const detailsPage = (subdirectory ? `/${subdirectory}` : '') + `/${property?.id}`;
        setRecentlyViewedProperties(property);
        window.open(`${detailsPage}`, '_blank');
      },
      titleLink: (subdirectory ? `/${subdirectory}` : '') + `/${property?.id}`,
      titleClickTrackVal: `${model?.id}|${property?.id}|${EXTERNAL_CTA_TYPE}`,
    };
    return titleDetails;
  };
  const setRecentlyViewedProperties = (property: any) => {
    const name = property?.basicInformation?.name;
    const localViewed = localStorage.getItem('miRecentlyViewedProperties');
    const recentViewed: recentViewedPropertiesType = JSON.parse(
      localViewed
        ? localViewed
        : JSON.stringify({
            config: {
              maxCachedPropertiesLimit: '10',
              maxCachedPropertiesTab: '2',
              maxCachedPropertiesDesktop: '3',
              maxCachedPropertiesMobile: '2',
              maxCachedDaysLimit: '60',
            },
            recentlyViewedProperties: [],
          })
    );
    const isDuplicate =
      recentViewed?.recentlyViewedProperties?.length &&
      recentViewed.recentlyViewedProperties.find(item => item.name === name);

    if (!isDuplicate) {
      const obj = {
        name: name,
        address: name,
      };

      if (recentViewed && recentViewed?.recentlyViewedProperties.length >= 0) {
        if (recentViewed.recentlyViewedProperties.length >= RECENT_SEARCH_LIST_LIMIT) {
          recentViewed.recentlyViewedProperties.pop();
        }
        recentViewed.recentlyViewedProperties.unshift(obj);
      }

      localStorage.setItem('miRecentlyViewedProperties', JSON.stringify(recentViewed));
    }
  };
  const getAddress = (address: any): string => {
    return `${address?.line1 ?? ''} ${address?.city ?? ''}, ${address?.stateProvince?.description ?? ''} ${
      address?.postalCode ?? ''
    } ${address?.country?.description ?? ''}`;
  };
  const getReviewDetails = (property: any) => {
    const id = property.id;
    const rating = property?.reviews?.stars?.count ?? 0;
    const reviewCount = property?.reviews?.numberOfReviews?.count ?? 0;
    const reviewUrl = (subdirectory ? `/${subdirectory}` : '') + `/hotels/hotel-reviews/${id}`;
    const reviewDetails = {
      reviewsLink: reviewUrl,
      reviewsLabel: '',
      reviewsClickTrackValue: `${model?.id}|${property?.id}-${model?.storefrontPropertyReviews}|${EXTERNAL_CTA_TYPE}`,
      reviewsAvg: rating,
      reviewsText: `(${reviewCount} ${model?.storefrontPropertyReviews})`,
    };
    return reviewDetails;
  };
  const getPropertyImages = (data: any) => {
    const photoGalleryObj = data.media.photoGallery;
    const propertyImageList: any = [];
    const propertyImageArray: any = [];
    let cauroselImages: any = [];
    const edgesList: any = [];

    if (photoGalleryObj) {
      Object.keys(photoGalleryObj).forEach((categoryKey: any) => {
        const propertyObject: any = [];
        if (categoryKey !== '__typename' && photoGalleryObj[categoryKey]) {
          propertyObject['categoryCode'] = categoryKey;
          propertyObject['photosObj'] = photoGalleryObj[categoryKey];
          propertyImageList.push(propertyObject);
        }
      });
    }
    if (photoGalleryObj[HOTEL_VIEW] === null && data?.media?.primaryImage?.edges?.[0]?.node?.imageUrls) {
      const hotelViewObj: any = [];
      hotelViewObj['categoryCode'] = HOTEL_VIEW;
      hotelViewObj['photosObj'] = data?.media?.primaryImage;
      propertyImageList.unshift(hotelViewObj);
    }
    propertyImageList.map((edgeCollection: any) => {
      edgesList.push(edgeCollection?.photosObj?.edges);
      edgesList.map((photosList: any) => {
        photosList.map((imgNode: any) => {
          const wideImg = imgNode?.node?.imageUrls?.wideHorizontal;
          if (wideImg !== null && wideImg !== '') {
            propertyImageArray.push({
              defaultImageUrl: MARRIOTT_CACHE_URL + wideImg + imageProperties,
            });
            cauroselImages = [
              ...new Map(propertyImageArray.map((item: any) => [item.defaultImageUrl, item])).values(),
            ].slice(0, 5);
          }
        });
      });
    });
    if (cauroselImages.length < 1) {
      const brandFallbackImageArray: any = {};
      brandFallbackImageArray['defaultImageUrl'] = data.basicInformation.brand.photos[0].content[0].url;
      cauroselImages.push(brandFallbackImageArray);
    }
    return cauroselImages;
  };
  const generateDetailsPageURL = (id: string) => {
    const detailsPage = (subdirectory ? `/${subdirectory}` : '') + `/${id}`;
    const footerlinks = {
      href: detailsPage,
      target: '_blank',
      custom_click_track_value: `${model?.id}|${
        model?.storefrontPropertyHotelDetails ?? 'View Hotel Details'
      }|${EXTERNAL_CTA_TYPE}`,
      rel: 'noreferrer',
      className: 'm-link-tertiary-button outlet-resevation-link custom_click_track ',
      buttonCopy: `${model?.storefrontPropertyHotelDetails ?? 'View Hotel Details'}`,
      isLink: true,
      onClick: () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (): Window | null => window.open(`${detailsPage}`, '_self');
      },
    };
    return footerlinks;
  };

  const getRatesNode = (hotel: any, category: string) => {
    if (isPreview) {
      if (showPoints) {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        return hotel?.node?.rates?.filter((obj: any) => {
          return obj?.rateCategory?.value === (category === 'rate' ? null : category == 'pointSaver' ? 'P17' : 'MRW');
        })[0];
      } else {
        return hotel?.node?.rates[0];
      }
    } else {
      if (showPoints) {
        const rateObj = hotel?.lowestAvailableRateCollection?.edges?.filter((obj: any) => {
          return (
            obj?.node?.rateCategory?.value === (category === 'rate' ? null : category == 'pointSaver' ? 'P17' : 'MRW')
          );
        });
        return rateObj[0]?.node;
      } else {
        return hotel.lowestAvailableRateCollection?.edges[0]?.node;
      }
    }
  };

  const getAmountPlusMandatoryFeeDetails = (hotel: any) => {
    const rateObj = getRatesNode(hotel, 'rate');
    const stayLength = rateObj?.lengthOfStay ?? 1;
    const rateStatus = rateObj?.status?.code ?? 'unavailable';
    let mandatoryFee;
    if (rateStatus === 'available') {
      if (stayLength > 1) {
        mandatoryFee = rateObj?.rateAmountsByMode?.lowestAverageNightlyRate?.amountPlusMandatoryFees?.origin
          ? rateObj?.rateAmountsByMode?.lowestAverageNightlyRate?.amountPlusMandatoryFees?.origin
          : {};
      } else {
        mandatoryFee = rateObj?.rateAmountsByMode?.firstNightLowestStay?.amountPlusMandatoryFees?.origin
          ? rateObj?.rateAmountsByMode?.firstNightLowestStay?.amountPlusMandatoryFees?.origin
          : {};
      }
    }

    const totalPrice =
      mandatoryFee && mandatoryFee?.value
        ? Math.round(mandatoryFee?.value / Math.pow(10, mandatoryFee?.valueDecimalPoint as number))
        : 0;
    return totalPrice;
  };
  const getMandatoryFeeDetails = (hotel: any) => {
    const rateObj = getRatesNode(hotel, 'rate');
    const mandatoryFee = rateObj?.rateAmounts[0]?.mandatoryFees?.origin
      ? rateObj?.rateAmounts[0]?.mandatoryFees?.origin
      : {};
    return mandatoryFee;
  };
  const getMandatoryFeeDescription = (descriptions: any) => {
    const mandatoryFeeDescObj =
      descriptions.length > 0
        ? descriptions.filter((item: { type: { code: string } }) => {
            return item.type.code === SURCHARGE_ORDINANCE_CODE;
          })
        : [];
    return mandatoryFeeDescObj;
  };

  const getFooterDetails = (hotel: any, clusterCode: string) => {
    const dateFlag =
      acceptLanguage === 'it-IT' ||
      acceptLanguage === 'fr-FR' ||
      acceptLanguage === 'pt-BR' ||
      acceptLanguage === 'es-ES' ||
      acceptLanguage === 'en-GB'
        ? true
        : false;

    const ratePage =
      (subdirectory && `/${subdirectory}`) +
      '/reservation/availabilitySearch.mi?' +
      `&numberOfRooms=${searchData?.quantity}&numberOfAdults=${
        Number(searchData?.numberInParty) - (searchData?.childAges?.length ?? 0)
      }&childrenCount=${searchData?.childAges?.length ?? ''}&childrenAges=${
        searchData?.childAges?.length ? searchData?.childAges.map((i: any) => (Number.isNaN(Number(i)) ? '0' : i)) : ''
      }` +
      '&flexibleDateSearchRateDisplay=false&useRewardsPoints=false&marrOfferId=' +
      offerId +
      '&fromDate=' +
      getDateFormatted(moment(searchData?.startDate), dateFlag ? 'DD/MM/YYYY' : 'MM/DD/YYYY') +
      '&toDate=' +
      getDateFormatted(moment(searchData?.endDate), dateFlag ? 'DD/MM/YYYY' : 'MM/DD/YYYY') +
      '&corporateCode=' +
      clusterCode +
      `&clusterCode=${clusterCode === '0' ? 'none' : 'corp'}` +
      '&propertyCode=' +
      (isPreview ? hotel?.node?.property?.id : hotel.property?.id);

    const rateObj = getRatesNode(hotel, 'rate');

    const rate = calculateRates(
      // isPreview ? hotel?.node?.rates : hotel.lowestAvailableRateCollection?.edges[0]?.node
      rateObj
    ).price;

    const isPointSaver =
      props?.offersData?.responseObject?.edges[0].node.clusterCode == 'P17' &&
      getRatesNode(hotel, 'pointSaver')?.status?.code == 'available'
        ? true
        : false;

    const footerlinks = {
      href: ratePage,
      target: '_self',
      ratesClickTrackValue: `${model.id}|${hotel?.property?.id}-${model?.bookNow}|${EXTERNAL_CTA_TYPE}`,
      rel: 'noreferrer',
      className: 'm-button-s m-button-primary custom_click_track reserve-cta-button',
      text: `${model?.bookNow ?? 'Book Now'}`,
      isLink: false,
      hasPrice: rate ? true : false,
      priceAriaLabel: '',
      priceClickTrackValue: `${model.id}|${hotel?.property?.id}-${model?.storefrontPropertyPointsPerStay}|${EXTERNAL_CTA_TYPE}`,
      priceValue: rate ?? null,
      priceType:
        rate &&
        `${
          isPreview ? hotel?.node?.property?.basicInformation?.currency : hotel?.property?.basicInformation?.currency
        } / ${model?.storefrontPropertyNight}`,
      unavailableRateText: model?.rateUnavailable ?? 'Rate Unavailable',
      points: showPoints
        ? getCommaSeparator(
            getRatesNode(hotel, 'points')?.rateAmountsByMode?.pointsPerUnit?.points ?? '',
            acceptLanguage
          )
        : '',
      showPoints: showPoints,
      pointsType: model?.storefrontPropertyPointsPerStay,
      isPointSaver: isPointSaver,
    };
    return footerlinks;
  };
  const calculateRates = (LARNode: any) => {
    const stayLength = LARNode?.lengthOfStay ?? 1;
    const rateStatus = LARNode?.status?.code ?? 'unavailable';
    let price = 0;
    if (rateStatus === 'available') {
      if (stayLength > 1) {
        const cashValue = LARNode?.rateAmountsByMode?.lowestAverageNightlyRate?.amount?.origin?.value;
        const decimalValue = LARNode?.rateAmountsByMode?.lowestAverageNightlyRate?.amount?.origin?.valueDecimalPoint;
        price = Math.round(cashValue / Math.pow(10, decimalValue as number));
      } else {
        const cashValue = LARNode?.rateAmountsByMode?.firstNightLowestStay?.amount?.origin?.value;
        const decimalValue = LARNode?.rateAmountsByMode?.firstNightLowestStay?.amount?.origin?.valueDecimalPoint;
        price = Math.round(cashValue / Math.pow(10, decimalValue as number));
      }
    }
    const points = LARNode?.pointsPerUnit?.points ?? 0;
    return {
      points: points,
      price: price,
    };
  };

  return (
    <StyledPropertiesListWrapper className={`${model?.appliedCssClassNames}`}>
      <div className={`container ${isPreview && 'propertiesPreviewMode'}`}>
        <div>
          {!(variation === 'sbo') && (
            <PropertiesListFilter
              model={model}
              availableBrands={availableBrands}
              handleApplyFilter={handleApplyFilter}
              selectedBrands={selectedBrands}
              brandFacets={brandFacets}
              handleClearAllFilters={handleClearAllFilters}
            />
          )}
          {isPreview && (
            <Text
              copyText={`This list includes up to 40 properties due to page loading impact.
              However, all associated offer properties will appear once this offer is published to the customers.`}
              fontSize={Types.size.medium}
              customClass={'properties-list-preview-message'}
              element={Types.tags.paragraph}
            />
          )}
        </div>
        {dataLoaderFlag ? (
          <div className="pt-5 pb-5">
            <SkeletonOutletCardLoader />
          </div>
        ) : errorMessage ? (
          <AlertNotification errorMessage={errorMessage} className={'no-hotels-found alert'} />
        ) : (
          <>
            {!isPreview && (
              <div className="page-card-count">
                <p className={clsx(isMobileViewPort ? 't-font-s' : 't-subtitle-l', 'pb-1', 'mb-0')}>
                  {pageCardCountInfo}
                </p>
              </div>
            )}
            {propertyCardData?.hotels?.length &&
              propertyCardData.hotels.map((hotel: any) => {
                return <PropertyCard {...hotel} isMBOP={true} trackingProperties={trackingProperties} />;
              })}
            <div className="pagination-container mt-5">
              <PaginationPanel
                currentPage={currentPage}
                totalCount={propertyCardData.totalProperties ?? 0}
                pageSize={40}
                onPageChange={(page: number): void => onPageItemClick(page)}
                labels={paginationPanelLabel}
                model={model}
              />
            </div>
          </>
        )}
      </div>
    </StyledPropertiesListWrapper>
  );
};
export default PropertiesListWrapper;
