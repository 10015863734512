// Styles for Points go here.
import { baseVariables, toRem } from '../../styles/index';
import styled from 'styled-components';

export const StyledPoints = styled.div`
  .points {
    font-family: ${baseVariables.font.fontSwissCondensed};
    &-underlined {
      border-bottom: ${toRem(5)} solid ${baseVariables.color.accent10};
    }
    &-rounded {
      border: ${toRem(4)} solid ${baseVariables.color.accent10};
      border-radius: 50%;
      width: fit-content;
    }
    &-underlinedLarge {
      height: ${toRem(8)};
      width: ${toRem(40)};
      @media ${baseVariables.mediaQuery.lg} {
        width: ${toRem(56)};
      }
      background: ${baseVariables.color.accent10};
    }
  }
`;
