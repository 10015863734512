import { CardLayeredVariations, size, tags } from '@marriott/mi-ui-library';
import { OffersCardLayeredProps } from '../OffersCardLayered.types';

export const CardLayeredMockTall2_3 = {
  cardLayeredVariations: 'Tall2:3' as CardLayeredVariations.Tall,
  dynamicMediaTall: {
    altText: 'Boat in ocean, aerial view',
    assetPath: 'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746',
    dynamic: true,
    renditions: [
      {
        renditionPath:
          'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746:Classic-Ver?wid=365&fit=constrain',
        mediaValue: '992px',
        width: 0,
        dynamic: true,
        damPath:
          '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
        mediaQuery: 'min-width',
        height: 0,
      },
      {
        renditionPath:
          'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746:Classic-Ver?wid=344&fit=constrain',
        mediaValue: '576px',
        width: 0,
        dynamic: true,
        damPath:
          '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
        mediaQuery: 'min-width',
        height: 0,
      },
      {
        renditionPath:
          'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746:Classic-Ver?wid=332&fit=constrain',
        mediaValue: '576px',
        width: 0,
        dynamic: true,
        damPath:
          '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
        mediaQuery: 'max-width',
        height: 0,
      },
    ],
  },
  logoIcon: 'RZ',
  logoIconAltText: 'The Ritz Carlton',
};

export const CardLayeredMockSquare = {
  cardLayeredVariations: 'square' as CardLayeredVariations.Square,
  dynamicMedia: {
    altText: 'Boat in ocean, aerial view',
    assetPath: 'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746',
    dynamic: true,
    renditions: [
      {
        renditionPath:
          'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746:Square?wid=365&fit=constrain',
        mediaValue: '992px',
        width: 0,
        dynamic: true,
        damPath:
          '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
        mediaQuery: 'min-width',
        height: 0,
      },
      {
        renditionPath:
          'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746:Square?wid=344&fit=constrain',
        mediaValue: '576px',
        width: 0,
        dynamic: true,
        damPath:
          '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
        mediaQuery: 'min-width',
        height: 0,
      },
      {
        renditionPath:
          'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746:Square?wid=332&fit=constrain',
        mediaValue: '576px',
        width: 0,
        dynamic: true,
        damPath:
          '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
        mediaQuery: 'max-width',
        height: 0,
      },
    ],
    damPath:
      '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
  },
};

export const mockOffersCardLayeredData: OffersCardLayeredProps = {
  offersData: {
    responseObject: {
      edges: [
        {
          node: {
            descriptionTeaser: 'This is Eyebrow field',
            title: 'Header field',
            callToActionLink: 'https://www.marriott.com',
            callToActionLabel: 'CTA One',
          },
        },
      ],
    },
  },

  model: {
    ctaLink: 'https://www.marriott.com',
    header: 'header',
    headerTag: 'h3' as tags.h3,
    badgeIcon: 'icon-globe',
    badgeText: 'Badge Text',
    styleclass: 'inverse',
    fontSize: 't-subtitle-l' as size.large,
    icon: 'instagram',
    openInNewTab: true,
    ...CardLayeredMockTall2_3,
  },

  isAuthorMode: true,
};
