import dayjs from 'dayjs';
import { canUseDOM } from '../helpers';
import Cookies from 'js-cookie';
import { COOKIE_KEYS } from '../constants';
import { DATA_LAYER_ATTRIBUTES as datalayerAttrList } from '../constants';
import { GlobalPageProps } from '../session';

export const getCurrentDateTime = () => {
  return dayjs(Date.now()).format('MM/DD/YY HH:mm:ss');
};

export const getCurrentLocale = (pageProps: GlobalPageProps) => {
  if (canUseDOM) {
    return pageProps?.['currentLocale'] || 'en-US';
  }
  return '';
};

export const propertyResolver = (value: string | undefined) => {
  return value ? value : '';
};

export const getEnvServerId = () => {
  if (Cookies.get(COOKIE_KEYS.miCnSite)) {
    return propertyResolver(Cookies.get(COOKIE_KEYS.miCnSite));
  } else {
    return propertyResolver(Cookies.get(COOKIE_KEYS.miSite));
  }
};

export const getCountryFromLocale = (pageProps: GlobalPageProps) => {
  const currentLocale = getCurrentLocale(pageProps);
  return currentLocale?.split('-')?.[1] || '';
};

export const getEnvSiteId = (pageProps: GlobalPageProps) => {
  const currentCountry = getCountryFromLocale(pageProps);
  if (currentCountry === 'LACA') {
    return 'ES';
  } else return currentCountry;
};

export const getAkamaiLocationAttrs = (headerValue?: string) => {
  if (headerValue?.length) {
    return headerValue.split(',').reduce((acc: Record<string, string>, pair) => {
      const [key, value] = pair.split('=');
      acc[key] = value;
      return acc;
    }, {});
  } else
    return {
      country_code: '',
      lat: '',
      long: '',
      city: '',
      region_code: '',
    };
};

export const getDatalayerAttrList = (pageProps: GlobalPageProps) => {
  if (canUseDOM) {
    return pageProps?.['model']?.['dataLayerStaticElements'] || datalayerAttrList;
  } else {
    return datalayerAttrList;
  }
};

export const getPreviousPageUrl = () => {
  return canUseDOM ? document.referrer : '';
};
