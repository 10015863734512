import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { LocaleUtils } from 'react-day-picker';
import moment from 'moment';
import { StyledAddDatesDiv, StyledDatesFooter } from './AddDates.styles';
import { ViewportContext, FormValuesContext, FocusContext, ContentContext } from '../../../index.contexts';
import { AddDatesProps } from './AddDates.types';
import Popup from '../../atoms/Popup/Popup';
import PopupHeader from '../../atoms/PopupHeader/PopupHeader';
import PopupMain from '../../atoms/PopupMain/PopupMain';
import { DATES } from '../../../constants/StoreConstants';
import { DateObjectType, getMinStayNextObject, getMinStayPrevObject } from '../../../utils/DateUtils';
import { DatesHeader } from '../../atoms/DatesHeader/DatesHeader';
import FlexibleDate from '../../atoms/FlexibleDate/FlexibleDate';
import { setDates } from '../../../store/store.actions';
import { CALENDAR_MAX_ALLOWED_DAYS, VARIATION_DESKTOP } from '../../../constants/ApplicationConstants';
import PopupFooter from '../../atoms/PopupFooter/PopupFooter';
import CommonUtils from '../../../utils/CommonUtils';
import Calendar from '../Calendar';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import { MONTHSHORTVALUE, WEEKDAYSLONGNAME, WEEKSDAYSSHORT } from '../../../../../constants/CommonConstants';
// import {
//   MONTHSHORTVALUE,
//   WEEKDAYSLONGNAME,
//   WEEKSDAYSSHORT,
// } from 'libs/mi-offers-components/src/constants/CommonConstants';
// import ErrorBoundary from '../../atoms/errorBoundary';

// const Calendar = React.lazy(() => import('@mi-apps/ui-library/src/molecules/Calendar'));

const AddDates: FC<AddDatesProps> = ({
  onCancel,
  onChange: changeMobileState,
  isHideFlexibleDates,
  acceptLanguage,
  isMPO = false,
  colorScheme,
}) => {
  const START_DATE = 'startDate';
  const END_DATE = 'endDate';
  type FocusedInputType = 'startDate' | 'endDate' | null;

  /** Contexts */
  const isMobileView = useContext(ViewportContext);
  const { formValues, setFormValues, setIsPopupOpen, maxCalendarDays } = useContext(FormValuesContext);
  const { focusComp } = useContext(FocusContext);
  // const { firstDayOfWeek, dateFormat } = useContext(ContentContext);
  const {
    firstDayOfWeek,
    checkInLabel,
    checkOutLabel,
    weekdays,
    weekdaysShort,
    weekdaysLong,
    months,
    monthsShort,
    dates,
    done,
    night,
    nights,
    dateFormat,
  } = useContext(ContentContext);
  const flexibleLabel = useContext(ContentContext)['flexible'];

  /** States */
  const [popupOpenState, setPopupOpenState] = useState(false);
  const [focusedState, setFocusedState] = useState<FocusedInputType>(START_DATE);
  // if from and to dates not available then show 'Check in - Check out' else "fromDate - toDate"
  const [displayText, setDisplayText] = useState<string>(`${checkInLabel} - ${checkOutLabel}`);

  const compRef = useRef<HTMLDivElement>(null);
  const { fromDate, toDate, flexible, minimumStay, checkin, checkout } = formValues?.[DATES] ?? {};
  const numberOfNights = CommonUtils.getNumberOfNights(fromDate, toDate);
  let labelText = dates ? dates : '';
  if (numberOfNights > 0) {
    labelText = `${numberOfNights} ${numberOfNights > 1 ? nights : night}${flexible ? ` (${flexibleLabel})` : ''}`;
  }

  // to get the dates based on the localization
  const getLocalizedDate = (date: DateObjectType | undefined): string => {
    moment.locale('locale');
    if (date) {
      //initially date will be in the english format we need to change that to the localized value
      const formattedDate = moment(date).format();
      return moment(formattedDate).format(dateFormat);
    } else {
      return '';
    }
  };

  // if from and to dates not available then show 'Check in - Check out' else "fromDate - toDate"
  useEffect(() => {
    if (fromDate || toDate) {
      setDisplayText(`${getLocalizedDate(fromDate)}${toDate ? ` - ${getLocalizedDate(toDate)}` : ''}`);
    }
  }, [fromDate, toDate]);

  // show flexible date checkbox when both from and to dates selected
  const showFlexible = fromDate && toDate && !isHideFlexibleDates;

  const [variation, setVariation] = React.useState<string>(CommonUtils.getVariation());

  let shortWeekdays = weekdays?.split(',');
  // set default weekdays if i18 weekdays value are invalid
  if (shortWeekdays?.length !== 7) {
    shortWeekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }

  let weeksdaysShort = weekdaysShort?.split(',');
  // set default weekdays if i18 weekdays value are invalid
  if (weeksdaysShort?.length !== 7) {
    weeksdaysShort = WEEKSDAYSSHORT;
  }

  let weekdaysLongName = weekdaysLong?.split(',');
  if (weekdaysLongName?.length !== 7) {
    weekdaysLongName = WEEKDAYSLONGNAME;
  }

  let monthsValue = months?.split(',');
  // set default month if i18 months value are invalid
  if (monthsValue?.length !== 12) {
    monthsValue = LocaleUtils.getMonths();
  }

  let monthsShortValue = monthsShort?.split(',');
  // set default month if i18 months value are invalid
  if (monthsShortValue?.length !== 12) {
    monthsShortValue = MONTHSHORTVALUE;
  }

  const setLanguageLocalization = (): void => {
    moment.locale('locale', {
      months: monthsValue,
      monthsShort: monthsShortValue,
      weekdays: weekdaysLongName,
      weekdaysShort: weeksdaysShort,
    });
  };

  // Set toDate and fromDate values
  const setToDateAndFromDate = (): void => {
    let newFromDate = fromDate as DateObjectType;
    let newToDate = toDate as DateObjectType;
    if (!fromDate && toDate) {
      //if fromDate is not selected setting the day before minstay.
      newFromDate = getMinStayPrevObject(newToDate, minimumStay ?? 1);
    }
    if (!toDate && newFromDate) {
      //if toDate is not selected setting the next day after minstay.
      newToDate = getMinStayNextObject(newFromDate, minimumStay ?? 1) ?? null;
    }
    setDateHandler(newFromDate, newToDate);
  };

  // effect to handle the changes after popup state is set to open
  useEffect(() => {
    // to scroll search form to top on interaction
    setIsPopupOpen?.(!!popupOpenState);

    if (popupOpenState) {
      setFocusOnFirstBtn();
      setFocusedState(START_DATE);
    } else {
      setToDateAndFromDate();
      setLanguageLocalization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupOpenState]);

  useEffect(() => {
    // To focus the first interactive element
    if (isMobileView) {
      CommonUtils.focusFirstTabbable(compRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // focus on the first interactive element in desktopview based on FocusContext API
    if (!isMobileView && focusComp === DATES) {
      CommonUtils.focusFirstTabbable(compRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusComp]);

  // to show popup on desktop on button click
  const showPopup = (): void => {
    setPopupOpenState(true);
  };

  // to show popup on desktop on button click
  const closePopup = (): void => {
    const sessionDateToSet = {
      startDate:
        formValues?.dates?.fromDate?.format('MM/DD/YYYY') ??
        getMinStayPrevObject(formValues?.dates?.toDate ?? moment(new Date()), minimumStay ?? 1)?.format('MM/DD/YYYY') ??
        null,
      endDate:
        formValues?.dates?.toDate?.format('MM/DD/YYYY') ??
        getMinStayNextObject(formValues?.dates?.fromDate ?? moment(new Date()), minimumStay ?? 1)?.format(
          'MM/DD/YYYY'
        ) ??
        null,
    };
    window.sessionStorage.setItem('OfferSearchDates', JSON.stringify(sessionDateToSet));
    let isInvalidSessionEndDate = false;
    if (sessionDateToSet?.startDate || sessionDateToSet?.endDate) {
      isInvalidSessionEndDate =
        moment(sessionDateToSet?.endDate) <
          moment(sessionDateToSet?.startDate).add(formValues?.dates?.minimumStay ?? 1, 'days') ||
        moment(sessionDateToSet?.endDate) > moment(formValues?.dates?.checkout);
    }
    isMPO
      ? eventUtil.dispatch('mpoInvalidSessionDateErrFlag', isInvalidSessionEndDate)
      : eventUtil.dispatch('invalidSessionDateErrFlag', isInvalidSessionEndDate);
    setPopupOpenState(false);
    compRef.current?.querySelector('button')?.focus();
  };

  const keyHandler = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (!e.shiftKey && e.keyCode === 9) {
      closePopup();
    }
  };
  const keyHandlerCalendarheader = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (e.shiftKey && e.keyCode === 9) {
      closePopup();
    }
  };

  const checkboxKeyHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && e.charCode === 13) {
      e.preventDefault();
      // const checkboxEle = document.querySelectorAll('.search-dates-popup input[name="flexibleDateSearch"]');
      // checkboxEle.length && checkboxEle[0].click();
      // setFormValues(
      //   setDates({
      //     ...(formValues?.[DATES] ?? {}),
      //     flexible: checked;
      //   }),
      // );
    }
  };

  // set focus on first button in the popup when popup state is set to open
  const setFocusOnFirstBtn = (): void => {
    const popupElemBtn = compRef.current?.querySelector('.search-dates-popup button') as HTMLElement;
    popupElemBtn?.focus();
  };

  // handler to set fromDate in the formValues
  const setDateHandler = (startDate: DateObjectType | null, endDate: DateObjectType | null): void => {
    const from = startDate ?? undefined;
    let end = endDate ?? undefined;

    // if from and end date are same then remove end date and keep focus on end date
    if (from?.isSame(end)) {
      setFocusedState(END_DATE);
      // end = undefined;
      end = moment(new Date(checkin ?? '')).add(minimumStay ?? 1, 'days');
    }

    setFormValues(
      setDates({
        ...(formValues?.[DATES] ?? {}),
        fromDate: from,
        toDate: end,
        isSelectedDate: true,
      })
    );
  };

  // clean button handler on check in
  const handleClearCheckIn = (): void => {
    setFocusedState(START_DATE);
    setDateHandler(null, null);
    window.sessionStorage.setItem('OfferSearchDates', '{}');
  };

  // clear button handler on checkout
  const handleClearCheckOut = (): void => {
    setFocusedState(END_DATE);
    setDateHandler(null, null);
    window.sessionStorage.setItem('OfferSearchDates', '{}');
  };

  // handler for flexible checkbox
  const flexibleChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormValues(
      setDates({
        ...(formValues?.[DATES] ?? {}),
        flexible: event.target?.checked ? event.target?.value : undefined,
      })
    );
  };

  // sets flexible false when flexible checkbox is hidden
  if (!showFlexible && flexible) {
    setFormValues(
      setDates({
        ...(formValues?.[DATES] ?? {}),
        flexible: undefined,
      })
    );
  }

  // Utility to set variation based on true value
  const setSuccessVariation = (flag: boolean, variation: string): void => {
    if (flag) {
      setVariation(variation);
    }
  };

  //To update variation based on viewport dimensions change
  useEffect(() => {
    // set viewport change event and return callback function to clear events
    const updateViewportHandler = CommonUtils.addRemoveResizeHandler(setSuccessVariation);

    return (): void => {
      // remove change event listner on component unmount
      updateViewportHandler();
    };
  }, []);

  // on click done in mobile state
  const changeMobileStateOnDone = (): void => {
    setToDateAndFromDate();
    changeMobileState?.();
  };

  return (
    <StyledAddDatesDiv ref={compRef}>
      {!isMobileView ? (
        <button
          type="button"
          onClick={showPopup}
          aria-expanded={popupOpenState}
          className="hover-60 desktop-calendar-btn"
        >
          <span className="search__calendar-label icon-nav---book t-label-xs">{labelText}</span>
          <span
            className={`h-r-form-field-txt search__calendar-value${!fromDate && !toDate ? '-empty' : ''} ${
              acceptLanguage === 'it-IT' && 'lowerCaseDate'
            }`}
          >
            {displayText}
          </span>
        </button>
      ) : (
        ''
      )}
      {isMobileView || popupOpenState ? (
        <Popup
          show={true}
          handleBlur={!isMobileView}
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
          className={`search-dates-popup ${colorScheme}`}
        >
          {isMobileView ? (
            <PopupHeader
              labelIcon="icon-nav---book"
              labelText={labelText}
              mainText={displayText}
              cancelClickHandler={isMobileView && onCancel ? onCancel : undefined}
              className="search-dates-popup__header"
            />
          ) : (
            <DatesHeader
              checkInDate={getLocalizedDate(fromDate)}
              checkOutDate={getLocalizedDate(toDate)}
              onClearCheckIn={handleClearCheckIn}
              onClearCheckOut={handleClearCheckOut}
              focusedCheckIn={focusedState === START_DATE}
              onClickCheckIn={(): void => setFocusedState(START_DATE)}
              onClickCheckOut={(): void => setFocusedState(END_DATE)}
              acceptLanguage={acceptLanguage}
              keyHandlerCalendarheader={keyHandlerCalendarheader}
            />
          )}
          <PopupMain className={`search-dates-popup__main ${variation !== VARIATION_DESKTOP ? 'h-r-center-item' : ''}`}>
            {/* <ErrorBoundary>
              <Suspense fallback={<div>Loading...</div>}> */}
            <Calendar
              startDate={fromDate ?? null}
              endDate={toDate ?? null}
              focusedInput={focusedState}
              setDateHandler={setDateHandler}
              setFocusedInputHandler={(newState: FocusedInputType): void => setFocusedState(newState)}
              variation={variation}
              nightLabel={night}
              nightsLabel={nights}
              maxAllowedDays={maxCalendarDays ?? CALENDAR_MAX_ALLOWED_DAYS}
              weekdays={shortWeekdays}
              months={monthsValue}
              weekdaysLong={weekdaysLongName}
              monthsShort={monthsShortValue}
              weekdaysShort={shortWeekdays}
              firstDayOfWeek={firstDayOfWeek as string}
              lengthOfStay={minimumStay}
              validOffersStartDate={moment(checkin)}
              validOffersEndDate={moment(checkout)}
            />
            {/* </Suspense>
            </ErrorBoundary> */}
            {showFlexible && isMobileView && !isMPO ? (
              <FlexibleDate onChange={flexibleChangeHandler} checked={!!flexible} />
            ) : (
              ''
            )}
          </PopupMain>
          {isMobileView ? (
            <PopupFooter primaryBtnLabel={done} primaryBtnClickHandler={changeMobileStateOnDone} />
          ) : (
            <StyledDatesFooter className="brand-css">
              {showFlexible && !isMPO ? (
                <FlexibleDate
                  onChange={flexibleChangeHandler}
                  checkboxKeyHandler={checkboxKeyHandler}
                  checked={!!flexible}
                />
              ) : (
                <div />
              )}
              <button type="button" onClick={closePopup} onKeyDown={keyHandler} className="m-button-primary brand-css">
                {done}
              </button>
            </StyledDatesFooter>
          )}
        </Popup>
      ) : (
        ''
      )}
    </StyledAddDatesDiv>
  );
};

export default AddDates;
