import { OffersHeroBannerProps } from '../OffersHeroBanner.types';

export const mockOffersHeroBannerData: OffersHeroBannerProps = {
  offersData: {
    uri: '/offers-dca-preview/038/OFF-038899-00',
    isPreview: 'true',
    offerId: 'OFF-038899-00',
    marsha: 'BJSLF',
    locale: 'en-US',
    brand: 'SI',
    responseObject: {
      edges: [
        {
          id: 'OFF-038899-00',
          node: {
            id: 'OFF-038899',
            title: 'Header field',
            name: null,
            urlTitle: null,
            url: '',
            seoNickname: 'sheraton-langfang-chaobai-river-hotel',
            description: 'This is the Description field',
            descriptionTeaser: 'This is Eyebrow field',
            searchable: true,
            numProperties: 1,
            numberOfOutlets: 0,
            offerCategory: null,
            parentOfferType: 'Merchandising Offer',
            offerType: 'CoBrand',
            stayStartDate: '2023-12-20',
            stayEndDate: '2024-01-31',
            bookingEndDate: '2024-01-31',
            bookingStartDate: '2023-12-20',
            minimumStay: 1,
            needToKnow: ['Bullet One', 'Bullet Two', 'Bullet Three'],
            clusterCode: null,
            reservationMessage: null,
            additionalTerms: null,
            termsAndConditions: 'This is Legal field for longer legal data',
            callToActionLink: 'https://www.marriott.com',
            callToActionLabel: 'CTA One',
            recurrencePattern: null,
            validDays: 'Monday;Tuesday;Wednesday;Thursday;Friday',
            associatedOutlets: [
              {
                outlets: [],
              },
            ],
            participatingProperties: {
              brandName: 'Sheraton',
              brandCode: 'SI',
              subBrandCode: null,
              country: 'China',
              countryCode: 'CN',
              stateProvince: null,
              stateCode: null,
              city: 'Langfang',
              properties: [
                {
                  id: 'BJSLF',
                  basicInformation: {
                    name: 'Sheraton Langfang Chaobai River Hotel',
                    currency: 'CNY',
                    brand: {
                      id: 'SI',
                    },
                  },
                },
              ],
            },
            photos: {
              name: 'tx-mcntx-ballroom-wedding--16514',
              captions: [
                {
                  length: 'short',
                  caption: 'Ballroom Wedding Reception Setup',
                },
                {
                  length: 'medium',
                  caption: 'Ballroom Wedding Reception Setup',
                },
                {
                  length: 'large',
                  caption: 'Our Rhythm & Blues Ballroom will set the tone for a memorable wedding reception in Macon.',
                },
              ],
              images: [
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Classic-Hor',
                  aspectRatio: 'Classic',
                },
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Feature-Hor',
                  aspectRatio: 'Feature',
                },
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Pano-Hor',
                  aspectRatio: 'Pano',
                },
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Square',
                  aspectRatio: 'Square',
                },
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Wide-Hor',
                  aspectRatio: 'Wide',
                },
              ],
            },
          },
          merchandisingNode: {
            cell: '54321',
            countryCode: 'US',
            guidSecondary: null,
            issuerCode: '654321',
            merchandisingTag: 'MER123',
            campaignId: 'CAM12345',
            offerCode: '665544',
            optionalFields: 'Optional',
            prequal: true,
            rpcCode: '1234',
            spid: '1234,2345,3456',
            theme: 'Light',
            caption: 'Caption',
            captionUrl: 'captionurl@marriott.com',
            descriptionShort: 'This is Description Short',
            headerLong: 'Header Long field',
            headerShort: 'Header Short field',
            promotionalTag: 'Member Exclusive-en_US',
            secondaryCallToActionLabel: 'CTA Two',
            secondaryCallToActionLink: 'https://www.marriott.com/offers.mi',
          },
        },
      ],
      facets: [],
    },
    responseStatus: {
      code: '200',
      message: 'SUCCESS',
    },
  },

  model: {
    heroSize: '2x1',
    eyebrowtext: 'Sample eyebrow',
    secondaryctalink: '#',
    primaryctalabel: 'Learn More',
    componentId: 'HeroBannerDCAPreview',
    secondaryopeninanewtab: true,
    videoaccountId: '6051814380001',
    dataLayer: {
      'herobanner-482960cb15': {
        '@type': 'mi-aem-homepage-spa/components/content/herobanner',
        'repo:modifyDate': '2024-01-23T10:38:56Z',
      },
    },
    locationopeninanewtab: false,
    scrim: 'mb-3 mt-3',
    resourceProperties: {
      heroSize: '2x1',
      eyebrowtext: 'Sample eyebrow',
      secondaryctalink: '#',
      primaryctalabel: 'Learn More',
      componentId: 'HeroBannerDCAPreview',
      secondaryopeninanewtab: 'true',
      videoaccountId: '6051814380001',
      locationopeninanewtab: 'false',
      primaryopeninanewtab: 'true',
      impressionTrack: 'false',
      'sling:resourceType': 'mi-aem-homepage-spa/components/content/herobanner',
      secondaryctalabel: 'Learn More',
      backgroundimagealttext: 'Person in a boat with another person snorkeling next to the boat',
      primaryctalink: '#',
      headertext: 'Explore Our Brands',
      imageLoading: 'eager',
      fileReferenceImage:
        '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-HongKong-921185062997893-168312741943293.jpg',
      enableAutoPlay: 'false',
      text: 'herobanner',
      impressionCount: 'false',
      merchandisingCategory: 'nonCobrand',
      enableMaskingOnDesktop: 'true',
      subheadingtext:
        'This hotel is a social butterfly that knows how to make everyone feel special. See for yourself at the not-so-secret speakeasy',
      fileReferenceBackgroundImagemobile:
        '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo893242722462014-168262788295778.jpg',
      aspectRatioMobileVideo: '1x1',
      enableVideoLoop: 'false',
      headertextstyle: 'withoutCoBrand',
      enableMute: 'false',
      cobrandmathhero: 'false',
      fileReferenceBackgroundImagedesktop:
        '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo802304433255482-168262816722252.jpg',
      assetsVariation: 'image',
      clickTrack: 'true',
      hideLocationCtaMobile: 'true',
      copyBlockWidth: 'default',
    },
    primaryopeninanewtab: true,
    secondaryctalabel: 'Learn More',
    trackingProperties: {
      atCCeVar48: null,
      trackingDescription: null,
      clickTrack: true,
      impressionTrack: false,
      trackingTag2: null,
      trackingTag1: null,
      merchandisingCategory: 'nonCobrand',
      trackingContentPosition: null,
      impressionCount: false,
      trackingOfferType: null,
    },
    playerDomain: 'https://players.brightcove.net',
    ':type': 'mi-aem-homepage-spa/components/content/herobanner',
    backgroundimagealttext: 'Person in a boat with another person snorkeling next to the boat',
    imgAltText: 'Hong Kong street food shop',
    primaryctalink: '#',
    backgroundMedia: {
      altText: null,
      assetPath: 'https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammo802304433255482-168262816722252',
      enableSmartCrop: true,
      dynamic: true,
      renditions: [
        {
          renditionPath:
            'https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammo802304433255482-168262816722252:Wide-Hor?wid=1440&hei=810, https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammo802304433255482-168262816722252:Wide-Hor?wid=2880&hei=1620 2x',
          mediaValue: '992px',
          width: 1440,
          dynamic: true,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo802304433255482-168262816722252.jpg',
          mediaQuery: 'min-width',
          height: 810,
        },
        {
          renditionPath:
            'https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammo802304433255482-168262816722252:Wide-Hor?wid=768&hei=432, https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammo802304433255482-168262816722252:Wide-Hor?wid=1536&hei=864 2x',
          mediaValue: '768px',
          width: 768,
          dynamic: true,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo802304433255482-168262816722252.jpg',
          mediaQuery: 'min-width',
          height: 432,
        },
        {
          renditionPath:
            'https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammo893242722462014-168262788295778:Classic-Ver?wid=365&hei=563, https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammo893242722462014-168262788295778:Classic-Ver?wid=730&hei=1126 2x',
          mediaValue: '767.5px',
          width: 365,
          dynamic: true,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo893242722462014-168262788295778.jpg',
          mediaQuery: 'max-width',
          height: 563,
        },
      ],
      damPath:
        '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo802304433255482-168262816722252.jpg',
    },
    headertext: 'Explore Our Brands',
    appliedCssClassNames: 'mt-3 mb-3',
    imageLoading: 'eager',
    fileReferenceImage:
      '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-HongKong-921185062997893-168312741943293.jpg',
    enableAutoPlay: false,
    id: 'herobanner-482960cb15',
    enableMaskingOnDesktop: true,
    playerId: 'default',
    subheadingtext:
      'This hotel is a social butterfly that knows how to make everyone feel special. See for yourself at the not-so-secret speakeasy',
    fileReferenceBackgroundImagemobile:
      '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo893242722462014-168262788295778.jpg',
    aspectRatioMobileVideo: '1x1',
    enableVideoLoop: false,
    headertextstyle: 'withoutCoBrand',
    enableMute: false,
    fileReferenceBackgroundImagedesktop:
      '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo802304433255482-168262816722252.jpg',
    cobrandmathhero: false,
    opensInNewTab: 'Opens a new window',
    verticalImageAltText: '',
    assetsVariation: 'image',
    hideLocationCtaMobile: true,
    copyBlockWidth: 'default',
  },
  isAuthorMode: true,
};
