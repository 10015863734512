import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

interface RecommendedMinicard {
  isOutletPage: boolean;
  isfallBackImage: string;
  bgColor?: string;
}

const landscapeTablet = `screen and (min-width: ${toRem(1026)})`;

export const StyledRecommendedMiniCard = styled.div<RecommendedMinicard>`
  background-color: ${props =>
    !props.isOutletPage && props.bgColor === 'offwhite'
      ? `${baseVariables.color.neutral20}`
      : !props.isOutletPage && props.bgColor === 'black'
      ? `${baseVariables.color.merch30}`
      : 'none'};
  padding: ${props => (props.isOutletPage ? `0 0 ${toRem(80)}` : `${toRem(40)} 0 ${toRem(80)} 0`)};

  @media ${baseVariables.mediaQuery.md} {
    padding: ${props => (props.isOutletPage ? `${toRem(16)} 0 ${toRem(172)}` : `${toRem(40)} 0 ${toRem(80)} 0`)};
  }

  @media ${baseVariables.mediaQuery.sm} {
    padding: ${toRem(20)} 0 ${toRem(80)} 0;
  }

  .miniCard-border-box-rnb {
    border-radius: 14px;
    border: ${baseVariables.color['cod-grey']};
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
    background-color: ${baseVariables.color.base20};
    margin: ${toRem(14)} ${toRem(14)};
  }

  .recommend-title-rnb {
    margin: 0 ${toRem(28)};
    .explore-link {
      color: ${props =>
        props.bgColor === 'offwhite' ? `${baseVariables.color.merch30}` : `${baseVariables.color.merch50}`};
    }
  }

  .left-arrow {
    color: ${props =>
      props.bgColor === 'offwhite' ? `${baseVariables.color.black}` : `${baseVariables.color.base20}`};
  }

  .right-arrow {
    color: ${props =>
      props.bgColor === 'offwhite' ? `${baseVariables.color.black}` : `${baseVariables.color.base20}`};
  }

  .glide__slide {
    @media ${baseVariables.mediaQuery.smallMobile} {
      padding: ${toRem(16)} ${toRem(16)} 0 ${toRem(16)};
    }
    @media ${baseVariables.mediaQuery.mobile} {
      padding: ${toRem(8)} ${toRem(8)} ${toRem(0)} ${toRem(8)};
    }
  }

  .glide__slides,
  .glide__track {
    overflow: hidden;
  }

  .glide__slides {
    list-style-type: none;
  }

  .glide__bullet {
    background-color: ${props =>
      props.bgColor === 'offwhite' ? `${baseVariables.color.black}` : `${baseVariables.color.base20}`};
    opacity: ${props => (props.bgColor === 'offwhite' ? `0.3` : `0.3`)};
  }

  .carouselControlType3b button[class*='-active']::after {
    background-color: ${props =>
      props.bgColor === 'offwhite' ? `${baseVariables.color.black}` : `${baseVariables.color.base20}`};
  }

  .carouselControlType3b .right-arrow:hover {
    background-color: ${props =>
      props.bgColor === 'offwhite' ? `${baseVariables.color.gallery}` : `${baseVariables.color.black}`};
  }
  .carouselControlType3b .left-arrow:hover {
    background-color: ${props =>
      props.bgColor === 'offwhite' ? `${baseVariables.color.gallery}` : `${baseVariables.color.black}`};
  }

  .icon-arrow-right-cropped:before {
    color: ${props =>
      props.bgColor === 'offwhite' ? `${baseVariables.color.black}` : `${baseVariables.color.base20}`};
  }

  .miniCard-border-desktop-rnb {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
    background-color: ${baseVariables.color.base20};
    border: ${baseVariables.color['cod-grey']};
  }

  .glide__arrow--disabled {
    opacity: 0.2;
  }

  .glide__arrow {
    position: static;
    transform: none;
    box-shadow: none;
  }

  .miniCardContent .miniCardBody [class*='t-subtitle-'] {
    overflow: unset;
    -webkit-box-orient: unset; /* stylelint-disable-line */
    white-space: unset;
  }

  @media ${baseVariables.mediaQuery.allTablets} {
    .miniCard-border-box-rnb {
      width: 100%;
      height: 100%;
      margin: ${toRem(16)} ${toRem(14)};
    }
    .miniCard-carousal-container {
      display: block;
    }
    .recommend-title-rnb {
      margin: 0 ${toRem(28)};
    }
  }

  @media ${landscapeTablet} {
    .miniCard-carousal-container {
      display: none;
    }
    .miniCard-border-box-rnb {
      border: ${baseVariables.color['cod-grey']};
    }

    .recommend-title-rnb {
      padding-bottom: ${toRem(16)};
    }

    .miniCardContent .miniCardBody [class*='t-subtitle-'] {
      overflow: unset;
      -webkit-box-orient: unset; /* stylelint-disable-line */
    }
  }

  @media ${baseVariables.mediaQuery.largeDesktop} {
    .miniCard-border-box-rnb {
      flex-direction: row;
      width: ${toRem(334)};
      height: ${toRem(100)};
      border: ${baseVariables.color['cod-grey']};
      margin: ${toRem(16)} ${toRem(14)};
    }

    .recommend-title-rnb {
      padding-bottom: ${toRem(16)};
    }

    .miniCard-carousal-container {
      display: none;
    }

    .miniacrd-desktop {
      display: block;
    }

    .miniCardContent .miniCardBody [class*='t-subtitle-'] {
      overflow: unset;
      -webkit-box-orient: unset; /* stylelint-disable-line */
    }
  }

  .miniCard-main-title {
    margin-bottom: ${toRem(40)};

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      margin-bottom: ${toRem(60)};
    }
  }

  .fallback-image {
    .miniCardImage {
      background: ${props => (props.isfallBackImage ? `url(${props?.isfallBackImage}) rgba(0, 0, 0, 0.5)` : 'none')};
      background-blend-mode: ${props => (props.isfallBackImage ? 'multiply' : 'unset')};
    }
  }
`;
