import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledFaqModalWrapper = styled.div`
  .accordion-body {
    padding: ${toRem(24)} 0 0 ${toRem(8)};
    @media ${baseVariables.mediaQuery.md} {
      padding: ${toRem(32)} ${toRem(11)} 0 ${toRem(24)};
    }
  }

  .modal-accordion-header {
    padding: ${toRem(28)} ${toRem(24)};
    @media ${baseVariables.mediaQuery.md} {
      padding: ${toRem(32)};
    }
  }

  .accordion__container .seperator {
    display: block;
    height: unset;
    border: ${toRem(1)} solid ${baseVariables.color.neutral30};
  }

  .t-horizontal-vertical-rule {
    color: unset;
    background-color: unset;
  }
`;
