/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
//prePageCall is as server side method to be called from:
//1. [[page.tsx]] to fetch model.json from AEM Author/Publish instances
//2. Product specific pages to fetch model.json for end user.
//3. Call dataLayer, session, interceptor and return the data as props to pages.
import { fetchModel } from '@adobe/aem-react-editable-components';
import { isEmpty, isNonEmptyString } from '@marriott/shared/mi-helper-utils';
import { ApolloEnvVars, DeployedEnvType, isLocalDev } from '@marriott/mi-apollo-client-utils';
import { inspect } from 'util';

import {
  getFeatureFlagData,
  getPageModel,
  getSessionData,
  respGridUtil,
  getSessionDataWithParams,
  processAcceptLanguage,
} from './helpers';
import { logger } from './logger';
import { MOCK_SESSION_ID } from './constants';
import { getDataLayer } from './dataLayer';
import { interceptor } from './interceptor';
import { useGlobalStore } from '@marriott/mi-store-utils';

const {
  NEXT_PUBLIC_AEM_AUTH,
  REDIRECT_URL,
  NEXT_PUBLIC_AEM_HOST,
  NEXT_PUBLIC_APP_NAME,
  USE_MOCK_MODEL,
  INVALID_REDIRECT_URL,
  SKIP_DATALAYER_CALL,
  SKIP_SESSION_CALL,
  SKIP_INTERCEPTOR_CALL,
  SKIP_FEATURE_FLAG_CALL,
  NEXT_AEM_AUTH,
  IS_LOCAL_DEV,
  IS_LOCAL_AEM_DEV,
  IS_SESSION_APP_GET_CALL_V1,
  DISABLE_APOLLO_CLIENT,
  APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG = '',
  APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION = '',
  APOLLOGRAPHQL_PUBLIC_CLIENT_NAME = '',
  APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME = '',
  APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING = '',
  APOLLOGRAPHQL_AUTH_TOKEN = '',
  APOLLOGRAPHQL_FULL_NGINX_ENDPOINT = '',
  APOLLOGRAPHQL_PUBLIC_LOCAL_DEV = '',
  APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH = '',
  APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH = '',
  DEPLOYED_ENV_TYPE = '',
  APOLLOGRAPHQL_PUBLIC_DEBUG_LOG = '',
  APOLLOGRAPHQL_LOCAL_ENDPOINT = '',
  IS_CN_ENV,
  UXL_IMAGE_CACHE_DOMAIN = '',
  APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW = '',
} = process.env;

declare global {
  // eslint-disable-next-line no-var
  var loggerInstance: CallableFunction;
}

//prePageCall is as server side method to be called from:
//1. [[page.tsx]] to fetch model.json from AEM Author/Publish instances
//2. Product specific pages to fetch model.json for end user.
export function prePageCall(
  productSpecificCalls:
    | boolean
    | ((context?: { [key: string]: any }, parallellCallsArr?: any, interceptorResponse?: any) => Promise<any>) = false,
  mockModel = {},
  isFromSlug?: boolean,
  removeServerEnv = false,
  preserveLocaleInUrl = false
) {
  return async (context: { [key: string]: any }) => {
    //Initialization start
    const { req, res, query } = context;
    let pagePath = '';

    const resolvedUrlString: string = context['resolvedUrl'] ?? req.url;
    const isAuthorMode = resolvedUrlString.includes('isAuthorMode');
    let resolvedUrl = resolvedUrlString.split('?')[0];
    const requestUri = resolvedUrl;
    const headersData = req?.headers;
    const cookies = req?.cookies;
    let interceptorResponse;
    let productSpecificProps: any = {};
    let model;
    let selector;
    let eaaPageIndicator;

    global.loggerInstance = logger({
      sessionID: req?.cookies?.sessionID,
      requestID: req.headers['x-request-id'],
    }) as CallableFunction;
    const { log } = global.loggerInstance('Preprocessor');

    // Validate incoming accept-lang header as it will be passed
    // to all microservices requests.
    let currentLocale = processAcceptLanguage(req.headers['accept-language'] ?? '');
    const initialLocale = currentLocale;

    const requestId = req.headers['x-request-id'] ?? `${Date.now()}`;
    const sessionIDVal = req?.cookies?.sessionID ?? MOCK_SESSION_ID;
    let cnEnvVars: { [key: string]: string } = {};

    const parallelCallsResponse: { [key: string]: any } = {};

    const isLoggedIn = cookies?.UserIdToken ? true : false;
    let sessionRedirect = false;
    let interceptorCookieArr: any;
    let sessionCookieArr: any;

    const redirect = {
      redirect: {
        permanent: false,
        // The routes we have configured in AEM are all 5-char routes (fr-fr, en-us)
        // so a valid 2-char locale used here (en, de) would cause an endless loop redirect.
        destination:
          preserveLocaleInUrl && currentLocale.length === 5
            ? `/${currentLocale.toLowerCase()}${REDIRECT_URL}`
            : REDIRECT_URL,
      },
    };

    log.debug('redirect: ', JSON.stringify(redirect));

    const preHeader: Record<string, string> = {
      'Content-Type': 'application/json',
      'x-request-id': req.headers['x-request-id'] || '',
      Cookie: `sessionID=${req?.cookies?.sessionID};UserIdToken=${
        req?.cookies?.UserIdToken ? req?.cookies?.UserIdToken : ''
      }`,
      'x-host': req.headers['x-host'] || '',
      'accept-language': currentLocale || '',
    };

    //Initialization end

    // Redirection to Custom 404 Page - Commenting the code to check redirection from Nginx
    // if (!isAuthorMode && isFromSlug && INVALID_REDIRECT_URL) {
    //   return { redirect: { destination: INVALID_REDIRECT_URL, permanent: false } };
    // }

    const logVars = {
      resolvedUrl,
      currentLocale,
      requestId,
      isAuthorMode,
      isLoggedIn,
      IS_LOCAL_AEM_DEV,
      isLocalDev,
      headersData,
    };
    log.debug(JSON.stringify(logVars));

    //function to generate list of parallel api calls based on env flags
    function generateParallelCallsList(): Array<{ [key: string]: Promise<any> | string }> {
      const parallelCallsArray: Array<{ [key: string]: Promise<any> | string }> = [];
      if (SKIP_SESSION_CALL !== 'true') {
        const sessionId = req?.cookies?.sessionID ?? MOCK_SESSION_ID;
        if (IS_SESSION_APP_GET_CALL_V1 === 'true') {
          parallelCallsArray.push({
            api: 'sessionData',
            apiHandler: getSessionDataWithParams(preHeader, IS_LOCAL_DEV === 'true', isAuthorMode),
          });
        } else {
          parallelCallsArray.push({
            api: 'sessionData',
            apiHandler: getSessionData(sessionId, IS_LOCAL_DEV === 'true', isAuthorMode),
          });
        }
      }
      if (SKIP_DATALAYER_CALL !== 'true' && !isAuthorMode) {
        parallelCallsArray.push({ api: 'dataLayer', apiHandler: getDataLayer(req, resolvedUrl, currentLocale, query) });
      }
      if (SKIP_FEATURE_FLAG_CALL !== 'true' && !isAuthorMode) {
        parallelCallsArray.push({ api: 'getFeatureFlagData', apiHandler: getFeatureFlagData() });
      }
      return parallelCallsArray;
    }

    //interceptor call is the first api call on serveside
    if (SKIP_INTERCEPTOR_CALL !== 'true' && IS_LOCAL_DEV !== 'true' && !isAuthorMode) {
      try {
        interceptorResponse = await interceptor(req, resolvedUrlString);
        log.debug(`Interceptor response: ${JSON.stringify(interceptorResponse?.data)}`);
        // locale value present in interceptor response
        const interceptorLocaleKey = processAcceptLanguage(
          interceptorResponse?.data?.requestAttributes?.pageManagerRequest?.global?.localeKey ?? ''
        );
        if (isNonEmptyString(interceptorLocaleKey) && currentLocale !== interceptorLocaleKey) {
          currentLocale = interceptorLocaleKey;
          log.debug(`Interceptor locale change: ${initialLocale}->${currentLocale}`);
        }

        interceptorCookieArr = interceptorResponse?.headers?.['set-cookie'] ?? '';

        //Interceptor redirect rules for account team only
        if (
          (process.env['NEXT_PUBLIC_APP_INTERCEPTOR_ID'] === 'account' ||
            process.env['NEXT_PUBLIC_APP_INTERCEPTOR_ID'] === 'groups') &&
          interceptorResponse?.data?.redirect &&
          interceptorResponse?.data?.redirectionPath
        ) {
          return {
            redirect: {
              permanent: false,
              destination: interceptorResponse?.data?.redirectionPath,
            },
          };
        }
      } catch (e) {
        log.error('Interceptor failed:', e);
        //redirecting user to configured page if interceptor api fails
        return redirect;
      }
    }

    const parallellCallsArr: Array<{ [key: string]: any }> = generateParallelCallsList(); //list of parallel apis to be made

    //Using Promise.allSettled because flow should not stop if any one of the api call fails.
    // For eg: If datalayer fails session and feature flag calls should not be skipped
    const responses = await Promise.allSettled(parallellCallsArr.map(parallelCall => parallelCall['apiHandler']));
    responses.forEach((resp: { [key: string]: any }, index) => {
      if (resp['status'] === 'fulfilled') {
        //if api call is success add the response to return object
        parallelCallsResponse[parallellCallsArr[index]['api']] = resp['value'];
        if (parallellCallsArr[index]['api'] === 'sessionData') {
          parallelCallsResponse[parallellCallsArr[index]['api']] = resp['value']['sessionData'];
          useGlobalStore.setState({ sessionData: resp['value']['sessionData'] });
          const cookieArr = resp['value'].sessionHeader?.['set-cookie'] ?? '';
          sessionCookieArr = cookieArr;
          if (cookieArr) {
            res.setHeader('Set-Cookie', cookieArr);
          }
        }

        // below code add Affiliate cookie if user landed from affiliate lint and this is common pre Processor similar implementation we seen in books preprocessor.
        if (interceptorCookieArr) {
          res.setHeader(
            'Set-Cookie',
            sessionCookieArr ? [...sessionCookieArr, ...interceptorCookieArr] : interceptorCookieArr
          );
        }
      } else if (resp['status'] === 'rejected') {
        //if session call fails redirect user to configured page and datalayer, feature flag apis fail silently
        if (parallellCallsArr[index]['api'] === 'sessionData' && !IS_LOCAL_DEV && !isAuthorMode) {
          sessionRedirect = true;
        }
      }
    });

    if (sessionRedirect) {
      return redirect;
    }

    //fetch any product specific data using the callback passed by product specific [[..page.tsx]].
    if (productSpecificCalls && typeof productSpecificCalls === 'function') {
      productSpecificProps = await productSpecificCalls(context, parallelCallsResponse, interceptorResponse?.data);
      // update resolved URL incase any product specific URI exists
      log.debug(`productSpecificProps: ${JSON.stringify(productSpecificProps)}`);

      if (productSpecificProps?.uri && !isAuthorMode) {
        resolvedUrl = productSpecificProps?.uri;
      }
      if (productSpecificProps?.redirectUrI && !isAuthorMode) {
        log.debug(`productSpecificProps redirect url: ${productSpecificProps.redirectUrI}`);
        redirect.redirect.destination = productSpecificProps.redirectUrI;
      }
    }

    //IF product specific call fails and user has to be redirected to some error page when it is not local or author instance
    if (productSpecificProps?.error && productSpecificProps?.error.value === true && !isLocalDev && !isAuthorMode) {
      log.error('Product specific error:', productSpecificProps?.error);

      return productSpecificProps?.error?.redirect;
    }

    // Redirection to Custom 404 Page - Commenting the code to check redirection from Nginx
    if (!isAuthorMode && isFromSlug && INVALID_REDIRECT_URL) {
      log.error(`Invalid redirect: ${res.statusCode}->404`);
      res.statusCode = 404;
      res.end();
    }

    // AEM call to fetch models
    try {
      if (String(USE_MOCK_MODEL) === 'true') {
        // To use mock model for local development set env USE_MOCK_MODEL to true in env.development file and
        //pass mockModel as parameter to prePageCall function
        log.debug('Utilizing mock model...');
        // Dynamic Import of Mock Model only in development - Not needed in productions, hence this check (Bundle Size)
        if (typeof mockModel === 'function') {
          mockModel = await mockModel();
        }
        model = respGridUtil(mockModel);
      } else {
        // The third OR condition is a fallback unless application teams start using IS_LOCAL_AEM_DEV for conneting to local AEM instance
        if (isAuthorMode || IS_LOCAL_AEM_DEV === 'true' || (!IS_LOCAL_AEM_DEV && isLocalDev)) {
          //By default fetch model from local AEM author instance for local development
          const pagePathArr = query['page'] as Array<string>;
          pagePath = `/${pagePathArr?.join('/')}`;

          model = await fetchModel({
            pagePath: resolvedUrl,
            host: NEXT_PUBLIC_AEM_HOST,
            options: {
              headers: {
                Authorization: (NEXT_PUBLIC_AEM_AUTH || NEXT_AEM_AUTH) as string, //TODO: NEXT_PUBLIC_AEM_AUTH to be removed once all the products remove it
              },
            },
          });
        } else {
          if (productSpecificProps?.selector) {
            selector = (productSpecificProps?.selector ?? '').toUpperCase();
          }
          if (productSpecificProps?.isEAA === true) {
            eaaPageIndicator = 'EAA';
          }
          const validProductSpecificPropsLocale = isNonEmptyString(productSpecificProps?.currentLocale)
            ? processAcceptLanguage(productSpecificProps?.currentLocale)
            : undefined;
          if (validProductSpecificPropsLocale && validProductSpecificPropsLocale !== currentLocale) {
            currentLocale = validProductSpecificPropsLocale;
            log.debug(`Product specific locale change: ${initialLocale}->${currentLocale}`);
          }

          //For lower and higher envs fetch model via hybrid config app
          const modelData = await getPageModel(
            resolvedUrl,
            currentLocale,
            sessionIDVal,
            productSpecificProps?.appId ?? (NEXT_PUBLIC_APP_NAME || ''),
            selector,
            isLoggedIn,
            eaaPageIndicator,
            productSpecificProps?.EXPERIENCE_SEGMENT,
            productSpecificProps?.productSpecificSeoUrl
          );
          if (isEmpty(modelData)) {
            if (!isAuthorMode) {
              log.debug(`Page model empty or missing, attempting to redirect to: ${redirect.redirect.destination}`);
              return redirect;
            }

            log.error('Failed to fetch page model.');
          }
          model = respGridUtil(modelData);
          log.debug('Publish mode model:', inspect(model));
        }
      }

      const getProcessENV = () => {
        const envKeys: Record<string, string> = {};
        (productSpecificProps?.nextPublicEnvKeys ?? []).forEach((key: string) => {
          envKeys[key] = process.env[key] ?? '';
        });
        return envKeys;
      };

      const apolloEnvVars: ApolloEnvVars | Record<string, never> =
        DISABLE_APOLLO_CLIENT !== 'true'
          ? {
              APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW,
              APOLLOGRAPHQL_FULL_NGINX_ENDPOINT,
              APOLLOGRAPHQL_LOCAL_ENDPOINT,
              APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG,
              APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME,
              APOLLOGRAPHQL_PUBLIC_CLIENT_NAME,
              APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION,
              APOLLOGRAPHQL_PUBLIC_DEBUG_LOG,
              APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH,
              APOLLOGRAPHQL_PUBLIC_LOCAL_DEV,
              APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH,
              APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING,
              DEPLOYED_ENV_TYPE: DEPLOYED_ENV_TYPE as DeployedEnvType,
              // returning auth token only for local development. For serverside calls auth token will be read from process.env
              ...(IS_LOCAL_DEV === 'true' && { APOLLOGRAPHQL_AUTH_TOKEN }),
            }
          : {};

      //IS_CN_ENV is true , then we will be using UXL_IMAGE_CACHE_DOMAIN = https://cache.marriott.com.cn for CN.
      if (IS_CN_ENV) {
        cnEnvVars = {
          UXL_IMAGE_CACHE_DOMAIN,
        };
        log.debug('cnEnvVars ', cnEnvVars);
      }
      return {
        props: {
          isAuthorMode,
          model: model,
          pagePath,
          resolvedUrl,
          requestUri,
          requestId,
          currentLocale,
          datalayerUrl: process.env['CLIENT_DATA_LAYER_PATH'] || '',
          headersData,
          cookies,
          query,
          selector: selector ?? '',
          ...(!removeServerEnv && { serverENV: JSON.stringify(getProcessENV()) }),
          params: context?.['params'] ? context?.['params'] : '',
          USE_MOCK_MODEL: String(process.env['USE_MOCK_MODEL']) === 'true',
          ...parallelCallsResponse,
          ...productSpecificProps,
          interceptorResponse: interceptorResponse?.data || {},
          apolloEnvVars,
          cnEnvVars,
        },
      };
    } catch (e) {
      if (!isAuthorMode) {
        log.error(`Failed, attempting to redirect to '${redirect.redirect.destination}':`, e);
        return redirect;
      }

      log.error(`Failed:`, e);
    }
  };
}
