/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import {
  DATE_MONTH_YEAR_FORMAT,
  GB_LOCALE_PATTERN_HYPHEN,
  US_LOCALE,
  JP_LOCALE,
  FR_LOCALE,
  IT_LOCALE,
  ES_LOCALE,
  PT_BR_LOCALE,
  YEAR_MON_DATE_FORMAT,
  KR_LOCALE,
  YEAR_MON_DATE_DOTTED_FORMAT,
  RU_LOCALE,
  DATE_DOTTED_FORMAT,
  DE_LOCALE,
  MOMENT_CN_LANG,
  TW_LOCALE,
  DATE_FORMAT_YEAR_MONTH_DAY,
  DATE_FORMAT_VALUE,
} from '../constants';
import { addSubDirectoryPrefix } from '../../utils/src/subDirectoryFunctions';
import { changeCountryCodeForChina } from '../../utils/src/helper';

// function to filter query paramteres with empty, null, or undefined value
const filterParams = (obj: any) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, val]) => !['', undefined, null, 'Invalid date'].includes(val as any) && key !== ''
    )
  );
};

const roomCountMap: Record<string, string> = {
  '4-9': '4',
  '10-25': '10',
  '26+': '26',
};

const roomCountMapForJPLocale: Record<string, string> = {
  '4〜9': '4',
  '10〜25': '10',
  '26以上': '26',
};

//function for generating params for Hotels and HP search form submit
export const performSubmitSearchFormAction = (
  submitSearchParams: any,
  searchformRef: any,
  submitSearchUrl: string | undefined,
  availabilitySearchUrl: string | undefined,
  currentLocale: string | undefined
) => {
  // Logic to pass the expected value for roomCount query parameter
  const params = filterParams(submitSearchParams);
  if (params?.['roomCount']) {
    if (currentLocale === JP_LOCALE) {
      params['roomCount'] = roomCountMapForJPLocale[params['roomCount'] as string] || params['roomCount'];
    } else {
      params['roomCount'] = roomCountMap[params['roomCount'] as string] || params['roomCount'];
    }
  }
  if (params?.['propertyCode']) {
    searchformRef.current.action = addSubDirectoryPrefix(availabilitySearchUrl);
  } else {
    searchformRef.current.action = addSubDirectoryPrefix(submitSearchUrl);
  }
  for (const param in params) {
    const input = document.createElement('input');
    input.setAttribute('name', param);
    input.setAttribute('value', params[param] as string);
    input.setAttribute('type', 'hidden');
    searchformRef.current.appendChild(input);
  }
  searchformRef.current.submit();
};

//function for generating params for meeting and events search form submit
export const performMeetingsSubmitSearchFormAction = (
  submitSearchParams: any,
  searchformRef: any,
  submitSearchUrl: string | undefined,
  deployedEnv: string,
  groupsDevURL: string,
  groupsTestURL: string
) => {
  const params = filterParams(submitSearchParams);
  const url = addSubDirectoryPrefix(submitSearchUrl);
  searchformRef.current.action = url;
  for (const param in params) {
    const input = document.createElement('input');
    input.setAttribute('name', param);
    input.setAttribute('value', params[param] as string);
    input.setAttribute('type', 'hidden');
    searchformRef?.current?.appendChild(input);
  }
  if (deployedEnv === 'searchtest1') {
    const formData = new FormData(searchformRef.current);
    const formDataObject: any = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    const finalUrl = `${groupsTestURL}${url}?${new URLSearchParams(formDataObject).toString()}`;
    window.location.href = finalUrl;
  } else if (deployedEnv === 'searchdev1') {
    const formData = new FormData(searchformRef.current);
    const formDataObject: any = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    const finalUrl = `${groupsDevURL}${url}?${new URLSearchParams(formDataObject).toString()}`;
    window.location.href = finalUrl;
  } else {
    searchformRef.current.requestSubmit();
  }
};
/**
 * Function to get formatted date by locale
 * @param {string}  currentLocale pass the current locale
 * @param {any}  dates pass the current locale
 * @param {string}  DATE_FORMAT_VALUE pass the current locale
 */
export const getFormattedDateByLocale = (currentLocale: string, dates: any, DATE_FORMAT_VALUE: string) => {
  let fromDateValue = moment(dates?.fromDate)?.format(DATE_FORMAT_VALUE) ?? '';
  let toDateValue = moment(dates?.toDate)?.format(DATE_FORMAT_VALUE) ?? '';
  switch (currentLocale?.replace('_', '-')) {
    case GB_LOCALE_PATTERN_HYPHEN:
    case FR_LOCALE:
    case IT_LOCALE:
    case ES_LOCALE:
    case PT_BR_LOCALE:
      if (dates?.fromDate) {
        fromDateValue = moment(dates?.fromDate)?.format(DATE_MONTH_YEAR_FORMAT);
      }
      if (dates?.toDate) {
        toDateValue = moment(dates?.toDate)?.format(DATE_MONTH_YEAR_FORMAT);
      }
      break;
    case JP_LOCALE:
      if (dates?.fromDate) {
        fromDateValue = moment(dates?.fromDate)?.format(YEAR_MON_DATE_FORMAT);
      }
      if (dates?.toDate) {
        toDateValue = moment(dates?.toDate)?.format(YEAR_MON_DATE_FORMAT);
      }
      break;
    case KR_LOCALE:
      if (dates?.fromDate) {
        fromDateValue = moment(dates?.fromDate)?.format(YEAR_MON_DATE_DOTTED_FORMAT);
      }
      if (dates?.toDate) {
        toDateValue = moment(dates?.toDate)?.format(YEAR_MON_DATE_DOTTED_FORMAT);
      }
      break;
    case RU_LOCALE:
    case DE_LOCALE:
      if (dates?.fromDate) {
        fromDateValue = moment(dates?.fromDate)?.format(DATE_DOTTED_FORMAT);
      }
      if (dates?.toDate) {
        toDateValue = moment(dates?.toDate)?.format(DATE_DOTTED_FORMAT);
      }
      break;
    case MOMENT_CN_LANG:
    case TW_LOCALE:
      if (dates?.fromDate) {
        fromDateValue = moment(dates?.fromDate)?.format(DATE_FORMAT_YEAR_MONTH_DAY);
      }
      if (dates?.toDate) {
        toDateValue = moment(dates?.toDate)?.format(DATE_FORMAT_YEAR_MONTH_DAY);
      }
      break;
    default:
      if (dates?.fromDate) {
        fromDateValue = moment(dates?.fromDate)?.format(DATE_FORMAT_VALUE);
      }
      if (dates?.toDate) {
        toDateValue = moment(dates?.toDate)?.format(DATE_FORMAT_VALUE);
      }
      break;
  }

  return { fromDateValue, toDateValue };
};

//function for generating params for Hotels and HP search form submit
export const formSubmitSearchRequestParams = (
  destination: any,
  destinationHiddenFields: any,
  roomsAndGuests: any,
  dates: any,
  usePoints: any,
  marriottBrands: any,
  specialRates: any,
  destinationFieldDetails: any,
  resortsHiddenFields: any,
  searchformRef: any,
  submitSearchUrl: string | undefined,
  availabilitySearchUrl: string | undefined,
  isAdvanceSearch: string | undefined,
  currentLocale?: string
): void => {
  const DATE_FORMAT_VALUE = 'MM/DD/YYYY';
  const secondaryText = destinationHiddenFields?.secondaryDescription
    ? destinationHiddenFields?.secondaryDescription
    : destinationHiddenFields?.state && destinationHiddenFields?.country
    ? `${destinationHiddenFields?.state}, ${changeCountryCodeForChina(destinationHiddenFields?.country)}`
    : '';
  const mainText = destinationHiddenFields?.primaryDescription ?? destinationHiddenFields?.city ?? '';
  const fromDateValue = getFormattedDateByLocale(currentLocale ?? US_LOCALE, dates, DATE_FORMAT_VALUE)?.fromDateValue;
  const toDateValue = getFormattedDateByLocale(currentLocale ?? US_LOCALE, dates, DATE_FORMAT_VALUE)?.toDateValue;
  const numberOfNights = Math.ceil(moment(dates?.toDate)?.diff(dates?.fromDate, 'days', true) ?? 0);
  const lastDefaultDate = dates?.lastDefaultDate ? moment(dates?.lastDefaultDate)?.format(DATE_FORMAT_VALUE) : '';

  const childrenAgesString =
    typeof roomsAndGuests?.childrenAges == 'object'
      ? roomsAndGuests?.childrenAges?.map((age: string) => (isNaN(parseInt(age, 10)) ? 0 : age)).toString()
      : roomsAndGuests?.childrenAges
          ?.split(',')
          ?.map((age: string) => (isNaN(parseInt(age, 10)) ? 0 : age))
          .toString();

  const hotelTypeValue = resortsHiddenFields?.hotelType?.toString() ?? '';
  const activitiesValue = resortsHiddenFields?.activities?.toString() ?? '';
  const amenitiesValue = resortsHiddenFields?.amenities?.toString() ?? '';

  let paramsObject = {};
  const commonParamsObject = {
    propertyCode: '',
    fromToDate: lastDefaultDate,
    fromToDate_submit: toDateValue,
    fromDate: fromDateValue,
    toDate: toDateValue,
    toDateDefaultFormat: toDateValue,
    fromDateDefaultFormat: fromDateValue,
    flexibleDateSearch: dates?.flexible ? 'true' : 'false',
    't-start': fromDateValue,
    't-end': toDateValue,
    lengthOfStay: numberOfNights,
    childrenCountBox: `${roomsAndGuests?.numChildrenPerRoom} Children Per Room`,
    childrenCount: roomsAndGuests?.numChildrenPerRoom,
    childrenAges: childrenAgesString,
    clusterCode: specialRates?.specialRateCode,
    corporateCode: specialRates?.corporateCode,
    groupCode: specialRates?.specialRateCode?.toUpperCase() === 'GROUP' ? specialRates?.corporateCode : '',
    useRewardsPoints: usePoints?.rewardsRedemption,
    marriottBrands: marriottBrands,
    hotelType: hotelTypeValue,
    activities: activitiesValue,
    amenities: amenitiesValue,
    isAdvanceSearch: isAdvanceSearch,
  };
  if (destinationFieldDetails?.recentlyViewedPopertyCode) {
    const availabilitySearchParamsObject = {
      propertyCode: destinationFieldDetails?.recentlyViewedPopertyCode,
      roomCountBox: `${roomsAndGuests?.numRooms} Rooms`,
      numberOfRooms:
        typeof roomsAndGuests?.numRooms === 'string'
          ? roomsAndGuests?.numRooms?.split(' ').join('')
          : roomsAndGuests?.numRooms,
      guestCountBox: `${roomsAndGuests?.numAdultsPerRoom} Adults Per Room`,
      numberOfAdults: roomsAndGuests?.numAdultsPerRoom,
    };
    paramsObject = { ...commonParamsObject, ...availabilitySearchParamsObject };
  } else {
    const submitSearchParamsObject = {
      roomTypeCode: '',
      recordsPerPage: 20,
      autoSuggestItemType: '',
      'destinationAddress.types': destinationHiddenFields?.types,
      'destinationAddress.latitude': destinationHiddenFields?.latitude,
      propertyCode: '',
      'destinationAddress.stateProvinceShort': '',
      isInternalSearch: true,
      'destinationAddress.cityPopulation': '',
      vsInitialRequest: false,
      searchType: 'InCity',
      'destinationAddress.locality': '',
      showAddressPin: '',
      'destinationAddress.stateProvinceDisplayName': destinationHiddenFields?.state,
      'destinationAddress.destinationPageDestinationAddress': '',
      countryName: changeCountryCodeForChina(destinationHiddenFields?.country),
      'destinationAddress.stateProvince': destinationHiddenFields?.state,
      searchRadius: destinationHiddenFields?.searchRadius,
      singleSearchAutoSuggest: 'Unmatched',
      'destinationAddress.placeId': destination?.destinationAddressPlaceId,
      airportName: '',
      'for-hotels-nearme': 'Near',
      suggestionsPropertyCode: '',
      'destinationAddress.country': changeCountryCodeForChina(destinationHiddenFields?.country),
      'destinationAddress.name': '',
      poiCity: '',
      'destinationAddress.countryShort': '',
      poiName: '',
      'destinationAddress.address': destinationHiddenFields?.address,
      'search-countryRegion': '',
      collapseAccordian: 'is-hidden',
      singleSearch: true,
      'destinationAddress.cityPopulationDensity': '',
      'destinationAddress.secondaryText': secondaryText,
      'destinationAddress.postalCode': destinationHiddenFields?.postalAddress,
      'destinationAddress.city': destinationHiddenFields?.city,
      'destinationAddress.mainText': mainText,
      airportCode: '',
      isTransient: true,
      'destinationAddress.longitude': destinationHiddenFields?.longitude,
      initialRequest: false,
      'destinationAddress.website': destinationHiddenFields?.website,
      'search-locality': '',
      flexibleDateSearchRateDisplay: false,
      propertyName: '',
      isSearch: true,
      marriottRewardsNumber: '',
      isRateCalendar: dates?.flexible ? 'true' : 'false',
      incentiveType_Number: '',
      incentiveType: '',
      flexibleDateLowestRateMonth: '',
      flexibleDateLowestRateDate: '',
      isMultiRateSearch: '',
      multiRateMaxCount: '',
      multiRateCorpCodes: '',
      useMultiRateRewardsPoints: '',
      multiRateClusterCodes: '',
      multiRateCorpCodesEntered: '',
      lowestRegularRate: '',
      'js-location-nearme-values': '',
      'destinationAddress.destination': destination?.displayText,
      isHideFlexibleDateCalendar: false,
      roomCountBox: `${roomsAndGuests?.numRooms} Room`,
      roomCount:
        typeof roomsAndGuests?.numRooms === 'string'
          ? roomsAndGuests?.numRooms?.split(' ').join('')
          : roomsAndGuests?.numRooms,
      guestCountBox: `${roomsAndGuests?.numAdultsPerRoom} Adult Per Room`,
      numAdultsPerRoom: roomsAndGuests?.numAdultsPerRoom,
    };
    paramsObject = { ...commonParamsObject, ...submitSearchParamsObject };
  }
  performSubmitSearchFormAction(paramsObject, searchformRef, submitSearchUrl, availabilitySearchUrl, currentLocale);
};

export const searchCounterValue = (counter: string) => {
  /**
   * To get and set Counter value in sessionStorage
   * and later updating hash in the url
   * */
  if (typeof window !== 'undefined') {
    if (sessionStorage.getItem(counter)) {
      const maxValue = parseInt(sessionStorage.getItem(counter) as string);
      /**
       * set the previous counter value with +1 on update search
       */
      sessionStorage.setItem(counter, (maxValue + 1).toString());
      //  setAdvDirectoryCounter(maxValue + 1);
    } else {
      sessionStorage.setItem(counter, `${0}`);
    }
  }
};
/**
 * Function to get date format by locale
 * @param {string}  currentLocale pass the current locale
 */
export const getDateFormatPerLocale = (currentLocale: string) => {
  switch (currentLocale?.replace('_', '-')) {
    case GB_LOCALE_PATTERN_HYPHEN:
    case FR_LOCALE:
    case IT_LOCALE:
    case ES_LOCALE:
    case PT_BR_LOCALE:
      return DATE_MONTH_YEAR_FORMAT;
    case JP_LOCALE:
      return YEAR_MON_DATE_FORMAT;

    case KR_LOCALE:
      return YEAR_MON_DATE_DOTTED_FORMAT;

    case RU_LOCALE:
    case DE_LOCALE:
      return DATE_DOTTED_FORMAT;

    case MOMENT_CN_LANG:
    case TW_LOCALE:
      return DATE_FORMAT_YEAR_MONTH_DAY;

    default:
      return DATE_FORMAT_VALUE;
  }
};

//function for generating params for meeting and events search form submit
export const formSubmitMeetingsRequest = (
  submitSearchUrl: string | undefined,
  destination: any,
  destinationHiddenFields: any,
  eventsDates: any,
  searchformRef: any,
  eventType: string,
  guestRoomCount: number,
  eventSpace: number,
  deployedEnv: string,
  groupsDevURL: string,
  groupsTestURL: string,
  currentLocale: string
) => {
  const mainText = destinationHiddenFields?.primaryDescription ?? destinationHiddenFields?.city ?? '';
  const fromDateValue = moment(eventsDates?.eventsFromDate)?.format(getDateFormatPerLocale(currentLocale)) ?? '';
  const toDateValue = moment(eventsDates?.eventsToDate)?.format(getDateFormatPerLocale(currentLocale)) ?? '';
  const lastDefaultDate = eventsDates?.lastDefaultDate
    ? moment(eventsDates?.lastDefaultDate)?.format(getDateFormatPerLocale(currentLocale))
    : '';

  let paramsObject = {};
  const commonParamsObject = {
    propertyCode: '',
    fromToDate: lastDefaultDate,
    fromToDate_submit: toDateValue,
    fromDate: fromDateValue,
    toDate: toDateValue,
    flexibleDateSearch: 'false',
  };

  const submitSearchParamsObject = {
    dateFormatPattern: '',
    localeForERFP: 'en_US',
    'searchCriteriaVO.autoSuggestItemType': '',
    'searchCriteriaVO.latitude': destinationHiddenFields?.latitude,
    'searchCriteriaVO.longitude': destinationHiddenFields?.longitude,
    'searchCriteriaVO.country': changeCountryCodeForChina(destinationHiddenFields?.country),
    'searchCriteriaVO.stateProvince': destinationHiddenFields?.state,
    'searchCriteriaVO.spcunits': '',
    'searchCriteriaVO.poiCity': '',
    'destinationAddress.types': destinationHiddenFields?.types,
    fromSwappableMeetingForm: '',
    monthNames: '',
    searchRadius: destinationHiddenFields?.searchRadius,
    'searchCriteriaVO.city': destinationHiddenFields?.city,
    defaultToDateDays: '',
    'destinationAddress.address': destinationHiddenFields?.address,
    'searchCriteriaVO.poiName': '',
    sqfootcalclabel: '',
    clickToSearch: '',
    showAddressPin: '',
    noCheckBoxSearch: '',
    'searchCriteriaVO.singleSearchAutoSuggest': 'Unmatched',
    weekDays: '',
    'destinationAddress.postalCode': destinationHiddenFields?.postalAddress,
    'destinationAddress.mainText': mainText,
    'searchCriteriaVO.airportCode': '',
    sgoSupported: '',
    roomsAndEventSelected: '',
    'destinationAddress.website': destinationHiddenFields?.website,
    populateTodateFromFromDate: '',
    'js-location-nearme-values': '',
    'searchCriteriaVO.destination': destination?.eventsDisplayText,
    eventType: eventType.toLowerCase(),
    'searchCriteriaVO.guestRoomCount': guestRoomCount,
    'searchCriteriaVO.sizeLargestMeetingRoom': eventSpace,
    submit: 'Find',
    isAdvanceSearch: true,
  };
  paramsObject = { ...commonParamsObject, ...submitSearchParamsObject };

  performMeetingsSubmitSearchFormAction(
    paramsObject,
    searchformRef,
    submitSearchUrl,
    deployedEnv,
    groupsDevURL,
    groupsTestURL
  );
};
