import { RnBCondensedFeature } from './CondensedFeature.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CardHorizontalFeature } from '@marriott/mi-ui-library';
import { CardHorizontalFeatureProps } from '@marriott/mi-ui-library';
import { CondensedFeatureProps } from './index';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const CondensedFeature = (props: CondensedFeatureProps) => {
  const cardHorizontalProps = {
    header: props?.title,
    description: props?.description,
    eyebrowtext: props?.eyebrowText ? props.eyebrowText : '',
    imageAlignment: 'right' as CardHorizontalFeatureProps['imageAlignment'],
    contentBlockAlignment: 'center' as CardHorizontalFeatureProps['contentBlockAlignment'],
    ctaType: 'secondaryButton' as CardHorizontalFeatureProps['ctaType'],
    linkText: props?.buttonLabel,
    linkUrl: props?.ctaLink,
    headerTag: 'h3' as CardHorizontalFeatureProps['headerTag'],
    openInNewTab: props?.openInNewTab ? true : false,
    styleclass: 'rnb-condensed-feature inverse',
    dynamicMedia: props?.dynamicMedia,
    trackingProperties: props?.trackingProperties,
  };

  return (
    <RnBCondensedFeature data-testid="condensedfeature" data-component-name="o-rnb-condensedfeature">
      <div className="container">
        {!!props?.headerText && (
          <h2 className="t-title-inverse-s condensed-feature-header-container m-0 mb-2 mb-md-5 p-0 pb-2 d-flex justify-content-center text-center">
            <span className="condensed-feature-header">{props.headerText}</span>
          </h2>
        )}
        <CardHorizontalFeature {...cardHorizontalProps} />
      </div>
    </RnBCondensedFeature>
  );
};

export const CondensedFeatureConfig = {
  emptyLabel: 'condensedfeature',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/condensedfeature`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CondensedFeatureEditable = (props: any) => (
  <EditableComponent config={CondensedFeatureConfig} {...props}>
    <CondensedFeature {...props} />
  </EditableComponent>
);
