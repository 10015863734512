import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const Styled = styled.div`
  ${rtl`
.hotel-overview-container {
  padding: ${toRem(0)} ${toRem(158)} ${toRem(0)};
  .overview-title-container{
    padding: 0 ${toRem(117)};
    text-align: center;
    margin-bottom: ${toRem(78)};
    border-bottom: ${toRem(1.5)} solid #EEE;
    .overview-title {
    }
    .overview-description {
      padding-bottom: ${toRem(37)};
      margin-bottom: 0;
    }
  }
}

@media only ${theme.mediaQuery.allTablets} {
  .hotel-overview-container {
    padding: ${toRem(15)} 0 0 0;
    .overview-title-container{
      padding: 0 ${toRem(97)};
      border-bottom: 0;
      margin-bottom: 0;
      .overview-title{
      }
      .overview-description{
        padding-bottom: ${toRem(27)};
      }
    }
  }
}

@media only ${theme.mediaQuery.allMobiles} {
  .hotel-overview-container {
    padding: 0;
    .overview-title-container{
      padding: 0 ${toRem(16)};
      margin-top: ${toRem(-45)};
      border-bottom: 0;
      .overview-title{
        text-align: left;
        padding-top: ${toRem(20)};
        border-top: ${toRem(1.5)} solid ${theme.color.gallery};
      }
      .overview-description{
        text-align: left;
        padding-bottom: ${toRem(22)};
        border-bottom: ${toRem(1.5)} solid ${theme.color.gallery};
      }
    }
  }
}

.hqv-modal {
  &.hotel-overview-container {
    padding: ${toRem(15)} 0 0 0;
    margin-left: ${toRem(24)};
    margin-right: ${toRem(8)};
    border-bottom: ${toRem(1.5)} solid ${theme.color['gallery']};
    .overview-title-container{
      padding:0 ${toRem(8)} 0 ${toRem(16)};
      @media only ${theme.mediaQuery.allMobiles} {
        padding:0;
        margin-left: ${toRem(16)};
        margin-right: ${toRem(16)};
      }
      border-bottom: 0;
      margin-bottom: 0;
      .overview-title{
      }
      .overview-description{
        padding-bottom: ${toRem(27)};
      }
    }
  }
}
`}
`;
