// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledTab = styled.div`
  border-radius: ${toRem(12)};
  box-shadow: 0 ${toRem(3)} ${toRem(16)} 0 rgba(0, 0, 0, 0.15);
  padding: ${toRem(32)} ${toRem(0)} ${toRem(27)} ${toRem(0)};
  .m-standard-tab-list {
    border-bottom: 1.6px solid ${baseVariables.color.neutral30} !important;
  }
  .m-standard-tab-list-item:first-child {
    margin-right: ${toRem(17)};
  }
  @media ${baseVariables.mediaQuery.md} {
    padding: ${toRem(24)} ${toRem(0)} ${toRem(27)} ${toRem(0)};
  }
  @media ${baseVariables.mediaQuery.lg} {
    padding: ${toRem(32)} ${toRem(0)} ${toRem(27)} ${toRem(0)};
  }
  .tab-desc {
    margin-top: ${toRem(-5)};
  }
  .inactive-tab {
    .tabIconText,
    .t-subtitle-m {
      color: #c4c4c4;
    }
  }

  .tabUL {
    justify-content: space-evenly;
    @media ${baseVariables.mediaQuery.lg} {
      width: 100% !important;
    }
  }

  .tabIconText {
    .iconContainer {
      font-size: ${toRem(24)};
      text-align: center;
      margin-bottom: 0.1rem;
      font-size: ${toRem(24)};
    }
    span {
      text-align: center;
      font-size: ${toRem(13)};
      font-weight: 700 !important;
    }
    display: grid;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .glide {
    @media ${baseVariables.mediaQuery.md} {
      ul {
        li {
          width: inherit !important;
        }
      }
    }
    &__track {
      padding-top: ${toRem(24)};
      .glide__slides_authoring {
        @media ${baseVariables.mediaQuery.md} {
          flex-wrap: nowrap;
        }
        width: 100% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
    }
    &__track__pt3 {
      overflow: hidden;
      padding-top: ${toRem(8)};
      .glide__slides_authoring {
        width: 100% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
    }
    .center-align {
      display: flex;
      justify-content: center;
      align-items: center;
      .carouselControlType1 {
        .right-arrow {
          height: 100px;
          width: 5rem;
          border-radius: 0px;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(28, 28, 28, 1) 100%,
            rgba(28, 28, 28, 1) 100%
          );
          right: 0.1rem;
          color: white;
          top: 0;
          @media ${baseVariables.mediaQuery.sm} {
            right: -1.25rem;
            color: light-gray-6;
          }
          .icon-arrow-right {
            position: absolute;
            right: 0;
          }
        }
        .left-arrow {
          height: 100px;
          width: 5rem;
          border-radius: 0px;
          background: rgb(28, 28, 28);
          background: linear-gradient(
            90deg,
            rgba(28, 28, 28, 1) 0%,
            rgba(255, 255, 255, 0) 100%,
            rgba(28, 28, 28, 1) 100%
          );
          color: white;
          left: 0.1rem;
          top: 0;
          @media ${baseVariables.mediaQuery.sm} {
            left: -1.25rem;
            color: white;
          }
          .icon-arrow-left {
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
`;
