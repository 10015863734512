import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledCardStatic = styled.div`
  .card-static-container {
    border-bottom: 1px solid ${baseVariables.color.neutral30};
  }

  .card-static-minutes-read {
    margin-right: ${toRem(0)} !important;
    margin-left: ${toRem(4)} !important;
  }

  .col-12 {
    padding: 0;
  }
`;
