import { OfferCardHorizontalMiniProps } from '../OfferCardHorizontalMini.types';

export const mockOfferCardHorizontalMiniData: OfferCardHorizontalMiniProps = {
  offersData: {
    uri: '/offers-dca-preview/038/OFF-038899-00',
    isPreview: 'true',
    offerId: 'OFF-038899-00',
    marsha: 'BJSLF',
    locale: 'en-US',
    brand: 'SI',
    responseObject: {
      edges: [
        {
          id: 'OFF-038899-00',
          node: {
            id: 'OFF-038899',
            title: 'Header field',
            name: null,
            urlTitle: null,
            url: '',
            seoNickname: 'sheraton-langfang-chaobai-river-hotel',
            description: 'This is the Description field',
            descriptionTeaser: 'This is Eyebrow field',
            searchable: true,
            numProperties: 1,
            numberOfOutlets: 0,
            offerCategory: null,
            parentOfferType: 'Merchandising Offer',
            offerType: 'CoBrand',
            stayStartDate: '2023-12-20',
            stayEndDate: '2024-01-31',
            bookingEndDate: '2024-01-31',
            bookingStartDate: '2023-12-20',
            minimumStay: 1,
            needToKnow: ['Bullet One', 'Bullet Two', 'Bullet Three'],
            clusterCode: null,
            reservationMessage: null,
            additionalTerms: null,
            termsAndConditions: 'This is Legal field for longer legal data',
            callToActionLink: 'https://www.marriott.com',
            callToActionLabel: 'CTA One',
            recurrencePattern: null,
            validDays: 'Monday;Tuesday;Wednesday;Thursday;Friday',
            associatedOutlets: [
              {
                outlets: [],
              },
            ],
            participatingProperties: {
              brandName: 'Sheraton',
              brandCode: 'SI',
              subBrandCode: null,
              country: 'China',
              countryCode: 'CN',
              stateProvince: null,
              stateCode: null,
              city: 'Langfang',
              properties: [
                {
                  id: 'BJSLF',
                  basicInformation: {
                    name: 'Sheraton Langfang Chaobai River Hotel',
                    currency: 'CNY',
                    brand: {
                      id: 'SI',
                    },
                  },
                },
              ],
            },
            photos: {
              name: 'tx-mcntx-ballroom-wedding--16514',
              captions: [
                {
                  length: 'short',
                  caption: 'Ballroom Wedding Reception Setup',
                },
                {
                  length: 'medium',
                  caption: 'Ballroom Wedding Reception Setup',
                },
                {
                  length: 'large',
                  caption: 'Our Rhythm & Blues Ballroom will set the tone for a memorable wedding reception in Macon.',
                },
              ],
              images: [
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Classic-Hor',
                  aspectRatio: 'Classic',
                },
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Feature-Hor',
                  aspectRatio: 'Feature',
                },
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Pano-Hor',
                  aspectRatio: 'Pano',
                },
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Square',
                  aspectRatio: 'Square',
                },
                {
                  url: '/is/image/marriotts7prod/tx-mcntx-ballroom-wedding--16514:Wide-Hor',
                  aspectRatio: 'Wide',
                },
              ],
            },
          },
          merchandisingNode: {
            cell: '54321',
            countryCode: 'US',
            guidSecondary: null,
            issuerCode: '654321',
            merchandisingTag: 'MER123',
            campaignId: 'CAM12345',
            offerCode: '665544',
            optionalFields: 'Optional',
            prequal: true,
            rpcCode: '1234',
            spid: '1234,2345,3456',
            theme: 'Light',
            caption: 'Caption',
            captionUrl: 'captionurl@marriott.com',
            descriptionShort: 'This is Description Short',
            headerLong: 'Header Long field',
            headerShort: 'Header Short field',
            promotionalTag: 'Member Exclusive-en_US',
            secondaryCallToActionLabel: 'CTA Two',
            secondaryCallToActionLink: 'https://www.marriott.com/offers.mi',
          },
        },
      ],
      facets: [],
    },
    responseStatus: {
      code: '200',
      message: 'SUCCESS',
    },
  },
  model: {
    request: {
      requestedSessionIdFromCookie: false,
      remoteHost: null,
      serverName: null,
      requestedSessionIdValid: false,
      secure: false,
      locale: 'en_US',
      serverPort: 0,
      cookies: [],
      requestedSessionId: null,
      authType: null,
      requestedSessionIdFromURL: false,
      requestedSessionIdFromUrl: false,
      contentType: null,
      pathTranslated: null,
      remoteAddr: null,
      remoteUser: null,
      responseContentTypes: ['application/json'],
      method: 'GET',
      resource: {
        openInAModel: 'hide',
        componentId: 'cardHorizontalMiniDCAPreview',
        headerTag: 'h5',
        assetVariation: 'image',
        textIsRich: ['true', 'true'],
        impressionTrack: 'false',
        headerShort: 'Plan your trip with travel tips and inspiration from Marriott Bonvoy Traveler.',
        'sling:resourceType': 'mi-aem-common-spa/components/content/cardhorizontalmini',
        descriptionText: '<p>Plan your trip with travel tips and inspiration from Marriott Bonvoy Traveler.</p>\r\n',
        'cq:lastRolledoutBy': 'sriya301',
        openInaNewTab: 'true',
        imgAltText: 'text',
        fileReferenceImage:
          '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
        impressionCount: 'false',
        ctaLink: '#',
        renderType: 'manual',
        merchandisingCategory: 'nonCobrand',
        hideDescriptionOnMobile: 'false',
        'cq:styleIds': ['1705953032664', '1705953032664'],
        'jcr:lastModifiedBy': 'admin',
        'jcr:created': 1682430470296,
        'cq:lastRolledout': 1706006336844,
        enableScrollingBehavior: 'false',
        'jcr:createdBy': 'admin',
        descriptionShort:
          '<p>mobile text To display shorter content for smaller viewports, add text here. If this field is not populated, the default Header content will display.</p>\r\n<p> </p>\r\n',
        hideEllipsis: 'false',
        'jcr:mixinTypes': ['cq:LiveRelationship'],
        clickTrack: 'false',
        'jcr:lastModified': 1706006336809,
        header: 'Where Will You Go Next?',
        'jcr:primaryType': 'nt:unstructured',
      },
      headerNames: null,
      contextPath: '',
      remotePort: 0,
      requestURI: '/content/mi-aem-offers-spa/na/en-us/offer-details/dca-preview.model.json',
      queryString: null,
      userPrincipal: {
        path: '/home/users/i/f4rH56UXRJYpu3GsCnRd',
        name: 'anonymous',
      },
      responseContentType: 'application/json',
      servletPath: '',
      contentLength: 0,
      requestPathInfo: {
        extension: 'json',
        resourcePath: '/content/mi-aem-offers-spa/na/en-us/offer-details/dca-preview/jcr:content',
        selectorString: 'model',
        suffixResource: null,
        selectors: ['model'],
        suffix: null,
      },
      pathInfo: '/content/mi-aem-offers-spa/na/en-us/offer-details/dca-preview.model.json',
      parameters: {},
    },
    dynamicMedia: {
      altText: 'Skyline view',
      assetPath: 'https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941',
      enableSmartCrop: true,
      dynamic: true,
      renditions: [
        {
          renditionPath:
            'https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Square?wid=100&hei=100, https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Square?wid=200&hei=200 2x',
          mediaValue: '992px',
          width: 100,
          dynamic: true,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
          mediaQuery: 'max-width',
          height: 100,
        },
        {
          renditionPath:
            'https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Square?wid=100&hei=100, https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Square?wid=200&hei=200 2x',
          mediaValue: '576px',
          width: 100,
          dynamic: true,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
          mediaQuery: 'min-width',
          height: 100,
        },
        {
          renditionPath:
            'https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Square?wid=100&hei=100, https://cache.marriott.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Square?wid=200&hei=200 2x',
          mediaValue: '576px',
          width: 100,
          dynamic: true,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
          mediaQuery: 'max-width',
          height: 100,
        },
      ],
      damPath:
        '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
    },
    openInAModel: 'hide',
    componentId: 'cardHorizontalMiniDCAPreview',
    headerTag: 'h5',
    assetVariation: 'image',
    impressionTrack: 'false',
    headerShort: 'Plan your trip with travel tips and inspiration from Marriott Bonvoy Traveler.',
    descriptionText: '<p>Plan your trip with travel tips and inspiration from Marriott Bonvoy Traveler.</p>\r\n',
    trackingProperties: {
      enableScrollingBehavior: false,
      trackingDescription: '',
      impressionTrack: false,
      description: '',
      trackingContentPosition: '',
      atCCeVar48: '',
      rightArrowDesc: '',
      cardLocation: '',
      leftArrowDesc: '',
      clickTrack: false,
      location: '',
      merchandisingCategory: 'nonCobrand',
      impressionCount: false,
      trackingTag: '',
      trackingOfferType: '',
    },
    isAuthor: false,
    path: '/content/mi-aem-offers-spa/na/en-us/offer-details/dca-preview/jcr:content/root/responsivegrid/cardhorizontalmini',
    ':type': 'mi-aem-common-spa/components/content/cardhorizontalmini',
    openInaNewTab: true,
    imgAltText: 'Skyline view',
    merchandisingCategory: 'nonCobrand',
    impressionCount: 'false',
    renderType: 'manual',
    ctaLink: '#',
    hideDescriptionOnMobile: false,
    styleclass: 'mb-5',
    descriptionShort:
      '<p>mobile text To display shorter content for smaller viewports, add text here. If this field is not populated, the default Header content will display.</p>\r\n<p> </p>\r\n',
    hideEllipsis: false,
    header: 'Where Will You Go Next?',
    iconPath: '',
    wcmMode: 'disabled',
  },
  isAuthorMode: true,
};
