/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { getPageModel, getFeatureFlagData, getMockSessionData, getMockPageModel } from './helper';
// import { constants } from '@marriott/mi-shop-components/constants';
import { getDataLayer } from './dataLayer';
import { RequestProps } from './dataLayer/index.types';
import { logger } from './logger';
import { interceptor } from './interceptor';
import { inspect } from 'util';
import Cookies from 'js-cookie';
import { isEmpty } from '@marriott/shared/mi-helper-utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useGlobalStore } from '@marriott/mi-store-utils';
import { addSubDirectoryPrefix, setLanguage } from './subDirectoryFunctions';

// add this way to allow the process env on browser
// https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables#bundling-environment-variables-for-the-browser
const REDIRECT_URL = process.env['REDIRECT_URL'];
const NEXT_PUBLIC_PRE_PROCESSING_URL_FINDHOTEL = process.env['NEXT_PUBLIC_PRE_PROCESSING_URL_FINDHOTEL'];
const IS_LOCAL_DEV = process.env['IS_LOCAL_DEV'];
const HOMEPAGE_REDIRECT_URL = process.env['HOMEPAGE_REDIRECT_URL'];
const SHOP_UXL_CALL_SPLIT = process.env['SHOP_UXL_CALL_SPLIT'];
const SKIP_DATALAYER_CALL = process.env['SKIP_DATALAYER_CALL'];
const APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG = process.env['APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG'] || '';
const APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION = process.env['APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION'] || '';
const APOLLOGRAPHQL_PUBLIC_CLIENT_NAME = process.env['APOLLOGRAPHQL_PUBLIC_CLIENT_NAME'] || '';
const APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME = process.env['APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME'] || '';
const APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING = process.env['APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING'] || '';
const APOLLOGRAPHQL_FULL_NGINX_ENDPOINT = process.env['APOLLOGRAPHQL_FULL_NGINX_ENDPOINT'] || '';
const APOLLOGRAPHQL_PUBLIC_LOCAL_DEV = process.env['APOLLOGRAPHQL_PUBLIC_LOCAL_DEV'] || '';
const APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH = process.env['APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH'] || '';
const APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH = process.env['APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH'] || '';
const DEPLOYED_ENV_TYPE = process.env['DEPLOYED_ENV_TYPE'] || '';
const APOLLOGRAPHQL_PUBLIC_DEBUG_LOG = process.env['APOLLOGRAPHQL_PUBLIC_DEBUG_LOG'] || '';
const APOLLOGRAPHQL_LOCAL_ENDPOINT = process.env['APOLLOGRAPHQL_LOCAL_ENDPOINT'] || '';
const APOLLOGRAPHQL_AUTH_TOKEN = process.env['APOLLOGRAPHQL_AUTH_TOKEN'] || '';
const IS_CN_ENV = process.env['IS_CN_ENV'];
const UXL_IMAGE_CACHE_DOMAIN = process.env['UXL_IMAGE_CACHE_DOMAIN'] || '';
const BAIDU_MAP_API_KEY = process.env['BAIDU_MAP_API_KEY'] || '';

const MOCK_SESSION_ID = '3DBB8A56-6296-5FB3-B355-33461E526E82';
declare global {
  // eslint-disable-next-line no-var
  var loggerInstance: CallableFunction;
}

const redirect = {
  redirect: {
    permanent: false,
    destination: addSubDirectoryPrefix(REDIRECT_URL),
  },
};

// Redirect user to configured page in case request is coming from getInitialProps method
const getInitialPropsRedirect = (res: any, redirectObj: typeof redirect) => {
  res.writeHead(307, { Location: redirectObj.redirect.destination });
  res.end();
};

/**
 * Method to conditionally redirect user to target page based on whether the request
 * is initialted by getInitialProps method or getServerSideProps
 * @param res Response Object
 * @param redirectObj Object containing redirection configs
 * @param IS_INTIAL_PROPS_CALL Boolean variable for branching
 * @returns
 */
const redirectUser = (res: any, redirectObj: typeof redirect, IS_INTIAL_PROPS_CALL: boolean) => {
  if (IS_INTIAL_PROPS_CALL) {
    getInitialPropsRedirect(res, redirectObj);
    return res;
  } else {
    return redirectObj;
  }
};

const homepageRedirect = {
  redirect: {
    permanent: false,
    destination: addSubDirectoryPrefix(HOMEPAGE_REDIRECT_URL),
  },
};

interface axiosResponseInterface extends AxiosResponse {
  notFound?: boolean;
  experienceSegments?: { brandCode: string };
  locale?: string;
}

class PreProcessorCallFailError extends Error {
  constructor(message: string, redirectUrl: string) {
    super(message);
    this.redirectUrl = redirectUrl;
  }
  redirectUrl: string;
}

/**
 *
 * @param url
 * @param header
 * @param body
 * @returns API response
 * This function is calling the pre-processir url and get the response, to be used in calling the page model.
 */
export const preProcessorCall = async (
  url: string,
  header: Record<string, string>,
  shopAppRequestBody: any,
  res?: any
): Promise<axiosResponseInterface> => {
  const { log } = global.loggerInstance('shopAppCall');
  log.debug(`[STEP 3] shop-app API url: ${url}`);
  log.debug(`[STEP 3] shop-app API req body: ${inspect(shopAppRequestBody)}`);

  let response;
  try {
    response = await axios.post(url, shopAppRequestBody, {
      headers: header,
    });
    log.debug(`[STEP 3] SUCCESS!! shop-app API call success with resp: ${inspect(response?.data)}`);
  } catch (e: any) {
    log.error(`[STEP 3] shop-app API call failed: ${e}`);
    log.debug(`[STEP 3] ERROR!! shop-app API call failed with resp header: ${inspect(e?.response?.headers?.location)}`);
    if (e?.response?.headers?.location) {
      throw new PreProcessorCallFailError('PreProcessor call failed', e?.response?.headers?.location);
    } else {
      throw new Error('PreProcessor call failed');
    }
  }
  if (response?.status !== 200) {
    // TODO : add condition for 0 hotels(status code 302)
    log.error(`[STEP 3] response status is: ${response?.status}`);
    return {
      ...(response?.data || {}),
      notFound: true,
    };
  }

  if (response?.status === 200) {
    const locationHeader = response?.headers['location'] ?? '';
    if (locationHeader) {
      res.setHeader('Location', locationHeader);
    }
  }
  return response.data as axiosResponseInterface;
};
export const preProcessorCallMocked = async () => {
  const { log } = global.loggerInstance('preProcessorCall');
  let response: any;
  try {
    // response = await axios.post(url, body, {
    //   headers: header,
    // });
    response = {
      status: 200,
      data: {
        sessionHeader: {},
        sessionData: await getMockSessionData(),
        experienceSegment: ['NOT Logged In'],
        contexts: {
          global: {
            localeKey: 'en_US',
          },
        },
      },
    };
    log.debug(`API call success with status code: ${inspect(response.status)}`);
  } catch (e) {
    log.error(`API call failed: ${e}`);
    throw new Error('PreProcessor call failed');
  }
  if (response?.status !== 200) {
    log.error(`response status is: ${response?.status}`);
    return {
      ...(response?.data || {}),
      notFound: true,
    };
  }
  return Promise.resolve(response.data);
};

/**
 *
 * @param gssp
 * @returns props
 * This function is used to excute the calls before the page gets rendered.
 * session, interceptor, pre-process, page model, datalayer
 */
export function prePageCall(gssp: (preData: any) => { props: any }) {
  let IS_INTIAL_PROPS_CALL = false;
  return async (context: any) => {
    try {
      const {
        req,
        res,
      }: {
        req: {
          cookies?: { sessionID: string; UserIdToken: string; authStateToken: string };
          headers: RequestProps;
          query: Record<string, unknown>;
          originalUrl: string;
          url: string;
        };
        res: any;
      } = context;

      let combinedArr: any = [];
      // when using getInitialProps we need to use originalUrl under req object
      // when using getServertSideProps resolvedUrl can be read from context
      const resolvedUrl: string = context.resolvedUrl ?? req.originalUrl ?? req.url;

      // resolvedUrl will only be present if page is using getServerSideProps
      if (!context.resolvedUrl) {
        IS_INTIAL_PROPS_CALL = true;
      }

      global.loggerInstance = logger({
        sessionID: req?.cookies?.sessionID,
        requestID: req.headers['x-request-id'],
      }) as CallableFunction;

      const { NEXT_PUBLIC_DEFAULT_LANG } = process.env;
      const { log, pLog } = global.loggerInstance('Pre Page');
      log.debug(`[STEP 1] Initialized logger instance: ${inspect(global.loggerInstance)}`);
      const apiStartTime = new Date().getTime();
      const preHeader: Record<string, string> = {
        'Content-Type': 'application/json',
        'x-request-id': req?.headers?.['x-request-id'] || '',
        Cookie: `sessionID=${req?.cookies?.sessionID ?? MOCK_SESSION_ID};UserIdToken=${
          req?.cookies?.UserIdToken ? req?.cookies?.UserIdToken : ''
        };authStateToken=${req?.cookies?.authStateToken ? req?.cookies?.authStateToken : ''}`,
        'X-Host': req?.headers?.['x-host'] ?? '',
        'accept-language': req?.headers?.['accept-language'] || NEXT_PUBLIC_DEFAULT_LANG || '',
      };

      let interceptorResponse;
      let interceptorResponseHeader;

      if (IS_LOCAL_DEV !== 'true') {
        const { response, interceptorHeader }: any = await interceptor(req, resolvedUrl);
        interceptorResponse = response;
        log.debug(`[STEP 2] Interceptor call response data ${inspect(interceptorResponse?.data)}`);
        log.debug(
          `[STEP 2] Interceptor call response global object data ${inspect(
            interceptorResponse?.data?.requestAttributes?.pageManagerRequest?.global
          )}`
        );
        interceptorResponseHeader = interceptorHeader;
        if (interceptorResponse?.status !== 200) {
          return redirectUser(res, redirect, IS_INTIAL_PROPS_CALL);
        }
      }

      if (IS_LOCAL_DEV !== 'true') {
        const interceptorCookieArr = interceptorResponseHeader['set-cookie'] ?? '';
        if (interceptorCookieArr) {
          combinedArr = combinedArr?.concat(interceptorCookieArr);
        }
      }

      // locale value present in interceptor response
      const locale = interceptorResponse?.data?.requestAttributes?.pageManagerRequest?.global?.localeKey || 'en_US';

      let preprocessorURL = NEXT_PUBLIC_PRE_PROCESSING_URL_FINDHOTEL;
      if (resolvedUrl.includes('?')) {
        preprocessorURL = `${NEXT_PUBLIC_PRE_PROCESSING_URL_FINDHOTEL}?${resolvedUrl.substring(
          resolvedUrl.indexOf('?') + 1
        )}`;
      }

      const preData =
        IS_LOCAL_DEV === 'true'
          ? await preProcessorCallMocked()
          : await preProcessorCall(preprocessorURL ?? '', preHeader, interceptorResponse?.data, res); //pre processor call.

      const { sessionData } = preData;
      useGlobalStore.setState({ sessionData });

      log.debug(`[STEP 3] preProcessor call success, session data recieved : ${inspect(sessionData)}`);
      log.debug(
        `[STEP 3] preProcessor call success, session data availabilityrequestVO: ${
          sessionData?.data
            ? inspect(sessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO)
            : inspect(sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO)
        }`
      );

      // Redirect user to homepage if user directly loads findHotels.mi
      const sessionDataObject = sessionData?.data?.AriesSearch?.searchCriteria
        ? sessionData?.data?.AriesSearch?.searchCriteria
        : sessionData?.cacheData?.data?.AriesSearch?.searchCriteria;
      log.error(`[STEP 3] preProcessor call success, search criteria: ${inspect(sessionDataObject)}`);
      log.error(`[STEP 3] preProcessor call success, search criteria Address: ${inspect(sessionDataObject?.address)}`);
      if (IS_LOCAL_DEV !== 'true' && resolvedUrl.includes('findHotels') && !sessionDataObject) {
        log.error(
          `sessionData does not contain the searchCriteria object, redirecting to homepage - ${JSON.stringify(
            homepageRedirect
          )}`
        );
        Cookies.remove('sessionID');
        return redirectUser(res, homepageRedirect, IS_INTIAL_PROPS_CALL);
      }

      let dataLayer;
      if (SKIP_DATALAYER_CALL !== 'true') {
        try {
          dataLayer = await getDataLayer(req, resolvedUrl, locale);
          log.debug(`[STEP 4] datalayer success, response ${inspect(dataLayer)}`);
        } catch (err) {
          log.error(`[STEP 4] datalayer failed, error: ${err}`);
        }
      }

      const headersData = req?.headers;
      const cookies = req?.cookies;
      const query = req?.query;

      const sessionIDVal = req?.cookies?.sessionID ?? MOCK_SESSION_ID;
      const pageUrl = `${resolvedUrl}`;
      const [model, getFeatureFlag] = await Promise.all([
        IS_LOCAL_DEV === 'true'
          ? getMockPageModel('findHotel') //get mock page model
          : getPageModel(pageUrl, locale, sessionIDVal), //get page model
        getFeatureFlagData(),
      ]);

      log.debug(`[STEP 5] model, feature call success, feture flag response ${inspect(getFeatureFlag)}`);

      if (sessionData && (sessionData?.data || sessionData?.cacheData)) {
        const sessionObject = sessionData?.data ? sessionData?.data : sessionData?.cacheData?.data;
        const authState = sessionObject?.AriesCommon?.memState;
        let authStateToken = '';
        const setCookieArr = [];
        if (authState === 'authenticated') {
          authStateToken = sessionObject.accessToken;
          setCookieArr.push(`authStateToken=${authStateToken}; Path=/; Secure; HttpOnly; SameSite=None`);
        }
        if (setCookieArr && setCookieArr.length > 0) {
          combinedArr = combinedArr?.concat(setCookieArr);
        }
      }

      res.setHeader('Set-Cookie', combinedArr);

      // for Local development ONLY
      // const [preData] = await Promise.all([
      //   preProcessorCallMocked(),
      //   // eslint-disable-next-line no-constant-condition
      //   //DATALAYERTYPE === 'traditional' ? getDataLayer(req) : emptyDataObj,
      // ]);

      pLog.log(
        '[PERF DETAILS] Interceptor, Shop-App, Datalayer, FeatureFlag, AEM model call time taken',
        apiStartTime,
        new Date().getTime()
      );

      // for mocking model data local development only
      const isFindHotels = resolvedUrl.includes('findHotels');
      const currentLocale = model?.language ? model?.language : locale?.replace('_', '-');
      const requestId = req.headers['x-request-id'] ?? `${Date.now()}`;

      const gsspData = await gssp(preData); // Run `getServerSideProps` to get page-specific data

      setLanguage(model?.language);

      // in case AEM or fetch model service is down, redirect user to configured
      // redirect page. This will fix "Skip to content" link on an empty page issue.
      if (model && isEmpty(model)) {
        log.error(
          `[ERROR DETAILS] page model not available, redirecting to configured page - ${JSON.stringify(redirect)}`
        );
        return redirectUser(res, redirect, IS_INTIAL_PROPS_CALL);
      }

      // when using getInitialProps we should return the data without props key
      // will be passed as is to pages.
      let cnEnvVars: { [key: string]: string } = {};
      const apolloEnvVars: { [key: string]: string } = {
        APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG,
        APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION,
        APOLLOGRAPHQL_PUBLIC_CLIENT_NAME,
        APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME,
        APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING,
        APOLLOGRAPHQL_FULL_NGINX_ENDPOINT,
        APOLLOGRAPHQL_PUBLIC_LOCAL_DEV,
        APOLLOGRAPHQL_PUBLIC_LOWER_ENV_PATH,
        APOLLOGRAPHQL_PUBLIC_HIGHER_ENV_PATH,
        DEPLOYED_ENV_TYPE,
        APOLLOGRAPHQL_PUBLIC_DEBUG_LOG,
        APOLLOGRAPHQL_LOCAL_ENDPOINT,
      };
      if (IS_LOCAL_DEV === 'true') {
        // returning auth token only for local development. For serverside calls auth token will be read from process.env
        apolloEnvVars['APOLLOGRAPHQL_AUTH_TOKEN'] = APOLLOGRAPHQL_AUTH_TOKEN;
      }
      // CNWEB-3021 Injecting the Image domain for UXL Image URLs through a env variable, in CN env only (behind IS_CN_ENV) check
      if (IS_CN_ENV) {
        cnEnvVars = {
          UXL_IMAGE_CACHE_DOMAIN,
          BAIDU_MAP_API_KEY,
        };
        log.debug('cnEnvVars ', cnEnvVars);
      }

      const returnObj = {
        model,
        fetchedSessionData: sessionData,
        resolvedUrl,
        requestId,
        currentLocale,
        dataLayer: dataLayer || {},
        SHOP_UXL_CALL_SPLIT,
        getFeatureFlag,
        headersData,
        cookies,
        query: query ?? {},
        //removed serverEnv as it contains sensitive information not to be viewed on client side
        interceptorResponse: interceptorResponse?.data || null,
        preHeader,
        ...gsspData.props,
        apolloEnvVars,
        cnEnvVars,
      };

      if (isFindHotels) {
        return {
          props: returnObj,
        };
      } else {
        return returnObj;
      }
    } catch (e) {
      const { log } = global.loggerInstance('Pre Page');
      log.debug(
        `[STEP 0] PAGE PREPROCESSOR ERROR logger initialized here \n\n\n\n\n' ${
          (inspect(global.loggerInstance), inspect(e))
        }`
      );

      log.error(`[STEP 0] prepage call error occurred: ${e}`);
      try {
        const { res } = context;
        if (e instanceof PreProcessorCallFailError) {
          return redirectUser(
            res,
            { redirect: { destination: e.redirectUrl, permanent: false } },
            IS_INTIAL_PROPS_CALL
          );
        }
        return redirectUser(res, redirect, IS_INTIAL_PROPS_CALL);
      } catch (err) {
        console.error('Error in pre-processor catch block, response desctructuring failed', err);
      }
    }
  };
}
