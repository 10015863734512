import { DestinationDetails, NearByDestinationsInfoProps } from '../NearByDestinations.types';

const mockDestinationList: DestinationDetails[] = [
  {
    destination: 'Mahabaleshwar, India',
    distance: '67.6 mi',
    duration: 3,
    placeId: '',
    address: 'Mahabaleshwar, India',
    city: 'Mahabaleshwar',
    country: 'IN',
    latitude: '17.92128',
    longitude: '73.72986',
    state: '',
    seconds: 108740,
    hoursAwayText: '2 hours 38 mins',
  },
  {
    destination: 'Mumbai, India',
    distance: '84.5 mi',
    duration: 2,
    placeId: '',
    address: 'Mumbai, India',
    city: 'Mumbai',
    country: 'IN',
    latitude: '19.06623',
    longitude: '72.999558',
    state: '',
    seconds: 135956,
    hoursAwayText: '2 hours 29 mins',
  },
  {
    destination: 'Navi Mumbai, India',
    distance: '81.7 mi',
    duration: 2,
    placeId: '',
    address: 'Navi Mumbai, India',
    city: 'Navi Mumbai',
    country: 'IN',
    latitude: '19.04334',
    longitude: '73.026991',
    state: '',
    seconds: 131511,
    hoursAwayText: '2 hours 23 mins',
  },
  // Add more destinations as needed
];

const mockDestinationsData: NearByDestinationsInfoProps = {
  destinationList: mockDestinationList,
};

export default mockDestinationsData;
