import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledPaginationSortByComponentDiv = styled.div`
  ${rtl`
  margin: auto;
  padding:0;
  font-weight: ${theme.fonts['fontWeightMedium']};
  font-family: ${theme.fonts['fontFamilySwiss']};
   padding-bottom: ${toRem(8)};

  .row-2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${toRem(0)};
    padding-left:0;
  }


  @media only ${theme.mediaQuery.allTablets} {
    .row-2{
      width: auto;
      margin-right: ${toRem(8)};
      padding:0;
    }
  }
  @media only ${theme.mediaQuery.allMobiles} {
    padding-bottom: 0;
    .row-2 {
      padding-left:${toRem(16)};
      padding-right:${toRem(16)};
      width: 100%;
      .sort-by-section .current-sort-option {
        padding-right: ${toRem(2)};
      }

      .sort-by-section .sort-by-label {
        padding-right: ${toRem(2)};
      }
    }
    .hotel-content{
      margin-left:${toRem(3)};
    }
    .results-summary{
      display:flex;
      align-items:center;
      line-height:${toRem(24)};
    }
    .pr-0 {
      padding-right:${toRem(16)} !important;
    }
  }
  `}
`;
