import clsx from 'clsx';
import { getFallbackImg } from '../../utils/OfferUtils';
import config from '../../lib/config';
import {
  CLASSIC_ASPECT_RATIO,
  EXPLORE_THIS_OFFER,
  MPO_MBO_BUTTON,
  MPO_MBO_STYLES,
  MPO_SBO_BUTTON,
  OFFER_IMAGE_CACHE_DOMAIN,
  SINGLE_BRAND_MULTI_PROP_OFFER,
  SQUARE_ASPECT_RATIO,
  WIDE_ASPECT_RATIO,
} from '../../constants/OffersHeroConstants';

import { Text, Types, Image, Button, Heading } from '@marriott/mi-ui-library';
import { OffersHeroMultiPropertyProps } from './OffersHero.types';
import { OffersHeroMultiPropertyStyled } from './OffersHero.styles';
import { useRef } from 'react';
import { INTERNAL_CTA_TYPE } from '../../constants/CommonConstants';

export const OffersHeroMultiProperty: React.FC<OffersHeroMultiPropertyProps> = props => {
  const { variation, offersHeroProps, modelObj } = props;
  const offerObj = offersHeroProps?.responseObject?.edges[0].node;
  let desktopImageSrc = '';
  const imageArr = offerObj?.photos?.images ?? [];
  const fallBackImg = getFallbackImg(config.FALLBACK_IMG_URI, CLASSIC_ASPECT_RATIO);
  const bannerTextClassName = clsx('t-font-swiss721');
  const OffersHeroMPORef = useRef(null);
  const ASPECT_RATIO_UXL_FIELD_MAPPING = {
    CLASSIC: 'classicHorizontal',
  };
  const objKey = CLASSIC_ASPECT_RATIO as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
  const DACImagePath = offerObj?.media?.primaryImage?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[objKey]];
  if (DACImagePath) {
    desktopImageSrc = OFFER_IMAGE_CACHE_DOMAIN + DACImagePath;
  } else if (imageArr.length) {
    for (const image of imageArr) {
      if (image.aspectRatio.toUpperCase() === CLASSIC_ASPECT_RATIO) {
        desktopImageSrc = OFFER_IMAGE_CACHE_DOMAIN + (image.url ?? fallBackImg);
      } else if (image.aspectRatio.toUpperCase() === SQUARE_ASPECT_RATIO) {
        //todo
      } else if (image.aspectRatio.toUpperCase() === WIDE_ASPECT_RATIO) {
        //todo
      }
    }
  } else {
    desktopImageSrc = OFFER_IMAGE_CACHE_DOMAIN + fallBackImg;
  }
  const handleExploreOffer = (): void => {
    const targetSection = document.getElementById('offers-search-form')?.offsetTop;
    const isFixedHeader = document.body.classList.contains('fixed-header');
    window.scrollTo({
      top: targetSection ? (isFixedHeader ? targetSection - 50 : targetSection - 150) : 0,
      behavior: 'smooth',
    });
  };

  return (
    <OffersHeroMultiPropertyStyled className="brand-css">
      <div
        className={`section-wrapper ${
          variation === SINGLE_BRAND_MULTI_PROP_OFFER
            ? modelObj?.appliedCssClassNames ?? `color-scheme7`
            : MPO_MBO_STYLES
        }`}
      >
        <div className="image-section">
          <Image
            title={`${offerObj?.title} img` ?? 'Hero Banner img'}
            customClass="hero-img"
            defaultImageURL={desktopImageSrc}
            altText={`${offerObj?.title} img` ?? 'Hero Banner img'}
          ></Image>
        </div>
        <div
          className={`details-section ${
            variation === SINGLE_BRAND_MULTI_PROP_OFFER && 't-background-color-rgba'
          } ${variation}`}
        >
          <div className="details">
            {variation === SINGLE_BRAND_MULTI_PROP_OFFER && (
              <div className="logo t-brand-logo-s">
                <div className={`brand-image brand-logo-${offersHeroProps?.brand} hotel-brand-logo t-brand-logo`}></div>
              </div>
            )}
            <div className="title">
              <Heading titleText={offerObj?.title} variation={Types.headingType.title} fontSize={Types.size.medium} />
            </div>
            <div className="desc">
              <Text
                copyText={offerObj?.descriptionTeaser}
                fontSize={Types.size.medium}
                customClass={clsx('t-font-swiss721')}
                element={Types.tags.paragraph}
              />
            </div>
            <div
              className={`navigation-btns ${
                variation === SINGLE_BRAND_MULTI_PROP_OFFER ? MPO_SBO_BUTTON : MPO_MBO_BUTTON
              }`}
            >
              <Button
                callback={(): void => handleExploreOffer()}
                custom_click_track_value={`${modelObj?.id}|${modelObj?.exploreThisOffer}|${INTERNAL_CTA_TYPE}`}
                className={clsx(`m-button-primary  ${bannerTextClassName}`)}
                trackingProperties={modelObj?.trackingProperties}
                ref={OffersHeroMPORef}
              >
                {modelObj?.exploreThisOffer ? modelObj.exploreThisOffer : EXPLORE_THIS_OFFER}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </OffersHeroMultiPropertyStyled>
  );
};
