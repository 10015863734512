import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { BannerText as BannerTextComp } from '@marriott/mi-ui-library';

export const BannerTextConfig = {
  emptyLabel: 'BannerText',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/bannertext`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BannerText = (props: any) => {
  return (
    <div data-testid="banner-text" data-component-name="o-common-static-bannertext">
      <EditableComponent config={BannerTextConfig} {...props}>
        <BannerTextComp {...props.model} />
      </EditableComponent>
    </div>
  );
};
