import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledTextComponent = styled.div`
  p {
    margin-bottom: 0;
  }
`;

export const CtaBlock = styled.div<{ textAlign: string }>`
  display: flex;
  gap: ${toRem(8)};
  align-items: ${props =>
    props.textAlign === 'center' ? 'center' : props.textAlign === 'left' ? 'flex-start' : 'flex-end'};
  flex-direction: column;
  .m-link {
    padding-bottom: ${toRem(2)};
  }
  .m-link-custom {
    padding: 0 0 ${toRem(2)} 0;
    border-bottom: ${toRem(2)} solid ${baseVariables.color.base10};
    background: transparent;
    font-size: ${toRem(16)};
    font-weight: 700;
  }
  .m-link-tertiary-button-external:hover {
    border-bottom: ${toRem(2)} solid ${baseVariables.color.base10};
  }

  @media ${baseVariables.mediaQuery.sm} {
    flex-direction: row;
    align-items: center;
    gap: ${toRem(16)};
  }
`;

export const DescriptionContainer = styled.div`
  .description-action-btn {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

    .m-link-action {
      cursor: pointer;
    }
  }
`;
