// Imports for external libraries go here.
import { FC, useState, useRef, ReactElement, useEffect, Suspense, lazy } from 'react';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// The closer the import is to the file the lower it should be in this list.
import { Heading, Text, Types, baseVariables, Image, Icon, useWindowSize } from '@marriott/mi-ui-library';
import { getACDLObject, useDynamicModel } from '@marriott/mi-headless-utils';
import {
  HeaderTextStyle,
  HeroSize,
  AssetVariation,
  LinkTargetType,
  ScrimStyle,
  Target,
  URL_CONSTANTS,
  convertToCommaSeparatedNumber,
  constants,
  CopyBlockWidthEnum,
} from '../../utils';
import { HeroBannerAssetProps } from './HeroBannerAsset/HeroBannerAsset.types';
import { HeroBannerSkeletonLoader } from './HeroBannerSkeletonLoader';
import {
  PrimaryButton,
  SecondaryButton,
  LocationButton,
  StyledHeroBannerContainer,
  CoBrandWrapper,
  StyledHeroBannerImage,
} from './HeroBanner.styles';
import { HeroBannerProps } from './HeroBanner.types';
import { useMediaQuery } from '../../hooks';

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>;
  }
}
const HeroBannerVideo = lazy(() => import('./HeroBannerVideo/HeroBannerVideo'));
const HeroBannerAsset = lazy(() => import('./HeroBannerAsset/HeroBannerAsset'));
export const HeroBanner: FC<HeroBannerProps> = props => {
  const { DEFAULT, EXTRATHIN, THIN, WIDE } = CopyBlockWidthEnum;
  const { targetData, isTargetContentLoading } = useDynamicModel(props.mboxParameter);
  const {
    primaryctalabel,
    primaryctalink,
    primaryopeninanewtab,
    secondaryctalabel,
    secondaryctalink,
    secondaryopeninanewtab,
    locationctalabel,
    locationctalink,
    locationopeninanewtab,
    hideLocationCtaMobile,
    headertext,
    subheadingtext,
    eyebrowtext,
    headertextstyle,
    heroSize,
    cobrandmathhero,
    balance,
    bonus,
    newtotal,
    staticpoint,
    assetsVariation,
    fileReferenceImage,
    imgAltText,
    font_icon,
    enableMaskingOnDesktop,
    imageLoading,
    appliedCssClassNames,
    enableTarget,
    trackingProperties,
    opensInNewTab,
    playerId,
    videoaccountId,
    videoIdDesktop,
    videoIdMobile,
    videoEnabledDesktop = Boolean(videoIdDesktop?.trim()),
    videoEnabledMobile = Boolean(videoIdMobile?.trim()),
    enableAutoPlay,
    enableVideoLoop,
    enableMute,
    fileReferenceVideoCoverImage,
    playerDomain = URL_CONSTANTS.BRIGHTCOVE_PLAYER_DOMAIN,
    aspectRatioMobileVideo,
    fileReferenceImageVertical,
    verticalImageAltText,
    fontIconAltText,
    backgroundMedia,
    componentId,
    copyBlockWidth = DEFAULT,
    backgroundimagealttext,
  } = props.mboxParameter ? targetData : props;
  const { width } = useWindowSize();
  const HeroBannerRef = useRef(null);
  const { NO_OPENER, NO_REFERER, TARGET_BLANK, COBRAND_MATH_HERO_CTA_CLASS } = constants;

  const { trackingTag1, trackingTag2 } = trackingProperties ?? {};
  const { assetPath, altText, dynamic, renditions } = backgroundMedia ?? {};

  const [hasMobileVideoError, setHasMobileVideoError] = useState(false);
  const [hasDesktopVideoError, setHasDesktopVideoError] = useState(false);

  const showVideoOnDesktopOnly =
    videoEnabledDesktop && videoEnabledMobile && !hasDesktopVideoError && hasMobileVideoError;

  const showVideoOnMobileOnly =
    (videoEnabledDesktop && videoEnabledMobile && hasDesktopVideoError && !hasMobileVideoError) ||
    (!videoEnabledDesktop && videoEnabledMobile && !hasMobileVideoError);

  const showVideoOnDesktopAndMobile =
    (videoEnabledDesktop && !videoEnabledMobile && !hasDesktopVideoError) ||
    (videoEnabledDesktop && videoEnabledMobile && !hasDesktopVideoError && !hasMobileVideoError);

  const { INTERNAL, EXTERNAL } = LinkTargetType;
  const { SIZE_16x9, SIZE_3x1, SIZE_2X1 } = HeroSize;
  const { WITHOUT_CO_BRAND, H1, H3, H4, H5, H6 } = HeaderTextStyle;
  const { headingType, size, tags } = Types;
  const { LIGHT_SCRIM } = ScrimStyle;
  const { ENABLE_TARGET, DISABLE_TARGET } = Target;
  const { VERTICAL_IMAGE } = AssetVariation;
  const isDesktopViewPort = useMediaQuery(baseVariables.mediaQuery.lg);
  const commonButtonClassname = isDesktopViewPort ? 'm-button-m' : 'm-button-s';
  const isVerticalImage = !!(assetsVariation && assetsVariation === VERTICAL_IMAGE && fileReferenceImageVertical);
  const isVerticalImageFromProps = !!(
    props.assetsVariation &&
    props.assetsVariation === VERTICAL_IMAGE &&
    props.fileReferenceImageVertical
  );
  const isCopyBlockWidthWide = !!(copyBlockWidth === WIDE);
  const isLightScrim = appliedCssClassNames?.includes(LIGHT_SCRIM);
  const shouldEnableDesktopMasking = enableMaskingOnDesktop && (heroSize === SIZE_16x9 || heroSize === SIZE_2X1);
  const eyebrowTextClassName = clsx(
    isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal',
    'mb-1 mb-lg-2'
  );
  const headingTextClassName = clsx(
    'hb__heading',
    isVerticalImage ? `vertical__image__hero-${heroSize} ` : '',
    isLightScrim ? '' : 't-color-inverse',
    heroSize === SIZE_16x9 || heroSize === SIZE_2X1 ? '' : ' mb-0',
    heroSize === SIZE_16x9 && headertextstyle === WITHOUT_CO_BRAND && !isVerticalImage ? ' hb__heading-cobrand' : '',
    (assetsVariation === AssetVariation.IMAGE && fileReferenceImage && !cobrandmathhero) ||
      (assetsVariation === AssetVariation.FONT_ICON && font_icon && !cobrandmathhero)
      ? ' hb__heading__assetsVariation '
      : '',
    !isVerticalImage && cobrandmathhero ? ' hb__heading__cobrandMathHero ' : ''
  );
  const subHeadingTextClassName = clsx(
    'hb__subheading',
    isVerticalImage ? `vertical__image__hero-${heroSize} ` : '',
    isLightScrim ? '' : 't-color-inverse',
    heroSize === SIZE_16x9 || heroSize === SIZE_2X1 ? '' : ' mt-1 mb-0',
    (assetsVariation === AssetVariation.IMAGE && fileReferenceImage && !cobrandmathhero) ||
      (assetsVariation === AssetVariation.FONT_ICON && font_icon && !cobrandmathhero)
      ? ' hb__subheading__assetsVariation '
      : '',
    !isVerticalImage && cobrandmathhero ? ' hb__subheading__cobrandMathHero ' : ''
  );
  const primaryButtonClassName = clsx(
    commonButtonClassname,
    isLightScrim ? 'm-button-primary' : 'm-button-primary-inverse'
  );
  const secondaryButtonClassName = clsx(
    commonButtonClassname,
    isLightScrim ? 'm-button-secondary' : ' m-button-secondary-inverse',
    'no-impression'
  );

  const copyBlockWidthContainerClass: string =
    copyBlockWidth === DEFAULT || (width && width < 576)
      ? 'col-12 col-xl-5 col-sm-6'
      : clsx('break-word col-12', {
          'col-xl-3 col-sm-4': copyBlockWidth === EXTRATHIN,
          'col-xl-4 col-sm-5': copyBlockWidth === THIN,
          'col-xl-6 col-sm-7': copyBlockWidth === WIDE && !isVerticalImage,
          'col-xl-7 col-lg-8 col-md-7 pr-lg-0 pr-xl-2': copyBlockWidth === WIDE && isVerticalImage,
        });
  const heroBannerAssetProps: HeroBannerAssetProps = {
    assetsVariation,
    fileReferenceImageVertical,
    verticalImageAltText,
    fontIcon: font_icon || '',
    fontIconAltText: fontIconAltText || '',
    heroSize,
    imgAltText,
    fileReferenceImage,
    cobrandmathhero,
    isCopyBlockWidthWide,
  };

  function createCobrandContent() {
    //TODO: Revalidate the value once Datalayer is implemented for Logged In state.
    const dataLayerObj = getACDLObject();
    const balancePoints = parseInt(`${dataLayerObj?.['mr_prof_points_balance'] ?? '0'}`, 10);
    const bonusPoint = staticpoint ? parseInt(staticpoint, 10) : 0;
    const totalPoints: number = balancePoints + bonusPoint;
    const cobrandLabelClassName = clsx(
      isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal',
      'hb__cobrand-label'
    );
    const cobrandBalanceClassName = clsx(
      'hb__cobrand-balance-points',
      isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal'
    );
    const cobrandBonusClassName = clsx(
      'hb__cobrand-bonus-points',
      isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal'
    );
    const cobrandTotalClassName = clsx(
      'hb__cobrand-total-points',
      isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal'
    );

    return (
      <CoBrandWrapper data-testid="herobanner" data-component-name="o-herobanner" className="row">
        <div className="col-lg-7 hb__cobrand">
          <div className="hb__cobrand-balance">
            <Text
              copyText={balance ?? ''}
              customClass={cobrandLabelClassName}
              fontSize={size.small}
              element={tags.div}
            />
            <div>
              <Text
                copyText={convertToCommaSeparatedNumber(balancePoints)}
                customClass={cobrandBalanceClassName}
                fontSize={size.small}
                element={tags.div}
              />
            </div>
          </div>
          <Icon iconClass="icon-plus"></Icon>
          <div className="hb__cobrand-bonus">
            <Text copyText={bonus ?? ''} customClass={cobrandLabelClassName} fontSize={size.small} element={tags.div} />
            <div>
              <Text
                copyText={convertToCommaSeparatedNumber(bonusPoint) ?? '0'}
                customClass={cobrandBonusClassName}
                fontSize={size.small}
                element={tags.div}
              />
            </div>
          </div>
          <div className="d-sm-flex d-none equal-icon"> = </div>
          <div className="hb__cobrand-total">
            <Text
              copyText={newtotal ?? ''}
              customClass={cobrandLabelClassName}
              fontSize={size.small}
              element={tags.div}
            />
            <div className="hb__cobrand-total__points-wrapper">
              <Text
                copyText={convertToCommaSeparatedNumber(totalPoints)}
                customClass={cobrandTotalClassName}
                fontSize={size.small}
                element={tags.div}
              />
            </div>
          </div>
        </div>
      </CoBrandWrapper>
    );
  }

  // form custom click track value for hero cta's
  const formCustomClickTrackValue = (label: string, openInNewtab?: boolean): string => {
    return trackingProperties?.clickTrack && !trackingProperties?.impressionTrack
      ? `${headertext ? headertext : subheadingtext}|${label}|${openInNewtab ? EXTERNAL : INTERNAL}`
      : '';
  };

  // for rendering open in new tab screen reader content for button
  const renderOpenInNewTabSRContent = (openInNewtab?: boolean): ReactElement | null => {
    return openInNewtab ? <span className="sr-only">{opensInNewTab}</span> : null;
  };

  useEffect(() => {
    window.addEventListener('load', () => {
      const parentContainer = document?.querySelector('.herobanner-fullbleed');
      parentContainer?.classList.remove('target-background');
      parentContainer?.classList.remove(`herobanner-ratio-${heroSize}`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={''}>
      <StyledHeroBannerContainer
        className={clsx({
          hb__masking: shouldEnableDesktopMasking,
          'hb__video-enabled-desktop': showVideoOnDesktopAndMobile,
          'hb__video-enabled-desktop-only': showVideoOnDesktopOnly,
          'hb__video-enabled-mobile-only': showVideoOnMobileOnly,
        })}
        id={componentId}
        shouldClipPathFor2x1={!showVideoOnDesktopAndMobile && !showVideoOnDesktopOnly} //we use clip-path for 2x1 hero (for min-width: 768px) but we should not apply it when video is enabled on desktop
      >
        {isTargetContentLoading ? (
          <HeroBannerSkeletonLoader
            width={width}
            copyBlockWidth={props.copyBlockWidth} //explicily to be read from props as initial width of elements depends on initial props
            copyBlockWidthContainerClass={copyBlockWidthContainerClass}
            isVerticalImage={isVerticalImageFromProps}
            eyebrowtext={props.eyebrowtext}
            heroSize={props.heroSize}
            cobrandmathhero={props.cobrandmathhero}
            subheadingtext={props.subheadingtext}
            secondaryctalabel={props.secondaryctalabel}
            primaryctalabel={props.primaryctalabel}
            headerText={props.headertext}
            appliedCssClassNames={props.appliedCssClassNames}
          />
        ) : (
          <div
            className={clsx(
              'hero-banner hb__scrim',
              appliedCssClassNames,
              enableTarget ? ENABLE_TARGET : DISABLE_TARGET
            )}
          >
            <div className={`hb-item hero-${heroSize} ${assetsVariation}`}>
              <StyledHeroBannerImage>
                <Image
                  customClass={clsx('hb__img', heroSize !== SIZE_16x9 ? 'hb-3x1-ratio' : '')}
                  defaultImageURL={assetPath}
                  altText={altText || backgroundimagealttext || 'Hero Image'}
                  dynamic={dynamic}
                  renditions={renditions}
                  title={altText || backgroundimagealttext || 'Hero Image'}
                  loading={imageLoading}
                />
              </StyledHeroBannerImage>
              <HeroBannerVideo
                playerId={playerId}
                videoaccountId={videoaccountId}
                videoIdDesktop={videoIdDesktop}
                videoIdMobile={videoIdMobile}
                videoEnabledDesktop={videoEnabledDesktop}
                videoEnabledMobile={videoEnabledMobile}
                enableAutoPlay={enableAutoPlay}
                enableVideoLoop={enableVideoLoop}
                enableMute={enableMute}
                fileReferenceVideoCoverImage={fileReferenceVideoCoverImage}
                playerDomain={playerDomain}
                location={headertext ? headertext : subheadingtext}
                aspectRatioMobileVideo={aspectRatioMobileVideo}
                clickTrack={trackingProperties?.clickTrack}
                heroSize={heroSize}
                hasDesktopVideoError={hasDesktopVideoError}
                hasMobileVideoError={hasMobileVideoError}
                setHasDesktopVideoError={setHasDesktopVideoError}
                setHasMobileVideoError={setHasMobileVideoError}
              />
              <div
                className={clsx(
                  'hb__cnt-sec gradient',
                  !(appliedCssClassNames?.includes('light-scrim') || appliedCssClassNames?.includes('no-scrim'))
                    ? copyBlockWidth
                    : '',
                  { 'overlay-text-hero3x1': heroSize === SIZE_3x1 }
                )}
              >
                <div
                  className={clsx(`container hb__cnt-ctr hero-${heroSize}`, {
                    vertical__image__Container: isVerticalImage,
                  })}
                >
                  <HeroBannerAsset {...heroBannerAssetProps} />
                  <div
                    className={clsx('hb__text-overlay', {
                      'col-12 col-lg-5 col-md-7 col-sm-12 vertical__image__centeraligned': isVerticalImage,
                    })}
                  >
                    {!isVerticalImage && eyebrowtext ? (
                      <div className="row">
                        <div className={copyBlockWidthContainerClass}>
                          <Text
                            copyText={eyebrowtext}
                            fontSize={size.extraSmall}
                            customClass={eyebrowTextClassName}
                            element={tags.div}
                          />
                        </div>
                      </div>
                    ) : null}

                    {/* Header Text code starts here  */}

                    {headertext ? (
                      <div className="row">
                        <div className={clsx(copyBlockWidthContainerClass)}>
                          <Heading
                            variation={headingType.title}
                            element={
                              headertextstyle === H1
                                ? tags.h1
                                : headertextstyle === H3
                                ? tags.h3
                                : headertextstyle === H4
                                ? tags.h4
                                : headertextstyle === H5
                                ? tags.h5
                                : headertextstyle === H6
                                ? tags.h6
                                : tags.h2
                            }
                            titleText={headertext}
                            customClass={headingTextClassName}
                          />
                        </div>
                      </div>
                    ) : null}
                    {/* Subheading text code starts here  */}
                    {subheadingtext ? (
                      <div className="row">
                        <div className={clsx(copyBlockWidthContainerClass)}>
                          <Heading
                            variation={headingType.subtitle}
                            element={tags.paragraph}
                            fontSize={size.medium}
                            titleText={subheadingtext}
                            customClass={subHeadingTextClassName}
                          />
                        </div>
                      </div>
                    ) : null}
                    {isCopyBlockWidthWide && !isVerticalImage && cobrandmathhero ? createCobrandContent() : null}
                    {/* Primary and Secondary Cta Button */}
                    <div className="row">
                      <div className="col-12">
                        <div
                          className={clsx({
                            flexLayout: !isVerticalImage,
                          })}
                        >
                          {primaryctalabel ? (
                            <PrimaryButton
                              buttonCopy={primaryctalabel}
                              className={clsx(primaryButtonClassName, {
                                'm-button-external': primaryopeninanewtab,
                                [COBRAND_MATH_HERO_CTA_CLASS]: cobrandmathhero,
                                'primaryButton-3x1': heroSize === SIZE_3x1,
                              })}
                              isLink={true}
                              href={primaryctalink}
                              ref={HeroBannerRef}
                              target={primaryopeninanewtab ? TARGET_BLANK : ''}
                              rel={primaryopeninanewtab ? `${NO_OPENER} ${NO_REFERER}` : undefined}
                              linkType={primaryopeninanewtab ? EXTERNAL : INTERNAL}
                              externalArrow={primaryopeninanewtab}
                              isTextBeforeChildren={true}
                              compName={'herobanner'}
                              linkAriaLabelOpenNewWindow={opensInNewTab}
                              trackingProperties={{
                                ...trackingProperties,
                                trackingTag: trackingTag1,
                              }}
                              custom_click_track_value={formCustomClickTrackValue(
                                primaryctalabel,
                                primaryopeninanewtab
                              )}
                            >
                              {renderOpenInNewTabSRContent(primaryopeninanewtab)}
                            </PrimaryButton>
                          ) : null}
                          {!isVerticalImage && secondaryctalabel ? (
                            <SecondaryButton
                              buttonCopy={secondaryctalabel}
                              className={clsx(secondaryButtonClassName, {
                                'm-button-external': secondaryopeninanewtab,
                                [COBRAND_MATH_HERO_CTA_CLASS]: cobrandmathhero,
                                'secondaryButton-3x1': heroSize === SIZE_3x1,
                              })}
                              isLink={true}
                              href={secondaryctalink}
                              ref={HeroBannerRef}
                              target={secondaryopeninanewtab ? TARGET_BLANK : ''}
                              rel={secondaryopeninanewtab ? `${NO_OPENER} ${NO_REFERER}` : undefined}
                              linkType={secondaryopeninanewtab ? EXTERNAL : INTERNAL}
                              externalArrow={secondaryopeninanewtab}
                              isTextBeforeChildren={true}
                              compName={'herobanner'}
                              linkAriaLabelOpenNewWindow={opensInNewTab}
                              trackingProperties={{
                                ...trackingProperties,
                                trackingTag: trackingTag2,
                              }}
                              custom_click_track_value={formCustomClickTrackValue(
                                secondaryctalabel,
                                secondaryopeninanewtab
                              )}
                            >
                              {renderOpenInNewTabSRContent(secondaryopeninanewtab)}
                            </SecondaryButton>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Location Cta Button */}
                {locationctalabel ? (
                  <LocationButton
                    buttonCopy={locationctalabel}
                    className={clsx('t-label-xs ml-3 ml-md-0', { 'd-none d-md-block': hideLocationCtaMobile })}
                    isLink={true}
                    href={locationctalink}
                    ref={HeroBannerRef}
                    target={locationopeninanewtab ? TARGET_BLANK : ''}
                    rel={locationopeninanewtab ? `${NO_OPENER} ${NO_REFERER}` : undefined}
                    linkType={locationopeninanewtab ? EXTERNAL : INTERNAL}
                    externalArrow={locationopeninanewtab}
                    isTextBeforeChildren={false}
                    compName={'herobanner'}
                    linkAriaLabelOpenNewWindow={opensInNewTab}
                    custom_click_track_value={formCustomClickTrackValue(locationctalabel, locationopeninanewtab)}
                  >
                    <span className="icon-location"></span>
                    {renderOpenInNewTabSRContent(locationopeninanewtab)}
                  </LocationButton>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </StyledHeroBannerContainer>
    </Suspense>
  );
};
