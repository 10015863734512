import React, { FC, RefObject, useEffect, useRef, useState } from 'react';

import { TextComponentProps } from './TextComponent.types';
import { StyledTextComponent, CtaBlock, DescriptionContainer } from './TextComponent.styles';
import { Eyebrow } from '../../atoms/Eyebrow';
import clsx from 'clsx';
import { Button } from '../../atoms/Button';
import { ButtonTypeVariation, expanded_constants, headingType, size } from '../../utils/enums/enums';
import { Heading } from '../../atoms/Heading';
import { RichText } from '../RichText';
import { throttle } from '../../utils/helper';

export const TextComponent: FC<TextComponentProps> = props => {
  let { eyebrow } = props;
  eyebrow = eyebrow && eyebrow.substring(0, 32);

  const {
    componentId,
    subHeader,
    styleclass,
    trackingProperties,
    primaryCtaLabel,
    primaryCtaLink,
    primaryCtaType,
    secondaryCtaLabel,
    secondaryCtaLink,
    secondaryCtaType,
    header,
    openInNewTabPrimary,
    openInNewTabSecondary,
    headerTag,
    contentAlignment,
    headerFontSize = size.medium,
    subHeaderFontSize = size.large,
    descriptionFontSize = size.medium,
    subHeaderTag,
    descriptionText,
    showLessDesc,
    customClass,
  } = props;

  const primaryCTAisLink = primaryCtaLink ? true : false;
  const secondaryCTAisLink = secondaryCtaLink ? true : false;

  const buttonCallbackOne = () => {
    window.open(primaryCtaLink, openInNewTabPrimary ? '_blank' : '_self');
  };

  const buttonCallbackTwo = () => {
    window.open(secondaryCtaLink, openInNewTabSecondary ? '_blank' : '_self');
  };

  function getCtaClasses(ctaType: string, openInNewTab: boolean) {
    let classNames = '';

    switch (ctaType) {
      case 'primaryButton':
        classNames += 'm-button-primary d-flex align-items-center';
        break;
      case 'secondaryButton':
        classNames += 'm-button-secondary d-flex align-items-center';
        break;
      case 'standaloneLink':
        classNames += 'm-link-custom';
        break;
      case 'tertiaryLink':
        classNames += 'm-link-tertiary-button';
        break;
      default:
        break;
    }

    if (openInNewTab) {
      if (ctaType === 'primaryButton' || ctaType === 'secondaryButton') {
        classNames += ' m-button-external';
      } else {
        classNames += ' m-link-tertiary-button-external';
      }
    }

    return classNames.trim();
  }

  const ctaOne = () => {
    return (
      primaryCtaLink &&
      primaryCtaLabel && (
        <Button
          href={primaryCtaLink}
          isLink={primaryCTAisLink}
          target={openInNewTabPrimary ? '_blank' : '_self'}
          callback={!primaryCTAisLink ? buttonCallbackOne : undefined}
          className={getCtaClasses(primaryCtaType as string, openInNewTabPrimary as boolean)}
          custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.description}|${
            openInNewTabPrimary ? 'external' : 'internal'
          }`}
          buttonCopy={primaryCtaLabel}
          isTextBeforeChildren={false}
        />
      )
    );
  };

  const ctaTwo = () => {
    return (
      secondaryCtaLink &&
      secondaryCtaLabel && (
        <Button
          href={secondaryCtaLink}
          isLink={secondaryCTAisLink}
          target={openInNewTabSecondary ? '_blank' : '_self'}
          callback={!secondaryCTAisLink ? buttonCallbackTwo : undefined}
          className={getCtaClasses(secondaryCtaType as string, openInNewTabSecondary as boolean)}
          custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.description}|${
            openInNewTabSecondary ? 'external' : 'internal'
          }`}
          buttonCopy={secondaryCtaLabel}
          isTextBeforeChildren={false}
        />
      )
    );
  };

  const ctas = () => {
    return (
      <CtaBlock textAlign={contentAlignment || ''}>
        {ctaOne()}
        {ctaTwo()}
      </CtaBlock>
    );
  };

  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  const TextDescriptionRef = useRef<HTMLDivElement>(null);

  const ELLIPSIS_2LINESHEIGHT = 48;

  const { SEE_LESS, SEE_MORE } = expanded_constants;
  const [showLinkText, setShowLinkText] = useState(SEE_MORE);
  const [isShowMoreLinkHide, setIsShowMoreLinkHide] = useState(showLessDesc);

  const handleExpandButton = (): void => {
    setShowLinkText(prevText => {
      if (prevText === SEE_LESS) return SEE_MORE;
      else if (prevText === SEE_MORE) return SEE_LESS;
      return SEE_LESS;
    });
  };

  const isEllipsisAdded = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      return (
        ref.current.scrollHeight > ref.current.clientHeight ||
        !(ref.current.clientHeight <= ELLIPSIS_2LINESHEIGHT && ref.current.scrollHeight <= ELLIPSIS_2LINESHEIGHT)
      );
    }
    return false;
  };

  useEffect(() => {
    function handleSeMoreOnResize() {
      if (showLessDesc) {
        if (TextDescriptionRef.current) {
          setIsShowMoreLinkHide(isEllipsisAdded(TextDescriptionRef));
        }
      } else {
        setIsShowMoreLinkHide(false);
      }
    }

    const throttledResizeHandler = throttle(handleSeMoreOnResize, 500);
    const observer = new ResizeObserver(throttledResizeHandler);
    observer.observe(document.body);
  }, [showLessDesc, showLinkText]);

  return (
    <StyledTextComponent
      data-component-name="m-ui-library-TextComponent"
      data-testid="ui-library-TextComponent"
      className={clsx(
        'container',
        contentAlignment === 'left' ? 'd-flex align-items-left flex-column' : '',
        contentAlignment === 'center' ? 'd-flex align-items-center flex-column' : '',
        contentAlignment === 'right' ? 'd-flex align-items-end flex-column' : '',
        styleclass,
        customClass
      )}
      {...customAttributes}
    >
      {eyebrow && <Eyebrow text={eyebrow} customClass={'mb-3'} />}

      {header && (
        <Heading
          customClass={clsx(
            'mb-3',
            contentAlignment === 'center' && 'text-center',
            contentAlignment === 'right' && 'text-right'
          )}
          variation={headingType.title}
          fontSize={headerFontSize}
          element={headerTag}
          dangerouslySet={header}
        />
      )}
      {subHeader && (
        <Heading
          customClass={clsx(
            'mb-3',
            contentAlignment === 'center' && 'text-center',
            contentAlignment === 'right' && 'text-right'
          )}
          variation={headingType.subtitle}
          fontSize={subHeaderFontSize}
          element={subHeaderTag}
          dangerouslySet={subHeader}
        />
      )}

      {descriptionText && (
        <DescriptionContainer
          className={clsx(
            contentAlignment === 'center' && 'text-center',
            contentAlignment === 'right' && 'text-right',
            't-font-' + descriptionFontSize,
            isShowMoreLinkHide === true ? 'pb-3' : '',
            'mb-3'
          )}
        >
          <RichText
            text={descriptionText}
            ref={TextDescriptionRef}
            componentId={componentId + '-DescriptionText'}
            customClass={clsx(showLessDesc && showLinkText === SEE_MORE ? 'm-ellipsis-2lines' : '', 'mb-3')}
          ></RichText>
          {isShowMoreLinkHide && (
            <div className="description-action-btn">
              <span className="m-link-action" onClick={handleExpandButton}>
                {showLinkText}
              </span>
              <span className="icon-arrow-right"></span>
            </div>
          )}
        </DescriptionContainer>
      )}
      {ctas()}
    </StyledTextComponent>
  );
};
