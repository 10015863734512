import React, { FC } from 'react';
import clsx from 'clsx';

import { BreadCrumbProps } from './HtmlSitemap.types';
import { StyledBreadcrumbContainer } from './HtmlSitemap.styles';
import { linksCollectionMock } from './__mock__/HtmlSitemap.mock';

import {
  LinksCollection,
  Heading,
  Types,
  LinksCollectionVariations,
  LinksCollectionAlignment,
  LinksCollectionProps,
} from '@marriott/mi-ui-library';

// Use named rather than default exports.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BreadCrumb: FC<BreadCrumbProps> = (props): any => {
  const { navlinks, subTitle } = props;
  console.log('BreadCrumb props', props);
  const defaultTitle = subTitle ? subTitle : 'Hotel Sitemap';
  const breadcrumbSubTitle =
    navlinks?.length > 0
      ? navlinks?.slice(-1)?.pop() !== undefined
        ? navlinks?.slice(-1)?.pop()?.linkLabel
        : defaultTitle
      : defaultTitle;
  const linksCollectionProps: LinksCollectionProps = linksCollectionMock;
  linksCollectionProps['variation'] = LinksCollectionVariations.Standard;
  linksCollectionProps['alignment'] = LinksCollectionAlignment.Horizontal;
  linksCollectionProps['linkListData'] = navlinks;

  return (
    <StyledBreadcrumbContainer>
      {navlinks && <LinksCollection {...linksCollectionProps} />}
      {breadcrumbSubTitle && (
        <Heading
          data-testid="breadcrumb-header"
          variation={Types.headingType.body}
          fontSize={Types.size.large}
          element={Types.tags.h1}
          titleText={breadcrumbSubTitle}
          customClass={clsx('subTitle pt-2')}
        />
      )}
    </StyledBreadcrumbContainer>
  );
};
