/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';
import { OUTLET_HERO_FALLBACK_IMAGE } from '../../modules/utils/constants/constants';

export interface outletDetailsState {
  outletData: any;
  outletDataLoading: boolean;
  outletDataError: boolean;
  skipQuery: boolean;
  outletGalleryTabData: any;
  outletGalleryError: boolean;
  outletMenuSignatureData: any;
  outletMenuSignatureError: boolean;
  outletOffersData: any;
  outletOffersError: boolean;
  setData: (data: any, loading: boolean, error: any, fallbackData: any, heroBannerImages: any) => void;
  setGalleryTabData: (data: any) => void;
  setMenuTabData: (data: any) => void;
  setOutletOffersData: (data: any) => void;
  setOutletMenuSignatureError: (outletMenuSignatureError: boolean) => void;
  setGalleryError: (outletGalleryError: boolean) => void;
  setOutletOfferError: (outletGalleryError: boolean) => void;
}

const initialState: outletDetailsState = {
  outletData: null,
  outletDataLoading: true,
  outletDataError: false,
  skipQuery: false,
  outletGalleryTabData: null,
  outletMenuSignatureData: null,
  outletGalleryError: false,
  outletMenuSignatureError: false,
  outletOffersData: null,
  outletOffersError: false,
  setData: () => {},
  setGalleryTabData: () => {},
  setMenuTabData: () => {},
  setOutletMenuSignatureError: () => {},
  setGalleryError: () => {},
  setOutletOffersData: () => {},
  setOutletOfferError: () => {},
};

export const OutletDetailsStore: StateCreator<outletDetailsState> = set => {
  return {
    ...initialState,
    setData: (data, loading, error, fallbackData, heroBannerImages) => {
      if (error && !data) {
        set({ outletData: null, outletDataLoading: loading, outletDataError: true, skipQuery: true });
      } else if (data && Object.keys(data).length) {
        const outledIdDetails = data || '';
        const isNameAvlb = !!outledIdDetails?.basicInformation?.name?.length;

        const errorCondition = isNameAvlb === false;
        set({
          outletData: { ...data, heroBannerImages, fallbackData },
          outletDataLoading: loading,
          outletDataError: outledIdDetails && !errorCondition ? false : true,
          skipQuery: true,
        });
      }
    },
    setGalleryTabData: data => {
      set({
        outletGalleryTabData: data,
      });
    },
    setGalleryError: value => {
      set({
        outletGalleryError: value,
      });
    },
    setMenuTabData: data => {
      set({
        outletMenuSignatureData: data,
      });
    },
    setOutletMenuSignatureError: value => {
      set({
        outletMenuSignatureError: value,
      });
    },
    setOutletOffersData: data => {
      set({
        outletOffersData: data,
      });
    },
    setOutletOfferError: data => {
      set({
        outletOffersError: data,
      });
    },
  };
};

export const useStore = createAppStore(OutletDetailsStore, {
  usePersistentStore: false,
});
