import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledContainer = styled.div`
  background-color: ${baseVariables.color.merch50};

  .linkText {
    text-decoration: none;
    margin-top: ${toRem(24)};

    @media ${baseVariables.mediaQuery.mobile} {
      float: left;
      text-decoration: none;
    }
  }

  .description {
    height: auto;
    width: 100%;
    margin-top: ${toRem(31.5)};
    text-align: left;
    .desc {
      @media ${baseVariables.mediaQuery.mobile} {
        color: ${baseVariables.color.black};
      }
    }
    @media ${baseVariables.mediaQuery.mobile} {
      height: auto;
      width: 100%;
      text-align: left;
    }
  }
  .floatingContainer {
    margin: ${toRem(28)} 0 0 0;
    text-align: left;
    padding-bottom: ${toRem(0)};

    @media ${baseVariables.mediaQuery.desktop}, @media ${baseVariables.mediaQuery.largeDesktop} {
      margin-left: ${toRem(772)};
      float: right;
      margin-top: -${toRem(50)};
      padding-bottom: ${toRem(120)};
    }
  }

  .floatingContainerNew {
    @media ${baseVariables.mediaQuery.allTablets} {
      margin-top: -${toRem(30)};
    }

    @media ${baseVariables.mediaQuery.desktop} {
      margin-top: -${toRem(50)};
    }

    @media ${baseVariables.mediaQuery.largeDesktop} {
      margin-top: -${toRem(50)};
    }
  }

  .container {
    @media ${baseVariables.mediaQuery.desktop}, @media ${baseVariables.mediaQuery.largeDesktop} {
      width: 100%;
    }

    @media ${baseVariables.mediaQuery.mobile} {
      width: 100%;
      height: auto;
    }
  }
`;
