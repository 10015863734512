/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { Model } from '@adobe/aem-spa-page-model-manager';

import {
  DCA_CONSTANTS,
  HYBRID_CONFIG_CONSTANTS,
  DEFAULT_ACCEPT_LANGUAGE,
  ACCEPT_LANGUAGE_TEST_REGEX,
  PI_DATA as dataLayerFilterList,
} from './constants';
import { mockSessionData } from '../mocks/sessionData';
import { logger } from './logger';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { NonEmptyString } from './helpers.types';

const { NEXT_PUBLIC_DYNAMIC_ROUTING_URL, SESSION_APP_CALL_URL, NEXT_PUBLIC_FEATURE_FLAG_URL } = process.env;
export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export const getProcessEnvs = () => {
  const processENV = process.env && { ...process.env };
  delete processENV['NEXT_AEM_AUTH'];
  delete processENV['NEXT_PUBLIC_AEM_AUTH'];
  if (canUseDOM) {
    const envTag = document.getElementById('__SERVERENV__');
    if (envTag) {
      const envObject = JSON.parse(envTag.innerHTML);
      return envObject;
    }
    return processENV;
  }
  return processENV;
};

export async function getPageModel(
  path: string,
  locale: string,
  sessionID: string,
  applicationName: string,
  selector: string,
  isAuthenticated = false,
  eaaPageIndicator = '',
  PREPROCESSOR_EXPERIENCE_SEGMENT: Array<string>,
  productSpecificSeoUrl = null
) {
  const { log, pLog } = global.loggerInstance('ModelCall');
  const { ROUTING_CONFIG, EXPERIENCE_SEGMENT, EXPERIENCE_SEGMENT_AUTHENTICATED } = HYBRID_CONFIG_CONSTANTS;
  locale = locale?.replace('_', '-');
  const pagePath = path.split('?')[0];

  const getCMSTemplateReqBody: {
    requestType: string;
    seoUrl: string;
    localeKey: string;
    experienceSegment: Array<string>;
    applicationName: string;
    sessionToken: string;
    selector?: string;
    eaaPageIndicator?: string;
    selectors?: string;
  } = {
    requestType: ROUTING_CONFIG,
    seoUrl: productSpecificSeoUrl ? productSpecificSeoUrl : pagePath,
    localeKey: locale,
    experienceSegment: PREPROCESSOR_EXPERIENCE_SEGMENT
      ? PREPROCESSOR_EXPERIENCE_SEGMENT
      : isAuthenticated
      ? [...EXPERIENCE_SEGMENT_AUTHENTICATED]
      : [...EXPERIENCE_SEGMENT],
    applicationName: applicationName,
    sessionToken: sessionID,
  };

  if (selector) {
    getCMSTemplateReqBody['selectors'] = selector;
  }
  if (eaaPageIndicator) {
    getCMSTemplateReqBody['experienceSegment'] = [...getCMSTemplateReqBody['experienceSegment'], eaaPageIndicator];
  }

  let pageModel = {};
  const apiStartTime = new Date().getTime();
  try {
    log.debug(`NEXT_PUBLIC_DYNAMIC_ROUTING_URL: ${NEXT_PUBLIC_DYNAMIC_ROUTING_URL}`);
    log.debug(`getCMSTemplateReqBody: ${JSON.stringify(getCMSTemplateReqBody)}`);
    const response = await axios.post(`${NEXT_PUBLIC_DYNAMIC_ROUTING_URL}`, getCMSTemplateReqBody);
    if (response) {
      pageModel = response.data.data;
    } else {
      log.error('AEM model failure:', response);
      pageModel = {};
    }
  } catch (err) {
    log.error('API call failed:', err);
    //returing page model and logging error
    return pageModel;
  }
  pLog.log('API call performance timing', apiStartTime, new Date().getTime());
  return pageModel;
}
//converts ":items" format to "cqItems" format.
export function transformToCQ(propKey: string) {
  const tempKey = propKey.substring(1);

  return 'cq' + tempKey.substring(0, 1).toUpperCase() + tempKey.substring(1);
}

//To format mock model in local dev, in other envs this formatting is taken care by OOTB fetchModel utility
export const respGridUtil = (item: Model) => {
  if (!item || !Object.keys(item).length) {
    return { cqPath: '' };
  }

  const keys = Object.getOwnPropertyNames(item);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props: any = {};

  keys.forEach((key: string) => {
    const propKey = key.startsWith(':') ? transformToCQ(key) : key;
    props[propKey] = item[key as keyof Model] || '';
  });

  return props;
};

export async function getSessionData(sessionIDVal: string, isLocalDev: boolean, isAuthorMode: boolean) {
  return new Promise((resolve, reject) => {
    let sessionData;
    let sessionHeader: any = {};
    const { log, pLog } = global.loggerInstance('SessionCall');
    const sessionGetCallUrl =
      SESSION_APP_CALL_URL || useClientEnvVarsStore.getState().envVarsObject['SESSION_GET_CALL_URL_CLIENT'];

    if (isLocalDev || isAuthorMode) {
      resolve({ sessionData: mockSessionData, sessionHeader: '' });
    } else {
      const apiStartTime = new Date().getTime();
      log.debug(JSON.stringify({ SESSION_APP_CALL_URL: sessionGetCallUrl, sessionIDVal }));
      axios
        .get(`${sessionGetCallUrl}`, {
          headers: {
            Cookie: `sessionID=${sessionIDVal}`,
            'Cache-Control': 'no-store, no-cache, private',
          },
        })
        .then(resp => {
          sessionHeader = resp?.headers;
          sessionData = resp?.data;
          log.debug(`Session Data loaded: ${JSON.stringify(sessionData)}`);
          resolve({ sessionData, sessionHeader });
        })
        .catch(e => {
          log.error('Error with session route!', e);
          reject('session');
        });
      pLog.log(`API call performance timing start: ${apiStartTime} end: ${new Date().getTime()}`);
    }
  });
}

export async function getSessionDataWithParams(
  preHeader: Record<string, string>,
  isLocalDev: boolean,
  isAuthorMode: boolean
) {
  return new Promise((resolve, reject) => {
    let sessionData;
    let sessionHeader: any = {};
    const { log, pLog } = global.loggerInstance('SessionCall');

    const sessionBody = { keys: process.env['NEXT_PUBLIC_SESSION_KEYS'] };

    if (isLocalDev || isAuthorMode) {
      resolve({ sessionData: mockSessionData, sessionHeader: '' });
    } else {
      const apiStartTime = new Date().getTime();
      log.debug(JSON.stringify({ SESSION_APP_CALL_URL, token: preHeader }));
      axios
        .post(`${SESSION_APP_CALL_URL}`, sessionBody, {
          headers: preHeader,
        })
        .then(resp => {
          sessionHeader = resp?.headers;
          sessionData = resp?.data;
          log.debug(`Session Data loaded: ${JSON.stringify(sessionData)}`);
          resolve({ sessionData, sessionHeader });
        })
        .catch(e => {
          log.error('Error with session route!', e);
          reject('session');
        });
      pLog.log(`API call performance timing start: ${apiStartTime} end: ${new Date().getTime()}`);
    }
  });
}

export async function getV1SessionData(
  sessionIDVal: string,
  isLocalDev: boolean,
  isAuthorMode: boolean,
  searchKeys: string
) {
  return new Promise((resolve, reject) => {
    let sessionData;
    let sessionHeader: any = {};
    const { log } = logger({ requestID: '', sessionID: '' })('SessionCall');

    const sessionApiUrl =
      process.env['SESSION_APP_GET_CALL_V1_URL'] ||
      useClientEnvVarsStore.getState().envVarsObject['SESSION_APP_GET_CALL_V1_URL'];
    if (isLocalDev || isAuthorMode) {
      log.debug(`Detected local or Author mode, using mock session`);
      resolve({ sessionData: mockSessionData, sessionHeader: '' });
    } else {
      const apiStartTime = new Date().getTime();
      log.debug(`sessionApiUrl: ${sessionApiUrl}`);
      log.debug(`session token: ${sessionIDVal}`);
      axios
        .post(
          `${sessionApiUrl}`,
          {
            keys: searchKeys,
          },
          {
            headers: {
              Cookie: `sessionID=${sessionIDVal}`,
            },
          }
        )
        .then(resp => {
          sessionHeader = resp?.headers;
          sessionData = resp?.data;
          log.debug('Session Data Loaded', sessionData);
          resolve({ sessionData, sessionHeader });
        })
        .catch(e => {
          log.debug('Error with session route!', e);
          reject('session');
        });
      log.debug('API call performance timing', apiStartTime, new Date().getTime());
    }
  });
}

/**
 * Populates the PI data layer with values from cookies based on the provided dataLayerFilterList.
 *
 * @param {boolean} isAcdl - A flag indicating whether to store the data in sessionStorage (true) or in window.dataLayer (false).
 * @returns {Record<string, string>} - The populated PI object if isAcdl is true, otherwise an empty object.
 */
export function populatePIdataLayer(isAcdl = false) {
  /**
   * Retrieves the value of a specified cookie.
   *
   * @param {string} name - The name of the cookie to retrieve.
   * @returns {string | null} - The value of the cookie, or null if not found.
   */
  function getCookieValue(name: string) {
    const match = cookieString.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  }

  const cookieString = document.cookie;
  const piObj: Record<string, string> = {};
  const memState: string = window?.dataLayer?.['memState']?.toString()?.toLowerCase() ?? '';
  const isAnonymousUser: boolean = memState !== 'authenticated' && memState !== 'remembered';

  // Iterate over each key-value pair in dataLayerFilterList
  for (const [key, val] of Object.entries(dataLayerFilterList)) {
    // Split the value by commas to handle multiple cookie names
    const cookieNames = val.split(',');
    // Iterate through each cookie name and assign the first found value to piObj
    for (const cookieName of cookieNames) {
      const value = getCookieValue(cookieName.trim());
      if (value) {
        piObj[key] = value;
        break; // Stop checking after the first valid cookie value is found
      }
      // if cookie is not present, get it from session data,
      // TODO: clean up code after current consumer ID cookie issue is fixed
      // shop page sessionData is without cacheData added fallback for home/shop/cobrand
      else if (
        (key === 'altCustId' || key === 'mr_id_alternate') &&
        (window.sessionData?.cacheData?.data?.consumerID || window.sessionData?.data?.consumerID) &&
        !isAnonymousUser
      ) {
        piObj[key] = window.sessionData?.cacheData?.data?.consumerID ?? window.sessionData?.data?.consumerID;
      }
    }
  }

  // Store the data in sessionStorage if isAcdl is true, otherwise in window.dataLayer
  if (isAcdl) {
    const oldDataLayer = JSON.parse(window.sessionStorage.getItem('oldDataLayer') || '{}');
    window.sessionStorage.setItem('oldDataLayer', JSON.stringify({ ...oldDataLayer, ...piObj }));
    return piObj;
  } else {
    window.dataLayer = { ...window.dataLayer, ...piObj };
    return {};
  }
}

export function getDataLayerScript(dataLayer: { data: any; mvpOffersData: string }) {
  return `var dataLayer = ${dataLayer?.data ? JSON.stringify(dataLayer?.data[0]) : '{}'}; var mvpOffers = ${
    dataLayer?.mvpOffersData && dataLayer?.mvpOffersData !== 'null' ? dataLayer?.mvpOffersData : '{}'
  };`;
}

export function getSessionDataScript(sessionData: Record<string, string>) {
  return `var sessionData = ${sessionData ? JSON.stringify(sessionData) : '{}'};`;
}

export async function getFeatureFlagData() {
  const { log } = global.loggerInstance('FeatureFlag');
  log.debug(`API url: ${NEXT_PUBLIC_FEATURE_FLAG_URL}`);
  return new Promise((resolve, reject) => {
    axios
      .get(`${NEXT_PUBLIC_FEATURE_FLAG_URL}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        log.error('Error with feature flag route!', e);
        reject('featureflag');
      });
  });
}

export const mtGC = (cname: string): string => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const cnameValue = c.substring(name.length, c.length);
      try {
        return decodeURIComponent(cnameValue);
      } catch {
        return cnameValue;
      }
    }
  }
  return '';
};

export const mtGCV = (cname: string): string => {
  const x: Array<string> = mtGC(cname).split('|');
  let i;
  for (i = 0; i < x.length; i++) {
    if (x[i] === DCA_CONSTANTS.MCMID_COOKIE) {
      return x[i + 1];
    }
  }
  return '';
};

export const cleanObject = (input: object): object => {
  if (typeof input === 'object' && input !== null) {
    if (Array.isArray(input)) {
      return input?.map(cleanObject)?.filter(item => item !== null && item !== undefined && item?.toString() !== '');
    }

    return Object.fromEntries(
      Object.entries(input)
        .map(([key, val]) => [key, cleanObject(val)])
        .filter(([k, v]) => v !== null && v !== undefined && v !== '' && k)
    );
  }

  return input;
};

/**
 * Validates accept-language header against a regex
 * @param header string
 * @returns boolean
 */
export const isValidAcceptLanguageHeader = (header: string): boolean => {
  const regex = ACCEPT_LANGUAGE_TEST_REGEX;
  return regex.test(header);
};

/**
 * Pulls accept-language header out of request object and validates it.
 * @param req Request object with (presumably) some heades on it
 * @returns string Valid accept-language header
 */
export const processAcceptLanguage = (locale: string): string => {
  // Remove underscore, remove spaces, split and take whaever's before the comma.
  let parsedAccLang = locale?.replace(/\s+/g, '')?.replace('_', '-').split(';')[0].split(',')[0] as NonEmptyString;
  // If a region designation is present, attempt to uppercase it.
  if (parsedAccLang?.length === 5) {
    parsedAccLang = `${parsedAccLang.split('-')[0]}-${parsedAccLang.split('-')[1].toUpperCase()}` as NonEmptyString;
  }
  // If parsed value truthy and valid, return, else return default.
  return parsedAccLang && isValidAcceptLanguageHeader(parsedAccLang)
    ? parsedAccLang
    : (DEFAULT_ACCEPT_LANGUAGE as NonEmptyString);
};

/**
 * @description Prop resolver for Server and Client
 * @param name Key of the process env variable to access
 * @returns {string|undefined} return env variable form either paeg props or process
 */
export const getEnvProp = (name: string): string | undefined => {
  if (typeof window !== 'undefined') {
    return JSON.parse(window.__NEXT_DATA__?.props?.['pageProps']?.['serverENV'] || '{}')?.[name] || '';
  }
  return process.env[name] || '';
};

/**
 *
 * @returns data layer object based on ACDL flag,
 * if true returns ACDL object else returns window.dataLayer object
 * @description as part of the new acdl implementation, the data layer object from data layer app
 * is stored and is read from session storage from oldDataLayer key
 * this needs to be updated once all the products have implemented ACDL...
 */
export const getACDLObject = () => {
  if (canUseDOM) {
    return useClientEnvVarsStore?.getState()?.envVarsObject?.['NEXT_PUBLIC_ENABLE_WEBSDK']
      ? JSON.parse(window?.sessionStorage?.getItem('oldDataLayer') || '{}')
      : window?.dataLayer;
  } else return {};
};
