/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Resource } from 'i18next';
import { ThemeProvider } from 'styled-components';
import { theme } from '@marriott/mi-ui-library-shop';
import SearchResults from './component';
import { ComponentProps } from './component/index.types';
import { PageContext } from '../../../context/PageContext';
import { getSearchQueryDataFromSession } from './lib/helper';
import { logger } from '../../../utils/src/logger';
import { ErrorHandlerAlert } from '../../molecules';
import { updateQueryParamsFromSession } from './store/store.action';

const { log } = logger({})('SearchResults');

interface SearchResultsProps {
  miSearchResults: Resource;
  searchResults: ComponentProps;
  model?: Record<string, any>;
}

export const SearchResultsComponent: React.FC<SearchResultsProps> = pageProps => {
  const pageContext = useContext(PageContext);
  log.debug('pageContext : ', pageContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initialState] = useState<any>();
  const searchResultProps = pageProps?.model || pageProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [jsonContent, setJsonContent] = useState<any>(searchResultProps);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [updatedTheme, setUpdatedTheme] = useState<any>({ ...theme, dir: '' });

  const sessionData = pageContext?.sessionData?.data
    ? pageContext?.sessionData?.data
    : pageContext?.sessionData?.cacheData?.data;

  let sessionToken = '';
  if (pageContext?.sessionData) {
    sessionToken = pageContext?.sessionData?.sessionToken ?? pageContext?.sessionData?.cacheData?.key;
  }
  const newSessionData = getSearchQueryDataFromSession(sessionData, sessionToken, pageContext?.currentLocale);

  const SessionVal =
    sessionData &&
    sessionData?.AriesSearch &&
    sessionData?.AriesSearch?.propertyFilterCriteria &&
    sessionData?.AriesSearch?.propertyFilterCriteria?.facets;
  /**
   * Function to update the specific filters value in QueryParams from session
   */
  updateQueryParamsFromSession(SessionVal, pageContext?.headersData);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const searchResultProps = pageProps?.model || pageProps;
    // pageProps = { ...pageProps };

    // setInitialState({});
    setJsonContent(searchResultProps);
    setUpdatedTheme({
      ...theme,
      dir: '',
    });
  }, [pageProps]);

  const renderComponent = () => {
    return (
      <ErrorHandlerAlert alertMessage={jsonContent?.['componentErrorMessage']}>
        <ThemeProvider theme={updatedTheme}>
          <SearchResults
            {...initialState}
            jsonContent={jsonContent}
            searchQueryData={newSessionData}
            completeSession={
              pageContext?.sessionData?.data ? pageContext?.sessionData : pageContext?.sessionData?.cacheData
            }
          />
        </ThemeProvider>
      </ErrorHandlerAlert>
    );
  };

  return renderComponent();
};
