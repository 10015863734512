/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CardCarouselContainer, CardLayeredVariations } from '@marriott/mi-ui-library';
import { useQuery } from '@apollo/client';

import { CardCarouselResortsProps } from './CardCarouselResorts.types';
import { StyledContainer } from './CardResortsWrapper.styles';
import {
  IMAGE_ALT_TEXT,
  IMPRESSION_TRACK,
  MERCHANDISING_CATEGORY,
  NO_OF_CARDS_TABLET,
  RESORT_CAROUSEL_ERROR_POLICY,
  IMAGE_CACHE_DOMAIN,
  CARD_SQUARE,
  OFFER_CAUROSEL_DEFAULT_IMAGE,
} from '../../constants/ResortCardConstants';
import { CarouselUXLMockJson } from './__mock__/ResortCarouselUXL.mock';
import { phoenixResortsCarousel } from '@marriott/mi-leisure-graphql';
import { SkeletonCardCarouselLoader } from './SkeletonCardCarouselLoader/index';
import { generateRandomNumber, getWCMModeFlagValue } from '@marriott/shared/mi-helper-utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

export const CardResortsWrapper: React.FC<CardCarouselResortsProps> = props => {
  const { model, acceptLanguage, isAuthorMode, requestId } = props;
  const [resortResult, setResultResort] = useState<any>([]);
  const [cardCount, setCardCount] = useState(0);
  const [offset, setOffset] = useState<number>(0);
  const [keys, setKeys] = useState<any>([]);
  const [totalResortCount, _setTotalResortCount] = useState({
    totalCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const acceptLang = acceptLanguage?.split(',')[0];
  const openInaNewTab = model?.openInaNewTab ? JSON.parse(model.openInaNewTab) : false;
  const isServer = !(typeof window != 'undefined' && window.document);
  const { envVarsObject } = useClientEnvVarsStore();
  const cardLayeredVariation =
    model?.cardsType === CARD_SQUARE ? CardLayeredVariations.Square : CardLayeredVariations.Tall;
  const baseRequestVariables = {
    limit: parseInt(model?.maxResortsCount),
    offset: offset,
    version: 'V1',
    search: {
      keyword: '*:*',
      facets: {},
    },
  };

  const isWcmModeDisabledFlag = getWCMModeFlagValue();
  let resortKeys = '';
  const filters: any = [];
  if (model?.hotelType) {
    const hotelTypeArray = [];
    hotelTypeArray.push(model?.hotelType);
    filters.push({
      type: 'PROPERTY_TYPES',
      dimensions: hotelTypeArray,
    });
    resortKeys = resortKeys.concat(model?.hotelType).concat(',');
  }
  if (model?.activities && model?.activities?.length > 0) {
    filters.push({
      type: 'ACTIVITIES',
      dimensions: model?.activities,
    });
    resortKeys = resortKeys.concat(model?.activities).concat(',');
  }
  if (model?.amenities && model?.amenities?.length > 0) {
    filters.push({
      type: 'AMENITIES',
      dimensions: model?.amenities,
    });
    resortKeys = resortKeys.concat(model?.amenities).concat(',');
  }
  if (model?.regions && model?.regions?.length > 0) {
    filters.push({
      type: 'LEISURE_REGIONS',
      dimensions: model?.regions,
    });
    resortKeys = resortKeys.concat(model?.regions);
  }

  const getVariables = () => {
    const resortObj: any = {};
    resortObj[resortKeys] = totalResortCount.totalCount;

    if (typeof window !== 'undefined' && totalResortCount.totalCount !== 0) {
      const resortObject: any = localStorage.getItem('resortObject');
      const parsedResortObject: any = JSON.parse(resortObject);
      const newObj = {
        ...parsedResortObject,
        ...resortObj,
      };
      localStorage.setItem('resortObject', JSON.stringify(newObj));
    }

    baseRequestVariables.search.facets = { terms: filters };
    return baseRequestVariables;
  };

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery(phoenixResortsCarousel, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    errorPolicy: RESORT_CAROUSEL_ERROR_POLICY,
    variables: getVariables(),
    context: {
      headers: {
        'x-request-id': requestId,
        'accept-language': acceptLang,
        ...(envVarsObject?.['NEXT_PUBLIC_UAT_AUTH_TOKEN'] &&
          isWcmModeDisabledFlag && { Authorization: envVarsObject?.['NEXT_PUBLIC_UAT_AUTH_TOKEN'] }),
      },
    },
    skip: isAuthorMode && !isWcmModeDisabledFlag,
  });

  useEffect(() => {
    if (queryLoading || queryError) {
      setIsLoading(true);
    } else {
      if (queryData && !queryLoading) {
        setIsLoading(false);
        console.log('queryData ', queryData);
        const mappedUxlData = processUxlData(queryData);
        processleisureCarousel(mappedUxlData);
      } else {
        if (isAuthorMode && !isWcmModeDisabledFlag && !queryLoading) {
          setIsLoading(false);
          const mappedUxlData = processUxlData(CarouselUXLMockJson);
          processleisureCarousel(mappedUxlData);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLoading, queryData, queryError]);

  function generateOffsetNumber(): number {
    console.log('resortKeys', resortKeys);
    const cardLimit = parseInt(model?.maxResortsCount);
    let newOffsetCount = 0;
    if (typeof window !== 'undefined') {
      const resortObject: any = localStorage.getItem('resortObject');
      const parsedResortObject = JSON.parse(resortObject);
      const availableResortForKey = parsedResortObject[resortKeys];
      const maxLimitOffset = availableResortForKey - cardLimit;
      if (availableResortForKey && availableResortForKey !== undefined && availableResortForKey > cardLimit) {
        newOffsetCount = Math.floor(generateRandomNumber() * (maxLimitOffset - 1 + 1)) + 1;
      }
    }
    return newOffsetCount;
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem('resortObject')?.length && totalResortCount) {
      setOffset(generateOffsetNumber());
    }
  }, [totalResortCount]);

  const processUxlData = (uxlData: any) => {
    // Initialize defaultResorts
    let defaultResorts = [];

    // Check if uxlData is defined, otherwise assign an empty array
    const response = uxlData || [];
    // Get the filtered resort count
    const filteredResortCount = response?.searchPropertiesByKeyword?.total || 0;
    totalResortCount.totalCount = filteredResortCount;
    localStorage.setItem('filteredResortCount', JSON.stringify(filteredResortCount));

    // eslint-disable-next-line prefer-const, prettier/prettier
    defaultResorts = response?.searchPropertiesByKeyword?.edges
      ?.filter((edge: any) => edge?.node?.basicInformation?.bookable === true)
      ?.map((item: any) => {
        // eslint-disable-next-line array-callback-return
        let propertyImage =
          cardLayeredVariation === CardLayeredVariations.Square
            ? item?.node?.media?.primaryImage?.edges[0]?.node?.imageUrls?.square
            : item?.node?.media?.primaryImage?.edges[0]?.node?.imageUrls?.classicVertical;

        // If propertyImage is not available, try to get it from brand photos
        if (!propertyImage && item?.node?.basicInformation?.brand?.photos?.length > 0) {
          propertyImage =
            item?.node?.basicInformation?.brand?.photos[0]?.content[0]?.url ?? OFFER_CAUROSEL_DEFAULT_IMAGE;
        }
        return {
          image: propertyImage,
          header: item?.node?.basicInformation?.name,
          propertyName: item?.node?.basicInformation?.name,
          ctaUrl:
            item?.node?.basicInformation?.brand?.id === 'RZ'
              ? `https://www.ritzcarlton.com/en/hotels/${item?.node?.seoNickname}/overview/`
              : `/hotels/travel/${item?.node?.seoNickname}`,
          newProperty: item?.node?.basicInformation?.newProperty,
          isFullyRenovated: item?.node?.basicInformation?.isFullyRenovated,
          isRecentlyRenovated: item?.node?.basicInformation?.isRecentlyRenovated,
          isAdultsOnly: item?.node?.basicInformation?.isAdultsOnly,
          hasRenovatedRooms: item?.node?.basicInformation?.hasRenovatedRooms,
          country: item?.node?.contactInformation?.address?.country?.description, //mapping with description as label is coming null in data
        };
      });
    return { resortsData: shuffleResorts(defaultResorts) };
  };

  function shuffleResorts<T>(defaultResorts: T[]): T[] {
    const shuffledResort = defaultResorts ? [...defaultResorts] : [];
    for (let i = shuffledResort.length - 1; i > 0; i--) {
      const j = Math.floor(generateRandomNumber() * (i + 1));
      [shuffledResort[i], shuffledResort[j]] = [shuffledResort[j], shuffledResort[i]];
    }
    return shuffledResort;
  }

  const processleisureCarousel = (data: any) => {
    const count = data?.resortsData?.length;
    const cardKeys = Object?.keys(data?.resortsData);
    setCardCount(count);
    setKeys(cardKeys);
    const resortMap = data?.resortsData?.map((card: any, index: number) => {
      let imageUri = '';
      let imageUriLg = '';
      if (card?.image) {
        if (card?.image?.includes('/is/image')) {
          imageUri = !isAuthorMode ? `${card?.image}?wid=364&fit=constrain` : `${card?.image}`;
          imageUriLg = !isAuthorMode ? `${card?.image}?wid=800&fit=constrain` : `${card?.image}`;
        } else if (card?.image?.includes('marriottassets') || card?.image?.includes('/content/dam')) {
          imageUri = `${card.image}?downsize=365px:*`;
          imageUriLg = `${card.image}?downsize=800px:*`;
        }
        if (!imageUri.startsWith(IMAGE_CACHE_DOMAIN)) {
          imageUri = IMAGE_CACHE_DOMAIN + imageUri;
          imageUriLg = IMAGE_CACHE_DOMAIN + imageUriLg;
        }
      }
      const cardLocDetails = model?.trackingProperties?.location
        ? `${model.trackingProperties.location}-card-${index}`
        : `card-${index}`;
      const eyeBrowText = card?.country;
      const badgeText =
        card?.newProperty === true
          ? model?.newHotelLabel
          : card?.isFullyRenovated === true
          ? model?.fullyRenovatedLabel
          : card?.isRecentlyRenovated === true
          ? model?.recentlyRenovatedLabel
          : card?.isAdultsOnly === true
          ? model?.adultsOnlyLabel
          : card?.hasRenovatedRooms === true
          ? model?.renovatedRoomsLabel
          : null;

      return {
        ':items': {
          [`card-carousel-${index}`]: {
            trackingProperties: {
              atCCeVar48: null,
              additionalTrackingVariables: null,
              trackingDescription: card?.header,
              description: card?.header,
              clickTrack: model?.trackingProperties?.clickTrack ? true : false,
              impressionTrack: false,
              location: cardLocDetails,
              trackingContentPosition: cardLocDetails,
              merchandisingCategory: 'nonCobrand',
              impressionCount: false,
              trackingTag: model?.trackingProperties?.trackingTag,
              trackingResortType: null,
              cardLocation: model?.trackingProperties?.cardLocation,
              leftArrowDesc: model?.trackingProperties?.leftArrowDesc,
              rightArrowDesc: model?.trackingProperties?.rightArrowDesc,
            },
            eyebrow: eyeBrowText,
            styleclass: model?.cardTheme,
            headerTag: 't-subtitle-l',
            icon: '',
            impressionTrack: IMPRESSION_TRACK,
            cardLayeredVariations: cardLayeredVariation,
            badgeIcon: '',
            imgAltText: IMAGE_ALT_TEXT,
            clickTrack: model?.trackingProperties?.clickTrack ? true : false,
            fileReferenceImage: imageUriLg,
            header: card.header,
            fontSize: 'h3',
            ':type': 'mi-aem-common-spa/components/content/cardlayered',
            [`${cardLayeredVariation === CardLayeredVariations.Square ? 'dynamicMedia' : 'dynamicMediaTall'}`]: {
              altText: IMAGE_ALT_TEXT,
              assetPath: imageUriLg,
              dynamic: true,
              renditions: [
                {
                  renditionPath: imageUriLg,
                  mediaValue: '768px',
                  width: 0,
                  dynamic: true,
                  damPath: imageUriLg,
                  mediaQuery: 'min-width',
                  height: 0,
                },
                {
                  renditionPath: imageUri,
                  mediaValue: '576px',
                  width: 0,
                  dynamic: true,
                  damPath: imageUri,
                  mediaQuery: 'min-width',
                  height: 0,
                },
                {
                  renditionPath: imageUri,
                  mediaValue: '576px',
                  width: 0,
                  dynamic: true,
                  damPath: imageUri,
                  mediaQuery: 'max-width',
                  height: 0,
                },
              ],
              damPath: card?.image,
            },
            merchandisingCategory: MERCHANDISING_CATEGORY,
            impressionCount: IMPRESSION_TRACK,
            ctaLink: card?.ctaUrl,
            badgeText: badgeText,
            openInNewTab: true,
          },
        },
      };
    });
    setResultResort(resortMap);
  };

  const allowedComponents = [
    {
      path: '/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/cardlayered',
      title: 'Card Layered',
    },
  ];
  const trackingProperties = {
    trackingContentPosition: 'trackingContentPosition',
    trackingOfferType: 'trackingOfferType',
    atCCeVar48: 'atCCeVar48',
    trackingDescription: model?.trackingProperties?.trackingDescription,
    trackingTag: 'trackingTag',
    isCoBrand: false,
    clickTrack: model?.trackingProperties?.clickTrack,
    impressionTrack: true,
    impressionCount: true,
    impressionEventType: 'impressionEventType',
    merchandisingCategory: 'merchandisingCategory',
    additionalTrackingVariables: 'additionalTrackingVariables',
    location: model?.trackingProperties?.location,
    payloadType: 'payloadType',
    compName: 'compName',
    enableScrollingBehavior: model?.trackingProperties?.enableScrollingBehavior,
    cardLocation: model?.trackingProperties?.cardLocation,
    leftArrowDesc: model?.trackingProperties?.leftArrowDesc,
    rightArrowDesc: model?.trackingProperties?.rightArrowDesc,
    description: model?.trackingProperties?.description,
  };

  return (
    <StyledContainer>
      <div>
        {!isLoading ? (
          <div className={clsx(cardCount > 0 ? '' : 'hideCarousel', 'container cardWrapper')}>
            <CardCarouselContainer
              headerText={model?.titleText}
              subHeaderText={model?.subTitleText}
              noOfCards={model?.noOfCards}
              ctaLabel={model?.ctaLabel}
              ctaLink={model?.ctaLink}
              ctaType={model?.ctaType}
              cardCount={cardCount}
              openInaNewTab={openInaNewTab}
              totalNumberOfCards={keys}
              trackingProperties={trackingProperties}
              styleclass={model?.appliedCssClassNames}
              cqItems={resortResult}
              componentId={model?.componentId}
              pagePath={''}
              itemPath={''}
              wcmMode={model?.wcmMode}
              isAuthorMode={false}
              noOfCardsTablet={NO_OF_CARDS_TABLET}
              allowedComponents={allowedComponents}
              variation={model?.variation}
            />
          </div>
        ) : (
          <div>
            <SkeletonCardCarouselLoader cardType={cardLayeredVariation} />
          </div>
        )}
      </div>
    </StyledContainer>
  );
};
export default CardResortsWrapper;
