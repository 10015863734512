import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { Badge } from '../../atoms/Badge';
import { Button } from '../../atoms/Button';
import { Eyebrow } from '../../atoms/Eyebrow';
import { Heading } from '../../atoms/Heading';
import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';
import { size, BadgeVariation, headingType } from '../../utils/enums/enums';
import { EyebrowEnum } from '../../atoms/Eyebrow/Eyebrow.types';

import { CardLayeredProps, CardLayeredVariations } from './CardLayered.types';
import { StyledCardLayered, StyledFontIcon } from './CardLayered.styles';

export const CardLayered: React.FC<CardLayeredProps> = props => {
  const {
    rel,
    badgeText,
    eyebrow,
    ctaLink,
    header,
    headerTag,
    fontSize: headerFontSize = size.large,
    cardLayeredVariations,
    badgeIcon,
    icon,
    trackingProperties,
    dynamicMedia,
    dynamicMediaTall,
    dynamicMediaWide,
    openInNewTab = false,
    styleclass,
    trunkEllipsesClass = 'm-ellipsis-2lines',
    chevronIconSizeClass = 'icon-m',
    additionalWrapperClass = '',
    logoIcon,
    logoIconAltText,
  } = props;

  const isWideVariation = cardLayeredVariations === CardLayeredVariations.Wide;
  const fontSize = isWideVariation ? size.extraLarge : headerFontSize;
  const renditions = isWideVariation
    ? dynamicMediaWide?.renditions
    : cardLayeredVariations === CardLayeredVariations.Tall
    ? dynamicMediaTall?.renditions
    : dynamicMedia?.renditions;

  const altText = isWideVariation
    ? dynamicMediaWide?.altText
    : cardLayeredVariations === CardLayeredVariations.Tall
    ? dynamicMediaTall?.altText
    : dynamicMedia?.altText;

  const assetPath = isWideVariation
    ? dynamicMediaWide?.assetPath
    : cardLayeredVariations === CardLayeredVariations.Tall
    ? dynamicMediaTall?.assetPath
    : dynamicMedia?.assetPath;

  const fontSizeTallWide = isWideVariation ? 's' : cardLayeredVariations === CardLayeredVariations.Tall ? 'xl' : '';

  const CardLayeredBtnRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;
  const [customFontSize, setCustomFontSize] = useState('');

  const carouselFont = () => {
    let customFont = '';
    const md = window.matchMedia('(max-width: 767px)');
    if (md?.matches) {
      customFont = size.large;
    }
    setCustomFontSize(customFont);
  };
  const handleResize = () => {
    carouselFont();
  };

  useEffect(() => {
    if (cardLayeredVariations === CardLayeredVariations.Square || isWideVariation) {
      carouselFont();
      window.addEventListener('resize', handleResize);
    }
  }, []);

  const customAttributes = trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': props?.componentId }
    : {};

  const isBadgeDisplay = ((cardLayeredVariations === CardLayeredVariations.Wide &&
    logoIcon?.length === 0 &&
    badgeText &&
    badgeIcon) ||
    (cardLayeredVariations !== CardLayeredVariations.Wide && badgeText) ||
    false) as boolean;

  return (
    <StyledCardLayered
      data-component-name="m-ui-library-CardLayered"
      data-testid="ui-library-CardLayered"
      className={`${additionalWrapperClass} col-12 p-0`}
      {...customAttributes}
      tabIndex={0}
      role="button"
      isBadgeDisplay={isBadgeDisplay}
    >
      <Button
        isLink
        href={ctaLink}
        target={openInNewTab ? '_blank' : '_self'}
        className={`chevronContainer ${chevronIconSizeClass} ${
          openInNewTab === true ? 'icon-arrow-right-external' : 'icon-arrow-right'
        }
         ${styleclass ? styleclass : ''}`}
        rel={rel}
        ref={CardLayeredBtnRef}
        trackingProperties={trackingProperties}
        ariaLabel={header || ''}
      >
        <div
          className={`card-layered ${styleclass ? styleclass : ''}`}
          data-variation={cardLayeredVariations}
          data-testid="card-layered"
        >
          <Image
            altText={altText}
            renditions={renditions}
            dynamic={dynamicMedia?.dynamic || dynamicMediaTall?.dynamic || dynamicMediaWide?.dynamic}
            defaultImageURL={assetPath}
          />
          <div className="card-layered__content">
            <div
              className={clsx(
                'overlay',
                !isBadgeDisplay ? 'justify-content-end' : '',
                cardLayeredVariations === CardLayeredVariations.Wide ? 'mb-0' : ''
              )}
            >
              {isBadgeDisplay && (
                <Badge
                  badgeVariation={BadgeVariation.Overlay}
                  badgeText={badgeText}
                  badgeIcon={badgeIcon?.split(':')[1] || badgeIcon}
                  customClass="cardlayered-badge"
                />
              )}
              {!isWideVariation && icon && (
                <Button
                  className={clsx('socialIcons', 'm-icon-button-primary')}
                  children={<Icon iconClass={[`icon-${icon}`, 'icon-m']} />}
                />
              )}
            </div>
            <div className="card-text-wrapper">
              <div
                className={clsx('card-texts pl-4', cardLayeredVariations !== CardLayeredVariations.Wide ? 'mt-2' : '')}
              >
                {cardLayeredVariations !== CardLayeredVariations.Square && logoIcon && (
                  <StyledFontIcon
                    fontIcon={logoIcon || ''}
                    className={`m-icon brand-logo-${logoIcon}`}
                    aria-labelledby={logoIconAltText || ''}
                    data-testid={logoIcon}
                    cardVariation={cardLayeredVariations}
                  />
                )}
                <div className="d-flex mr-2">
                  {eyebrow && <Eyebrow text={eyebrow} variation={EyebrowEnum.Overlay} />}
                  <span className={clsx(isWideVariation ? 'col-2' : 'col-3')}></span>
                </div>
                <div className="d-flex mt-2">
                  <Heading
                    element={headerTag}
                    disableCustomClickTrack
                    variation={headingType.subtitle}
                    titleText={header || ''}
                    fontSize={
                      (customFontSize ? customFontSize : fontSize?.split('-')[2] || fontSize || fontSizeTallWide) as
                        | size.extraLarge
                        | size.large
                    }
                    customClass={clsx(
                      `${trunkEllipsesClass} m-0 card-layered-header`,
                      isWideVariation ? 'card-wide-header col-10' : 'col-9 p-0'
                    )}
                  />
                  <span className={clsx(isWideVariation ? 'col-2' : 'col-3')}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Button>
    </StyledCardLayered>
  );
};
