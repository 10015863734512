/* eslint-disable @typescript-eslint/no-explicit-any */
export const getSearchResultQuery = (
  searchData: any,
  offerId: string,
  offset: any,
  brandFacets: any,
  isPreview: any,
  customerId: any,
  marshCodes?: [],
  clusterCode?: string
): any => {
  let obj;
  if (!isPreview) {
    const distanceSort =
      (searchData?.latitude && searchData?.longitude) || searchData?.stateCode || searchData?.countryCode;
    obj = {
      input: {
        queries: [
          {
            id: 'offers',
            values: String(offerId ?? '1355935'),
          },
        ],
        facets: {
          terms: [
            {
              type: 'BRANDS',
              dimensions: brandFacets,
            },
          ],
        },
      },
      sort: {
        field: distanceSort ? 'OFFER_DISTANCE' : 'PROPERTY_NAME',
      },
      offset: offset,
      limit: 40,
      larSearch: {
        startDate: searchData?.startDate,
        endDate: searchData?.endDate,
        includeUnavailableProperties: true,
        quantity: Number(searchData?.quantity) ?? 1,
        numberInParty: Number(searchData?.numberInParty) ?? 1,
        childAges: searchData?.childAges?.map((i: string) => (Number.isNaN(Number(i)) ? 0 : Number(i))) ?? [],
        customerId: customerId,
        includeMandatoryFees: true,
      },
      // customerId: customerId,
      limitPerCategory: 2,
    };

    interface LocationType {
      id: string;
      values: string;
    }

    if (searchData?.latitude && searchData?.longitude) {
      const locDetails: LocationType[] = [
        {
          id: 'latitude',
          values: String(searchData?.latitude),
        },
        {
          id: 'longitude',
          values: String(searchData?.longitude),
        },
      ];

      obj.input.queries.push(locDetails[0]);
      obj.input.queries.push(locDetails[1]);
    } else if (searchData?.stateCode) {
      const stateDetails = {
        id: 'state',
        values: String(searchData?.stateCode),
      };
      obj.input.queries.push(stateDetails);
    } else if (searchData?.countryCode) {
      const countryDetails = {
        id: 'country',
        values: String(searchData?.countryCode),
      };
      obj.input.queries.push(countryDetails);
    }
    // if (customerId) {
    //   const loginUserReqObj = {
    //     ...obj,
    //     larSearch: {
    //       ...obj.larSearch,
    //       customerId: customerId,
    //     },
    //   };
    //   return loginUserReqObj;
    // }
    if (clusterCode == '39E' || clusterCode == 'MRW' || clusterCode == 'MW1' || clusterCode == 'P17') {
      const pointsOfferReqObj = {
        ...obj,
        larSearch: {
          ...obj.larSearch,
          customerId: customerId,
          rateRequestTypes: [
            {
              type: 'CLUSTER',
              value: 'MRW',
            },
            {
              type: 'CLUSTER',
              value: 'P17',
            },
            {
              type: 'STANDARD',
              value: '',
            },
          ],
        },
      };
      return pointsOfferReqObj;
    }
  } else {
    obj = {
      search: {
        ids: marshCodes,
        options: {
          startDate: searchData?.startDate,
          endDate: searchData?.endDate,
          rateRequestTypes: [
            {
              type: 'CLUSTER',
              value: clusterCode,
            },
          ],
          includeUnavailableProperties: true,
        },
      },
      limit: 40,
      offset: 0,
      limitPerCategory: 2,
    };
    if (clusterCode == '39E' || clusterCode == 'MRW' || clusterCode == 'MW1' || clusterCode == 'P17') {
      const pointsOfferReqObj = {
        ...obj,
        search: {
          ...obj.search,
          options: {
            ...obj.search.options,
            rateRequestTypes: [
              {
                type: 'CLUSTER',
                value: 'MRW',
              },
              {
                type: 'CLUSTER',
                value: 'P17',
              },
              {
                type: 'STANDARD',
                value: '',
              },
            ],
          },
        },
      };
      return pointsOfferReqObj;
    }
  }
  return obj;
};

export const getCommaSeparator = (numb: number, lang: string) => {
  return numb ? Number(Math.floor(numb))?.toLocaleString(lang || '') : null;
};
