import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledEarnPointsSection } from './EarnPointsInfoSection.styles';
import { Button, Eyebrow, Heading, Text, Types, Image, RichText } from '@marriott/mi-ui-library';
import StepSection from './StepSection/StepSection';
import clsx from 'clsx';
import { EarnPointsPoints } from './EarnPointsInfoSection.types';
import { TRACKING_CONSTANT } from '../../modules/utils/constants';
const {
  EARN_REDEEM_EARN_POINT_SECTION,
  EARN_REDEEM_EARN_POINT_SECTION_CTA,
  EARN_REDEEM_EARN_POINT_SECTION_MEMBER_CTA,
  EXTERNAL_LINK,
} = TRACKING_CONSTANT;

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const EarnPointsInfoSection = (props: EarnPointsPoints) => {
  return (
    <StyledEarnPointsSection
      className={clsx('container pb-4 px-3 px-md-0')}
      data-testid="earnpointsprogramdetails"
      data-component-name="o-rnb-earnpointsprogramdetails"
    >
      {props?.errorMsg && (
        <div className="py-4">
          <RichText
            componentId="earns-points-error-message"
            customClass="icon-information icon-decorative d-flex m-message-inline error-sev2 t-font-m rich-text-link "
            text={props?.errorMsg || ''}
          />
        </div>
      )}
      <div className="earn-container">
        {props?.dynamicMedia && (
          <div className="earn-container__image-section">
            <Image
              title=""
              defaultImageURL={props?.dynamicMedia?.assetPath}
              customClass=""
              loading="eager"
              altText={props?.dynamicMedia?.altText}
              renditions={props?.dynamicMedia?.renditions}
              dynamic={props?.dynamicMedia?.dynamic}
            />
          </div>
        )}
        {(props?.title || props?.description) && (
          <div className={clsx('earn-container__overview-section m-auto d-flex flex-column text-center py-3 py-md-4')}>
            {props?.eyebrowText && <Eyebrow text={props?.eyebrowText} />}
            {props?.title && (
              <Heading
                variation={Types.headingType.subtitle}
                titleText={props?.title}
                fontSize={Types.size.extraLarge}
                element={Types.tags.span}
              />
            )}
            {props?.description && (
              <Text
                copyText={props?.description}
                fontSize={Types.size.medium}
                element={Types.tags.span}
                customClass="t-font-m"
              />
            )}
          </div>
        )}
        {(props?.sectionOneTitle || props?.sectionTwoTitle || props?.sectionThreeTitle) && (
          <>
            <div className={clsx('earn-container__separator mx-3 mx-md-4')}></div>
            <div className={clsx('earn-container__step-section d-flex m-3 flex-column m-md-4 flex-md-row')}>
              {props?.sectionOneTitle && props?.sectionOneIconPath && props?.sectionOneDescription && (
                <StepSection
                  iconClass={props?.sectionOneIconPath}
                  title={props?.sectionOneTitle}
                  description={props?.sectionOneDescription}
                />
              )}
              {props?.sectionTwoTitle && props?.sectionTwoIconPath && props?.sectionTwoDescription && (
                <StepSection
                  iconClass={props?.sectionTwoIconPath}
                  title={props?.sectionTwoTitle}
                  description={props?.sectionTwoDescription}
                />
              )}
              {props?.sectionThreeTitle && props?.sectionThreeIconPath && props?.sectionThreeDescription && (
                <StepSection
                  iconClass={props?.sectionThreeIconPath}
                  title={props?.sectionThreeTitle}
                  description={props?.sectionThreeDescription}
                />
              )}
            </div>
          </>
        )}
        {props?.programCtaLink && (
          <div className={clsx('m-auto text-center py-3 py-sm-3 pt-4 pb-0 pb-md-3')}>
            <Button
              className="m-button-s m-button-primary px-5 py-2"
              type={Types.ButtonTypeVariation.Button}
              buttonCopy={props?.programCtaLabel}
              isTextBeforeChildren={true}
              href={props?.programCtaLink}
              isLink={true}
              target={props?.programCtaOpenNewTab === true ? '_blank' : ''}
              custom_click_track_value={`${EARN_REDEEM_EARN_POINT_SECTION}|${EARN_REDEEM_EARN_POINT_SECTION_CTA}|${EXTERNAL_LINK}`}
              trackingProperties={props?.trackingProperties}
            ></Button>
          </div>
        )}
        {props?.membershipCtaLink && (
          <div className={clsx('m-auto text-center py-2 d-flex flex-row align-items-center justify-content-center')}>
            <Text
              copyText={props?.membershipLabel}
              fontSize={Types.size.small}
              element={Types.tags.div}
              customClass={'pr-2'}
            />
            <Button
              className="m-link-tertiary-button"
              buttonCopy={props?.membershipCtaLabel}
              isTextBeforeChildren={true}
              href={props?.membershipCtaLink}
              isLink={true}
              target={props?.membershipCtaOpenNewTab === true ? '_blank' : ''}
              custom_click_track_value={`${EARN_REDEEM_EARN_POINT_SECTION}|${EARN_REDEEM_EARN_POINT_SECTION_MEMBER_CTA}|${EXTERNAL_LINK}`}
              trackingProperties={props?.trackingProperties}
            ></Button>
          </div>
        )}
      </div>
    </StyledEarnPointsSection>
  );
};

export const EarnPointsInfoSectionConfig = {
  emptyLabel: 'earnpointsprogramdetails',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/earnpointsprogramdetails`,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const EarnPointsSectionEditable = (props: any) => (
  <EditableComponent config={EarnPointsInfoSectionConfig} {...props}>
    <EarnPointsInfoSection {...props} />
  </EditableComponent>
);
