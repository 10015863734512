import { FC, SyntheticEvent } from 'react';
import clsx from 'clsx';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../styles/theme';
import { StyledRecentListDiv } from './index.styles';

export interface RecentListProps {
  heading: string;
  listArray: Array<Record<string, string | number>>;
  listIcon: string;
  nameKey: string;
  valueKey?: string;
  // clickHandler to update search input field
  clickHandler: (value: string | number, pid?: string | number, secondaryDescription?: string | number) => void;
  // top destinations don't require updating hidden fields; thus this is optional
  hiddenFieldsHandler?: (data: Record<string, string | number>) => void;
  inlineDisplay?: boolean | undefined;
  elementClass?: string;
}

const RecentList: FC<RecentListProps> = ({
  heading,
  listArray,
  nameKey,
  valueKey,
  listIcon,
  clickHandler,
  hiddenFieldsHandler,
  inlineDisplay,
  elementClass,
}) => {
  const handler = (e: SyntheticEvent, item: Record<string, string | number>): void => {
    e.preventDefault();
    if (item[nameKey]) {
      clickHandler(item[valueKey ? valueKey : nameKey], item['placeId'], item['secondaryDescription']);
      if (hiddenFieldsHandler) {
        hiddenFieldsHandler(item);
      }
    }
  };
  const arr = listArray.slice(Math.max(listArray?.length - 5, 0));
  return (
    <ThemeProvider theme={theme}>
      <StyledRecentListDiv
        theme={{ itemLength: listArray.length }}
        data-testid="recent-list"
        className={clsx(inlineDisplay ? 'inline-list' : '', elementClass ? elementClass : '')}
      >
        <h3 className="t-label-xs">{heading}</h3>
        <ul>
          {arr?.map((item: Record<string, string | number>) => (
            <li key={`recent-list-item-${item[nameKey]}`}>
              <a
                href="#"
                onTouchStart={(event: SyntheticEvent): void => handler(event, item)}
                onClick={(event: SyntheticEvent): void => handler(event, item)}
              >
                <span className={clsx(`icon-${listIcon}`, 't-subtitle-m')}>{item[nameKey]}</span>
              </a>
            </li>
          ))}
        </ul>
      </StyledRecentListDiv>
    </ThemeProvider>
  );
};

export default RecentList;
