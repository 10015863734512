/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OfferCardHorizontalMiniProps } from './OfferCardHorizontalMini.types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CardHorizontalMini } from '@marriott/mi-common-static-components';
import { mockOfferCardHorizontalMiniData } from './__mock__/OfferCardHorizontalMini.mock';
import { StyledOfferCardHorizontalMini } from './OfferCardHorizontalMini.styles';
import { getMediaData, getUXLMediaSource, getMediaDataSecondary, decodeHtml } from '../../utils/OfferUtils';
import { PRIMARY_MEDIA, SECONDARY_MEDIA } from '../../constants/CommonConstants';
import { ImageRenditionsData } from '../../utils/ImageRenditionStructure';

export const OfferCardHorizontalMiniWrapperConfig = {
  emptyLabel: 'OfferCardHorizontalMini',
  isEmpty: false,
  resourceType: `mi-aem-common-spa/components/content/cardhorizontalmini`,
};

export const OfferCardHorizontalMiniWrapper: React.FC<OfferCardHorizontalMiniProps> = props => {
  const { model, offersData, isAuthorMode } = props;
  const { offersHorizontalMini } = ImageRenditionsData;
  const offersDataNode = !isAuthorMode
    ? offersData?.responseObject?.edges[0]
    : mockOfferCardHorizontalMiniData.offersData?.responseObject?.edges[0];

  const offerType = offersData?.responseObject?.edges[0]?.node?.offerType;
  const uxlMediaSource = getUXLMediaSource(offersData?.responseObject?.edges[0]?.node?.media, SECONDARY_MEDIA);
  const mediaSource = isAuthorMode
    ? 'model'
    : offerType == 'Non-CoBrand'
    ? uxlMediaSource
    : offerType == 'CoBrand' && uxlMediaSource == PRIMARY_MEDIA
    ? 'model'
    : uxlMediaSource;

  const isMediaFromDAC = mediaSource == PRIMARY_MEDIA || mediaSource == SECONDARY_MEDIA;
  const media = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : mediaSource == SECONDARY_MEDIA
    ? getMediaDataSecondary(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersHorizontalMini?.dynamicMedia,
        mediaSource
      )
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersHorizontalMini?.dynamicMedia,
        mediaSource,
        PRIMARY_MEDIA
      );

  return (
    <StyledOfferCardHorizontalMini data-testid="cardhorizontalmini" data-component-name="o-offers-cardhorizontalmini">
      <CardHorizontalMini
        img={model?.icon}
        openInaNewTab={model?.openInaNewTab}
        ctaLink={offersDataNode?.node?.callToActionLink || model?.ctaLink}
        header={decodeHtml(offersDataNode?.node?.title) || model?.header}
        headerShort={model?.headerShort}
        descriptionText={decodeHtml(offersDataNode?.node?.description) || model?.descriptionText}
        headerTag={model?.headerTag}
        descriptionShort={model?.descriptionShort}
        trackingProperties={model?.trackingProperties}
        dynamicMedia={isMediaFromDAC ? media : model?.dynamicMedia}
        styleclass={model?.styleclass}
        assetVariation={model?.assetVariation}
        iconPath={model?.iconPath}
      />
    </StyledOfferCardHorizontalMini>
  );
};

export const OfferCardHorizontalMini = (props: any) => (
  <EditableComponent config={OfferCardHorizontalMiniWrapper} {...props}>
    <div data-testid="offers-cardhorizontal-mini">
      <OfferCardHorizontalMiniWrapper {...props} />
    </div>
  </EditableComponent>
);
