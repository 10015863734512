/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { FilterProps, SearchFilterPanelProps } from './SearchFilterPanel.types';
import { StyledSearchFilterPanel } from './SearchFilterPanel.styles';
import { BrandFilter } from '../BrandFilter';
import { CheckboxControlsList, Modal, RadiobuttonControlsList, ToggleControlsList } from '@marriott/mi-ui-library';
import { getCategorizedBrands } from '../../organisms/MapAndRegionCard/HotelDirectoryMap/HotelDirectoryMapHelper';
import { SearchFacetsList, SEARCH_FILTER_MODAL } from '../../constants/Filters.constant';

// Use named rather than default exports.
export const SearchFilterPanel: FC<SearchFilterPanelProps> = ({
  popupOpenState,
  setPopupOpenState,
  filtersData,
  primaryButtonLabel,
  secondaryButtonLabel,
  filterOrder, // [{"filterName":"AMENITIES"},{"filterName":"BRANDS"}]
  filtersPayload, // [{type: 'BRANDS', dimensions: ['CY', 'SH']}]
  setFiltersPayload,
  dynamicFiltersEnabled, // true
  setFiltersCount, // 2
  setApplyBtnClicked,
  brandCategories,
  filtersHeaderLabel,
  clickTrackingLoc,
  onApplyButtonClicked,
  filterButtonClicked,
  setFilterButtonClicked,
  initialResponse,
  showMoreLabel,
  showLessLabel,
}) => {
  //state to update the filter payload temporarily before clicking on apply button
  const [tempFilterPayload, setTempFilterPayload] = useState<any>(filtersPayload); // [{type: 'BRANDS', dimensions: ['CY', 'SH']}]
  // state to show up all pre-selected filters and reset the filter on click of clear button
  const [defaultSelectedFilters, setDefaultSelectedFilters] = useState<any>(filtersPayload);
  let selectedFiltersCount = 0;
  const prevfiltersRef = useRef<any>([]);
  const filtersRef = useRef<any>();
  filtersRef.current = filtersPayload;
  const prevfiltersRefVal = filtersRef.current;

  useEffect(() => {
    prevfiltersRef.current = filtersPayload;
  }, []);

  useEffect(() => {
    if (!filterButtonClicked?.allFilters && !filterButtonClicked?.amenities && !filterButtonClicked?.brands)
      setPopupOpenState(false);
  }, [filterButtonClicked]);

  useEffect(() => {
    const modal = document?.getElementById('filter-modal');
    const allFiltersPill = document.getElementById('allFilters');
    const amenitiesPill = document.getElementById('amenities');
    const brandsPill = document.getElementById('brands');

    allFiltersPill && allFiltersPill.addEventListener('keydown', handleTab);
    amenitiesPill && amenitiesPill.addEventListener('keydown', handleTab);
    brandsPill && brandsPill.addEventListener('keydown', handleTab);

    if (modal) {
      modal.addEventListener('keydown', handleShiftTab);
    }
    return () => {
      modal && modal.removeEventListener('keydown', handleShiftTab);
      allFiltersPill && allFiltersPill.removeEventListener('keydown', handleTab);
      amenitiesPill && amenitiesPill.removeEventListener('keydown', handleTab);
      brandsPill && brandsPill.removeEventListener('keydown', handleTab);
    };
  }, []);

  // handle shift tab functionality if focus goes out of modal
  const handleShiftTab = (event: any) => {
    const el = document.activeElement;
    if (event.shiftKey && event.key === 'Tab') {
      if (filterButtonClicked?.allFilters && el === document?.getElementById('filter-modal')) {
        event.preventDefault();
        const input = document.getElementById('allFilters');
        if (input) {
          input.focus();
        }
      } else if (filterButtonClicked?.amenities && el === document?.getElementById('filter-modal')) {
        event.preventDefault();
        const input = document.getElementById('amenities');
        if (input) {
          input.focus();
        }
      } else if (filterButtonClicked?.brands && el === document?.getElementById('filter-modal')) {
        event.preventDefault();
        const input = document.getElementById('brands');
        if (input) {
          input.focus();
        }
      }
    }
  };

  // handle to focus modal
  const focusModal = (event: any) => {
    event.preventDefault();
    const input = document?.getElementById('filter-modal');
    input && input.focus();
  };

  // handle tab functionality if focus is on pill and popup is open
  const handleTab = (event: any) => {
    const el = document.activeElement;
    if (event.key === 'Tab' && !(event.shiftKey && event.key === 'Tab')) {
      if (
        (filterButtonClicked?.allFilters && el === document.getElementById('allFilters')) ||
        (filterButtonClicked?.amenities && el === document.getElementById('amenities')) ||
        (filterButtonClicked?.brands && el === document.getElementById('brands'))
      ) {
        focusModal(event);
      }
    }
  };

  // set focus on filters pill on close of modal
  const getFocusOnFiltersPill = () => {
    if (filterButtonClicked?.allFilters) {
      const input = document.getElementById('allFilters');
      if (input) {
        input.focus();
      }
    } else if (filterButtonClicked?.amenities) {
      const input = document.getElementById('amenities');
      if (input) {
        input.focus();
      }
    } else if (filterButtonClicked?.brands) {
      const input = document.getElementById('brands');
      if (input) {
        input.focus();
      }
    }
  };

  // get count of selected filters on modal
  const getAppliedFiltersCount = () => {
    tempFilterPayload?.forEach((filter: { type: string; dimensions: Array<string> }) => {
      selectedFiltersCount = selectedFiltersCount + filter?.dimensions?.length;
    });
    setFiltersCount(selectedFiltersCount);
  };

  const [selectedFilterObj, _setSelectedFilterObj] = useState('');

  // update payload on selection of any filters
  // filterObjList --> [{code: 'all-suites', label: 'All Suites'}]
  // filterObjList --> [{brandTagId: 'CY', brandTagTitle: 'Courtyard'}]
  const handleFiltersSelectionChange = (code: string, filterObjList: any) => {
    let temp: Array<string> = [];

    if (code === SearchFacetsList?.brands) {
      filterObjList?.forEach((obj: any) => temp?.push(obj?.brandTagId));
      temp = [...new Set(temp)];
    } else {
      filterObjList?.forEach((obj: any) => temp?.push(obj?.code));
    }

    const payload = filtersPayload?.map((obj: any) => (obj?.type === code ? { ...obj, dimensions: temp } : obj));

    setTempFilterPayload(payload);
    setDefaultSelectedFilters(payload);

    if (dynamicFiltersEnabled) {
      setFiltersPayload(payload);
    }
  };

  // show filters list on modal how it returns in UXL response with specific type
  const showFiltersList = (filterData?: any) => {
    if (filterData?.type === 'brand-filter') {
      return (
        <BrandFilter
          title={filterButtonClicked?.brands ? '' : filterData?.filterName}
          showSeparator={filterButtonClicked?.brands ? false : true}
          brandCategories={getCategorizedBrands(filterData?.data, brandCategories)}
          defaultSelected={showDefaultSelectedFilters(filterData)}
          onChange={(obj): void => handleFiltersSelectionChange(filterData?.code, obj)}
          isHotelBrandSelectedFlag={true}
          clickTrackingLoc={clickTrackingLoc}
          clickTrackingVal={`${filterData?.filterName} Section:`}
        />
      );
    } else if (filterData?.type === 'checkbox-controlled-list' && filterData?.data?.length > 0) {
      return (
        <CheckboxControlsList
          name={filterData?.filterName}
          title={
            filterButtonClicked?.amenities &&
            filterData?.code?.toUpperCase() === SearchFacetsList?.amenities?.toUpperCase()
              ? ''
              : filterData?.filterName
          }
          controls={filterData?.data}
          enableShowMoreToggle={filterButtonClicked?.amenities ? false : true}
          onChange={(obj): void => handleFiltersSelectionChange(filterData?.code, obj)}
          showMoreCount={8}
          showSeparator={true}
          clickTrackingLoc={clickTrackingLoc}
          clickTrackingVal={`${filterData?.filterName} Section:`}
          defaultSelected={showDefaultSelectedFilters(filterData)}
          showMoreLabel={showMoreLabel}
          showLessLabel={showLessLabel}
        />
      );
    } else if (filterData?.type === 'radiobutton-controlled-list' && filterData?.data?.length > 0) {
      return (
        <RadiobuttonControlsList
          name={filterData?.filterName}
          title={filterData?.filterName}
          controls={filterData?.data}
          enableShowMoreToggle={true}
          onChange={(obj): void => handleFiltersSelectionChange(filterData?.code, obj)}
          showMoreCount={8}
          showSeparator={true}
          custom_click_track_value={`${clickTrackingLoc} | ${filterData?.filterName} Section: ${selectedFilterObj} | internal`}
          defaultSelected={showDefaultSelectedFilters(filterData)}
        />
      );
    } else if (filterData?.type === 'toggle-controlled-list' && filterData?.data?.length > 0) {
      return (
        <ToggleControlsList
          name={filterData?.filterName}
          title={filterData?.filterName}
          controls={filterData?.data}
          enableShowMoreToggle={true}
          onChange={(obj): void => handleFiltersSelectionChange(filterData?.code, obj)}
          showMoreCount={8}
          custom_click_track_value={`${clickTrackingLoc} | ${filterData?.filterName} Section: ${selectedFilterObj} | internal`}
          showSeparator={true}
          defaultSelected={showDefaultSelectedFilters(filterData)}
        />
      );
    } else if (
      (filterButtonClicked?.brands === true || filterButtonClicked?.allFilters === true) &&
      filtersData?.length === 0
    ) {
      return (
        <BrandFilter
          title={filterButtonClicked?.brands ? ' ' : filterData?.filterName}
          showSeparator={false}
          brandCategories={getCategorizedBrands(filterData?.data, brandCategories)}
          defaultSelected={showDefaultSelectedFilters(filterData)}
          onChange={(obj): void => handleFiltersSelectionChange(filterData?.code, obj)}
          isHotelBrandSelectedFlag={true}
          clickTrackingLoc={clickTrackingLoc}
          clickTrackingVal={`${filterData?.filterName} Section:`}
        />
      );
    } else {
      return <div></div>;
    }
  };

  // show filters list on modal in specified order
  const showFiltersInSpecifiedOrder = (
    filterOrder: Array<{ filterName: string }>,
    filtersResponse: Array<FilterProps>
  ) => {
    if (filtersResponse?.length > 0) {
      // looping through the filterOrder props received from AEM or passed manually
      return filterOrder?.map((code: { filterName: string }) => {
        // looping through the filter response and compare the filter code
        return filtersResponse?.map((_response: FilterProps) => {
          if (code?.filterName === _response?.code) {
            return showFiltersList(_response);
          } else {
            return;
          }
        });
      });
    } else return showFiltersList();
  };

  // apply filters on click of apply button on modal
  const applyBtnHandler = () => {
    setFiltersPayload(tempFilterPayload);
    getAppliedFiltersCount();
    prevfiltersRef?.current !== filtersPayload && setApplyBtnClicked(true);
    onApplyButtonClicked?.();
    filterButtonClicked && getFocusOnFiltersPill();
    setFilterButtonClicked?.({ allFilters: false, amenities: false, brands: false });
  };

  // show default selected filters on modal
  const showDefaultSelectedFilters = (filterData: FilterProps): any => {
    const temp: any = [];

    //  looping through the initial filter response and compare with filter payload to get the default selected filters
    const filterObj = initialResponse?.find((obj: any) => obj?.code === filterData?.code);
    filterObj?.data?.forEach((data: any) => {
      defaultSelectedFilters?.forEach((fp: any) => {
        if (fp?.type === SearchFacetsList?.brands) {
          fp?.dimensions?.forEach((brandCode: string) => {
            if (brandCode === data?.code && !temp?.find((a: any) => a?.brandTagId === data?.code))
              temp.push({ brandTagId: data?.code, brandTagTitle: data?.label });
          });
        } else {
          fp?.dimensions?.forEach((filterCode: string) => {
            if (filterCode === data?.code && !temp?.find((a: any) => a?.label === data?.label)) {
              temp.push({ code: data?.code, label: data?.label });
            }
          });
        }
      });
    });

    return temp;
  };

  // reset the filter payload on modal
  const clearBtnHandler = () => {
    // reset the filter payload
    const resetFilterPayload = tempFilterPayload?.map((fp: any) => {
      if (filterButtonClicked?.allFilters) {
        return { type: fp?.type, dimensions: [] };
      } else if (filterButtonClicked?.amenities) {
        if (fp?.type === SearchFacetsList?.amenities) {
          return { type: fp?.type, dimensions: [] };
        } else return fp;
      } else {
        if (fp?.type === SearchFacetsList?.brands) {
          return { type: fp?.type, dimensions: [] };
        } else return fp;
      }
    });

    // reset the default selected filters
    const resetDefaultSelectedFilters = defaultSelectedFilters?.map((sf: any) => {
      if (filterButtonClicked?.allFilters) {
        return { type: sf?.type, dimensions: [] };
      } else if (filterButtonClicked?.amenities) {
        if (sf?.type === SearchFacetsList?.amenities) {
          return { type: sf?.type, dimensions: [] };
        } else return sf;
      } else {
        if (sf?.type === SearchFacetsList?.brands) {
          return { type: sf?.type, dimensions: [] };
        } else return sf;
      }
    });

    setDefaultSelectedFilters(resetDefaultSelectedFilters);
    setTempFilterPayload(resetFilterPayload);

    // in case of dynamic filter is enabled, reset the filter selection on click of clear button
    if (dynamicFiltersEnabled) {
      setFiltersPayload(resetFilterPayload);
    }
  };

  // reset the filters payload to it's previous state on close of modal
  const resetFiltersPayloadOnClose = () => {
    if (dynamicFiltersEnabled && prevfiltersRef?.current !== filtersPayload) {
      setFiltersPayload(prevfiltersRef?.current);
    }
  };

  // reset the filters payload to it's previous state on click outside of modal
  const resetFiltersPayloadOnClickOutside = () => {
    if (dynamicFiltersEnabled && filtersRef?.current !== prevfiltersRefVal) {
      setFiltersPayload(prevfiltersRefVal);
    }
  };

  return (
    <StyledSearchFilterPanel data-component-name="m-shop-SearchFilterPanel" data-testid="shop-SearchFilterPanel">
      <Modal
        className={clsx('custom-scrollbar', 'modal-main-container', popupOpenState ? '' : 'close-popup-header')}
        show={true}
        handleBlur={true}
        popupOpenState={popupOpenState}
        setPopupOpenState={setPopupOpenState}
        allFilterPopUp={true}
        role="heading"
        aria-level="1"
        onClose={(): void => {
          setPopupOpenState(false);
          resetFiltersPayloadOnClickOutside();

          setFilterButtonClicked?.({ allFilters: false, amenities: false, brands: false });
        }}
        modalId="filter-modal"
      >
        <Modal.Header
          className={clsx('t-subtitle-xl', 'modal-header')}
          labelText={filtersHeaderLabel ?? 'Filters'}
          popupHeaderOnCLoseFunc={(): void => {
            resetFiltersPayloadOnClose();
            filterButtonClicked && getFocusOnFiltersPill();
            setFilterButtonClicked?.({ allFilters: false, amenities: false, brands: false });
          }}
        />
        <Modal.Body
          className={clsx(
            'all-filters',
            'custom-scrollbar',
            filterButtonClicked?.amenities || filterButtonClicked?.brands ? 'mt-3' : ''
          )}
        >
          {filterOrder
            ? showFiltersInSpecifiedOrder(filterOrder, filtersData)
            : filtersData?.map((filterData: any) => showFiltersList(filterData))}
        </Modal.Body>
        <Modal.Footer
          clearClickHandler={clearBtnHandler}
          setPopUpOpenState={setPopupOpenState}
          applyBtnClickHandler={applyBtnHandler}
          clearButtonLabel={secondaryButtonLabel}
          applyLabel={primaryButtonLabel}
          className={clsx('modal-footer')}
          clickTrackingLoc={SEARCH_FILTER_MODAL}
        />
      </Modal>
    </StyledSearchFilterPanel>
  );
};
