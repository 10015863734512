import React, { FC, useRef, useState, useEffect } from 'react';
import clsx from 'clsx';

import { Button } from '../../atoms/Button';
import { Heading } from '../../atoms/Heading';
import { Icon } from '../../atoms/Icon';
import { headingType, size } from '../../utils/enums/enums';
import { GeneralMessageProps } from './GeneralMessage.types';
import { StyledGeneralMessage } from './GeneralMessage.styles';

export const GeneralMessage: FC<GeneralMessageProps> = props => {
  const {
    openInNewTab = false,
    header,
    headerTag,
    descriptionText,
    linkText,
    ctaType,
    linkUrl,
    trackingProperties,
    styleclass,
    componentId,
    ARIA_COLLAPSE,
    ARIA_EXPAND,
  } = props;

  const GeneralMessageRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;
  const buttonCallback = () => {
    window.open(linkUrl, openInNewTab ? '_blank' : '_self');
  };

  const customAttributes = trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': props?.componentId }
    : {};

  const tertiaryBtnClass =
    ctaType === 'tertiarylink'
      ? openInNewTab
        ? 'm-link-tertiary-button-inverse m-link-tertiary-button-external gm_link'
        : 'm-link-tertiary-button-inverse gm_link'
      : '';
  const secondaryBtnClass =
    ctaType === 'secondarybutton'
      ? openInNewTab
        ? 'm-button-s m-button-secondary-inverse m-button-external'
        : 'm-button-s m-button-secondary-inverse'
      : '';
  const primaryBtnClass =
    ctaType === 'primarybutton'
      ? openInNewTab
        ? 'm-button-s m-button-primary-inverse m-button-external'
        : 'm-button-s m-button-primary-inverse'
      : '';

  const [isCollapsed, setIsCollapsed] = useState('d-block');
  const [iconClass, setIconClass] = useState('icon-arrow-up');
  const [display, setDisplay] = useState(true);
  const [showEllipsis, setShowEllipsis] = useState('');
  const [is1Line, setIs1Line] = useState(false);
  const [ariaLabel, setAriaLabel] = useState(ARIA_COLLAPSE);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isTextWrappedToTwoLines(element: any, spaceHeight: number) {
    if (element) {
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      const containerHeight = element.clientHeight - spaceHeight;
      return containerHeight / lineHeight;
    }
    return 1;
  }

  useEffect(() => {
    const headerContainer = document.querySelector(`.gmhead_${componentId}`);
    const descContainer = document.querySelector(`.gmdesc_${componentId}`);
    const headerLines = isTextWrappedToTwoLines(headerContainer, 0);
    const descLines = isTextWrappedToTwoLines(descContainer, 16);
    const noOfLines = headerLines === 1 && descLines === 1;
    setIs1Line(noOfLines);
  }, []);

  const iconClickHandler = () => {
    if (display) {
      setIsCollapsed('d-none');
      setIconClass('icon-arrow-down');
      setDisplay(false);
      setShowEllipsis('m-ellipsis-1line');
      setAriaLabel(ARIA_EXPAND);
      return;
    } else {
      setIsCollapsed('d-block');
      setIconClass('icon-arrow-up');
      setDisplay(true);
      setShowEllipsis('');
      setAriaLabel(ARIA_COLLAPSE);
      return;
    }
  };

  return (
    <StyledGeneralMessage
      data-component-name="m-ui-library-GeneralMessage"
      data-testid="ui-library-GeneralMessage"
      className={clsx('p-0 m-container-fullbleed', `${styleclass ? styleclass : ''}`)}
      styleclass={styleclass}
    >
      <div className={'gm container-sm'} data-testid="general-message" {...customAttributes}>
        {header && (
          <Heading
            element={headerTag}
            variation={headingType.subtitle}
            fontSize={size.large}
            titleText={header || ''}
            disableCustomClickTrack
            customClass={clsx('gm_text', 'gm_heading', showEllipsis, `gmhead_${componentId}`)}
          />
        )}
        {descriptionText && (
          <div
            dangerouslySetInnerHTML={{ __html: descriptionText }}
            className={clsx('gm_text', 'gm_description', 'mx-auto', 't-font-s', showEllipsis, `gmdesc_${componentId}`)}
          ></div>
        )}
        {linkText && linkUrl && (
          <Button
            href={linkUrl}
            className={clsx('gm_button', primaryBtnClass, secondaryBtnClass, tertiaryBtnClass, isCollapsed)}
            target={`${openInNewTab ? '_blank' : '_self'}`}
            linkType={`${openInNewTab ? 'external' : ''}`}
            rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
            ref={GeneralMessageRef}
            buttonCopy={linkText}
            trackingProperties={trackingProperties}
            isTextBeforeChildren={false}
            callback={buttonCallback}
          />
        )}
        {(linkText || (!linkText && !is1Line)) && (
          <div className={clsx('gm_collapse')} onClick={iconClickHandler} tabIndex={0}>
            <Icon iconClass={clsx('icon', `${iconClass}`)}>
              <span className="visually-hidden" aria-live="polite">
                {ariaLabel}
              </span>
            </Icon>
          </div>
        )}
      </div>
    </StyledGeneralMessage>
  );
};
