import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledOutletCardContainer = styled.div`
  background: ${baseVariables.color.base20};
  box-shadow: 0 16px 50px rgba(0, 0, 0, 0.105), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: ${toRem(14)};

  @media ${baseVariables.mediaQuery.desktop && baseVariables.mediaQuery.largeDesktop} {
    display: flex;
  }
  .outlet-card-item-one {
    flex-basis: 45%;
  }

  .outlet-card-item-two {
    flex-basis: 55%;
  }
  .card-image-class {
    height: 100%;
    width: 100%;
    border-top-left-radius: ${toRem(8)};
    border-bottom-left-radius: ${toRem(8)};
  }
  .outlet-card-details {
    justify-content: left;
    display: flex;
    flex-direction: column;

    .outlet-card-name {
      display: flex;
      flex-direction: row;
      column-gap: ${toRem(11)};
    }

    @media ${baseVariables.mediaQuery.desktop},
      @media ${baseVariables.mediaQuery.tablet},
      @media ${baseVariables.mediaQuery.allTablets},
      @media ${baseVariables.mediaQuery.largeDesktop} {
      height: 100%;
      width: 100%;
      justify-content: space-between;
    }
  }
  .search-outlet-icon {
    width: 18px;
    height: 20px;
  }
  .reserve-button-link {
    border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
    text-align: right;
  }
  .reserve-cta-button {
    margin: ${toRem(16)};
  }
  .outlet-card-description {
    margin-bottom: ${toRem(20)};
    display: none;
    @media ${baseVariables.mediaQuery.desktop},
      @media ${baseVariables.mediaQuery.allTablets},
      @media ${baseVariables.mediaQuery.largeDesktop} {
      display: block;
    }
  }
  .card-carousel-container {
    position: relative;
    width: 402px;
    box-sizing: border-box;
  }
  .glide {
    max-height: 352px;
    @media ${baseVariables.mediaQuery.mobile}, @media ${baseVariables.mediaQuery.desktop} {
      max-width: 100%;
    }
    @media ${baseVariables.mediaQuery.largeDesktop} {
      width: 500px;
      max-width: 500px;
    }
  }
  .glide-bullet-container {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.54);
    padding: 8px;
    border-radius: 20px;
    @media ${baseVariables.mediaQuery.mobile && baseVariables.mediaQuery.allTablets} {
      margin-bottom: ${toRem(8)};
    }
  }
  .carousal-arrow {
    background-color: rgba(0, 0, 0, 0.54);
    width: ${toRem(40)};
    height: ${toRem(40)};

    position: absolute;
    border-radius: 50%;
    border-color: transparent;
    background-color: rgba(255, 255, 255, 0.376);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 6px 26px 0px;
    font-size: 1.5rem;
    padding: 0px;
    z-index: 1;
  }

  .glide__arrows {
    margin-bottom: ${toRem(52)};
  }

  .carousal-bullet {
    width: ${toRem(4)};
    height: ${toRem(4)};
  }
  .carousal-background {
    background-color: transparent;
  }
  .arrow-container {
    height: 100%;
    width: 100%;
  }
  img {
    max-height: 352px;
    width: 100%;
    border-top-right-radius: ${toRem(8)};
    border-top-left-radius: ${toRem(8)};
    border-bottom-left-radius: 0;

    @media ${baseVariables.mediaQuery.largeDesktop}, @media ${baseVariables.mediaQuery.desktop} {
      border-top-left-radius: ${toRem(8)};
      border-bottom-left-radius: ${toRem(8)};
      border-top-right-radius: 0;
    }
  }
  .glide__slides {
    padding: 0;
    .glide__slide {
      width: 100%;
      list-style: none;
    }
  }
  .glide__track {
    position: relative;
    overflow: hidden;
  }
  .controls_wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .carouselControlType3a {
      width: 100%;
      height: 100%;
      justify-content: space-between;
    }
    @media ${baseVariables.mediaQuery.mobile && baseVariables.mediaQuery.allTablets} {
      bottom: ${toRem(94)};
    }
    .glide__bullet {
      pointer-events: none;
    }
  }

  @media ${baseVariables.mediaQuery.mobile} && ${baseVariables.mediaQuery.allTablets} {
    flex-direction: column;

    .reserve-button-link {
      border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
      margin: 0;
    }
    img {
      width: 100%;
    }
    .glide {
      width: 100%;
    }
    .carousal-arrow {
      margin-top: ${toRem(48)};
    }
    .outlet-card-details {
      .cuisine-type {
        margin-top: ${toRem(8)};
        margin-bottom: ${toRem(4)};
        line-height: 14px;
      }
    }
    .outlet-resevation-link {
      margin-top: ${toRem(16)};
      margin-bottom: ${toRem(16)};
    }
  }
`;

export const StyledPropertyCardContainer = styled.div`
  .overridden-property-card-style {
    .property-card__title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;

      @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg} {
        -webkit-line-clamp: 2;
      }

      @media ${baseVariables.mediaQuery.xl} {
        -webkit-line-clamp: 1;
      }
    }
  }

  .trimmed-property-description {
    .property-card__subTitle {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .property-card__details-container_desc {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
  }
  .glide__arrow {
    top: 50%;
  }
`;
