import React, { useContext } from 'react';
import { SearchResultContext } from '../../index.context';
import { StyledSearchSummaryContainerDiv } from './index.style';
import { useWindowSize } from '../../../../../../hooks';
import { BREAKPOINT_TABLET } from '../../../lib/application.constants';
import { renderedData } from '../../organisms/searchResults';
import { usePageContext } from '../../../../../../context/PageContext/index';
import { hardCodedAllLocale } from '../../index.constant';
import clsx from 'clsx';
import { formatNumberByLocale } from '../../../../../../utils/src/utils';

export const SearchSummaryComponent: React.FC = () => {
  const { searchResult } = useContext(SearchResultContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { jsonContent, isMapViewEnabled }: any = useContext(renderedData);
  const { currentLocale } = usePageContext();
  let initialNumber = searchResult?.pageInfo?.currentOffset ?? 0;
  const { width } = useWindowSize();

  if (searchResult?.total) {
    initialNumber = initialNumber + 1;
  }
  // add few extra classes for de domain on basis of this flag, in order to achive some design changes
  const addDeClassOnSortBy = isMapViewEnabled && currentLocale === hardCodedAllLocale.de;

  const hotelCount = searchResult?.total?.toString();
  return (
    <div className="d-flex">
      {searchResult && (
        <StyledSearchSummaryContainerDiv
          className={clsx(
            width && width > BREAKPOINT_TABLET ? 't-subtitle-l' : 't-label-alt-s',
            ' results-summary',
            addDeClassOnSortBy ? 'result-summary-de' : ''
          )}
        >
          <div className="search-summary-container">
            {searchResult?.pageInfo ? (
              <span>
                {/* {initialNumber}-
                {(searchResult?.pageInfo?.hasNextPage === true
                  ? searchResult?.pageInfo?.nextOffset
                  : searchResult?.total) ?? 0}{' '}
                {jsonContent.ofLabel}{' '} */}
                {`${initialNumber} - ${
                  (searchResult?.pageInfo?.hasNextPage === true
                    ? searchResult?.pageInfo?.nextOffset
                    : searchResult?.total) ?? 0
                } ${jsonContent.ofLabel} `}
              </span>
            ) : (
              <span>
                {initialNumber}-{searchResult?.total ?? 0} {jsonContent?.ofLabel}{' '}
              </span>
            )}
            <span className="hotel-content">{`${formatNumberByLocale(hotelCount ?? 0, currentLocale)} ${
              jsonContent?.hotelsLabel
            } `}</span>
          </div>
        </StyledSearchSummaryContainerDiv>
      )}
    </div>
  );
};
