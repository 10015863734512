import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';

export const StyledDropdownOptionList = styled.div`
  .highlighted-item: focus {
    background: ${baseVariables.color.neutral20};
    border-radius: ${toRem(8)};
    outline: none;
    z-index: 100;
  }
  .recent-list-heading {
    margin-bottom: 0;
    padding: 0 0 ${toRem(8)} ${toRem(8)};
  }
  ul {
    border-top: ${toRem(1)} solid #eeeeee;
    list-style: none;
    padding-left: 0;
  }
`;
