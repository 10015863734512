import React from 'react';
import { ComponentProps } from './index.types';
import SearchResultComp from './organisms/searchResults';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    // extends React's HTMLAttributes
    custom_click_track_value?: string;
  }
}

const SearchResults: React.FC<ComponentProps> = ({
  jsonContent,
  GOOGLE_MAP_API_KEY,
  requestID = '',
  searchQueryData,
  completeSession,
}) => {
  return (
    <SearchResultComp
      jsonContent={jsonContent}
      GOOGLE_MAP_API_KEY={GOOGLE_MAP_API_KEY}
      requestID={requestID}
      searchQueryData={searchQueryData}
      completeSession={completeSession}
    ></SearchResultComp>
  );
};

export default SearchResults;
