/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
// import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledSearchFieldCategoriesDiv = styled.div<{
  $isTabbedSearchForm?: boolean;
  $cityPickListToggle?: boolean;
}>`
  ${rtl`
  //mobie first approach
  .hide-dropdown{
    display:none;
  }
  .m-input-text-field input[type=text]:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]) {
    padding-right: ${toRem(32)};
  }
  .recent-list-heading {
    padding-bottom: ${toRem(4)};
    line-height: ${toRem(18)};
    font-weight: ${baseVariables.font.fontWeightRegular};
  }
  .text-secondary {
    font-size: ${toRem(12)};
  }
  
  .dropdown-container{
    margin:2 auto;
    padding-top: ${toRem(4)};
    .dropdown-to-modal {
      padding: 0;
      .m-dropdown-content {
        padding: 0 ${toRem(24)} ${toRem(24)} ${toRem(24)} !important;
        margin-top: ${toRem(24)};
      }
    }
  }
  .m-dropdown-children{
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  .destination-medium-font{
    input{
      font-weight:700 !important;
      line-height: ${toRem(21)} !important;
    }
  }

  .destination-label-medium-font{
    label{
    font-weight:700 !important;
    }
  }
  .icon-search {
    font-size: ${toRem(16)};
    top: 0;
    position: unset;
    padding-right: 0;
    ::before {
      height: ${toRem(16)};
      width: ${toRem(16)};
    }
  }
  .icon-cancel:before {
    height:${(props: any) => (props.$isTabbedSearchForm !== true ? `${toRem(20)}` : `${toRem(16)}`)};
    width:${(props: any) => (props.$isTabbedSearchForm !== true ? `${toRem(20)}` : `${toRem(16)}`)};
  }
  .clear-input-icon {
    font-size:${(props: any) => (props.$isTabbedSearchForm !== true ? `${toRem(20)}` : `${toRem(16)}`)};
    vertical-align: bottom;
  }
  .m-dropdown-header{
    background: ${baseVariables.color['base20']};
    z-index: 1;
    position: sticky;
    top: 0;
    &:after{
      width:100%;
    }
  }

  .m-dropdown-content{
    box-shadow: ${baseVariables.shadows.boxShadowTile} ;
    border-radius: ${toRem(8)} ${toRem(8)} 0 0 ;
    max-height:90vh !important;
  }
  .dropdown-to-modal{
    height:${(props: any) =>
      props.$isTabbedSearchForm !== true ? (props.$cityPickListToggle ? `80vh` : `${toRem(600)}`) : '80vh'};
    position:fixed;
    display:grid;
    .m-dropdown-content{
      height:${(props: any) => props.$cityPickListToggle && 'auto !important'};
      min-height:${(props: any) => props.$cityPickListToggle && 'unset !important'};
    }
  }
  .header-heading{
    display:contents;
  }
  .m-input-text-field-no-border input[type='text']::-webkit-input-placeholder {
    color: ${baseVariables.color.neutral40};
  }
  .mobile-input-field{
    height:${toRem(54)}
  }
  @media ${baseVariables.mediaQuery.lg} {
    .highlighted-item {
      margin-top: 0;
    }
    .recent-list-heading {
      padding-bottom: ${toRem(8)};
      font-weight: ${baseVariables.font.fontWeightRegular};
    }
    .m-input-text-field-no-border label {
      margin-bottom: ${toRem(4)};
    }
    .m-input-text-field-no-border input[type='text']::-webkit-input-placeholder {
      color: ${baseVariables.color.neutral30};
    }
    .destination-searchbar-divider{
      border-bottom: ${toRem(2)} solid ${baseVariables.color.neutral00};
    }
    .dropdown-container{
      position:absolute;
      z-index:1;
      width:${toRem(348)};
      & >div:first-child {
        z-index:1001;
        bottom: unset;
        width: unset;
        height: fit-content;
        position:relative;

        & >div:first-child {
          height:fit-content;
        }
      }
    }
    .city-picklist-dropdown-container{
      display: flex;
      width: ${toRem(1092)};
      flex-direction: column;
      align-items: flex-start;
      left:0;
      margin-top:${toRem(10)};
    }
    .m-dropdown-content{
      box-shadow: ${baseVariables.shadows.boxShadowTile} ;
      border-radius: ${toRem(8)} ;
    }
    .m-dropdown-children{
      padding:${toRem(16)};
    }
  }
  `}
`;
