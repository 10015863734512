/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { trackImpression } from '../../../modules/utils/helpers';
import { StyledOutletAdditionalDetails } from './OutletAdditionalDetails.styles';
import { Icon, Button } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { TRACKING_CONSTANT, GOOGLE_LOCATION, TARGET_BLANK } from '../../../modules/utils/constants';

export const OutletAdditionalDetails = (props: any) => {
  // TODO: Update the type of socialIcons once UXL query is deployed
  let socialIcons: any = [];

  const getSocialMediaList = () => {
    const socialMediaIcons = props?.outletData?.contactInfo?.socialMedia;
    if (props?.socialMediaList && socialMediaIcons) {
      socialIcons = socialMediaIcons.map((element: any) => {
        const socialMediaCode = element?.type?.code?.split('Url')[0].toLowerCase();
        const socialMedia = props?.socialMediaList?.filter((item: any) => {
          return item.socialMediaName.toLowerCase() === socialMediaCode;
        });
        const updatedElement = { ...element, socialMediaIcon: socialMedia[0]?.socialMediaIcon };
        return updatedElement;
      });
    }
  };

  getSocialMediaList();
  const outletData = props?.outletData;

  const {
    OUTLET_ADDITIONAL_SECTION,
    OUTLET_WEBSITE_LINK,
    OUTLET_RESERVATION_LINK,
    OUTLET_LOCATION_MAP,
    OUTLET_PHONE_CONTACT,
    OUTLET_EMAIL_CONTACT,
    EXTERNAL_LINK,
    OUTLET_ORDER_DELIVERY_LINK,
  } = TRACKING_CONSTANT;

  const restUrl = outletData?.basicInformation?.url || '';
  const orderUrl = outletData?.pickupDelivery?.deliveryUrl || '';
  const websiteName = outletData?.name || '';
  const hotelName = outletData?.properties?.edges[0]?.node?.property?.basicInformation?.displayName || '';
  const hotelUrl = outletData?.properties?.edges[0]?.node?.property?.basicInformation?.websiteUrl || '';
  const phoneNo =
    outletData?.contactInfo?.contactNumbers[0]?.phoneNumber?.display &&
    !outletData?.contactInfo?.contactNumbers[0]?.phoneNumber?.display.includes(undefined)
      ? outletData?.contactInfo?.contactNumbers[0]?.phoneNumber?.display
      : '';
  const emailId = outletData?.contactInfo?.emails ? outletData?.contactInfo?.emails[0] : '';
  const location = outletData?.contactInfo?.address || '';
  const latitude = outletData?.properties?.edges[0]?.node?.property?.basicInformation?.latitude || '';
  const longitude = outletData?.properties?.edges[0]?.node?.property?.basicInformation?.longitude || '';

  useEffect(() => {
    if (props?.trackingProperties?.impressionTrack)
      trackImpression(props?.trackingProperties || {}, 'Outlet Additional Details' || '');
  }, [props?.trackingProperties]);

  return (
    <StyledOutletAdditionalDetails>
      {!!restUrl && (
        <p className="additional-details d-flex mb-4 t-font-s" data-testid="rest-url">
          <Icon iconClass="icon-globe" ariaLabel="icon-globe" />
          <span className="d-inline-flex">
            <Button
              isLink={true}
              href={restUrl}
              target={TARGET_BLANK}
              className="additional-details__link t-font-s pb-0 ml-2"
              aria-label={websiteName}
              custom_click_track_value={`${OUTLET_ADDITIONAL_SECTION}|${OUTLET_WEBSITE_LINK}|${EXTERNAL_LINK}`}
            >
              {props?.restaurantWebsiteLabel}
            </Button>
          </span>
        </p>
      )}
      {!!orderUrl && (
        <p className="additional-details d-flex mb-4 t-font-s">
          <Icon iconClass="icon-car-rental-deck" ariaLabel="icon-car-rental-deck" />
          <span className="d-inline-flex">
            <Button
              isLink={true}
              href={`https://${orderUrl}`}
              target={TARGET_BLANK}
              className="additional-details__link t-font-s pb-0 ml-2"
              aria-label={websiteName}
              custom_click_track_value={`${OUTLET_ADDITIONAL_SECTION}|${OUTLET_ORDER_DELIVERY_LINK}|${EXTERNAL_LINK}`}
            >
              {props?.orderDeliveryLabel}
            </Button>
          </span>
        </p>
      )}

      {!!hotelName && (
        <p className="additional-details d-flex mb-4 t-font-s">
          <Icon iconClass="icon-bed" ariaLabel="icon-bed" />
          <span className="d-inline-flex">
            <Button
              testId="btn-test"
              isLink={!!hotelUrl}
              href={hotelUrl}
              aria-label={hotelName}
              className={`${
                hotelUrl
                  ? 'additional-details__link t-font-s pb-0 ml-2'
                  : 'additional-details__link t-font-s pb-0 ml-2 additional-details__non-link'
              }`}
              target={TARGET_BLANK}
              custom_click_track_value={`${OUTLET_ADDITIONAL_SECTION}|${OUTLET_RESERVATION_LINK}|${EXTERNAL_LINK}`}
            >
              {hotelName}
            </Button>
          </span>
        </p>
      )}

      {!!(latitude && longitude && location?.line1) && (
        <p className="additional-details d-flex mb-4 t-font-s" data-testid="outlet-location">
          <Icon iconClass="icon-location" ariaLabel="icon-location" />
          <span className="d-inline-flex">
            <Button
              isLink={true}
              href={`${GOOGLE_LOCATION}${latitude},${longitude}`}
              aria-label={location?.line1}
              target={TARGET_BLANK}
              className="additional-details__link t-font-s pb-0 ml-2"
              custom_click_track_value={`${OUTLET_ADDITIONAL_SECTION}|${OUTLET_LOCATION_MAP}|${EXTERNAL_LINK}`}
            >
              {location?.line1} {location?.city} {location?.stateProvince} {location?.country?.description}{' '}
              {location?.postalCode}
            </Button>
          </span>
        </p>
      )}

      {!!phoneNo && (
        <p className="additional-details d-flex mb-4 t-font-s">
          <Icon iconClass="icon-phone" ariaLabel="icon-phone" />
          <span className="d-inline-flex">
            <Button
              isLink={true}
              href={`tel:${phoneNo}`}
              aria-label={phoneNo}
              className="additional-details__link t-font-s pb-0 ml-2"
              custom_click_track_value={`${OUTLET_ADDITIONAL_SECTION}|${OUTLET_PHONE_CONTACT}|${EXTERNAL_LINK}`}
            >
              {phoneNo}
            </Button>
          </span>
        </p>
      )}

      {!!emailId && (
        <p className="additional-details d-flex mb-4 t-font-s" data-testid="outlet-email">
          <Icon iconClass="icon-mail" ariaLabel="icon-mail" />
          <span className="d-inline-flex">
            <Button
              isLink={true}
              href={`mailto:${emailId}`}
              aria-label={emailId}
              className="additional-details__link t-font-s pb-0 ml-2"
              custom_click_track_value={`${OUTLET_ADDITIONAL_SECTION}|${OUTLET_EMAIL_CONTACT}|${EXTERNAL_LINK}`}
            >
              {props?.emailContactLabel}
            </Button>
          </span>
        </p>
      )}
      <div className="social-icons-container">
        {socialIcons?.map(
          (socialIcon: any) =>
            !!socialIcon.url &&
            !!socialIcon.socialMediaIcon && (
              <Button
                isLink={true}
                href={socialIcon.url}
                className="additional-details__link t-font-s pb-0"
                target={TARGET_BLANK}
                key={`social-icon-${socialIcon.type.code}`}
                custom_click_track_value={`${OUTLET_ADDITIONAL_SECTION}|R&B ${
                  socialIcon.type.code?.split('Url')[0]
                } Button|${EXTERNAL_LINK}`}
              >
                <Icon
                  iconClass={socialIcon.socialMediaIcon + clsx(' font-size-base social-icons ')}
                  data-testid="social-media"
                ></Icon>
              </Button>
            )
        )}
      </div>
    </StyledOutletAdditionalDetails>
  );
};
