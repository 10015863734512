/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useEffect, useReducer, useMemo, useRef } from 'react';
import Axios from 'axios';
import dynamic from 'next/dynamic';
import { ComponentProps, SearchResultAlertType } from '../../index.types';
import {
  StyledSearchResultContainerDiv,
  StyledFilterContainerDiv,
  StyledSearchResultPropertyContainer,
  StyledAlertNotificationDiv,
} from './index.styles';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import { InitialSearchResult, UpdateSearchResult, UpdateSearchState } from '../../../store/store.reducer';
import {
  getInitialSearchMockState,
  getSearchResultQuery,
  SearchActions,
  getSearchFilterClearState,
  getSearchAppliedFilter,
  getShopQueryForSearchInput,
} from '../../../store/store.action';
import SM from '../../../lib/config';
import { SearchResultAlertContext, SearchFilterContext, SearchResultContext } from '../../index.context';
import { PaginationSortByComponent } from '../../molecules/paginationSortBy';
import CommonUtils from '../../../lib/CommonUtils';
import {
  clearAllFilterKey,
  DEFAULT_SORT_OPTS,
  DefaultValue,
  invalidSearchPropertyResultKey,
  maxCapacityKey,
  noAvailabilityKey,
  noHotelsWithinRadiusKey,
  noNearAddressKey,
  RECOMMENDED_OPTION,
  RECOMMENDED_SORT,
  SearchFacetsConstants,
  SearchFacetsList,
  searchFilters,
  SearchPageAlerts,
  SearchSortFieldForDISTANCE,
  SearchSortFieldsDirection,
  unsuccessfulSellKey,
} from '../../index.constant';
import {
  getCurrentPriceRateBlock,
  getCurrRateAmountBlock,
  getCurrencyOriginAccordToLocale,
  enableBoostBrands,
} from '../../../lib/helper';
import { SkeletonPropertyCardLoaderComponent } from '../../molecules/skeletonPropertyCardLoader';
import { Messages } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import {
  getAlertsData,
  getTotalFilterCount,
  updateDataLayerObj,
  updateDataLayerObjSigin,
  extractDataFromResponse,
  getAllPropertyIDS,
  updateSessionDocProperties,
  interactionsAppliedOnSERP,
  fieldsInteractedInSERP,
  isUnsuccessfulUserJourney,
  enableHotelList,
  clearCouchBaseCache,
  updateQueryParams,
} from '../../../lib/helper';
import moment from 'moment';
import { useApolloClient, useMutation } from '@apollo/client';
import { useCheckBreakpoint } from '../../../../../../hooks';
import { phoenixShopUpdateCustomerSavedProperties } from '@marriott/mi-shop-graphql';
import { usePageContext } from '../../../../../../context/PageContext/index';
import { getShopResult } from '../../../lib/shop.api';
import { inspect } from 'util';
import { getDeployedEnvType } from '@marriott/mi-apollo-client-utils';
import { getMockSearchResultData, logger, updateSessionDocErrorMessages } from '../../../../../../utils';
import { useSessionUpdateContext } from '../../../../../../context/SessionUpdateContext'; // 'libs/mi-shop-components/src/context';
import { useHQVContext } from '../../../../../../context';
import { HQV_ACTIONS } from '../../../../../../reducers';
import { isServer } from '@marriott/mi-apollo-client-utils';
import { RateNodeTypeToBeUsed } from '../../index.constant';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { targetPayloadRequest } from '@marriott/mi-headless-utils';
import Cookies from 'js-cookie';

const PropertyListComponent = dynamic(() => import('../../molecules/propertyList'));
const FiltersComponent = dynamic(() => import('../../molecules/filtersComponent'));
const MemberExclusiveBanner = dynamic(() => import('../../molecules/memberExclusiveBanner'));
const AlertNotification = dynamic(() => import('../../molecules/alerts'));

const { log } = logger({})('SearchResults/searchResults');
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>;
  }
}

export const renderedData = createContext({
  jsonContent: {},
  configData: SM,
  isMapViewEnabled: false,
  setISMapViewEnabled: () => {},

  GOOGLE_MAP_API_KEY: '',

  recentlyViewed: [],
  setRecentlyViewed: () => {},

  featureSelected: false,
  setFeatureSelected: () => {},

  isFlexibleDateSearch: false,
  setIsFlexibleDateSearch: () => {},

  isSearchClicked: false,
  setIsSearchClicked: () => {},

  isStateOrCountry: false,
  setIsStateOrCountry: () => {},

  continueCtaProperty: '',
  setContinueCtaProperty: () => {},

  sessionObject: { consumerID: '', accessToken: '', sessionID: '' },
  setSessionObject: () => {},

  revisionToken: '',
  setRevisionToken: (obj: object) => obj,

  savedProperties: [],
  setSavedProperties: (_savedProperties: string[]) => {},

  formValues: {},
  setFormValues: (_formValues: any) => {},

  isSpecialRateSearch: false,
  setIsSpecialRateSearch: () => {},

  isAutoSuggestSelectedSearch: false,
  setIsAutoSuggestSelectedSearch: (_value: boolean) => {},

  isRecentViewSelectedSearch: false,
  setIsRecentViewSelectedSearch: (_value: boolean) => {},

  isErrorState: true,
  setIsErrorState: () => {},

  isLastCardProcessed: false,
  setIsLastCardProcessed: () => {},

  imageURL: '',
  setImageURL: () => {},

  isTaxesandFeesIncludedFromPopup: false,
  setIsTaxesandFeesIncludedFromPopup: (_value: boolean) => {},
});

export const SavedPropertiesDispatchContext = createContext<any>({});

const SearchResultComp: React.FC<ComponentProps> = ({
  jsonContent,
  GOOGLE_MAP_API_KEY,
  requestID = '',
  searchQueryData,
  completeSession,
}) => {
  const {
    pageState,
    requestId,
    resolvedUrl,
    headersData,
    query,
    currentLocale,
    SESSION_APP_GET_CALL_URL,
    SESSION_APP_POST_CALL_URL,
    datalayerParams,
    datalayerProperties,
    sessionData,
    setSessionData,
    CACHE_CLEAR_URL,
    NEXT_PUBLIC_PREFIX,
    ENABLE_TARGET_SEGMENT_CALL,
  } = usePageContext();
  const showFullPriceInSession = sessionData?.cacheData
    ? sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.showFullPrice
    : sessionData?.data?.AriesSearch?.searchCriteria?.showFullPrice;
  // TODO - build an endpoint generator and replace this URL with method call.
  let loggerApiURL = '/api/logger';
  if (getDeployedEnvType() === 'higher') {
    loggerApiURL = `${NEXT_PUBLIC_PREFIX}/api/logger`;
  }
  const { interactionsSelected, setInteractionsSelected } = useSessionUpdateContext();
  const [popPupOpen, setPopupOpen] = useState<boolean>(false);
  const [updateSearchCounter, setUpdateSearchCounter] = useState<number>(0);
  const [hidePropertyList, setHidePropertyList] = useState<boolean>(true);
  const [segmentId, setSegmentId] = useState(Cookies.get('shopSegmentId') ?? '');

  if (requestId) {
    requestID = requestId;
  }
  const configData = SM; //load config from config file

  const apolloClientRef = useApolloClient(); // create apollo client instance
  const {
    getFlexibleFilter,
    getFlexiblePropertyInfo,
    getDatedFilter,
    getDatedPropertyInfo,
    getMedia,
    getKeywordPropertyInfo,
    getCurrencyConversionRate,
  } = getShopResult(apolloClientRef); // import all the api function

  const [isInitialLoad, setInitialLoad] = useState(true);

  const handleNextClick = (isInitialLoadData: boolean): void => {
    setInitialLoad(isInitialLoadData);
  };
  const queryCommonContext = {
    /**
     * define common params for all the query
     */
    context: {
      headers: {
        'x-request-id': requestID !== '' ? requestID : `${Date.now()}`,
        'accept-language': currentLocale,
      },
    },
  };

  const stateSearchFromSession =
    !completeSession?.data?.AriesSearch?.searchCriteria?.address?.latitude &&
    !completeSession?.data?.AriesSearch?.searchCriteria?.address?.city &&
    (completeSession?.data?.AriesSearch?.searchCriteria?.address?.stateProvince ||
      completeSession?.data?.AriesSearch?.searchCriteria?.address?.country) &&
    !completeSession?.data?.AriesSearch?.searchCriteria?.address?.city;
  // maintain search query global state with update/get
  const [searchQueryState, setSearchQuery] = useReducer(
    UpdateSearchState,
    getInitialSearchMockState(
      searchQueryData,
      ENABLE_TARGET_SEGMENT_CALL?.toString(),
      currentLocale,
      Cookies.get('shopSegmentId') ?? '',
      stateSearchFromSession
    )
  );

  // maintain global state for search result list item
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const mockSearchResult: any = SearchResultMockData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchResult, setSearchResult]: any = useReducer(UpdateSearchResult, InitialSearchResult);

  const { hqvDispatch } = useHQVContext();
  /** check if we are getting view=map from session
   * then isMapViewEnabled = true
   */
  const pageResultViewType = completeSession?.data?.AriesSearch?.searchCriteria?.searchResultViewType
    ? completeSession?.data?.AriesSearch?.searchCriteria?.searchResultViewType === 'map'
    : completeSession?.cacheData?.data?.AriesSearch?.searchCriteria?.searchResultViewType === 'map';

  const clickTrackingLoc = 'Phoenix Search Results';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isMapViewEnabled, setISMapViewEnabled]: any = useState<boolean>(
    isServer ? pageResultViewType : sessionStorage.getItem('map_view') === 'true' || pageResultViewType
  );
  const [wasMapViewEnabled, setWasMapViewEnabled]: any = useState<boolean>(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(true);

  const [isMobileState, setIsMobile] = useState(CommonUtils.isOnlyMobileView());
  //check loading of current result
  const [isLoadingSearchResult, setIsLoadingSearchResult] = useState<boolean>(true);

  //set search result errors in global state if any
  const [searchResultAlerts, setSearchResultAlerts] = useState<SearchResultAlertType>(SearchPageAlerts.default);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [recentlyViewed, setRecentlyViewed]: any = useState<any>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [featureSelected, setFeatureSelected]: any = useState<boolean>(false);
  const [distance, setDistance] = useState<number>(
    parseFloat(completeSession?.data?.AriesSearch?.searchCriteria?.searchRadius)
  );
  const [numberOfNights, setNumberOfNights] = useState<number>(
    sessionData?.data
      ? sessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.lengthOfStay
      : sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.lengthOfStay
  );
  const [pointsChecked, setPointsChecked] = useState<boolean>(searchQueryState[SearchFacetsConstants.points]);
  const SPECIAL_RATE_TYPE_NONE = 'none';
  const [isSpecialRateSearch, setIsSpecialRateSearch]: any = useState<boolean>(false);
  const isFlexibleSearchType =
    (completeSession?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.flexibleDate ||
      !completeSession?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkInDate ||
      searchQueryData?.options?.endDate === searchQueryData?.options?.startDate) &&
    completeSession?.data?.AriesSearch?.searchCriteria?.searchType !== 'Keyword';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isFlexibleDateSearch, setIsFlexibleDateSearch]: any = useState<boolean>(
    pageState.authorMode ? false : isFlexibleSearchType
  );
  const [leisureRegionValue, setLeisureRegionValue] = useState<string>(
    completeSession?.data?.AriesSearch?.searchCriteria?.address?.region
      ? completeSession?.data?.AriesSearch?.searchCriteria?.address?.region
      : ''
  );

  const isFlexibleDateSearchRef = useRef<boolean>(isFlexibleSearchType ? true : false);

  const nightsCount = numberOfNights;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isSearchClicked, setIsSearchClicked]: any = useState<boolean>(false);

  const [isFilterChange, setISFilterChange] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isErrorState, setIsErrorState]: any = useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [imageURL, setImageURL]: any = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isLastCardProcessed, setIsLastCardProcessed]: any = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isStateOrCountry, setIsStateOrCountry]: any = useState<boolean>(false);
  const viewportXl = useCheckBreakpoint('viewportXl');
  const viewportL = useCheckBreakpoint('viewportL');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [continueCtaProperty, setContinueCtaProperty]: any = useState(
    completeSession?.data
      ? completeSession?.data?.AriesReservation?.propertyId
      : completeSession?.cacheData?.data?.AriesReservation?.propertyId
  );

  const consumerID = completeSession?.data ? completeSession?.data?.consumerID : null;
  const accessToken = completeSession?.data ? completeSession?.data?.accessToken : null;
  const sessionTokenFromCompleteSession = sessionData?.data?.sessionToken || sessionData?.key;
  const sessionID = completeSession?.data ? sessionTokenFromCompleteSession : null;
  const rewardsID = completeSession?.data ? completeSession?.data?.rewardsId : null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sessionObject, setSessionObject]: any = useState({
    consumerID: consumerID,
    accessToken: accessToken,
    sessionID: sessionID,
    rewardsId: rewardsID,
  });

  const custNumber = useRef<string>(rewardsID || '');

  const [keywordSearch, setKeywordSearch] = useState(
    completeSession?.data?.AriesSearch?.searchCriteria?.searchType === 'Keyword'
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [revisionToken, setRevisionToken]: any = useState<any>('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [savedProperties, setSavedProperties]: any = useState<any>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filterUpdateData, setFilterUpdateData] = useState<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValues, setFormValues] = useState<any>({});
  const [isAutoSuggestSelectedSearch, setIsAutoSuggestSelectedSearch] = useState<boolean>(false);
  const [isRecentViewSelectedSearch, setIsRecentViewSelectedSearch] = useState<boolean>(false);
  //state to detect error scenario
  const [errorScenario, setErrorScenario] = useState<boolean>(false);
  const [isStateSearch, setStateSearch] = useState<boolean>(stateSearchFromSession);
  const [isDestinationSearch, setIsDestinationSearch] = useState<boolean>(false);
  const [hiddenDestData, setHiddenDestData] = useState<any>(
    completeSession?.data?.AriesSearch?.searchCriteria?.address
  );
  const [updateSearch, setUpdateSearch] = useState<boolean>(false);
  const [isTaxesandFeesIncludedFromPopup, setIsTaxesandFeesIncludedFromPopup] = useState<boolean>(false);
  const [targetSegmentCallCompleted, setTargetSegmentCallCompleted] = useState<boolean>(false);
  useEffect(() => {
    //Can not remove these selectors, As these selectors are from AEM header component.
    const staticHeaderLogo = document.querySelector('.m-header__thin-logo-icon');
    const staticHeader = document.querySelector('.m-header__thin-container');
    staticHeaderLogo &&
      staticHeaderLogo.addEventListener('click', () => {
        sessionStorage.setItem('map_view', 'false');
      });
    if (staticHeader) {
      staticHeader.setAttribute('style', 'z-index:5');
    }
  }, []);

  const callSegmentApi = async () => {
    const segmentPayload = targetPayloadRequest({
      isUxl: 'false',
      mboxName:
        process.env['TARGET_SEGMENT_MBOX_NAME'] ||
        useClientEnvVarsStore.getState().envVarsObject['TARGET_SEGMENT_MBOX_NAME'],
      propertyToken:
        process.env['TARGET_SEGMENT_PROPERTY_TOKEN'] ||
        useClientEnvVarsStore.getState().envVarsObject['TARGET_SEGMENT_PROPERTY_TOKEN'],
    });
    try {
      const response = await Axios.post('/mi/api/targetSegmentFetch', {
        targetReqPayload: segmentPayload,
      });
      setSegmentId(response?.data?.segmentId);
      if (response?.data?.segmentId === RECOMMENDED_SORT && !isStateSearch) {
        setSearchQuery({
          type: SearchActions.updateQueryAndOtherSearchQueryParam,
          payload: {
            [SearchFacetsConstants.sort]: {
              fields: [
                {
                  field: RECOMMENDED_OPTION,
                  direction: SearchSortFieldsDirection.desc,
                },
              ],
            },
          },
        });
      } else {
        setTargetSegmentCallCompleted(true);
      }
      return response?.data?.segmentId;
    } catch (error) {
      setTargetSegmentCallCompleted(true);
      console.error('Error calling segment API:', error);
      throw error; // Propagate the error up for handling
    }
  };

  useEffect(() => {
    if (sessionObject?.consumerID && sessionObject?.accessToken) {
      consumeRevisionToken();
    }
  }, []);

  useEffect(() => {
    const searchOject = searchQueryState[SearchFacetsConstants.search];
    if (searchQueryData?.latitude && !searchOject[SearchFacetsConstants.latitude]) {
      searchOject[SearchFacetsConstants.latitude] = searchQueryData?.latitude;
      searchOject[SearchFacetsConstants.longitude] = searchQueryData?.longitude;
      searchOject[SearchFacetsConstants.destination] = undefined;
      setSearchQuery({
        type: SearchActions.updateQueryAndOtherSearchQueryParam,
        payload: {
          [SearchFacetsConstants.search]: searchOject,
        },
      });
    }
  }, [searchQueryData]);

  const displayDefaultError = (e?: any, data?: any) => {
    Axios.post(loggerApiURL, {
      message: `SearchResults component UXL error for sessionId ${sessionID}: ${inspect(e)}`,
    });
    setIsLoadingSearchResult(false);
    if (!data || data?.total !== 0) {
      setSearchResultAlerts(
        getAlertsData(jsonContent?.alertsListArray && jsonContent?.alertsListArray[0]?.alertCodeListModel[0], 'info')
      );
    }
  };

  const getMediaTourFromUXL = async (allid: any) => {
    /**
     * get all media by property id
     */
    try {
      const { data, error } = await getMedia({
        variables: {
          ids: allid,
          limitPerCategory: 2,
        },
        context: {
          headers: {
            'x-b3-traceId': searchQueryData?.sessionToken ?? 'fallback-token',
            'x-b3-spanId': requestID !== '' ? requestID : `${Date.now()}`,
            'x-request-id': requestID !== '' ? requestID : `${Date.now()}`,
            'accept-language': currentLocale,
          },
        },
      });
      if (data) {
        setSearchResult({
          type: SearchActions.updateMedia,
          payload: {
            media: data.propertiesByIds,
          },
        });
      }
      if (error) {
        Axios.post(loggerApiURL, {
          message: `error with media UXL api for sessionId ${sessionID}: ${JSON.stringify(error)}`,
        });
      }
    } catch (e) {
      Axios.post(loggerApiURL, {
        message: `error with media UXL api for sessionId ${sessionID}: ${JSON.stringify(e)}`,
      });
    }
  };

  const loadCurrencyOnceResultLoaded = async (data: any) => {
    /**
     * load all price currency once we get the result
     */
    let currencyListArr: Array<string> | undefined;
    data?.forEach((edge: any) => {
      const ratesNode = edge?.node?.rates ?? edge?.node?.property?.rates;
      /**fetch rates nodes accroding to api */
      if (ratesNode) {
        /**
         * filter rate node as per the usage
         */
        const currRateNode = getCurrentPriceRateBlock(searchQueryState, ratesNode);
        const currRate = getCurrRateAmountBlock(RateNodeTypeToBeUsed, currRateNode);
        const currencyInfo = getCurrencyOriginAccordToLocale(currRate?.amount);
        if (currencyInfo && currencyInfo.currency) {
          /**
           * store different currency info in a local var
           */
          if (currencyListArr && currencyListArr.indexOf(currencyInfo?.currency) === -1) {
            /**
             * set another currency if we have
             */
            currencyListArr.push(currencyInfo?.currency);
            return; // empty return statement to break further execution
          }
          if (currencyListArr === undefined) {
            /**
             * set first currency
             */
            currencyListArr = [currencyInfo?.currency];
            return; // empty return statement to break further execution
          }
        }
      }
    });
    setSearchQuery({
      type: SearchActions.updateCurrency,
      payload: {
        currentOriginCurrency: currencyListArr,
      },
    });
    if (isInitialLoad && searchQueryState.currentCurrency !== DefaultValue) {
      /**
       * if we have diffrent currency on search load
       */
      await getDataWithCurrencyFilterApplied(currencyListArr);
      return; // empty return statement to break further execution
    }
    return; // empty return statement to break further execution
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loadSearchResults = async (data: any): Promise<void> => {
    //Can not remove these selectors, As these selectors are from different component.
    const filtersHidden = document.querySelector('.filter-component');
    const paginationHidden = document.querySelector('.pagination-sort-container');
    const _res = data;
    await loadCurrencyOnceResultLoaded(_res.edges); // load currency post search result
    // responseDataFromLoadSearchResults = data;
    const dataLayerObj = window.dataLayer ?? {};
    try {
      //removes the filters hidden class if no error shows up
      if (filtersHidden || paginationHidden) {
        filtersHidden?.classList.remove('filters-container-hidden');
        paginationHidden?.classList.remove('filters-container-hidden');
      }
      setErrorScenario(false);
      let responseStatus;
      if (_res?.status[0]?.messages) {
        responseStatus = _res?.status[0]?.messages[0]?.dev?.field ?? _res?.status[0]?.messages[0]?.dev?.field;
      }
      if (sessionStorage.getItem('updateSearchCounter')) {
        const sessionValue = sessionStorage.getItem('updateSearchCounter');
        const maxValue = Math.max(parseInt(sessionValue as string), updateSearchCounter);
        setUpdateSearchCounter(maxValue + 1);
        sessionStorage.setItem('updateSearchCounter', (maxValue + 1).toString());
      } else {
        sessionStorage.setItem('updateSearchCounter', `${updateSearchCounter}`);
      }
      setIsStateOrCountry(
        searchQueryState && searchQueryState?.search?.latitude
          ? false
          : _res?.searchCenter === null || _res?.searchCenter?.latitude === null
      );
      const totalFilterCount = getTotalFilterCount(searchQueryState);
      const sortByOptions =
        !isStateOrCountry && !isFlexibleDateSearch
          ? ENABLE_TARGET_SEGMENT_CALL && Cookies.get('shopSegmentId') === RECOMMENDED_SORT
            ? pointsChecked
              ? 'recommended,distance,city,points,brand'
              : 'recommended,distance,city,price,brand'
            : pointsChecked
            ? 'distance,city,points,brand'
            : 'distance,city,price,brand'
          : !isStateOrCountry && isFlexibleDateSearch
          ? ENABLE_TARGET_SEGMENT_CALL && Cookies.get('shopSegmentId') === RECOMMENDED_SORT
            ? 'recommended,distance,city,brand'
            : 'distance,city,brand'
          : isStateOrCountry && isFlexibleDateSearch
          ? 'city,brand'
          : pointsChecked
          ? 'city,points,brand'
          : 'city,price,brand';

      localStorage.setItem('sortByOptions', sortByOptions);
      updateDataLayerObj(
        dataLayerObj,
        _res,
        searchQueryState,
        isFlexibleDateSearch,
        isMapViewEnabled,
        datalayerProperties,
        isAutoSuggestSelectedSearch,
        isRecentViewSelectedSearch,
        sortByOptions,
        headersData?.referer
      );

      setSearchResult({
        /**
         * set search result for the user query
         */
        type: SearchActions.setSearchResultData,
        payload: {
          pageInfo: _res?.pageInfo,
          total: _res?.total,
          edges: _res?.edges,
          searchCenter: _res?.searchCenter,
          facets: _res?.facets,
        },
      });
      setSearchQuery({
        /**set filter/facets for the search result */
        type: SearchActions.setFilter,
        payload: _res?.facets,
      });

      if (!_res?.total) {
        /**
         * error cases if we get result 0
         */
        if (totalFilterCount && !_res?.total) {
          setIsLoadingSearchResult(false);
          setSearchResultAlerts(
            getAlertsData(
              jsonContent?.alertsListArray?.filter(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (key: any) => key.alertMsgTypeName === clearAllFilterKey
              )[0]?.alertCodeListModel[0],
              'warning'
            )
          );
          setWasMapViewEnabled(isMapViewEnabled);
          setISMapViewEnabled(false);
          sessionStorage.setItem('map_view', 'false');
        } else {
          Axios.post(loggerApiURL, {
            message: `SearchResults component totalFilterCount and _res?.total for sessionId ${sessionID}: ${totalFilterCount}, ${_res?.total}`,
          });
          //redirection to ADV search page is stopped as errors are shown in SERP now

          // const sessionData = await updateSessionDocErrorMessages(
          //   sessionID,
          //   SESSION_APP_GET_CALL_URL,
          //   SESSION_APP_POST_CALL_URL,
          //   ['search-error-messages|no.hotels.within.radius.error.msg']
          // );
          // const advanceSearchPageQuery = formAdvanceSearchPageQuery({
          //   sessionData,
          //   errorCode: 'LAR-1001',
          //   addErrorParams: true,
          // });
          // router.push(`${process.env['REDIRECT_URL']}?${advanceSearchPageQuery}`);

          //filters and pagination are hidden if error shows
          if (filtersHidden || paginationHidden) {
            filtersHidden?.classList.add('filters-container-hidden');
            paginationHidden?.classList.add('filters-container-hidden');
          }
          //flag to to detect error
          setErrorScenario(true);
          //errors will be shown based on the respective conditions which satisfies
          if (completeSession?.data?.AriesSearch?.searchCriteria?.searchType === 'NearAddress') {
            //To update error key in session doc
            await updateSessionDocErrorMessages(sessionID, SESSION_APP_GET_CALL_URL, SESSION_APP_POST_CALL_URL, [
              'search-error-messages|search.form.near.address.property.result.not.found',
            ]);
            //to set the error message
            setSearchResultAlerts(
              getAlertsData(
                jsonContent?.alertsListArray?.filter(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (key: any) => key.alertMsgTypeName === noNearAddressKey
                )[0]?.alertCodeListModel[0],
                'search.form.near.address.property.result.not.found'
              )
            );
          } else {
            switch (_res?.status[0]?.messages[0]?.user?.field) {
              case 'LAR-1001':
                //To update error key in session doc
                await updateSessionDocErrorMessages(sessionID, SESSION_APP_GET_CALL_URL, SESSION_APP_POST_CALL_URL, [
                  'search-error-messages|no.hotels.within.radius.error.msg',
                ]);
                //to set the error message
                setSearchResultAlerts(
                  getAlertsData(
                    jsonContent?.alertsListArray?.filter(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (key: any) => key.alertMsgTypeName === noHotelsWithinRadiusKey
                    )[0]?.alertCodeListModel[0],
                    'no.hotels.within.radius.error.msg'
                  )
                );
                break;
              case 'LAR-1002':
                //To update error key in session doc
                await updateSessionDocErrorMessages(sessionID, SESSION_APP_GET_CALL_URL, SESSION_APP_POST_CALL_URL, [
                  'search-error-messages|no.availability.within.radius.error.msg',
                ]);
                //to set the error message
                setSearchResultAlerts(
                  getAlertsData(
                    jsonContent?.alertsListArray?.filter(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (key: any) => key.alertMsgTypeName === noAvailabilityKey
                    )[0]?.alertCodeListModel[0],
                    'no.availability.within.radius.error.msg'
                  )
                );
                break;
              case 'LAR-1003':
                //To update error key in session doc
                await updateSessionDocErrorMessages(sessionID, SESSION_APP_GET_CALL_URL, SESSION_APP_POST_CALL_URL, [
                  'search-error-messages|property.search.hotels.maxcapacity.not.available',
                ]);
                //to set the error message
                setSearchResultAlerts(
                  getAlertsData(
                    jsonContent?.alertsListArray?.filter(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (key: any) => key.alertMsgTypeName === maxCapacityKey
                    )[0]?.alertCodeListModel[0],
                    'property.search.hotels.maxcapacity.not.available'
                  )
                );
                break;
              default:
                //To update error key in session doc
                await updateSessionDocErrorMessages(sessionID, SESSION_APP_GET_CALL_URL, SESSION_APP_POST_CALL_URL, [
                  'search-error-messages|search.form.property.result.not.found',
                ]);
                //to set the error message
                setSearchResultAlerts(
                  getAlertsData(
                    jsonContent?.alertsListArray?.filter(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (key: any) => key.alertMsgTypeName === invalidSearchPropertyResultKey
                    )[0]?.alertCodeListModel[0],
                    'search.form.property.result.not.found'
                  )
                );
            }
          }
        }
      } else {
        if (responseStatus === SearchPageAlerts.warning.code) {
          setSearchResultAlerts(getAlertsData(jsonContent?.alertsListArray[1]?.alertCodeListModel[0], 'warning'));
        } else if (isUnsuccessfulUserJourney()) {
          const alert = jsonContent?.alertsListArray?.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (key: any) => key.alertMsgTypeName === unsuccessfulSellKey
          )[0];
          setSearchResultAlerts(getAlertsData(alert?.alertCodeListModel[0], 'UnsuccessfulSell'));
        }
      }
      setIsLoadingSearchResult(false);
    } catch (e) {
      Axios.post(loggerApiURL, {
        message: `SearchResults component UXL catch error for sessionId ${sessionID}: ${inspect(e)}`,
      });
      setSearchResultAlerts(
        getAlertsData(jsonContent?.alertsListArray && jsonContent?.alertsListArray[0]?.alertCodeListModel[0], 'info')
      );
      //handle error at this place for any call
      setIsLoadingSearchResult(false);
    }
  };

  const value = {
    jsonContent,
    configData,
    isMapViewEnabled: isMapViewEnabled,
    setISMapViewEnabled: setISMapViewEnabled,
    GOOGLE_MAP_API_KEY,
    recentlyViewed,
    setRecentlyViewed,
    featureSelected,
    setFeatureSelected,
    isFlexibleDateSearch,
    setIsFlexibleDateSearch,
    isSearchClicked,
    setIsSearchClicked,
    isStateOrCountry,
    setIsStateOrCountry,
    continueCtaProperty,
    setContinueCtaProperty,
    sessionObject,
    setSessionObject,
    revisionToken,
    setRevisionToken,
    savedProperties,
    setSavedProperties,
    isSpecialRateSearch,
    setIsSpecialRateSearch,
    formValues,
    setFormValues,
    isAutoSuggestSelectedSearch,
    setIsAutoSuggestSelectedSearch,
    isRecentViewSelectedSearch,
    setIsRecentViewSelectedSearch,
    isErrorState,
    setIsErrorState,
    isLastCardProcessed,
    setIsLastCardProcessed,
    imageURL,
    setImageURL,
    isTaxesandFeesIncludedFromPopup,
    setIsTaxesandFeesIncludedFromPopup,
  };

  useEffect(() => {
    setContinueCtaProperty(
      completeSession?.data
        ? completeSession?.data?.AriesReservation?.propertyId
        : completeSession?.cacheData?.data?.AriesReservation?.propertyId
    );
    setIsSpecialRateSearch(
      completeSession?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.clusterCode !==
        SPECIAL_RATE_TYPE_NONE ||
        (formValues && formValues?.specialRates && formValues?.specialRates?.clusterCode !== SPECIAL_RATE_TYPE_NONE)
    );
    eventUtil.on('displayLoader', () => {
      setIsLoadingSearchResult(true);
      setSearchResult({
        /**
         * set search result empty
         */
        type: SearchActions.setSearchResultData,
        payload: {
          pageInfo: {},
          total: 0,
          edges: [],
        },
      });
    });

    eventUtil.on('updateShopPageFormValues', data => {
      /**
       * on update search click event listen
       */
      setUpdateSearch(true);
      setKeywordSearch(false);
      const {
        usePoints,
        searchObj,
        isFlexibleDateSearch,
        formValues,
        destinationHiddenData,
        isAutoSuggestSelected,
        isRecentSearchSelected,
        updatedSessionData,
      } = data;
      setLeisureRegionValue(destinationHiddenData?.region);
      setIsSpecialRateSearch(
        updatedSessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.clusterCode !==
          SPECIAL_RATE_TYPE_NONE ||
          (formValues && formValues?.specialRates && formValues?.specialRates?.clusterCode !== SPECIAL_RATE_TYPE_NONE)
      );
      if (updatedSessionData) {
        setSessionData(updatedSessionData);
        const sessionDataObject = updatedSessionData?.data?.AriesSearch?.searchCriteria
          ? updatedSessionData?.data?.AriesSearch?.searchCriteria
          : updatedSessionData?.cacheData?.data?.AriesSearch?.searchCriteria;
        Axios.post(loggerApiURL, {
          message: `SearchResults update session Data, Search Criteria ${sessionID}: ${inspect(
            sessionDataObject
          )}, ${inspect(sessionDataObject?.address)}`,
        });
      }
      setHiddenDestData(destinationHiddenData);
      setIsFlexibleDateSearch(pageState.authorMode ? false : isFlexibleDateSearch);
      isFlexibleDateSearchRef.current = pageState.authorMode ? false : isFlexibleDateSearch;
      setFormValues(formValues);
      setIsAutoSuggestSelectedSearch(isAutoSuggestSelected);
      setIsRecentViewSelectedSearch(isRecentSearchSelected);

      setIsSearchClicked(true);
      const { options, latitude, destination } = searchObj;
      const { startDate, endDate } = options;
      setIsStateOrCountry(latitude !== null || leisureRegionValue ? false : true);
      const searchCriteriaObj = updatedSessionData?.data?.AriesSearch?.searchCriteria
        ? updatedSessionData?.data?.AriesSearch?.searchCriteria
        : updatedSessionData?.cacheData?.data?.AriesSearch?.searchCriteria;
      setNumberOfNights(
        searchCriteriaObj?.availabilityRequestVO?.lengthOfStay ??
          Math.ceil(moment(formValues?.dates?.toDate)?.diff(formValues?.dates?.fromDate, 'days', true) ?? 0)
      );
      setPointsChecked(usePoints);
      setFeatureSelected(false);
      setIsErrorState(true);
      setIsLastCardProcessed(false);
      setDistance(parseFloat(destinationHiddenData?.radius ?? searchCriteriaObj?.searchRadius));

      if (usePoints) {
        //reset price filter if we have applied any
        setSearchQuery({ type: SearchActions.setApplyFilter, payload: { [SearchFacetsList.price]: {} } });
      } else {
        //reset price filter if we have applied any
        setSearchQuery({ type: SearchActions.setApplyFilter, payload: { [SearchFacetsList.points]: {} } });
      }
      const hideRecommendedOption =
        destination &&
        (destinationHiddenData?.state?.split(',').length > 0 ||
          destinationHiddenData?.country?.split(',').length > 0 ||
          !destinationHiddenData?.latitude);

      if (
        destination &&
        (destinationHiddenData?.state?.split(',').length > 0 || destinationHiddenData?.country?.split(',').length > 0)
      ) {
        setStateSearch(true);
      } else {
        setStateSearch(false);
        if (destinationHiddenData.state) {
          setSearchQuery({
            type: SearchActions.setApplyFilter,
            payload: {
              [SearchFacetsList.states]: {},
            },
          });
        } else {
          setSearchQuery({
            type: SearchActions.setApplyFilter,
            payload: {
              [SearchFacetsList.countries]: {},
            },
          });
        }
      }

      if (destinationHiddenData?.region) {
        setStateSearch(false);
      }

      const currentDate = moment(); // current date
      const diffFromStartDate = Math.abs(moment.duration(currentDate.diff(startDate)).asDays() ?? 0);
      const diffFromEndDate = Math.abs(moment.duration(currentDate.diff(endDate)).asDays() ?? 0);
      // set availability checkbox true or false basis of search object
      const isIncludeUnAvailableProperties = diffFromStartDate > 6 || diffFromEndDate > 6 ? true : false;

      // setSearchQuery({ type: SearchActions.updatePoints, payload: { [SearchFacetsConstants.points]: usePoints } });
      setSearchQuery({
        type: SearchActions.updateQueryAndOtherSearchQueryParam,
        payload: {
          // set availability checkbox true or false basis of search object
          [SearchFacetsConstants.unavailableProperties]: isIncludeUnAvailableProperties,
          [SearchFacetsConstants.offset]: 0,
          [SearchFacetsConstants.goToNextPage]: 0,
          [SearchFacetsConstants.sort]: {
            fields: fetchSortFields(latitude, hideRecommendedOption),
          },
          // change this to above line if these is any change
          [SearchFacetsConstants.points]: usePoints, //remove seperate statement and add these into single update function
          [SearchFacetsConstants.search]: searchObj,
          //comment below lines if you want to presist the currency filter
          currentCurrency: DefaultValue,
          currentOriginCurrency: [],
          currentAppliedCurrency: DefaultValue,
        },
      });
    });

    return (): void => {
      eventUtil.remove('displayLoader', () => {});
      eventUtil.remove('updateShopPageFormValues', () => {});
    };
  }, []);

  const setDataToContext = (data: any, error: any): void => {
    /******* set data/error to context  */
    /*** remove code duplication  */
    if (error) {
      Axios.post(loggerApiURL, {
        message: `SearchResults component setDataToContext error for sessionId ${sessionID}: ${inspect(error)}`,
      });
      displayDefaultError(error, data);
    }
    if (data) {
      setISFilterChange(false);
      loadSearchResults(data);
    }
  };
  const fetchSortFields = (latitudeValue: number, hideRecommendedOption: boolean) => {
    // sort options for hotellist=marsha and boost brand combined scenario
    if (
      !hideRecommendedOption &&
      (segmentId === RECOMMENDED_SORT || Cookies.get('shopSegmentId') === RECOMMENDED_SORT)
    ) {
      if (window && window?.dataLayer) {
        window.dataLayer['search_list_setting_sort_order'] = RECOMMENDED_OPTION?.toLowerCase();
      }
      return [
        {
          field: RECOMMENDED_OPTION,
          direction: SearchSortFieldsDirection.desc,
        },
      ];
    } else if (enableHotelList() === true && enableBoostBrands() === true) {
      return [
        {
          field: 'BRAND',
          direction: SearchSortFieldsDirection.asc,
        },
      ];
    } else if (enableHotelList() === true) {
      return [
        {
          field: 'PROPERTY_NAME',
          direction: SearchSortFieldsDirection.asc,
        },
      ];
    } else if (latitudeValue) {
      return [
        {
          field: 'DISTANCE',
          direction: SearchSortFieldsDirection.asc,
        },
      ];
    } else {
      return SearchSortFieldForDISTANCE?.map((fieldVal: any) => {
        return {
          field: fieldVal,
          direction: SearchSortFieldsDirection.asc,
        };
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ifLeisureRegionApplicable = (searchPayload: any) => {
    //logic to handle leisureRegions if navigated from homepage , or else regular destination flow will work

    //if state or country search and destination is availabe , remove destination
    if (
      isStateSearch &&
      searchPayload.search.destination &&
      searchPayload.search.destination !== completeSession?.data?.AriesSearch?.searchCriteria?.airportCode
    ) {
      searchPayload.search.destination = '';
    }

    //if region is available in sessiondata , add leisure region to payload
    if (leisureRegionValue !== '') {
      const newValue = { type: SearchFacetsList.leisureRegions, dimensions: [leisureRegionValue] };
      const existingTermIndex = searchPayload?.search?.facets?.terms?.findIndex(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (term: any) => term.type === newValue.type
      );
      if (existingTermIndex !== -1) {
        // Update the existing object
        searchPayload.search.facets.terms[existingTermIndex] = {
          ...searchPayload.search.facets.terms[existingTermIndex],
          ...newValue,
        };
      } else {
        // Add a new object
        searchPayload.search.facets.terms.push(newValue);
      }
      if (
        searchPayload &&
        searchPayload.search &&
        searchPayload.search.destination &&
        isFlexibleDateSearchRef.current
      ) {
        searchPayload.search.destination = '';
      }

      // commented code as it might be required in future
      //once all operations done , make region as blank
      // if (completeSession?.data?.AriesSearch?.searchCriteria?.address?.region) {
      //   completeSession.data.AriesSearch.searchCriteria.address.region = '';
      // }
    } else {
      //if not state or country search , and destination is available on state , assign it to payload
      if (searchQueryState?.search?.destination && !isStateSearch) {
        searchPayload.search.destination = searchQueryState?.search?.destination;
      }
    }

    // remove destination from search payload in case no destination given for hotellist deeplink
    let hasDestinationParam = false;
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      hasDestinationParam =
        params?.has('destinationAddress.destination') && params?.get('destinationAddress.destination') !== '';
    }

    if (enableHotelList() === true && !hasDestinationParam && searchPayload?.search?.destination) {
      delete searchPayload?.search?.destination;
    }
  };

  //Handle response from search result
  const handleResponse = (
    res: { data: { searchLowestAvailableRatesByDestination: any; searchPropertiesByDestination: any } },
    isDesti: boolean,
    isFlexibleDateSearch: boolean,
    isStateSearch: boolean | undefined
  ) => {
    const response = extractDataFromResponse(res.data, isDesti, isFlexibleDateSearch, isStateSearch);
    setHidePropertyList(false);
    setInitialLoad(false);
    /*
                Dispatches the searchCenter address field so as to Update
                the destination field value with searchCenter address, if destination query triggers.
                */
    if (
      (res.data?.searchLowestAvailableRatesByDestination || res.data?.searchPropertiesByDestination) &&
      response?.searchCenter !== null
    ) {
      eventUtil.dispatch('SearchResultsData', response?.searchCenter?.address);
    }
    setDataToContext(response, null);
    const allIDs = getAllPropertyIDS(response.edges);
    if (allIDs?.length) {
      getMediaTourFromUXL(allIDs);
    }
  };

  //Search Result Data call START
  const getSearchDataOnAnyUIChanges = async (): Promise<void> => {
    try {
      const searchVar = getSearchResultQuery(
        searchQueryState,
        isTaxesandFeesIncludedFromPopup,
        showFullPriceInSession,
        isFlexibleDateSearch,
        custNumber.current,
        distance
      );
      let searchPayload = Object.assign({}, searchVar);
      const isDesti =
        searchVar.search.destination !== undefined &&
        searchVar.search.destination !== null &&
        searchVar.search.destination !== '';
      setIsDestinationSearch(isDesti);
      if (isInitialLoad) {
        setHidePropertyList(false);
      }
      if (isDesti && isStateSearch) {
        searchPayload = getShopQueryForSearchInput(searchVar, hiddenDestData, searchQueryState, isFlexibleDateSearch);
      }
      ifLeisureRegionApplicable(searchPayload);
      const payload = {
        variables: {
          ...searchPayload,
        },
        ...queryCommonContext,
      };

      /****
       * create graphql call flexible/dated */
      if (isFlexibleDateSearch) {
        getFlexiblePropertyInfo(
          /***
           * flexible search api call
           */
          payload,
          isDesti
        ).then(
          (res: any) => {
            if (res) {
              handleResponse(res, isDesti, isFlexibleDateSearch, isStateSearch);
            }
          },
          (error: any) => {
            if (error) {
              setDataToContext(null, error);
              Axios.post(loggerApiURL, {
                message: `error with media UXL api for sessionId ${sessionID}: ${JSON.stringify(error)}`,
              });
            }
          }
        );
      } else {
        getDatedPropertyInfo(
          /***
           * dated search api call
           */
          payload,
          isDesti,
          isStateSearch
        ).then(
          (res: any) => {
            if (res) {
              handleResponse(res, isDesti, isFlexibleDateSearch, isStateSearch);
            }
          },
          (error: any) => {
            if (error) {
              setDataToContext(null, error);
              Axios.post(loggerApiURL, {
                message: `error with media UXL api for sessionId ${sessionID}: ${JSON.stringify(error)}`,
              });
            }
          }
        );
      }
      eventUtil.dispatch('loadSearchForm', true);
    } catch (e) {
      Axios.post(loggerApiURL, {
        message: `SearchResults component getSearchDataOnAnyUIChanges catch block error for sessionId ${sessionID}: ${inspect(
          e
        )}`,
      });
      displayDefaultError(e);
    }
  };

  //Keyword Search Result Data call START
  const getKeywordSearchDataOnAnyUIChanges = async () => {
    try {
      setHidePropertyList(false);
      const searchVar = getSearchResultQuery(searchQueryState, isTaxesandFeesIncludedFromPopup, showFullPriceInSession);
      const searchQuery = {
        limit: 40,
        offset: 0,
        search: {
          keyword: completeSession?.data?.AriesSearch?.searchCriteria?.keywordSearchRequestVO?.keywords,
          options: {
            ...searchVar?.search?.options,
            startDate: `${moment().format('YYYY-MM-DD')}`,
            endDate: `${moment().add(1, 'days').format('YYYY-MM-DD')}`,
          },
        },
      };
      const { data, error } = await getKeywordPropertyInfo({
        variables: {
          ...searchQuery,
        },
        ...queryCommonContext,
      });
      if (data) {
        setDataToContext(data.searchLowestAvailableRatesByKeyword, error);
        const allIDs = getAllPropertyIDS(data.searchLowestAvailableRatesByKeyword?.edges);
        if (allIDs?.length) {
          getMediaTourFromUXL(allIDs);
        }
      }
      if (error) {
        Axios.post(loggerApiURL, {
          message: `SearchResults component getKeywordSearchDataOnAnyUIChanges error for sessionId ${sessionID}: ${inspect(
            error
          )}`,
        });
        displayDefaultError(error, data?.searchLowestAvailableRatesByKeyword);
      }
    } catch (e) {
      Axios.post(loggerApiURL, {
        message: `SearchResults component getKeywordSearchDataOnAnyUIChanges catch block error for sessionId ${sessionID}: ${inspect(
          e
        )}`,
      });
      displayDefaultError(e);
    }
  };
  const getCurrencyData = async (currency: string) => {
    if (currency === searchQueryState.currentCurrency) {
      return {
        currentConversionRate: 1,
        currentConversionRateDecimalPoint: 1,
        currency: currency,
      };
    }
    const payloadObj = {
      input: {
        from: {
          currency: currency, // currency we have on property card
          valueDecimalPoint: 2, // dummy required value for UXL
          value: 1, // dummy required input for UXL
        },
        toCurrency: searchQueryState?.currentCurrency?.toUpperCase(), //currency selected by user
      },
    };

    const { data } = await getCurrencyConversionRate({
      variables: payloadObj,
      ...queryCommonContext,
    });

    return {
      currentConversionRate:
        data?.convertCurrency?.exchangeRate / Math.pow(10, data?.convertCurrency?.exchangeRateDecimalPoint),
      currentConversionRateDecimalPoint: data?.convertCurrency?.exchangeRateDecimalPoint,
      currency: currency,
    };
  };
  const getDataWithCurrencyFilterApplied = async (currencyArr?: Array<string> | undefined) => {
    const currencyList = currencyArr ?? searchQueryState.currentOriginCurrency;
    if (currencyList) {
      try {
        const data = await Promise.all(currencyList.map(getCurrencyData));
        setSearchQuery({
          /**
           * update currency conversion rate based on the currency selected
           */
          type: SearchActions.updateCurrency,
          payload: {
            currentConversionRate: data,
            currentAppliedCurrency: searchQueryState.currentCurrency, // set user current currency
          },
        });
        if (isFilterChange) {
          // call query function also if user has changed the filter with currency
          if (keywordSearch) {
            getKeywordSearchDataOnAnyUIChanges();
          } else {
            getSearchDataOnAnyUIChanges();
          }
        } else {
          if (!isInitialLoad) {
            if (sessionStorage.getItem('updateSearchCounter')) {
              const maxValue = parseInt(sessionStorage.getItem('updateSearchCounter') as string);
              sessionStorage.setItem('updateSearchCounter', (maxValue + 1).toString());
            } else {
              sessionStorage.setItem('updateSearchCounter', '0');
            }
            const searchCounterValue = sessionStorage.getItem('updateSearchCounter');
            if (window && window.dataLayer) {
              window.dataLayer['updates_search_call_counter'] = searchCounterValue;
            }
            // Update the window.location.hash with the update search counter
            window.history.replaceState({}, headersData?.referer, `#/${searchCounterValue}/`);
          }
          setHidePropertyList(false); // display property records
          setIsLoadingSearchResult(false); // hide loader
        }
        updateDataLayerObj(
          window?.dataLayer ?? {},
          data,
          searchQueryState,
          isFlexibleDateSearch,
          isMapViewEnabled,
          datalayerProperties,
          isAutoSuggestSelectedSearch,
          isRecentViewSelectedSearch,
          localStorage.getItem('sortByOptions'),
          headersData?.referer
        );
        return; // stop next execution
      } catch (e) {
        log.debug(`currency:function error ${inspect(e)}`);
        Axios.post(loggerApiURL, {
          message: `SearchResults component getSearchDataOnAnyUIChanges catch block error for sessionId ${sessionID}: ${inspect(
            e
          )}`,
        });
        return; // stop next execution
      }
    }
    return; // stop next execution
  };

  const getUpdatedFilterOnUSelection = async (payload: any) => {
    try {
      const params = new URLSearchParams(window.location.search);
      if (params.has('unsuccessfulSell')) {
        params.delete('unsuccessfulSell');
        const searchCounterValue = sessionStorage.getItem('updateSearchCounter');
        window.history.replaceState({}, headersData?.referer, decodeURIComponent(`?${params}#/${searchCounterValue}/`));
      }
      const searchVar = getSearchResultQuery(
        payload,
        isTaxesandFeesIncludedFromPopup,
        showFullPriceInSession,
        isFlexibleDateSearchRef.current,
        custNumber.current,
        distance
      );
      let searchPaylod = Object.assign({}, searchVar);
      const isDesti =
        searchVar.search.destination !== undefined &&
        searchVar.search.destination !== null &&
        searchVar.search.destination !== '';
      setIsDestinationSearch(isDesti);
      if (isDesti && isStateSearch) {
        searchPaylod = getShopQueryForSearchInput(searchVar, hiddenDestData, payload, isFlexibleDateSearchRef.current);
      }
      const load = {
        variables: {
          ...searchPaylod,
        },
        ...queryCommonContext,
      };
      /****
       * create graphql call flexible/dated */
      const { data, error } = isFlexibleDateSearchRef.current
        ? await getFlexibleFilter(
            /***
             * flexible search api call
             */
            load,
            isDesti
          )
        : await getDatedFilter(
            /***
             * dated search api call
             */
            load,
            isDesti,
            isStateSearch
          );
      if (data) {
        const res = extractDataFromResponse(data, isDesti, isFlexibleDateSearchRef.current);
        setFilterUpdateData(res);
      }
      if (error) {
        Axios.post(loggerApiURL, {
          message: `searchresult:error with UXL filter api for sessionId ${sessionID}: ${inspect(error)}`,
        });
      }
    } catch (e) {
      Axios.post(loggerApiURL, {
        message: `searchresult:error with UXL filter api for sessionId ${sessionID}: ${inspect(e)}`,
      });
    }
  };

  //need to remove this code once we are donw with client side calling pattern
  useEffect(() => {
    eventUtil.on('updateShopPageFilterModalValues', payload => {
      getUpdatedFilterOnUSelection(payload);
    });
    eventUtil.on('observeFilterChangeToUpdateTheDataWithCurrency', () => {
      //listen to this event once currency will change with other filter
      setISFilterChange(true);
    });
    return (): void => {
      // remove all this event
      eventUtil.remove('updateShopPageFilterModalValues', () => {});
      eventUtil.remove('observeFilterChangeToUpdateTheDataWithCurrency', () => {});
    };
  }, []);

  /**
   * get and update latest session
   */
  const getLatestSessionData = async () => {
    const filters = getTotalFilterCount(searchQueryState);
    const lengthOfStayValue: number = nightsCount;

    const fieldsWithValuesTrue = fieldsInteractedInSERP(interactionsSelected);

    const appliedInteractions = interactionsAppliedOnSERP(
      searchQueryState,
      formValues,
      lengthOfStayValue,
      filters,
      featureSelected,
      isFlexibleDateSearch,
      searchResult
    );

    const updatedSessionDoc = await updateSessionDocProperties(
      sessionData?.key ??
        sessionData?.data?.sessionToken ??
        sessionData?.cacheData?.data?.sessionToken ??
        sessionData?.sessionToken,
      sessionObject?.accessToken,
      SESSION_APP_GET_CALL_URL,
      SESSION_APP_POST_CALL_URL,
      datalayerParams,
      searchQueryState,
      savedProperties,
      appliedInteractions,
      resolvedUrl,
      headersData,
      query,
      fieldsWithValuesTrue,
      isMapViewEnabled,
      updateSearch
    );
    // Update the session data state value
    if (updatedSessionDoc && Object.keys(updatedSessionDoc).length > 0) {
      setSessionData(updatedSessionDoc);
    }
    hqvDispatch({ type: HQV_ACTIONS.PROPERTY_SESSION_DATA, payload: updatedSessionDoc });
  };

  useEffect(() => {
    // currentAppliedCurrency contains the currency the results are currently in
    // currentCurrency contains the value that the user has newly selected
    // adding a condition to prevent redundant updateSession calls on currency change
    if (
      (searchQueryState?.currentAppliedCurrency === searchQueryState?.currentCurrency ||
        searchQueryState?.currentCurrency === DefaultValue) &&
      !isInitialLoad
    ) {
      getLatestSessionData();
    }
    setIsAutoSuggestSelectedSearch(false);
    setIsRecentViewSelectedSearch(false);
    setUpdateSearch(false);
  }, [
    searchQueryState.search,
    searchQueryState[SearchFacetsConstants.goToNextPage],
    JSON.stringify(searchQueryState[SearchFacetsConstants.sort]),
    isMapViewEnabled,
    searchQueryState?.currentCurrency,
    searchQueryState?.currentConversionRate,
  ]);

  const getSearchResultMockData = async () => {
    const response = await getMockSearchResultData();
    setSearchResult({
      /**
       * set search result for the user query
       */
      type: SearchActions.setSearchResultData,
      payload: {
        pageInfo: response?.pageInfo,
        total: response?.total,
        edges: response?.edges,
        // searchCenter: response?.searchCenter,
      },
    });
    //update search facets with mock data
    setSearchQuery({
      type: SearchActions.setFilter,
      payload: response?.facets,
    });
    //disable loading state because we are using mock data
    setIsLoadingSearchResult(false);
    setHidePropertyList(false);
    eventUtil.dispatch('loadSearchForm', true);
  };

  const updateSearchAPICalls = () => {
    /**
     * fetch search result based on search query
     */
    setHidePropertyList(true);
    setIsLoadingSearchResult(true);
    setSearchResultAlerts(SearchPageAlerts.default);
    window.scrollTo(0, 0);
    if (!pageState.authorMode) {
      if (
        searchQueryState.currentCurrency === DefaultValue ||
        searchQueryState.currentCurrency === searchQueryState.currentAppliedCurrency ||
        isInitialLoad
      ) {
        setSearchResult({
          /**
           * set search result empty
           */
          type: SearchActions.setSearchResultData,
          payload: {
            pageInfo: {},
            total: 0,
            edges: [],
          },
        });
        /**
         * get search result with two api call, basic info and media
         */
        if (keywordSearch) {
          getKeywordSearchDataOnAnyUIChanges();
        } else {
          getSearchDataOnAnyUIChanges();
        }
      } else {
        /**
         * get currency conversion rate on currency apply filter
         */
        getDataWithCurrencyFilterApplied();
        // setSearchResultAlerts(SearchPageAlerts.success);
      }
    } else {
      // used in author mode

      getSearchResultMockData();
    }
    setFilterUpdateData(null);
  };

  useEffect(() => {
    /**
     * main function to call every UXL api
     */
    if (
      ENABLE_TARGET_SEGMENT_CALL?.toString() === 'true' &&
      currentLocale === 'en-US' &&
      !segmentId &&
      !Cookies.get('shopSegmentId')
    ) {
      callSegmentApi();
    } else {
      updateSearchAPICalls();
    }
  }, [
    searchQueryState.search,
    searchQueryState[SearchFacetsConstants.goToNextPage],
    JSON.stringify(searchQueryState[SearchFacetsConstants.sort]),
    searchQueryState.currentCurrency,
  ]);

  useEffect(() => {
    if (targetSegmentCallCompleted) {
      setTargetSegmentCallCompleted(false);
      updateSearchAPICalls();
    }
  }, [targetSegmentCallCompleted]);

  useEffect(() => {
    setSegmentId(Cookies.get('shopSegmentId') ?? '');
  }, [Cookies.get('shopSegmentId')]);

  //toggle map view
  const enableMapView = (mapViewClicked: boolean): void => {
    // Update the view type to map in URL
    const params = new URLSearchParams(window.location.search);
    params.set(searchFilters.view, 'map');
    window.history.replaceState({}, headersData?.referer, `${decodeURIComponent(`?${params}`)}${window.location.hash}`);
    const searchFormEl = document.getElementById('document_search_form_container_el_ref');
    // setSelectedHotel([]);
    sessionStorage.setItem('map_view', 'true');
    document.body.style.overflow = 'hidden';
    // Check if the current view is list view before changing it to map view
    if (!isMapViewEnabled) {
      if (window && window.scrollY && window.scrollY !== 0) {
        // If scrolled, return to unscrolled state so vh is calculated correctly. (Safari fix.)
        window.scrollTo(0, 0);
        // After scrolling is complete, enable map view.
        setTimeout(() => {
          setISMapViewEnabled(true);
          setInteractionsSelected({ ...interactionsSelected, listOrMapButton: true });
        }, 200);
      } else {
        // Else enable map view immediately.
        setISMapViewEnabled(true);
        setInteractionsSelected({ ...interactionsSelected, listOrMapButton: true });
      }
    }
    if (searchFormEl) {
      searchFormEl?.classList.add('map-view-sticky');
      // searchFormEl.style.top = `0px`; // AFAIK this isn't necessary. Leaving in case a regression shows up in testing.
      searchFormEl?.focus();
    }
    if (mapViewClicked) {
      if (sessionStorage.getItem('updateSearchCounter')) {
        const maxValue = parseInt(sessionStorage.getItem('updateSearchCounter') as string);
        sessionStorage.setItem('updateSearchCounter', (maxValue + 1).toString());
      } else {
        sessionStorage.setItem('updateSearchCounter', '0');
      }
      const searchCounterValue = sessionStorage.getItem('updateSearchCounter');
      if (window && window.dataLayer) {
        window.dataLayer['updates_search_call_counter'] = searchCounterValue;
      }
      // Update the window.location.hash with the update search counter
      window.history.replaceState({}, headersData?.referer, `#/${searchCounterValue}/`);
      if (window && window.dataLayer) {
        window.dataLayer['search_results_view'] = 'Map View';
      }
    }
  };

  //toggle list view
  const enableListView = (listViewClicked: boolean): void => {
    // Update the view type to list in URL
    const params = new URLSearchParams(window.location.search);
    params.set(searchFilters.view, 'list');
    window.history.replaceState({}, headersData?.referer, `${decodeURIComponent(`?${params}`)}${window.location.hash}`);
    // Check if the current view is map view before changing it to list view
    if (isMapViewEnabled) {
      setISMapViewEnabled(false);
      setInteractionsSelected({ ...interactionsSelected, listOrMapButton: true });
    }
    sessionStorage.setItem('map_view', 'false');
    document.body.style.overflow = 'auto';
    // eventUtil.dispatch('makeSearchFormStickyForMapView', {isMapViewEnabled:false});
    const searchFormEl = document.getElementById('document_search_form_container_el_ref');
    if (searchFormEl) {
      searchFormEl.classList.remove('map-view-sticky');
    }
    if (listViewClicked) {
      if (sessionStorage.getItem('updateSearchCounter')) {
        const maxValue = parseInt(sessionStorage.getItem('updateSearchCounter') as string);
        sessionStorage.setItem('updateSearchCounter', (maxValue + 1).toString());
      } else {
        sessionStorage.setItem('updateSearchCounter', '0');
      }
      const searchCounterValue = sessionStorage.getItem('updateSearchCounter');
      if (window && window.dataLayer) {
        window.dataLayer['updates_search_call_counter'] = searchCounterValue;
      }
      // Update the window.location.hash with the update search counter
      // window.location.hash = `#/${searchCounterValue}/`;
      window.history.replaceState({}, headersData?.referer, `#/${searchCounterValue}/`);
      if (window && window.dataLayer) {
        window.dataLayer['search_results_view'] = 'List View';
      }
    }
  };

  useEffect(() => {
    /**
     * show map view on basis of session param
     */
    const resultsViewType = completeSession?.data?.AriesSearch?.searchCriteria?.searchResultViewType
      ? completeSession?.data?.AriesSearch?.searchCriteria?.searchResultViewType
      : completeSession?.cacheData?.data?.AriesSearch?.searchCriteria?.searchResultViewType;
    const searchMapValue = sessionStorage?.getItem('map_view');
    if ((resultsViewType === 'map' || searchMapValue === 'true') && !errorScenario) {
      enableMapView(false);
    } else {
      enableListView(false);
    }
    const dataLayerObj = window.dataLayer ?? {};
    updateDataLayerObjSigin(dataLayerObj, datalayerProperties);
    if (window && window?.dataLayer) {
      window.dataLayer['search_list_setting_sort_order'] =
        (searchResult?.searchCenter?.latitude === null || searchResult?.searchCenter === null) &&
        (!searchQueryState?.search?.latitude || searchQueryState?.search?.latitude === null) &&
        searchQueryState[SearchFacetsConstants.sort]?.fields[1]?.field === 'CITY'
          ? searchQueryState[SearchFacetsConstants.sort]?.fields[1].field?.toLowerCase()
          : searchQueryState[SearchFacetsConstants.sort]?.fields[0].field?.toLowerCase();
    }
  }, [isLoadingSearchResult]);

  useEffect(() => {
    /**
     * Handler for resize event
     */
    if (window && window?.screen?.width && window?.dataLayer) {
      const screenSize = window?.screen?.width;
      if (screenSize < 992) {
        window.dataLayer['is_desktop'] = false;
      } else {
        window.dataLayer['is_desktop'] = true;
      }
    }
    const resizeHandler = (): void => {
      const currentViewIsMobile = CommonUtils.isOnlyMobileView();
      if (!isMobileState) {
        // viewport was not mobile earlier but now its mobile
        if (currentViewIsMobile) {
          setIsMobile(true);
        }
      } else {
        // viewport was mobile earlier but now its not
        if (!currentViewIsMobile) {
          setIsMobile(false);
        }
      }
    };
    window.addEventListener('resize', resizeHandler, { passive: true });
    return (): void => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [isMobileState]);

  const FilterProvideValue = useMemo(() => {
    /**
     * use memo hook to avoid assignment on every render
     */
    return { searchQueryState, setSearchQuery };
  }, [searchQueryState, setSearchQuery]);
  const SearchResultProviderValue = useMemo(() => {
    return { searchResult, setSearchResult };
  }, [searchResult, setSearchResult]);
  const SearchResultErrorProviderValue = useMemo(() => {
    return { searchResultAlerts, setSearchResultAlerts };
  }, [searchResultAlerts, setSearchResultAlerts]);

  // TODO: validate this need to wrap inside a function
  if (isMapViewEnabled && !searchResultAlerts.showPageAndFilter) {
    enableListView(false);
  }

  const setDefaultCurrency = (): void => {
    setSearchQuery({
      type: SearchActions.updateCurrency,
      payload: {
        currentCurrency: DefaultValue,
        currentAppliedCurrency: DefaultValue,
      },
    });
    setInteractionsSelected({ ...interactionsSelected, currencyConversion: true });
    // Update the currency filter to default in the URL
    updateQueryParams(
      {
        selectedCurrency: DefaultValue,
      },
      ['selectedCurrency'],
      headersData?.referer
    );
  };

  const resetAllFilter = (): void => {
    const payload = getSearchFilterClearState();
    setSearchQuery({ type: SearchActions.setApplyFilter, payload: payload });
    setSearchQuery({
      type: SearchActions.setQuery,
      payload: {
        [SearchFacetsConstants.facets]: {
          ...searchQueryState.search[SearchFacetsConstants.facets],
          ...getSearchAppliedFilter(payload, searchQueryState),
        },
      },
    });
    if (wasMapViewEnabled) {
      setISMapViewEnabled(true);
      sessionStorage.setItem('map_view', 'true');
      setWasMapViewEnabled(false);
    }
  };

  const showBannerConditionsInListView = (): boolean => {
    const result = !featureSelected && nightsCount >= 4 && pointsChecked && !isMapViewEnabled;
    return result;
  };

  const showMemberExclusiveBannerInListView = useMemo(
    () => showBannerConditionsInListView(),
    [featureSelected, nightsCount, pointsChecked, isMapViewEnabled]
  );

  const showBannerConditionsInMapView = (): boolean => {
    const result = !featureSelected && nightsCount >= 4 && pointsChecked && isMapViewEnabled;
    return result;
  };

  const showMemberExclusiveBannerInMapView = useMemo(
    () => showBannerConditionsInMapView(),
    [featureSelected, nightsCount, pointsChecked, isMapViewEnabled]
  );

  const isBackendUpdating = useRef<boolean>(false);
  const queue = useRef<any>([]);

  const consumeRevisionToken = () => {
    if (sessionData) {
      setRevisionToken(
        sessionData?.data
          ? sessionData?.data?.AriesCommon?.revisionToken
          : sessionData?.cacheData?.data?.AriesCommon?.revisionToken
      );
      custNumber.current = sessionData?.data ? sessionData?.data?.rewardsId : sessionData?.cacheData?.data?.rewardsId;
      const savedPropertiesListFromSession: Array<string> = sessionData?.data
        ? sessionData?.data?.AriesCommon?.savedProps?.split(',')
        : sessionData?.cacheData?.data?.AriesCommon?.savedProps?.split(',');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const savedPropertiesArrayWithId: any = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      savedPropertiesListFromSession?.map((marsha: any) => savedPropertiesArrayWithId.push({ id: marsha }));
      setSavedProperties(savedPropertiesArrayWithId);
      dispatch({ type: 'populateState', payload: savedPropertiesArrayWithId });
    }
  };

  const initialData = {
    // revisionToken: revisionToken,
    savedPropertiesList: savedProperties,
    triggerSaveProperties: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const savedPropertiesReducer = (dataList: typeof initialData, action: any) => {
    switch (action.type) {
      case 'added': {
        dataList.savedPropertiesList.push({ id: action.payload });
        return { ...dataList, triggerSaveProperties: true };
      }
      case 'removed': {
        dataList.savedPropertiesList = dataList.savedPropertiesList.filter((data: any) => data.id !== action.payload);
        return { ...dataList, triggerSaveProperties: true };
      }
      case 'populateState': {
        dataList.savedPropertiesList = action.payload;
        return { ...dataList, triggerSaveProperties: false };
      }
      case 'populateStateWithCallback': {
        dataList.savedPropertiesList = action.payload;
        return { ...dataList, triggerSaveProperties: true };
      }
      default: {
        return dataList;
      }
    }
  };

  const [savedData, dispatch] = useReducer(savedPropertiesReducer, initialData);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const consumeSavedProperties = async (data: any) => {
    const savedPropertyData = data;
    if (savedPropertyData) {
      if (savedPropertyData?.updateCustomerSavedProperties?.status[0]?.httpStatus === 200) {
        setRevisionToken(savedPropertyData?.updateCustomerSavedProperties?.revisionToken);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const savedPropertiesArrayWithId: any = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        savedPropertyData?.updateCustomerSavedProperties?.savedProperties?.map((idValue: any) =>
          savedPropertiesArrayWithId.push({ id: idValue.id })
        );
        setSavedProperties(savedPropertiesArrayWithId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const marshaList: any = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        savedPropertiesArrayWithId?.map((marsha: any) => marshaList.push(marsha?.id.toLowerCase()));
        const savedPropsSelected = { ...interactionsSelected, savedProperties: true };
        setInteractionsSelected(savedPropsSelected);
        const fieldsWithValuesTrue = fieldsInteractedInSERP(savedPropsSelected);
        const updatedSessionDoc = await updateSessionDocProperties(
          sessionData?.key ??
            sessionData?.data?.sessionToken ??
            sessionData?.cacheData?.data?.sessionToken ??
            sessionData?.sessionToken,
          sessionObject?.accessToken,
          SESSION_APP_GET_CALL_URL,
          SESSION_APP_POST_CALL_URL,
          datalayerParams,
          null,
          marshaList,
          null,
          resolvedUrl,
          headersData,
          query,
          fieldsWithValuesTrue,
          isMapViewEnabled,
          updateSearch
        );
        setSessionData(updatedSessionDoc);
        isBackendUpdating.current = false;
        if (queue.current.length !== 0) {
          dispatch({ type: 'populateStateWithCallback', payload: savedPropertiesArrayWithId });
        } else {
          dispatch({ type: 'populateState', payload: savedPropertiesArrayWithId });
        }
        clearCouchBaseCache(sessionObject?.consumerID, CACHE_CLEAR_URL, loggerApiURL);
      }
    }
  };

  //formation of update properties query
  const [updateProperties] = useMutation(phoenixShopUpdateCustomerSavedProperties, {
    onCompleted: data => {
      consumeSavedProperties(data);
    },
    // notifyOnNetworkStatusChange: true, // did the work
    onError: error => {
      Axios.post(loggerApiURL, {
        message: `update property error for sessionId ${sessionID}: ${JSON.stringify(error)}`,
      });
    },
  });

  useEffect(() => {
    if (sessionObject?.consumerID && sessionObject?.accessToken && savedData.triggerSaveProperties) {
      //removing dulicate values from array which was causing the UXL call to break
      const uniqueSavedProperties = savedData?.savedPropertiesList?.filter((obj: { id: any }, index: number) => {
        return index === savedData?.savedPropertiesList?.findIndex((o: { id: any }) => obj.id === o.id);
      });
      if (!isBackendUpdating.current) {
        isBackendUpdating.current = true;

        const params = {
          input: {
            id: sessionObject?.consumerID,
            revisionToken: revisionToken,
            savedProperties: queue.current.length === 0 ? uniqueSavedProperties : queue.current.shift(),
          },
        };

        updateProperties({
          variables: {
            ...params,
          },
          context: {
            headers: {
              'x-request-id': requestID,
            },
          },
        });
      } else {
        queue.current.push(uniqueSavedProperties);
      }
    }
  }, [savedData]);
  useEffect(() => {
    eventUtil.on('changeZIndex', value => {
      if (document) {
        const filterEle = document.getElementsByClassName(
          'filters-container filters-map-view-container'
        ) as HTMLCollectionOf<HTMLElement>;
        const buttonEle = document.getElementsByClassName('drawer-icon-container') as HTMLCollectionOf<HTMLElement>;
        if (value && filterEle && buttonEle && filterEle.length) {
          filterEle[0]?.style.setProperty('z-index', '1');
          buttonEle[0]?.style.setProperty('z-index', '0', 'important');
        } else {
          filterEle[0]?.style.setProperty('z-index', '2');
          buttonEle[0]?.style.setProperty('z-index', '1');
        }
      }
    });
    return (): void => {
      eventUtil.remove('changeZIndex', () => {});
    };
  }, []);

  return (
    <renderedData.Provider value={value}>
      <SearchResultContext.Provider value={SearchResultProviderValue}>
        <SearchResultAlertContext.Provider value={SearchResultErrorProviderValue}>
          <SavedPropertiesDispatchContext.Provider value={dispatch}>
            {/* show loader on click update search and return response upon successful UXL call */}
            {searchResultAlerts?.showPageAndFilter || isLoadingSearchResult ? (
              <SearchFilterContext.Provider value={FilterProvideValue}>
                <StyledSearchResultContainerDiv
                  className={clsx(
                    isMapViewEnabled ? 'map-view color-scheme3' : 'list-view color-scheme3 ',
                    !searchResultAlerts.showPageAndFilter || searchResult.total === 0 ? 'no-map-button' : '',
                    segmentId ? segmentId : DEFAULT_SORT_OPTS
                  )}
                >
                  <>
                    <StyledFilterContainerDiv
                      className={
                        isMapViewEnabled ? 'filters-container filters-map-view-container' : 'filters-container'
                      }
                    >
                      <FiltersComponent
                        filterUpdateData={filterUpdateData}
                        isStateSearch={isStateSearch}
                        isDestinationSearch={isDestinationSearch}
                        className="filter-component"
                      />
                      {showMemberExclusiveBannerInListView && (
                        <MemberExclusiveBanner numberOfNights={nightsCount} pointsChecked={pointsChecked} />
                      )}
                      {showMemberExclusiveBannerInMapView && !viewportXl && (
                        <MemberExclusiveBanner numberOfNights={nightsCount} pointsChecked={pointsChecked} />
                      )}
                      {!isMapViewEnabled && !errorScenario && (
                        <PaginationSortByComponent
                          isMobileState={isMobileState}
                          className="pagination-sort-container search-results"
                          segmentId={segmentId}
                          isLatLongAvailable={isStateSearch}
                        />
                      )}
                      {!isMapViewEnabled &&
                      searchResultAlerts?.errorMessage &&
                      searchResultAlerts?.showPageAndFilter &&
                      !isUnsuccessfulUserJourney() ? (
                        <AlertNotification
                          setLinkClick={
                            searchQueryState.currentCurrency === DefaultValue ? resetAllFilter : setDefaultCurrency
                          }
                          clickTrackingText={`Phoenix Search Results | ${
                            searchResultAlerts?.type === 'no-hotels'
                              ? 'Hotel Directory'
                              : searchResultAlerts?.type === 'warning'
                              ? 'Clear all filters'
                              : 'Alert: Set default currency link clicked'
                          } | internal`}
                        />
                      ) : (
                        ''
                      )}
                      {!isMapViewEnabled &&
                      searchResultAlerts?.errorMessage &&
                      searchResultAlerts?.showPageAndFilter &&
                      isUnsuccessfulUserJourney() ? (
                        <Messages
                          className="unsuccess-full-journey"
                          messageType="warning"
                          messageVariation={false}
                          messageHeading={searchResultAlerts?.heading}
                          messageText={searchResultAlerts?.errorMessage}
                        />
                      ) : (
                        ''
                      )}
                    </StyledFilterContainerDiv>
                    {hidePropertyList ? (
                      <SkeletonPropertyCardLoaderComponent
                        isViewportL={viewportL}
                        isInitialLoad={isInitialLoad}
                        isMapViewEnabled={isMapViewEnabled}
                        segmentId={segmentId}
                        isLatLongAvailable={isStateSearch}
                      />
                    ) : (
                      <>
                        <div className="search-form-container">
                          <StyledSearchResultPropertyContainer
                            className={
                              isMapViewEnabled
                                ? clsx(
                                    'search-results-container',
                                    'color-scheme1',
                                    'search-map-view-result-container',
                                    isDrawerVisible ? 'active-drawer' : '',
                                    popPupOpen ? 'map-popup-position' : ''
                                  )
                                : clsx('search-results-container', isLoadingSearchResult ? 'hide-property-list' : '')
                            }
                          >
                            <PropertyListComponent
                              isMobileState={isMobileState}
                              memberExclusiveBannerDetails={{
                                featureSelected: featureSelected,
                                numberOfNights: nightsCount,
                                pointsChecked: pointsChecked,
                              }}
                              alertLinkClickFn={
                                searchQueryState.currentCurrency === DefaultValue ? resetAllFilter : setDefaultCurrency
                              }
                              handlePopUpOpen={setPopupOpen}
                              enableListView={enableListView}
                              handleNextClick={handleNextClick}
                              isDrawerVisible={isDrawerVisible}
                              segmentId={segmentId}
                              isLatLongAvailable={isStateSearch}
                            />
                          </StyledSearchResultPropertyContainer>
                          {isLoadingSearchResult && (
                            <SkeletonPropertyCardLoaderComponent
                              isViewportL={viewportL}
                              isInitialLoad={isInitialLoad}
                              isMapViewEnabled={isMapViewEnabled}
                              segmentId={segmentId}
                              isLatLongAvailable={isStateSearch}
                            />
                          )}
                          {isMapViewEnabled && (
                            <button
                              type="button"
                              className={`drawer-icon-container color-scheme1 analytics-click custom_click_track ${
                                isDrawerVisible && 'active-drawer hide-drawer'
                              }`}
                              aria-label={jsonContent.propertyCardDrawerLabel}
                              onClick={(): void => setIsDrawerVisible(!isDrawerVisible)}
                              {...{
                                custom_click_track_value: `${clickTrackingLoc}| Property Card Drawer ${
                                  isDrawerVisible ? 'Opened' : 'Closed'
                                } |internal`,
                              }}
                              tabIndex={0}
                            >
                              <div className={`icon-arrow-${isDrawerVisible ? 'left' : 'right'} first`} />
                              <div className={`icon-arrow-${isDrawerVisible ? 'left' : 'right'} second`} />
                            </button>
                          )}
                        </div>
                        {!isMapViewEnabled && (
                          <div className="map-button-container container fixed-button">
                            <button
                              className="map-button m-button-primary-inverse custom_click_track"
                              // custom_click_track_value={`${clickTrackingLoc}|  back to map view from list view |internal`}
                              {...{ custom_click_track_value: `QuickFilters| map | internal` }}
                              onClick={() => enableMapView(true)}
                            >
                              <span className="icon icon-map"></span> {jsonContent?.mapLabel}
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                </StyledSearchResultContainerDiv>
              </SearchFilterContext.Provider>
            ) : (
              <StyledAlertNotificationDiv>
                <AlertNotification isNonPropertyList={true} isAloneOnPage={true} />
              </StyledAlertNotificationDiv>
            )}
          </SavedPropertiesDispatchContext.Provider>
        </SearchResultAlertContext.Provider>
      </SearchResultContext.Provider>
    </renderedData.Provider>
  );
};

export default SearchResultComp;
