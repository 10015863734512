/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCurrentDateObject, getDateObj, getNextDateObject } from '@marriott/mi-ui-library';
import {
  SPECIAL_RATES,
  DATES,
  DESTINATION,
  ROOMS_AND_GUESTS,
  USE_POINTS,
  DESTINATION_HIDDEN_FIELDS,
  LATITUDE_AND_LONGITUDE,
  BRANDS,
  ERROR_MESSAGE,
  MEETINGS_DESTINATION,
  MEETINGS_DATES,
} from '../../modules/store/store.constants';
import { MAX_NUMBER_OF_NIGHTS_ALLOWED } from '../constants/Calendar.constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDestinationText = (searchCriteria: any) => {
  const { destinationAddressMainText, destinationAddressSecondaryText, address, airportCode, poiName } = searchCriteria;
  if (address?.destination) {
    return address?.destination;
  } else if (destinationAddressMainText && destinationAddressSecondaryText) {
    return `${destinationAddressMainText}, ${destinationAddressSecondaryText}`;
  } else {
    return `${airportCode}, ${poiName}`;
  }
};

export const invalidDates = (search_date_check_in: string, search_date_check_out: string) => {
  // Function to check if a date string is valid
  const isValidDate = (dateString: any) => {
    return !isNaN(Date?.parse(dateString));
  };

  // Extract fromDate and toDate from the session
  const fromDate = search_date_check_in;
  const toDate = search_date_check_out;
  const bothDatesNullOrEmpty = (fromDate === null || fromDate === '') && (toDate === null || toDate === '');

  // Check if both fromDate and toDate are present in the URL
  if (fromDate !== null && toDate !== null) {
    // Check if the dates are invalid
    const fromDateInvalid = !isValidDate(fromDate);
    const toDateInvalid = !isValidDate(toDate);
    // Set a flag based on the validity of fromDate and toDate
    const invalidDatesFlag = fromDateInvalid || toDateInvalid;
    // Trigger the function only if there are invalid dates
    if (invalidDatesFlag) {
      return invalidDatesFlag;
    }
  } else if (bothDatesNullOrEmpty) {
    return true;
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function initializeSearchForm(data: any, modelData: any): any {
  const { AriesSearch, AriesCommon } = data || {};
  let sessionObj = {};
  if ((!AriesSearch && !AriesCommon) || (!AriesSearch && AriesCommon)) {
    return sessionObj;
  }
  let invalidDate: any = '';
  const search_date_check_in = AriesCommon?.search_date_check_in;
  const search_date_check_out = AriesCommon?.search_date_check_out;

  const { searchCriteria } = AriesSearch;
  const { appliedCssClassNames } = modelData;
  let errorMessages: any = '';
  if (AriesSearch && AriesSearch?.errorMessages && appliedCssClassNames) {
    errorMessages = AriesSearch?.errorMessages;
    invalidDate = invalidDates(search_date_check_in, search_date_check_out);
  }

  const rk_clusterCode = AriesCommon?.rk_clusterCode;
  const isRewardRedemption = AriesCommon?.isRewardRedemption;
  const search_isFlexibleDate = AriesCommon?.search_isFlexibleDate;
  const clustCode = AriesCommon?.clustCode;
  const res_num_adults = AriesCommon?.res_num_adults;
  const res_num_children = AriesCommon?.res_num_children;

  let fromDate;
  let toDate;
  let flexible;
  let numOfNights;
  const currentDate = getCurrentDateObject();
  const nextDate = getNextDateObject(currentDate);

  if (search_isFlexibleDate === true || search_isFlexibleDate === 'true') {
    flexible = true;
    fromDate = search_date_check_in && getDateObj(new Date(search_date_check_in));
    toDate = search_date_check_out && getDateObj(new Date(search_date_check_out));
    numOfNights = AriesCommon?.lengthOfStay;
  } else if (
    search_date_check_in &&
    search_date_check_out &&
    (search_isFlexibleDate === false || search_isFlexibleDate === 'false')
  ) {
    if (invalidDate) {
      flexible = false;
      fromDate = null;
      toDate = null;
      numOfNights = null;
    } else {
      flexible = false;
      fromDate = search_date_check_in && getDateObj(new Date(search_date_check_in));
      toDate = search_date_check_out && getDateObj(new Date(search_date_check_out));
      numOfNights = AriesCommon?.lengthOfStay;
    }
  } else if (AriesSearch && AriesSearch?.errorMessages && invalidDate) {
    flexible = false;
    fromDate = null;
    toDate = null;
    numOfNights = null;
  }

  let numbRooms = '';
  const { roomListLabel } = modelData;
  const tempArr = roomListLabel?.split(', ');
  const roomCounts = tempArr?.filter((item: string) => {
    return item?.includes(AriesCommon?.numRooms);
  });
  if (AriesCommon?.numRooms) {
    if (AriesCommon?.numRooms === 4) {
      numbRooms = roomCounts?.length ? roomCounts[0] : '4-9';
    } else if (AriesCommon?.numRooms === 10) {
      numbRooms = roomCounts?.length ? roomCounts[0] : '10-25';
    } else if (AriesCommon?.numRooms === 26) {
      numbRooms = roomCounts?.length ? roomCounts[0] : '26+';
    } else {
      numbRooms = AriesCommon?.numRooms;
    }
  }
  let corporate_code = '';
  if (
    rk_clusterCode &&
    (rk_clusterCode === 'CORP' || rk_clusterCode === 'GOV' || rk_clusterCode === 'AAA' || rk_clusterCode === 'NONE')
  ) {
    corporate_code = rk_clusterCode.toLowerCase();
  } else {
    corporate_code = rk_clusterCode ? rk_clusterCode : 'none';
  }
  let rewardsRed = false;
  if (isRewardRedemption && isRewardRedemption === 'true') {
    rewardsRed = true;
  } else {
    rewardsRed = false;
  }

  // Check if searchCriteria is present in session document
  if (AriesSearch && AriesSearch?.errorMessages && AriesCommon) {
    let destination = AriesSearch?.search_keyword && AriesSearch?.search_keyword;
    let locationBlank = true;
    if (
      errorMessages?.submitSearchFormErrorMessages?.errorMessageKeys[0] ===
      'search-error-messages|location.blank.invalid'
    ) {
      destination = '';
      locationBlank = false;
    }
    if (!AriesSearch?.search_keyword) {
      if (locationBlank) {
        return (sessionObj = {
          [ERROR_MESSAGE]: {
            errorMessages: errorMessages,
          },
        });
      } else {
        return (sessionObj = {
          [ERROR_MESSAGE]: {
            errorMessages: errorMessages,
          },
          [DESTINATION]: {
            displayText: destination,
          },
          [DATES]: {
            lastDefaultDate: search_date_check_in,
            fromDate,
            toDate,
            flexible,
            search_date_check_in,
            search_date_check_out,
            lengthOfStay: numOfNights,
            numberOfNights: numOfNights,
          },
        });
      }
    } else if (
      AriesSearch &&
      AriesSearch?.errorMessages &&
      rk_clusterCode &&
      search_date_check_in &&
      res_num_adults &&
      AriesSearch?.search_keyword
    ) {
      return (sessionObj = {
        [ERROR_MESSAGE]: {
          errorMessages: errorMessages,
        },
        [DESTINATION]: {
          displayText: destination,
        },
        [DATES]: {
          lastDefaultDate: search_date_check_in,
          fromDate,
          toDate,
          flexible,
          search_date_check_in,
          search_date_check_out,
          lengthOfStay: numOfNights,
          numberOfNights: numOfNights,
        },
        [MEETINGS_DESTINATION]: {
          eventsDisplayText: destination,
        },
        [MEETINGS_DATES]: {
          eventsLastDefaultDate: search_date_check_in,
          eventsFromDate: fromDate,
          eventsToDate: toDate,
          numberOfNights: numOfNights,
        },
        [ROOMS_AND_GUESTS]: {
          numRooms: numbRooms,
          numAdultsPerRoom: res_num_adults,
          numChildrenPerRoom: res_num_children ? res_num_children : 0,
          childrenAges: AriesCommon?.childrenAges ? AriesCommon?.childrenAges : [],
        },
        [USE_POINTS]: {
          rewardsRedemption: rewardsRed,
        },
        [SPECIAL_RATES]: {
          clusterCode: rk_clusterCode?.toLocaleLowerCase(),
          specialRateCode: corporate_code,
          corporateCode: clustCode ? clustCode : '',
        },
        [BRANDS]: {
          marriottBrands: AriesCommon?.brandCodes ? AriesCommon?.brandCodes : '',
        },
      });
    } else if (AriesSearch && AriesSearch?.errorMessages) {
      return (sessionObj = {
        [ERROR_MESSAGE]: {
          errorMessages: errorMessages,
        },
      });
    } else {
      return sessionObj;
    }
  } else if (AriesSearch && !AriesSearch?.errorMessages && !AriesSearch?.searchCriteria) {
    return (sessionObj = {
      [ERROR_MESSAGE]: {
        errorMessages: '',
      },
    });
  }
  const {
    availabilityRequestVO,
    destinationAddressPlaceId,
    keywordSearchRequestVO,
    searchType,
    address,
    destinationAddressMainText,
    destinationAddressSecondaryText,
    searchRadius,
    brandCodes,
  } = searchCriteria;
  const {
    checkInDate,
    checkOutDate,
    flexibleDate,
    lengthOfStay,
    numRooms,
    numAdultsPerRoom,
    numChildrenPerRoom,
    childrenAges,
    rewardsRedemption,
    clusterCode,
    groupCode,
  } = availabilityRequestVO;
  let { corporateCode } = availabilityRequestVO;
  let specialRateCode = clusterCode;
  /* istanbul ignore else */
  if (specialRateCode === 'AAA' || specialRateCode === 'GOV' || specialRateCode === 'NONE') {
    specialRateCode = specialRateCode?.toLocaleLowerCase();
  }
  if (specialRateCode?.toUpperCase() === 'GROUP' && groupCode) {
    specialRateCode = specialRateCode?.toLocaleLowerCase();
    corporateCode = groupCode;
  }
  let latitude;
  let longitude;

  /* istanbul ignore else */
  if (address?.latitude && address?.longitude) {
    latitude = address?.latitude;
    longitude = address?.longitude;
  }

  /*
    If check-in and check-out dates are missing and flexibleDates flag is false,
    then it will be considered as 1 night flexible dates flow for current month
  */
  if (!checkInDate && !checkOutDate && flexibleDate === false) {
    flexible = true;
    fromDate = currentDate;
    toDate = nextDate;
    numOfNights = 1;
  } else {
    flexible = flexibleDate;
    fromDate = getDateObj(checkInDate);
    toDate = getDateObj(checkOutDate);
    numOfNights = flexibleDate ? (lengthOfStay > MAX_NUMBER_OF_NIGHTS_ALLOWED ? 1 : lengthOfStay) : lengthOfStay;
  }

  if ((destinationAddressPlaceId || address?.destination) && !AriesSearch?.errorMessages) {
    sessionObj = {
      [DESTINATION]: {
        displayText: getDestinationText(searchCriteria),
        destinationAddressPlaceId: destinationAddressPlaceId,
      },
      [DATES]: {
        lastDefaultDate: checkInDate,
        fromDate,
        toDate,
        flexible,
        checkInDate,
        checkOutDate,
        lengthOfStay: numOfNights,
        numberOfNights: numOfNights,
      },
      [ROOMS_AND_GUESTS]: {
        numRooms: numRooms,
        numAdultsPerRoom: numAdultsPerRoom,
        numChildrenPerRoom: numChildrenPerRoom,
        childrenAges: childrenAges,
      },
      [DESTINATION_HIDDEN_FIELDS]: {
        latitude: latitude,
        longitude: longitude,
        destinationAddressMainText: destinationAddressMainText,
        destinationAddressSecondaryText: destinationAddressSecondaryText,
        city: address?.city,
        state: address?.stateProvince,
        country: address?.country,
        searchRadius: searchRadius,
      },
      [LATITUDE_AND_LONGITUDE]: {
        latitude: latitude,
        longitude: longitude,
      },
      [USE_POINTS]: {
        rewardsRedemption: rewardsRedemption,
      },
      [BRANDS]: {
        marriottBrands: brandCodes,
      },
      [SPECIAL_RATES]: {
        clusterCode: clusterCode,
        specialRateCode: specialRateCode,
        corporateCode: corporateCode,
      },
      [ERROR_MESSAGE]: {
        errorMessages: '',
      },
    };
  }

  if (searchType === 'Keyword' && !AriesSearch?.errorMessages) {
    sessionObj = {
      [DESTINATION]: {
        displayText: `${keywordSearchRequestVO?.keywords}`,
      },
      [DATES]: {
        flexibleDate: true,
      },
      [USE_POINTS]: {
        rewardsRedemption: rewardsRedemption,
      },
      [BRANDS]: {
        marriottBrands: brandCodes,
      },
      [SPECIAL_RATES]: {
        clusterCode: clusterCode,
        specialRateCode: specialRateCode,
        corporateCode: corporateCode,
      },
      [ERROR_MESSAGE]: {
        errorMessages: '',
      },
    };
  }

  return sessionObj;
}

export function searchFormHelperFunction(
  state: Record<string, any>,
  fieldNames: Array<string>,
  fieldsData: Record<string, any>,
  reset?: boolean
): object {
  const updatedState = state;
  //to reset everything inside the given field , this will not reset the whole store but only reset the given fieldname
  if (reset) {
    fieldNames.forEach((name: string) => {
      updatedState[name] = {};
    });
  }
  fieldNames.forEach((name: string) => {
    updatedState[name] = { ...updatedState[name], ...fieldsData[name] };
  });
  return updatedState;
}

/* istanbul ignore next */
export const constructSearchformLabels = (model: any) => {
  if (model) {
    const isTabbedSearchForm =
      typeof model?.['appliedCssClassNames'] === 'string' && model?.['appliedCssClassNames'].toLowerCase() === 'phoenix'
        ? true
        : false;

    model['isTabbedSearchForm'] = isTabbedSearchForm;
    model['submitAction'] = model['submitAction'] ?? 'default';

    model['submitSpecificDateCta'] = model['submitSpecificDateCta'] ?? model['dateModuleSubmitCta'];
    model['flexibleDatesSubmitCta'] = model['flexibleDatesSubmitCta'] ?? model['flexibleSearchSubmitCTA'];
    model['resetSpecificDateCta'] = model['resetSpecificDateCta'] ?? model['dateModuleResetCta'];
    model['flexibleDatesResetCta'] = model['flexibleDatesResetCta'] ?? model['reset'];

    model['enableWeekendSelectorOnMobile'] = model['enableWeekendSelectorOnMobile'] ?? model['enableWeekendSelector'];
    model['dateModalHeader'] = model['dateModalHeader'] ?? model['stayDates'];
    model['destinationModalHeader'] = model['destinationModalHeader'] ?? model['placeholderTextMobile'];

    model['nameOfRecentlyViewedSection'] =
      model['nameOfRecentlyViewedSection'] ?? model['placeholderTextrecentlyViewedLabel'];
    model['nameOfRecentSearchesSection'] =
      model['nameOfRecentSearchesSection'] ?? model['placeholderTextRecentSearchesLabel'];
    model['nameOfPopularDestinationSection'] =
      model['nameOfPopularDestinationSection'] ?? model['trendingDestinationsLabel'];
    model['nightLabel'] = model['nightLabel'] ?? model['night'];
    model['night'] = model['night'] ?? model['nightLabel'];
    model['roomsAndGuestEyebrowText'] = model['roomsAndGuestEyebrowText'] ?? model['roomsAndGuestEybrowText'];
    model['placeholderTextMobile'] = model['placeholderTextMobile'] ?? model['expandedHelperTextMobile'];
    model['findHotelLabel'] = model['findHotelLabel'] ?? model['submitCTA'];
  }
};
//function to check if recent view and recent searches are available on localstorgae
export const isRecentViewAndSearchAvailable = (isServer: any) => {
  const getRecentSearchList: string | null = !isServer ? localStorage.getItem('miRecentSearch') : null;
  const recentSearchesAll: Array<Record<string, string>> = getRecentSearchList && JSON.parse(getRecentSearchList);
  //this function will fetch the last 3 recently searched destinations from localstorage
  const recentSearches: Array<Record<string, string>> = recentSearchesAll?.slice(-3);
  if (recentSearches && recentSearches.length > 0) {
    const nonPastDateSearches = recentSearches.filter(item => {
      //converting date to hours(0,0,0,0) to avoid time comparision , only dates are being considered
      return new Date(item['fromDate']).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    });
    if (nonPastDateSearches && nonPastDateSearches.length) {
      const nonFlexibleSearches = nonPastDateSearches;
      if (nonFlexibleSearches?.length) {
        return true;
      }
    }
  }
  const getRecentViewList: string | null = !isServer ? localStorage.getItem('miRecentlyViewedProperties') : null;
  const recentlyViewedAll: Array<Record<string, string>> =
    getRecentViewList && JSON.parse(getRecentViewList)?.recentlyViewedProperties;
  //this function will fetch the last 3 recently viewed properties from localstorage
  const recentlyViewed: Array<Record<string, string>> = recentlyViewedAll?.slice(0, 3);
  if (recentlyViewed && recentlyViewed.length > 0) {
    return true;
  }
  return false;
};
