/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useMemo, useRef, useContext, createContext } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CardHorizontalMini, Types, Link } from '@marriott/mi-ui-library';
import { StyledRecommendedMiniCard } from './RecommendedMiniCards.styles';
import Glide from '@glidejs/glide';
import clsx from 'clsx';
import { trackImpression, useGetBreakpoint, recommendationFilters } from '../../modules/utils/helpers';
import {
  TRACKING_CONSTANT,
  RECOMMENDED_RESULT_LIMIT,
  PAGES,
  searchResultsPageUrl,
} from '../../modules/utils/constants';
import { useLazyQuery } from '@apollo/client';
import { searchRestaurantsByGeolocation, similarRestaurants } from '@marriott/mi-rnb-graphql';
import { outletDetailsState, useStore } from '../../modules/store/outletDetailsStore';
import SearchResultsFilterData from '../../modules/_localDevMocks/SearchResultsFiltersMock.json';
import Cookies from 'js-cookie';
import { PageParamsContext } from '../../modules/context/PageContext';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const RecommendedMiniCards = (props: any) => {
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const [isTabletViewPort, setIsTabletViewPort] = useState(useGetBreakpoint() === 'tablet');
  const glideRef = useRef<HTMLDivElement | any>(null);
  let index: number;
  const IMAGE_URL_DOMAIN = 'https://cache.marriott.com';
  const {
    OUTLET_RECOMMENDATION_SECTION,
    HOME_PAGE_RECOMMENDATION_SECTION,
    EXPLORE_NEARBY,
    MINI_CARD,
    EXTERNAL_LINK,
    CITY_PAGE,
  } = TRACKING_CONSTANT;
  const { currentPage, recommendationData } = useContext(PageParamsContext);

  const location =
    currentPage === PAGES.OUTLET_DETAILS_PAGE
      ? OUTLET_RECOMMENDATION_SECTION
      : currentPage === PAGES.HOMEPAGE
      ? HOME_PAGE_RECOMMENDATION_SECTION
      : CITY_PAGE;
  const isServer = !(typeof window != 'undefined' && window.document);
  const outletData = useStore((state: outletDetailsState) => state.outletData);
  const [linkValue, setLinkValue] = useState('');
  const [miniCardData, setMiniCardData] = useState<any[]>();
  const [restaurantData, setRestaurantData] = useState<Record<string, any>>();
  const outletDataError = useStore((state: outletDetailsState) => state.outletDataError);
  const outletDataLoading = useStore((state: any) => state.outletDataLoading);
  const fallBackImage = props.dynamicMedia?.assetPath ? `${props.dynamicMedia.assetPath}?downsize=*:100` : '';
  const { outletId, backgroundColor } = useContext(PageParamsContext);

  const pageContext = useContext(createContext<any>({}));
  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');
  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsTabletViewPort(useGetBreakpoint() === 'tablet');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (recommendationData?.['length']) {
      setRestaurantData(recommendationData);
      setMiniCardData(recommendationData);
    }
  }, [recommendationData]);

  const query = props?.recommendationTypes === 'geosearch' ? searchRestaurantsByGeolocation : similarRestaurants;
  const [loadMiniCardData, { loading, error, data }] = useLazyQuery(query);

  useEffect(() => {
    if (currentPage !== PAGES.CITYPAGE) {
      let latitude, longitude, searchTerm;
      if (
        props?.recommendationTypes === 'geosearch' &&
        !props?.outletDetailPage &&
        props?.latitude &&
        props?.longitude
      ) {
        latitude = props?.latitude;
        longitude = props?.longitude;
      } else if (props?.recommendationTypes === 'itemtoitem' && props?.outletDetailPage && outletData) {
        searchTerm = outletData?.contactInfo?.address?.city
          ? outletData?.contactInfo?.address?.city
          : outletData?.contactInfo?.address?.state?.description
          ? outletData?.contactInfo?.address?.state?.description
          : outletData?.contactInfo?.address?.country?.description
          ? outletData?.contactInfo?.address?.country?.description
          : '';
      } else if (props?.recommendationTypes === 'geosearch' && props?.outletDetailPage && outletData) {
        latitude = outletData?.properties?.edges[0]?.node?.property?.basicInformation?.latitude;
        longitude = outletData?.properties?.edges[0]?.node?.property?.basicInformation?.longitude;
        searchTerm = outletData?.contactInfo?.address?.city
          ? outletData?.contactInfo?.address?.city
          : outletData?.contactInfo?.address?.state?.description
          ? outletData?.contactInfo?.address?.state?.description
          : outletData?.contactInfo?.address?.country?.description
          ? outletData?.contactInfo?.address?.country?.description
          : '';
      }

      const geoSearchVariable = {
        search: {
          latitude: latitude,
          longitude: longitude,
        },
        limit: RECOMMENDED_RESULT_LIMIT,
      };

      const restaurantId = props?.outletDetailPage
        ? props?.outletId
          ? props?.outletId || ''
          : outletId || ''
        : props?.outletId || '';

      const itemSearchVariable = {
        input: {
          restaurantId: restaurantId?.toString(),
          filters: recommendationFilters(props),
        },
        limit: RECOMMENDED_RESULT_LIMIT,
      };

      const queryVariable = props?.recommendationTypes === 'geosearch' ? geoSearchVariable : itemSearchVariable;

      const getRecommendMiniCardsData = () => {
        loadMiniCardData({
          fetchPolicy: isServer ? 'network-only' : 'cache-first',
          variables: queryVariable,

          context: {
            headers: {
              // Any header, including x-request-id, can be passed in args with query.
              // If you don't pass it in the authLink will generate a random ID.
              'accept-language': 'en-US',
              'x-request-id': requestId,
              'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
              'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
              'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            },
          },
        });
      };
      // To do Add condition to check props?.outletId also

      if (!props?.isAuthorMode && ((!!latitude && !!longitude) || !!restaurantId)) {
        if (props?.outletDetailPage) {
          if (!outletDataError && !outletDataLoading) {
            getRecommendMiniCardsData();
          } else return;
        } else {
          getRecommendMiniCardsData();
        }
      }
      if (props?.outletDetailPage && searchTerm) {
        if (props?.recommendationTypes === 'geosearch') {
          setLinkValue(`${searchResultsPageUrl}?term=${searchTerm}&lat=${latitude}&long=${longitude}`);
        } else {
          let filters = '';
          filters +=
            props?.isState && outletData?.contactInfo?.address?.state?.description
              ? `&stateProvinceDescription=${outletData?.contactInfo?.address?.state?.description}`
              : '';
          filters +=
            props?.isCuisine && outletData?.diningExperience?.cuisine?.length === 1
              ? `&cuisine=${outletData?.diningExperience?.cuisines[0]}`
              : '';
          filters +=
            props?.isCountry && outletData?.contactInfo?.address?.country?.description
              ? `&countryDescription=${outletData?.contactInfo?.address?.country?.description}`
              : '';
          setLinkValue(`${searchResultsPageUrl}?term=${searchTerm}&type=ots${filters}`);
        }
      }
    }
  }, [props, outletData, outletId, outletDataError, recommendationData]);

  useEffect(() => {
    if (!recommendationData?.length && currentPage !== PAGES.CITYPAGE) {
      if (props?.recommendationTypes === 'geosearch') {
        setRestaurantData(data?.searchRestaurantsByGeolocation?.edges);
        setMiniCardData(data?.searchRestaurantsByGeolocation?.edges);
      } else if (props?.recommendationTypes === 'itemtoitem') {
        setRestaurantData(data?.searchRecommendations?.similarRestaurants?.edges);
        setMiniCardData(data?.searchRecommendations?.similarRestaurants?.edges);
      }
    }
  }, [data, recommendationData]);

  useEffect(() => {
    if (props.isAuthorMode) {
      const restaurantData = SearchResultsFilterData?.data?.searchRestaurantsByGeolocation?.edges?.slice(
        0,
        RECOMMENDED_RESULT_LIMIT
      );
      setRestaurantData(restaurantData);
      setMiniCardData(restaurantData);
    }
  }, [props?.isAuthorMode]);

  useEffect(() => {
    if (miniCardData && miniCardData?.length > 2) {
      const glide = new Glide(glideRef?.current, {
        type: 'slider',
        gap: 0,
        startAt: 0,
        focusAt: 'center',
        autoplay: false,
        bound: false,
        rewind: false,
        dragThreshold: false,
        perView: 1,
        peek: {
          before: 0,
          after: 0,
        },
        keyboard: false,
      });

      const glideOnRun = () => {
        index = glideRef?.current
          ?.querySelector('.glide__slide--active .glide__slide')
          ?.getAttribute('data-glide-index');
        if (Number(index) === 0) {
          setIsFirstSlide(true);
          setIsLastSlide(false);
        } else if (Number(index) === miniCardData?.length - 3) {
          setIsFirstSlide(false);
          setIsLastSlide(true);
        } else {
          setIsFirstSlide(false);
          setIsLastSlide(false);
        }
      };

      glide?.on('run.after', glideOnRun);

      if (restaurantData && restaurantData?.['length'] > 2) {
        glide?.mount();
      }
    }
  }, [miniCardData]);

  useEffect(() => {
    if (props?.trackingProperties?.impressionTrack)
      trackImpression(props?.trackingProperties || {}, 'Recommended Section');
  }, [props?.trackingProperties]);

  const getOutletTitle = (title: string) => {
    if (title?.length > 40) {
      return title.slice(0, 40) + '[...]';
    } else {
      return title;
    }
  };

  if (loading || error) return null;
  if (restaurantData && restaurantData?.['length'] > 2) {
    return (
      <StyledRecommendedMiniCard
        data-component-name="m-rnb-RecommendedMiniCards"
        data-testid="rnb-RecommendedMiniCards"
        isOutletPage={props?.outletDetailPage}
        isfallBackImage={fallBackImage}
        bgColor={backgroundColor}
        className={clsx('mx-md-0', props?.outletDetailPage ? 'recommended-mini-card-wrapper d-none ' : '')}
      >
        {props?.title && <div className="t-title-s text-center miniCard-main-title">{props?.title}</div>}
        <div className="container p-0">
          <div className="recommend-title-rnb d-flex flex-wrap m-0  pl-3 pl-sm-2 align-items-center">
            {props?.outletDetailPage ? (
              linkValue ? (
                <Link
                  linkHref={linkValue}
                  linkClassName="t-font-m custom_click_track explore-link"
                  target={props?.openInNewTab ? '_blank' : ''}
                  rel="noreferrer"
                  custom_click_track_value={`${location}|${EXPLORE_NEARBY}|${EXTERNAL_LINK}`}
                  text={props?.linkText}
                ></Link>
              ) : (
                <span>{props?.linkText}</span>
              )
            ) : (
              <Link
                linkHref={props?.linkUrl}
                linkClassName="t-font-m custom_click_track explore-link"
                target={props?.openInNewTab ? '_blank' : ''}
                rel="noreferrer"
                custom_click_track_value={`${location}|${EXPLORE_NEARBY}|${EXTERNAL_LINK}`}
                text={props?.linkText}
              ></Link>
            )}
            <span className="icon-arrow-right-cropped pl-3 t-font-s"></span>
          </div>
          <div
            className={isMobileViewPort || isTabletViewPort ? 'd-none' : 'd-flex flex-wrap minicard-desktop'}
            data-testid="miniCard-desktop-view"
          >
            {miniCardData?.map((res: any, index: number) => {
              const isFallback = !res?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls?.square;
              const imageData = {
                altText: res[index]?.node?.name,
                assetPath: res?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls?.square
                  ? `${IMAGE_URL_DOMAIN}${res?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls?.square}?downsize=100:*`
                  : `${props?.dynamicMedia?.assetPath}?downsize=100:*`,
                dynamic: false,
                renditions: [],
              };
              const styleClass =
                !!props?.iconPath && isFallback
                  ? 'miniCard-border-desktop-rnb my-2 mx-0 fallback-image'
                  : 'miniCard-border-desktop-rnb my-2 mx-0';

              return (
                <div
                  className="col-lg-4 col-md-6 col-12 custom_click_track"
                  data-custom_click_track_value={`${location}|${MINI_CARD}|${EXTERNAL_LINK}`}
                  key={`recommendation-mini-card-${index}`}
                >
                  <CardHorizontalMini
                    openInaNewTab={true}
                    ctaLink={`/en-us/dining/${res?.node?.seoUrl}.mi`}
                    header={getOutletTitle(res?.node?.name)}
                    headerShort={res?.node?.name}
                    headerTag={Types.tags.div}
                    descriptionText={res?.node?.cuisine || ''}
                    descriptionShort={res?.node?.cuisine || ''}
                    styleclass={styleClass}
                    dynamicMedia={imageData}
                    img={`${IMAGE_URL_DOMAIN}${res?.node?.media?.imageConnection?.edges[0].node.imageUrls.square}?downsize=100:*`}
                    assetVariation={!!props?.iconPath && isFallback ? 'iconfont' : ''}
                    iconPath={!!props?.iconPath && isFallback ? `${props?.iconPath} icon-inverse` : ''}
                    imageLoading="lazy"
                  />
                </div>
              );
            })}
          </div>
          {/* Need to add more optimized code for the Carousal logic*/}
          <div className={isMobileViewPort || isTabletViewPort ? 'miniCard-carousal-container' : 'd-none'}>
            <div className="glide col-md-12 p-0" ref={glideRef}>
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides d-flex p-0">
                  {miniCardData?.map((res: any, index: number) => {
                    if (index % 3 === 0) {
                      const carouselData = [];
                      for (let ind = index; ind <= index + 2; ind++) {
                        const imageData = {
                          altText: res[ind]?.node?.name,
                          assetPath: miniCardData[ind]?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls?.square
                            ? `${IMAGE_URL_DOMAIN}${miniCardData[ind]?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls?.square}?downsize=100:*`
                            : `${props?.dynamicMedia?.assetPath}?downsize=100:*`,
                          dynamic: false,
                          renditions: [],
                        };

                        const imageDataFallback =
                          !miniCardData[ind]?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls?.square;

                        const styleClass =
                          !!props?.iconPath && imageDataFallback
                            ? 'miniCard-border-box-rnb m-0 mb-3 fallback-image'
                            : 'miniCard-border-box-rnb m-0 mb-3';

                        miniCardData[ind]?.node?.name &&
                          carouselData.push(
                            <CardHorizontalMini
                              openInaNewTab={true}
                              ctaLink={`/en-us/dining/${miniCardData[ind]?.node?.seoUrl}.mi`}
                              header={getOutletTitle(miniCardData[ind]?.node?.name)}
                              headerShort={getOutletTitle(miniCardData[ind]?.node?.name)}
                              headerTag={Types.tags.div}
                              descriptionText={miniCardData[ind]?.node?.cuisine || ''}
                              descriptionShort={miniCardData[ind]?.node?.cuisine || ''}
                              styleclass={styleClass}
                              dynamicMedia={imageData}
                              img={`${IMAGE_URL_DOMAIN}${miniCardData[ind]?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls?.square}?downsize=100:*`}
                              assetVariation={!!props?.iconPath && imageDataFallback ? 'iconfont' : ''}
                              iconPath={!!props?.iconPath && imageDataFallback ? `${props?.iconPath} icon-inverse` : ''}
                              imageLoading="lazy"
                            />
                          );
                      }

                      return (
                        <div key={index}>
                          <li className="glide__slide my-2 mx-0" key={index} data-glide-index={index}>
                            <div className="slide-content">{carouselData}</div>
                          </li>
                        </div>
                      );
                    }
                    return undefined;
                  })}
                </ul>
                {isMobileViewPort && miniCardData && miniCardData?.length > 3 && (
                  <div className="controls_wrapper">
                    <div className="controls d-flex align-items-center justify-content-center">
                      <div className="carouselControl carouselControlType3b">
                        <div className="glide__arrows" data-glide-el="controls">
                          <button
                            className={`glide__arrow glide__arrow--left left-arrow ${
                              isFirstSlide ? 'glide__arrow--disabled' : ''
                            }`}
                            data-glide-dir="<"
                            aria-label="Previous"
                            disabled={isFirstSlide}
                          >
                            <span className="icon-arrow-left"></span>
                          </button>
                        </div>
                        <div data-glide-el="controls[nav]">
                          {miniCardData?.map((_: any, index: number) => {
                            if (index % 3 === 0) {
                              return <button className="" data-glide-dir={index} key={index}></button>;
                            }
                            return undefined;
                          })}
                        </div>

                        <div className="glide__arrows" data-glide-el="controls">
                          <button
                            className={`glide__arrow glide__arrow--right right-arrow ${
                              isLastSlide ? 'glide__arrow--disabled' : ''
                            }`}
                            data-glide-dir=">"
                            aria-label="Next"
                            disabled={isLastSlide}
                          >
                            <span className="icon-arrow-right"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledRecommendedMiniCard>
    );
  } else {
    return null;
  }
};

export const RecommendedMiniCardsConfig = {
  emptyLabel: 'recommendation',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/recommendation`,
};

export const RecommendedMiniCardsEditable = (props: any) => (
  <EditableComponent config={RecommendedMiniCardsConfig} {...props}>
    <RecommendedMiniCards {...props} />
  </EditableComponent>
);
