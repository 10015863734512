import { toRem } from '../../styles';
import styled from 'styled-components';

export const StyledCarouselGallery = styled.div<{ variation: string }>`
  .aem-container:first-child {
    margin-left: 0;
  }
  .glide {
    &__track {
      padding-top: ${props => (props.variation === 'carouselTriptych' ? toRem(24) : '0')};
      .glide__slides_authoring {
        width: 100% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
      ul {
        margin-left: ${props => (props.variation === 'carouselTriptych' ? toRem(-8) : '')};
      }
    }
    .glide__slides > .cq-Editable-dom > .cq-Editable-dom--container {
      display: contents;
    }
    .glide__slide {
      margin: auto;
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
        object-fit: cover;
      }
    }
    .glide__slide:not(.glide__slide--active) {
      transform: ${props => (props.variation === 'carouselHero' ? 'none' : 'scaleY(0.909);')};
    }
    .center-align-controls {
      display: flex;
      position: ${props => (props.variation === 'carouselHero' ? 'absolute' : '')};
      bottom: ${props => (props.variation === 'carouselHero' ? toRem(24) : '')};
      justify-content: center;
      align-items: center;
      width: auto;
      left: 0;
      right: 0;
      margin-top: ${props => (props.variation === 'carouselTriptych' ? toRem(24) : '')};
      margin-bottom: ${props => (props.variation === 'carouselTriptych' ? toRem(24) : '')};
    }
  }
  .glide--swipeable {
    cursor: default;
  }
`;
