/* eslint-disable @typescript-eslint/no-var-requires */
// export const phoenixShopHotelAmenities = require('./lib/phoenixShopHotelAmenities.graphql');
// export const phoenixShopHQVPhotogalleryCall = require('./lib/phoenixShopHQVPhotogalleryCall.graphql');
// export const phoenixShopHQVConvertCurrencyQuery = require('./lib/phoenixShopHQVConvertCurrencyQuery.graphql');

/* SERP query */
// search result query

export const phoenixShopCustomerToken = require('./lib/phoenixShopCustomerToken.graphql');
export const phoenixShopUpdateCustomerSavedProperties = require('./lib/phoenixShopUpdateCustomerSavedProperties.graphql');

export const phoenixShopLowestAvailableRatesByGeoQuery = require('./lib/phoenixShopLowestAvailableRatesByGeoQuery.graphql');
export const phoenixShopLowestAvailableRatesByGeoFilterQuery = require('./lib/phoenixShopLowestAvailableRatesByGeoFilterQuery.graphql');
export const phoenixShopLowestAvailableRatesByDestinationQuery = require('./lib/phoenixShopLowestAvailableRatesByDestinationQuery.graphql');
export const phoenixShopLowestAvailableRatesByDestinationFilterQuery = require('./lib/phoenixShopLowestAvailableRatesByDestinationFilterQuery.graphql');
export const phoenixShopPropertiesByGeoLocation = require('./lib/phoenixShopPropertiesByGeoLocation.graphql');
export const phoenixShopPropertiesByGeoLocationFilter = require('./lib/phoenixShopPropertiesByGeoLocationFilter.graphql');
export const phoenixShopPropertiesByDestination = require('./lib/phoenixShopPropertiesByDestination.graphql');
export const phoenixShopPropertiesByDestinationFilter = require('./lib/phoenixShopPropertiesByDestinationFilter.graphql');
export const phoenixShopLowestAvailableRatesByKeywordQuery = require('./lib/phoenixShopLowestAvailableRatesByKeywordQuery.graphql');
export const phoenixShopLowestAvailableRatesByKeywordFilterQuery = require('./lib/phoenixShopLowestAvailableRatesByKeywordFilterQuery.graphql');
export const phoenixShopConvertCurrencyQuery = require('./lib/phoenixShopConvertCurrencyQuery.graphql');
export const phoenixShopLowestAvailableRatesByLocationSearchInputFilterQuery = require('./lib/phoenixShopLowestAvailableRatesByLocationSearchInputFilterQuery.graphql');
export const phoenixShopLowestAvailableRatesByLocationSearchInputQuery = require('./lib/phoenixShopLowestAvailableRatesByLocationSearchInputQuery.graphql');

/* ****  SERP three Query Breakdown *****  */
export const phoenixShopPropertiesMediaGalleryByIDS = require('./lib/phoenixShopPropertiesMediaGalleryByIDS.graphql');
/*** Note: These queries are for split calls, uncomment this code only split calls are resumed */
// export const phoenixShopPropertiesByDestinationGetIDS = require('./lib/phoenixShopPropertiesByDestinationGetIDS.graphql');
// export const phoenixShopLowestAvailableRatesByGeoQueryGetIDS = require('./lib/phoenixShopLowestAvailableRatesByGeoQueryGetIDS.graphql');
// export const phoenixShopLowestAvailableRatesByDestinationGetIDSQuery = require('./lib/phoenixShopLowestAvailableRatesByDestinationGetIDSQuery.graphql');
// export const phoenixShopLowestAvailableRatesGetIDSByKeyword = require('./lib/phoenixShopLowestAvailableRatesGetIDSByKeyword.graphql');
// export const phoenixShopPropertiesByGeoLocationGetIDS = require('./lib/phoenixShopPropertiesByGeoLocationGetIDS.graphql');
// export const phoenixShopPropertiesBasicInfoByIDS = require('./lib/phoenixShopPropertiesBasicInfoByIDS.graphql');
// export const phoenixShopLowestAvailableRatesByLocationSearchInputGetIDSQuery = require('./lib/phoenixShopLowestAvailableRatesByLocationSearchInputGetIDSQuery.graphql');

// search form query
// export const phoenixShopSuggestedPlacesDetails = require('./lib/phoenixShopSuggestedPlacesDetailsQuery.graphql');
// export const phoenixShopSuggestedPlacesQuery = require('./lib/phoenixShopSuggestedPlacesQuery.graphql');

// SmHotelGalleryTitle
// Property info in main component
// export const phoenixShopHotelGalleryTitlePropertyInfo = require('./lib/phoenixShopHotelGalleryTitlePropertyInfo.graphql');
// Distance only client-side query
// export const phoenixShopHotelGalleryTitleDistanceOnly = require('./lib/phoenixShopHotelGalleryTitleDistanceOnly.graphql');
// Rates only client-side query
// export const phoenixShopHotelGalleryTitleRateOnly = require('./lib/phoenixShopHotelGalleryTitleRateOnly.graphql');

// consolidated basic property info for hqv title, location, and overview
// export const phoenixShopPropertyInfoCall = require('./lib/phoenixShopPropertyInfoCall.graphql');

// query to get all information for specific marshas for both dated and non-dated search
export const phoenixShopPropertiesDatedAllInfoByIDs = require('./lib/phoenixShopPropertiesDatedAllInfoByIDs.graphql');
export const phoenixShopPropertiesNonDatedAllInfoByIDs = require('./lib/phoenixShopPropertiesNonDatedAllInfoByIDs.graphql');
export const phoenixShopPropertiesDatedFiltersByIDs = require('./lib/phoenixShopPropertiesDatedFiltersByIDs.graphql');
export const phoenixShopPropertiesNonDatedFiltersByIDs = require('./lib/phoenixShopPropertiesNonDatedFiltersByIDs.graphql');
