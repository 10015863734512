import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .pagination-next-link,
  .pagination-prev-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .page-arrow-container {
    display: flex;
    align-items: center;
    cursor: default;
  }
  .shop-pagination {
    list-style-type: none;
    padding-left: ${toRem(0)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;

    .shop-pagination-item {
      margin: 0 ${toRem(1)};
      padding: ${toRem(4)} ${toRem(8)};
      border-radius: 50%;
      border: ${toRem(1)} solid transparent;
      height: ${toRem(24)};
      width: ${toRem(24)};
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border-color: ${baseVariables.color.neutral40};
      }
      &.active {
        background: ${baseVariables.color.neutral40};
        color: ${baseVariables.color.merch50};
        &:hover {
          border: none;
          cursor: default;
        }
      }
    }
    .shop-pagination-item.shop-pagination-dots {
      width: ${toRem(14)} !important;
      margin: 0;
      padding: 0;
      &:hover {
        border: none;
        cursor: default;
      }
    }
    .shop-pagination-arrow {
      font-size: ${toRem(30)};
      width: auto;
      padding: ${toRem(2)} 0;
      &.disabled {
        pointer-events: none;
        color: ${baseVariables.color.alert20};
      }

      .icon-arrow-right,
      .icon-arrow-left {
        &:before {
          width: ${toRem(24)};
          height: ${toRem(24)};
        }
      }
    }
  }
`;
