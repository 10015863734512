import React, { useRef, useEffect, useState, useContext, useMemo, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';

import { isServer } from '@marriott/mi-apollo-client-utils';
import { phoenixShopPropertyInfoCall } from './HQVQueries';
import { useGetBreakpoint, getClassForBreakpoint } from '@marriott/mi-ui-library-shop';
import { Styled } from './HotelGalleryTitle.styles';
import Rating from '../../molecules/Rating/index';
import { ComponentProps, RestrictedCountryListItemProps } from './HotelGalleryTitle.types';
import SM, { NAVIGATION_URL_CONSTANTS, hardCodedAllLocale } from './config';
import { PageContext, usePageContext } from '../../../context/PageContext';
import { Distance } from '../../molecules/Distance';
import {
  DEFAULT_FALLBACK_START_DATE,
  DEFAULT_FALLBACK_END_DATE,
  DEFAULT_FALLBACK_LATITUDE,
  DEFAULT_FALLBACK_LONGITUDE,
  DEFAULT_FALLBACK_BRAND_CODE,
  reviewsRedirectionVarValue,
  locationDescriptionFilterToDisplayOnHQV,
  resortFeeDescriptionFilterToDisplayOnHQV,
  destinationFeeDescriptionFilterToDisplayOnHQV,
  surchargeOrdinanceCostDescriptionFilterToDisplayOnHQV,
} from '../../../constants';
import { formatPhoneNumber, formatNumberByLocale } from '../../../utils/src/utils';
import { hotelGalleryTitleMockData } from './mock/hotelGalleryTitle';
import { addSubDirectoryPrefix, isReviewCountHidden, logger } from '../../../utils';
import { callDataLayer } from './utils/helper';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useHQVContext } from '../../../context';
import { ErrorHandlerAlert } from '../../molecules';
import { useRedirectionRules } from '../../../hooks';
import moment from 'moment';
import { TOOLTIP_POSITION, PropertyReview, PropertyRatingContainer, CN_RATING_TOOLTIP } from '@marriott/mi-ui-library';

const { log } = logger({})('HotelGalleryTitle');
const Rates = React.lazy(() => import('../../molecules/Rates'));
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    // extends React's HTMLAttributes
    custom_click_track_value?: string;
  }
}

const SmHotelGalleryTitleComp: React.FC<ComponentProps> = pageProps => {
  const { model, isAuthorMode } = pageProps;
  // eslint-disable-next-line no-console
  const { pageState, currentLocale, datalayerParams, datalayerProperties } = usePageContext();
  const disableHotelHeaderAndReviewLinks = model?.disableHotelHeaderAndReviewLinks;
  const disableHotelReviewLinks = model?.disableHotelReviewLinks ?? false;
  const pageContext = useContext(PageContext);

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery = useMemo(() => {
    return (
      !isServer &&
      !(pageState.authorMode || isAuthorMode) &&
      !pageContext.sessionData &&
      !(pageContext.sessionData.data || pageContext.sessionData.cacheData)
    );
  }, [pageState, pageContext, isAuthorMode]);

  const { hqvState } = useHQVContext();

  const addressElement = useRef(null);
  const menuBar = useRef(null);
  const [scroll, setScroll] = useState(false);
  const [_viewRatesClass, setViewRatesClass] = useState('menu-section-rates');
  const [desktopDotHider, setDesktopDotHider] = useState(false);
  const [breakpoint, setBreakpoint] = useState<string | undefined>(useGetBreakpoint());

  const searchCriteria = hqvState.propertySessionData?.cacheData?.data?.AriesSearch?.searchCriteria;
  const propertyId = hqvState.propertyIDvalue;
  const hideRates = pageContext.resolvedUrl?.includes('/availabilityCalendar') ? true : false;
  const startDate: string = useMemo(() => {
    return pageContext?.sessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkInDate
      ? moment(pageContext?.sessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkInDate).format(
          'YYYY-MM-DD'
        )
      : moment(
          pageContext?.sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkInDate
        ).format('YYYY-MM-DD') ?? DEFAULT_FALLBACK_START_DATE;
  }, [searchCriteria]);
  const endDate: string = useMemo(() => {
    return pageContext?.sessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkOutDate
      ? moment(pageContext?.sessionData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkOutDate).format(
          'YYYY-MM-DD'
        )
      : moment(
          pageContext?.sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkOutDate
        ).format('YYYY-MM-DD') ?? DEFAULT_FALLBACK_END_DATE;
  }, [searchCriteria]);
  const latitude = useMemo(() => {
    return pageContext?.sessionData?.data?.AriesSearch?.searchCriteria?.address?.latitude
      ? parseFloat(pageContext?.sessionData?.data?.AriesSearch?.searchCriteria?.address?.latitude)
      : parseFloat(pageContext?.sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.address?.latitude) ??
          DEFAULT_FALLBACK_LATITUDE;
  }, [searchCriteria]);
  const longitude = useMemo(() => {
    return pageContext?.sessionData?.data?.AriesSearch?.searchCriteria?.address?.longitude
      ? parseFloat(pageContext?.sessionData?.data?.AriesSearch?.searchCriteria?.address?.longitude)
      : parseFloat(pageContext?.sessionData?.cacheData?.data?.AriesSearch?.searchCriteria?.address?.longitude) ??
          DEFAULT_FALLBACK_LONGITUDE;
  }, [searchCriteria]);
  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const brandCode = useMemo(() => {
    return pageContext?.resolvedUrl?.split('brandCode=')[1]?.split('&')[0] ?? DEFAULT_FALLBACK_BRAND_CODE;
  }, [pageContext]);

  // Reset breakpoint on window resize.
  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setBreakpoint(useGetBreakpoint());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [selectedCategory, setSelectedCategory] = useState(SM.SM_PHOTOS);

  const handleScroll = () => {
    const scrollPosition = (window as Window) && 'pageYOffset' in window ? window.pageYOffset : window.scrollY;
    if (scrollPosition > SM.SM_SCROLL_LIMIT) {
      setScroll(true);
      if (window.innerWidth > 1023) {
        setViewRatesClass('menu-section-rates view-rates-sticky');
      }
    } else {
      setScroll(false);
      setViewRatesClass('menu-section-rates');
    }
    SM.SM_SECTIONS.forEach(v => {
      if (document.getElementById(v) !== null) {
        const titleOffset = document.getElementById(v)?.offsetTop ?? 0;
        if (scrollPosition > titleOffset - 150) {
          setSelectedCategory(document.querySelector("[href='#" + v + "']")?.innerHTML ?? SM.SM_PHOTOS);
        }
      }
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    log.debug(
      `[PerfTesting][SMHotelGalleryTitle] Entering the useEffect function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
    );
    window.addEventListener('scroll', handleScroll, { passive: true });
    // eslint-disable-next-line no-console
    log.debug(
      `[PerfTesting][SMHotelGalleryTitle] Exiting the useEffect function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
    );
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //make a datalayer call on load
  useEffect(() => {
    callDataLayer(
      pageContext?.resolvedUrl,
      pageContext?.sessionData,
      pageContext?.headersData,
      pageContext?.query,
      propertyId,
      brandCode,
      datalayerParams,
      datalayerProperties
    );
  }, []);

  const {
    loading: propertyDataLoading,
    error: propertyDataError,
    data: propertyDataGraph,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(phoenixShopPropertyInfoCall, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    variables: {
      // ids: [propertyId],
      propertyId: propertyId,
      filter: 'PHONE',
      descriptionsFilter:
        pageContext?.currentLocale === hardCodedAllLocale?.en_US
          ? [
              locationDescriptionFilterToDisplayOnHQV,
              resortFeeDescriptionFilterToDisplayOnHQV,
              destinationFeeDescriptionFilterToDisplayOnHQV,
              surchargeOrdinanceCostDescriptionFilterToDisplayOnHQV,
            ]
          : ['LOCATION'],
    },
    context: {
      headers: {
        // Any header, including x-request-id, can be passed in args with query.
        // If you don't pass it in the authLink will generate a random ID.
        'x-request-id': requestId,
        'accept-language': pageContext?.currentLocale,
      },
    },
    skip: skipQuery,
    onCompleted: data => {
      log.debug('phoenixShopHotelGalleryTitlePropertyInfo complete: ', data);
    },
  });

  if (propertyDataError) {
    log.debug('phoenixShopHotelGalleryTitlePropertyInfo failed: ', propertyDataError);
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && addressElement.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const height = (addressElement as any).current.offsetHeight;
      if (height > 30) {
        setDesktopDotHider(true);
      }
    }
  }, [propertyDataGraph, propertyDataLoading, addressElement]);

  // log.debug('propertyDataArr: ', JSON.stringify(propertyDataGraph));
  // log.debug('hashCode', hashCode(JSON.stringify(propertyDataArr)));
  // log.debug('propertyId: ', propertyId);

  const propertyData = useMemo(() => {
    return pageState.authorMode || isAuthorMode ? hotelGalleryTitleMockData : propertyDataGraph;
  }, [pageState, propertyDataGraph, isAuthorMode]);

  const addressObj = propertyData?.property?.contactInformation?.address;
  const addressLine1 = addressObj?.line1 ?? '';
  const addressCity = addressObj?.city ?? '';
  const addressStateProvince = addressObj?.stateProvince?.description ?? '';
  const addressPostalCode = addressObj?.postalCode ?? '';
  const restrictedCountryList: RestrictedCountryListItemProps[] = model?.countryListRestricted ?? [];

  let addressCountry = addressObj?.country?.description ?? '';
  const addressCountryCode = addressObj?.country?.code;
  // Reading through list of countries to be hidden and replacing the country with the new country
  restrictedCountryList?.forEach(restrictedCountryItem => {
    if (restrictedCountryItem?.countryItemRestricted === addressCountryCode) {
      addressCountry = restrictedCountryItem?.replacedCountryItem;
    }
  });
  if (model?.overridePropertyCountry && addressObj?.country?.description === model?.overridePropertyCountryText) {
    addressCountry = '';
  }
  const isMax = propertyData?.property?.basicInformation?.isMax;

  const reviewsRedirectionURL = useRedirectionRules(
    propertyData?.property?.basicInformation?.brand?.id,
    propertyId,
    propertyData?.property?.basicInformation?.name,
    reviewsRedirectionVarValue,
    pageContext?.currentLocale,
    propertyData?.property?.seoNickname
  );

  const formedURL =
    propertyData?.property?.id?.toLowerCase() +
    '-' +
    propertyData?.property?.basicInformation?.name
      .replace('-', ' ')
      .replace(/([~!@#$%^&*()_+=`{}[\]|\\:;'<>,./? ])+/g, '-')
      .replace(/^(-)+|(-)+$/g, '')
      ?.toLowerCase();
  const hwsURL = propertyData?.property?.seoNickname ?? formedURL;

  const clickTrackingLoc = 'HQV AEM';
  const titleUrl = disableHotelHeaderAndReviewLinks
    ? ''
    : addSubDirectoryPrefix(NAVIGATION_URL_CONSTANTS.SM_VIEW_HOTEL_WEBSITE_LINK) + hwsURL;

  const reviewUrl = disableHotelReviewLinks ? '' : reviewsRedirectionURL;
  const propertyUrl = disableHotelHeaderAndReviewLinks
    ? ''
    : addSubDirectoryPrefix(NAVIGATION_URL_CONSTANTS.SM_VIEW_HOTEL_WEBSITE_LINK) +
      propertyData?.property?.id?.toLowerCase() +
      '-' +
      propertyData?.property?.basicInformation?.name
        .replace('-', ' ')
        .replace(/([~!@#$%^&*()_+=`{}[\]|\\:;'<>,./? ])+/g, '-')
        .replace(/^(-)+|(-)+$/g, '')
        ?.toLowerCase();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menuItemSelected = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      setSelectedCategory(event.currentTarget.getAttribute('data-menuitem'));
      adjustScrollPadding();
    }
  };

  // TODO: This is a utility and should be moved to a shareable utils location.
  function adjustScrollPadding(): void {
    // eslint-disable-next-line no-console
    log.debug(
      `[PerfTesting][SMHotelGalleryTitle] Entering the adjustScrollPadding function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
    );
    const titleComponent = document.getElementsByClassName('title-container-menu')[0] as HTMLElement;
    const additionalSpacingElements = document.getElementsByClassName(
      'adjustable-margin-element'
    ) as HTMLCollectionOf<HTMLElement>;
    if (!titleComponent?.classList.contains('position-sticky-menu')) {
      for (let i = 0; i < additionalSpacingElements.length; i++) {
        if (!additionalSpacingElements[i].classList.contains('space-buffer')) {
          additionalSpacingElements[i].classList.add('space-buffer');
        }
      }
    } else {
      for (let i = 0; i < additionalSpacingElements.length; i++) {
        if (additionalSpacingElements[i].classList.contains('space-buffer')) {
          additionalSpacingElements[i].classList.remove('space-buffer');
        }
      }
    }
    // eslint-disable-next-line no-console
    log.debug(
      `[PerfTesting][SMHotelGalleryTitle] Exiting the adjustScrollPadding function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
    );
  }

  // function to determine if a comma should be displayed or not
  function addressDelimiterFinder(startString: string, addressArr: string[]) {
    const filteredAddressArr = addressArr.filter(str => str !== '');
    if (startString && filteredAddressArr.length > 0) {
      return ', ';
    }
    return ' ';
  }

  const [secondaryTitle, setSecondaryTitle] = useState(false);
  /**
   * Set the flag to true if the locale is Korea/Japan
   */
  useEffect(() => {
    if (currentLocale === hardCodedAllLocale?.jp || currentLocale === hardCodedAllLocale?.ko) {
      setSecondaryTitle(true);
    } else {
      setSecondaryTitle(false);
    }
  }, [currentLocale]);

  return (
    <ErrorHandlerAlert alertMessage={model?.componentErrorMessage}>
      <Styled>
        <div className={clsx('App', `${hqvState?.modalOpenState ? 'hqv-modal standard' : ''}`)}>
          <div className="title-main-container">
            <div className="title-container">
              <div className="title-container__right-container">
                <span
                  className={`hotel-logo brand-${propertyData?.property?.basicInformation?.brand?.id.toLowerCase()}`}
                ></span>
                <div className="border-class"></div>
              </div>

              <div className="title-container__title">
                <a
                  href={titleUrl}
                  title={propertyData?.property?.basicInformation?.name}
                  className={clsx(
                    'title-container__hotel-name custom_click_track',
                    getClassForBreakpoint(breakpoint, 't-title-s', 't-title-m', 't-title-s'),
                    disableHotelHeaderAndReviewLinks ? 'disableLinks' : ''
                  )}
                  {...{
                    custom_click_track_value: `${clickTrackingLoc}| Property Title: ${propertyData?.property?.basicInformation?.name}|internal`,
                  }}
                >
                  <div
                    className={clsx(
                      (currentLocale === hardCodedAllLocale?.jp || currentLocale === hardCodedAllLocale?.ko) &&
                        'primary-title'
                    )}
                  >
                    {propertyData?.property?.basicInformation?.name}{' '}
                    {propertyData &&
                      propertyData?.property?.basicInformation?.nameInDefaultLanguage ===
                        propertyData?.property?.basicInformation?.name &&
                      !model?.disableTextDescriptor && (
                        <span className="text-descriptor">{model?.descriptorSearchLabel}</span>
                      )}
                  </div>
                  {secondaryTitle &&
                    propertyData?.property?.basicInformation?.nameInDefaultLanguage !==
                      propertyData?.property?.basicInformation?.name && (
                      <div
                        className={clsx(
                          (currentLocale === hardCodedAllLocale?.jp || currentLocale === hardCodedAllLocale?.ko) &&
                            'secondary-title pt-2'
                        )}
                      >
                        {propertyData?.property?.basicInformation?.nameInDefaultLanguage}
                      </div>
                    )}
                </a>
                {/* Address */}
                <div ref={addressElement} className={clsx('title-container__adress', secondaryTitle && 'pt-2')}>
                  {propertyData?.property?.contactInformation?.address ? (
                    <div className="title-container__address-box">
                      <span className="icon-location mi-icon"></span>

                      <span
                        className={clsx(
                          't-subtitle-m',
                          getClassForBreakpoint(breakpoint, 't-font-m', 't-font-m', 't-font-s')
                        )}
                      >
                        {/* Not using the addressDelimiterFinder since the comma after addressLine1 follows different logic
                          According to FSD-91318, this is what is expected:

                            line1 city, state, country, postal code
                            If there is no country then line1 city, state, postal code
                            if no state then line1 city, country, postal code
                            If no city & country then line1, state, postal code
                            If no postal code , city & country then line1, city */}
                        {addressLine1 +
                          (addressLine1 &&
                          ((addressCity && !addressStateProvince && !addressCountry && !addressPostalCode) ||
                            (!addressCity && addressStateProvince && !addressCountry && addressPostalCode))
                            ? ', '
                            : ' ')}
                        {addressCity +
                          addressDelimiterFinder(addressCity, [
                            addressStateProvince,
                            addressPostalCode,
                            addressCountry,
                          ])}
                        {addressStateProvince +
                          addressDelimiterFinder(addressStateProvince, [addressPostalCode, addressCountry])}
                        {addressCountry + addressDelimiterFinder(addressCountry, [addressPostalCode])}
                        {addressPostalCode}
                      </span>
                      {propertyData?.property?.contactInformation?.contactNumbers[0]?.phoneNumber && (
                        <span className={'round-circle ' + (desktopDotHider ? 'address-line-circle' : '')}></span>
                      )}
                    </div>
                  ) : null}
                  {/* Phone */}
                  {propertyData?.property?.contactInformation?.contactNumbers[0]?.phoneNumber && (
                    <a
                      href={`tel:${propertyData?.property?.contactInformation?.contactNumbers[0]?.phoneNumber?.original}`}
                      className="title-container__cn-box custom_click_track"
                      {...{ custom_click_track_value: `${clickTrackingLoc}| Phone |internal` }}
                    >
                      <span className="icon-phone mi-icon"></span>
                      <span
                        className={clsx(
                          'underlined-label',
                          getClassForBreakpoint(breakpoint, 't-font-m', 't-font-m', 't-font-xs')
                        )}
                      >
                        {formatPhoneNumber(
                          propertyData?.property?.contactInformation?.contactNumbers[0]?.phoneNumber?.display ?? '',
                          currentLocale
                        )}
                      </span>
                    </a>
                  )}
                </div>
                <div className="title-container__third-row">
                  {!isMax
                    ? !!(
                        propertyData?.property?.reviews?.stars?.count &&
                        propertyData?.property?.reviews?.stars?.count > 0
                      ) && (
                        <span
                          className={clsx('t-font-s title-container__rating', 'custom_click_track')}
                          {...{ custom_click_track_value: `${clickTrackingLoc}| Reviews |internal` }}
                        >
                          <Rating ratings={propertyData?.property?.reviews?.stars?.count} />
                          <PropertyRatingContainer
                            propertyReviewUrl={reviewUrl}
                            disabled={isReviewCountHidden(currentLocale)}
                            tooltipPosition={TOOLTIP_POSITION.top}
                            toolTipContent={CN_RATING_TOOLTIP}
                            className={clsx('reviews-link-section', disableHotelReviewLinks ? 'disableLinks' : '')}
                          >
                            <span className={'t-font-s circle-rating__rating-text'}>
                              {formatNumberByLocale(propertyData?.property?.reviews?.stars?.count, currentLocale)}
                            </span>
                            <PropertyReview
                              isVisible={!isReviewCountHidden(currentLocale)}
                              className={'t-font-s circle-rating__rating-review '}
                            >
                              <>
                                (
                                {propertyData?.property?.reviews?.numberOfReviews?.count
                                  ? formatNumberByLocale(
                                      propertyData?.property?.reviews?.numberOfReviews?.count,
                                      currentLocale
                                    )
                                  : 0}{' '}
                                {model?.reviewsLabel})
                              </>
                            </PropertyReview>
                          </PropertyRatingContainer>
                        </span>
                      )
                    : null}
                  {/* If reviews, and lat, long, start, and end, we have what we need to retrieve distance client-side.  */}
                  {startDate && endDate ? (
                    <>
                      {propertyData?.property?.reviews?.stars?.count &&
                        propertyData?.property?.reviews?.stars?.count > 0 &&
                        !isMax && <span className="round-circle"></span>}
                      <span
                        className={clsx(
                          'title-container__subTitle',
                          propertyData?.property?.reviews?.stars?.count &&
                            propertyData?.property?.reviews?.stars?.count > 0
                            ? 'reviews-present'
                            : 'reviews-absent'
                        )}
                      >
                        {!!(latitude && longitude) && model?.disableDistanceFromLocation !== true && (
                          <Distance
                            distanceUnitLabel={model?.distanceUnitLabel}
                            {...{ propertyId, requestId, latitude, longitude, startDate, endDate, model }}
                          />
                        )}
                      </span>
                    </>
                  ) : null}
                  {propertyData?.property ? (
                    <a
                      rel="noreferrer"
                      className={clsx(
                        'title-container__category-box',
                        'custom_click_track',
                        getClassForBreakpoint(breakpoint, 't-font-s', 't-font-s', 't-font-s'),
                        disableHotelHeaderAndReviewLinks ? 'd-none' : ''
                      )}
                      href={propertyUrl}
                      {...{ custom_click_track_value: `${clickTrackingLoc}| View Hotel Website |internal` }}
                      target="_blank"
                    >
                      <span
                        className={clsx(
                          'title-container__category',
                          'm-link-tertiary-button-external',
                          'm-link-action'
                        )}
                      >
                        {model?.viewHotelWebsiteLabel}
                      </span>
                      <span aria-label="Opens in a new browser window."></span>
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
            {!model?.menuOptionsDisabled && (
              <div
                ref={menuBar}
                className={`t-font-m title-container-menu analytics-click custom_click_track ${
                  scroll ? 'position-sticky-menu' : ''
                } ${SM.SM_PHOTOS}`}
              >
                {!model?.photoSectionDisabled && hqvState.photosAvailable && (
                  <a
                    className={`t-font-m title-container-menu_item analytics-click custom_click_track ${
                      selectedCategory === SM.SM_PHOTOS ? 'menu-selected' : ''
                    }`}
                    data-menuitem={SM.SM_PHOTOS}
                    onClick={(e): void => menuItemSelected(e)}
                    onKeyDown={(e): void => menuItemSelected(e)}
                    href="#hqv-photo-section"
                    custom_click_track_value={`${clickTrackingLoc}|${SM.SM_PHOTOS}|internal`}
                  >
                    {model?.photosLabel}
                  </a>
                )}
                {!model?.locationSectionDisabled && (
                  <a
                    className={`t-font-m title-container-menu_item analytics-click custom_click_track ${
                      selectedCategory === SM.SM_LOCATION ? 'menu-selected' : ''
                    } ${SM.SM_LOCATION}`}
                    data-menuitem={SM.SM_LOCATION}
                    onClick={(e): void => menuItemSelected(e)}
                    onKeyDown={(e): void => menuItemSelected(e)}
                    href="#hqv-location-section"
                    custom_click_track_value={`${clickTrackingLoc}|${SM.SM_LOCATION}|internal`}
                  >
                    {model?.locationLabel}
                  </a>
                )}
                {!model?.hotelInformationSectionDisabled && hqvState.informationAvailable && (
                  <a
                    className={`t-font-m title-container-menu_item analytics-click custom_click_track ${
                      selectedCategory === SM.SM_HOTEL_INFO ? 'menu-selected' : ''
                    } ${SM.SM_HOTEL_INFO}`}
                    data-menuitem={SM.SM_HOTEL_INFO}
                    onClick={(e): void => menuItemSelected(e)}
                    onKeyDown={(e): void => menuItemSelected(e)}
                    href="#hqv-hotel-info-section"
                    custom_click_track_value={`${clickTrackingLoc}|${SM.SM_HOTEL_INFO}|internal`}
                  >
                    {model?.hotelInformationLabel}
                  </a>
                )}
                {!model?.amenitiesSectionDisabled && hqvState.amenetiesAvailable && (
                  <a
                    className={`t-font-m title-container-menu_item analytics-click custom_click_track ${
                      selectedCategory === SM.SM_AMENITIES ? 'menu-selected' : ''
                    } ${SM.SM_AMENITIES}`}
                    data-menuitem={SM.SM_AMENITIES}
                    onClick={(e): void => menuItemSelected(e)}
                    onKeyDown={(e): void => menuItemSelected(e)}
                    href="#hqv-amenities-section"
                    custom_click_track_value={`${clickTrackingLoc}|${SM.SM_AMENITIES}|internal`}
                  >
                    {model?.amenitiesLabel}
                  </a>
                )}
                {!model?.propertyDetailsSectionDisabled && hqvState.propertyDetailsAbailable && (
                  <a
                    className={`t-font-m title-container-menu_item analytics-click custom_click_track ${
                      selectedCategory === SM.SM_PROPERTY_DETAILS ? 'menu-selected' : ''
                    } ${SM.SM_PROPERTY_DETAILS}`}
                    data-menuitem={SM.SM_PROPERTY_DETAILS}
                    onClick={(e): void => menuItemSelected(e)}
                    onKeyDown={(e): void => menuItemSelected(e)}
                    href="#hqv-property-details-section"
                    custom_click_track_value={`${clickTrackingLoc}|${SM.SM_PROPERTY_DETAILS}|internal`}
                  >
                    {model?.propertyDetailsLabel}
                  </a>
                )}
              </div>
            )}
            {propertyData?.property && !hideRates && (
              <ErrorBoundary fallback={<p>Something went wrong</p>}>
                <Suspense fallback={''}>
                  <Rates
                    propertyData={propertyData?.property}
                    jsonContent={model}
                    // {...{ jsonContent, sessionData, toCurrency, isTaxesAndFeesEnabled, usePointsSelected }}
                  />
                </Suspense>
              </ErrorBoundary>
            )}
          </div>
        </div>
      </Styled>
    </ErrorHandlerAlert>
  );
};

export default SmHotelGalleryTitleComp;

// export const HotelGalleryTitleConfig = {
//   emptyLabel: 'SmHotelGalleryTitle',
//   isEmpty: false,
//   resourceType: `mi-aem-shop-spa/components/content/hotelgallerytitle`,
// };

// export const HotelGalleryTitleEditable = (props: any) => (
//   props.cqPath.includes('experiencefragment') ? <SmHotelGalleryTitleComp {...props} /> :
//   <EditableComponent config={HotelGalleryTitleConfig} {...props}>
//     <SmHotelGalleryTitleComp {...props} />
//   </EditableComponent>
// );
