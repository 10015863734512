import { canUseDOM, getEnvProp } from '../helpers';
import { logger } from '@marriott/shared/mi-helper-utils';
import type { DatalayerAttributes, PersistentDatalayerAttributes } from './types';
import Cookies from 'js-cookie';

export const EVENT_DRIVEN_DATALAYER = 'eventdriven';

export type DatalayerType = 'eventdriven' | 'traditional';

/**
 * Return the current datalayer object from the window.
 */
export function getDataLayerData(type: DatalayerType): PersistentDatalayerAttributes | Record<string, unknown> {
  return canUseDOM && EVENT_DRIVEN_DATALAYER === type ? window?.dataLayer || {} : {};
}

/**
 * Normalize static attributes from the model json.
 */
export function normalizeModelAttributes(attributes?: DatalayerAttributes): Record<string, string> {
  if (!attributes) {
    return {};
  }
  return attributes?.reduce((acc: Record<string, string>, attr) => {
    if (attr.value && attr.value !== 'undefined' && attr.value !== null) {
      acc[attr.key as string] = attr.value as string;
    }
    return acc;
  }, {});
}

export async function updateServerSession(options: {
  name: string;
  createOrUpdate: Record<string, unknown>;
}): Promise<void> {
  const sessionId = Cookies.get('sessionID');
  const endpoint = getEnvProp('NEXT_PUBLIC_UPDATE_SESSION_ENDPOINT') || '';
  const { log } = logger({ requestID: '', sessionID: sessionId })(options.name);
  const sessionHeaders = new Headers();
  sessionHeaders.append('Content-Type', 'application/json');
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: sessionHeaders,
      body: JSON.stringify({
        createOrUpdate: {
          ...options.createOrUpdate,
          clientTimeStamp: true,
        },
      }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error updating session data! status: ${response.status}`);
    }
    const { data } = await response.json();
    return data;
  } catch (error) {
    log.error(`Error updating session for ${options.name}`, error);
    return Promise.reject(error);
  }
}

/**
 * Get the session data from the session app. This is also where we want to include logic to determine if we need to fetch the session data from the server or use the mock data.
 * the data in the first place.
 */
export async function getSessionStoreData(): Promise<Record<string, unknown>> {
  const endpoint = getEnvProp('NEXT_PUBLIC_GET_SESSION_ENDPOINT') || '';
  const { log } = logger({ requestID: '', sessionID: '' })('getServerSession');
  try {
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error(`HTTP error retrieving session data! status: ${response.status}`);
    }
    const {
      cacheData: { data: nestedSessionData },
    } = await response.json();
    return Promise.resolve({ data: nestedSessionData });
  } catch (error) {
    log.error(`Error updating session for ${'fetch session data'}`, error);
    return Promise.reject(error);
  }
}
