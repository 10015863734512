//method to check if Object is empty
import { randomBytes } from 'crypto';

export const isEmpty = (value: object): boolean => (value ? Object.keys(value).length === 0 : true);

export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export enum AuthStateEnum {
  UN_AUTHENTICATED = 'unauthenticated',
  REMEMBERED = 'remembered',
  AUTHENTICATED = 'authenticated',
  ATTEMPTED_SIGNIN = 'attemptedSignIn',
  DATALAYER_AUTHENTICATION_CHECK_VALUE = 'mr_prof_authentication_state',
}

/**
 * convertToCommaSeparatedNumber
 *
 * the function takes the number and converts the number into comma separated number and returns the number as a string.
 *
 * @param number - The number that has to be formatted to a localized number format.
 * @param locale - The string : locale for specific region.
 * @returns localized number format of the number that is passed based on the locale param.
 *  */
export function convertToCommaSeparatedNumber(number: number, locale = 'en-US'): string {
  return new Intl.NumberFormat(locale).format(number);
}

export function atGetCookie(cname: string) {
  const name = cname + '=',
    decodedCookie = decodeURIComponent(document.cookie),
    cookeArray = decodedCookie.split(';');
  for (let i = 0; i < cookeArray.length; i++) {
    let cookie = cookeArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

// Check if AEM Preview mode then check for wcmmode query parameter and return flag
export const getWCMModeFlagValue = () => {
  if (typeof window !== 'undefined') {
    const pathName = window?.location?.href;
    const paramWcmMode = pathName.includes('?') ? pathName.split('?') : '';
    const isWCM = paramWcmMode.includes('wcmmode=disabled');
    return isWCM;
  } else {
    return false;
  }
};

/**
 * Validates a string as non-empty
 *
 * @param str Value to be checked for truthiness, string type, and non-empty
 * @returns boolean
 */
export const isNonEmptyString = (str: unknown): boolean => {
  return !!str && typeof str == 'string' && str.replace(/\s+/g, '').length > 0;
};

export const generateRandomNumber = () => {
  if (window && window?.crypto) {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] / 0xffffffff;
  } else {
    const randomBytesBuffer = randomBytes(4);
    // Convert to a float between 0 and 1
    return randomBytesBuffer.readUInt32LE(0) / 0xffffffff;
  }
};
