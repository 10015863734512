/* eslint-disable @typescript-eslint/no-unused-vars */
// Imports for external libraries go here.
import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OffersCobrandCardHorizontalFlexibleProps } from './OffersCobrandCardHorizontalFlexible.types';
import { CobrandCardHorizontalFlexible, Types } from '@marriott/mi-ui-library';
import { StyledOffersCobrandCardHorizontalFlexible } from './OffersCobrandCardHorizontalFlexible.styles';
import { mockOffersCobrandCardHorizontalFlexibleData } from './__mock__/OffersCobrandCardHorizontalFlexible.mock';
import { decodeHtml, getMediaData, getMediaSource } from '../../utils/OfferUtils';
import { SECONDARY_MEDIA, PRIMARY_MEDIA } from '../../constants/CommonConstants';
import { ImageRenditionsData } from '../../utils/ImageRenditionStructure';

export const OffersCobrandCardHorizontalFlexibleWrapperConfig = {
  emptyLabel: 'OffersCobrandCardHorizontalFlexible',
  isEmpty: false,
  resourceType: `mi-aem-common-spa/components/content/cobrandcardhorizontalflexible`,
};

export const OffersCobrandCardHorizontalFlexibleWrapper: React.FC<OffersCobrandCardHorizontalFlexibleProps> = props => {
  const { model, offersData, isAuthorMode } = props;
  const { offersCobrandCarHorizontal } = ImageRenditionsData;
  const offersDataNode = !isAuthorMode
    ? offersData?.responseObject?.edges[0]
    : mockOffersCobrandCardHorizontalFlexibleData.offersData?.responseObject?.edges[0];
  const offerType = offersData?.responseObject?.edges[0]?.node?.offerType;
  const expectedMediaSource = offerType == 'CoBrand' ? SECONDARY_MEDIA : PRIMARY_MEDIA;
  const mediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        expectedMediaSource,
        offersCobrandCarHorizontal?.aspectRatio.dynamicMedia
      );
  const isMediaFromDAC = mediaSource == SECONDARY_MEDIA || mediaSource == PRIMARY_MEDIA;
  const media = isAuthorMode
    ? model?.dynamicMedia
    : getMediaData(offersData?.responseObject?.edges[0]?.node?.media, model?.dynamicMedia, mediaSource, PRIMARY_MEDIA);
  return (
    <StyledOffersCobrandCardHorizontalFlexible>
      <CobrandCardHorizontalFlexible
        openInNewTab={model?.openInNewTab}
        ctaLink={offersDataNode?.node?.callToActionLink ?? model?.ctaLink}
        ctaLinkText={offersDataNode?.node?.callToActionLabel ?? model?.ctaLinkText}
        header={offersDataNode?.node?.title ? decodeHtml(offersDataNode?.node?.title) : model?.header}
        headerTag={model?.headerTag ?? Types.tags.h2}
        styleclass={model?.styleclass}
        descriptionText={
          offersDataNode?.node?.description ? decodeHtml(offersDataNode?.node?.description) : model?.descriptionText
        }
        descriptionTextMobile={decodeHtml(offersDataNode?.node?.descriptionTextMobile ?? '')}
        trackingProperties={model?.trackingProperties}
        // dynamicMedia={model?.dynamicMedia}
        dynamicMedia={isMediaFromDAC ? media : model?.dynamicMedia}
        componentId={model?.componentId}
        ctaType={model?.ctaType}
      />
    </StyledOffersCobrandCardHorizontalFlexible>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersCobrandCardHorizontalFlexible = (props: any) => (
  <div
    data-component-name="o-offers-OffersCobrandCardHorizontalFlexible"
    data-testid="cobrand-card-horizontal-flexible"
  >
    <EditableComponent config={OffersCobrandCardHorizontalFlexibleWrapperConfig} {...props}>
      <OffersCobrandCardHorizontalFlexibleWrapper {...props} />
    </EditableComponent>
  </div>
);
