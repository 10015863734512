import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledTabComponentContainer = styled.div`
  // Overriding Common component styles
  .column-container {
    &-tabs {
      .tab-wrapper {
        box-shadow: none;
        padding-top: 0rem !important;
      }
      .tab-desc {
        padding: ${toRem(4)} 0;
      }
    }
  }
  .tab_container_authmode {
  }
  .tabMenu_header {
    font-size: ${toRem(55)};
    text-align: center;
    padding-top: 1rem;
    display: inherit;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: unset;
    width: auto;
    span {
      text-transform: initial;
      font-size: ${toRem(14)};
    }
  }
`;
