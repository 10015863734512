// Imports for external libraries go here.
import React from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OffersHeroBannerProps } from './OffersHeroBanner.types';
import { StyledOffersHeroBanner } from './OffersHeroBanner.styles';
import { mockOffersHeroBannerData } from './__mock__/OffersHeroBanner.mock';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HeroBanner } from '@marriott/mi-homepage-components';
import { getMediaData, getMediaSource, getImageWithDomain, decodeHtml } from '../../utils/OfferUtils';
import { ASSET_VARIATION, PRIMARY_MEDIA } from '../../constants/CommonConstants';
import { ImageRenditionsData } from '../../utils/ImageRenditionStructure';

export const OffersHeroBannerWrapperConfig = {
  emptyLabel: 'Offers Hero Banner',
  isEmpty: false,
  resourceType: 'mi-aem-homepage-spa/components/content/herobanner',
};

export const OffersHeroBannerReactWrapper: React.FC<OffersHeroBannerProps> = props => {
  const { model, offersData, isAuthorMode } = props;
  const { offersHeroBanner } = ImageRenditionsData;
  const offersDataNode = !isAuthorMode
    ? offersData?.responseObject?.edges[0]
    : mockOffersHeroBannerData.offersData?.responseObject?.edges[0];
  const mediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        PRIMARY_MEDIA,
        offersHeroBanner?.aspectRatio?.backgroundMedia
      );
  const isMediaFromDAC = mediaSource === PRIMARY_MEDIA;
  const media = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersHeroBanner?.backgroundMedia,
        mediaSource,
        PRIMARY_MEDIA
      );
  const fileReferenceImageFromDAC = getImageWithDomain(
    offersData?.responseObject?.edges[0]?.node?.media?.secondaryImage?.imageSrc
  );

  const heroBannerData = {
    model: {
      backgroundMedia: isMediaFromDAC ? media : model?.backgroundMedia,
      appliedCssClassNames: offersDataNode?.merchandisingNode?.theme || model?.appliedCssClassNames,
      locationctalabel: offersDataNode?.merchandisingNode?.caption,
      locationctalink: offersDataNode?.merchandisingNode?.captionUrl,
      locationopeninanewtab: model?.locationopeninanewtab,
      eyebrowtext:
        fileReferenceImageFromDAC || model?.fileReferenceImage
          ? ''
          : decodeHtml(offersDataNode?.node?.descriptionTeaser) || model?.eyebrowtext,
      headertext: decodeHtml(offersDataNode?.node?.title) || model?.headertext,
      headertextstyle: model?.headertextstyle,
      subheadingtext: decodeHtml(offersDataNode?.node?.description) || model?.subheadingtext,
      primaryctalink: offersDataNode?.node?.callToActionLink || model?.primaryctalink,
      primaryctalabel: offersDataNode?.node?.callToActionLabel || model?.primaryctalabel,
      primaryopeninanewtab: model?.primaryopeninanewtab,
      secondaryctalink: offersDataNode?.merchandisingNode?.secondaryCallToActionLink || model?.secondaryctalink,
      secondaryctalabel: offersDataNode?.merchandisingNode?.secondaryCallToActionLabel || model?.secondaryctalabel,
      secondaryopeninanewtab: model?.secondaryopeninanewtab,
      fileReferenceBackgroundImagedesktop: isMediaFromDAC
        ? media?.fileReferenceBackgroundImage
        : model?.fileReferenceBackgroundImagedesktop,
      fileReferenceBackgroundImagemobile: isMediaFromDAC
        ? media?.fileReferenceBackgroundImage
        : model?.fileReferenceBackgroundImagemobile,
      fileReferenceImage: fileReferenceImageFromDAC ? fileReferenceImageFromDAC : model?.fileReferenceImage,
      assetsVariation: model?.assetsVariation ?? ASSET_VARIATION?.IMAGE,
    },
  };
  return (
    <StyledOffersHeroBanner data-testid="herobanner" data-component-name="o-offers-herobanner">
      <HeroBanner {...heroBannerData} />
    </StyledOffersHeroBanner>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersHeroBanner = (props: any) => (
  <EditableComponent config={OffersHeroBannerWrapperConfig} {...props}>
    <div className="m-container-fullbleed" data-testid="offers-hero-banner">
      <OffersHeroBannerReactWrapper {...props} />
    </div>
  </EditableComponent>
);
