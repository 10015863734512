/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState } from 'react';
import { Image, canUseDOM, respGridUtil, useCheckBreakpoint } from '@marriott/mi-ui-library';
import { LinksCollectionAlignment } from '@marriott/mi-ui-library';
import { TabProps, TabbedSearchFormProps, VacationLabelProps } from './TabbedSearchForm.types';
import { StyledTab } from './TabbedSearchForm.styles';
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { LinksCollection } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { TabComponent } from '@marriott/mi-ui-library';
import { MEETINGS_AND_EVENTS, clickTrackTabbedSFTitle, tabIcons } from '../../modules/constants';
import {
  ADV_SEARCH_FORM_CURR_DATE_ROOM_COUNT_ERROR_CODE,
  ADV_SEARCH_FORM_TEN_ROOMS_COUNT_ERROR_CODE,
  ADV_SEARCH_FORM_THREE_TO_TEN_ROOMS_COUNT_ERROR_CODE,
  ADV_SEARCH_FORM_VACATION_ROOM_CAR_LINK,
  ADV_SEARCH_FORM_VACATION_ROOM_FLIGHT_LINK,
  ADV_SEARCH_FROM_COMPI_ID,
  tabSelect,
} from '../../constants/lib/constants';
import { usePageContext } from '../../context';
import { updateDataLayerOnInteractions, updateDatalayerObjForAdvSearch } from '../../modules/utils/helpers';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { logger } from '@marriott/mi-headless-utils';
import { SELECTED_TAB, TABBED_SEARCHFORM_CONTAINER } from '../../modules/store/store.constants';
import { searchCounterValue } from '../../modules/utils';
import { SearchFormState } from '../../modules/store/searchFormStore';

if (global && !global.loggerInstance) {
  global.loggerInstance = logger({ requestID: '', sessionID: '' }) as CallableFunction;
}

export const TabbedSearchFormConfig = {
  emptyLabel: 'TabbedSearchFormConfig',
  isEmpty: () => true,
  resourceType: `mi-aem-shop-spa/components/content/tabbedsearchformwrapper/v1/tabbedsearchformwrapper`,
};

export const TabbedSearchFormContainer: FC<TabbedSearchFormProps> = (props: TabbedSearchFormProps) => {
  const { cqItems, isAuthorMode, itemPath, pagePath, tabbedSearchFormItems, numberOfSearchForms, searchFormsList } =
    props;
  // added for loop to call label from baseline search form
  let vacationLabels: VacationLabelProps = {};
  const tempItems: any = cqItems;
  for (const key in tempItems) {
    const items = tempItems[key][':items'];
    for (const item in items) {
      const labels = items[item];
      for (const label in labels) {
        if (label === ADV_SEARCH_FROM_COMPI_ID && labels[label] === tabSelect?.hotelsTab) {
          vacationLabels = labels;
        }
      }
    }
  }

  /**
   * shop store to access the data
   */
  const shopStoreInitialization = useSearchFormStore((state: SearchFormState) => state.shopStoreInitialization);
  const setSearchFormState = useSearchFormStore((state: SearchFormState) => state.setSearchFormState);
  const tabSelected = useSearchFormStore((state: SearchFormState) => state.selectedTab);
  const selectedTab = tabSelected?.selectedTab;
  const {
    sessionData,
    currentLocale,
    resolvedUrl,
    headersData,
    query,
    datalayerParams,
    datalayerProperties,
    ENABLE_ADV_SEARCH_DATALAYER_CALL,
  } = usePageContext();
  const searchFormRef = useRef<HTMLDivElement>(null); // search form main container div reference

  // updating tabbed form container flag on searchform store
  // to determine whether it from tabbedform or not
  setSearchFormState([TABBED_SEARCHFORM_CONTAINER], {
    [TABBED_SEARCHFORM_CONTAINER]: {
      tabbedSearchFormContainer: true,
    },
  });

  useEffect(() => {
    /**
     * on page load to set the session data in store
     */
    shopStoreInitialization(sessionData, vacationLabels);
    /**
     * Flag based DataLayer call for Advance Search Page
     */
    ENABLE_ADV_SEARCH_DATALAYER_CALL === 'true' && loadDatalayer();
    /**
     * on page refresh set the advanceSearchPageTab to the store from localStorage
     */
    const advanceSearchPageTab = localStorage?.getItem('advanceSearchPageTab');
    if (advanceSearchPageTab && advanceSearchPageTab === tabSelect?.hotels) {
      setSearchFormState([SELECTED_TAB], {
        [SELECTED_TAB]: {
          selectedTab: tabSelect?.hotelsTab,
        },
      });
      localStorage?.setItem('advanceSearchPageTab', tabSelect?.hotelsTab);
    } else if (advanceSearchPageTab && advanceSearchPageTab === tabSelect?.events) {
      setSearchFormState([SELECTED_TAB], {
        [SELECTED_TAB]: {
          selectedTab: tabSelect?.meetingsTab,
        },
      });
      localStorage?.setItem('advanceSearchPageTab', tabSelect?.meetingsTab);
    }
    advanceSearchPageTab &&
      setSearchFormState([SELECTED_TAB], {
        [SELECTED_TAB]: {
          selectedTab: advanceSearchPageTab,
        },
      });
    /**
     * function to redirect to the exact tab with the deeplink if selectedTab present in the URL
     */
    getParamVal();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      let searchFormTopPositionValue = searchFormRef.current?.offsetTop;
      const searchFormTopPositionValuePostScroll = searchFormRef.current?.getBoundingClientRect()?.top;
      if (searchFormTopPositionValuePostScroll && searchFormTopPositionValuePostScroll < 24) {
        searchFormTopPositionValue = 0;
      }
      if (searchFormTopPositionValue) {
        setSearchFormState(['searchFormTopPosition'], {
          searchFormTopPosition: {
            value: searchFormTopPositionValue - 24,
          },
        });
      } else {
        setSearchFormState(['searchFormTopPosition'], {
          searchFormTopPosition: {
            value: window.scrollY,
          },
        });
      }
    };
    let searchFormTopPositionValue = searchFormRef.current?.offsetTop;
    const searchFormTopPositionValuePostScroll = searchFormRef.current?.getBoundingClientRect()?.top;
    if (searchFormTopPositionValuePostScroll && searchFormTopPositionValuePostScroll < 24) {
      searchFormTopPositionValue = 0;
    }
    if (searchFormTopPositionValue) {
      setSearchFormState(['searchFormTopPosition'], {
        searchFormTopPosition: {
          value: searchFormTopPositionValue - 24,
        },
      });
    } else {
      setSearchFormState(['searchFormTopPosition'], {
        searchFormTopPosition: {
          value: window.scrollY,
        },
      });
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sessionID = sessionData?.sessionToken
    ? sessionData?.sessionToken
    : sessionData?.data?.sessionToken
    ? sessionData?.data?.sessionToken
    : sessionData?.cacheData?.key
    ? sessionData?.cacheData?.key
    : sessionData?.cacheData?.data?.sessionToken;

  const isTablet = useCheckBreakpoint('viewportM');

  // Create an array of Tab objects for rendering tabs
  const tabList = tabbedSearchFormItems?.map((item: TabProps) => ({
    tabValue: item?.componentId,
    tabTitle: item?.tabTitle,
    tabSubHeading: item?.tabSubHeading,
  }));

  // Flag to manage the selectedTab Param to change
  const [tabParamVal, setTabParamVal] = useState(true);

  /**
   * function to redirect to the exact tab with the deeplink if selectedTab present in the URL
   */
  const getParamVal = () => {
    // Extract selectedTab from the URL
    const Tab = new URLSearchParams(window?.location?.search)?.get('selectedTab');
    const advanceSearchPageTab = localStorage?.getItem('advanceSearchPageTab');

    if ((Tab && Tab === tabSelect?.hotels) || advanceSearchPageTab === tabSelect?.hotels) {
      setTabParamVal(false);
      setSearchFormState([SELECTED_TAB], {
        [SELECTED_TAB]: {
          selectedTab: tabSelect?.hotelsTab,
        },
      });
      localStorage?.setItem('advanceSearchPageTab', tabSelect?.hotelsTab);
    } else if ((Tab && Tab === tabSelect?.events) || advanceSearchPageTab === tabSelect?.events) {
      setTabParamVal(false);
      localStorage?.setItem('advanceSearchPageTab', tabSelect?.meetingsTab);
      setSearchFormState([SELECTED_TAB], {
        [SELECTED_TAB]: {
          selectedTab: tabSelect?.meetingsTab,
        },
      });
    }
  };

  // Function to toggle the active tab
  const toggleActiveComponents = (tabValue: string) => {
    //set selected tab value to session storage to retain the selected tab on page refresh or navigating back to the page, see useEffect with dependency 'canUseDOM'
    localStorage?.setItem('advanceSearchPageTab', tabValue);
    setTabParamVal(true);
    setSearchFormState([SELECTED_TAB], {
      [SELECTED_TAB]: {
        selectedTab: tabValue,
      },
    });
    const counter = 'advSearchCounter';
    searchCounterValue(counter);
    const counterValue = sessionStorage.getItem(counter) || '0';
    if (window && window.dataLayer) {
      window.dataLayer['updates_search_call_counter'] = counterValue;
    }
  };

  useEffect(() => {
    if (canUseDOM) {
      searchCounterValue('advSearchCounter');
    }
  }, [canUseDOM]);

  useEffect(() => {
    const counter = 'advSearchCounter';
    const countervValue = sessionStorage.getItem(counter) || '0';
    const Tab = new URLSearchParams(window?.location?.search)?.get('selectedTab');
    const params = new URLSearchParams(window.location.search);
    let selTab = selectedTab;
    /**
     * If the tabSelected is Base Line Search Form replace selectedTab with Hotels
     */
    if (selectedTab === tabSelect?.hotelsTab) {
      selTab = tabSelect?.hotels;
    } else if (selectedTab === tabSelect?.meetingsTab) {
      selTab = tabSelect?.events;
    }
    /**
     * set the TabValue in the params
     */
    tabParamVal && Tab && params.set('selectedTab', selTab);
    // Append the counter value to the window
    if (window && window.dataLayer) {
      window.dataLayer['updates_search_call_counter'] = countervValue;
    }
    /**
     * Effectively updates the browser history with a new state and URL
     * without triggering a full page reload.
     */
    window.history.replaceState({}, headersData?.referer, `${decodeURIComponent(`?${params}`)}#/${countervValue}/`);
  }, [tabSelected]);

  const errorMessages = useSearchFormStore((state: SearchFormState) => state?.errorMessage);
  const errorMessageKey = errorMessages?.errorMessages?.submitSearchFormErrorMessages?.errorMessageKeys[0];

  useEffect(() => {
    /**
     * If the rooms count is b/w 10-25 and after the submit btn click
     * redirects to the meetings form with the error message
     */

    if (
      errorMessageKey === ADV_SEARCH_FORM_TEN_ROOMS_COUNT_ERROR_CODE ||
      errorMessageKey === ADV_SEARCH_FORM_CURR_DATE_ROOM_COUNT_ERROR_CODE ||
      errorMessages?.errorMessages?.submitSearchFormErrorMessages?.errorFields?.['roomCount.extended'] === true
    ) {
      localStorage?.setItem('advanceSearchPageTab', tabSelect?.meetingsTab);
      setSearchFormState([SELECTED_TAB], {
        [SELECTED_TAB]: {
          selectedTab: tabSelect?.meetingsTab,
        },
      });
    } else if (errorMessageKey === ADV_SEARCH_FORM_THREE_TO_TEN_ROOMS_COUNT_ERROR_CODE) {
      localStorage?.setItem('advanceSearchPageTab', tabSelect?.hotelsTab);
      setSearchFormState([SELECTED_TAB], {
        [SELECTED_TAB]: {
          selectedTab: tabSelect?.hotelsTab,
        },
      });
    }
  }, [errorMessageKey]);

  // Function to return the exact form items order
  const getItemsSorted = (formItem: any) => {
    const Items = Object.entries(formItem[':items']);
    const itemsOrder = formItem[':itemsOrder'];
    return Object.fromEntries(Items.sort((a: any, b: any) => itemsOrder.indexOf(a[0]) - itemsOrder.indexOf(b[0])));
  };

  const loadDatalayer = async (): Promise<void> => {
    await updateDataLayerOnInteractions(resolvedUrl, headersData, query, datalayerParams, currentLocale, sessionID);
    updateDatalayerObjForAdvSearch(window?.dataLayer ?? {}, datalayerProperties);
  };

  // Method to return Responsive Grid for showing parsys in Author mode
  const aemParsyserContainer = (index: number) => {
    return (
      <div className={clsx('col-12')}>
        <ResponsiveGrid
          gridClassNames={`col-12`}
          columnClassNames={{
            responsivegrid: `col-12`,
          }}
          customClassName={`p-0 m-0`}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pagePath}
          itemPath={`${itemPath}/${searchFormsList[index]}`}
          config={{
            isEmpty: () => true,
            resourceType: 'mi-aem-common-spa/components/container',
          }}
        />
      </div>
    );
  };

  /**
   * tabsComponentsContainer returns the components to be displayed on UI in the Authored Order
   */
  const tabsComponentsContainer = (ItemsOrder: string, cqItems: any) => {
    delete cqItems['tabbedSearchFormItems'];
    if (Object.prototype.hasOwnProperty.call(cqItems, ItemsOrder)) {
      const form = cqItems[ItemsOrder];
      const searchFormName: any = getItemsSorted(form);
      const renderGrid = () => {
        return (
          <ResponsiveGrid
            model={respGridUtil(props.model.cqItems[ItemsOrder])}
            {...respGridUtil(props.model.cqItems[ItemsOrder])}
          />
        );
      };
      for (const itemKey in searchFormName) {
        if (selectedTab?.toLowerCase() === searchFormName[itemKey]?.componentId?.toLowerCase()) {
          return renderGrid();
        }
      }
      return null;
    }
    return null;
  };

  const dynamicMed = (index: number) => {
    return tabbedSearchFormItems[index]?.dynamicMedia;
  };

  return (
    <StyledTab
      data-component-name="o-shop-tabbedsearchformwrapper"
      data-testid="tabbedsearchformwrapper"
      $isMeetingsTab={selectedTab === tabSelect?.meetingsTab}
      className={clsx('tabbed-sf-wrapper', 'color-scheme3')}
    >
      {tabList?.map((tab: TabProps, idx: number) => {
        const { tabValue } = tab;

        const dynamicMedia = dynamicMed(idx);
        const dynamicMedia2 = selectedTab === MEETINGS_AND_EVENTS ? dynamicMed(idx) : dynamicMed(idx + 1);

        return (
          selectedTab === tabValue && (
            <div>
              <div className={clsx('image-container', selectedTab === MEETINGS_AND_EVENTS && 'hide-image')}>
                <Image
                  renditions={dynamicMedia?.renditions}
                  dynamic={dynamicMedia?.dynamic}
                  defaultImageURL={dynamicMedia?.assetPath}
                  altText={dynamicMedia?.altText ? dynamicMedia?.altText : ''}
                />
              </div>
              <div className={clsx('image-container', selectedTab !== MEETINGS_AND_EVENTS && 'hide-image')}>
                <Image
                  renditions={dynamicMedia2?.renditions}
                  dynamic={dynamicMedia2?.dynamic}
                  defaultImageURL={dynamicMedia2?.assetPath}
                  altText={dynamicMedia2?.altText ? dynamicMedia2?.altText : ''}
                />
              </div>
            </div>
          )
        );
      })}
      <div
        ref={searchFormRef}
        className={clsx(
          'container',
          'tabbed-container',
          !isTablet && 'color-scheme1',
          'px-4 px-md-0 px-lg-0',
          'd-flex align-items-center'
        )}
      >
        <TabComponent
          selectedTab={selectedTab}
          tabList={tabList}
          clickTrackingLoc={clickTrackTabbedSFTitle}
          setSelectedTab={(value: string) => toggleActiveComponents(value)}
          tabContainerClass={`m-icon-tab-wrapper px-0 px-md-5`}
          customClass={`m-icon-tab-list mr-2`}
          iconClass={`m-icon-tab-list-item col-sm-6 col-md-auto`}
          isIconTabs={true}
          tabIcon={tabIcons}
          SubHeadingClass="m-icon-tab-list-title sub-heading"
          subTitleClass="m-icon-tab-list-item-heading"
          classTabsEnabled="color-scheme1 icon-tabs-container"
        >
          {/* Render form containers based on the mode */}
          {isAuthorMode && Array.from({ length: numberOfSearchForms }, (_, i) => aemParsyserContainer(i))}
          {!isAuthorMode &&
            Array.from({ length: numberOfSearchForms }, (_, i) => {
              const formName = searchFormsList[i];
              const container = tabsComponentsContainer(formName, cqItems);
              if (container !== null) {
                return <div key={formName}>{container}</div>;
              }
              return null;
            })}
        </TabComponent>
      </div>
      {vacationLabels?.['enableVacationPackages'] && selectedTab === tabSelect?.hotelsTab && (
        <div className={clsx('container d-flex vacations color-scheme3', 'flex-md-row flex-lg-row flex-column')}>
          <div className="vacation-text">{vacationLabels?.['vacationPackagesText']}</div>
          {/* to display on desktop and tablet */}
          <div className="accomdation d-none d-md-flex d-lg-flex">
            <LinksCollection
              linkListData={[
                {
                  linkURL: ADV_SEARCH_FORM_VACATION_ROOM_FLIGHT_LINK,
                  linkLabel: vacationLabels?.['roomsAndFlightCta'],
                  openInNewTab: true,
                },
                {
                  linkURL: ADV_SEARCH_FORM_VACATION_ROOM_CAR_LINK,
                  linkLabel: vacationLabels?.['roomAndCarCta'],
                  openInNewTab: true,
                },
              ]}
              alignment={'horizontal-align' as LinksCollectionAlignment.Horizontal}
              trackingProperties={{
                clickTrack: true,
                location: clickTrackTabbedSFTitle,
              }}
              linkListClassName="standard"
            ></LinksCollection>
          </div>
          {/* to display on mobile */}
          <div className="accomdation d-flex d-md-none d-lg-none">
            <LinksCollection
              linkListData={[
                {
                  linkURL: ADV_SEARCH_FORM_VACATION_ROOM_FLIGHT_LINK,
                  linkLabel: vacationLabels?.['roomsAndFlightCta'],
                  openInNewTab: true,
                },
                {
                  linkURL: ADV_SEARCH_FORM_VACATION_ROOM_CAR_LINK,
                  linkLabel: vacationLabels?.['roomAndCarCta'],
                  openInNewTab: true,
                },
              ]}
              alignment={'vertical-align' as LinksCollectionAlignment.Vertical}
              trackingProperties={{
                clickTrack: true,
                location: clickTrackTabbedSFTitle,
              }}
              linkListClassName="standard"
            ></LinksCollection>
          </div>
        </div>
      )}
    </StyledTab>
  );
};

export const TabbedSearchForm = (props: TabbedSearchFormProps) => {
  return (
    <EditableComponent config={TabbedSearchFormConfig} {...props}>
      <TabbedSearchFormContainer {...props} />
    </EditableComponent>
  );
};
