import { EditableComponent } from '@adobe/aem-react-editable-components';
import { GeneralMessage as GeneralMessageComp, GeneralMessageProps } from '@marriott/mi-ui-library';

export const GeneralMessageConfig = {
  emptyLabel: 'GeneralMessage',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/generalmessage`,
};

export const GeneralMessage = (props: GeneralMessageProps) => {
  const updatedProps = { ...props, ARIA_EXPAND: 'Expand Message', ARIA_COLLAPSE: 'Collapse Message' };
  return (
    <div data-testid="general-message" data-component-name="o-common-static-generalmessage">
      <EditableComponent config={GeneralMessageConfig} {...props}>
        <GeneralMessageComp {...updatedProps} />
      </EditableComponent>
    </div>
  );
};
