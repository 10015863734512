import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';

export const StyledFeaturedOutlets = styled.div<{ variation?: string }>`
  background: ${props => (props?.variation === 'culinaryView' ? 'inherit' : `${baseVariables.color.neutral20}`)};

  .glide__track {
    overflow: hidden;
  }
  .property-card__details-container {
    height: calc(100% - 50%);
  }
  @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl}, @media ${baseVariables.mediaQuery.md} {
    .property-card__details-container {
      height: inherit;
    }
  }
`;
