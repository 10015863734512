// Styles for OffersHeroBanner go here.
import styled from 'styled-components';

export const StyledOffersHeroBanner = styled.div.attrs({
  className: 'offersCustomHeroBanner',
})`
  margin-bottom: 2.5rem;
  .herobanner-fullbleed {
    float: none !important;
  }
`;
