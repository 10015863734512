/* eslint-disable @typescript-eslint/no-var-requires */
import phoenixOfferDetailsQuery from './lib/phoenixOfferDetails.graphql';
import phoenixOfferDetailsPreviewQuery from './lib/phoenixOfferDetailsPreview.graphql';
import phoenixOffersSearchForm from './lib/phoenixOffersSearchForm.graphql';
import phoenixOfferCardListingQuery from './lib/phoenixOfferCardListing.graphql';
import phoenixOfferSuggestedPlacesQuery from './lib/phoenixOfferSuggestedPlacesQuery.graphql';
import phoenixOfferSuggestedPlacesDetailsQuery from './lib/phoenixOfferSuggestedPlacesDetailsQuery.graphql';
import phoenixOffersCarousel from './lib/phoenixOffersCarousel.graphql';
import phoenixOffersFallbackOffers from './lib/phoenixOffersFallbackOffers.graphql';
import phoenixOfferPreview from './lib/phoenixOfferPreview.graphql';
import phoenixOffersCardListingPreview from './lib/phoenixOffersCardListingPreview.graphql';
import phoenixOfferParticipatingProperties from './lib/phoenixOfferParticipatingProperties.graphql';
import phoenixOfferParticipatingPropertiesMarsha from './lib/phoenixOfferParticipatingPropertiesMarsha.graphql';
import phoenixLowestAvailableRates from './lib/phoenixLowestAvailableRates.graphql';
import phoenixOfferDetailsForOutlets from './lib/phoenixOfferDetailsForOutlets.graphql';
import phoenixOfferDetailsPreviewForOutlets from './lib/phoenixOfferDetailsPreviewForOutlets.graphql';
import phoenixNearbyDestinations from './lib/phoenixNearbyDestinations.graphql';
import PhoenixOffersSearchByArea from './lib/PhoenixOffersSearchByArea.graphql';
import PhoenixOffersSearchByGeolocation from './lib/PhoenixOffersSearchByGeolocation.graphql';
import phoenixOffersListSearch from './lib/phoenixOffersListSearch.graphql';

export {
  phoenixOfferDetailsQuery,
  phoenixOfferDetailsPreviewQuery,
  phoenixOffersSearchForm,
  phoenixOfferSuggestedPlacesQuery,
  phoenixOfferCardListingQuery,
  phoenixOfferSuggestedPlacesDetailsQuery,
  phoenixOffersCarousel,
  phoenixOffersFallbackOffers,
  phoenixOfferPreview,
  phoenixOffersCardListingPreview,
  phoenixOfferParticipatingProperties,
  phoenixOfferParticipatingPropertiesMarsha,
  phoenixLowestAvailableRates,
  phoenixOfferDetailsForOutlets,
  phoenixOfferDetailsPreviewForOutlets,
  phoenixNearbyDestinations,
  PhoenixOffersSearchByArea,
  PhoenixOffersSearchByGeolocation,
  phoenixOffersListSearch,
};
