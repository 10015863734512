import { FC } from 'react';
import clsx from 'clsx';

import { StyledRadioButtonDiv } from './index.styles';
import { RadioButtonProps } from './index.types';

export const RadioButton: FC<RadioButtonProps> = ({
  className,
  radiobuttonId,
  radiobuttonName,
  radiobuttonLabel,
  checked,
  value,
  onChange,
  disabled,
  setTabIndex,
  customClickClass,
  isRichText,
  labelClassName,
  tabIndexForInput,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      onChange && onChange(e);
    }
  };
  return (
    <StyledRadioButtonDiv className={className}>
      <input
        type="radio"
        id={radiobuttonId}
        data-testid={`${radiobuttonName}-testId`}
        name={radiobuttonName}
        onChange={onChange}
        checked={checked}
        value={value}
        disabled={disabled}
        aria-checked={checked}
        aria-label=" "
        tabIndex={tabIndexForInput}
        onKeyDown={handleKeyDown}
        className={customClickClass}
        {...props}
      />
      {isRichText ? (
        <label className={clsx(labelClassName ? labelClassName : '')} htmlFor={radiobuttonId}>
          <a tabIndex={setTabIndex} className={clsx('keydown-anchor', disabled ? 'disabled' : '')} role="link">
            {radiobuttonLabel}
          </a>
        </label>
      ) : (
        <label className={clsx(labelClassName ? labelClassName : '')} htmlFor={radiobuttonId}>
          {radiobuttonLabel}
        </label>
      )}
    </StyledRadioButtonDiv>
  );
};
export default RadioButton;
