export const MOCK_SESSION_ID = '7FC3A108-DF69-5E10-B767-6D2FB461B924';
export const AUTHENTICATED = 'authenticated';

export const DATALAYER_CONSTANT = {
  headerKeys: [
    'accept-language',
    'user-agent',
    'x-request-id',
    'x-service-id',
    'x-forwarded-host',
    'x-host',
    'x-xm-site-id',
    'x-lite-site',
    'x-request-uri',
    'x-request-host',
    'sec-fetch-user',
    'x-forwarded-for',
    'x-schema',
    'sec-fetch-mode',
    'x-forwarded-port',
    'sec-fetch-site',
    'sec-fetch-dest',
    'x-forwarded-proto',
    'referer',
    'forwarded',
    'messageid',
    'x-akamai-edgescape',
    'x-akamai-device-characteristics',
    'x-client-ip',
    'x-akamai-config-log-detail',
    'akamai-origin-hop',
    'digitalserviceswitch',
  ],
};

export const HYBRID_CONFIG_CONSTANTS = {
  ROUTING_CONFIG: 'routingConfig',
  EXPERIENCE_SEGMENT: ['NOT Logged In'],
  EXPERIENCE_SEGMENT_AUTHENTICATED: ['Logged In'],
  MOCK_SESSION_ID: '7FC3A108-DF69-5E10-B767-6D2FB461B924',
};

export const PPV5_URL_FORMAT = 'einterface/v5';

export const DCA_CONSTANTS = {
  gcv: 'AMCV_664516D751E565010A490D4C@AdobeOrg',
  MCMID_COOKIE: 'MCMID',
  orgId: '664516D751E565010A490D4C@AdobeOrg',
  trackingServer: 'metrics.marriott.com',
  trackingServerSecure: 'smetrics.marriott.com',
  marketingCloudServer: 'metrics.marriott.com',
  marketingCloudServerSecure: 'smetrics.marriott.com',
};

export const DEFAULT_ACCEPT_LANGUAGE = 'en-US';
export const ACCEPT_LANGUAGE_TEST_REGEX = /^[a-z]{2}(-[A-Z]{2})?$/;

export const DATA_LAYER_ATTRIBUTES = {
  cookie_mi_site: '',
  request_id: '',
  browser_akamai_loc_country: '',
  env_date_time: '',
  env_is_prod: '', // AEM
  env_platform: '', // AEM
  env_server_id: '',
  env_site_id: '',
  page_requested_uri: '',
  page_url_query_string: '',
  sessionId: '',
  browser_akamai_loc_lat: '',
  browser_akamai_loc_long: '',
  browser_akamai_loc_city: '',
  cookie_personalization_guid: '',
  device_language_preferred: '',
  env_language: '',
  site_id: '',
  bot_cookie_info: '',
  browser_akamai_loc_state: '',
  previous_page: '',
};

export const DATA_LAYER_IGNORE_LIST = [
  'roomkey_api_key',
  'roomkey_api_version',
  'ensighten_settings',
  'rk_enabled',
  'rk_errorMessage',
  'rk_roomKeyBaseURL',
  'invokeFlushErrors',
  'rk_isProfileAuthenticated',
  'rk_currency',
  'rk_cList',
  'mvp_prequal_endpoint',
];

export const DATA_LAYER_CAN_BE_NULL_LIST = ['browser_akamai_loc_city'];

export const COOKIE_KEYS = {
  personalization: 'Personalization',
  miCnSite: 'MI_CN_SITE',
  miSite: 'MI_SITE',
};

// keys in this object are datalayer keys and values are cookie names
export const PI_DATA = {
  altCustId: 'dtm_user_id, customerId',
  mr_id_alternate: 'dtm_user_id, customerId',
  sessionId: 'sessionID',
  session_jsession_id: 'sessionID',
  cookie_mi_visitor: 'sessionID',
};
