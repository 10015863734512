/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState, KeyboardEvent, useRef, useContext } from 'react';
import moment from 'moment';
import { StyledDatePickerDiv, StyledFooterDatePickerDiv } from './Datepicker.styles';
import { DatePickerProps } from './Datepicker.types';
import {
  SHORT_WEEK_DAYS,
  LONG_WEEK_DAYS_NAME,
  SHORT_MONTH_NAME,
  SHORT_WEEK_DAYS_NAME,
  FLEXIBLE_FORMAT_YEAR_MONTH,
  CN_FLEXIBLE_FORMAT_YEAR_MONTH,
  MAX_NUMBER_OF_NIGHTS_ALLOWED,
  DATE_MOBILE_FORMAT_DEFAULT,
} from '../../modules/constants';
import {
  DropDownModal,
  getLocalizedDate,
  InputTextField,
  TabComponent,
  Button,
  getCurrentDateObject,
  getNextDateObject,
  getTotalNumberOfDays,
  getValueFromDate,
  DateObjectType,
  useCheckBreakpoint,
} from '@marriott/mi-ui-library';
import clsx from 'clsx';
import DatePickerExact from './Datepicker.exact';
import DatePickerFlexible from './Datepicker.flexible';
import { useStaticDataContext } from '../../modules/context';
import { PageContext } from '../../context/PageContext';
import { SearchFormState, useSearchFormStore } from '../../modules/store/searchFormStore';
import { LocaleUtils } from 'react-day-picker';
import { DATES, MEETINGS_DATES } from '../../modules/store/store.constants';
import { searchFormClickTrackingLoc, datepickerResetClickTrackingLoc, tabSelect } from '../../constants/lib/constants';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';
import { getCustomFormattedDate } from '../../modules/utils';
import { generateRandomNumber } from '@marriott/shared/mi-helper-utils';
import { useLazyQuery } from '@apollo/client';
import { phoenixShopAdvSearchInventoryDate } from '../SearchField/queries';
import { updateSessionInSearchForm } from '../../utils/src/helper';
import { usePersistentGlobalStore } from '@marriott/mi-store-utils';
import { convertToUpperCase } from '../../utils/src/utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { DEFAULT_GROUP_DATE_LIMIT, DEFAULT_SINGLE_DATE_LIMIT } from '../../constants/Calendar.constant';

const DatePicker: FC<DatePickerProps> = ({
  onDatePickerChange,
  onDatePickerCancel,
  isModalOpen,
  isMobileTestView,
  alignRight = false,
  hideTabHeader = false,
  setDefaultDate = false,
  isMobileForm,
  isSearchFormSticky,
}) => {
  const clickTrackingLoc = 'Search Form Dates Module';
  /**
   * search form store
   */
  const dates = useSearchFormStore((state: SearchFormState) => state.dates);
  const setSearchFormState = useSearchFormStore((state: SearchFormState) => state.setSearchFormState);
  const tabSelected = useSearchFormStore((state: SearchFormState) => state.selectedTab);
  const eventsDates = useSearchFormStore((state: SearchFormState) => state.eventsDates);
  const selectedTab = tabSelected?.selectedTab;
  const pageContext = useContext(PageContext);
  const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData);
  const globalData = usePersistentGlobalStore((state: any) => state.globalData);

  const searchFormTopPosition = useSearchFormStore((state: any) => state?.searchFormTopPosition);

  /** all the data from store */
  const { fromDate, toDate, flexible = false, numberOfNights: nightCount = 1 } = dates ?? {};
  const { eventsFromDate, eventsToDate, numberOfNights: meetingsNightCount = 0 } = eventsDates ?? {};

  const {
    exactDatesTabText,
    flexibleTabText,
    stayDates,
    dateModalHeader,
    night,
    nights,
    nightLabel,
    weekdays,
    weekdaysLong,
    months,
    weekdaysShort,
    monthsShort,
    maxNumberOfNights,
    dateHelperText,
    dateHelperTextMobile,
    clear,
    enableFlexibleSearch,
    datesSearchLabel,
    isTabbedSearchForm,
    flexibleDatesToggle,
    submitSpecificDateCta,
    resetSpecificDateCta,
    flexibleDatesSubmitCta,
    currentLocale = pageContext?.currentLocale,
    dateFormat,
  } = useStaticDataContext();

  /** States */
  const [datepickerPopupOpenState, setDatepickerPopupOpenState] = useState(isModalOpen ?? false);
  const [resetFlag, setResetFlag] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showBadge, setShowBadge] = useState(isTabbedSearchForm ?? false);
  const hideFlexibleDateFlow = enableFlexibleSearch === true ? false : true;
  const [dateTextFont, setDateTextFont] = useState('date-text-font');
  const [singleDateLimit, setSingleDateLimit] = useState(
    globalData?.sessionData?.cacheData?.data?.AriesCommon?.singleDateLimit
  );
  const [groupDateLimit, setGroupDateLimit] = useState(
    globalData?.sessionData?.cacheData?.data?.AriesCommon?.groupDateLimit
  );
  const queryCommonContext = {
    /**
     * define common params for all the query
     */
    context: {
      headers: {
        'x-request-id': `${Date.now()}`,
        'accept-language': currentLocale?.replace('_', '-'),
      },
    },
  };

  useEffect(() => {
    if (hideFlexibleDateFlow) {
      setCurrentActiveTab(tab1Label);
      if (flexible) {
        handleDateSelection(eventsFromDate, eventsToDate, false, false);
      }
    } else {
      setCurrentActiveTab(tab2Label);
    }
  }, [hideFlexibleDateFlow]);
  const callUpdateGlobalDate = (data: {
    advancedReservationDateLimit: { singleDateLimit: { value: any }; groupDateLimit: { value: any } };
  }) => {
    const initialSessionData = globalData?.sessionData ?? {};
    const initalCacheData = initialSessionData?.cacheData ?? {};
    const initalData = initalCacheData?.data ?? {};
    const initalAriesCommonData = initalData?.AriesCommon ?? {};
    const sessionData = {
      ...initialSessionData,
      cacheData: {
        ...initalCacheData,
        data: {
          ...initalData,
          AriesCommon: {
            ...initalAriesCommonData,
            singleDateLimit: data?.advancedReservationDateLimit?.singleDateLimit?.value,
            groupDateLimit: data?.advancedReservationDateLimit?.groupDateLimit?.value,
          },
        },
      },
    };
    updateGlobalData('sessionData', sessionData);
  };

  const [getInventoryDates] = useLazyQuery(phoenixShopAdvSearchInventoryDate, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: data => {
      let singleDateLimit;
      let groupDateLimit;
      if (
        data?.advancedReservationDateLimit?.singleDateLimit?.value &&
        data?.advancedReservationDateLimit?.groupDateLimit?.value
      ) {
        singleDateLimit = data?.advancedReservationDateLimit?.singleDateLimit?.value;
        groupDateLimit = data?.advancedReservationDateLimit?.groupDateLimit?.value;
        updateSessionInSearchForm(
          globalData?.sessionData,
          useClientEnvVarsStore.getState().envVarsObject['SESSION_APP_POST_CALL_URL'],
          pageContext?.NEXT_PUBLIC_PREFIX,
          singleDateLimit,
          groupDateLimit
        );
        callUpdateGlobalDate({
          advancedReservationDateLimit: {
            singleDateLimit: {
              value: singleDateLimit,
            },
            groupDateLimit: {
              value: groupDateLimit,
            },
          },
        });
      } else {
        singleDateLimit = moment().add(DEFAULT_SINGLE_DATE_LIMIT, 'd');
        groupDateLimit = moment().add(DEFAULT_GROUP_DATE_LIMIT, 'd');
      }
      setSingleDateLimit(singleDateLimit);
      setGroupDateLimit(groupDateLimit);
    },
    onError: () => {
      const singleDateLimit = moment().add(DEFAULT_SINGLE_DATE_LIMIT, 'd');
      const groupDateLimit = moment().add(DEFAULT_GROUP_DATE_LIMIT, 'd');
      setSingleDateLimit(singleDateLimit);
      setGroupDateLimit(groupDateLimit);
    },
  });

  useEffect(() => {
    if (isTabbedSearchForm && badgeValue() !== '') {
      setShowBadge(true);
    } else {
      setShowBadge(false);
    }
  }, [isTabbedSearchForm, nightCount]);
  const [_totalNights, setTotalNights] = useState(0);
  const maxFlexibleNumberOfNights = maxNumberOfNights ? Number(maxNumberOfNights) : MAX_NUMBER_OF_NIGHTS_ALLOWED;

  const tab1Label = 'exactDate'; // hardcoded tab value for tab switch
  const tab2Label = 'flexibleDate';
  const [currentActiveTab, setCurrentActiveTab] = useState<string>(
    selectedTab !== tabSelect?.meetingsTab ? (flexible ? tab2Label : tab1Label) : tab1Label
  );
  // handle current active tab
  useEffect(() => {
    if (flexible && selectedTab !== tabSelect?.meetingsTab) {
      setCurrentActiveTab(tab2Label);
    } else {
      setCurrentActiveTab(tab1Label);
    }
  }, [flexible]);
  const isLargeDesktop = useCheckBreakpoint('viewportXl');
  const isDesktop = useCheckBreakpoint('viewportL');
  const isTabletView = useCheckBreakpoint('viewportM'); // check if viewport is for mobile, to change the calendar month
  const isMobileView = isMobileTestView ?? !isDesktop;
  const tabList = [
    {
      tabTitle: exactDatesTabText,
      tabSubtitle: '',
      tabValue: tab1Label,
      isActive: !flexible ? true : false,
    },
  ];
  if (enableFlexibleSearch || flexibleDatesToggle) {
    tabList.push({
      tabTitle: flexibleTabText,
      tabSubtitle: '',
      tabValue: tab2Label,
      isActive: flexible ? true : false,
    });
  }

  /** required value for calendar
   * these value updates as per locale
   * we will get week days infromation from AEM
   * update AEM value and fallback
   */
  let shortWeekdays = weekdays?.split(',');
  // set default weekdays if i18 weekdays value are invalid
  if (shortWeekdays?.length !== 7) {
    shortWeekdays = SHORT_WEEK_DAYS;
  }

  let weeksdaysShort = weekdaysShort?.split(',');
  // set default weekdays if i18 weekdays value are invalid
  if (weeksdaysShort?.length !== 7) {
    /** fallback value if we are not getting this details from AEM */
    weeksdaysShort = SHORT_WEEK_DAYS_NAME;
  }

  let weekdaysLongName = weekdaysLong?.split(',');
  if (weekdaysLongName?.length !== 7) {
    /** fallback value if we are not getting this details from AEM */
    weekdaysLongName = LONG_WEEK_DAYS_NAME;
  }

  let monthsValue = months?.split(',');
  // set default month if i18 months value are invalid
  if (monthsValue?.length !== 12) {
    monthsValue = LocaleUtils.getMonths();
  }

  let monthsShortValue = monthsShort?.split(',');
  // set default month if i18 months value are invalid
  if (monthsShortValue?.length !== 12) {
    monthsShortValue = SHORT_MONTH_NAME;
  }

  const setLanguageLocalization = (): void => {
    /**
     * localization for moment to get the date formatted
     */
    moment.locale('locale', {
      months: monthsValue,
      monthsShort: monthsShortValue,
      weekdays: weekdaysLongName,
      weekdaysShort: weeksdaysShort,
    });
  };

  useEffect(() => {
    if (datepickerPopupOpenState && isMobileView) {
      //Can't access body element without this selector.
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      inputElementRef.current?.focus();
    }
    return () => {
      //Can't access body element without this selector.
      document.getElementsByTagName('body')[0].style.overflow = 'initial';
    };
  }, [datepickerPopupOpenState, isMobileView]);

  // effect to handle the changes after popup state is set to open

  useEffect(() => {
    /**
     * setLocalization on moment object
     */
    setLanguageLocalization();

    if (!setDefaultDate) {
      resetButtonHandler();
    }
  }, []);

  useEffect(() => {
    if (!singleDateLimit) {
      getInventoryDates({
        ...queryCommonContext,
      });
    }
  }, []);

  // if from and to dates not available then show 'Check in - Check out' else "fromDate - toDate"
  let displayText = '';
  if ((fromDate || toDate) && !flexible && selectedTab !== tabSelect?.meetingsTab) {
    if (isDesktop && !isLargeDesktop && !isTabbedSearchForm) {
      // added for device width 992 to 1200px for HP only
      displayText = `${
        fromDate
          ? getCustomFormattedDate(getLocalizedDate(fromDate, DATE_MOBILE_FORMAT_DEFAULT, currentLocale), currentLocale)
          : ''
      }${
        toDate
          ? ` - ${getCustomFormattedDate(
              getLocalizedDate(toDate, DATE_MOBILE_FORMAT_DEFAULT, currentLocale),
              currentLocale
            )}`
          : ''
      }`;
    } else {
      displayText = `${
        fromDate ? getCustomFormattedDate(getLocalizedDate(fromDate, dateFormat, currentLocale), currentLocale) : ''
      }${
        toDate ? ` - ${getCustomFormattedDate(getLocalizedDate(toDate, dateFormat, currentLocale), currentLocale)}` : ''
      }`;
    }
  } else {
    selectedTab !== tabSelect?.meetingsTab
      ? (displayText = fromDate
          ? convertToUpperCase(
              getLocalizedDate(
                fromDate,
                isCNLocale(currentLocale) ? CN_FLEXIBLE_FORMAT_YEAR_MONTH : FLEXIBLE_FORMAT_YEAR_MONTH,
                currentLocale
              ),
              currentLocale
            )
          : '')
      : (displayText = `${
          eventsFromDate
            ? getCustomFormattedDate(getLocalizedDate(eventsFromDate, dateFormat, currentLocale), currentLocale)
            : ''
        }${
          eventsToDate
            ? ` - ${getCustomFormattedDate(getLocalizedDate(eventsToDate, dateFormat, currentLocale), currentLocale)}`
            : ''
        }`);
  }

  const resetButtonHandler = () => {
    const from = getCurrentDateObject();
    const end = getNextDateObject(from);
    handleDateSelection(from, end, flexible ? true : false, true);
    /**
     * Set the flag to true on reset btn click
     */
    setResetFlag(true);
    setTotalNights(generateRandomNumber());
  };

  // handle dynamic css for date text font
  useEffect(() => {
    switch (true) {
      case displayText.length >= 45:
        setDateTextFont('date-text-font-xl');
        break;
      case displayText.length >= 35 && displayText.length < 45:
        setDateTextFont('date-text-font-l');
        break;
      case displayText.length >= 30 && displayText.length < 35:
        setDateTextFont('date-text-font-m');
        break;
      default:
        setDateTextFont('date-text-font');
        break;
    }
  }, [displayText]);

  const getTotalNumberOfNights = (from: DateObjectType, end: DateObjectType, flexi = false) => {
    /**
     * in case of flexible it will return the max number of nights
     */
    const totalnNights = getTotalNumberOfDays(from, end);
    setTotalNights(totalnNights);
    if (totalnNights > maxFlexibleNumberOfNights && flexi) {
      //  return if user has enterd max number of nights in case of fkexible
      return true;
    }
    // other cases return false
    return false;
  };

  const handleDateSelection = (
    startDate: DateObjectType | null | undefined,
    endDate: DateObjectType | null | undefined,
    flexible = false,
    isReset = false
  ) => {
    /**
     * handle date selection
     */
    const today = getCurrentDateObject();
    let from: moment.Moment | null | undefined = startDate
      ? startDate
      : selectedTab === tabSelect?.meetingsTab
      ? null
      : today;
    let end: moment.Moment | null | undefined = endDate && endDate;
    if (from && end && getTotalNumberOfNights(from, end, flexible)) {
      // reset date in case of flexible if we have more then 9 nights
      const date = from.startOf('month');
      from = date.isBefore(today) ? today : date;
      end = getNextDateObject(from);
    }
    if (end?.isBefore(from)) {
      end = null;
    }
    if (flexible) {
      /**
       * seperate store value's for meetings form
       */
      selectedTab !== tabSelect?.meetingsTab
        ? setSearchFormState([DATES], {
            ...dates,
            [DATES]: {
              ...dates,
              fromDate: from,
              toDate: end,
              flexible: flexible,
              checkInDate: from && !flexible ? getValueFromDate(from) : '', // empty checkin date for flexible
              checkOutDate: end && !flexible ? getValueFromDate(end) : '', // empty checkout date for flexible
              numberOfNights:
                from && end && !getTotalNumberOfNights(from, end, flexible)
                  ? Number(getTotalNumberOfDays(from, end)?.toFixed(0) || 1)
                  : 1,
              lengthOfStay:
                from && end && !getTotalNumberOfNights(from, end, flexible) ? getTotalNumberOfDays(from, end) : 1,
            },
          })
        : setSearchFormState([MEETINGS_DATES], {
            //TODO: to use the destructuring dates from meetings session.
            // ...dates,
            [MEETINGS_DATES]: {
              //TODO: to use the destructuring dates from meetings session.
              // ...dates,
              eventsLastDefaultDate: '',
              eventsFromDate: from,
              eventsToDate: end,
              checkInDate: from && getValueFromDate(from), // empty checkin date for flexible
              checkOutDate: end && getValueFromDate(end), // empty checkout date for flexible
              numberOfNights:
                from && end && !getTotalNumberOfNights(from, end, false)
                  ? Number(getTotalNumberOfDays(from, end)?.toFixed(0) || 0)
                  : 0,
              lengthOfStay:
                from && end && !getTotalNumberOfNights(from, end, false) ? getTotalNumberOfDays(from, end) : 1,
            },
          });
      const total = getTotalNumberOfDays(from, end);
      const month = moment(from).month();
      const year = moment(from).year();

      if (total === 1 && month === moment().month() && year === moment().year()) {
        setShowIcon(false);
      } else {
        setShowIcon(true);
      }
    } else {
      if (isReset) {
        /**
         * seperate store value's for meetings form
         */
        selectedTab !== tabSelect?.meetingsTab
          ? setSearchFormState([DATES], {
              ...dates,
              [DATES]: {
                ...dates,
                fromDate: null,
                toDate: null,
                flexible: flexible,
                checkInDate: '', // empty checkin date for flexible
                checkOutDate: '', // empty checkout date for flexible
                numberOfNights: 0,
                lengthOfStay: 0,
              },
            })
          : setSearchFormState([MEETINGS_DATES], {
              // ...dates,
              [MEETINGS_DATES]: {
                // ...dates,
                eventsLastDefaultDate: '',
                eventsFromDate: null,
                eventsToDate: null,
                checkInDate: '', // empty checkin date for flexible
                checkOutDate: '', // empty checkout date for flexible
                numberOfNights: 0,
                lengthOfStay: 0,
              },
            });

        setShowIcon(false);
      } else {
        /**
         * seperate store value's for meetings form
         */
        selectedTab !== tabSelect?.meetingsTab
          ? setSearchFormState([DATES], {
              ...dates,
              [DATES]: {
                ...dates,
                fromDate: from,
                toDate: end,
                flexible: flexible,
                checkInDate: from && !flexible ? getValueFromDate(from) : '', // empty checkin date for flexible
                checkOutDate: end && !flexible ? getValueFromDate(end) : '', // empty checkout date for flexible
                numberOfNights:
                  from && end && !getTotalNumberOfNights(from, end, flexible)
                    ? Number(getTotalNumberOfDays(from, end)?.toFixed(0) || 1)
                    : 1,
                lengthOfStay:
                  from && end && !getTotalNumberOfNights(from, end, flexible) ? getTotalNumberOfDays(from, end) : 1,
              },
            })
          : setSearchFormState([MEETINGS_DATES], {
              // ...dates,
              [MEETINGS_DATES]: {
                // ...dates,
                eventsLastDefaultDate: '',
                eventsFromDate: from,
                eventsToDate: end,
                checkInDate: from && getValueFromDate(from), // empty checkin date for flexible
                checkOutDate: end && getValueFromDate(end), // empty checkout date for flexible
                numberOfNights:
                  from && end && !getTotalNumberOfNights(from, end, false)
                    ? Number(getTotalNumberOfDays(from, end)?.toFixed(0) || 0)
                    : 0,
                lengthOfStay:
                  from && end && !getTotalNumberOfNights(from, end, false) ? getTotalNumberOfDays(from, end) : 1,
              },
            });
        setShowIcon(true);
      }
    }
  };

  const onTabChange = (currTab: string): void => {
    /**
     * handale tab change on datepicker
     */
    if (currTab === tab2Label) {
      // reset dates in case of flexible if user enter more then max limit val
      handleDateSelection(fromDate, toDate, true);
    } else {
      handleDateSelection(fromDate, toDate, false);
    }
    setCurrentActiveTab(currTab);
  };

  const nightCountLabelValue = () => {
    //this code is not applicable for advance search form, hence removing the meetings code
    if (nightCount > 0) {
      /**
       * return night count
       */
      return `${nightCount === 1 ? `${nightCount} ${night}` : `${nightCount} ${nights}`}`;
    }
    return datesSearchLabel;
  };

  const badgeValue = () => {
    if (selectedTab !== tabSelect?.meetingsTab) {
      if (nightCount > 0) {
        return `${nightCount === 1 ? `${nightCount} ${nightLabel}` : `${nightCount} ${nights}`}`;
      } else {
        return;
      }
    } else if (selectedTab === tabSelect?.meetingsTab) {
      if (meetingsNightCount > 0) {
        return `${
          meetingsNightCount === 1 ? `${meetingsNightCount} ${nightLabel}` : `${meetingsNightCount} ${nights}`
        }`;
      } else {
        return;
      }
    } else {
      return '';
    }
  };

  const inputlabelText = isTabbedSearchForm
    ? stayDates
    : currentActiveTab === tab1Label
    ? `${nightCountLabelValue()}`
    : `${stayDates} (${nightCountLabelValue()})`;
  const handleModalClose = (): void => {
    /**
     * close modal on done click
     * handle all other required part when user close the date picker modal
     */
    if (toDate === null && fromDate !== null && fromDate !== undefined) {
      handleDateSelection(fromDate, getNextDateObject(fromDate), flexible ? true : false);
      selectedTab !== tabSelect?.meetingsTab &&
        handleDateSelection(fromDate, getNextDateObject(fromDate), flexible ? true : false);
    }
    if (eventsToDate === null && eventsFromDate !== null && eventsFromDate !== undefined) {
      selectedTab === tabSelect?.meetingsTab &&
        handleDateSelection(eventsFromDate, getNextDateObject(eventsFromDate), false);
    }
    setDatepickerPopupOpenState(false);
    setShowIcon(false);

    if (onDatePickerChange) {
      onDatePickerChange();
    }
  };

  useEffect(() => {
    if (datepickerPopupOpenState === false && !toDate && fromDate && selectedTab !== tabSelect?.meetingsTab) {
      handleDateSelection(fromDate, getNextDateObject(fromDate), flexible ? true : false);
    }
    /**
     * handle dates selection for meetings form in popup close state if there is no to-date
     */
    if (
      isTabbedSearchForm &&
      datepickerPopupOpenState === false &&
      selectedTab === tabSelect?.meetingsTab &&
      eventsToDate === null &&
      eventsFromDate !== null &&
      eventsFromDate !== undefined
    ) {
      handleDateSelection(eventsFromDate, getNextDateObject(eventsFromDate), false);
    }
  }, [datepickerPopupOpenState]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      // Check if the click target is not within the popup or the button or the icons
      if (
        !(event?.target as Element).closest('.shop-datepicker-modal') &&
        !(event?.target as Element)?.closest('.datepicker-input-container')
      ) {
        setDatepickerPopupOpenState(false);
      }
    };
    const focusoutListener = (event: FocusEvent): void => {
      if (
        event.relatedTarget &&
        !datepickerref?.current?.contains(event.relatedTarget as Element) &&
        !datepickerref?.current?.contains(document.activeElement) &&
        datepickerref?.current?.contains(event.target as Element) &&
        !(event.relatedTarget as Element)?.classList.contains('trailing-element')
        //making this change to restrict the unwated close of dropdown on clicking of white spaces
      ) {
        if (setDatepickerPopupOpenState) {
          setDatepickerPopupOpenState(false);
        }
      }
    };
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    if (isDesktop) {
      datepickerref?.current?.addEventListener('focusout', focusoutListener); // Attaching focusout event to dropdown to handle blur event
    }

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      datepickerref?.current?.removeEventListener('focusout', focusoutListener);
    };
  }, [datepickerPopupOpenState]);

  /**
   *
   * @returns
   * component that will render inside the tab based one the current active tab
   */
  const calendarComp = () => {
    if (currentActiveTab === tab1Label) {
      return (
        <div
          className={clsx(
            hideFlexibleDateFlow && !isDesktop ? 'datepicker-calendar-wrapper-mobile' : 'datepicker-calendar-wrapper',
            !hideFlexibleDateFlow ? 'datepicker-calendar-wrapper-multi-tabs' : ''
          )}
        >
          <DatePickerExact
            weekdays={shortWeekdays}
            months={monthsValue}
            weekdaysLong={weekdaysLongName}
            monthsShort={monthsShortValue}
            weekdaysShort={weeksdaysShort}
            isMobile={isMobileView}
            onDateSelection={handleDateSelection}
            dates={selectedTab !== tabSelect?.meetingsTab ? { ...dates } : { ...eventsDates }}
            resetFlag={resetFlag}
            setResetFlag={setResetFlag}
            isTabletView={isTabletView}
            isDatePickerModalOpen={datepickerPopupOpenState}
            clickTrackingLoc={clickTrackingLoc}
            singleDateLimit={singleDateLimit}
            groupDateLimit={groupDateLimit}
          />
        </div>
      );
    } else {
      return (
        <DatePickerFlexible
          clickTrackingLoc={clickTrackingLoc}
          dates={dates}
          onDateSelection={handleDateSelection}
          monthList={monthsValue}
          singleDateLimit={singleDateLimit}
        />
      );
    }
  };
  const buttonRef = useRef<HTMLButtonElement>(null); //ref to the button of datepicker , to be used in dropdown modal to identify the last element of the modal

  const FooterComponent: FC = () => (
    <StyledFooterDatePickerDiv
      className={clsx(
        'datepicker-footer align-items-center d-flex justify-content-between  standard',
        !isTabbedSearchForm ? 'pt-3' : 'pt-3',
        !isDesktop ? 'pb-4 px-4' : '',
        isDesktop && !isTabbedSearchForm && flexible && 'pt-5 mt-5'
      )}
    >
      <a
        href="#"
        className="m-link-action reset-action-button custom_click_track"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          resetButtonHandler();
        }}
        title={resetSpecificDateCta}
        data-testid={resetSpecificDateCta}
        custom_click_track_value={`${clickTrackingLoc}| ${datepickerResetClickTrackingLoc} |internal`}
        role="button" // forcefully setting role = button to anchor tag as it was reading Reset Link
      >
        {resetSpecificDateCta}
      </a>
      <Button
        ref={buttonRef}
        callback={handleModalClose}
        className="m-button-secondary m-button-s"
        custom_click_track_value={`${clickTrackingLoc}| ${submitSpecificDateCta} button |internal`}
        buttonCopy={currentActiveTab === tab1Label ? submitSpecificDateCta : flexibleDatesSubmitCta}
      />
    </StyledFooterDatePickerDiv>
  );
  const datepickerref = useRef<HTMLDivElement>(null);
  const inputElementRef = useRef<HTMLInputElement>(null);

  return (
    <StyledDatePickerDiv
      data-component-name="m-shop-DatePicker"
      data-testid="shop-DatePicker"
      tabIndex={-1}
      $isTabbedSearchForm={isTabbedSearchForm === true ? true : false}
      className={clsx(isMobileView ? 'date-picker-mobile-wrapper' : '', 'date-picker-wrapper')}
    >
      {(!isDesktop && !datepickerPopupOpenState) || isDesktop || (!isDesktop && isTabbedSearchForm && !isModalOpen) ? (
        <div
          className={clsx(isTabbedSearchForm !== true && 'date-input-field-wrapper', 'standard', 'date-cursor')}
          data-test-id="date-input-modal-button"
          onClick={() => {
            //removed event.stopPropagation as it was restricting Analytics tracking
            setDatepickerPopupOpenState(true);
          }}
          tabIndex={-1}
          onKeyDown={(ev: KeyboardEvent) => {
            if (ev.keyCode === 13) {
              // check if user press enter on datepicker
              setDatepickerPopupOpenState(!datepickerPopupOpenState); //toggle datepicker on enter
            }
          }}
        >
          <InputTextField
            className={'m-input-text-field datepicker-input-container'} // these 2 class names are mandatory , please do not remove
            label={inputlabelText}
            inputValue={displayText}
            getInputProps={() => ({
              readOnly: true,
            })}
            onFocus={() => {
              if (displayText.length && !flexible) {
                setShowIcon(true);
              }
            }}
            inputTextFieldClassName={clsx(
              isTabbedSearchForm ? dateTextFont : '',
              'custom_click_track',
              'text-overflow-ellipsis'
            )}
            showIcon={datepickerPopupOpenState && showIcon && !isTabbedSearchForm}
            //changing from iconOnMouseDownCapture to iconOnClick as the onclick fire's the click tracking
            iconOnClick={resetButtonHandler}
            iconClass="icon-cancel clear-input-icon custom_click_track"
            iconAriaLabel={clear}
            placeHolderText={dateHelperText}
            togglePlaceholder={true}
            iconForLabel="icon-stay-dates"
            custom_click_track_value={`${searchFormClickTrackingLoc} | Date picker selected |internal}`}
            icon_custom_click_track_value={`${searchFormClickTrackingLoc} | Cleared dates |internal}`}
            infoLabel="date-picker"
            variation={isTabbedSearchForm ? 'default' : 'line-type'}
            withinModal={!isDesktop}
            showUnderline={isMobileForm}
            showBadge={showBadge}
            badgeValue={badgeValue()}
          />
        </div>
      ) : (
        <div></div>
      )}
      <div ref={datepickerref}>
        <DropDownModal
          shouldAutoFocusOnPopup={true}
          isAutoScrollEnabled={true}
          parentElementTopValue={searchFormTopPosition?.value}
          handleBlur={isTabbedSearchForm}
          scrollDownModalCloseState={true}
          dropDownSpecificFlag={true}
          applyDefaultHeight={isTabbedSearchForm ? true : false}
          className={clsx(
            'shop-datepicker-modal standard',
            isTabbedSearchForm ? 'shop-datepicker-modal-tabbedform' : '',
            isSearchFormSticky && isCNLocale(currentLocale) && 'is-search-form-sticky',
            alignRight && 'align-right'
          )}
          dropDownChildrenClassName={flexible ? 'shop-dropdown-children-el' : ''}
          childrenClassName={clsx(
            isTabbedSearchForm && isDesktop ? '' : 'shop-datepicker-dropdown',
            datepickerPopupOpenState && isDesktop && !isTabbedSearchForm ? 'shop-datepicker-dropdown-styles' : '',
            {
              'shop-date-picker-drop-down-cn': isCNLocale(currentLocale),
            }
          )}
          show={datepickerPopupOpenState}
          dropdownModalOpenState={datepickerPopupOpenState}
          setDropdownModalOpenState={val => {
            if (toDate === null && fromDate !== null && fromDate !== undefined) {
              handleDateSelection(fromDate, getNextDateObject(fromDate), flexible ? true : false);
            }
            if (eventsToDate === null && eventsFromDate !== null && eventsFromDate !== undefined) {
              selectedTab === tabSelect?.meetingsTab &&
                handleDateSelection(eventsFromDate, getNextDateObject(eventsFromDate), false);
            }

            /**
             * Note - The problem here is that the variable 'resetFlag' is undefined inside the callback.
             * The solution is a workaround to access latest state via 'prevState' arg.
             **/

            let latestResetFlag;
            setResetFlag(prev => {
              latestResetFlag = prev;
              return prev;
            });
            if (!(latestResetFlag && showIcon)) {
              setDatepickerPopupOpenState(val);
            }
          }}
          //set setDatepickerPopupOpenState directly to setDropdownModalOpenState in case we dont have cross cta on dates field
          {...(!isTabbedSearchForm ? {} : { setDropdownModalOpenState: setDatepickerPopupOpenState })}
          // mobile setting enabled
          mobileTakeOver={isMobileView}
          mobileModalHeading={dateModalHeader}
          mobileHeaderEnabled={isMobileView}
          dropdownModalOnCLoseFunc={() => {
            setShowIcon(false);

            setDatepickerPopupOpenState(false); //toggle datepicker on enter
            if (onDatePickerCancel) {
              onDatePickerCancel();
            }
          }}
          customLastElementRef={buttonRef}
          role="dialog"
        >
          <>
            <div className={clsx('modal-header-sticky')}>
              {isMobileView && (
                <div className="py-2 modal-header-input standard">
                  <InputTextField
                    ref={inputElementRef}
                    onFocus={() => {
                      if (displayText.length && !flexible) {
                        setShowIcon(true);
                      }
                    }}
                    className={'m-input-text-field datepicker-input-container'} // these 2 class names are mandatory , please do not remove
                    label={inputlabelText + ' '}
                    inputValue={displayText}
                    getInputProps={() => ({
                      readOnly: true,
                    })}
                    placeHolderText={dateHelperTextMobile}
                    togglePlaceholder={true}
                    showIcon={datepickerPopupOpenState && showIcon && !isTabbedSearchForm}
                    iconClass="icon-cancel clear-input-icon custom_click_track"
                    iconOnClick={resetButtonHandler}
                    iconAriaLabel={clear}
                    iconForLabel="icon-stay-dates"
                    infoLabel="Date Picker"
                    variation={isTabbedSearchForm ? 'default' : 'line-type'}
                    withinModal={true}
                    showUnderline={true}
                    custom_click_track_value={`${searchFormClickTrackingLoc} | Date picker selected |internal`}
                    icon_custom_click_track_value={`${searchFormClickTrackingLoc} | Cleared dates |internal`}
                    showBadge={showBadge}
                    badgeValue={badgeValue()}
                  />
                </div>
              )}
              <TabComponent
                tabList={tabList} // pass the tab content
                setSelectedTab={onTabChange}
                selectedTab={currentActiveTab}
                clickTrackingLoc={clickTrackingLoc} // tab click value
                hidetabsAndContent={false}
                children={calendarComp()}
                isSwitcherTabs={true} // second varitaion of tab
                customClass={
                  currentActiveTab === tab1Label && !isDesktop
                    ? 'datepicker-tab-wrapper-exact'
                    : 'datepicker-tab-wrapper'
                }
                disabledContainerClass={true}
                hideTabs={hideTabHeader}
                ariaRole="button"
              />
            </div>
            <FooterComponent />
          </>
        </DropDownModal>
      </div>
    </StyledDatePickerDiv>
  );
};

export default DatePicker;
