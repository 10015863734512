import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';

import { Heading } from '../../atoms/Heading';
import { Image } from '../../atoms/Image';
import { Icon } from '../../atoms/Icon';
import { Link } from '../../atoms/Link';
import { headingType, size } from '../../utils/enums/enums';

import { StyledHorzMiniCardContainer } from './CardHorizontalMini.styles';
import { CardHorizontalMiniProps } from './CardHorizontalMini.types';
import { removeParentTagRTE } from '../../utils/helper';
import { Button } from '../../atoms/Button';
import { Modal } from '../Modal';
import { RichText } from '../RichText';

export const CardHorizontalMini: FC<CardHorizontalMiniProps> = ({
  openInaNewTab,
  ctaLink,
  header,
  headerShort,
  headerTag,
  descriptionText,
  descriptionShort,
  trackingProperties,
  dynamicMedia,
  styleclass,
  assetVariation,
  iconPath,
  componentId,
  hideEllipsis,
  hideDescriptionOnMobile,
  hideImage,
  openInAModel,
  modalHeader = '',
  modalDescription = '',
  modalLinkText,
  imageLoading = 'lazy',
  custom_click_track_value = '',
}) => {
  const isBodyCopy = descriptionText !== undefined && descriptionText.length > 0 ? true : false;
  const isBodyCopyMobile = descriptionShort !== undefined && descriptionShort.length > 0 ? true : false;
  const renditions = dynamicMedia?.renditions;

  const CardHorizontalMiniRef = useRef(null) as unknown as React.MutableRefObject<HTMLAnchorElement>;
  const customAttributes = trackingProperties?.enableScrollingBehavior
    ? { 'data-section-tracking': componentId ? componentId : '' }
    : {};

  // Using removeParentTagRTE to clean the text recieved from RTE
  const descriptionRTEText = descriptionText ? removeParentTagRTE(descriptionText) : '';

  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);

  const toggleModal = () => {
    setPopupOpenState(!popupOpenState);
  };

  const getCardContent = () => {
    return (
      <>
        {!hideImage && (
          <>
            {assetVariation !== 'iconfont' ? (
              <Image
                customClass={clsx(hideEllipsis ? 'miniCardImage--height' : '', 'miniCardImage')}
                altText={dynamicMedia?.altText}
                renditions={renditions}
                dynamic={dynamicMedia?.dynamic}
                defaultImageURL={dynamicMedia?.assetPath}
                tabIndex={0}
                loading={imageLoading}
              />
            ) : (
              <div className={clsx(hideEllipsis ? 'miniCardImage--height' : '', 'miniCardImage')}>
                <Icon iconClass={clsx(`${iconPath}`, 'm-auto')} />
              </div>
            )}
          </>
        )}
        <div className={clsx('miniCardContent')}>
          <div
            className={clsx(
              hideEllipsis
                ? (hideDescriptionOnMobile ? 'pb-3 pb-md-2 pt-4' : 'pb-md-2 pt-3 pt-md-4') + ' miniCardBody--wrapText'
                : 'miniCardBody'
            )}
          >
            <div className={clsx('d-none d-md-block', { 'd-block': !headerShort })}>
              <Heading
                titleText={header}
                variation={headingType.subtitle}
                disableCustomClickTrack
                fontSize={size.large}
                element={headerTag}
                customClass={clsx('clampLines')}
              />
            </div>
            <div className={clsx('d-block d-md-none')}>
              <Heading
                titleText={headerShort}
                variation={headingType.subtitle}
                fontSize={size.large}
                element={headerTag}
                customClass={clsx('clampLines')}
              />
            </div>
            {isBodyCopy && (
              <div
                dangerouslySetInnerHTML={{ __html: descriptionText }}
                className={clsx(
                  'd-none d-md-block',
                  hideDescriptionOnMobile ? '' : { 'd-block': !descriptionShort },
                  't-font-alt-s'
                )}
              ></div>
            )}
            {isBodyCopyMobile && (
              <div
                dangerouslySetInnerHTML={{ __html: descriptionShort }}
                className={clsx('d-md-none', 't-font-alt-s', hideDescriptionOnMobile ? 'd-none' : '')}
              ></div>
            )}

            {openInAModel === 'show' && (
              <Button
                buttonCopy={modalLinkText}
                isLink={true}
                className={clsx('miniCardContent__link')}
                callback={() => {
                  toggleModal();
                }}
              />
            )}
          </div>
          <span className={clsx('icon-s', 'miniCardChevron', !openInaNewTab ? 'icon-arrow-right' : '')}></span>
        </div>
      </>
    );
  };

  return (
    <>
      <StyledHorzMiniCardContainer
        data-component-name="m-ui-library-CardHorizontalMini"
        data-testid="ui-library-CardHorizontalMini"
        openInaNewTab={openInaNewTab}
        className={clsx(
          `col-12 p-0  ${styleclass ? styleclass : ''} ${hideEllipsis ? 'miniCard--height' : ''} ${
            hideImage ? 'd-block pl-4' : ''
          }`
        )}
        fontIcon={iconPath || ''}
        aria-describedby={componentId}
        {...customAttributes}
      >
        {openInAModel !== 'show' && (
          <Link
            linkClassName={[
              'minicardlink t-background-color',
              openInaNewTab === true ? 'icon-arrow-right-external' : '',
              't-font-alt-s',
            ]}
            linkHref={ctaLink}
            target={openInaNewTab === true ? '_blank' : '_self'}
            trackingProperties={trackingProperties}
            rel={`${openInaNewTab ? 'noopener noreferrer' : ''}`}
            ref={CardHorizontalMiniRef}
            custom_click_track_value={custom_click_track_value}
          >
            {getCardContent()}
          </Link>
        )}
        {openInAModel === 'show' && (
          <div className={clsx('minicardlink t-background-color', 't-font-s')}>
            {getCardContent()}
            <Modal show={popupOpenState}>
              <Modal.Header labelText={modalHeader} popupHeaderOnCLoseFunc={toggleModal} />
              <Modal.Body>
                <RichText componentId={`richtext-${componentId}`} text={modalDescription}></RichText>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </StyledHorzMiniCardContainer>
      <span id={componentId} className="sr-only">
        {`${assetVariation !== 'iconfont' ? dynamicMedia?.altText : iconPath} ${header} ${
          descriptionRTEText ? descriptionRTEText : ''
        }`}
      </span>
    </>
  );
};
