import { FC } from 'react';
import clsx from 'clsx';
import { getEventKeyTypes } from '@marriott/mi-ui-library';
import { StyledPaginationContainer } from './index.styles';
import { PaginationPanelProps } from './index.types';
import { useTranslation } from 'react-i18next';

import { usePagination, DOTS } from './usePagination';
import { INTERNAL_CTA_TYPE } from '../../../constants/CommonConstants';

declare module 'react' {
  interface HTMLAttributes<T> {
    // extends React's HTMLAttributes
    custom_click_track_value?: string;
  }
}
export const PaginationPanel: FC<PaginationPanelProps> = ({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
  className,
  classList,
  labels,
  model,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });
  const { t } = useTranslation();

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = (): void => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  return (
    <StyledPaginationContainer
      className={clsx('shop-pagination-container', className ?? className, classList?.container)}
    >
      <ul className={`shop-pagination ${classList?.listParent}`} role="navigation">
        <li
          className={clsx(
            'shop-pagination-arrow',
            'shop-pagination-prev',
            't-font-s',
            currentPage === 1 ? 'disabled' : '',
            'custom_click_track'
          )}
          onClick={onPrevious}
          onKeyDown={(event): void => {
            if (getEventKeyTypes(event)?.isEnterKey) {
              onPrevious();
            }
          }}
          // TODO: Code refactor is required to add anchor tags inside the li tags, to support accessibility
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          tabIndex={currentPage === 1 ? -1 : 0}
          aria-label={t('PrevPage')}
          {...{
            custom_click_track_value: `${model?.id}|${model?.pagination ?? 'Pagination'}-${
              model?.previous
            }|${INTERNAL_CTA_TYPE}`,
          }}
        >
          <div className="page-arrow-container">
            <span className="icon-arrow-left"></span>
            <span className="pagination-prev-link t-font-xs mr-4 ml-2">{labels?.previous}</span>
          </div>
        </li>
        {paginationRange?.map((pageNumber, ind: number) =>
          pageNumber.toString() === DOTS ? (
            <li key={`${ind}-list`} className="shop-pagination-item shop-pagination-dots" role="presentation">
              &#8230;
            </li>
          ) : (
            <li
              className={clsx(
                'shop-pagination-item',
                classList?.listItem,
                pageNumber === currentPage ? 'active t-label-s' : 't-font-s',
                'custom_click_track'
              )}
              key={`${ind}-list`}
              // TODO: Code refactor is required to add anchor tags inside the li tags, to support accessibility
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              tabIndex={0}
              aria-label={`${pageNumber}`}
              {...{ custom_click_track_value: `${model?.id}|Page-${pageNumber}|${INTERNAL_CTA_TYPE}` }}
              onClick={(): void => onPageChange(pageNumber)}
              onKeyDown={(event): void => {
                if (getEventKeyTypes(event)?.isEnterKey) {
                  onPageChange(pageNumber);
                }
              }}
            >
              {pageNumber}
            </li>
          )
        )}
        <li
          className={clsx(
            'shop-pagination-arrow',
            'shop-pagination-next',
            't-font-s',
            currentPage === lastPage ? 'disabled' : '',
            'custom_click_track'
          )}
          onClick={onNext}
          onKeyDown={(event): void => {
            if (getEventKeyTypes(event)?.isEnterKey) {
              onNext();
            }
          }}
          // TODO: Code refactor is required to add anchor tags inside the li tags, to support accessibility
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          tabIndex={currentPage === lastPage ? -1 : 0}
          aria-label={t('NextPage')}
          {...{
            custom_click_track_value: `${model?.id}|${model?.pagination ?? 'Pagination'}-${
              model?.next
            }|${INTERNAL_CTA_TYPE}`,
          }}
        >
          <div className="page-arrow-container">
            <span className="pagination-next-link t-font-xs ml-4 mr-2">{labels?.next}</span>
            <span className="icon-arrow-right"></span>
          </div>
        </li>
      </ul>
    </StyledPaginationContainer>
  );
};
