export const MeetingsAndEventsSearchFormModel = {
  brandSlug: '',
  brandCSS: '/bin/mi-aem-common-spa/clientlibs/brand-config.{brandCode}.css',
  staticComponentClientLibsCSS: '/etc.clientlibs/mi-aem-shop/clientlibs/clientlib-static-component.min.css',
  dataLayerInitializationScript:
    '/etc.clientlibs/core/wcm/components/commons/datalayer/v1/clientlibs/core.wcm.components.commons.datalayer.v1.js',
  componentsResourceTypes: [
    'mi-aem-shop-spa/components/content/hoteldirectory/v1/hoteldirectory',
    'core/wcm/components/page/v2/page',
    'wcm/foundation/components/responsivegrid',
    'nt:unstructured',
    'mi-aem-shop-spa/components/content/meetingssearchform/v1/meetingssearchform',
    'mi-aem-shop-spa/components/structure/headlessnextjspage',
  ],
  htmlPageItems: [],
  metaNames: [
    {
      name: 'keyword',
      value: '',
    },
    {
      name: 'msapplication-TileColor',
      value: '',
    },
    {
      name: 'msapplication-TileImage',
      value: '',
    },
    {
      name: 'onLoadLightboxURI',
      value: '',
    },
    {
      name: 'description',
      value: '',
    },
    {
      name: 'showLightboxOnLoad',
      value: '',
    },
    {
      name: 'robots',
      value: 'index,follow',
    },
  ],
  language: 'en-US',
  title: 'MeetingsAndEventsSearchForm',
  dataLayerType: 'traditional',
  authorMode: true,
  cssClassNames: 'headlessnextjspage remotepagenext page basicpage',
  ':type': 'mi-aem-shop-spa/components/structure/headlessnextjspage',
  dataLayerLaunchScript:
    'https://assets.adobedtm.com/697d0c070f1e/16ad26f0160a/launch-ENaaca933107c74c4eb05514750752e1b8-staging.min.js',
  favIcon: '',
  ':hierarchyType': 'page',
  remoteSPAUrl: 'http://localhost:4200',
  currentPagePath: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content',
  lastModifiedDate: 1702455917457,
  canonical: '',
  ':items': {
    root: {
      ':items': {
        hoteldirectory: {
          ':type': 'mi-aem-shop-spa/components/content/hoteldirectory/v1/hoteldirectory',
        },
        responsivegrid: {
          ':items': {
            meetingssearchform: {
              eventDetailsSubheading: 'Event Details',
              disableRecentlyViewed: false,
              placeholderTextDesktop: 'Where do you want to hold your event ?',
              dataLayer: {
                'meetingssearchform-4fa388b1a8': {
                  '@type': 'mi-aem-shop-spa/components/content/meetingssearchform/v1/meetingssearchform',
                  'repo:modifyDate': '2023-12-20T10:22:28Z',
                },
              },
              enableFlexibleSearch: true,
              dateFormat: 'ddd, MMM DD',
              disableTopDestination: false,
              dateModalHeader: 'Select your dates',
              eventSpaceEyebrowText: 'Event Space',
              ':type': 'mi-aem-shop-spa/components/content/meetingssearchform/v1/meetingssearchform',
              eventSpaceUtilityLabel: 'Square footage needed:',
              id: 'meetingssearchform-4fa388b1a8',
              enableWeekendSelectorOnMobile: false,
              guestRoomsEyebrowText: 'Guest Rooms',
              submitCTA: 'Find',
              guestRoomsHelperText: '# of Rooms',
              destinationModalHeader: 'Where next?',
              dateHelperTextDesktop: 'Add dates',
              stayDates: 'Dates',
              thisWeekendLabel: 'This Weekend',
              nights: 'Nights',
              dateHelperText: 'Start Date — End Date',
              disableGuestRooms: false,
              maxRecentSearches: '3',
              listOfEventTypes: [
                {
                  eventType: 'Business',
                },
                {
                  eventType: 'Wedding',
                },
                {
                  eventType: 'Social',
                },
              ],
              specificDatesToggle: false,
              destinationModalHelperText: 'Search city, hotel, address and more…',
              dateHelperTextMobile: 'Add dates',
              destinationModuleEyebrowText: 'Destination',
              nameOfRecentlyViewedSection: 'Recently Viewed',
              componentId: 'Meetings',
              eventSpaceHelperText: '# of Attendees',
              dateEyebrowText: 'Dates',
              maxPopularDestination: '3',
              disableEventDetails: false,
              resetSpecificDateCta: 'Reset',
              localDateFormat: 'MM/DD/YYYY',
              appliedCssClassNames: 'phoenix',
              guestRoomsMaxNoOfGuest: '10',
              orderingRecentlySearchedAndViewed: 'recentSearch',
              hideDestination: false,
              disableEventSpace: false,
              disableEventType: false,
              maxRecentlyViewed: '1',
              eventTypePurposeTitleCopy: 'What’s the purpose of your event?',
              topDestinationsListItems: [],
              placeholderTextMobile: 'Where do you want to hold your event ?',
              nameOfPopularDestinationSection: 'Trending Destinations',
              submitSpecificDateCta: 'Done',
              firstDayOfWeek: '0',
              hideAutoSuggest: false,
              disableRecentlySearched: false,
              nameOfRecentSearchesSection: 'Recent Searches',
            },
          },
          allowedComponents: {
            components: [
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/advancedsearchform/v1/advancedsearchform',
                title: 'Advanced Search Form',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/availabilitycalendar/v1/availabilitycalendar',
                title: 'Availability Calendar component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/baselinesearchform/v1/baselinesearchform',
                title: 'Baseline Search Form',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/container',
                title: 'Container',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/experiencefragment',
                title: 'Experience Fragment',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/exploredestinations/v1/exploredestinations',
                title: 'Explore Our Destinations Component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/homepagesearch/v1/homepagesearch',
                title: 'Homepage Search Form',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hotelgalleryoverview',
                title: 'Hotel Gallery overview',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hotelgalleryphotoviewer',
                title: 'Hotel Gallery Photo Viewer',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hotelgallerytitle',
                title: 'Hotel Gallery Title',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hotelinformation',
                title: 'Hotel information',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hvmibanner/v1/hvmibanner',
                title: 'HVMI Banner component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/mapsubregion/v1/mapsubregion',
                title: 'Map Sub Region Component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/meetingssearchform/v1/meetingssearchform',
                title: 'Meetings & Events Search Form',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/searchform/v1/searchform',
                title: 'Search Form',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/searchresults/v1/searchresults',
                title: 'Search Results component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/responsivegrid/wcm/foundation/components/responsivegrid',
                title: 'Layout Container',
              },
            ],
            applicable: false,
          },
          ':type': 'wcm/foundation/components/responsivegrid',
          ':itemsOrder': ['meetingssearchform'],
          gridClassNames: 'aem-Grid aem-Grid--12 aem-Grid--default--12',
          columnClassNames: {
            meetingssearchform: 'aem-GridColumn aem-GridColumn--default--12',
          },
          columnCount: 12,
        },
      },
      allowedComponents: {
        components: [
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/advancedsearchform/v1/advancedsearchform',
            title: 'Advanced Search Form',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/availabilitycalendar/v1/availabilitycalendar',
            title: 'Availability Calendar component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/baselinesearchform/v1/baselinesearchform',
            title: 'Baseline Search Form',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/container',
            title: 'Container',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/experiencefragment',
            title: 'Experience Fragment',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/exploredestinations/v1/exploredestinations',
            title: 'Explore Our Destinations Component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/homepagesearch/v1/homepagesearch',
            title: 'Homepage Search Form',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/hotelgalleryoverview',
            title: 'Hotel Gallery overview',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/hotelgalleryphotoviewer',
            title: 'Hotel Gallery Photo Viewer',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/hotelgallerytitle',
            title: 'Hotel Gallery Title',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/hotelinformation',
            title: 'Hotel information',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/hvmibanner/v1/hvmibanner',
            title: 'HVMI Banner component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/mapsubregion/v1/mapsubregion',
            title: 'Map Sub Region Component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/meetingssearchform/v1/meetingssearchform',
            title: 'Meetings & Events Search Form',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/searchform/v1/searchform',
            title: 'Search Form',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/mi-aem-shop-spa/components/content/searchresults/v1/searchresults',
            title: 'Search Results component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm/jcr:content/root/wcm/foundation/components/responsivegrid',
            title: 'Layout Container',
          },
        ],
        applicable: false,
      },
      ':type': 'wcm/foundation/components/responsivegrid',
      ':itemsOrder': ['hoteldirectory', 'responsivegrid'],
      gridClassNames: 'aem-Grid aem-Grid--12 aem-Grid--default--12',
      columnClassNames: {
        hoteldirectory: 'aem-GridColumn aem-GridColumn--default--12',
        responsivegrid: 'aem-GridColumn aem-GridColumn--default--12',
      },
      columnCount: 12,
    },
  },
  ':itemsOrder': ['root'],
  templateName: 'headless-next-js-template',
  ':path': '/content/marriott-shop-spa/language-masters/na/en-us/MeetingsAndEventsSearchForm',
  staticComponentClientLibsJS: '/etc.clientlibs/mi-aem-shop/clientlibs/clientlib-static-component.min.js',
  gridCss: '/etc.clientlibs/mi-aem-shop/clientlibs/clientlib-base.css',
  metaProperties: [
    {
      name: 'og:title',
      value: '',
    },
    {
      name: 'og:description',
      value: '',
    },
    {
      name: 'imageURL',
      value: '',
    },
    {
      name: 'og:type',
      value: '',
    },
    {
      name: 'og:site_name',
      value: '',
    },
    {
      name: 'fbAppId',
      value: '',
    },
    {
      name: 'og:url',
      value: '',
    },
  ],
  designPath: '/libs/settings/wcm/designs/default',
};
