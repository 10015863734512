import { FC } from 'react';
import clsx from 'clsx';

import { RadioButtonProps } from './RadioButton.types';
import { StyledRadioButtonDiv } from './RadioButton.styles';

// the attributes which are required to pass as props for radio button as follows:
// radiobuttonId, radiobuttonName, radiobuttonLabel, checked, onChange, value,
// custom_click_track_value, disabled, setTabIndex, customClickClass

export const RadioButton: FC<RadioButtonProps> = ({
  radiobuttonId,
  radiobuttonName,
  radiobuttonLabel,
  checked,
  onChange,
  className,
  value,
  disabled,
  setTabIndex,
  customClickClass,
  isRichText,
  labelClassName,
  onKeyDown,
  dataTestId,
  tabIndexForInput,
  ariaLabelForLink,
  ...props
}) => {
  return (
    <StyledRadioButtonDiv
      data-component-name="a-ui-library-RadioButton"
      data-testid="ui-library-RadioButton"
      className={className ?? ''}
    >
      <input
        type="radio"
        id={radiobuttonId}
        name={radiobuttonName}
        onChange={onChange}
        checked={checked}
        value={value}
        disabled={disabled}
        className={customClickClass}
        aria-checked={checked}
        tabIndex={tabIndexForInput}
        onKeyDown={event => {
          onKeyDown?.(event, value, radiobuttonLabel);
        }}
        {...props}
      />
      {isRichText ? (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className={clsx(labelClassName ?? '')} htmlFor={radiobuttonId}>
          <span
            tabIndex={setTabIndex}
            className={clsx('keydown-anchor', disabled ? 'disabled' : '')}
            onKeyDown={event => {
              onKeyDown?.(event, value, radiobuttonLabel);
            }}
            data-testid={dataTestId}
            dangerouslySetInnerHTML={{ __html: radiobuttonLabel ?? '' }}
            aria-label={`${ariaLabelForLink || radiobuttonLabel} link`}
          ></span>
        </label>
      ) : (
        <label className={clsx(labelClassName ?? '')} htmlFor={radiobuttonId}>
          <span
            tabIndex={setTabIndex}
            className={clsx('keydown-anchor', disabled ? 'disabled' : '')}
            onKeyDown={event => {
              onKeyDown?.(event, value, radiobuttonLabel);
            }}
            data-testid={dataTestId}
            aria-label={`${ariaLabelForLink || radiobuttonLabel} option`}
          >
            {' '}
            {radiobuttonLabel}{' '}
          </span>
        </label>
      )}
    </StyledRadioButtonDiv>
  );
};
