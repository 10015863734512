// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledEarnPointsSection = styled.div`
  background: inherit;

  img {
    width: 100%;
    aspect-ratio: 1/1;
  }

  @media ${baseVariables.mediaQuery.md} {
    img {
      aspect-ratio: 3/1;
    }
  }

  .icon-decorative::before {
    color: #b94600 !important;
  }

  .earn-container {
    border-radius: ${toRem(16)};
    background: ${baseVariables.color.base20};

    &__image-section {
      img {
        border-radius: ${toRem(16)} ${toRem(16)} 0 0;
      }

      .gradient {
        &:after {
          background: none;
        }
      }
    }

    &__overview-section {
      .t-overline-normal,
      .t-subtitle-xl {
        margin-bottom: ${toRem(12)};
      }

      max-width: none;
      padding: ${toRem(16)};

      @media ${baseVariables.mediaQuery.md} {
        padding: ${toRem(24)};
      }

      @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
        max-width: 60%;
        padding: unset;
      }
    }

    &__step-section {
      row-gap: ${toRem(16)};

      @media ${baseVariables.mediaQuery.md},
        @media ${baseVariables.mediaQuery.md},
        @media ${baseVariables.mediaQuery.md} {
        column-gap: ${toRem(16)};
      }
    }

    &__separator {
      height: ${toRem(1)};
      border-top: 1px solid ${baseVariables.color['neutral30']};
    }
  }
  .error-sev2 {
    background-color: ${baseVariables.color['base20']};
    border: 0.063rem solid #b94600;
    p {
      margin: 0;
      margin-left: 0.5rem;
    }
  }
  .rich-text-link {
    a {
      text-decoration: underline;
    }
  }
`;
