/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';

export interface rnbErrorState {
  errorCode: any;
  errorMessage: string;
  // Generic error obj will include only those errors which are not associated with uxl/api calls
  genericError: any;
  value: number;
  setErrorState: (errorCode: number, errorMessage: string) => void;
  setGenericErrorState: (genericError: any) => void;
  increment: () => void;
}

export const initialState: rnbErrorState = {
  errorCode: null,
  errorMessage: '',
  genericError: {},
  value: 0,
  setErrorState: () => {},
  setGenericErrorState: () => {},
  increment: () => {},
};

export const useStore: StateCreator<rnbErrorState> = set => {
  return {
    ...initialState,
    setErrorState: (errorCode, errorMessage) => {
      set({ errorCode: errorCode, errorMessage: errorMessage });
    },
    setGenericErrorState: genericError => {
      set({ genericError: genericError });
    },
    increment: () => {
      set(state => ({ value: state.value + 1 }));
    },
  };
};

export const rnbErrorStore = createAppStore(useStore, {
  usePersistentStore: false,
});
