import { FC, useContext } from 'react';
import { StyledHeroBanner, GlobalStyle } from './HeroBanner.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { BannerLayered as HeroBannerComponent } from '@marriott/mi-ui-library';
import { HeroBannerProps } from './HeroBanner.types';
import { PageParamsContext } from '../../modules/context';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

const HeroBanner: FC<HeroBannerProps> = props => {
  const { userId } = useContext(PageParamsContext);

  return (
    <StyledHeroBanner data-testid="herobanner" data-component-name="o-rnb-herobanner">
      <GlobalStyle userId={userId} />
      <HeroBannerComponent {...props} imageLoading="eager" styleclass={props?.styleclass} />
    </StyledHeroBanner>
  );
};

const HeroBannerConfig = {
  emptyLabel: 'herobanner',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/herobanner`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HeroBannerEditable: FC<any> = props => (
  <EditableComponent config={HeroBannerConfig} {...props}>
    <HeroBanner {...props} />
  </EditableComponent>
);

export { HeroBanner, HeroBannerEditable };
