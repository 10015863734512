// import { toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledRnBRichText = styled.div`
  .rich-text-link {
    a {
      text-decoration: underline;
    }
    p {
      padding-bottom: ${toRem(16)};
    }
  }

  .faq-label-class {
    text-decoration: underline;
    cursor: pointer;
    font-weight: ${baseVariables.font['fontWeightMedium']};
  }
`;
