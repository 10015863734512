/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.

import React, { useEffect, useState, useRef } from 'react';
import { StyledTab } from './Tabs.styles';
import { Button, Icon, Heading, Types } from '@marriott/mi-ui-library';
import { TabProps } from './Tabs.types';
import { clsx } from 'clsx';
import Glide from '@glidejs/glide';

export const TabConfig = {
  emptyLabel: 'Tab',
  isEmpty: () => true,
  resourceType: `/components/content/Tab`,
};

export const TabComponent = (props: TabProps) => {
  const tabProps = props;
  const { tabList, componentId, trackingProperties, isAuthorMode } = tabProps;
  //const componentId = 'qpjlhvyf9g5gqbfu';
  const [activeTab, setActiveTab] = useState(props.setSelectedTab);
  const cardsCount = tabList.length;
  const [cardsPerView, setCardsPerView] = useState(2);
  const [viewPort, setViewPort] = useState('Desktop');
  const tabPerViewCount = 5;
  const tabActionRef = useRef(null) as unknown as React.MutableRefObject<HTMLAnchorElement>;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const handleResize = () => {
    const sm = window.matchMedia('(max-width: 575px)');
    const md = window.matchMedia('(min-width:576px) and (max-width: 767px)');
    //const lg = window.matchMedia('(min-width: 768px)and (max-width: 991px)');
    const lg = window.matchMedia('(min-width: 768px)and (max-width: 1366px)');
    const xl = window.matchMedia('(min-width: 1367px)');
    if (sm?.matches) {
      setViewPort('Mobile');
      setCardsPerView(1);
    } else if (md?.matches) {
      setViewPort('Mobile');
      setCardsPerView(1);
    } else if (lg?.matches) {
      setViewPort('Tablet');
      setCardsPerView(1);
    } else if (xl?.matches) {
      setViewPort('Desktop');
      setCardsPerView(3);
    }
  };

  useEffect(() => {
    const pageWcmMode = document.getElementsByTagName('html')[0];
    const updateStyleComponent = document.getElementById(`${componentId}__slides`);
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          const currWcmMode = pageWcmMode?.getAttribute('class');
          if (currWcmMode?.includes('Edit')) {
            updateStyleComponent?.classList.add('glide__slides_authoring');
          } else if (currWcmMode?.includes('Preview')) {
            updateStyleComponent?.classList.remove('glide__slides_authoring');
          }
        }
      });
    });
    observer.observe(pageWcmMode, { attributes: true });
    handleResize();

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const carouselRootSelector = `#${componentId}`;
    if (document?.querySelector(carouselRootSelector)) {
      try {
        new Glide(carouselRootSelector, {
          type: 'slider',
          bound: true,
          perView: 7,
          startAt: 0,
          rewind: false,
          gap: 3,
          autoplay: false,
          dragThreshold: false,
          swipeThreshold: false,
          breakpoints: {
            575: {
              perView: 3,
            },

            768: {
              perView: tabPerViewCount,
            },
            1180: {
              perView: 7,
            },
          },
        }).mount({ ArrowDisabler });

        document
          ?.querySelector(`#${componentId}_left_arrow`)
          ?.addEventListener('touchstart', (e: any) => e.preventDefault());
        document
          ?.querySelector(`#${componentId}_right_arrow`)
          ?.addEventListener('touchstart', (e: any) => e.preventDefault());
      } catch (error) {
        // log.error(`Error in initializing Glide for ${componentId}`, error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsPerView]);

  const ArrowDisabler = function (Glide: any, Components: any) {
    return {
      mount() {
        // Only in effect when rewinding is disabled
        if (Glide.settings.rewind) {
          return;
        }
        const left = document?.querySelector('.glide__arrow--left');
        left?.classList.remove('.is-disabled');
        Glide.on(['mount.after', 'run'], () => {
          // Filter out arrows_control
          for (const controlItem of Components.Controls.items) {
            if (controlItem.className !== 'glide__arrows') {
              continue;
            }

            // Set left arrow state
            const left = controlItem?.querySelector('.glide__arrow--left');
            if (left) {
              if (Glide.index === 0) {
                left.classList.add('.is-disabled'); // Disable on first slide
              } else {
                console.log('glide index', Glide.index);
                left.classList.remove('.is-disabled'); // Enable on other slides
                //const updateStyleComponent = document.getElementById(`${componentId}__slides`);
                //updateStyleComponent?.classList.add('leftMaskedTab');
              }
            }

            // Set right arrow state
            const right = controlItem?.querySelector('.glide__arrow--right');
            if (right) {
              if (viewPort === 'Tablet') {
                if (Glide.index === 0) {
                  if (tabList.length > Glide.settings.perView) {
                    right.classList.remove('.is-disabled');
                  }
                }
                if (Glide.index > 0) {
                  if (Glide.index === tabList.length - Glide.settings.perView) {
                    right.classList.add('.is-disabled'); // Disable on last slide
                  } else {
                    right.classList.remove('.is-disabled'); // Disable on other slides
                  }
                }
                //right.classList.add('.is-disabled');
              } else {
                if (Glide.index === Components.Sizes.length - Glide.settings.perView) {
                  right.classList.add('.is-disabled'); // Disable on last slide
                } else {
                  right.classList.remove('.is-disabled'); // Disable on other slides
                }
              }
            }
          }
        });
      },
    };
  };
  useEffect(() => {
    if (props.setSelectedTab) {
      setActiveTab(props.setSelectedTab);
    }
  }, [props]);

  return (
    <StyledTab
      className={clsx(
        'tab-wrapper',
        ` ${!props.disabledContainerClass && 'container'}`,
        ` ${props.hidetabsAndContent && 'd-none'}`
      )}
    >
      <div id={componentId} className={clsx(`glide 'col-md-8 p-0'}`)}>
        <div className={clsx('glide__track')} data-glide-el="track">
          <ul
            className={clsx(`m-standard-tab-list`, `tabUL`, ``, 'glide__slides')}
            role="tablist"
            id={`${componentId}__slides`}
          >
            {tabList.map((tab, key) => {
              const { tabTitle, tabValue, tabIcon, tabAction } = tab;

              return (
                <li
                  key={key}
                  className={clsx(
                    'custom_click_track',
                    `m-standard-tab-list-item`,
                    activeTab === tabValue ? 'active' : 'inactive-tab'
                  )}
                  {...{
                    custom_click_track_value: `${tabTitle}-tab`,
                  }}
                  role="tab"
                  tabIndex={0}
                  aria-label={tabTitle}
                >
                  <Button
                    isLink={props.isTablink !== undefined ? props.isTablink : true}
                    href={tabAction}
                    ref={tabActionRef}
                    ariaExpanded={tabValue}
                    custom_click_track_value={`${tabTitle}-tab`}
                    trackingProperties={trackingProperties}
                  >
                    <div className={clsx('tabIconText')}>
                      <Icon iconClass={`${tabIcon} iconContainer`} />

                      <Heading
                        element={Types.tags.span}
                        variation={Types.headingType.subtitle}
                        titleText={tabTitle}
                        disableCustomClickTrack={props?.disableCustomClickTrack}
                      />
                    </div>
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>

        {!isAuthorMode &&
          viewPort !== 'Desktop' &&
          ((viewPort === 'Tablet' && cardsCount > tabPerViewCount && cardsCount !== cardsPerView) ||
            (viewPort === 'Mobile' && cardsCount !== cardsPerView)) && (
            <div className="center-align" data-testid="mobile-tabs">
              <div className="carouselControlType1">
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx('left-arrow', 'glide__arrow--left', ``)}
                    id={`${componentId}_left_arrow`}
                    data-glide-dir="<"
                    aria-label="left-arrow"
                    data-custom_click_track_value={`internal`}
                  >
                    <span className="icon-arrow-left" id={`${componentId}_left_arrow`} data-id="left"></span>
                  </button>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx('right-arrow', 'glide__arrow--right')}
                    id={`${componentId}_right_arrow`}
                    data-glide-dir=">"
                    aria-label="right-arrow"
                  >
                    <span className="icon-arrow-right" data-id="right"></span>
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    </StyledTab>
  );
};
