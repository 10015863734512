/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import clsx from 'clsx';
import {
  AVERAGE_NIGHTLY_RATE_PER_UNIT,
  AVG_TAB,
  DESKTOP_CURRENCY_LENGTH,
  MOBILE_CURRENCY_LENGTH,
  POINTS_PER_UNIT,
  SUBTOTAL_PER_UNIT,
  TABLET_CURRENCY_LENGTH,
} from '../../constants/Calendar.constant';
import { getCompactFormat, formatNumberByLocale } from '../../utils/src/utils';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    // extends React's HTMLAttributes
    custom_click_track_value?: string;
  }
}
export const CustomizeRenderDay = (
  day: Date,
  isMobile: boolean,
  data: any,
  isDataLoading: boolean | undefined,
  conversionFactorRate: number,
  isViewportL: boolean,
  currentLocale: string,
  smallerDevicesCheck: boolean,
  isPointsSelected: boolean,
  isRedemption: boolean,
  lowPriceIndicator: string,
  compactFormatLiteral: any
) => {
  const adfData = data?.adfData?.node;
  const selectedTab = data?.selectedTab;
  const isLowest = data?.isLowest;
  const rateTypeToDisplay =
    isPointsSelected || isRedemption
      ? POINTS_PER_UNIT
      : selectedTab === AVG_TAB
      ? AVERAGE_NIGHTLY_RATE_PER_UNIT
      : SUBTOTAL_PER_UNIT;
  const specificRate = fetchSpecificRates(
    isPointsSelected || isRedemption ? adfData?.totalPricing?.rateAmountsByMode : adfData?.rates?.rateAmounts,
    rateTypeToDisplay,
    conversionFactorRate
  );
  const rateToDisplay = getCommaSeparators(specificRate, isMobile, isViewportL, currentLocale, compactFormatLiteral);
  // Variable containing last bookable date. Variable naming based on story AC.
  const kDate = data?.inventoryDateData;

  return (
    <div
      role="button"
      tabIndex={0}
      className={clsx(
        'daypicker-cell-custom d-flex justify-content-center pt-1 pt-md-0',
        `${(!adfData && smallerDevicesCheck) || adfData ? 'custom_click_track' : ''}`
      )}
      {...{
        custom_click_track_value: `ADFCalendar|${
          !adfData
            ? moment(day).isAfter(kDate)
              ? data['rateUnavailableText']
              : data['notAvailableForCheckInText']
            : 'Checkin'
        } clicked|internal`,
      }}
      aria-label={moment(day).format('dddd')}
    >
      <div
        className={clsx(
          'DayPicker-Day-Cell-Wrapper',
          'px-0',
          ' px-md-2',
          adfData ? 'pb-5' : 'rate-unavailable',
          'pt-3',
          'mt-1'
        )}
      >
        <span className={clsx('DayPicker-Day-value', isMobile ? 't-subtitle-l' : 't-font-s"')}>{day?.getDate()}</span>
        <input className="DayPicker-Day-month-value" type="hidden" value={day?.getMonth()} />
        <input className="DayPicker-Day-year-value" type="hidden" value={day?.getFullYear()} />
        <div className="DayPicker-Day-custom-data">
          {!moment(day).utc().isBefore(moment().utc(), 'day') ? (
            isDataLoading ? (
              <>
                <div className="skeleton-loader-composite"></div>
                <div className="low-placeholder"></div>
              </>
            ) : (
              <div
                className={clsx(
                  !isMobile || !adfData || moment(day).isAfter(kDate) ? 't-font-alt-xs' : 't-font-alt-m ',
                  'price-section',
                  isLowest ? (isMobile ? 'm-price-saving' : 'mobile-price-low-rates') : ''
                )}
              >
                {moment(day).isAfter(kDate)
                  ? !isMobile
                    ? '-'
                    : data['rateUnavailableText']
                  : !adfData
                  ? !isMobile
                    ? '-'
                    : data['notAvailableForCheckInText']
                  : rateToDisplay}
              </div>
            )
          ) : (
            ''
          )}
          {isLowest && !isDataLoading ? <div className="t-font-alt-xs low-label-adf">{lowPriceIndicator}</div> : ''}
        </div>
      </div>
    </div>
  );
};

//used to fetch rates based on rate type required
export const fetchSpecificRates = (rateObject: any, rateType: string, conversionFactorRate: number) => {
  const rateToDisplay =
    rateType === 'points-per-unit'
      ? rateObject?.pointsPerQuantity
      : rateObject?.find((rateAmount: any) => {
          return rateAmount?.rateMode?.code === rateType;
        });
  if (rateType === 'points-per-unit') {
    return rateToDisplay?.points;
  } else {
    return Math.round(
      conversionFactorRate
        ? (rateToDisplay?.amount?.origin?.value / Math.pow(10, rateToDisplay?.amount?.origin?.valueDecimalPoint)) *
            conversionFactorRate
        : rateToDisplay?.amount?.origin?.value / Math.pow(10, rateToDisplay?.amount?.origin?.valueDecimalPoint)
    );
  }
};
export const setLanguageLocalization = (
  monthsShortValue: Array<string>,
  monthsValue: Array<string>,
  weekdaysLongName: Array<string>,
  weeksdaysShort: Array<string>
): void => {
  moment.locale('locale', {
    months: monthsValue,
    monthsShort: monthsShortValue,
    weekdays: weekdaysLongName,
    weekdaysShort: weeksdaysShort,
  });
};

/**
 * Function to format the Price on locale based and also on viewPort specific
 * based on the length of the price we recieve
 */

export const PriceDisplay = (
  price: number,
  isViewportM: boolean,
  isViewportL: boolean,
  currentLocale: string,
  compactFormatLiteral: any
) => {
  let formattedPrice = '';

  /**
   * Check if the 'price' is a string or a number,
   * and assign the appropriate value to the 'number' variable.
   */
  const number = typeof price === 'string' ? parseFloat(price) : price;
  /**
   * Convert the price to string so as to calculate the length to format it based on the viewPorts
   */
  const numberAsString = number?.toString();
  const currencyLength = numberAsString?.length;
  if (
    !isNaN(number) &&
    ((currencyLength > DESKTOP_CURRENCY_LENGTH && isViewportL) ||
      (currencyLength > TABLET_CURRENCY_LENGTH && isViewportM && !isViewportL) ||
      (currencyLength > MOBILE_CURRENCY_LENGTH && !isViewportM))
  ) {
    formattedPrice = getCompactFormat(number, currentLocale, compactFormatLiteral);
  } else {
    formattedPrice = formatNumberByLocale(price, currentLocale);
  }
  return formattedPrice;
};

/**
 * Function to add the comma seperator for the formatted Price
 * on locale based and also on viewPort based
 */
export const getCommaSeparators = (
  price: number,
  isViewportM: boolean,
  isViewportL: boolean,
  currentLocale: string,
  compactFormatLiteral: any
): string => {
  let formattedNumber = '';
  if (price !== undefined) {
    formattedNumber = PriceDisplay(price, isViewportM, isViewportL, currentLocale, compactFormatLiteral);
  }
  return formattedNumber;
};
