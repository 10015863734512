import { StyledSkeletonLoaderDiv, StyledSkeletonLoaderContainer } from './SkeletonCardCarouselLoader.styles';
import { CardLayeredVariations } from '@marriott/mi-ui-library';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SkeletonCardCarouselLoader = (props: any) => {
  const tallCards = props?.cardType === CardLayeredVariations?.Tall;
  return (
    <StyledSkeletonLoaderContainer className="container skeleton-loader-container">
      <StyledSkeletonLoaderDiv>
        {[...Array(3)].map(ind => (
          <div className="main-wrapper" key={ind}>
            <div className="top-main-wrapper">
              <div
                className="skeleton-loader-composite img-container mb-1"
                style={
                  tallCards
                    ? {
                        height: '526px',
                        width: '330px',
                      }
                    : {
                        height: '326px',
                        width: '330px',
                      }
                }
              ></div>
            </div>
          </div>
        ))}
      </StyledSkeletonLoaderDiv>
    </StyledSkeletonLoaderContainer>
  );
};
