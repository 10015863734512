import { useState } from 'react';
import { logger } from '../lib/logger';
import { canUseDOM } from '../lib/helpers';

declare global {
  interface Window {
    adobeDataLayer: {
      [key: number]: {
        event: string;
        data: Record<string, unknown>;
      };
      version: number;
      push: (event: { event: string; data: Record<string, unknown> }) => void;
      getState: () => Record<string, unknown>;
      addEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
      removeEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
    };
  }
}

type UseDataLayer = {
  pushState: (eventData: DataLayerEvent) => void;
  getState: () => Record<string, unknown> | null;
};

type DataLayerEvent = {
  event: string;
  data: Record<string, unknown>;
};

export function useDatalayer() {
  const { log } = logger({})('useDatalayer');
  const [adobeDataLayer] = useState<UseDataLayer>({
    pushState: (payload: DataLayerEvent) => {
      if (canUseDOM) {
        log.info('pushing to dataLayer', payload);
        window.adobeDataLayer.push(payload);
      }
    },
    getState: () => {
      if (canUseDOM) {
        return window.adobeDataLayer.getState();
      }
      return null;
    },
  });

  return adobeDataLayer;
}
