import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledFilterContainerDiv = styled.div`
  margin: 10px;
`;

export const GlobalStyle = createGlobalStyle`
  .spinner-wrapper {
    display: none !important;
  }
`;

export const StyledSearchResultContainer = styled.div`
  background-color: ${baseVariables.color.neutral10};
  padding-bottom: ${toRem(40)};
  .property-card__details-container {
    height: calc(100% - 50%);
  }

  @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    .property-card__details-container {
      height: inherit;
    }
  }
`;
