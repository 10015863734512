import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledAccordionContainerEnhanced = styled.span`
  .m-accordion__container {
    &_header {
      margin-bottom: ${toRem(24)};
      p {
        margin: 0;
      }
    }
  }
`;
