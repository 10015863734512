/* eslint-disable @typescript-eslint/no-explicit-any */

import moment from 'moment';
import {
  DefaultValue,
  SPECIAL_CODE,
  POINTS_CODE_VALUE,
  PRICE_CODE,
  RateNodeTypeToBeUsed,
  PointsNodeTypeToBeUsed,
  SearchFacetsConstants,
  AVAILABLE_CODE,
  P17_CODE,
  STRIKE_PRICE_THRESHOLD_PERCENTAGE,
  hardCodedAllLocale,
  searchFilters,
  showTaxesandFeesLabel,
  showAvailabilityLabel,
  dataLayerLabels,
  SESSION_SEARCH_KEYS,
} from '../component/index.constant';
import { EEO_MEMBER_CORP_CODE, EEO_MEMBER_CODE, keyMappings } from '../../../../constants/lib/constants';
import { SearchResultAlertType } from '../component/index.types';
import {
  SearchRateAmountType,
  SearchStateStoreDataType,
  SearchRateType,
  SearchStateExtendsType,
} from '../store/store.type';
import {
  SearchFacetsList,
  filterList,
  GraphQlAPiResponseKey,
  DefaultCurrency,
  DefaultEURCurrency,
} from '../component/index.constant';
import Cookies from 'js-cookie';
import Axios from 'axios';
import { changeCountryCodeForChina, formatNumberByLocale, getLanguage, logger } from '../../../../utils';
import { DATALAYER_CONSTANT } from '../../../../../../mi-shop-components/src/constants';
import { KeysRequest } from '../../../../../../mi-shop-components/src/utils/src/dataLayer/index.types';
import { SearchRateMonetaryAmountType } from '../store/store.type';
import config from './config';
import { MILESTOMETERS, REWARDS_REDEMPTION_CLUSTER_CODES } from '../../SearchResults/lib/application.constants';

const { log } = logger({})('SearchResults/helper');
const { NEXT_PUBLIC_DEFAULT_LANG } = process.env;

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>;
  }
}

export function getSearchQueryDataFromSession(data: any, sessionToken: string, currentLocale: string): any {
  const { AriesSearch, AriesCommon } = data || {};

  if (!AriesSearch) {
    return null;
  }
  const { searchCriteria, search_keyword } = AriesSearch;
  const { availabilityRequestVO, destinationAddressPlaceId, address, selectedCurrency } = searchCriteria;
  const { longitude, latitude } = address || {};
  const { checkInDate, checkOutDate, rewardsRedemption, childrenAges, numGuestsPerRoom, numRooms } =
    availabilityRequestVO;
  const checkIn = moment.utc(checkInDate).format('YYYY-MM-DD');
  const checkOut = moment.utc(checkOutDate).format('YYYY-MM-DD');
  const amenitiesObj: Record<string, string> = {};
  AriesSearch?.propertyFilterCriteria?.facets?.amenities?.forEach((amentiy: string) => {
    amenitiesObj[amentiy] = amentiy;
  });
  const activitiesObj: Record<string, string> = {};
  AriesSearch?.propertyFilterCriteria?.facets?.['activities-on-site']?.forEach((activity: string) => {
    activitiesObj[activity] = activity;
  });
  const brandsObj: Record<string, string> = {};
  AriesSearch?.propertyFilterCriteria?.facets?.brands?.forEach((brand: string) => {
    brandsObj[brand] = brand;
  });
  const transportationTypesObj: Record<string, string> = {};
  AriesSearch?.propertyFilterCriteria?.facets?.['transportation-types']?.forEach((transportationType: string) => {
    transportationTypesObj[transportationType] = transportationType;
  });
  const hotelTypesObj: Record<string, string> = {};
  AriesSearch?.propertyFilterCriteria?.facets?.['property-types']?.forEach((hotelType: string) => {
    hotelTypesObj[hotelType] = hotelType;
  });
  const eventsObj: Record<string, string> = {};
  AriesSearch?.propertyFilterCriteria?.facets?.['meetings-and-events']?.forEach((event: string) => {
    eventsObj[event] = event;
  });
  const statesObj: Record<string, string> = {};
  AriesSearch?.propertyFilterCriteria?.facets?.states?.forEach((state: string) => {
    statesObj[state] = state;
  });
  const citiesObj: Record<string, string> = {};
  AriesSearch?.propertyFilterCriteria?.facets?.cities?.forEach((city: string) => {
    citiesObj[city] = city;
  });

  let specialRateCode = availabilityRequestVO?.clusterCode;
  if (specialRateCode === 'AAA' || specialRateCode === 'GOV') {
    specialRateCode = specialRateCode.toLocaleLowerCase();
  }

  // FOR EEO enabled users
  const EEOMemberLevel =
    AriesCommon?.memState === 'authenticated' && data.memberLevel
      ? EEO_MEMBER_CORP_CODE[data.memberLevel]
      : AriesCommon?.memState === 'remembered' && AriesSearch.rememberedMemberLevel
      ? EEO_MEMBER_CORP_CODE[AriesSearch.rememberedMemberLevel]
      : null;

  /*
    Read the special rates value from session data,
    and populate rateRequestTypes for UXL calls
  */
  let ratesType: Array<{ type: string | undefined; value: string | undefined } | []> = [];
  switch (specialRateCode) {
    case 's9r':
      ratesType = [
        {
          type: 'CLUSTER',
          value: specialRateCode,
        },
      ];
      break;
    case 'S9R':
      ratesType = [
        {
          type: 'CLUSTER',
          value: specialRateCode,
        },
      ];
      break;
    case 'corp':
      // Check if it is Rewards Redemption flow based on the cluster code that is entered
      if (REWARDS_REDEMPTION_CLUSTER_CODES.includes(availabilityRequestVO?.corporateCode?.toLocaleUpperCase())) {
        ratesType = [
          {
            type: 'CLUSTER',
            value: 'MRW',
          },
          {
            type: 'CLUSTER',
            value: 'P17',
          },
          {
            type: 'STANDARD',
            value: '',
          },
        ];
      } else {
        ratesType = [
          {
            type: 'CLUSTER',
            value: availabilityRequestVO?.corporateCode,
          },
        ];
      }
      break;
    case 'gov':
      ratesType = [
        {
          type: 'GOV',
          value: specialRateCode,
        },
      ];
      break;
    case 'aaa':
      ratesType = [
        {
          type: 'AAA',
          value: specialRateCode,
        },
      ];
      break;
    default:
      ratesType = [
        {
          type: 'STANDARD',
          value: '',
        },
      ];
      break;
  }
  // for strikethorugh story, EEO members may get different rates based on there level.
  if (EEOMemberLevel && availabilityRequestVO?.clusterCode === 'none') {
    ratesType = [
      {
        type: 'STANDARD',
        value: '',
      },
      {
        type: 'CLUSTER',
        value: EEOMemberLevel,
      },
    ];
  }
  if (rewardsRedemption) {
    ratesType = [
      {
        type: 'CLUSTER',
        value: 'MRW', // hardcode the value to get the points on the search result
      },
      {
        type: 'STANDARD',
        value: '',
      },
      {
        type: 'CLUSTER',
        value: 'P17',
      },
    ];
  }
  /*
  retrieving domain and using it to enable mandatory fee
  */
  const isMandatoryFeeApplicable = config?.ALLOWED_DOMAINS.includes(currentLocale);
  const defaultObj = {
    sessionToken,
    keyword: search_keyword,
    latitude: latitude ?? null,
    longitude: longitude ?? null,
    destinationAddressPlaceId: destinationAddressPlaceId,
    amenities: amenitiesObj,
    usePoints: rewardsRedemption,
    activities: activitiesObj,
    brands: brandsObj,
    transportationTypes: transportationTypesObj,
    hotelTypes: hotelTypesObj,
    events: eventsObj,
    states: statesObj,
    cities: citiesObj,
    showFullPrice: searchCriteria?.showFullPrice === 'true',
    showAvailableHotels: isUnsuccessfulUserJourney() ? false : searchCriteria?.showAvailableHotels,
    selectedCurrency: selectedCurrency,
    options: {
      startDate: checkIn,
      endDate: checkOut,
      includeMandatoryFees: isMandatoryFeeApplicable && !rewardsRedemption ? true : false,
      numberInParty: numGuestsPerRoom,
      childAges: childrenAges?.split(',')?.map((key: any) => {
        return parseInt(key);
      }),
      rateRequestTypes: ratesType,
      quantity: numRooms,
    },
  };
  return defaultObj;
}

export function getPriceCommaSeparator(
  numb: number,
  searchQueryState: SearchStateStoreDataType,
  currentLocale: string,
  hqvCurrency: string = ''
): string {
  let str: string | number = numb;
  const currentCurrencyValue = hqvCurrency ? hqvCurrency : searchQueryState.currentCurrency;
  if (currentCurrencyValue !== DefaultValue) {
    //replaced toFixed with .floor to have exact price as HQV and Aries
    str = Math.floor(numb);
  }
  return formatNumberByLocale(str, currentLocale);
}

export function getPriceByDecimalPoint(
  price: any,
  decimalPoint: number | undefined,
  searchQueryState: SearchStateStoreDataType,
  currentLocale: string,
  currentPropertyCurrency: string | undefined,
  hqvConversionRate: any = null,
  hqvCurrency: string = ''
): string {
  const defaultCurrency = currentLocale === hardCodedAllLocale.de ? DefaultEURCurrency : DefaultCurrency;
  const currentCurrency = currentPropertyCurrency ?? defaultCurrency;
  const currPrice = Math.floor(price / Math.pow(10, decimalPoint as number));
  const conversionRateArray = hqvConversionRate ? hqvConversionRate : searchQueryState.currentConversionRate;
  const currentConverSionRateArr = conversionRateArray?.filter(
    (key: { currency: string }) => key.currency === currentCurrency
  );
  const currentConverSionRate = currentConverSionRateArr[0]?.currentConversionRate || 1; // set 1 to avoid null value
  const currentCurrencyValue = hqvCurrency ? hqvCurrency : searchQueryState.currentCurrency;
  const convertedPrice = currentCurrencyValue === DefaultValue ? currPrice : currPrice * currentConverSionRate;
  return getPriceCommaSeparator(convertedPrice, searchQueryState, currentLocale, hqvCurrency);
}

export function getCurrRateAmountBlock(
  nodeToBeUsed: string,
  block: SearchRateType | null
): SearchRateAmountType | null | undefined {
  // return rate amount block that to be used on search result
  if (!block) {
    return null;
  }
  const rateAmountBlock = block?.rateAmounts?.filter(
    (key: SearchRateAmountType) => key.rateMode.code === nodeToBeUsed
  )[0];
  return rateAmountBlock;
}

// fetch price block from graphql object on the basis of rate category
export function getCurrentPriceRateBlock(
  currState: SearchStateStoreDataType,
  rateBlockArr?: Array<SearchRateType>
): SearchRateType | null {
  if (!rateBlockArr) {
    return null;
  }
  const rateTypeArr = currState[SearchFacetsConstants.search]?.[SearchFacetsConstants.options]?.rateRequestTypes;
  // filter standard rate every time
  const rateBlock = rateBlockArr?.filter((key: SearchRateType) => {
    if (key.rateCategory?.value === null && key.rateCategory?.type.code === PRICE_CODE) {
      return true;
    } else {
      if (rateTypeArr?.length && rateTypeArr?.length === 1) {
        return (
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          key.rateCategory?.value?.toLowerCase() === rateTypeArr[0]?.value?.toString().toLowerCase()
        );
      }
      return false;
    }
  })[0];
  return rateBlock;
}

// fetch rate block from graphql object on the basis of points savers flag
export function getCurrentPointSaversRateBlock(rateBlockArr?: Array<SearchRateType>): SearchRateType | null {
  if (!rateBlockArr) {
    return null;
  }
  const pointSaversBlock = rateBlockArr?.filter((key: SearchRateType) => {
    if (key.rateCategory?.value === P17_CODE && key.status?.code === AVAILABLE_CODE) {
      return true;
    } else {
      return false;
    }
  })[0];
  return pointSaversBlock;
}

// fetch points block from graphql object on the basis of rate category
export function getCurrentRatePointsBlock(
  rateBlockArr?: Array<SearchRateType>
): SearchRateAmountType | null | undefined {
  if (!rateBlockArr) {
    return null;
  }
  // get points from the same block that we are going to use for price
  const rateBlock = rateBlockArr?.filter(
    (key: SearchRateType) =>
      key.rateCategory?.value === POINTS_CODE_VALUE && key.rateCategory?.type.code === SPECIAL_CODE
  )[0];
  return getCurrRateAmountBlock(PointsNodeTypeToBeUsed, rateBlock);
}

export function getRateBlock(rateBlockArr?: Array<SearchRateType>, filterType?: string): SearchRateType | null {
  if (!rateBlockArr) {
    return null;
  }
  let rateBlock = {};
  if (filterType === 'points') {
    rateBlock = rateBlockArr?.filter(
      (key: SearchRateType) =>
        key.rateCategory?.value === POINTS_CODE_VALUE && key.rateCategory?.type.code === SPECIAL_CODE
    )[0];
  } else if (filterType === 'eeo') {
    rateBlock = rateBlockArr?.filter(
      (key: SearchRateType) =>
        key.rateCategory?.value &&
        EEO_MEMBER_CODE.indexOf(key.rateCategory?.value) > -1 &&
        key.rateCategory?.type.code === SPECIAL_CODE
    )[0];
  }
  return rateBlock;
}

export function getAlertsData(alert: any, type: string): SearchResultAlertType {
  let info = {};
  switch (type) {
    case 'info':
      info = {
        code: '',
        heading: alert?.alertHeadingLabel ? alert.alertHeadingLabel : '',
        hasLink: false,
        linkUrl: '',
        linkText: alert?.alertLinkTextLabel ? alert.alertLinkTextLabel : '',
        errorMessage: alert?.alertMessageLabel ? alert.alertMessageLabel : '',
        showPageAndFilter: false,
        icon: 'icon-information',
        type: 'info',
      };
      break;
    case 'warning':
      info = {
        code: 'queries.v2.properties.limit.range.dev.2',
        heading: alert?.alertHeadingLabel ? alert.alertHeadingLabel : '',
        hasLink: false,
        linkUrl: '',
        linkText: alert?.alertLinkTextLabel ? alert.alertLinkTextLabel : '',
        errorMessage: alert?.alertMessageLabel ? alert.alertMessageLabel : '',
        showPageAndFilter: true,
        icon: 'icon-warning',
        type: 'warning',
      };
      break;
    case 'success':
      info = {
        code: '',
        heading: alert?.alertHeadingLabel ? alert.alertHeadingLabel : '',
        hasLink: false,
        linkUrl: '',
        // linkCallbackFunc: setDefaultPrice(),
        linkText: alert?.alertLinkTextLabel ? alert.alertLinkTextLabel : '',
        errorMessage: alert?.alertMessageLabel ? alert.alertMessageLabel : '',
        showPageAndFilter: true,
        icon: 'icon-success',
        type: 'success',
      };
      break;
    //error message shows any of the below case satisfies
    case 'search.form.near.address.property.result.not.found':
      info = {
        code: 'search.form.near.address.property.result.not.found',
        heading: alert?.alertHeadingLabel ? alert.alertHeadingLabel : '',
        hasLink: false,
        linkUrl: '',
        linkText: alert?.alertLinkTextLabel ? alert.alertLinkTextLabel : '',
        errorMessage: alert?.alertMessageLabel ? alert.alertMessageLabel : '',
        showPageAndFilter: true,
        icon: 'icon-information',
        type: 'search-form-near',
      };
      break;
    case 'no.hotels.within.radius.error.msg':
      info = {
        code: 'no.hotels.within.radius.error.msg',
        heading: alert?.alertHeadingLabel ? alert.alertHeadingLabel : '',
        hasLink: false,
        linkUrl: '',
        icon: 'icon-information',
        linkText: alert?.alertLinkTextLabel ? alert.alertLinkTextLabel : '',
        errorMessage: alert?.alertMessageLabel ? alert.alertMessageLabel : '',
        showPageAndFilter: true,
        type: 'no-hotels',
      };
      break;
    case 'no.availability.within.radius.error.msg':
      info = {
        code: 'no.availability.within.radius.error.msg',
        heading: alert?.alertHeadingLabel ? alert.alertHeadingLabel : '',
        hasLink: false,
        linkUrl: '',
        linkText: alert?.alertLinkTextLabel ? alert.alertLinkTextLabel : '',
        errorMessage: alert?.alertMessageLabel ? alert.alertMessageLabel : '',
        showPageAndFilter: true,
        icon: 'icon-information',
        type: 'no-availability',
      };
      break;
    //LAR-1003 case
    case 'property.search.hotels.maxcapacity.not.available':
      info = {
        code: 'property.search.hotels.maxcapacity.not.available',
        heading: alert?.alertHeadingLabel ? alert.alertHeadingLabel : '',
        hasLink: false,
        linkUrl: '',
        linkText: alert?.alertLinkTextLabel ? alert.alertLinkTextLabel : '',
        errorMessage: alert?.alertMessageLabel ? alert.alertMessageLabel : '',
        showPageAndFilter: true,
        icon: 'icon-information',
        type: 'property-search',
      };
      break;
    case 'search.form.property.result.not.found':
      info = {
        code: '',
        heading: alert?.alertHeadingLabel ? alert.alertHeadingLabel : '',
        hasLink: false,
        linkUrl: '',
        linkText: alert?.alertLinkTextLabel ? alert.alertLinkTextLabel : '',
        errorMessage: alert?.alertMessageLabel ? alert.alertMessageLabel : '',
        showPageAndFilter: true,
        icon: 'icon-information',
        type: 'result-not-found',
      };
      break;
    //UnsuccessfulSell alert
    case 'UnsuccessfulSell':
      info = {
        code: '',
        heading: alert?.alertHeadingLabel ? alert.alertHeadingLabel : '',
        hasLink: false,
        linkUrl: '',
        linkText: alert?.alertLinkTextLabel ? alert.alertLinkTextLabel : '',
        errorMessage: alert?.alertMessageLabel ? alert.alertMessageLabel : '',
        showPageAndFilter: true,
        icon: 'icon-warning',
        type: 'unsuccessfulSell',
      };
      break;
    default:
      info = {
        code: '',
        heading: '',
        hasLink: false,
        linkUrl: '',
        linkText: '',
        errorMessage: '',
        showPageAndFilter: true,
        icon: '',
        // type:''
      };
      break;
  }
  return info;
}

export function getTotalFilterCount(obj: SearchStateStoreDataType | SearchStateExtendsType | null): number {
  const arr = Object.values(SearchFacetsList)?.map((filterType, _filterKey) => {
    const tempSearchData = obj?.[filterType] ?? {};
    return Object.keys(tempSearchData).length;
  });
  return arr.reduce((prevVal, val) => prevVal + val);
}

export function getScreenCategory() {
  let screenCategory = '';
  if (window.innerWidth < 240) {
    screenCategory = 'XXS';
  } else if (window.innerWidth >= 240 && window.innerWidth < 320) {
    screenCategory = 'XS';
  } else if (window.innerWidth >= 320 && window.innerWidth < 480) {
    screenCategory = 'S';
  } else if (window.innerWidth >= 480 && window.innerWidth < 560) {
    screenCategory = 'SM';
  } else if (window.innerWidth >= 560 && window.innerWidth < 672) {
    screenCategory = 'M';
  } else if (window.innerWidth >= 672 && window.innerWidth < 768) {
    screenCategory = 'MML';
  } else if (window.innerWidth >= 768 && window.innerWidth < 864) {
    screenCategory = 'ML';
  } else if (window.innerWidth >= 864 && window.innerWidth < 960) {
    screenCategory = 'MLL';
  } else if (window.innerWidth >= 960 && window.innerWidth < 1024) {
    screenCategory = 'L';
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
    screenCategory = 'XL';
  } else if (window.innerWidth >= 1200 && window.innerWidth < 1440) {
    screenCategory = 'XXL';
  } else if (window.innerWidth >= 1440) {
    screenCategory = 'XXXL';
  } else {
    screenCategory = 'Unknown';
  }

  return screenCategory;
}

export function updateDataLayerObj(
  dataLayer: Record<string, unknown>,
  _searchResults: any,
  _searchQuery: SearchStateStoreDataType | any,
  isFlexibleDateSearch: boolean,
  isMapViewEnabled: boolean,
  datalayerProperties: any,
  isAutoSuggestSelectedSearch?: boolean,
  isRecentViewSelectedSearch?: boolean,
  sortByOptions?: string | null,
  referer?: string
) {
  let deviceOrientation = datalayerProperties?.NEXT_PUBLIC_ORIENTATION_LANDSCAPE;
  const screenCategory = getScreenCategory();
  const params = new URLSearchParams(window.location.search);
  // screenCategory = window.innerWidth < 576 ? 'S' : window.innerWidth < 768 ? 'M' : window.innerWidth < 992 ? 'L' : 'XXL';
  if (window.innerWidth < 576 && window.innerHeight > window.innerWidth) {
    deviceOrientation = datalayerProperties?.NEXT_PUBLIC_ORIENTATION_PORTRAIT;
  }
  let startIndex = _searchResults?.pageInfo?.currentOffset ?? 0;
  if (_searchResults?.total) {
    startIndex = startIndex + 1;
  }
  const endIndex =
    _searchResults?.pageInfo?.hasNextPage === true ? _searchResults?.pageInfo?.nextOffset : _searchResults?.total ?? 0;
  const recordsValue = `${startIndex}*${endIndex}`;
  const totalPages = Math.ceil(parseInt(_searchResults?.total) / _searchQuery?.limit);

  let marshaListArray: any = [];
  const allRateList: any = [];
  let criteria2: any = [];

  if (!isFlexibleDateSearch) {
    marshaListArray = _searchResults?.edges?.map(
      (res: { node: { property: { id: string } } }) => res?.node?.property?.id
    );

    //updated the code when points are applied.
    if (_searchQuery?.pointsEnable) {
      _searchResults?.edges?.forEach((edge: any) => {
        const currRateNode = getCurrentPriceRateBlock(_searchResults, edge?.node?.rates);
        const currRateDetails = getCurrRateAmountBlock(RateNodeTypeToBeUsed, currRateNode);
        if (currRateDetails) {
          const ratesToPush = Math.round(
            currRateDetails?.amount?.origin?.value / Math.pow(10, currRateDetails?.amount?.origin?.valueDecimalPoint)
          );
          allRateList.push(ratesToPush);
        }
      });
      criteria2 = allRateList;
    } else {
      //updated the code for when includeMandatoryFees is true.
      if (_searchQuery?.search?.options?.includeMandatoryFees) {
        _searchResults?.edges?.forEach((edge: any) => {
          const currRateNode = getCurrentPriceRateBlock(_searchQuery, edge?.node?.rates);
          const currRateDetails = getCurrRateAmountBlock(RateNodeTypeToBeUsed, currRateNode);
          if (
            currRateDetails &&
            currRateDetails.amountPlusMandatoryFees &&
            currRateDetails.amountPlusMandatoryFees.origin
          ) {
            const ratesToPush = Math.round(
              currRateDetails?.amountPlusMandatoryFees?.origin?.value /
                Math.pow(10, currRateDetails?.amountPlusMandatoryFees?.origin?.valueDecimalPoint)
            );
            allRateList.push(ratesToPush);
          }
        });
      } else {
        //updated the code for when includeMandatoryFees is false.
        _searchResults?.edges?.forEach((edge: any) => {
          const currRateNode = getCurrentPriceRateBlock(_searchQuery, edge?.node?.rates);
          const currRateDetails = getCurrRateAmountBlock(RateNodeTypeToBeUsed, currRateNode);
          if (currRateDetails) {
            const ratesToPush = Math.round(
              currRateDetails?.amount?.origin?.value / Math.pow(10, currRateDetails?.amount?.origin?.valueDecimalPoint)
            );
            allRateList.push(ratesToPush);
          }
        });
      }
      criteria2 = allRateList;
    }
  } else {
    marshaListArray = _searchResults?.edges?.map((res: { node: { id: string } }) => res?.node?.id);
  }

  let selectedFilters = '';
  let uniqueSelectedFilters = '';
  let appliedSearchFilters = '';
  Object.keys(_searchQuery).forEach(key => {
    filterList.forEach(filter => {
      if (key === filter && _searchQuery[filter] && Object.values(_searchQuery[filter]).length > 0) {
        const selectedFilter = Object.values(_searchQuery[filter]).toString();
        selectedFilters = selectedFilters + selectedFilter + ',';
        if (filter === dataLayerLabels.price) {
          uniqueSelectedFilters = uniqueSelectedFilters + `${selectedFilter}-PRICE,`;
          appliedSearchFilters = appliedSearchFilters + key + `:${selectedFilter}-PRICE|`;
        } else if (filter === dataLayerLabels.points) {
          uniqueSelectedFilters = uniqueSelectedFilters + `${selectedFilter}-POINT,`;
          appliedSearchFilters = appliedSearchFilters + key + `:${selectedFilter}-POINT,|`;
        } else if (filter === dataLayerLabels.distance) {
          uniqueSelectedFilters = uniqueSelectedFilters + `${selectedFilter}-DIST,`;
          appliedSearchFilters = appliedSearchFilters + key + `:${selectedFilter}-DIST|`;
        } else {
          uniqueSelectedFilters = uniqueSelectedFilters + selectedFilter + ',';
          appliedSearchFilters = appliedSearchFilters + key + ':' + selectedFilter + '|';
        }
      }
    });
  });

  const completeFilterList: { [x: string]: string }[] = [];
  _searchResults?.facets?.forEach((bucketList: any) => {
    const bucketType = bucketList?.type?.code;
    bucketList?.buckets?.forEach(
      (filter: {
        code: string;
        label: string;
        description: string;
        index: number;
        count: number;
        start: string;
        end: string;
      }) => {
        if (filter.code) {
          if (filter.code === filter.description) {
            completeFilterList.push({ [filter.code]: `${filter.label} (${filter.count})` });
          } else {
            completeFilterList.push({ [filter.code]: `${filter.description} (${filter.count})` });
          }
        } else {
          if (bucketType === 'price') {
            completeFilterList.push({ [`${filter.index}-PRICE`]: `${filter.start}-${filter.end}` });
          } else if (bucketType === 'distance') {
            completeFilterList.push({
              [`${filter.index}-DIST`]: `${Math.floor(parseInt(filter?.start) / MILESTOMETERS)?.toFixed(
                0
              )}-${Math.floor(parseInt(filter?.end) / MILESTOMETERS)?.toFixed(0)}`,
            });
          } else {
            completeFilterList.push({ [`${filter.index}-POINT`]: `${filter.start}-${filter.end}` });
          }
        }
      }
    );
  });

  let selectedFilterArr: any = [];
  selectedFilterArr = uniqueSelectedFilters.split(',');

  let selectedFilterLabels = '';
  selectedFilterArr.forEach((key: string) => {
    completeFilterList.forEach(filter => {
      if (key in filter) {
        const selectedFilterLabel = filter[key];
        selectedFilterLabels = selectedFilterLabels + selectedFilterLabel + '|';
      }
    });
  });

  if (selectedFilterLabels.charAt(selectedFilterLabels.length - 1) === '|') {
    selectedFilterLabels = selectedFilterLabels.slice(0, -1);
  }

  let appliedSearchFilterArr: any = [];
  appliedSearchFilterArr = appliedSearchFilters.split('|');

  let appliedSearchFilterLabels = '';
  appliedSearchFilterArr.forEach((key: string) => {
    completeFilterList.forEach(filter => {
      key
        .split(':')[1]
        ?.split(',')
        ?.forEach(filterKey => {
          if (filterKey in filter) {
            const filterCategory =
              key.split(':')[0].charAt(0) + key.split(':')[0].toLowerCase().slice(1).replace('_', ' ') + ':';
            const appliedFilterLabel =
              key.split(':')[0] === dataLayerLabels.points ||
              key.split(':')[0] === dataLayerLabels.price ||
              key.split(':')[0] === dataLayerLabels.brand ||
              key.split(':')[0] === dataLayerLabels.distance ||
              key.split(':')[0] === dataLayerLabels.states
                ? filterCategory + filter[filterKey].split('(')[0].trim()
                : filterCategory +
                  filterKey.split(':')[0].charAt(0).toUpperCase() +
                  filterKey.split(':')[0].toLowerCase().slice(1).replace('-', ' ');
            appliedSearchFilterLabels = appliedSearchFilterLabels + appliedFilterLabel + '|';
          }
        });
    });
  });

  if (_searchQuery?.currentCurrency) {
    appliedSearchFilterLabels =
      appliedSearchFilterLabels +
      (_searchQuery?.currentCurrency !== 'default'
        ? searchFilters.selectedCurrency.charAt(0).toUpperCase() +
          searchFilters.selectedCurrency.slice(1) +
          ':' +
          _searchQuery?.currentCurrency
        : searchFilters.selectedCurrency.charAt(0).toUpperCase() +
          searchFilters.selectedCurrency.slice(1) +
          ':' +
          (_searchQuery?.currentOriginCurrency ? _searchQuery?.currentOriginCurrency : _searchQuery?.currentCurrency)) +
      '|';
  }

  if (!_searchQuery?.includeUnavailableProperties) {
    appliedSearchFilterLabels = appliedSearchFilterLabels + showAvailabilityLabel + '|';
  } else {
    appliedSearchFilterLabels.replace(`|${showAvailabilityLabel}`, '');
  }

  if (_searchQuery?.includeTaxesAndFees) {
    appliedSearchFilterLabels = appliedSearchFilterLabels + showTaxesandFeesLabel + '|';
  } else {
    appliedSearchFilterLabels.replace(`|${showTaxesandFeesLabel}`, '');
  }

  if (appliedSearchFilterLabels.charAt(appliedSearchFilterLabels.length - 1) === '|') {
    appliedSearchFilterLabels = appliedSearchFilterLabels.slice(0, -1);
  }

  let pathType = '';
  if (Cookies.get('newSearch')) {
    pathType = datalayerProperties?.NEXT_PUBLIC_PATHTYPE_NEW ?? '';
  } else {
    pathType = datalayerProperties?.NEXT_PUBLIC_PATHTYPE_LEGACY ?? '';
  }

  dataLayer['search_criteria_filter_selected_ids'] = selectedFilters.replace(/,\s*$/, '');
  dataLayer['search_result_refinements'] = selectedFilterLabels;
  dataLayer['search_filters_applied'] = appliedSearchFilterLabels;
  dataLayer['search_list_records_start_end_index'] = recordsValue;
  dataLayer['search_res_path_type'] = pathType;
  dataLayer['search_list_records_on_page'] =
    _searchResults?.total > _searchQuery?.limit ? _searchQuery?.limit?.toString() : _searchResults?.total?.toString();
  dataLayer['search_multiRate_or_single'] = datalayerProperties?.NEXT_PUBLIC_SEARCH_SINGLE_RATE ?? '';
  dataLayer['search_list_pages_total'] = totalPages?.toString();
  dataLayer['search_list_number_of_page'] = totalPages?.toString();
  dataLayer['search_list_page_current'] = parseInt(dataLayer['search_list_page_current'] as any);
  dataLayer['search_impression_criteria3'] = `${_searchQuery?.sort?.fields[0]?.field?.toLowerCase()};${
    !isFlexibleDateSearch && _searchQuery?.currentCurrency !== 'default' ? _searchQuery?.currentCurrency : ''
  };${_searchQuery?.includeUnavailableProperties ? false : true};${_searchQuery?.limit};${_searchQuery?.nextPage + 1};${
    _searchResults?.total
  }`;
  dataLayer['search_impression_criteria2'] = !isFlexibleDateSearch
    ? criteria2?.toString()?.replaceAll(',', ';').replaceAll(';;', ';')
    : '';
  dataLayer['search_list_sort_types_available'] = sortByOptions;
  dataLayer['search_total_price_checkbox'] = !isFlexibleDateSearch ? _searchQuery?.includeTaxesAndFees : false;
  dataLayer['search_list_setting_records_per_page'] = _searchQuery?.limit;
  dataLayer['search_list_records_total'] = _searchResults?.total;
  dataLayer['search_list_marshas_on_page'] = marshaListArray?.toString().replaceAll(',', ';');
  dataLayer['search_list_setting_default_sort'] = _searchQuery?.sort?.fields[0]?.field === 'DISTANCE' ? true : false;
  dataLayer['search_currency_type'] = !isFlexibleDateSearch
    ? _searchQuery?.currentCurrency && _searchQuery?.currentCurrency !== 'default'
      ? _searchQuery?.currentCurrency
      : _searchResults?.edges[0]?.node?.rates[0]?.rateAmounts[0]?.amount?.origin?.currency ?? 'Default'
    : 'Default';
  dataLayer['previous_page'] = window.document?.referrer;
  dataLayer['ram_tile_names'] = datalayerProperties?.NEXT_PUBLIC_RAM_TILE_NAME;
  dataLayer[
    'page_breakpt_orientation'
  ] = `${datalayerProperties?.NEXT_PUBLIC_PAGE_BREAKPT}${screenCategory} - ${window.innerWidth}w: ${deviceOrientation}`;
  dataLayer['page_type'] = window.location?.href;
  dataLayer['search_results_view'] = isMapViewEnabled
    ? datalayerProperties?.NEXT_PUBLIC_SEARCH_MAPVIEW
    : datalayerProperties?.NEXT_PUBLIC_SEARCH_LISTVIEW;
  dataLayer['ensightenEnabled'] = true;
  dataLayer['search_type'] =
    !isAutoSuggestSelectedSearch && !isRecentViewSelectedSearch
      ? 'typed'
      : !isRecentViewSelectedSearch && isAutoSuggestSelectedSearch
      ? 'Selected Recommended Result'
      : !isAutoSuggestSelectedSearch && isRecentViewSelectedSearch
      ? 'Selected Recent Searches'
      : '';
  dataLayer['page_data_layer_ready'] = 'true';
  if (dataLayer['search_result_position_selected']) {
    delete dataLayer['search_result_position_selected'];
  }
  if (
    params.has('unsuccessfulSell') &&
    params.get('unsuccessfulSell') === 'true' &&
    _searchResults?.total &&
    _searchResults?.total > 0
  ) {
    dataLayer['search_sold_out_search'] = 'true';
  }
  const searchCounterValue = sessionStorage.getItem('updateSearchCounter');
  dataLayer['updates_search_call_counter'] = searchCounterValue;
  // Update the window.location.hash with the update search counter
  window.history.replaceState({}, referer as string, `#/${searchCounterValue}/`);
}

export function updateDataLayerObjSigin(
  dataLayer: Record<string, unknown>,
  // isMapViewEnabled: boolean,
  datalayerProperties: any
) {
  let deviceOrientation = datalayerProperties?.NEXT_PUBLIC_ORIENTATION_LANDSCAPE;
  const screenCategory = getScreenCategory();

  if (window.innerWidth < 576 && window.innerHeight > window.innerWidth) {
    deviceOrientation = datalayerProperties?.NEXT_PUBLIC_ORIENTATION_PORTRAIT;
  }
  // ************** ram_tile_names - real logic
  // window.trackAnalyticsObj = JSON.stringify(obj);
  //   //populate Tile Names in dataLayer
  //   if (typeof obj.tiles != "undefined") {
  //     var tempList = "";
  //     $.each(obj.tiles, function(index, value) {
  //       tempList += value.name;
  //       //check for errors, add loaded status
  //       if (typeof value.error !== "undefined") {
  //         tempList += "_Not Loaded|";
  //       } else {
  //         tempList += "_Loaded|";
  //       }
  //       //tempList += value.id.substring(0, value.id.lastIndexOf("_") ) + "|";
  //     });
  //     tempList = tempList.replace(/\|\s*$/, "");
  //     dataLayer.ram_tile_names = tempList;
  //   }

  dataLayer['ensightenEnabled'] = true;
  dataLayer[
    'page_breakpt_orientation'
  ] = `${datalayerProperties?.NEXT_PUBLIC_PAGE_BREAKPT}${screenCategory} - ${window.innerWidth}w: ${deviceOrientation}`;
  // dataLayer['search_results_view'] = isMapViewEnabled
  //   ? datalayerProperties?.NEXT_PUBLIC_SEARCH_MAPVIEW
  //   : datalayerProperties?.NEXT_PUBLIC_SEARCH_LISTVIEW;
  dataLayer['page_type'] = window.location?.href;
  dataLayer['previous_page'] = window.document?.referrer;
  dataLayer['ram_tile_names'] = datalayerProperties?.NEXT_PUBLIC_RAM_TILE_NAME;
}

export function getAllPropertyIDS(arr: any) {
  /**
   * return all property ids from array
   */
  return arr?.map((key: any) => (key.node.id === undefined ? key.node.property.id : key.node.id));
}

export function extractDataFromResponse(data: any, isDesti: boolean, isFlexi: boolean, isStateSearch?: boolean) {
  /**
   * receive all type of request data and return required formatted data
   * data = <graphql response>
   * displayAlert = <show alert function>
   */
  let key;
  if (enableHotelList() === true) {
    if (isFlexi) {
      key = GraphQlAPiResponseKey.FLEXI_by_id;
    } else {
      key = GraphQlAPiResponseKey.LAR_by_id;
    }
  } else {
    if (isFlexi) {
      //flexible search
      key = isDesti ? GraphQlAPiResponseKey.FLEXI_desti : GraphQlAPiResponseKey.FLEXI_geo;
    } else {
      // dated with LAR
      if (isStateSearch && isDesti) {
        key = GraphQlAPiResponseKey.LAR_search_input;
      } else {
        key = isDesti ? GraphQlAPiResponseKey.LAR_desti : GraphQlAPiResponseKey.LAR_geo;
      }
    }
  }
  return data[key];
}
export function mergeRatesWithBasicInfo(rates: any, infoArr: any) {
  /**
   * rates = <rates data error from LAr>
   * inforArr = <info array from property id info>
   */
  const tempObj = JSON.parse(JSON.stringify(rates));
  const ratesObj = { ...tempObj }; // remove readonly property from obj
  const ratesEdges = ratesObj?.edges?.map((key: any) => {
    if (key.node.property) {
      const id = key.node.property.id;
      const obj = infoArr?.filter((info: any) => info.id === id)[0];
      key.node.property = {
        ...key.node.property,
        ...obj,
      };
      return key;
    } else {
      const id = key.node.id;
      const obj = infoArr?.filter((info: any) => info.id === id)[0];
      key.node = {
        ...key.node,
        ...obj,
      };
      return key;
    }
  });
  ratesObj.edges = ratesEdges;
  return ratesObj;
}

export const fieldsInteractedInSERP = (interactionsSelected: any): any => {
  const fieldsWithTrueVal: string[] = [];

  for (const [key, value] of Object.entries(interactionsSelected)) {
    if (value === true) {
      fieldsWithTrueVal.push(key);
    }
  }

  return fieldsWithTrueVal;
};

export const interactionsAppliedOnSERP = (
  searchQueryState: any,
  formValues: any,
  lengthOfStayValue: number,
  filters: number,
  featureSelected: boolean,
  isFlexibleDateSearch: boolean,
  searchResult: any
): any => {
  const appliedInteractions = {
    brands: Object.keys(searchQueryState?.BRANDS ?? {}),
    includeUnavailableHotels: searchQueryState?.includeUnavailableProperties,
    includeTaxesAndFees: searchQueryState?.includeTaxesAndFees,
    sortByOption: searchQueryState?.sort?.fields[0]?.field,
    pageNumber: searchQueryState?.nextPage / searchQueryState?.limit + 1,
    currencyType: searchQueryState?.currentCurrency,
    priceRange: Object.keys(searchQueryState?.PRICE ?? {}),
    pointsRange: Object.keys(searchQueryState?.POINTS ?? {}),
    distanceRange: Object.keys(searchQueryState?.DISTANCE ?? {}),
    amenities: Object.keys(searchQueryState?.AMENITIES ?? {}),
    transportations: Object.keys(searchQueryState?.TRANSPORTATION_TYPES ?? {}),
    hotelTypes: Object.keys(searchQueryState?.PROPERTY_TYPES ?? {}),
    events: Object.keys(searchQueryState?.MEETINGS_EVENTS ?? {}),
    states: Object.keys(searchQueryState?.STATES ?? {}),
    cities: Object.keys(searchQueryState?.CITIES ?? {}),
    activities: Object.keys(searchQueryState?.ACTIVITIES ?? {}),
    formValues: formValues,
    lengthOfStay: lengthOfStayValue,
    filtersCount: filters,
    featureSelected: featureSelected,
    isFlexibleDateSearch: isFlexibleDateSearch,
    propertyRecordsCount: searchResult?.total ?? 0,
  };

  return appliedInteractions;
};

export function formPageURLQueryString(interactions: any, delimiter: string) {
  return `destinationAddress.destination=${
    interactions?.formValues?.destination?.displayText
  }${delimiter}propertyCode=${delimiter}marriottRewardsNumber=${delimiter}fromDateDefaultFormat=${
    interactions?.formValues?.dates?.fromDate !== undefined
      ? interactions?.formValues?.dates?.fromDate.format('MM/DD/YYYY')
      : ''
  }${delimiter}airportCode=${delimiter}childrenAges=${
    interactions?.formValues?.roomsAndGuests?.childrenAges
  }${delimiter}lengthOfStay=${
    interactions?.formValues?.dates?.fromDate !== undefined && interactions?.formValues?.dates?.toDate !== undefined
      ? interactions?.lengthOfStay
      : 0
  }${delimiter}search-locality=${delimiter}lowestRegularRate=${
    interactions?.formValues?.specialRates?.clusterCode === 'none' ? true : false
  }${delimiter}collapseAccordian=is-hidden${delimiter}t-end=${
    interactions?.formValues?.dates?.toDate !== undefined
      ? interactions?.formValues?.dates?.toDate.format('YYYY-MM-DD')
      : ''
  }${delimiter}isRateCalendar=true${delimiter}isTransient=true${delimiter}numAdultsPerRoom=${
    interactions?.formValues?.roomsAndGuests?.adultsCount
  }${delimiter}destinationAddress.secondaryText=${
    interactions?.formValues?.destinationHiddenFields?.state &&
    interactions?.formValues?.destinationHiddenFields?.country
      ? `${interactions?.formValues?.destinationHiddenFields?.state}, ${interactions?.formValues?.destinationHiddenFields?.country}`
      : `${interactions?.formValues?.destinationHiddenFields?.state ?? ''}` ||
        `${interactions?.formValues?.destinationHiddenFields?.country ?? ''}`
  }${delimiter}toDateDefaultFormat=${
    interactions?.formValues?.dates?.toDate !== undefined
      ? interactions?.formValues?.dates?.toDate.format('MM/DD/YYYY')
      : ''
  }${delimiter}isInternalSearch=true${delimiter}fromToDate=${delimiter}suggestionsPropertyCode=${delimiter}recordsPerPage=40${delimiter}poiCity=${delimiter}incentiveType_Number=${delimiter}destinationAddress.city=${
    interactions?.formValues?.destinationHiddenFields?.city ?? ''
  }${delimiter}destinationAddress.placeId=${
    interactions?.formValues?.destination?.pid
  }${delimiter}destinationAddress.stateProvinceShort=${delimiter}multiRateCorpCodesEntered=${delimiter}isSearch=true${delimiter}multiRateMaxCount=${delimiter}searchType=InCity${delimiter}search-countryRegion=${delimiter}destinationAddress.longitude=${
    interactions?.formValues?.destinationHiddenFields?.longitude ?? ''
  }${delimiter}destinationAddress.name=${delimiter}countryName=${delimiter}roomTypeCode=${delimiter}destinationAddress.latitude=${
    interactions?.formValues?.destinationHiddenFields?.latitude ?? ''
  }${delimiter}clusterCode=${interactions?.formValues?.specialRates?.clusterCode}${delimiter}fromToDate_submit=${
    interactions?.formValues?.dates?.toDate !== undefined
      ? interactions?.formValues?.dates?.toDate.format('MM/DD/YYYY')
      : ''
  }${delimiter}destinationAddress.postalCode=${delimiter}poiName=${delimiter}flexibleDateLowestRateMonth=${delimiter}isHideFlexibleDateCalendar=false${delimiter}t-start=${
    interactions?.formValues?.dates?.fromDate !== undefined
      ? interactions?.formValues?.dates?.fromDate.format('YYYY-MM-DD')
      : ''
  }${delimiter}destinationAddress.cityPopulation=${delimiter}flexibleDateSearchRateDisplay=false${delimiter}destinationAddress.cityPopulationDensity=${delimiter}multiRateClusterCodes=${delimiter}roomCount=${
    interactions?.formValues?.roomsAndGuests?.roomsCount
  }${delimiter}destinationAddress.countryShort=${delimiter}incentiveType=${delimiter}destinationAddress.stateProvinceDisplayName=${delimiter}js-location-nearme-values=${delimiter}destinationAddress.address=${
    interactions?.formValues?.destination?.displayText
  }${delimiter}fromDate=${
    interactions?.formValues?.dates?.fromDate !== undefined
      ? interactions?.formValues?.dates?.fromDate.format('MM/DD/YYYY')
      : ''
  }${delimiter}for-hotels-nearme=Near${delimiter}childrenCount=${
    interactions?.formValues?.roomsAndGuests?.childrenCount
  }${delimiter}searchRadius=${
    interactions?.formValues?.destinationHiddenFields?.radius
  }${delimiter}destinationAddress.locality=${delimiter}flexibleDateSearch=${
    interactions?.formValues?.dates?.fromDate !== undefined && interactions?.formValues?.dates?.toDate !== undefined
      ? interactions?.isFlexibleDateSearch
      : false
  }${delimiter}multiRateCorpCodes=${delimiter}useMultiRateRewardsPoints=${delimiter}toDate=${
    interactions?.formValues?.dates?.toDate !== undefined
      ? interactions?.formValues?.dates?.toDate.format('MM/DD/YYYY')
      : ''
  }${delimiter}vsInitialRequest=false${delimiter}destinationAddress.destinationPageDestinationAddress=${delimiter}initialRequest=true${delimiter}guestCountBox=${
    interactions?.formValues?.roomsAndGuests?.adultsCount
  } Adult Per Room${delimiter}propertyName=${delimiter}singleSearchAutoSuggest=Unmatched${delimiter}showAddressPin=${delimiter}corporateCode=${
    interactions?.formValues?.specialRates?.corporateCode
  }${delimiter}destinationAddress.website=${delimiter}airportName=${delimiter}singleSearch=true${delimiter}autoSuggestItemType=${delimiter}destinationAddress.stateProvince=${
    interactions?.formValues?.destinationHiddenFields?.state ?? ''
  }${delimiter}flexibleDateLowestRateDate=${delimiter}destinationAddress.types=${delimiter}destinationAddress.country=${
    changeCountryCodeForChina(interactions?.formValues?.destinationHiddenFields?.country) ?? ''
  }${delimiter}destinationAddress.mainText=${
    interactions?.formValues?.destinationHiddenFields?.city
  }${delimiter}roomCountBox=${
    interactions?.formValues?.roomsAndGuests?.roomsCount
  } Room${delimiter}isMultiRateSearch=${delimiter}childrenCountBox=${
    interactions?.formValues?.roomsAndGuests?.childrenCount
  } Children Per Room${delimiter}useRewardsPoints=${
    interactions?.formValues?.usePoints?.value
  }${delimiter}rateClusterSetNumType=${delimiter}rateClusterSetNum=`;
}

function checkArrayOfObj(savedProperties: string | any[]) {
  for (let i = 0; i < savedProperties.length; i++) {
    if (typeof savedProperties[i] !== 'string') {
      return false;
    }
  }
  return true;
}

/* update saved props related session variables */
function updateSavedPropsRelatedSessionVariables(
  sessionData: any,
  accessToken: string,
  savedProperties: any,
  AriesCommonPayloadObject: any,
  AriesRewardsPayloadObject: any
) {
  const isSavedPropertyStringArr = checkArrayOfObj(savedProperties);
  const savedPropertyList: unknown[] = [];
  if (!isSavedPropertyStringArr) {
    savedProperties?.forEach((savedObj: ArrayLike<unknown> | { [s: string]: unknown }) => {
      if (Object.values(savedObj).length) {
        savedPropertyList.push(Object.values(savedObj)[0]);
      }
    });
  }
  if (sessionData?.consumerID && accessToken) {
    if (sessionData?.AriesCommon) {
      AriesCommonPayloadObject.savedProps = isSavedPropertyStringArr
        ? String(savedProperties)?.toUpperCase()
        : String(savedPropertyList)?.toUpperCase();
      AriesCommonPayloadObject.savedPropSize = String(savedProperties?.length);
      AriesCommonPayloadObject.mr_prof_saved_prop = isSavedPropertyStringArr
        ? String(savedProperties)?.toUpperCase()
        : String(savedPropertyList)?.toUpperCase();
      AriesCommonPayloadObject.mr_prof_saved_prop_count = String(savedProperties?.length);
      AriesCommonPayloadObject.memState = 'authenticated';
    }
    if (sessionData?.AriesRewards) {
      AriesRewardsPayloadObject.savedHotelList = isSavedPropertyStringArr ? savedProperties : savedPropertyList;
    }
  }
}

function updateListOrMapButtonRelatedSessionVariables(isMapView: boolean | undefined, AriesSearchPayloadObject: any) {
  if (isMapView) {
    AriesSearchPayloadObject.searchCriteria.searchResultViewType = 'map';
  } else if (!isMapView) {
    AriesSearchPayloadObject.searchCriteria.searchResultViewType = 'list';
  }
}

/* update filters related session variables */

function updateFiltersRelatedSessionVariables(
  sessionData: any,
  interactions: any,
  selectedInteractions: any,
  removeBlock: any,
  AriesSearchPayloadObject: any,
  searchQueryState: SearchStateStoreDataType | null,
  isFlexibleDateSearch: boolean
) {
  AriesSearchPayloadObject.searchCriteria.moreThanTwoFilters = interactions?.filtersCount > 2 ? true : false;
  AriesSearchPayloadObject.searchCriteria.moreThanOneFilter = interactions?.filtersCount > 1 ? true : false;

  if (!sessionData?.AriesSearch?.propertyFilterCriteria && interactions?.filtersCount > 0) {
    AriesSearchPayloadObject.propertyFilterCriteria = {};
  }

  if (searchQueryState !== null && searchQueryState?.currentConversionRate?.length > 0) {
    const currConversionBlock = searchQueryState?.currentConversionRate;
    // Checking to see if currency is default in which case conversion factor should be 1
    AriesSearchPayloadObject.currentConversionRate =
      searchQueryState?.currentCurrency !== DefaultValue && !isFlexibleDateSearch
        ? currConversionBlock
        : [
            {
              currency: 'default',
              currentConversionRate: 1,
              currentConversionRateDecimalPoint: 0,
            },
          ];
    AriesSearchPayloadObject.currentConversionRateDecimalPoint =
      searchQueryState?.currentCurrency !== DefaultValue && !isFlexibleDateSearch
        ? currConversionBlock
        : [
            {
              currency: 'default',
              currentConversionRate: 1,
              currentConversionRateDecimalPoint: 0,
            },
          ];
  }

  const facets =
    interactions?.brands?.length ||
    interactions?.amenities?.length ||
    interactions?.transportations?.length ||
    interactions?.hotelTypes?.length ||
    interactions?.events?.length ||
    interactions?.states?.length ||
    interactions?.cities?.length ||
    interactions?.activities?.length;

  const rangeFilters =
    interactions?.priceRange?.length || interactions?.pointsRange?.length || interactions?.distanceRange?.length;

  // Update propertyFilterCriteria based on selectedInteractions
  if (
    facets > 0 &&
    selectedInteractions?.length > 0 &&
    sessionData?.AriesSearch?.propertyFilterCriteria &&
    AriesSearchPayloadObject?.propertyFilterCriteria &&
    AriesSearchPayloadObject?.propertyFilterCriteria?.facets &&
    Object.keys(AriesSearchPayloadObject?.propertyFilterCriteria?.facets)?.length
  ) {
    for (const originalKey of selectedInteractions) {
      const key = keyMappings[originalKey] || originalKey;
      if (key in AriesSearchPayloadObject.propertyFilterCriteria.facets) {
        AriesSearchPayloadObject.propertyFilterCriteria.facets[key] = [];
        delete AriesSearchPayloadObject.propertyFilterCriteria.facets[key];
      }
    }
  } else if (selectedInteractions?.length > 0 && facets > 0) {
    AriesSearchPayloadObject.propertyFilterCriteria.facets = {};
  }

  if (rangeFilters > 0) {
    AriesSearchPayloadObject.propertyFilterCriteria.rangeFilters = {};
  }

  if (rangeFilters === 0 && sessionData?.AriesSearch?.propertyFilterCriteria?.rangeFilters) {
    removeBlock?.push('AriesSearch.propertyFilterCriteria.rangeFilters');
  }

  if (facets === 0 && sessionData?.AriesSearch?.propertyFilterCriteria?.facets) {
    removeBlock?.push('AriesSearch.propertyFilterCriteria.facets');
  }

  if (facets === 0 && rangeFilters === 0 && sessionData?.AriesSearch?.propertyFilterCriteria) {
    removeBlock?.push('AriesSearch.propertyFilterCriteria');
  }
}

/* update availability filter related session variables */
function updateAvailabilityFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  AriesSearchPayloadObject.searchCriteria.availabilityRequestVO.excludeUnavailableProperties =
    !interactions?.includeUnavailableProperties;
  AriesSearchPayloadObject.searchCriteria.showAllHotel = interactions?.includeUnavailableProperties;
}

/* update price filter related session variables */
function updatePriceFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.priceRange?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.rangeFilters.price = interactions?.priceRange;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.rangeFilters?.price;
  }
}

/* update points filter related session variables */
function updatePointsFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.pointsRange?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.rangeFilters.points = interactions?.pointsRange;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.rangeFilters?.points;
  }
}

/* update amenities filter related session variables */
function updateAmenitiesFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.amenities?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.facets.amenities = interactions?.amenities;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.facets?.amenities;
  }
}

/* update brands filter related session variables */
function updateBrandsFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.brands?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.facets.brands = interactions?.brands;
    AriesSearchPayloadObject.searchCriteria.brandCodes = interactions?.brands;
  } else {
    // Remove the 'brands' key from facets
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.facets?.brands;
  }
}

/* update distance filter related session variables */
function updateDistanceFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.distanceRange?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.rangeFilters.distance = interactions?.distanceRange;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.rangeFilters?.distance;
  }
}

/* update transportation filter related session variables */
function updateTransportationFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.transportations?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.facets['transportation-types'] = interactions?.transportations;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.facets?.['transportation-types'];
  }
}

/* update hotel type filter related session variables */
function updateHotelTypeFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.hotelTypes?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.facets['property-types'] = interactions?.hotelTypes;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.facets?.['property-types'];
  }
}

/* update event type filter related session variables */
function updateEventsFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.events?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.facets['meetings-and-events'] = interactions?.events;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.facets?.['meetings-and-events'];
  }
}

/* update activities filter related session variables */
function updateActivitiesFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.activities?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.facets['activities-on-site'] = interactions?.activities;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.facets?.['activities-on-site'];
  }
}

/* update states filter related session variables */
function updateStatesFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.states?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.facets.states = interactions?.states;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.facets?.states;
  }
}

/* update city filter related session variables */
function updateCityFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  if (interactions?.cities?.length) {
    AriesSearchPayloadObject.propertyFilterCriteria.facets.cities = interactions?.cities;
  } else {
    delete AriesSearchPayloadObject?.propertyFilterCriteria?.facets?.cities;
  }
}

/* update pagination related session variables */
function updatePaginationFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  AriesSearchPayloadObject.searchCriteria.paginationAndSortingRequestVO.pageNumber = interactions?.pageNumber;
}

/* update taxes and fees related session variables */
function updateTaxesAndFeesFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  AriesSearchPayloadObject.searchCriteria.showFullPrice = String(interactions?.includeTaxesAndFees)?.toLowerCase();
}

/* update currency conversion filter related session variables */
function updateCurrencyFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  AriesSearchPayloadObject.searchCriteria.selectedCurrency = `${interactions?.currencyType
    ?.charAt(0)
    ?.toUpperCase()}${interactions?.currencyType?.slice(1)}`;
}

/* update sort by related session variables */
function updateSortByFiltersRelatedSessionVariables(interactions: any, AriesSearchPayloadObject: any) {
  AriesSearchPayloadObject.searchCriteria.paginationAndSortingRequestVO.sortType =
    interactions?.sortByOption?.toLowerCase();
}

/* call and update datalayer on any changes in SERP */
async function callDataLayerOnFeatureChanges(
  resolvedUrl: any,
  headersData: any,
  query: any,
  datalayerParams: any,
  sessionID: string
) {
  const getPageURI = resolvedUrl ? resolvedUrl.split('?')[0] : '';
  const MOCK_SESSION_ID = '825167AA-9F54-5B1B-95C0-53EEC4E22391';
  const absolutePageURL = `${getPageURI}.mi`;
  const localeKeyValue = headersData?.['accept-language']?.split(',')[0]?.replace('-', '_');
  const channelValue = datalayerParams?.NEXT_PUBLIC_DATALAYER_SITENAME?.split('.')[0];
  const datalayerUrl = datalayerParams?.MI_DATALAYER_APP_URL || '';

  const getHeaders = (): string => {
    let cookieValues = '';
    if (headersData?.mi_site) {
      cookieValues = cookieValues + ` MI_SITE=${headersData?.mi_site};`;
    }
    if (headersData?.mi_visitor) {
      cookieValues = cookieValues + ` MI_VISITOR=${headersData?.mi_visitor};`;
    }
    if (headersData?.remembermeuserid) {
      cookieValues = cookieValues + ` RememberMeUserID=${encodeURIComponent(headersData?.remembermeuserid)};`;
    }
    if (headersData?.remembermealternateid) {
      cookieValues = cookieValues + ` RememberMeAlternateID=${encodeURIComponent(headersData?.remembermealternateid)};`;
    }
    if (headersData?.useridtoken) {
      cookieValues = cookieValues + ` UserIdToken=${encodeURIComponent(headersData?.useridtoken)};`;
    }
    return cookieValues;
  };

  const headersKey: Record<string, string | undefined> = {};

  // creating headers for datalayer call
  if (headersData && Object.keys(headersData).length > 0) {
    DATALAYER_CONSTANT.headerKeys.forEach(name => {
      if (name && headersData[name as KeysRequest['keys']]) {
        headersKey[name] = headersData[name as KeysRequest['keys']];
      }
    });
  }

  let acceptLangHeader;
  if (headersKey['accept-language']) {
    acceptLangHeader = headersKey['accept-language'];
    delete headersKey['accept-language'];
  }

  const reqBody = {
    context: {
      localeKey: localeKeyValue ?? 'en_US',
      programFlag: '',
      siteName: datalayerParams?.NEXT_PUBLIC_DATALAYER_SITENAME,
      mobileAuthEnabled: 'false',
      productSiteId: datalayerParams?.NEXT_PUBLIC_DATALAYER_PRODUCT,
      channel: channelValue,
      pageContent: [],
      pageURI: getPageURI,
      referer: headersData?.referer,
      absolutePageURL: absolutePageURL,
      applicationName: datalayerParams?.NEXT_PUBLIC_DATALAYER_APPLICATION,
      products: datalayerParams?.NEXT_PUBLIC_DATALAYER_PRODUCT,
      template: datalayerParams?.NEXT_PUBLIC_DATALAYER_TEMPLATE,
      seoQueryParams: query,
    },
    sessionToken: sessionID ?? MOCK_SESSION_ID,
    sourceURI: absolutePageURL,
    variation: datalayerParams?.NEXT_PUBLIC_DATALAYER_VARIATION,
  };

  // datalayer call
  const dataLayer = await Axios.post(datalayerUrl, reqBody, {
    headers: {
      // please make sure these coookies are in single line. Else there will be `TypeError [ERR_INVALID_CHAR]`
      cookie: `sessionID=${sessionID}; RememberMeFlag=${headersData?.remembermeflag}; ${getHeaders()}`,
      'Accept-Language': getLanguage() || acceptLangHeader || NEXT_PUBLIC_DEFAULT_LANG || '',
      ...headersKey,
    },
  });

  log.debug(`${headersKey}, 'headersKey headersKey`);
  log.debug(`==========datalayerUrl==========: ${datalayerUrl}`);
  log.debug(`==========reqBody==========: ${JSON.stringify(reqBody)}`);
  log.debug(`==========response==========: ${JSON.stringify(dataLayer)}`);

  const datalayerResponse = dataLayer?.data?.component?.data?.dataProperties;
  if (datalayerResponse?.length && window?.dataLayer) {
    for (const [key] of Object.entries(window.dataLayer)) {
      datalayerResponse?.forEach((entry: any) => {
        if (window.dataLayer) {
          if (key === entry.key) {
            window.dataLayer[key] = entry.value;
          } else {
            window.dataLayer[entry.key] = entry.value;
          }
        }
      });
    }
  }
}

export async function updateSessionDocProperties(
  sessionID: string,
  accessToken: string,
  SESSION_APP_GET_CALL_URL: string,
  SESSION_APP_POST_CALL_URL: string,
  datalayerParams: any,
  searchQueryState: SearchStateStoreDataType | null,
  savedProperties?: any,
  interactions?: any,
  resolvedUrl?: any,
  headersData?: any,
  query?: any,
  selectedInteractions?: any,
  isMapView?: boolean | undefined,
  updateSearch?: boolean
) {
  const sessionUrl: any = SESSION_APP_GET_CALL_URL;
  const postSessionUrl: any = SESSION_APP_POST_CALL_URL;
  const removeBlock: any = [];
  let postCallTriggered = false;
  let postPayload: any = {};
  let response: any = {};

  try {
    if (
      interactions?.featureSelected ||
      (selectedInteractions &&
        selectedInteractions.length > 0 &&
        selectedInteractions.indexOf('savedProperties') > -1) ||
      (selectedInteractions &&
        selectedInteractions.length > 0 &&
        selectedInteractions.indexOf('listOrMapButton') > -1) ||
      interactions?.isFlexibleDateSearch
    ) {
      response = await Axios.post(
        sessionUrl,
        {
          keys: SESSION_SEARCH_KEYS,
        },
        {
          headers: {
            Cookie: `sessionID=${sessionID}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      );
      const sessionDoc = response?.data;
      const AriesCommonPayloadObject = sessionDoc?.cacheData?.data?.AriesCommon;
      const AriesSearchPayloadObject = sessionDoc?.cacheData?.data?.AriesSearch;
      const AriesRewardsPayloadObject = sessionDoc?.cacheData?.data?.AriesRewards;
      const AriesUpdateSessionPayloadObject: any = {};

      updateFiltersRelatedSessionVariables(
        sessionDoc?.cacheData?.data,
        interactions,
        selectedInteractions,
        removeBlock,
        AriesSearchPayloadObject,
        searchQueryState,
        interactions?.isFlexibleDateSearch
      );
      log.debug('values inside array', selectedInteractions);

      selectedInteractions?.forEach((value: any) => {
        switch (value) {
          case 'availability':
            updateAvailabilityFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'price':
            updatePriceFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'points':
            updatePointsFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'amenities':
            updateAmenitiesFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'brands':
            updateBrandsFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'distance':
            updateDistanceFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'transportation':
            updateTransportationFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'hotelType':
            updateHotelTypeFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'events':
            updateEventsFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'activities':
            updateActivitiesFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'states':
            updateStatesFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'city':
            updateCityFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'pagination':
            updatePaginationFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'taxesAndFees':
            updateTaxesAndFeesFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'currencyConversion':
            updateCurrencyFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'sortBy':
            updateSortByFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'quickFilters':
            updateAmenitiesFiltersRelatedSessionVariables(interactions, AriesSearchPayloadObject);
            break;
          case 'savedProperties':
            updateSavedPropsRelatedSessionVariables(
              sessionDoc?.cacheData?.data,
              accessToken,
              savedProperties,
              AriesCommonPayloadObject,
              AriesRewardsPayloadObject
            );
            break;
          case 'listOrMapButton':
            updateListOrMapButtonRelatedSessionVariables(isMapView, AriesSearchPayloadObject);
            break;
          default:
            log.debug('None of the above cases matched', value);
            break;
        }
      });

      if (sessionDoc?.cacheData?.data?.consumerID && accessToken === null) {
        AriesCommonPayloadObject.memState = 'remembered';
      }

      if (!sessionDoc?.cacheData?.data?.consumerID && !accessToken) {
        AriesCommonPayloadObject.memState = 'unauthenticated';
      }
      // updateMiscSessionVariables(
      //   sessionDoc?.cacheData?.data,
      //   interactions,
      //   criteriaChanged,
      //   AriesCommonPayloadObject,
      //   AriesSearchPayloadObject
      // );
      AriesUpdateSessionPayloadObject['AriesSearch'] = AriesSearchPayloadObject;
      AriesUpdateSessionPayloadObject['AriesCommon.memState'] = AriesCommonPayloadObject?.memState;
      if (sessionDoc?.cacheData?.data?.consumerID && accessToken) {
        if (AriesRewardsPayloadObject?.savedHotelList) {
          AriesUpdateSessionPayloadObject['AriesRewards.savedHotelList'] = AriesRewardsPayloadObject?.savedHotelList;
        }
        if (AriesCommonPayloadObject?.savedProps) {
          AriesUpdateSessionPayloadObject['AriesCommon.savedProps'] = AriesCommonPayloadObject?.savedProps;
        }
        if (AriesCommonPayloadObject?.savedPropSize) {
          AriesUpdateSessionPayloadObject['AriesCommon.savedPropSize'] = AriesCommonPayloadObject?.savedPropSize;
        }
        if (AriesCommonPayloadObject?.mr_prof_saved_prop) {
          AriesUpdateSessionPayloadObject['AriesCommon.mr_prof_saved_prop'] =
            AriesCommonPayloadObject?.mr_prof_saved_prop;
        }
        if (AriesCommonPayloadObject?.mr_prof_saved_prop_count) {
          AriesUpdateSessionPayloadObject['AriesCommon.mr_prof_saved_prop_count'] =
            AriesCommonPayloadObject?.mr_prof_saved_prop_count;
        }
      }

      await Axios.post(
        postSessionUrl,
        {
          createOrUpdate: AriesUpdateSessionPayloadObject,
          remove: removeBlock,
        },
        {
          headers: {
            Cookie: `sessionID=${sessionID}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      );
      postCallTriggered = true;
      postPayload = sessionDoc;
      postPayload.cacheData.data.AriesCommon = AriesCommonPayloadObject;
      postPayload.cacheData.data.AriesSearch = AriesSearchPayloadObject;
      postPayload.cacheData.data.AriesRewards = AriesRewardsPayloadObject;
    }

    if (postCallTriggered || updateSearch) {
      callDataLayerOnFeatureChanges(resolvedUrl, headersData, query, datalayerParams, sessionID);
    }

    if (postCallTriggered) {
      return postPayload;
    } else {
      return response?.data;
    }
  } catch (error) {
    // ignore
    return error;
  }
}

export function getDestinationHiddenFilterData(hiddenData: any): SearchStateExtendsType {
  log.debug(
    hiddenData.state
      ? {
          [SearchFacetsList.states]: {
            [hiddenData.state]: hiddenData.state,
          },
        }
      : {
          [SearchFacetsList.countries]: {
            [hiddenData.country]: hiddenData.country,
          },
        },
    'dest'
  );
  if (hiddenData.state || hiddenData.stateProvince) {
    if (hiddenData.state) {
      return {
        [SearchFacetsList.states]: {
          [hiddenData.state]: hiddenData.state,
        },
      };
    } else {
      return {
        [SearchFacetsList.states]: {
          [hiddenData.stateProvince]: hiddenData.stateProvince,
        },
      };
    }
  } else {
    return {
      [SearchFacetsList.countries]: {
        [hiddenData.country]: hiddenData.country,
      },
    };
  }
}

// fetch strikethrough special offered rate block from graphql object on the basis of rate category and memberLevel
export function getCurrentRateStrikeThroughBlock(
  rateBlockArr?: Array<SearchRateType>
): SearchRateAmountType | null | undefined {
  if (!rateBlockArr) {
    return null;
  }
  if (rateBlockArr.length > 1) {
    // get strikethorugh price from the same block that we are going to use for price
    const rateBlock = rateBlockArr?.filter(
      (key: SearchRateType) =>
        key.rateCategory?.value &&
        EEO_MEMBER_CODE.indexOf(key.rateCategory?.value) > -1 &&
        key.rateCategory?.type.code === SPECIAL_CODE
    )[0];
    return getCurrRateAmountBlock(RateNodeTypeToBeUsed, rateBlock);
  }
  return null;
}

//check Strikethorugh percentage threshold is matching or not
export function checkThresholdPercentage(standardPrice: number, eeoPrice: number): boolean {
  let thresholdFlag = false;
  let percentage = 0;
  let total = 0;

  percentage = (standardPrice * STRIKE_PRICE_THRESHOLD_PERCENTAGE) / 100;
  total = standardPrice - eeoPrice;
  if (total >= percentage) {
    thresholdFlag = true;
  }
  return thresholdFlag;
}

export function getCurrencyOriginAccordToLocale(node: any): SearchRateMonetaryAmountType | undefined | null {
  return node?.origin;
}

interface arrayFilters {
  amenities?: Array<string | number>;
  transportationTypes?: Array<string | number>;
  brands?: Array<string | number>;
  hotelTypes?: Array<string | number>;
  activities?: Array<string | number>;
  events?: Array<string | number>;
  states?: Array<string | number>;
  cities?: Array<string | number>;
}

interface searchFilters extends arrayFilters {
  includeTaxes?: boolean;
  showAvailableHotels?: boolean;
  selectedCurrency?: string;
}

/**
 * Method to update the query parameters in the URL
 * @param filtersSelected {object}
 * @param filtersToUpdate {Array<string>}
 * @returns {void}
 */
export function updateQueryParams(
  filtersSelected: searchFilters,
  filtersToUpdate: Array<string>,
  referer: string
): void {
  const params = new URLSearchParams(window.location.search);
  for (const filter of filtersToUpdate) {
    if (filter === 'includeTaxes' || filter === 'showAvailableHotels' || filter === 'selectedCurrency') {
      params.set(searchFilters[filter], String(filtersSelected?.[filter]));
    } else {
      if (filtersSelected?.[filter as keyof arrayFilters]?.length) {
        params.set(
          searchFilters[filter as keyof arrayFilters],
          filtersSelected?.[filter as keyof arrayFilters]?.join(',') as string
        );
      } else if (enableBoostBrands() === true && searchFilters[filter as keyof arrayFilters] === 'marriottBrands') {
        const brandsList = params?.getAll('marriottBrands')?.toString();
        params.set(searchFilters[filter as keyof searchFilters], brandsList);
        params.set('showMore', 'true');
      } else {
        params.delete(searchFilters[filter as keyof searchFilters]);
      }
    }
  }
  if (filtersSelected?.selectedCurrency) {
    window.history.replaceState({}, referer, decodeURIComponent(`?${params}${window.location.hash}`));
  } else {
    window.history.replaceState({}, referer, decodeURIComponent(`?${params}`));
  }
}

/**
 * Method to update the query parameters in the URL
 * @param SessionID {String}
 * @returns SessionObject
 */
export async function getHQVSessionData(sessionID: string, SESSION_APP_GET_CALL_URL: string) {
  const sessionUrl: any = SESSION_APP_GET_CALL_URL;
  try {
    const response = await Axios.post(
      sessionUrl,
      {
        keys: SESSION_SEARCH_KEYS,
      },
      {
        headers: {
          Cookie: `sessionID=${sessionID}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

/**
 * Method to get the rank of an index
 * @param index {number}
 * @returns string
 */
export function getIndexOfPropertyCard(index?: number): string {
  const idx = index ?? 0;
  if ((idx + 1) % 40 === 1 || (idx + 1) % 40 === 21 || (idx + 1) % 40 === 31) {
    return 'st';
  } else if ((idx + 1) % 40 === 2 || (idx + 1) % 40 === 22 || (idx + 1) % 40 === 32) {
    return 'nd';
  } else if ((idx + 1) % 40 === 3 || (idx + 1) % 40 === 23 || (idx + 1) % 40 === 33) {
    return 'rd';
  } else return 'th';
}

/**
 * Method to add datalayer attribute on click of view rates button on property card
 * @param searchResult {any}
 * @param pageNumber {number}
 * @param index {number}
 * @param isFlexibleDateSearch {boolean}
 * @returns void
 */
export function updateDatalayerObjViewRates(
  searchResult?: any,
  pageNumber?: number,
  index?: number,
  isFlexibleDateSearch?: boolean,
  HQVRateBlockFlag: boolean = false
) {
  const idx = index ?? 0;
  const rank = getIndexOfPropertyCard(index);
  if (!isFlexibleDateSearch && !HQVRateBlockFlag) {
    const search_result_position_selected = `page ${pageNumber}|${
      searchResult?.pageInfo?.hasNextPage === true ? searchResult?.pageInfo?.nextOffset : searchResult?.total
    } results per page|${idx + 1}${rank} selected`;
    sessionStorage.setItem('search_result_position_selected', search_result_position_selected);
  }
}

/**
 * Method to check boost brands in the URL
 * @returns boolean
 */
export function enableBoostBrands(): boolean {
  const params = new URLSearchParams(window.location.search);
  const hasBrandsParam = params?.has('marriottBrands') && params?.getAll('marriottBrands')?.length > 0;
  const hasShowMoreParam = params?.has('showMore') && params?.get('showMore') === 'true';

  if (hasBrandsParam && hasShowMoreParam) return true;
  else return false;
}

/**
 * Method to check if the unsuccessful user journey alert should be displayed
 * @returns boolean
 */
export function isUnsuccessfulUserJourney(): boolean {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    const unsuccessfulSellFlag = params?.has('unsuccessfulSell') && params?.get('unsuccessfulSell') === 'true';
    return unsuccessfulSellFlag;
  } else {
    return false;
  }
}

/**
 * Method to check hotel list params in the url
 * @returns boolean
 */
export function enableHotelList(): boolean {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    const hasHotelsParam =
      params?.has('searchType') &&
      (params?.get('searchType') === 'MiniStoresHotelList' || params?.get('searchType') === 'HotelList');
    const hasHotelListParam = params?.has('hotelList') && params?.getAll('hotelList')?.length > 0;

    if (hasHotelsParam && hasHotelListParam) return true;
    else return false;
  } else {
    return false;
  }
}

/**
 * Method to clear the couchbase and show favorite properties instantly
 * @param consumerID {string}
 * @param url {string}
 * @param loggerApiURL {string}
 * @returns SavedPropertiesObject
 */
export const clearCouchBaseCache = async (consumerID: string, url: string, loggerApiURL: string) => {
  let result;
  try {
    result = await Axios.post(url, {
      consumerID,
    });
    return result;
  } catch (err) {
    Axios.post(loggerApiURL, {
      message: `Error while clearing cache for consumerId ${consumerID}: ${JSON.stringify(err)}`,
    });
    return null;
  }
};

/**
 * function return the active brands
 */

export const filterBrands = (brandsArray: any, searchQueryStateBrands: any) => {
  /**
   * To store the non disable brands in filteredBrandsByCategory
   */
  const filteredBrandsByCategory: any = {};
  brandsArray?.forEach((category: any) => {
    const categoryBrands = category?.brandsLabelListItems;
    categoryBrands?.forEach((brand: any) => {
      const brandTagId = brand?.brandTagId;
      /**
       * Checking the brands in searchQueryState brands and the brand state in the jsonContent
       */
      if (brand?.brandTagId && searchQueryStateBrands?.[brand?.brandTagId] && !brand?.disable) {
        filteredBrandsByCategory[brandTagId] = brandTagId;
      }
    });
  });
  /**
   * Retrun the non disabled brands
   */
  return filteredBrandsByCategory;
};
