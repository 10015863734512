import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledPropertyListContainerDiv = styled.div`
  ${rtl`
  height: 100%;
  .active-drawer-list{
    display:none;
  }
  .m-message-inline.warning {
    flex: 0 0 96%;
    margin-right: ${toRem(14)};
  }
  .pagination-container{
      padding-top: ${toRem(12)};
  }
  .property-card-outer-container {
    flex-grow: 0.9;
    padding: 0;
    width: 100vw;
  }
  .outer-map-view-class{
    width: ${toRem(354)};
   --gp-placement-max-height: calc(100vh - ${toRem(320)});
    height: var(--gp-placement-max-height);
    overflow-y: auto;
    margin-right: ${toRem(6.5)};
    ::-webkit-scrollbar {
      width: ${toRem(8)};
    }
    ::-webkit-scrollbar-track {
      background: ${theme.color.white};
      border-radius: ${toRem(4)};
      margin: ${toRem(50)} 0;
    }
    .sort-by-label {
      line-height: normal;
      width: ${toRem(100)};
      text-align: right;
    }

    .current-sort-option {
      white-space: nowrap;
      line-height: normal;
      width: ${toRem(100)};
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .search-summary-container {
      width: ${toRem(125)};
      line-height: normal;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.color['light-gray-3']} !important;
      border-radius: ${toRem(4)};
      height:${toRem(183)};
      background-clip: unset;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.color['dark-gray-10']} !important;
    }
    .map-view-selected  {
      .property-card-container {
        .text-container {
           .price-container {
                .property-card-price-component {
                  .rate-container{
                      .points-section{ 
                        .price-section{
                          text-align: right;
                          }
                      }
                  }
           }
        }
   }
}
      width: ${toRem(332)};
      margin-top: ${toRem(0)};
      &.with-alerts{
        margin-top: ${toRem(0)};
      }
      .property-card {
        margin-bottom: ${toRem(16)};
      }
    }
  }
  @media only ${theme.mediaQuery.allTablets} {
    .property-card-container {
      .property-card {
        width:auto;
      }
    }
    .map-view-selected {
      display: none;
    }
  }
  @media only ${theme.mediaQuery.allTablets} {
    .property-card-outer-container{
      padding: 0;
    }
  }
  @media only ${theme.mediaQuery.allMobiles} {
    .property-card-outer-container{
      padding: 0;
    }
    .property-card-container {
      .property-card {
        width: auto;
        margin: ${toRem(15)};
        overflow: hidden;
        border-radius: ${toRem(14)};
        box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
      }
    }
    .map-view-selected {
      display: none;
    }
  }`}
`;
