export const CardListUXLMockJson = {
  offersSearch: {
    __typename: 'offersSearchConnection',
    edges: [
      {
        __typename: 'OffersSearchEdge',
        node: {
          __typename: 'Offer',
          descriptionTeaser: 'Settle in and save on 5+ consecutive nights.',
          id: '1355935',
          photos: {
            __typename: 'AdtImages',
            captions: [
              {
                __typename: 'AdtImageCaption',
                caption: 'Woman in blue dress standing under a tree ',
                length: 'short',
              },
              {
                __typename: 'AdtImageCaption',
                caption: 'Woman standing under falling leaves',
                length: 'medium',
              },
              {
                __typename: 'AdtImageCaption',
                caption: 'Create new memories this fall with our favorite packages of the season and save',
                length: 'large',
              },
            ],
            images: [
              {
                __typename: 'AdtImage',
                aspectRatio: 'Classic',
                url: '/is/image/marriotts7prod/bonvoy-falling-leaves-37906:Classic-Hor',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Feature',
                url: '/is/image/marriotts7prod/bonvoy-falling-leaves-37906:Feature-Hor',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Pano',
                url: '/is/image/marriotts7prod/bonvoy-falling-leaves-37906:Pano-Hor',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Wide',
                url: '/is/image/marriotts7prod/bonvoy-falling-leaves-37906:Wide-Hor',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Square',
                url: '/is/image/marriotts7prod/bonvoy-falling-leaves-37906:Square',
              },
            ],
          },
          seoNickname: 'the-westin-monache-resort-mammoth',
          title: 'Save on 5+ Nights',
          url: 'https://www.marriott.com/offers/long-term-stay-savings-in-the-u-s-and-canada-1355935',
          urlTitle: 'long-term-stay-savings-in-the-u-s-and-canada',
        },
      },
      {
        __typename: 'OffersSearchEdge',
        node: {
          __typename: 'Offer',
          descriptionTeaser:
            "It's a Celebration! Treat yourself to a special getaway at The Westin Monache in Mammoth!",
          id: 'OFF-86202',
          photos: {
            __typename: 'AdtImages',
            captions: [
              {
                __typename: 'AdtImageCaption',
                caption: 'Pool',
                length: 'short',
              },
              {
                __typename: 'AdtImageCaption',
                caption: 'Outdoor Pool',
                length: 'medium',
              },
              {
                __typename: 'AdtImageCaption',
                caption:
                  'Take delight in warm water on your skin any time of year in our heated outdoor pool at The Westin Monache Resort, Mammoth.',
                length: 'large',
              },
            ],
            images: [
              {
                __typename: 'AdtImage',
                aspectRatio: 'Classic',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-6482-hor-clsc.jpg',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Feature',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-6482-hor-feat.jpg',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Pano',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-6482-hor-pano.jpg',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Wide',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-6482-hor-wide.jpg',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Square',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-6482-sq.jpg',
              },
            ],
          },
          seoNickname: 'the-westin-monache-resort-mammoth',
          title: 'Celebrate Premium',
          url: 'https://www.marriott.com/offers/celebrate-premiums-off-86202/mmhwi-the-westin-monache-resort-mammoth',
          urlTitle: 'celebrate-premiums',
        },
      },
      {
        __typename: 'OffersSearchEdge',
        node: {
          __typename: 'Offer',
          descriptionTeaser: 'Save up to 20% on stays at Westin Monache Mammoth',
          id: 'OFF-52987',
          photos: {
            __typename: 'AdtImages',
            captions: [
              {
                __typename: 'AdtImageCaption',
                caption: 'Refresh Pool Deck',
                length: 'short',
              },
              {
                __typename: 'AdtImageCaption',
                caption: 'Outdoor Pool',
                length: 'medium',
              },
              {
                __typename: 'AdtImageCaption',
                caption:
                  'Soothe your sore muscles in the whirlpools or take a refreshing dip in the outdoor heated pool on our pool deck after a day of skiing or hiking Mammoth Mountain.',
                length: 'large',
              },
            ],
            images: [
              {
                __typename: 'AdtImage',
                aspectRatio: 'Classic',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-5382-hor-clsc.jpg',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Feature',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-5382-hor-feat.jpg',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Pano',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-5382-hor-pano.jpg',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Wide',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-5382-hor-wide.jpg',
              },
              {
                __typename: 'AdtImage',
                aspectRatio: 'Square',
                url: '/marriottassets/marriott/MMHWI/mmhwi-pool-5382-sq.jpg',
              },
            ],
          },
          seoNickname: 'the-westin-monache-resort-mammoth',
          title: 'Ikon Pass Holders Save On Stays In Mammoth',
          url: 'https://www.marriott.com/offers/ikon-pass-holders-save-on-stays-in-mammoth-off-52987/mmhwi-the-westin-monache-resort-mammoth',
          urlTitle: 'ikon-pass-holders-save-on-stays-in-mammoth',
        },
      },
    ],
  },
};

// this has four offers
export const CardListUXLMockJsonFourOffers = {
  offersSearch: {
    __typename: 'offersSearchConnection',
    edges: [...CardListUXLMockJson.offersSearch.edges, CardListUXLMockJson.offersSearch.edges[0]],
  },
};
