import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { lg, md, xl } = { ...baseVariables.mediaQuery };

export const StyledBrandRibbon = styled.div`
  margin-top: ${toRem(32)};
  margin-bottom: ${toRem(32)};
  &.brand-ribbon-extra-pt {
    padding-top: ${toRem(19)};
  }

  .brands-ribbon-inner-container {
    padding: ${toRem(0)} ${toRem(20)} ${toRem(14)};
    border: ${toRem(2)} solid ${baseVariables.color['neutral20']};
  }
  .brands-ribbon-logo {
    text-align: center;
    display: block;
    margin-top: ${toRem(-9)};
    margin-bottom: ${toRem(16)};
    a {
      position: relative;
      bottom: ${toRem(10)};
      font-size: ${toRem(32)};
      font-weight: ${baseVariables.font['fontWeightRegular']};
      &:hover {
        font-weight: ${baseVariables.font['fontWeightRegular']};
      }
      display: inline-block;
      padding-left: ${toRem(7)};
      padding-right: ${toRem(7)};
      @media screen and ${baseVariables.mediaQuery['tablet']} {
        padding-left: ${toRem(10)};
        padding-right: ${toRem(10)};
        border: ${toRem(5)} solid transparent;
        margin-top: ${toRem(-5)};
      }
      @media screen and ${baseVariables.mediaQuery['desktop']} {
        padding-left: ${toRem(14)};
        padding-right: ${toRem(14)};
      }
      background: ${baseVariables.color['base20']};
    }
  }
  .category {
    padding-top: ${toRem(24)};
    padding-right: ${toRem(5)};
    margin-bottom: ${toRem(12)};
    white-space: nowrap;
    text-align: center;
    @media screen and ${baseVariables.mediaQuery['desktop']} {
      text-align: left;
    }
  }
  .cardLinks {
    &.navBrands {
      .linkCollectionItem {
        display: grid;
        list-style: none;
        padding: 0;
        margin: 0;
        grid-template-columns: repeat(2, 1fr);
        gap: ${toRem(16)};
        padding: ${toRem(14)} ${toRem(26)};
        margin: ${toRem(24)};
        @media ${md} {
          grid-template-columns: repeat(4, 1fr);
          padding: ${toRem(14)} ${toRem(32)};
        }
        @media ${lg} {
          grid-template-columns: repeat(5, 1fr);
          padding: ${toRem(14)} ${toRem(30)};
          margin: ${toRem(40)};
        }
        @media ${xl} {
          grid-template-columns: repeat(6, 1fr);
        }
        .linkItems {
          max-width: ${toRem(155)};
          min-width: ${toRem(104)};
          @media ${md} {
            max-width: ${toRem(168)};
            min-width: ${toRem(140)};
          }
          @media ${lg} {
            max-width: ${toRem(164)};
            min-width: ${toRem(162)};
          }
          a {
            display: flex;
            height: ${toRem(80)};
            border-radius: ${toRem(8)};
            border: 1px solid ${baseVariables.color['neutral30']};
            color: ${baseVariables.color['neutral40']};
            background: ${baseVariables.color['base20']};
            align-items: center;
            justify-content: center;
          }
          [class*='brand-logo-']:hover:before {
            color: ${baseVariables.color['base20']};
          }
          .brand-logo-DE:before {
            font-size: 2.5rem;
          }
          .brand-logo-MD:before {
            font-size: 0.75rem;
          }
          .brand-logo-WI:before {
            font-size: 1rem;
          }
          .brand-logo-DS:before {
            font-size: 0.6rem;
          }
          .brand-logo-BR:before {
            font-size: 2.25rem;
          }
          .brand-logo-CY:before {
            font-size: 0.875rem;
          }
          .brand-logo-FP:before {
            font-size: 2rem;
          }
          .brand-logo-SH:before {
            font-size: 1.875rem;
          }
          .brand-logo-PR:before {
            font-size: 2.25rem;
          }
          .brand-logo-FI:before {
            font-size: 1.375rem;
          }
          .brand-logo-AR:before {
            font-size: 2.5rem;
          }
          .brand-logo-AL:before {
            font-size: 1.875rem;
          }
          .brand-logo-OX:before {
            font-size: 2rem;
          }
          .brand-logo-RI:before {
            font-size: 1rem;
          }
          .brand-logo-TS:before {
            font-size: 1.8rem;
          }
          .brand-logo-EL:before {
            font-size: 2.5rem;
          }
          .brand-logo-HV:before {
            font-size: 2.2rem;
          }
          .brand-logo {
            &-RZ:hover,
            &-rz:hover,
            &-XR:hover,
            &-xr:hover,
            &-EB:hover,
            &-eb:hover,
            &-WH:hover,
            &-eb:hover,
            &-MC:hover,
            &-mc:hover,
            &-DS:hover,
            &-ds:hover,
            &-OX:hover,
            &-ox:hover,
            &-HV:hover,
            &-hv:hover {
              background-color: ${baseVariables.color['base10']};
            }
            &-LC:hover,
            &-lc:hover,
            &-TX:hover,
            &-tx:hover {
              background-color: #565656;
            }
            &-JW:hover,
            &-jw:hover {
              background-color: #6d716b;
            }
            &-MV:hover,
            &-mv:hover {
              background-color: #5f5f5f;
            }
            &-DE:hover,
            &-de:hover {
              background-color: #252487;
            }
            &-MD:hover,
            &-md:hover {
              background-color: #263a50;
            }
            &-WI:hover,
            &-wi:hover {
              background-color: #51555a;
            }
            &-AK:hover,
            &-ak:hover {
              background-color: #37323e;
            }
            &-BR:hover,
            &-br:hover {
              background-color: #2a2e36;
            }
            &-GE:hover,
            &-ge:hover {
              background-color: #003a7a;
            }
            &-CY:hover,
            &-cy:hover {
              background-color: #53575a;
            }
            &-FP:hover,
            &-cy:hover {
              background-color: #53575a;
            }
            &-FP:hover,
            &-fp:hover {
              background-color: #10293c;
            }
            &-SH:hover,
            &-sh:hover {
              background-color: #2f4256;
            }
            &-PR:hover,
            &-pr:hover {
              background-color: #51545d;
            }
            &-FI:hover,
            &-fi:hover {
              background-color: #51748b;
            }
            &-AR:hover,
            &-ar:hover {
              background-color: #51545d;
            }
            &-AL:hover,
            &-al:hover {
              background-color: #303e47;
            }
            &-ER:hover,
            &-er:hover {
              background-color: #413000;
            }
            &-RI:hover,
            &-ri:hover {
              background-color: #502b3a;
            }
            &-TS:hover,
            &-ts:hover {
              background-color: #d42228;
            }
            &-EL:hover,
            &-el:hover {
              background-color: #017a93;
            }
            &-SI:hover,
            &-si:hover {
              background-color: #3a3a3a;
            }
            &-XE:hover,
            &-xe:hover {
              background-color: #024da1;
            }
          }
        }
      }
    }
    .linkCollectionItem {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      margin: 0;
      .linkItems {
        [class*='portfolio-icon-']:hover:before,
        [class*='brand-logo-']:hover:before {
          color: ${baseVariables.color['neutral40']};
        }
        [class*='brand-logo-']:hover {
          background-color: transparent;
        }
        [class*='portfolio-icon-'],
        [class*='brand-logo-'] {
          font-weight: 500;
          outline-offset: 0.25rem;
          padding: 0;
        }
        [class*='portfolio-icon-']:before,
        [class*='brand-logo-']:before {
          font-size: 2rem;
          line-height: normal;
        }
        .portfolio-icon-ritz-carlton:before,
        .brand-logo-RZ:before {
          font-size: 2.4rem;
        }

        .portfolio-icon-autograph-collection:before,
        .portfolio-icon-marriott-executive-apartments:before {
          font-size: 1.5625rem;
        }

        .portfolio-icon-edition:before,
        .brand-logo-EB:before {
          font-size: 0.75rem;
        }

        .portfolio-icon-delta-hotels:before,
        .brand-logo-DE:before {
          font-size: 2.1rem;
        }

        .portfolio-icon-courtyard:before,
        .brand-logo-CY:before {
          font-size: 0.42rem;
        }

        .portfolio-icon-residence-inn:before,
        .brand-logo-RI:before {
          font-size: 0.58rem;
        }

        .portfolio-icon-springhill-suites:before,
        .brand-logo-SH:before {
          font-size: 1.28rem;
        }

        .portfolio-icon-fairfield-inn:before,
        .brand-logo-FI:before {
          font-size: 0.67rem;
        }

        .portfolio-icon-towneplace-suites:before,
        .brand-logo-TS:before {
          font-size: 0.95rem;
        }

        .portfolio-icon-protea-hotels:before,
        .brand-logo-PR:before {
          font-size: 1.27rem;
        }

        .portfolio-icon-moxy:before,
        .brand-logo-OX:before {
          font-size: 1.4rem;
        }

        .portfolio-icon-st-regis:before,
        .brand-logo-XR:before {
          font-size: 2.2rem;
        }

        .portfolio-icon-le-meridien:before,
        .portfolio-icon-westin:before,
        .brand-logo-MD:before,
        .brand-logo-WI:before {
          font-size: 0.6rem;
        }

        .portfolio-icon-tribute-portfolio:before,
        .brand-logo-TX:before {
          font-size: 1rem;
        }

        @media screen and (min-width: 992px) {
          .portfolio-icon-tribute-portfolio:before,
          .brand-logo-TX:before {
            font-size: 0.8rem;
          }
        }

        .portfolio-icon-design-hotels:before,
        .brand-logo-DS:before {
          font-size: 0.4rem;
        }

        .portfolio-icon-aloft:before,
        .brand-logo-AL:before {
          font-size: 1.3rem;
        }

        .portfolio-icon-four-points:before,
        .brand-logo-FP:before {
          font-size: 1.1rem;
        }

        .portfolio-icon-rcr:before,
        .brand-logo-BR:before {
          font-size: 1.08rem;
        }

        .portfolio-icon-ac-hotels:before,
        .portfolio-icon-element:before,
        .brand-logo-AC:before,
        .brand-logo-EL:before {
          font-size: 2.1rem;
        }

        .portfolio-icon-marriott:before,
        .brand-logo-MC:before {
          font-size: 2.4rem;
        }

        .portfolio-icon-vacation-club:before,
        .brand-logo-MV:before {
          font-size: 2.5rem;
        }

        .portfolio-icon-w-hotels:before,
        .brand-logo-WH:before {
          font-size: 0.75rem;
        }

        .portfolio-icon-mg:before,
        .brand-logo-mg:before {
          font-size: 1.3rem;
        }

        a {
          display: block;
          background: none;
          padding: 0.5rem;
        }
      }
    }
  }
`;
