import React, { FC, useEffect, useState } from 'react';

import { LinkBanner } from '@marriott/mi-ui-library';
import { StyledListViewContainer } from './HtmlSitemap.styles';

// Use named rather than default exports.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SitemapListView: FC<any> = (props): any => {
  const {
    model: { columnDetails },
    subTitle,
  } = props;

  const [windowSize, setWindowSize] = useState(typeof window !== 'undefined' && window?.innerWidth);
  props.model.linkBannerHeader = Number(windowSize) < 768 ? subTitle : '';

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // checking window size for customising data incase of resize or change from potrait to landscape
  const handleResize = () => {
    if (typeof window !== 'undefined') {
      setWindowSize(window.innerWidth);
    }
  };

  return (
    <StyledListViewContainer data-testid="sitemap-listview">
      {columnDetails?.length === 0 && <h2>No hotel sitemap details available</h2>}
      {!!columnDetails?.length && <LinkBanner {...props} />}
    </StyledListViewContainer>
  );
};
