/* eslint-disable @typescript-eslint/no-explicit-any */
import dynamic from 'next/dynamic';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Button } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import Cookies from 'js-cookie';
import {
  outletGalleryImages,
  outletMenusSignatureDishAndChefImage,
  offerSearchByOutlets,
} from '@marriott/mi-rnb-graphql';
import { PageParamsContext } from '../../../modules/context/PageContext';
import { IMAGE_URL_DOMAIN, menuId } from '../../../modules/utils/constants';

import { OfferDataTypes } from '../OfferTab/OfferTab.types';
// Tabs
import { StyledOutletTabContainer } from './OutletTab.styles';
import { OverviewTab } from '../OverviewTab/index';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { outletDetailsState, useStore } from 'libs/mi-rnb-components/src/modules/store/outletDetailsStore';
import { scrollSection } from '../../../modules/utils/helpers';

// Dynamic Imports
const GalleryTab = dynamic(() => import('../GalleryTab/GalleryTab'));
const OfferTab = dynamic<OfferDataTypes>(() => import('../OfferTab').then(mod => mod.OfferTab));
const MenuTab = dynamic<any>(() => import('../MenuTab').then(mod => mod.MenuTab));
const SignatureTab = dynamic<any>(() => import('../SignatureTab/SignatureTab').then(mod => mod.SignatureTab));
const ChefFeatureTab = dynamic<any>(() => import('../ChefFeatureTab/ChefFeatureTab'));

interface TabType {
  title: string;
  index: number;
  render: () => ReactNode;
}

export const OutletTab = (props: any) => {
  const [selectedTabIndex, setselectedTabIndex] = useState<number>(0);
  const { marsha, outletId } = useContext(PageParamsContext);
  const outletData = useStore((state: outletDetailsState) => state.outletData);
  const outletGalleryTabData = useStore((state: outletDetailsState) => state.outletGalleryTabData);
  const setGalleryTabData = useStore((state: outletDetailsState) => state.setGalleryTabData);
  const outletGalleryError = useStore((state: outletDetailsState) => state.outletGalleryError);
  const setGalleryError = useStore((state: outletDetailsState) => state.setGalleryError);
  const setMenuTabData = useStore((state: outletDetailsState) => state.setMenuTabData);
  const outletMenuSignatureData = useStore((state: outletDetailsState) => state.outletMenuSignatureData);
  const outletMenuSignatureError = useStore((state: outletDetailsState) => state.outletMenuSignatureError);
  const outletOffersData = useStore((state: outletDetailsState) => state.outletOffersData);
  const setOutletOffersData = useStore((state: outletDetailsState) => state.setOutletOffersData);
  const outletOffersError = useStore((state: outletDetailsState) => state.outletOffersError);
  const setOutletOfferError = useStore((state: outletDetailsState) => state.setOutletOfferError);
  const setOutletMenuSignatureError = useStore((state: outletDetailsState) => state.setOutletMenuSignatureError);
  const imageArr: string[] = [];

  const overviewTitle = props?.overviewLabel;
  const cheffeatureTitle = props?.chefFeatureLabel;
  const galleryTitle = props?.galleryLabel;
  const signatureTitle = props?.signatureDishesLabel;
  const menuTitle = props?.menuLabel;
  const offerTitle = props?.offersLabel;

  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();

  const pageContext = useContext(createContext<any>({}));
  const [menuDetailsErrorMessage, setMenuDetailsErrorMessage] = useState('');
  const [showMenuDetailsError, setShowMenuDetailsError] = useState(false);
  const scrollableSectionRef = useRef<HTMLUListElement | null>(null);

  const skipQuery = useMemo(() => {
    return props?.isAuthorMode || outletGalleryTabData || outletGalleryError;
  }, [props?.isAuthorMode, outletGalleryTabData, outletGalleryError]);

  const skipMenuQuery = useMemo(() => {
    return props?.isAuthorMode || outletMenuSignatureError || outletMenuSignatureData !== null;
  }, [props?.isAuthorMode, outletMenuSignatureError, outletMenuSignatureData]);

  const skipOffersQuery = useMemo(() => {
    return props?.isAuthorMode || outletOffersError || outletOffersData !== null;
  }, [props?.isAuthorMode, outletOffersError, outletOffersData]);

  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');

  const [tabList, setTabList] = useState<TabType[]>([
    { title: overviewTitle, index: 0, render: () => <OverviewTab {...props} /> },
  ]);

  const getMenuTab = (menuTabData: any): TabType | null => {
    const menuErrorDetails = (isMenuError: boolean, menuErrorMessage: string) => {
      setShowMenuDetailsError(isMenuError);
      const message = props?.menuErrorLabel?.replace(/\[.*?\]/, menuErrorMessage);
      setMenuDetailsErrorMessage(message);
    };

    if (menuTabData?.length > 0 || outletData?.basicInformation?.menuURL)
      return {
        title: menuTitle,
        index: 3,
        render: () => <MenuTab {...props} menuData={menuTabData} menuErrorDetails={menuErrorDetails} />,
      };
    return null;
  };

  const getSignatureTab = (signatureDishData: any): TabType | null => {
    if (signatureDishData?.length > 0) {
      return {
        title: signatureTitle,
        index: 4,
        render: () => <SignatureTab signatureData={signatureDishData} {...props} />,
      };
    }
    return null;
  };

  const getChefTab = (chefTabData: any): TabType | null => {
    if (chefTabData?.name && chefTabData?.description) {
      return {
        title: cheffeatureTitle,
        index: 5,
        render: () => <ChefFeatureTab chefData={chefTabData} {...props} />,
      };
    }
    return null;
  };

  useQuery(outletGalleryImages, {
    variables: {
      propertyId: marsha,
      restaurantId: Number(outletId),
      version: 'V2',
    },
    context: {
      headers: {
        // Any header, including x-request-id, can be passed in args with query.
        // If you don't pass it in the authLink will generate a random ID.
        'accept-language': 'en-US',
        'x-request-id': requestId,
        'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
        'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
        'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
      },
    },
    skip: skipQuery,
    onCompleted: data => {
      setGalleryTabData(data);
    },
    onError: () => {
      setGalleryError(true);
    },
  });

  useEffect(() => {
    if (outletGalleryTabData) {
      let filteredTabList = false;
      for (const item of tabList) {
        if (item?.title === galleryTitle) {
          filteredTabList = true;
          break; // Exit the loop as soon as the title is found
        }
      }
      if (!filteredTabList) {
        if (outletGalleryTabData && outletGalleryTabData?.restaurantById?.media?.imageConnection?.edges?.length > 0) {
          outletGalleryTabData?.restaurantById?.media?.imageConnection?.edges?.forEach((item: any) => {
            if (item?.node?.imageUrls && item?.node?.imageUrls?.classicHorizontal?.trim()) {
              const imageUrl = `${IMAGE_URL_DOMAIN}${item?.node?.imageUrls?.classicHorizontal}?downsize=400:*`;
              imageArr.push(imageUrl);
            }
          });
          setTabList((prevState: TabType[]) => [
            ...prevState,
            { title: galleryTitle, index: 2, render: () => <GalleryTab galleryData={imageArr} /> },
          ]);
        }
      }
    }
  }, [outletGalleryTabData]);

  useQuery(outletMenusSignatureDishAndChefImage, {
    variables: {
      propertyId: marsha,
      restaurantId: Number(outletId),
      architecturalServicesAssetPlacement: false,
      version: 'V2',
    },
    context: {
      headers: {
        // Any header, including x-request-id, can be passed in args with query.
        // If you don't pass it in the authLink will generate a random ID.
        'accept-language': 'en-US',
        'x-request-id': requestId,
        'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
        'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
        'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
      },
    },
    skip: skipMenuQuery,
    onCompleted: data => {
      if (data) {
        setMenuTabData(data);
      }
    },
    onError: data => {
      if (data) {
        setOutletMenuSignatureError(true);
      }
    },
  });

  useEffect(() => {
    if (outletMenuSignatureData?.restaurantById) {
      const menuTab = getMenuTab(outletMenuSignatureData?.restaurantById?.menus);
      const signTab = getSignatureTab(outletMenuSignatureData?.restaurantById?.signatureDish);
      const chefTab = getChefTab(outletMenuSignatureData?.restaurantById?.chef);

      setTabList((prevState: TabType[]) => {
        const newTablist = [...prevState];
        if (menuTab) newTablist.push(menuTab);
        if (signTab) newTablist.push(signTab);
        if (chefTab) newTablist.push(chefTab);
        return newTablist;
      });
    }
  }, [outletMenuSignatureData]);

  useQuery(offerSearchByOutlets, {
    variables: {
      input: {
        queries: [
          {
            id: 'outlets',
            values: outletId,
          },
        ],
      },
      ...(props?.sortTypes && {
        sort: {
          field: props?.sortTypes,
        },
      }),
    },
    context: {
      headers: {
        // Any header, including x-request-id, can be passed in args with query.
        // If you don't pass it in the authLink will generate a random ID.
        'accept-language': 'en-US',
        'x-request-id': requestId,
        'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
        'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
        'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
      },
    },
    skip: skipOffersQuery,
    onCompleted: data => {
      if (data) {
        setOutletOffersData(data);
      }
    },
    onError: data => {
      if (data) {
        setOutletOfferError(true);
      }
    },
  });

  useEffect(() => {
    if (outletOffersData) {
      if (outletOffersData?.offersSearch?.edges?.length > 0) {
        let filteredTabList = false;
        for (const item of tabList) {
          if (item?.title === offerTitle) {
            filteredTabList = true;
            break; // Exit the loop as soon as the title is found
          }
        }
        if (!filteredTabList) {
          setTabList((prevState: TabType[]) => [
            ...prevState,
            {
              title: offerTitle,
              index: 1,
              id: 'offertab',
              render: () => <OfferTab aemProps={props} offerData={outletOffersData?.offersSearch} />,
            },
          ]);
        }
      }
    }
  }, [outletOffersData]);

  useEffect(() => {
    scrollSection(scrollableSectionRef);
  }, [props]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (tabList?.length && query?.has(menuId)) {
      const menuIndex = tabList?.findIndex(tabItem => tabItem.title === menuTitle);
      if (menuIndex >= 0) setselectedTabIndex(menuIndex);
    }
  }, [window.location.search, tabList]);

  return (
    <StyledOutletTabContainer>
      <ul role="tablist" className="m-standard-tab-list p-0 mb-3 scrollable-section" ref={scrollableSectionRef}>
        {tabList?.map(
          (tab: TabType, index: number) =>
            tab && (
              <li
                role="tab"
                className={clsx(
                  'm-standard-tab-list-item ml-0 mr-4 mr-0 mb-0 pb-3',
                  index === selectedTabIndex ? 'active' : ''
                )}
                onClick={e => {
                  setselectedTabIndex(index);
                  e.currentTarget.focus();
                }}
                key={tab?.index}
                tabIndex={index === selectedTabIndex ? 0 : -1}
                data-testid={tab?.title}
              >
                <Button id={'tab_' + tab?.title} data-toggle="tab" aria-controls="" aria-selected="true">
                  <span className="item-heading t-subtitle-m">{tab?.title}</span>
                </Button>
              </li>
            )
        )}
      </ul>
      {showMenuDetailsError && (
        <div className={'m-message-inline error-sev2 col-12 rnb-error-message mt-3 mb-3'}>
          <div className="m-message-content-wrap">
            <div className="m-message-content">
              <span>{menuDetailsErrorMessage}</span>
            </div>
          </div>
        </div>
      )}
      {tabList[selectedTabIndex].render()}
    </StyledOutletTabContainer>
  );
};
