export const CardListModelMockJson = {
  titleText: 'More Offers At This Property',
  componentId: 'compId01',
  dataLayer: {
    'offers-cards-a6c47ec91b': {
      '@type': 'mi-aem-offers-spa/components/offers-cards',
      'repo:modifyDate': '2023-07-21T06:57:03Z',
    },
  },
  enableCarouselControls: 'false',
  ctaLabel: 'See Details',
  showless: 'Show Less',
  showMore: 'Show More',
  isAuthor: false,
  rightArrowDesc: 'test-right',
  cardLocation: 'test1',
  ctaType: 'secondaryButton',
  ':type': 'mi-aem-offers-spa/components/offers-cards',
  openInaNewTab: 'false',
  id: 'offers-cards-a6c47ec91b',
  enableScrollingBehavior: 'false',
  leftArrowDesc: 'test-left',
  chevronButton: 'false',
  clickTrack: 'true',
  wcmMode: 'disabled',
};

export const CardListApiMockJson = {
  marsha: 'MMHWI',
  locale: 'en-US',
  offerId: 'OFF-1234',
};
