/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { HtmlSitemapProps } from './HtmlSitemap.types';
import { HotelSitemap } from './HotelSitemap';
import { StyledHtmlSitemap } from './HtmlSitemap.styles';

export const HtmlSitemapWrapperConfig = {
  emptyLabel: 'HtmlSitemap',
  isEmpty: false,
  resourceType: `mi-aem-leisure-spa/components/htmlsitemap`,
};

export const HtmlSitemapWrapper: React.FC<HtmlSitemapProps> = props => {
  return <HotelSitemap {...props} />;
};

export const HtmlSitemap = (props: any) => (
  <EditableComponent config={HtmlSitemapWrapperConfig} {...props}>
    <StyledHtmlSitemap data-component-name="o-leisure-htmlsitemap">
      <HtmlSitemapWrapper {...props} />
    </StyledHtmlSitemap>
  </EditableComponent>
);
