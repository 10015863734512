import { Types } from '@marriott/mi-ui-library';
import { TRACKING_SEARCH_URL } from './constants';

const { INTERNAL, EXTERNAL } = Types.LinkTargetType;

type ClickTrackingProps = {
  fallbacks: {
    position: string;
    description: string;
  };
  url?: string | null;
  trackingProperties?: Types.TrackingPropsInterface;
};

export const getClickTrackValue = ({ fallbacks, url, trackingProperties }: ClickTrackingProps) => {
  let trackPosAndDesc = '';

  trackingProperties?.trackingContentPosition
    ? (trackPosAndDesc += `${trackingProperties?.trackingContentPosition}|`)
    : (trackPosAndDesc += `${fallbacks.position}|`);

  trackingProperties?.trackingDescription
    ? (trackPosAndDesc += `${trackingProperties?.trackingDescription}|`)
    : (trackPosAndDesc += `${fallbacks.description}|`);

  if (url) {
    trackPosAndDesc += `${url.includes(TRACKING_SEARCH_URL) ? INTERNAL : EXTERNAL}`;
  } else {
    trackPosAndDesc += INTERNAL;
  }

  return trackPosAndDesc;
};
