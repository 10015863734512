import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

interface SearchFormProps {
  isDropDownOpen: boolean;
  isHomePage: boolean;
  animationSpeed?: number;
}

export const SearchFieldContainer = styled.div<SearchFormProps>`
  width: 100%;

  @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    padding: ${props => (props.isDropDownOpen ? `${toRem(40)} !important` : `0 !important`)};
    padding-top: ${toRem(20)} !important;
    // width: 68%;
    padding-bottom: 0;
    border-radius: ${props => (props.isDropDownOpen ? `${toRem(20)} ${toRem(20)} 0 0` : 0)};
    box-shadow: ${props => (props.isDropDownOpen ? `0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.1)` : 'none')};
  }

  .MuiAutocomplete-noOptions {
    color: ${baseVariables.color.merch30};
    padding: ${toRem(24)} 0;

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      padding: ${toRem(32)} 0;
    }
  }

  .MuiAutocomplete-popperDisablePortal {
    border-radius: 0 0 ${toRem(20)} ${toRem(20)};
    background-color: ${baseVariables.color.merch50};
    box-shadow: none;
    width: 100% !important;
    margin: ${toRem(20)} 0 0 ${toRem(-56)};
    padding: 0;

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      border-top: none;
      box-shadow: 0 ${toRem(20)} ${toRem(20)} ${toRem(-4)} rgba(0, 0, 0, 0.1);
      margin-top: 0;
      // width: calc(68% - 32px) !important;
      margin-left: ${toRem(-40)};
      padding: 0;
    }
  }

  .MuiAutocomplete-paper {
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    padding: 0 ${toRem(24)};
    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      border-radius: 0 0 ${toRem(20)} ${toRem(20)};
      margin: 0;
      padding: 0 ${toRem(40)};
    }
  }

  .rnb-destination-label {
    margin-bottom: ${toRem(4)};
  }

  .rnb-label-dining-icon {
    margin-right: ${toRem(8)};
  }

  .rnb-destination-search-icon {
    margin: 0 ${toRem(12)} 0 0;
  }

  .rnb-dest-field-clear-btn {
    display: inline;
    padding: 0;
    background: none;
    width: ${toRem(13)};
    height: ${toRem(13)};
    font-size: ${toRem(13)};
    line-height: ${toRem(13)};

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      margin: ${toRem(4)} ${toRem(4)} 0 ${toRem(4)};
    }
  }

  .MuiAutocomplete-listbox {
    max-height: none;
    padding: ${toRem(12)} 0 ${toRem(16)} 0;
  }

  .MuiAutocomplete-listbox > li {
    .MuiAutocomplete-groupLabel {
      &:empty {
        display: none;
      }
    }
  }

  .MuiAutocomplete-groupUl .MuiAutocomplete-option {
    padding: 0;
  }

  .MuiAutocomplete-groupLabel {
    font-size: ${toRem(12)};
    font-family: 'Swiss-721';
    line-height: ${toRem(16)};
    color: ${baseVariables.color['dove-grey']};
    padding: ${toRem(4)} 0 ${toRem(8)} 0;
    text-transform: uppercase;

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      padding: ${toRem(20)} 0 ${toRem(12)} 0;
      border-top: 1px solid ${baseVariables.color.neutral20};
    }
  }

  .rnb-current-location-icon {
    margin-right: ${toRem(18)};

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      margin-right: ${toRem(16)};
    }
  }

  .rnb-destination-option {
    margin: ${toRem(12)} 0;
    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      margin: ${toRem(8)} 0;
    }
  }

  .MuiAutocomplete-inputRoot::placeholder,
  .MuiInputBase-input::placeholder {
    color: ${props =>
      props.isDropDownOpen
        ? `${baseVariables.color.merch50} !important`
        : `${baseVariables.color.neutral00} !important`};
  }

  .MuiAutocomplete-inputRoot,
  .MuiInputBase-input {
    color: ${props =>
      props.isDropDownOpen
        ? `${baseVariables.color.merch50} !important`
        : `${baseVariables.color.neutral00} !important`};
    padding: ${props => (props.isDropDownOpen ? `0 ${toRem(12)} 0 0` : 'none')};
    caret-color: #ff8d6b;

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      color: ${baseVariables.color.neutral00} !important;
    }
  }

  // below media query will target only on safari
  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      .MuiAutocomplete-popper {
        background-color: ${baseVariables.color.merch50};
      }
    }
  }

  .animate {
    animation-name: swipePlaceholders;
    animation-duration: ${props => (props.animationSpeed ? `${props.animationSpeed}s` : '4s')};
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .rnb-dest-search-input {
    overflow: hidden;
  }

  @keyframes swipePlaceholders {
    49% {
      transform: translateY(-100%);
    }
    50% {
      opacity: 0;
      transform: translateY(100%);
    }
    60% {
      opacity: 1;
    }
  }
`;
