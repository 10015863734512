/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useContext, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  StyledHeroBannerCarousel,
  GlobalStyle,
  ShowMiniCards,
  StyledOverlayContainer,
  StyledCityPageHeroBanner,
  StyledCampaignHeroBanner,
} from './RnBHeroBanner.styles';
import { Image, Text, Icon, Types, Heading } from '@marriott/mi-ui-library';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { PageParamsContext } from '../../modules/context/PageContext';
import { outletDetailsState, useStore } from '../../modules/store/outletDetailsStore';
import OutletDetailsMock from './__mocks__/OutletDetailsMock.json';
import { rnbErrorState, rnbErrorStore } from '../../modules/store/rnbErrorStore';
import { RnBHeroImageCarousel } from '../RnBHeroImageCarousel';
import clsx from 'clsx';
import { mockHeroBannerImage } from './__mocks__/mockModel';
import { useGetBreakpoint } from '../../modules/utils/helpers';
import { campaignDetailsStore } from '../../modules/store/campaignDetailsStore';
import { IMAGE_URL_DOMAIN } from '../../modules/utils/constants';
import campaignOffersDetailsMock from '../../organisms/CampaignOffersCardContainer/__mocks__/CampaignOfferDetailsMock.json';

const { tags, size } = Types;
const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const RnBHeroBanner = (props: any) => {
  const setErrorState = rnbErrorStore((state: rnbErrorState) => state.setErrorState);
  const {
    fallbackDescription,
    fallbackHeroImgPath,
    outletDataResponse,
    heroBannerImages,
    campaignId,
    campaignData,
    campaignDataError,
  } = useContext(PageParamsContext);
  const outletDataError = useStore((state: outletDetailsState) => state.outletDataError);
  const setData = useStore((state: outletDetailsState) => state.setData);
  const outletData = useStore((state: outletDetailsState) => state.outletData);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const setCampaignId = campaignDetailsStore(state => state.setCampaignId);
  const setCampaignData = campaignDetailsStore(state => state.setCampaignData);
  const setCampaignDataError = campaignDetailsStore(state => state.setCampaignDataError);
  const setCampaignOffersList = campaignDetailsStore(state => state.setCampaignOffersList);
  const isCampaignUXLdata: boolean =
    props?.pageType === 'campaignPage' &&
    campaignData?.heroBanner?.imageUrls?.square &&
    campaignData?.heroBanner?.imageUrls?.panoramicHorizontal;

  useEffect(() => {
    if (props?.isAuthorMode) {
      setData(
        OutletDetailsMock?.data?.restaurantById,
        false,
        false,
        { fallbackDescription, fallbackHeroImgPath },
        mockHeroBannerImage
      );
    } else if (!outletDataResponse) {
      setData(null, false, true, {}, []);
    } else if (outletDataResponse && !outletData) {
      setData(outletDataResponse, false, false, { fallbackDescription, fallbackHeroImgPath }, heroBannerImages);
    }
  }, [outletDataResponse, props?.isAuthorMode, setData, fallbackDescription, fallbackHeroImgPath]);

  useEffect(() => {
    if (outletDataError) {
      setErrorState(0, 'apiError');
    }
  }, [outletDataError, setErrorState]);

  useEffect(() => {
    if (props?.isAuthorMode) {
      setCampaignData(campaignOffersDetailsMock?.data?.diningOfferCampaignDetails);
      setCampaignDataError(false);
      setCampaignOffersList(campaignOffersDetailsMock?.data?.diningOfferCampaignDetails?.OfferDetail, false, false);
    } else {
      setCampaignId(campaignId);
      setCampaignData(campaignData);
      setCampaignDataError(campaignDataError || false);
    }
  }, [campaignId, campaignData, campaignDataError, props?.isAuthorMode]);

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (props?.pageType === 'cityPage') {
    return (
      <StyledCityPageHeroBanner>
        <Image
          title=""
          defaultImageURL={props?.dynamicMedia?.assetPath}
          customClass=""
          loading="eager"
          altText={props?.dynamicMedia?.altText}
          renditions={props?.dynamicMedia?.renditions}
          dynamic={props?.dynamic}
        />
        {props?.overlayText && (
          <div data-testid="overlayTextTest" className="bl_iconText-wrapper">
            <div className={`t-label-inverse-xs`}>
              <Icon iconClass={props?.iconPath} ariaLabel="aria-label" />
              <Text
                fontSize={size.extraSmall}
                copyText={props?.overlayText}
                element={tags.span}
                customClass="px-1"
              ></Text>
            </div>
          </div>
        )}
      </StyledCityPageHeroBanner>
    );
  } else if (props?.pageType === 'campaignPage' || props?.pageType === 'earnRedeemPage') {
    return (
      <StyledCampaignHeroBanner styleClass={props?.styleclass} className={props?.styleclass}>
        <div className="bl-item">
          <Image
            title=""
            defaultImageURL={
              isCampaignUXLdata
                ? isMobileViewPort
                  ? `${IMAGE_URL_DOMAIN}${campaignData?.heroBanner?.imageUrls?.square}`
                  : `${IMAGE_URL_DOMAIN}${campaignData?.heroBanner?.imageUrls?.panoramicHorizontal}`
                : props?.dynamicMedia?.assetPath
            }
            loading="eager"
            altText={
              props?.pageType === 'campaignPage' && campaignData?.heroBanner?.alternateDescription
                ? campaignData?.heroBanner?.alternateDescription
                : props?.dynamicMedia?.altText
            }
            renditions={isCampaignUXLdata ? null : props?.dynamicMedia?.renditions}
            dynamic={isCampaignUXLdata ? null : props?.dynamic}
          />
        </div>
        {props?.pageType === 'earnRedeemPage' && props?.overlayText && (
          <div data-testid="overlayTextTest" className="bl_iconText-wrapper">
            <div className={`t-label-inverse-xs`}>
              <Icon iconClass={props?.iconPath} ariaLabel="aria-label" />
              <Text
                fontSize={size.extraSmall}
                copyText={props?.pageType === 'earnRedeemPage' ? props?.overlayText : ''}
                element={tags.span}
                customClass="px-1"
              ></Text>
            </div>
          </div>
        )}
        {(campaignData?.title || props?.header) && (
          <div className={`bl__cnt-sec gradient`}>
            <div className="container-sm bl__cnt-ctr px-3 px-md-0">
              <div className="row m-0">
                <div className="p-sm-0 p-md-0 p-lg-0 p-xl-0 col-12 col-md-6 col-lg-5 col-xl-5">
                  {(campaignData?.title || props?.header) && (
                    <Heading
                      titleText={
                        props?.pageType === 'earnRedeemPage'
                          ? props?.header
                          : campaignData?.title
                          ? campaignData?.title
                          : ''
                      }
                      variation={Types.headingType.title}
                      fontSize={Types.size.medium}
                      customClass={clsx('mb-2 t-title-m text-left')}
                    />
                  )}
                  {(campaignData?.description || props?.description) && (
                    <Text
                      fontSize={isMobileViewPort ? size.small : size.medium}
                      copyText={
                        props?.pageType === 'earnRedeemPage'
                          ? props?.description
                          : campaignData?.description
                          ? campaignData?.description
                          : ''
                      }
                      element={tags.span}
                      customClass={clsx(isMobileViewPort ? 't-font-s' : 't-font-m')}
                    ></Text>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </StyledCampaignHeroBanner>
    );
  } else {
    return outletDataError === true ? null : outletData &&
      outletDataError === false &&
      (props?.heroBannerCarouselDisabled || outletData?.heroBannerImages?.length === 1) ? (
      <StyledHeroBannerCarousel className="outlet-hero-banner">
        <GlobalStyle />
        <ShowMiniCards />
        <StyledOverlayContainer iconPath={!!props?.iconPath}>
          <Image
            title=""
            defaultImageURL={
              outletData?.heroBannerImages[0]?.isFallBackImage
                ? outletData?.heroBannerImages[0]?.dynamicMedia?.assetPath
                : outletData?.heroBannerImages[0]?.defaultImageUrl
            }
            customClass="outlet-hero-banner--img"
            loading="eager"
            altText={
              outletData?.heroBannerImages[0]?.isFallBackImage
                ? outletData?.heroBannerImages[0]?.dynamicMedia?.altText
                : 'Slide-1'
            }
            renditions={
              outletData?.heroBannerImages[0]?.isFallBackImage
                ? outletData?.heroBannerImages[0]?.dynamicMedia?.renditions
                : null
            }
            dynamic={outletData?.heroBannerImages[0]?.isFallBackImage ? true : false}
          />
          {outletData?.heroBannerImages[0]?.isFallBackImage && (
            <div className="overlay-content d-flex justify-content-center align-items-center flex-column">
              {!!props.iconPath && (
                <span className={clsx('icon-inverse mb-4 d-inline-flex', `${props.iconPath}`)}></span>
              )}
              {!!props.overlayText && <div className="t-subtitle-inverse-l">{props?.overlayText}</div>}
            </div>
          )}
        </StyledOverlayContainer>
      </StyledHeroBannerCarousel>
    ) : outletData &&
      outletDataError === false &&
      (!props?.heroBannerCarouselDisabled || outletData?.heroBannerImages?.length > 1) ? (
      <StyledHeroBannerCarousel className="outlet-hero-banner">
        <GlobalStyle />
        <ShowMiniCards />
        <RnBHeroImageCarousel images={outletData?.heroBannerImages} imgCustomClass="outlet-hero-banner--img" />
      </StyledHeroBannerCarousel>
    ) : (
      <div className="spinner-wrapper d-flex justify-content-center align-items-center vh-100">
        <GlobalStyle />
        <div className="m-spinner-m"></div>
      </div>
    );
  }
};

export const RnBHeroBannerConfig = {
  emptyLabel: 'OutletHeroBanner',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/herobannercarousel`,
};

export const RnBHeroBannerEditable = (props: any) => (
  <div data-component-name="m-rnb-RnBHeroBanner" data-testid="rnb-RnBHeroBanner">
    <EditableComponent config={RnBHeroBannerConfig} {...props}>
      <RnBHeroBanner {...props} />
    </EditableComponent>
  </div>
);
