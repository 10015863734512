import { StyledStepSection } from './StepSection.styles';
import { Heading, Types, Text, Icon } from '@marriott/mi-ui-library';
import { StepSectionProps } from './StepSection.types';

export const StepSection = (props: StepSectionProps) => {
  return (
    <StyledStepSection className="d-flex flex-column text-left">
      <Icon iconClass={`${props?.iconClass} step-section__icon`} ariaLabel={props?.iconClass} />

      <Heading
        variation={Types.headingType.subtitle}
        titleText={props?.title}
        fontSize={Types.size.medium}
        element={Types.tags.span}
        customClass="mb-1"
      />
      <Text
        copyText={props?.description}
        fontSize={Types.size.small}
        element={Types.tags.span}
        customClass="t-font-alt-s"
      />
    </StyledStepSection>
  );
};

export default StepSection;
