import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

export const StyledAccordion = styled.div<{
  disableAccInTabletAndDesktop: boolean;
  disableAccInDesktop: boolean;
  enableAccordionSeparator: boolean;
  enableFullWidthHeader: boolean;
}>`
  .accordion {
    height: 100%;
    &__container {
      height: 100%;
      ${props =>
        props.enableAccordionSeparator
          ? `border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral30']};`
          : 'border-bottom: 0;'}
      ${props =>
        props.enableAccordionSeparator
          ? props.disableAccInTabletAndDesktop
            ? `
@media ${baseVariables.mediaQuery.md} {
border-right: ${toRem(1)} solid ${baseVariables.color['neutral30']};
border-bottom: 0;
}
`
            : props.disableAccInDesktop
            ? `@media ${baseVariables.mediaQuery.lg} {
            border-right: ${toRem(1)} solid ${baseVariables.color['neutral30']};
            border-bottom: 0;
          }`
            : ''
          : 'border-right: 0;'}
      @media ${baseVariables.mediaQuery.md} {
        padding-right: ${toRem(32)};
        padding-left: ${toRem(16)};
      }
      .accordion__list {
        overflow: hidden;
        margin-top: ${toRem(9)};
        padding-bottom: ${toRem(16)};
        transition: all 0.4s ease;
        @media ${baseVariables.mediaQuery.md} {
          transition: none;
          padding-bottom: ${toRem(16)};
        }
        &.collapsed {
          max-height: 0;
          padding: 0;
        }
        &.hide-visibility {
          visibility: hidden;
          margin-top: 0;
        }
      }
      .accordion__title {
        margin-top: -${toRem(2)};
        ${props => (props.enableFullWidthHeader ? 'width: 100%;' : '')}
      }
      .accordion__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: none;
        width: 100%;
        padding: 0;
        color: inherit;
        &:focus-visible {
          outline: -webkit-focus-ring-color auto ${toRem(1)};
        }
        .icon-arrow-down,
        .icon-arrow-up {
          margin-left: auto;
          margin-top: 0;
          &::before {
            font-size: ${toRem(12)};
          }
        }
        .accordion__title {
          margin: ${toRem(16)} ${toRem(0)} ${toRem(16)} ${toRem(-1)};
        }
      }
      .accordion_tab--disabled .accordion__list {
        &.collapsed {
          @media ${baseVariables.mediaQuery.md} {
            max-height: unset;
          }
          &.hide-visibility {
            @media ${baseVariables.mediaQuery.md} {
              visibility: visible;
            }
          }
        }
      }
      .accordion_des--disabled .accordion__list {
        &.collapsed {
          @media ${baseVariables.mediaQuery.lg} {
            max-height: unset;
          }
          &.hide-visibility {
            @media ${baseVariables.mediaQuery.lg} {
              visibility: visible;
            }
          }
        }
      }
      &.accordion_des--disabled {
        padding: 0;
        @media ${baseVariables.mediaQuery.lg} {
          padding-right: ${toRem(86)};
          padding-left: ${toRem(32)};
        }
        @media ${baseVariables.mediaQuery.xl} {
          padding-right: ${toRem(56)};
          padding-left: ${toRem(40)};
        }
      }
    }
  }
`;
