/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useRef, useEffect, useContext, createContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Button, Eyebrow, Heading, Types, Image } from '@marriott/mi-ui-library';
import Cookies from 'js-cookie';
import { offersByList, offersBasedOnLatLong, offersByCountry } from '@marriott/mi-rnb-graphql';
import Glide from '@glidejs/glide';
import { PageParamsContext } from '../../modules/context';
import { OffersDynamicProps } from './OffersDynamic.types';
import { StyledOffersDynamic } from './OffersDynamic.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useLazyQuery } from '@apollo/client';
import { isServer } from '@marriott/mi-apollo-client-utils';
import offersListMock from './__mocks__/mockModel.json';
import { useGetBreakpoint } from '../../modules/utils';

import {
  OFFER_QUERY_FACET_TYPE,
  OFFER_QUERY_FACET_DIMENSION,
  OFFER_QUERY_TYPE,
  PAGES,
} from '../../modules/utils/constants/constants';
import { TRACKING_CONSTANT } from '../../../src/modules/utils/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;
export const OffersDynamic: React.FC<OffersDynamicProps> = props => {
  const [offersList, setOffersList] = useState<any>([]);
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const pageContext = useContext(createContext<any>({}));
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const [isTabletViewPort, setIsTabletViewPort] = useState(useGetBreakpoint() === 'tablet');
  const glideRef = useRef<HTMLDivElement | any>(null);

  let index: number;
  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');
  const [cardsPerView, setCardsPerView] = useState(isMobileViewPort ? 1.25 : isTabletViewPort ? 2 : 3);
  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();
  const { currentPage } = useContext(PageParamsContext);
  const { offerSearchTypes } = props;
  const { CITY_OFFERS_COMPONENT, CITY_OFFERS_RNB, EXTERNAL_LINK } = TRACKING_CONSTANT;

  let query = offersBasedOnLatLong;
  if (!props?.isAuthorMode) {
    if (offerSearchTypes === 'countrycode') {
      query = offersByCountry;
    } else if (offerSearchTypes === 'offerlist') {
      query = offersByList;
    } else if (offerSearchTypes === 'latlong') {
      query = offersBasedOnLatLong;
    } else {
      query = offersBasedOnLatLong;
    }
  }

  const [loadOffersData, { data }] = useLazyQuery(query);

  useEffect(() => {
    if (!props?.isAuthorMode) {
      const countryVariable = {
        input: {
          queries: [{ id: 'country', values: [props?.country] }],
          offertype: OFFER_QUERY_TYPE,
          facets: {
            terms: [
              {
                type: OFFER_QUERY_FACET_TYPE,
                dimensions: OFFER_QUERY_FACET_DIMENSION,
              },
            ],
          },
        },
        ...(props?.sortTypes && {
          sort: {
            field: props?.sortTypes,
          },
        }),
      };

      const offerList = props?.offerList?.map((ids: { offers: string | number }) => ids?.offers);
      const offersVariable = {
        input: {
          queries: { id: 'offers', values: offerList },
        },
        ...(props?.sortTypes && {
          sort: {
            field: props?.sortTypes,
          },
        }),
      };

      const latLongVariable = {
        input: {
          queries: [
            {
              id: 'latitude',
              values: props?.latitude,
            },
            {
              id: 'longitude',
              values: props?.longitude,
            },
          ],
          facets: {
            terms: [
              {
                type: OFFER_QUERY_FACET_TYPE,
                dimensions: OFFER_QUERY_FACET_DIMENSION,
              },
            ],
          },
          offertype: OFFER_QUERY_TYPE,
        },
        ...(props?.sortTypes && {
          sort: {
            field: props?.sortTypes,
          },
        }),
      };

      let queryVariable;
      if (offerSearchTypes === 'countrycode') {
        queryVariable = countryVariable;
      } else if (offerSearchTypes === 'offerlist') {
        queryVariable = offersVariable;
      } else if (offerSearchTypes === 'latlong') {
        queryVariable = latLongVariable;
      }
      loadOffersData({
        fetchPolicy: isServer ? 'network-only' : 'cache-first',
        errorPolicy: 'all',
        variables: queryVariable,
        context: {
          headers: {
            // Any header, including x-request-id, can be passed in args with query.
            // If you don't pass it in the authLink will generate a random ID.
            'accept-language': 'en-US',
            'x-request-id': requestId,
            'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });
    }
  }, [props?.offerSearchTypes, props?.isAuthorMode]);

  useEffect(() => {
    if (props?.offerSearchTypes === 'countrycode') {
      const resultsOffersCountryList = data?.offersSearch?.edges?.map((offers: any) => offers).slice(0, 9);
      setOffersList(resultsOffersCountryList);
    } else if (props?.offerSearchTypes === 'offerlist' || props?.offerSearchTypes === 'latlong') {
      const resultsOffersList = data?.offersSearch?.edges?.map((offers: any) => offers).slice(0, 9);
      setOffersList(resultsOffersList);
    }
  }, [data]);

  useEffect(() => {
    function getCurrentBreakpoint() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsTabletViewPort(useGetBreakpoint() === 'tablet');
    }
    window.addEventListener('resize', getCurrentBreakpoint);
    return () => window.removeEventListener('resize', getCurrentBreakpoint);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    const xs = window.matchMedia('(max-width: 575px)');
    const sm = window.matchMedia('(min-width:576px) and (max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    const lg = window.matchMedia('(min-width: 992px) and (max-width: 1199px)');
    const xl = window.matchMedia('(min-width: 1200px)');
    if (xs?.matches) {
      setCardsPerView(1.25);
    } else if (sm?.matches) {
      setCardsPerView(1.25);
    } else if (md?.matches) {
      setCardsPerView(2);
    } else if (lg?.matches) {
      setCardsPerView(3);
    } else if (xl?.matches) {
      setCardsPerView(3);
    }
  };

  useEffect(() => {
    if (props?.isAuthorMode) {
      const results: any = offersListMock?.data?.offersSearch?.edges?.map((offers: any) => offers).slice(0, 9);
      setOffersList(results);
    }
  }, [props?.isAuthorMode]);

  const componentId = 'glideDynamic';

  useEffect(() => {
    const glide = new Glide(glideRef?.current, {
      type: 'slider',
      bound: true,
      perView: cardsPerView,
      startAt: 0,
      rewind: false,
      gap: 16,
      dragThreshold: false,
      breakpoints: {
        1200: {
          perView: 3,
          peek: {
            before: 0,
            after: 0,
          },
        },
        992: {
          perView: 2,
          peek: {
            before: 0,
            after: 0,
          },
        },
        768: {
          perView: 1.25,
          peek: {
            before: 0,
            after: 0,
          },
        },
      },
      keyboard: false,
    });
    const glideOnRun = () => {
      index = glideRef.current
        ?.querySelector('.glide__slides .glide__slide--active ')
        ?.getAttribute('data-glide-index');
      if (Number(index) === 0) {
        setIsFirstSlide(true);
        setIsLastSlide(false);
      } else if (Number(index) === offersList?.length - cardsPerView) {
        setIsFirstSlide(false);
        setIsLastSlide(true);
      } else if (Number(index) === offersList?.length - 1) {
        setIsFirstSlide(false);
        setIsLastSlide(true);
      } else {
        setIsFirstSlide(false);
        setIsLastSlide(false);
      }
    };
    glide?.on(['mount.after', 'run', 'run.after'], glideOnRun);
    if (offersList?.['length'] >= 2) {
      glide?.mount();
    }
  }, [offersList, isMobileViewPort, isTabletViewPort, cardsPerView]);

  return offersList?.length > 0 ? (
    <StyledOffersDynamic
      data-component-name="m-rnb-OffersDynamic"
      data-testid="rnb-OffersDynamic"
      currentPage={currentPage}
      className="pt-4 pt-lg-4"
    >
      <div className="container">
        <div className="carousal_header pb-4">{props?.title && <Eyebrow text={props?.title} />}</div>
        <div id={componentId} className={clsx('glide')} ref={glideRef}>
          <div className={clsx('glide__track')} data-glide-el="track">
            <ul
              className={clsx('glide__slides d-flex list-container pl-0 list-unstyled pb-3')}
              id={`${componentId}__slides`}
            >
              {offersList?.map((offer: any, index: number) => {
                return (
                  <li className="container p-0 m-0" key={offer?.node?.id} data-glide-index={index}>
                    <div
                      className={clsx(
                        isMobileViewPort
                          ? 'col-12 p-0 offers-container'
                          : `${
                              offersList.length === 1
                                ? 'col-4 p-0 offers-container'
                                : offersList.length === 2
                                ? 'col-11 p-0 offers-container'
                                : 'col-12 p-0 offers-container'
                            }`
                      )}
                    >
                      <div className="card-vertical d-flex flex-column standard" data-testid="card-vertical">
                        <Image
                          title={offer?.node?.title}
                          dynamic={false}
                          defaultImageURL={
                            offer?.node?.photos?.images[0]?.url
                              ? `https://cache.marriott.com${offer?.node?.photos?.images[0]?.url}`
                              : `${props?.dynamicMedia?.assetPath}`
                          }
                          loading="lazy"
                          altText={offer?.node?.title}
                        />
                        <div className={`card-vertical__body pt-4 pr-5 pb-5 pl-5 overlay align-items-center`}>
                          <div
                            className={`d-flex flex-column align-items-center justify-content-between card-vertical__content`}
                          >
                            <div className="card-align">
                              {offer?.node?.associatedOutlets[0]?.property?.contactInformation?.address?.country
                                ?.description && (
                                <Eyebrow
                                  text={
                                    offer?.node?.associatedOutlets[0]?.property?.contactInformation?.address?.country
                                      ?.description
                                  }
                                />
                              )}
                              {offer?.node?.title && (
                                <Heading
                                  variation={Types.headingType.subtitle}
                                  fontSize={Types.size.extraLarge}
                                  titleText={offer?.node?.title}
                                  customClass={'t-subtitle-xl heading m-0 bonvoy-wheels '}
                                />
                              )}
                              {offer?.node?.offerTag && (
                                <div className={clsx('t-label-s pt-2 pr-3 pb-2 pl-3', 'sep-lines ')}>
                                  {offer?.node?.offerTag}
                                </div>
                              )}
                              <div className="pb-4 pt-3">
                                {offer?.node?.descriptionTeaser && (
                                  <div
                                    dangerouslySetInnerHTML={{ __html: offer?.node?.descriptionTeaser }}
                                    className={clsx('description', 't-font-s')}
                                  ></div>
                                )}
                              </div>
                            </div>
                            <Button
                              href={offer?.node?.url}
                              isLink={true}
                              className="m-button-s m-button-secondary"
                              target={`${props?.openInNewTab ? '_blank' : '_self'}`}
                              rel={`${props?.openInNewTab ? 'noopener noreferrer' : ''}`}
                              trackingProperties={props?.trackingProperties}
                              custom_click_track_value={`${CITY_OFFERS_COMPONENT}|${CITY_OFFERS_RNB}|${EXTERNAL_LINK}`}
                            >
                              {props?.ctaText}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h1 style={{color: 'white'}}>Hi</h1> */}
                  </li>
                );
              })}
              {offersList.length === 2 && !isMobileViewPort && (
                <li className="container p-0" key="offer-dynamic-content">
                  <div></div>
                </li>
              )}
            </ul>
          </div>

          <div className={clsx('card-align')}>
            <div className={clsx('carouselControlType1 mx-auto', currentPage === PAGES.HOMEPAGE ? 'inverse' : '')}>
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className={`glide__arrow glide__arrow--left left-arrow ${
                    isFirstSlide ? 'glide__arrow--disabled' : ''
                  }`}
                  data-glide-dir="<"
                  data-content="Previous"
                  disabled={isFirstSlide}
                  aria-label="Previous"
                >
                  <span className="icon-arrow-left" id={`${componentId}_left_arrow`} data-id="left"></span>
                </button>
              </div>
              {offersList.length > (isMobileViewPort ? 1 : isTabletViewPort ? 2 : 3) && (
                <div data-glide-el="controls[nav]">
                  {Array.from({ length: offersList?.length - (cardsPerView - 1) }, (_, i) => (
                    <button className="" data-glide-dir={i} key={cardsPerView + i} aria-label={`Slide ${i}`}></button>
                  ))}
                </div>
              )}
              {offersList.length > (isMobileViewPort ? 1 : isTabletViewPort ? 2 : 3) && (
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={`glide__arrow glide__arrow--right right-arrow ${
                      isLastSlide ? 'glide__arrow--disabled' : ''
                    }`}
                    data-glide-dir=">"
                    data-content="Next"
                    disabled={isLastSlide}
                    aria-label="Next"
                  >
                    <span className="icon-arrow-right" data-id="right"></span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledOffersDynamic>
  ) : null;
};

export const OffersDynamicConfig = {
  emptyLabel: 'dynamicoffers',
  isEmpty: true,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/dynamicoffers`,
};

export const OffersDynamicEditable: FC<any> = props => (
  <EditableComponent config={OffersDynamicConfig} {...props}>
    <OffersDynamic {...props} />
  </EditableComponent>
);
