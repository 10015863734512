import { FC, useEffect } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { rnbErrorState, rnbErrorStore } from 'libs/mi-rnb-components/src/modules/store/rnbErrorStore';
import { StyledErrorMessage, GlobalErrorStyle } from './RnBErrorMessage.styles';
import { RnBErrorMessageProps } from './index';
import { Heading, Text, Types } from '@marriott/mi-ui-library';
import { useRouter } from 'next/router';
import clsx from 'clsx';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

// Use this component to conditionally render the alert message
// Setting the state.errorMessage to ' ', will hide the component
// Setting the state.errorMessage to valid value [anything apart from ''], will show the component

export const RnBErrorMessage: FC<RnBErrorMessageProps> = (props: RnBErrorMessageProps) => {
  const errorMessage = rnbErrorStore((state: rnbErrorState) => state.errorMessage);
  const setGenericErrorState = rnbErrorStore((state: rnbErrorState) => state.setGenericErrorState);
  const router = useRouter();
  let is404error = false;

  if (router?.pathname === '/dining/404') {
    is404error = true;
  }

  useEffect(() => {
    const errorObj = {
      locationErrorTitle: props?.locationErrorTitle,
      locationErrorDescription: props?.locationErrorDescription,
    };
    setGenericErrorState(errorObj);
  }, [props?.locationErrorTitle, props?.locationErrorDescription, setGenericErrorState]);

  return (
    <>
      <StyledErrorMessage
        data-component-name="m-rnb-RnBErrorMessage"
        data-testid="rnb-RnBErrorMessage"
        isEmpty={!errorMessage}
        className={clsx(
          'container rnb-error-container p-0 rnb-additional-container',
          errorMessage ? 'error-message-active' : ''
        )}
      >
        <div className={'m-message-inline error-sev2 col-12 rnb-error-message mt-3 mb-3'}>
          <div className="m-message-content-wrap">
            <div className="m-message-content">
              <span>
                {props[errorMessage as keyof typeof props] ? props[errorMessage as keyof typeof props] : errorMessage}
              </span>
            </div>
          </div>
        </div>
      </StyledErrorMessage>
      {is404error && <GlobalErrorStyle />}
      <div className={is404error ? 'error-404-container container mt-3 mt-md-5 mt-lg-5 mt-xl-5' : 'd-none'}>
        <Heading
          customClass="t-numbers-s pt-2 mb-0 error-heading mb-3"
          titleText={props?.errorTitle}
          variation={Types.headingType.title}
          fontSize={Types.size.small}
          element={Types.tags.h2}
        />
        <Text
          customClass="t-font-s pt-1"
          copyText={props?.errorDesc}
          fontSize={Types.size.small}
          element={Types.tags.div}
        />
      </div>
    </>
  );
};

export const RnBErrorMessageConfig = {
  emptyLabel: 'RnBErrorMessage',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/rnberror`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RnBErrorMessageEditable = (props: any) => (
  <EditableComponent config={RnBErrorMessageConfig} {...props}>
    <RnBErrorMessage {...props} />
  </EditableComponent>
);
