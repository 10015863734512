/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
import { createAppStore } from './zustand';
import { NEXT_DATA } from 'next/dist/shared/lib/utils';
import { canUseDOM, AuthStateEnum } from '@marriott/shared/mi-helper-utils';

interface globalState {
  sessionData: Record<string, any>;
  userProfileData: any;
  isSignedInUser: boolean;
  isRememberedUser: boolean;
}
const { AUTHENTICATED, REMEMBERED, DATALAYER_AUTHENTICATION_CHECK_VALUE } = AuthStateEnum;
declare global {
  interface Window {
    __NEXT_DATA__: NEXT_DATA;
    dataLayer?: Record<string, unknown>;
  }
}

const initialState: globalState = {
  sessionData: {},
  userProfileData: {},
  isSignedInUser: false,
  isRememberedUser: false,
};

export const globalStore: StateCreator<globalState> = () => {
  if (typeof window !== 'undefined') {
    // hydration
    initialState.sessionData = window?.__NEXT_DATA__?.props?.['pageProps']?.sessionData;
    initialState.isSignedInUser = !!(canUseDOM
      ? window?.dataLayer?.[DATALAYER_AUTHENTICATION_CHECK_VALUE] === AUTHENTICATED
      : false);
    initialState.isRememberedUser = !!(canUseDOM
      ? window?.dataLayer?.[DATALAYER_AUTHENTICATION_CHECK_VALUE] === REMEMBERED
      : false);
  }
  return {
    ...initialState,
  };
};

export const useGlobalStore = createAppStore(globalStore, {
  usePersistentStore: false,
});
