export const SearchFacetsList = {
  brands: 'BRANDS',
  amenities: 'AMENITIES',
  propertyType: 'PROPERTY_TYPES',
  activities: 'ACTIVITIES',
  hotelType: 'HOTEL_SERVICE_TYPES',
  events: 'MEETINGS_EVENTS',
  transportation: 'TRANSPORTATION_TYPES',
  leisureRegion: 'LEISURE_REGIONS',
  city: 'CITIES',
  country: 'COUNTRIES',
  state: 'STATES',
};

export const SEARCH_FILTER_MODAL = 'Search Filter Panel Modal';

export const amenityCodeToIconMap: Record<string, string> = {
  'EV Charging': 'ev-charging-station',
  'Whirlpool in Room': 'pool',
  'Whirlpool on Site': 'pool',
  'new-lobby': 'check',
};

export const HDQueryParamsToCheck: string[] = ['activities-on-site', 'property-types', 'amenities'];
