// Imports for external libraries go here.
import { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import Glide from '@glidejs/glide';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Image } from '@marriott/mi-ui-library';
import { RnBHeroImageCarouselProps, ImageProps } from './RnBHeroImageCarousel.types';
import { StyledRnBHeroImageCarousel } from './RnBHeroImageCarousel.styles';

// Use named rather than default exports.
export const RnBHeroImageCarousel: FC<RnBHeroImageCarouselProps> = ({
  images = [],
  imgCustomClass = '',
  index = 0,
  hideCarouselPips = false,
}) => {
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const glideRef = useRef<HTMLDivElement | any>(null);

  useEffect(() => {
    if (images?.length > 1) {
      const glide = new Glide(glideRef?.current, {
        type: 'slider',
        gap: 0,
        startAt: index,
        focusAt: 'center',
        autoplay: false,
        bound: false,
        rewind: false,
        dragThreshold: false,
        perView: 1,
        peek: {
          before: 0,
          after: 0,
        },
        keyboard: false,
      });

      const glideOnRun = () => {
        const index = glideRef?.current?.querySelector('.glide__slide--active')?.getAttribute('data-glide-index');

        if (Number(index) === 0) {
          setIsFirstSlide(true);
          setIsLastSlide(false);
        } else if (Number(index) === images?.length - 1) {
          setIsFirstSlide(false);
          setIsLastSlide(true);
        } else {
          setIsFirstSlide(false);
          setIsLastSlide(false);
        }
      };

      glide.on('run.after', glideOnRun);

      glide.mount();
    }
  }, [images]);

  return (
    <StyledRnBHeroImageCarousel
      data-component-name="m-rnb-RnBHeroImageCarousel"
      data-testid="rnb-RnBHeroImageCarousel"
      className="hero-img-carousel__container"
    >
      <div className="glide" ref={glideRef}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides d-flex p-0 m-0">
            {images?.map((img: ImageProps, index: number) => (
              <li className="glide__slide" data-glide-index={index} key={index}>
                <Image
                  title=""
                  defaultImageURL={`${img.defaultImageUrl}`}
                  altText={`Slide ${index + 1}`}
                  customClass={imgCustomClass}
                  loading={index === 0 || index === 1 || index === images.length - 1 ? 'eager' : 'lazy'}
                />
              </li>
            ))}
          </ul>

          <div className="glide__controls_wrapper d-flex align-items-center p-0 pr-2 pl-2">
            <div className="glide__arrows" data-glide-el="controls">
              <button
                className={clsx('glide__arrow glide__arrow--left p-0', isFirstSlide ? 'glide__arrow--disabled' : '')}
                data-glide-dir="<"
                aria-label="Previous"
              >
                <span className="icon-arrow-left"></span>
              </button>
            </div>
            {(hideCarouselPips && images?.length <= 10) || !hideCarouselPips ? (
              <div data-glide-el="controls[nav]" className="glide-bullet-container d-flex align-items-center">
                {images?.map((_, index: number) => (
                  <button
                    className="glide__bullet p-0"
                    aria-label={`glide__bullet-${index}`}
                    data-glide-dir={`=${index}`}
                    key={index}
                  ></button>
                ))}
              </div>
            ) : null}
            <div className="glide__arrows" data-glide-el="controls">
              <button
                className={clsx('glide__arrow glide__arrow--right p-0', isLastSlide ? 'glide__arrow--disabled' : '')}
                data-glide-dir=">"
                aria-label="Next"
              >
                <span className="icon-arrow-right"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </StyledRnBHeroImageCarousel>
  );
};
