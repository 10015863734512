export const MBOPOfferDetailsMock = {
  data: {
    offer: {
      id: 'OFF-38264',
      title: 'PARLIAMONE A PRANZO',
      description:
        'Elevate your midday break at Giano Restaurant with our Business Lunch formulas, designed to fit seamlessly into your schedule without compromising on taste.<br><br>Choose between <strong>two different options</strong>:<br><br><strong>FORMULA #1 - 22 €</strong><br><ul><li>1 Starter</li><li>1 Pasta Dish</li><li>Water</li><li>Coffee</li></ul><br><strong>FORMULA #2 - 35 €</strong><br><ul><li>1 Starter</li><li>1 Main Course</li><li>Water</li><li>Coffee</li></ul><br>From Monday to Friday at Giano Restaurant, squeeze taste into your schedule.<br><br>',
      bookingStartDate: '2024-01-23',
      bookingEndDate: '2024-06-30',
      associatedOutlets: [
        {
          outlets: [
            {
              cuisines: ['American'],
              name: 'Viet Kitchen',
              reservationURL: 'https://www.marriott.com',
              id: '6756582',
            },
            {
              cuisines: ['American'],
              name: 'Viet Kitchen',
              reservationURL: 'https://www.marriott.com',
              id: '675658233',
            },
          ],
          property: {
            basicInformation: {
              displayName: null,
            },
            id: 'ROMWV',
            contactInformation: {
              contactNumbers: [
                {
                  phoneNumber: {
                    countryCode: '+39',
                    original: '+3906894121',
                    international: '+39 06 894121',
                    national: '06 894121',
                    display: '+39 06 894121',
                    areaCityCode: null,
                    extension: null,
                    number: null,
                  },
                },
              ],
            },
          },
        },
      ],
      media: {
        primaryImage: {
          imageUrls: {
            panoramicHorizontal: '',
            classicHorizontal: '',
          },
          alternateDescription: 'alt text',
          title: 'Sample Images',
        },
      },
      termsAndConditions:
        'Offer must fall within valid dates and is only valid through the stay window end date. Limited number of rooms are available for this promotion. Tax is additional. Offer does not apply to groups of 10 or more rooms. Offer cannot be combined with any other promotions. Blackout dates may apply. Advance reservations required. Other restrictions apply. Rates are per room, per night and based on availability at the time of reservations.',
      needToKnow: ['Aruba Aloe products room amenity', 'Yoga mat delivered to room', 'A massage for two'],
    },
  },
};

export const CHUBOfferDetailsMock = {
  data: {
    diningOfferbyId: {
      offerId: '3130',
      name: 'Candlelit Romantic Night',
      description: 'Candlelit Romantic Night',
      needToKnow: ['Aruba Aloe products room amenity', 'Yoga mat delivered to room', 'A massage for two'],
      termsAndConditions:
        'Offer must fall within valid dates and is only valid through the stay window end date. Limited number of rooms are available for this promotion. Tax is additional. Offer does not apply to groups of 10 or more rooms. Offer cannot be combined with any other promotions. Blackout dates may apply. Advance reservations required. Other restrictions apply. Rates are per room, per night and based on availability at the time of reservations.',
      image: {
        alternateDescription: 'skyline',
        title: 'ievds-attraction-skyline-5334',
        imageUrls: {
          classicHorizontal: '/content/dam/marriott-renditions/IEVDS/ievds-attraction-skyline-5334-hor-clsc.jpg',
          panoramicHorizontal: '/content/dam/marriott-renditions/IEVDS/ievds-attraction-skyline-5334-hor-pano.jpg',
          __typename: 'ImageRendition',
        },
        __typename: 'OfferImage',
      },
      schedule: {
        expiryDate: '2024-09-25',
        recurrence: 'none',
        startDate: '2024-07-09',
        endDate: '2024-09-25',
        __typename: 'DiningOfferSchedule',
      },
      participatingOutlets: [
        {
          property: {
            id: 'BERZT',
            __typename: 'Hotel',
            basicInformation: {
              displayName: null,
              __typename: 'HotelBasicInformation',
              latitude: 52.510052,
              longitude: 13.375555,
            },
            contactInformation: {
              contactNumbers: [
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: '+49',
                    extension: null,
                    display: '+49 30 337775300',
                    international: '+49 30 337775300',
                    national: '030 337775300',
                    number: null,
                    original: '+4930337775300',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: '+49',
                    extension: null,
                    display: '+49 30 337777',
                    international: '+49 30 337777',
                    national: '030 337777',
                    number: null,
                    original: '+4930337777',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: '+49',
                    extension: null,
                    display: '+49 30 337772100',
                    international: '+49 30 337772100',
                    national: '030 337772100',
                    number: null,
                    original: '+4930337772100',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: null,
                    extension: null,
                    display: '+4930337777; ext= 2200',
                    international: null,
                    national: null,
                    number: null,
                    original: '+4930337777; ext= 2200',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: '+49',
                    extension: null,
                    display: '+49 30 337775555',
                    international: '+49 30 337775555',
                    national: '030 337775555',
                    number: null,
                    original: '+4930337775555',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
              ],
              __typename: 'PropertyContactInformation',
            },
          },
          restaurantDetails: [
            {
              cuisine: 'German',
              restaurant: {
                id: 'BERZT.6557748',
                primaryType: null,
                basicInformation: {
                  name: 'POTS',
                  primaryType: 'Restaurant',
                  reservationURL: 'https://www.sevenrooms.com/landing/potsberlin',
                  __typename: 'RestaurantBasicInformation',
                },
                diningExperience: {
                  cuisines: ['German'],
                  __typename: 'RestaurantDiningExperience',
                },
                __typename: 'Restaurant',
              },
              __typename: 'RestaurantDetail',
            },
          ],
          __typename: 'DiningParticipatingOutlets',
        },
      ],
      __typename: 'DiningOffer',
    },
  },
};
