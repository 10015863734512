export const ImageRenditionsData = {
  offersHorizontalMini: {
    aspectRatio: { dynamicMedia: ['square'] },
    dynamicMedia: {
      altText: 'Dominican Republic Destination Image',
      assetPath: 'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472',
      enableSmartCrop: true,
      dynamic: true,
      renditions: [
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Square?wid=100&hei=100, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Square?wid=200&hei=200 2x',
          mediaValue: '992px',
          width: 100,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'max-width',
          height: 100,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Square?wid=100&hei=100, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Square?wid=200&hei=200 2x',
          mediaValue: '576px',
          width: 100,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'min-width',
          height: 100,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Square?wid=100&hei=100, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Square?wid=200&hei=200 2x',
          mediaValue: '576px',
          width: 100,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'max-width',
          height: 100,
        },
      ],
      damPath:
        '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
    },
  },
  offersBannerLayered: {
    aspectRatio: {
      dynamicMedia: ['featureHorizontal', 'wideVertical'],
      dynamicMediaMobile: ['wideVertical'],
    },
    dynamicMedia: {
      altText: 'Skyline view',
      assetPath: 'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941',
      enableSmartCrop: true,
      dynamic: true,
      renditions: [
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Feature-Hor?wid=2880&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Feature-Hor?wid=5760&fit=constrain 2x',
          mediaValue: '992px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Feature-Hor?wid=992&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Feature-Hor?wid=1984&fit=constrain 2x',
          mediaValue: '768px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Feature-Hor?wid=768&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Feature-Hor?wid=1536&fit=constrain 2x',
          mediaValue: '576px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Wide-Ver?wid=576&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-31-155587055957941:Wide-Ver?wid=1152&fit=constrain 2x',
          mediaValue: '576px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
          mediaQuery: 'max-width',
          height: 0,
        },
      ],
      damPath:
        '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-31-155587055957941.jpg',
    },
    dynamicMediaMobile: {
      renditions: [
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-19-553122070513536:Wide-Ver?wid=576&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-19-553122070513536:Wide-Ver?wid=1152&fit=constrain 2x',
          mediaValue: '576px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-19-553122070513536.jpg',
          mediaQuery: 'max-width',
          height: 0,
        },
      ],
    },
  },
  offersCardLayered: {
    aspectRatio: {
      dynamicMedia: ['square'],
      dynamicMediaWide: ['wideHorizontal'],
      dynamicMediaTall: ['classicVertical'],
    },
    dynamicMedia: {
      altText: 'Dominican Republic Destination Image',
      assetPath: 'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472',
      enableSmartCrop: true,
      dynamic: true,
      renditions: [
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746:Square?wid=365&fit=constrain',
          mediaValue: '992px',
          width: 0,
          dynamic: true,
          damPath:
            '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746:Square?wid=344&fit=constrain',
          mediaValue: '576px',
          width: 0,
          dynamic: true,
          damPath:
            '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7stage/bonvoy-1096489-gettyimage-16746:Square?wid=332&fit=constrain',
          mediaValue: '576px',
          width: 0,
          dynamic: true,
          damPath:
            '/content/dam/marriott-digital/bonvoy/global/en_us/photo/unlimited/assets/bonvoy-1096489-gettyimage-16746.jpg',
          mediaQuery: 'max-width',
          height: 0,
        },
      ],
      damPath:
        '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
    },
    dynamicMediaWide: {
      renditions: [
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=570&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=1140&fit=constrain 2x',
          mediaValue: '1200px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=480&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=960&fit=constrain 2x',
          mediaValue: '768px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=575&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=1150&fit=constrain 2x',
          mediaValue: '767px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'max-width',
          height: 0,
        },
      ],
    },
    dynamicMediaTall: {
      renditions: [
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Classic-Ver?wid=380&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Classic-Ver?wid=760&fit=constrain 2x',
          mediaValue: '1200px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Classic-Ver?wid=480&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Classic-Ver?wid=960&fit=constrain 2x',
          mediaValue: '768px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Classic-Ver?wid=575&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Classic-Ver?wid=1150&fit=constrain 2x',
          mediaValue: '767px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'max-width',
          height: 0,
        },
      ],
    },
  },
  offersHeroBanner: {
    aspectRatio: {
      backgroundMedia: ['wideHorizontal', 'classicVertical', 'square'],
    },
    backgroundMedia: {
      renditions: [
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=1440&hei=810, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=2880&hei=1620 2x',
          mediaValue: '992px',
          width: 1440,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'min-width',
          height: 810,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=768&hei=432, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Wide-Hor?wid=1536&hei=864 2x',
          mediaValue: '768px',
          width: 768,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'min-width',
          height: 432,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Square?wid=365&hei=563, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472:Classic-Ver?wid=730&hei=1126 2x',
          mediaValue: '767.5px',
          width: 365,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
          mediaQuery: 'max-width',
          height: 563,
        },
      ],
    },
  },
  offersHeroV2: {
    aspectRatio: {
      dynamicMedia: ['wideHorizontal', 'classicVertical'],
      dynamicMediaLogo: ['classicHorizontal'],
    },
    dynamicMedia: {
      renditions: [
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammo353562720852746-168262627028617:Pano-Hor?wid=1920&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammo353562720852746-168262627028617:Wide-Hor?wid=3840&fit=constrain 2x',
          mediaValue: '992px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo353562720852746-168262627028617.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammo353562720852746-168262627028617:Pano-Hor?wid=992&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammo353562720852746-168262627028617:Wide-Hor?wid=1984&fit=constrain 2x',
          mediaValue: '768px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo353562720852746-168262627028617.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammo353562720852746-168262627028617:Pano-Hor?wid=768&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammo353562720852746-168262627028617:Wide-Hor?wid=1536&fit=constrain 2x',
          mediaValue: '576px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo353562720852746-168262627028617.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammo353562720852746-168262627028617:Square?wid=576&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammo353562720852746-168262627028617:Classic-Ver?wid=1152&fit=constrain 2x',
          mediaValue: '576px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammo353562720852746-168262627028617.jpg',
          mediaQuery: 'max-width',
          height: 0,
        },
      ],
    },
    dynamicMediaLogo: {
      renditions: [
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-671156180530346:Classic-Hor?wid=200&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-671156180530346:Classic-Hor?wid=400&fit=constrain 2x',
          mediaValue: '992px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-671156180530346.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-671156180530346:Classic-Hor?wid=150&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-671156180530346:Classic-Hor?wid=300&fit=constrain 2x',
          mediaValue: '576px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-671156180530346.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-671156180530346:Classic-Hor?wid=150&fit=constrain, https://s7d1.scene7.com/is/image/marriotts7prod/pdt-JordHammond-HongKong-671156180530346:Classic-Hor?wid=300&fit=constrain 2x',
          mediaValue: '576px',
          width: 0,
          dynamic: true,
          smartCropType: null,
          damPath:
            '/content/dam/marriott-digital/continents/greater-china/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-JordHammond-HongKong-671156180530346.jpg',
          mediaQuery: 'max-width',
          height: 0,
        },
      ],
    },
  },
  offersCobrandCarHorizontal: {
    aspectRatio: {
      dynamicMedia: ['wideHorizontal'],
    },
    dynamicMedia: {
      altText: 'Dominican Republic Destination Image',
      assetPath: 'https://s7d1.scene7.com/is/image/marriotts7prod/pdt-Dominican-Republic-453698474706472',
      enableSmartCrop: true,
      dynamic: true,
      renditions: [],
      damPath:
        '/content/dam/marriott-digital/continents/cala/en_us/destinations/photo/2023-photoshoot/unlimited/assets/pdt-Dominican-Republic-453698474706472.jpg',
    },
  },
};
