// Styles for LanguageSelector go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledLanguageSelector = styled.div`
  .modal-popup-container {
    @media ${baseVariables.mediaQuery.lg} {
      position: absolute;
    }
    @media ${baseVariables.mediaQuery.sm} {
      position: fixed;
      height: 100vh;
      margin: 20px auto;
    }
  }

  .modal-main-container {
    position: relative;
    @media ${baseVariables.mediaQuery.sm} {
      overflow-y: auto;
      max-height: 80vh;
    }
    @media ${baseVariables.mediaQuery.lg} {
      overflow-y: unset;
      max-height: none;
    }
  }

  .modal-header {
    height: ${toRem(32)};
    h4 {
      color: ${baseVariables.color['base10']};
    }
    .popup-close {
      max-height: ${toRem(32)};
      max-width: ${toRem(32)};
    }
  }

  .custom-header-font h2 {
    color: ${baseVariables.color['base10']};
  }

  .custom-link a {
    color: ${baseVariables.color['base10']};
  }
`;
