// Imports for external libraries go here.
import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OffersCardLayeredProps } from './OffersCardLayered.types';
import { CardLayered } from '@marriott/mi-ui-library';
import { StyledOffersCardLayered } from './OffersCardLayered.styles';
import { mockOffersCardLayeredData } from './__mock__/OffersCardLayered.mock';
import { decodeHtml, getMediaData, getMediaSource } from '../../utils/OfferUtils';
import { PRIMARY_MEDIA } from '../../constants/CommonConstants';
import { ImageRenditionsData } from '../../utils/ImageRenditionStructure';

export const OffersCardLayeredWrapperConfig = {
  emptyLabel: 'OffersCardLayered',
  isEmpty: false,
  resourceType: `mi-aem-common-spa/components/content/CardLayered`,
};

export const OffersCardLayeredWrapper: React.FC<OffersCardLayeredProps> = props => {
  const { model, offersData, isAuthorMode } = props;
  const { offersCardLayered } = ImageRenditionsData;
  const offersDataNode = !isAuthorMode
    ? offersData?.responseObject?.edges[0]
    : mockOffersCardLayeredData.offersData?.responseObject?.edges[0];

  const mediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        PRIMARY_MEDIA,
        offersCardLayered?.aspectRatio?.dynamicMedia
      );
  const isMediaFromDAC = mediaSource === PRIMARY_MEDIA;
  const media = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersCardLayered?.dynamicMedia,
        mediaSource,
        PRIMARY_MEDIA
      );

  const tallMediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        PRIMARY_MEDIA,
        offersCardLayered?.aspectRatio?.dynamicMediaTall
      );
  const tallMedia = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersCardLayered?.dynamicMediaTall,
        tallMediaSource,
        PRIMARY_MEDIA
      );
  const isTallMediaFromDAC = tallMediaSource === PRIMARY_MEDIA;

  const wideMediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        PRIMARY_MEDIA,
        offersCardLayered?.aspectRatio?.dynamicMediaWide
      );
  const wideMedia = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersCardLayered?.dynamicMediaWide,
        wideMediaSource,
        PRIMARY_MEDIA
      );
  const isWideMediaFromDAC = wideMediaSource === PRIMARY_MEDIA;

  return (
    <StyledOffersCardLayered>
      <CardLayered
        cardLayeredVariations={model?.cardLayeredVariations}
        header={offersDataNode?.node?.title ? decodeHtml(offersDataNode?.node?.title) : model?.header}
        headerTag={model?.headerTag}
        badgeText={model.badgeText}
        eyebrow={
          offersDataNode?.node?.descriptionTeaser ? decodeHtml(offersDataNode?.node?.descriptionTeaser) : model?.eyebrow
        }
        ctaLink={offersDataNode?.node?.callToActionLink ?? model.ctaLink}
        badgeIcon={model?.badgeIcon}
        icon={model?.icon}
        dynamicMedia={isMediaFromDAC ? media : model?.dynamicMedia}
        dynamicMediaTall={isTallMediaFromDAC ? tallMedia : model?.dynamicMediaTall}
        dynamicMediaWide={isWideMediaFromDAC ? wideMedia : model?.dynamicMediaWide}
        openInNewTab={model.openInNewTab}
        trackingProperties={model?.trackingProperties}
        fontSize={model.fontSize}
        styleclass={model?.styleclass}
        logoIcon={model?.logoIcon}
        logoIconAltText={model?.logoIconAltText}
      />
    </StyledOffersCardLayered>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersCardLayered = (props: any) => (
  <div data-testid="offers-card-layered" data-component-name="o-offers-cardlayered">
    <EditableComponent config={OffersCardLayeredWrapperConfig} {...props}>
      <OffersCardLayeredWrapper {...props} />
    </EditableComponent>
  </div>
);
