/* eslint-disable @typescript-eslint/no-var-requires */
export const suggestedPlaces = require('./lib/suggestedPlaces.graphql');
export const suggestedPlacesDetails = require('./lib/suggestedPlacesDetails.graphql');
export const searchRestaurantsByGeolocation = require('./lib/searchRestaurantsByGeolocation.graphql');
export const searchRestaurantsByLocation = require('./lib/searchRestaurantsByLocation.graphql');
export const searchRestaurantsByOpenText = require('./lib/searchRestaurantsByOpenText.graphql');
export const catalogRestaurantById = require('./lib/catalogRestaurantById.graphql');
export const similarRestaurants = require('./lib/similarRestaurants.graphql');
export const outletGalleryImages = require('./lib/outletGalleryImages.graphql');
export const outletMenusSignatureDishAndChefImage = require('./lib/outletMenusSignatureDishAndChefImage.graphql');
export const outletMenuDetail = require('./lib/outletMenuDetail.graphql');
export const outletsByRestaurantIds = require('./lib/outletsByRestaurantIds.graphql');
export const offersBasedOnLatLong = require('./lib/offersBasedOnLatLong.graphql');
export const offersByList = require('./lib/offersByList.graphql');
export const offersByCountry = require('./lib/offersByCountry.graphql');
export const offerSearchByOutlets = require('./lib/offerSearchByOutlets.graphql');
export const lookupMenuDiets = require('./lib/lookupMenuDiets.graphql');
export const lookupMenuAllergens = require('./lib/lookupMenuAllergens.graphql');
export const searchRestaurantsByGeolocationFacets = require('./lib/searchRestaurantsByGeolocationFacets.graphql');
export const searchRestaurantsByLocationFacets = require('./lib/searchRestaurantsByLocationFacets.graphql');
export const searchRestaurantsByOpenTextFacets = require('./lib/searchRestaurantsByOpenTextFacets.graphql');
export const lookupsByType = require('./lib/lookupsByType.graphql');
export const campaignBannerAndOverviewDetails = require('./lib/campaignBannerAndOverviewDetails.graphql');
export const campaignMBOPOfferDetails = require('./lib/campaignMBOPOfferDetails.graphql');
export const campaignCHUBOfferDetails = require('./lib/campaignCHUBOfferDetails.graphql');
export const campaignOffersDetailsFacets = require('./lib/campaignOffersDetailsFacets.graphql');
export const campaignOffersListDetails = require('./lib/campaignOffersListDetails.graphql');
