import moment, { Moment } from 'moment';
type DateObjectType = Moment;

const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const getDateFormatted = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? 'MM/DD/YYYY') ?? '';
};

export const getMPOFormattedDate = (): { currMPODate: string; nextMPODate: string } => {
  const dateObj = {
    currMPODate: getDateFormatted(moment(new Date()), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
    nextMPODate: getDateFormatted(moment(new Date()).add(1, 'days'), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
  };
  return dateObj;
};
export const getFormattedFromDate = (dateFlag: boolean): { currDate: string; nextDate: string } => {
  const dateObj = {
    currDate: getDateFormatted(moment(new Date()), dateFlag ? 'DD/MM/YYYY' : 'MM/DD/YYYY')
      .toString()
      .split('-')
      .join('/'),
    nextDate: getDateFormatted(moment(new Date()).add(1, 'days'), dateFlag ? 'DD/MM/YYYY' : 'MM/DD/YYYY')
      .toString()
      .split('-')
      .join('/'),
  };
  return dateObj;
};
export const getMPOFormattedDateNew = (date: string): { currMPODate: string } => {
  if (moment(date) < moment(new Date())) {
    const dateObj = {
      currMPODate: getDateFormatted(moment(new Date()), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
    };
    return dateObj;
  } else {
    const dateObj = {
      currMPODate: getDateFormatted(moment(new Date(date)), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
    };
    return dateObj;
  }
};
export const addDaysToDate = (currentDate: string, daysToAdd: number): string => {
  return getDateFormatted(moment(currentDate).add(daysToAdd, 'days'), 'YYYY-MM-DD');
};
