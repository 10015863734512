import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledSkeletonLoaderContainer = styled.div`
  ${rtl`
    @media ${theme.mediaQuery.largeMobile} {
      padding-right: 0;
      padding-left: 0;
    }
  `}
`;

export const StyledSkeletonLoaderDiv = styled.div`
  ${rtl`
    border-radius: ${toRem(14)};
    height: ${toRem(285)};
    margin: ${toRem(10)} 0;
    font-family: ${theme.fonts['fontFamilySwiss']};
    display: flex;
    box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
    background-color: ${theme.color['white']};
      @media ${theme.mediaQuery.allMobiles} {
        flex-flow:wrap;
         height: auto;
      }
    .left-side-loader {
      width:45%;
      @media ${theme.mediaQuery.allMobiles} {
        width:100%;
        min-height:${toRem(200)}
      }
      .image-place-loader {
        border-top-left-radius:${toRem(10)};
        border-bottom-left-radius:${toRem(10)};
         @media ${theme.mediaQuery.allMobiles} {
            border-radius:${toRem(10)};
            border-top-left-radius:${toRem(10)};
            border-top-right-radius:${toRem(10)};
            border-bottom-right-radius:${toRem(0)};
            border-bottom-left-radius:${toRem(0)};
          }
      }
    }
    .skeleton-loader-composite {
      background-color: ${theme.color['light-gray-1']};
      color: white;
      -webkit-animation: fadein 2s ease-in alternate infinite;
      -moz-animation: fadein 2s ease-in alternate infinite;
      animation: fadein 2s ease-in alternate infinite;
    }
    @-webkit-keyframes fadein {
      10%   { opacity: 0.3; }
      25%   { opacity: 0.35; }
      50%   { opacity: 0.4; }
      75%   { opacity: 0.5; }
      100% { opacity: 0.6; }
    }
    @-moz-keyframes fadein {
      10%   { opacity: 0.3; }
      25%   { opacity: 0.35; }
      50%   { opacity: 0.4; }
      75%   { opacity: 0.5; }
      100% { opacity: 0.6; }
    }
    @keyframes fadein {
      10%   { opacity: 0.3; }
      25%   { opacity: 0.35; }
      50%   { opacity: 0.4; }
      75%   { opacity: 0.5; }
      100% { opacity: 0.6; }
    }
    .right-side-loader {
      padding-left: ${toRem(15)};
      padding-top: ${toRem(20)};
      padding-bottom: ${toRem(20)};
      width:55%;
      @media ${theme.mediaQuery.allMobiles} {
        width:100%;
        padding-right: ${toRem(15)};
      }
        .top-right-side-loader {
          width:90%;
          @media ${theme.mediaQuery.allMobiles} {
            width:100%;
          }
        }
        .bottom-rate-place-loader-container {
          width:90%;
          display: flex;
          justify-content: flex-end;
          .rate-place-loader {
            border-radius: ${toRem(22)};
          }
           @media ${theme.mediaQuery.allMobiles} {
            width:100%;
            .rate-place-loader {
              width:40%
            }
          }
        }
      }
    }
  `}
`;

export const StyledSkeletonMapViewLoaderDiv = styled.div`
  ${rtl`
    z-index: 3;
    position: fixed;
    top:0;
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.6);

    .map-view-loader{
      padding: ${toRem(10)};
      border-radius: ${toRem(12)};
      display: flex;
      justify-content: center;
      align-items: center;
    }
`}
`;
