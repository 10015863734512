/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-webpack-loader-syntax
import contextualVars from '!!scss-extract-loader?{"plugins": ["scss-extract-js"]}!../../../mi-ui-global-styles/src/styles/base/variables/_contextualExport.scss';
import { baseVals } from './baseVariables';

function deleteDuplicateValues(baseVars: any, contextual: any) {
  for (const key in contextual) {
    if (typeof contextual[key] === 'object' && typeof baseVars[key] === 'object') {
      deleteDuplicateValues(baseVars[key], contextual[key]);
      // eslint-disable-next-line no-prototype-builtins
    } else if (baseVars.hasOwnProperty(key) && baseVars[key] === contextual[key]) {
      delete contextual[key];
    }
  }
  return contextual;
}

export const contextualVariable = deleteDuplicateValues(baseVals, contextualVars);

Object.keys(contextualVariable).forEach(function (key) {
  key.indexOf('icon') === 0 && delete contextualVariable[key];
  key.indexOf('spacer') === 0 && delete contextualVariable[key];
});
