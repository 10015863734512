export default {
  playServicePath: process.env.NODE_ENV === 'development' ? 'https://ram-dev5.ose-dev39-vxbyr.cloud.marriott.com' : '',

  autoSuggestionLocationDetailsAPI: '/aries-search/v2/autoComplete.comp',
  searchFormDataAPI: '/aries-search/v2/searchForm.comp',
  offerSearchFormDefaultActionURL: '/offers/submitOfferSearch.mi',
  searchFormDefaultActionURL: '/search/submitSearch.mi',
  searchFormGroupActionURL: '/meetings/search-hotels.mi?isTransient=true',

  //componentResolverPath: 'http://localhost:9090',
  componentResolverPath:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:9090'
      : process.env['UXL_RESOLVER_ENDPOINT'] ??
        'https://phoenix-searchtest1.ose-dev39-red.aws-use1.cloud.marriott.com/mi-presentation-shop/',
  graphqlPlyServiceURl: 'https://gatewaydsaptst2.marriott.com/v1/graph/query',
  miServicePAth:
    'http://mi-datastore-app-phoenix-searchdev1.ose-dev39-red.aws-use1.cloud.marriott.com/data/ARIES_SESSION/',
  //this is to specify allowed domains for domain specific features like mandatory fee
  ALLOWED_DOMAINS: ['en-US'],
};
