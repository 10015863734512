import { OffersCobrandCardHorizontalFlexibleProps } from '../OffersCobrandCardHorizontalFlexible.types';

export const mockOffersCobrandCardHorizontalFlexibleData: OffersCobrandCardHorizontalFlexibleProps = {
  offersData: {
    responseObject: {
      edges: [
        {
          node: {
            description: 'This is the Description field',
            descriptionTeaser: 'This is Eyebrow field',
            title: 'Header field',
            callToActionLink: 'https://www.marriott.com',
            callToActionLabel: 'CTA One',
            descriptionTextMobile: 'This is the Description field mob',
          },
        },
      ],
    },
  },

  model: {
    ctaLink: '#',
    ctaLinkText: 'ctaLinkText',
    header: 'header',
  },

  isAuthorMode: true,
};
