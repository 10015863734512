/* eslint-disable no-extra-boolean-cast */
import { StyledOffersOverlayContent } from './OffersOverlayContent.styles';
import { Heading, Button, Image, Icon, Types, Text, size, tags, RichText } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
//import { OffersOverlayProps } from './OffersOverlayContent.types';
import {
  TRACKING_CONSTANT,
  GOOGLE_LOCATION,
  TARGET_BLANK,
  offerType,
  IMAGE_URL_DOMAIN,
} from '../../../modules/utils/constants';
import { OfferDetailsType } from './OffersOverlayContent.types';
const { CAMPAIGN_PAGE, EXTERNAL_LINK, CAMPAIGN_PAGE_OVERLAY_LOCATION_BUTTON, CAMPAIGN_PAGE_OVERLAY_PHONE_BUTTON } =
  TRACKING_CONSTANT;

// TODO - Replace any with correct props after UXL is up
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersOverlayContent = (props: any) => {
  const [showTermsSection, setShowTermsSection] = useState(false);
  const [offerDetails, setOfferDetails] = useState<OfferDetailsType>({});
  const isMobileViewPort = props?.isMobileViewPort;

  useEffect(() => {
    generateModalData();
  }, [props?.offerType]);

  const toggleShowTermsSection = () => {
    setShowTermsSection(!showTermsSection);
  };

  const getDate = (date: string) => {
    const generatedDate = new Date(date);
    return date
      ? generatedDate.getDate() + ' ' + generatedDate.toString().substring(4, 7) + ' ' + generatedDate.getFullYear()
      : '';
  };

  const getValidityInfo = (startDate: string, endDate: string) => {
    const offerValidity = props?.offerValidityMessageLabel;
    return startDate && endDate ? offerValidity.replace(/\{.*?\}/, startDate).replace(/\{.*?\}/, endDate) : '';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getContactInfo = (contactInfo: any) => {
    return !!contactInfo
      ? contactInfo?.contactNumbers[0]?.phoneNumber?.display &&
        !contactInfo?.contactNumbers[0]?.phoneNumber?.display.includes(undefined)
        ? contactInfo?.contactNumbers[0]?.phoneNumber?.display
        : ''
      : '';
  };

  const generateModalData = () => {
    if (props?.offerType === offerType?.MbopOffer) {
      const updatedOfferDetails = {
        imageUrl: isMobileViewPort
          ? !!props?.offerDetails?.media?.primaryImage?.imageUrls?.classicHorizontal
            ? `${IMAGE_URL_DOMAIN}${props?.offerDetails?.media?.primaryImage?.imageUrls?.classicHorizontal}` +
              '?&downsize=*:186px'
            : ''
          : !!props?.offerDetails?.media?.primaryImage?.imageUrls?.panoramicHorizontal
          ? `${IMAGE_URL_DOMAIN}${props?.offerDetails?.media?.primaryImage?.imageUrls?.panoramicHorizontal}` +
            '?&downsize=*:295px'
          : '',
        imageAltText: props?.offerDetails?.media?.primaryImage?.alternateDescription,
        imageTitle: props?.offerDetails?.media?.primaryImage?.title,
        validityInfo: getValidityInfo(
          getDate(props?.offerDetails?.bookingStartDate),
          getDate(props?.offerDetails?.bookingEndDate)
        ),
        offerTitle: props?.offerDetails?.title,
        offerDescription: props?.offerDetails?.description,
        propertyName:
          props?.offerDetails?.associatedOutlets?.length > 0
            ? props?.offerDetails?.associatedOutlets[0]?.property?.basicInformation?.displayName
            : '',
        propertyLatitude:
          props?.offerDetails?.associatedOutlets?.length > 0
            ? props?.offerDetails?.associatedOutlets[0]?.property?.basicInformation?.latitude
            : '',
        propertyLongitude:
          props?.offerDetails?.associatedOutlets?.length > 0
            ? props?.offerDetails?.associatedOutlets[0]?.property?.basicInformation?.longitude
            : '',
        contactNumber: getContactInfo(
          props?.offerDetails?.associatedOutlets?.length > 0
            ? props?.offerDetails?.associatedOutlets[0]?.property?.contactInformation
            : ''
        ),
        needToKnowInfo: props?.offerDetails?.needToKnow,
        terms: props?.offerDetails?.termsAndConditions,
      };
      setOfferDetails(updatedOfferDetails);
    } else if (props?.offerType === offerType?.CHUBOffer) {
      const updatedOfferDetails = {
        imageUrl: isMobileViewPort
          ? !!props?.offerDetails?.image?.imageUrls?.classicHorizontal
            ? `${IMAGE_URL_DOMAIN}${props?.offerDetails?.image?.imageUrls?.classicHorizontal}?&downsize=*:186px`
            : ''
          : !!props?.offerDetails?.image?.imageUrls?.panoramicHorizontal
          ? `${IMAGE_URL_DOMAIN}${props?.offerDetails?.image?.imageUrls?.panoramicHorizontal}?&downsize=*:295px`
          : '',
        imageAltText: props?.offerDetails?.image?.alternateDescription,
        imageTitle: props?.offerDetails?.image?.title,
        validityInfo: getValidityInfo(
          getDate(props?.offerDetails?.schedule?.startDate),
          getDate(props?.offerDetails?.schedule?.endDate)
        ),
        offerTitle: props?.offerDetails?.name,
        offerDescription: props?.offerDetails?.description,
        propertyName: props?.offerDetails?.participatingOutlets[0]?.property?.basicInformation?.name || '',
        propertyLatitude: props?.offerDetails?.participatingOutlets[0]?.property?.basicInformation?.latitude || '',
        propertyLongitude: props?.offerDetails?.participatingOutlets[0]?.property?.basicInformation?.longitude || '',
        contactNumber: getContactInfo(props?.offerDetails?.participatingOutlets[0]?.property?.contactInformation),
        needToKnowInfo: props?.offerDetails?.needToKnow,
        terms: props?.offerDetails?.termsAndConditions,
      };
      setOfferDetails(updatedOfferDetails);
    }
  };

  return (
    <StyledOffersOverlayContent>
      {offerDetails?.imageUrl && (
        <div>
          <Image
            title={offerDetails?.imageTitle}
            defaultImageURL={offerDetails?.imageUrl}
            altText={offerDetails?.imageAltText}
            customClass="overlay-content__image"
            loading={'eager'}
          />
        </div>
      )}
      <div className="overlay-content__offer-section my-4 d-flex flex-column">
        {offerDetails?.offerTitle && (
          <Heading
            variation={Types.headingType.subtitle}
            titleText={offerDetails?.offerTitle}
            fontSize={isMobileViewPort ? Types.size.large : Types.size.extraLarge}
          ></Heading>
        )}
        {offerDetails?.validityInfo && (
          <Text
            copyText={offerDetails?.validityInfo}
            customClass="t-font-s"
            fontSize={size.small}
            element={tags.span}
          />
        )}
        {((offerDetails?.propertyLatitude && offerDetails?.propertyLongitude) || offerDetails?.contactNumber) && (
          <div
            className={clsx(
              'd-flex align-items-baseline overlay-content__contact-section',
              isMobileViewPort ? 'flex-column' : 'flex-row'
            )}
          >
            {offerDetails?.propertyName && offerDetails?.propertyLatitude && offerDetails?.propertyLongitude && (
              <div>
                <Icon iconClass="icon-location mr-2" ariaLabel="icon-location" />
                <span className="d-inline-flex t-font-s">
                  <Button
                    isLink={true}
                    href={`${GOOGLE_LOCATION}${offerDetails?.propertyLatitude},${offerDetails?.propertyLongitude}`}
                    aria-label={offerDetails?.propertyName}
                    target={TARGET_BLANK}
                    trackingProperties={props?.trackingProperties}
                    custom_click_track_value={`${CAMPAIGN_PAGE}|${CAMPAIGN_PAGE_OVERLAY_LOCATION_BUTTON}|${EXTERNAL_LINK}`}
                  >
                    {offerDetails?.propertyName}
                  </Button>
                </span>
              </div>
            )}
            {offerDetails?.contactNumber && (
              <div>
                <Icon iconClass="icon-phone" ariaLabel="icon-phone" />
                <span className="d-inline-flex t-font-s ml-2">
                  <Button
                    isLink={true}
                    href={`tel:${offerDetails?.contactNumber}`}
                    aria-label={offerDetails?.contactNumber}
                    target={TARGET_BLANK}
                    trackingProperties={props?.trackingProperties}
                    custom_click_track_value={`${CAMPAIGN_PAGE}|${CAMPAIGN_PAGE_OVERLAY_PHONE_BUTTON}|${EXTERNAL_LINK}`}
                  >
                    {offerDetails?.contactNumber}
                  </Button>
                </span>
              </div>
            )}
          </div>
        )}
        {offerDetails?.offerDescription && (
          <RichText
            text={offerDetails?.offerDescription}
            componentId="offer-description"
            customClass={clsx(isMobileViewPort ? 'mt-3 t-font-alt-s' : 'mt-4 t-font-alt-m')}
          />
        )}
      </div>
      {offerDetails?.needToKnowInfo && offerDetails?.needToKnowInfo?.length > 0 && (
        <div className="overlay-content__need-to-know d-flex flex-column mb-4">
          <Text
            copyText={props?.needToKnowLabel}
            customClass="t-subtitle-m mb-2"
            fontSize={size.large}
            element={tags.span}
          />
          {offerDetails?.needToKnowInfo?.map((item: string) => (
            <Text
              copyText={item}
              customClass={clsx(isMobileViewPort ? 't-font-alt-s' : 't-font-alt-m')}
              fontSize={isMobileViewPort ? size.small : size.medium}
              element={tags.paragraph}
              key={item}
            />
          ))}
        </div>
      )}
      {offerDetails?.terms && (
        <div className="overlay-content__terms-conditions d-flex flex-column align-items-start">
          <Button
            type={Types.ButtonTypeVariation.Button}
            className="overlay-content__view-terms-cta p-0 m-0 m-button-s m-button-primary"
            callback={toggleShowTermsSection}
          >
            <>
              {props?.termConditionLabel}
              <span
                className={clsx('show-more-icon pl-1', showTermsSection ? 'icon-dropdown-up' : 'icon-dropdown-down')}
              ></span>
            </>
          </Button>
          {showTermsSection && (
            <Text
              copyText={offerDetails?.terms}
              customClass={clsx(isMobileViewPort ? 't-font-alt-s' : 't-font-alt-m')}
              fontSize={isMobileViewPort ? size.small : size.medium}
              element={tags.span}
            />
          )}
        </div>
      )}
    </StyledOffersOverlayContent>
  );
};

export default OffersOverlayContent;
