/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { canUseDOM } from '../../utils/helper';
import { StyledDropdownContentDiv, StyledDropdownContainerDiv } from './DropdownModal.styles';
import clsx from 'clsx';
import { DropdownModalProps } from './DropdownModal.types';
import { HEIGHT_VALUE, PX_TO_REM } from '../../constants/ApplicationConstants';

/*
  Information!
  Molecule : DropDownModal will act as a dropdown and any html element can be passed as child
             Using mobileTakeOver flag we can convert dropdown into a modal for mobile screens
  Props    : className - class name or clsx statement can be passed
             show - Flag to show and hide the dropdown
             mobileTakeOver - Flag to enable modal in mobile
             mobileFooterEnabled - Flag to enable footer in mobile modal
             mobileHeaderEnabled - Flag to enable header in mobile modal
             role - To decide ARIA role based on usage
             labelledBy - Screen reader text value
             mobileModalHeading - Title for mobile modal screen
             clearButtonLabel - Clear button label
             applyLabel - Apply button label
             clickTrackingLoc - Analytics tracking location value
             children - Main prop that contains the content of dropdown
             handleBlur - Flag to enable blur windows event handling
             scrollDownModalCloseState - Flag to close the popup on scrolling
             dropdownModalOpenState - Flag to identify mobile dropdown open state
             setDropdownModalOpenState - To set or update dropdown open state
             dropdownModalOnCLoseFunc - Function to provide custom function for close button
             applyBtnClickHandler - Mobile modal's Apply button function
             clearClickHandler - Mobile modal's Clear button function
             isAutoScrollEnabled - auto scrolls the page to top if dropdown height is not fit for use viewport
             parentElementTopValue - top value of parent element for setting scroll position in case isAutoScrollEnabled = true
             customLastElementRef - ref for last element of the modal
             contentLabel - ARIA label for dropdown content container 
*/

export const DropDownModal: React.FC<DropdownModalProps> = ({
  className,
  childrenClassName,
  show,
  mobileTakeOver,
  mobileHeaderEnabled,
  mobileFooterEnabled,
  role,
  labelledBy,
  mobileModalHeading,
  clearButtonLabel,
  applyLabel,
  clickTrackingLoc,
  children,
  handleBlur,
  scrollDownModalCloseState,
  dropdownModalOpenState,
  setDropdownModalOpenState,
  dropdownModalOnCLoseFunc,
  applyBtnClickHandler,
  clearClickHandler,
  dropDownChildrenClassName,
  applyDefaultHeight,
  dropDownSpecificFlag = true,
  desktopDropdown = false,
  parentElementTopValue,
  isAutoScrollEnabled = false,
  shouldAutoFocusOnPopup = false,
  customLastElementRef,
  contentLabel,
}) => {
  const dropdownModalOuterRef = useRef<HTMLDivElement>(null); // Dropdown main container div reference
  const dropdownModalRef = useRef<HTMLDivElement>(null); // Dropdown content div reference
  const scrollingRef = useRef(false); // ref to handle scrolling positions
  const [calculatedTop, setCalculatedTop] = useState(0);
  useEffect(() => {
    const headerComp = document.querySelector('.m-header');
    const headerBottomValue = headerComp?.getBoundingClientRect().bottom ?? 80;
    setCalculatedTop(headerBottomValue + 2);
  }, []);
  useEffect(() => {
    isAutoScrollEnabled && checkModalInView();
    // close dropdwon on desktop if tab focus is out of dropdwon or user clicks outside the dropdwon
    if (handleBlur && dropdownModalOpenState) {
      const dropdownElem = dropdownModalOuterRef.current;
      if (dropdownElem && dropdownModalOpenState && setDropdownModalOpenState) {
        // Below, we are checking if the clicking is on any of the elements that are not contained
        // within 'dropdownElem' but are used to either open the dropdwon or to return to the dropdwon and closing dropdwon accordingly
        const clickListener = (event: Event): void => {
          if (mobileTakeOver) {
            if (!dropdownElem.contains(event.target as Element)) {
              if (setDropdownModalOpenState) {
                setDropdownModalOpenState(false);
              }
            }
          } else {
            setTimeout(() => {
              if (
                !dropdownElem.contains(event.target as Element) &&
                !dropdownElem.contains(document.activeElement) &&
                dropDownSpecificFlag
              ) {
                if (setDropdownModalOpenState) {
                  setDropdownModalOpenState(false);
                }
              }
            }, 0);
          }
        };

        // Function to close dropdwon if user click outside of component by checking if dropdown content element contains target element
        const focusoutListener = (event: FocusEvent): void => {
          if (
            event.relatedTarget &&
            !dropdownElem.contains(event.relatedTarget as Element) &&
            !dropdownElem.contains(document.activeElement) &&
            !mobileTakeOver &&
            dropdownElem.contains(event.target as Element) &&
            dropDownSpecificFlag
            //making this change to restrict the unwated close of dropdown on clicking of white spaces
          ) {
            if (setDropdownModalOpenState) {
              setDropdownModalOpenState(false);
            }
          }
        };
        //TODO:
        setTimeout(() => {
          document.addEventListener('mousedown', clickListener); // Attaching click event to document to handle click outside of dropdown
        }, 100);

        dropdownElem.addEventListener('focusout', focusoutListener); // Attaching focusout event to dropdwon to handle blur event

        // removing event listeners from document
        return (): void => {
          document.removeEventListener('mousedown', clickListener);
          dropdownElem.removeEventListener('focusout', focusoutListener);
        };
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (): void => {};
  }, [handleBlur, dropdownModalOpenState, setDropdownModalOpenState]);

  //this useEffect is to keep the control inside the modal when navigated using keyboard (ADA)
  const firstFocusableElementRef = useRef<HTMLDivElement>(null);
  let lastFocusableElementRef = useRef<HTMLButtonElement>(null);
  if (customLastElementRef) {
    lastFocusableElementRef = customLastElementRef;
  }
  useEffect(() => {
    const dropdownElem = dropdownModalOuterRef.current;
    setTimeout(() => {
      if (dropdownElem && mobileTakeOver && dropdownModalOpenState) {
        const trapFocus = (event: { key: string; shiftKey: any; preventDefault: () => void }) => {
          // Check if the modal is open and if the tab key is pressed
          if (event.key === 'Tab') {
            // If shift is held down and we're on the first focusable element, move to the last
            if (event.shiftKey) {
              if (document.activeElement === firstFocusableElementRef.current) {
                lastFocusableElementRef.current?.focus();
                event.preventDefault();
              }
            } else {
              // If tabbing normally and we reach the last element, cycle back to first
              if (document.activeElement === lastFocusableElementRef.current) {
                firstFocusableElementRef.current?.focus();
                event.preventDefault();
              }
            }
          }
        };
        dropdownElem?.addEventListener('keydown', trapFocus);
        return (): void => {
          dropdownElem?.removeEventListener('keydown', trapFocus);
        };
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return (): void => {};
    }, 1000);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (): void => {};
  }, [dropdownModalOpenState]);
  /**
   * checks if scrolling has already ended or still happening as one small scroll fires multiple scrolling event
   */
  const ensureScrollingEnds = () => {
    let latScrollTop = window?.scrollY || document.documentElement?.scrollTop;
    const checkScroll = () => {
      const currentScrollTop = window?.scrollY || document.documentElement?.scrollTop;
      if (latScrollTop === currentScrollTop && scrollingRef.current) {
        scrollingRef.current = false;
      } else {
        latScrollTop = currentScrollTop;
        setTimeout(checkScroll, 100);
      }
    };
    setTimeout(checkScroll, 100);
  };

  /**
   * checks if modal is within the user viewport, if not it scrolls the page using searchFormRef
   */
  const checkModalInView = () => {
    if (dropdownModalOpenState && parentElementTopValue && !mobileTakeOver && dropdownModalOuterRef?.current) {
      const modalRect = dropdownModalOuterRef?.current?.getBoundingClientRect();
      if (modalRect?.bottom > window?.innerHeight && parentElementTopValue) {
        scrollingRef.current = true;
        const scrollToTarget = parentElementTopValue;
        let start = window.scrollY;
        let timer = 0;
        const step = () => {
          start = start + (scrollToTarget - start) / 5; // Adjust the divisor for speed/frames
          window.scrollTo(0, start);
          if (Math.abs(start - scrollToTarget) > 1) {
            requestAnimationFrame(step); // Recursively animate to the target
          } else {
            window.scrollTo(0, scrollToTarget); // Final jump to the exact location
            ensureScrollingEnds();
          }
        };
        cancelAnimationFrame(timer);
        timer = requestAnimationFrame(step);
      }
    }
  };

  useEffect(() => {
    if (dropdownModalOpenState && dropdownModalOuterRef && shouldAutoFocusOnPopup) {
      dropdownModalOuterRef.current?.focus();
    }
    //change height dynamically for mobiles where the mobile default elements like below/aboce browser url section opens/closes
    const dropdownElem = dropdownModalRef.current;

    const getInnerHeight = () => {
      if (dropdownElem?.classList.contains('room-guest-dropdown')) {
        if (canUseDOM && dropdownModalOpenState) {
          const requiredElem = document.querySelectorAll('.m-dropdown-content');
          if (requiredElem && requiredElem.length) {
            requiredElem.forEach((node: any) => {
              if (node && node.style) {
                node.style.setProperty('height', '100px');
                node.style.setProperty('min-height', '100px');
                node.style.setProperty('display', 'inline-flex');
              }
            });
          }
          dropdownElem.style.setProperty('display', 'revert');
          dropdownElem?.style.setProperty('min-height', '400px');
          dropdownElem?.style.setProperty('height', 'fit-content');
        }
      } else if (dropdownElem?.classList.contains('special-rates-dropdown')) {
        if (canUseDOM && dropdownModalOpenState) {
          const requiredElem = document.querySelectorAll('.m-dropdown-content');
          if (requiredElem && requiredElem.length) {
            requiredElem.forEach((node: any) => {
              if (node && node.style) {
                node.style.setProperty('height', '100px');
                node.style.setProperty('min-height', '100px');
                node.style.setProperty('display', 'inline-flex');
              }
            });
          }
          dropdownElem?.style.setProperty('display', 'revert');
          dropdownElem?.style.setProperty('min-height', '494px');
          dropdownElem?.style.setProperty('height', 'fit-content');
        }
      } else if (dropdownElem?.classList.contains('search-field-dropdown')) {
        if (canUseDOM && dropdownModalOpenState) {
          const requiredElem = document.querySelectorAll('.m-dropdown-content');
          if (requiredElem && requiredElem.length) {
            requiredElem.forEach((node: any) => {
              if (node && node.style) {
                node.style.setProperty('height', '100px');
                node.style.setProperty('min-height', '100px');
                node.style.setProperty('display', 'inline-flex');
              }
            });
          }
          dropdownElem?.style.setProperty('display', 'revert');
          dropdownElem?.style.setProperty('min-height', '627px');
          dropdownElem?.style.setProperty('height', 'fit-content');
        }
      } else if (dropdownElem?.classList.contains('shop-datepicker-dropdown')) {
        if (canUseDOM && dropdownModalOpenState) {
          const requiredElem = document.querySelectorAll('.m-dropdown-content');
          if (requiredElem && requiredElem.length) {
            requiredElem.forEach((node: any) => {
              if (node && node.style) {
                node.style.setProperty('height', '100px');
                node.style.setProperty('min-height', '100px');
                node.style.setProperty('display', 'inline-flex');
              }
            });
          }
          dropdownElem?.style.setProperty('display', 'revert');
          dropdownElem?.style.setProperty('min-height', '627px');
          dropdownElem?.style.setProperty('height', 'fit-content');
        }
      } else if (dropdownElem?.classList.contains('search-form-dropdown')) {
        if (canUseDOM && dropdownModalOpenState) {
          const requiredElem = document.querySelectorAll('.m-dropdown-content');
          if (requiredElem && requiredElem.length) {
            requiredElem.forEach((node: any) => {
              if (node && node.style) {
                // node.style.setProperty('min-height', '544px');
                node.style.setProperty('height', '544px');
                node?.style.setProperty('display', 'unset');
              }
            });
          }
          dropdownElem?.style.setProperty('display', 'unset');
          // dropdownElem?.style.setProperty('min-height', '544px');
          dropdownElem?.style.setProperty('height', 'fit-content');
        }
      } else {
        dropdownElem?.style.setProperty('height', window.innerHeight * PX_TO_REM - HEIGHT_VALUE + 'rem');
      }
      if (!dropdownModalOpenState) {
        if (canUseDOM) {
          const requiredElem = document.querySelectorAll('.m-dropdown-content');
          if (requiredElem && requiredElem.length) {
            requiredElem.forEach((node: any) => {
              if (node && node.style) {
                node.style.setProperty('min-height', '544px');
                node.style.setProperty('height', '544px');
                node.style.setProperty('display', 'unset');
              }
            });
          }
        }
      }
    };
    !applyDefaultHeight && mobileTakeOver && getInnerHeight();

    /**
     * closes the popup if it is already open in scrolling state
     */
    const handleScroll = () => {
      /**
       * condition to check the popup is opened or not in desktop view
       */
      if (
        dropdownModalOpenState === true &&
        scrollDownModalCloseState === true &&
        !mobileTakeOver &&
        !scrollingRef.current
      ) {
        if (setDropdownModalOpenState) {
          setDropdownModalOpenState(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dropdownModalOpenState]);

  // Funtion to handle click on dropdown body
  // TODO : remove eslint disable with appropriate event
  const handleClick = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      if (canUseDOM && !applyDefaultHeight) {
        const requiredElem = document.querySelectorAll('.m-dropdown-content');
        if (requiredElem && requiredElem.length) {
          requiredElem.forEach((node: any) => {
            if (node && node.style) {
              setTimeout(() => {
                node.style.setProperty('display', 'unset');
                node.style.setProperty('min-height', '544px');
                node.style.setProperty('height', 'fit-content');
              }, 0);
            }
          });
        }
      }
      if (dropdownModalOnCLoseFunc) {
        dropdownModalOnCLoseFunc(false);
      }
      if (setDropdownModalOpenState) {
        setDropdownModalOpenState(false);
      }
    }
  };

  // Function to handle clear button click if mobileTakeOver flag is enabled and handler function is passed in props
  // TODO : remove eslint disable with appropriate event
  const clearButtonHandler = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      if (clearClickHandler) {
        clearClickHandler(event);
      }
    }
  };

  // Function to handle apply button click if mobileTakeOver flag is enabled and handler function is passed in props
  // TODO : remove eslint disable with appropriate event
  const onHandleApplyClick = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      if (canUseDOM && !applyDefaultHeight) {
        const requiredElem = document.querySelectorAll('.m-dropdown-content');
        if (requiredElem && requiredElem.length) {
          requiredElem.forEach((node: any) => {
            if (node && node.style) {
              setTimeout(() => {
                node.style.setProperty('display', 'unset');
                node.style.setProperty('min-height', '544px');
                node.style.setProperty('height', 'fit-content');
              }, 0);
            }
          });
        }
      }
      if (dropdownModalOnCLoseFunc) {
        dropdownModalOnCLoseFunc(false);
      }
      if (setDropdownModalOpenState) {
        setDropdownModalOpenState(false);
      }
    }
    if (applyBtnClickHandler) {
      applyBtnClickHandler(event);
    }
    if (dropdownModalOnCLoseFunc) {
      dropdownModalOnCLoseFunc(false);
    }
    if (setDropdownModalOpenState) {
      setDropdownModalOpenState(false);
    }
  };
  return (
    /*
      Dropdown parent div you can provide classname prop with clsx statement to add your custom class
      You can also enable mobile modal stylings with mobileTakeOver flag
    */
    <StyledDropdownContainerDiv
      data-component-name="m-ui-library-DropDownModal"
      data-testid="ui-library-DropDownModal"
      ref={dropdownModalOuterRef}
      className={clsx('dropdown-container-div', className)}
      show={show}
      calculatedTop={calculatedTop}
      tabIndex={-1}
    >
      <div className={clsx(mobileTakeOver ? 'dropdown-to-modal-background' : '')}>
        <div className={clsx('m-dropdown-container', mobileTakeOver ? 'dropdown-to-modal' : '')}>
          {/*
            Dropdown container div you can provide role based on your ADA requirement
            data-testid field is added to target this element for Jest unit testing
          */}
          <StyledDropdownContentDiv
            ref={dropdownModalRef}
            id={labelledBy}
            className={clsx('m-dropdown-content', childrenClassName)}
            role={role}
            aria-modal={true}
            aria-labelledby={labelledBy}
            aria-label={contentLabel}
            data-testid="dropdown-modal-content"
          >
            {/*
            Conditionally rendering modal header with title and close button
            If mobileTakeOver flag is enabled and heading text value is provided
          */}
            {mobileTakeOver && mobileHeaderEnabled && (
              <div className="m-dropdown-header">
                {mobileModalHeading && <h4 className={clsx('header-heading')}>{mobileModalHeading}</h4>}
                <div
                  className={clsx('custom_click_track')}
                  tabIndex={0}
                  role="button"
                  onClick={(e): void => {
                    handleClick(e);
                  }}
                  onKeyDown={(e): void => {
                    handleClick(e);
                  }}
                  {...{ custom_click_track_value: `${clickTrackingLoc}| Close Button |internal` }}
                  aria-label="Close pop up"
                  ref={firstFocusableElementRef}
                >
                  <span className="icon-clear icon-l "></span>
                </div>
              </div>
            )}
            {/*
            Dropdown content div it will read children prop
            we can provide any valid react JSX element here
          */}
            <div className={clsx('m-dropdown-children', dropDownChildrenClassName)}>{children}</div>
            {/*
            Conditionally rendering modal footer with apply and clear button
            If mobileTakeOver flag is enabled and apply and clear functions are provided
          */}
            {(mobileTakeOver || desktopDropdown) && mobileFooterEnabled && (
              <div className="m-dropdown-action-bar">
                <button
                  {...(!clearButtonLabel ? { ariaHidden: true } : {})}
                  {...(!clearButtonLabel ? { tabIndex: -1 } : {})}
                  onClick={(e): void => clearButtonHandler(e)}
                  onKeyDown={(e): void => clearButtonHandler(e)}
                  className={clsx('clear', 'm-dropdown-action-clear', 'custom_click_track', 'm-link-action')}
                  {...{
                    custom_click_track_value: `${clickTrackingLoc}| Footer Button: ${
                      clearButtonLabel || 'Clear'
                    } Button |internal`,
                  }}
                >
                  {clearButtonLabel}
                </button>
                <button
                  ref={lastFocusableElementRef}
                  {...(!applyLabel ? { ariaHidden: true } : {})}
                  {...(!applyLabel ? { tabIndex: -1 } : {})}
                  type="button"
                  onClick={(e): void => onHandleApplyClick(e)}
                  className={clsx('m-button-s', 'apply-button', 'm-button-secondary', 'custom_click_track')}
                  {...{
                    custom_click_track_value: `${clickTrackingLoc}| Footer Button: ${
                      applyLabel || 'Apply'
                    } Button |internal`,
                  }}
                >
                  {applyLabel}
                </button>
              </div>
            )}
          </StyledDropdownContentDiv>
        </div>
      </div>
    </StyledDropdownContainerDiv>
  );
};
