import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledStepSection = styled.div`
  flex-basis: 100%;

  .step-section {
    &__icon {
      font-size: ${toRem(32)};
      margin-bottom: ${toRem(12)};
    }
  }
`;
