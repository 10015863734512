/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';

import { LanguageSelectorProps } from './LanguageSelector.types';

import { LinksCollection, Button, Modal } from '@marriott/mi-ui-library';
import { StyledLanguageSelector } from './LanguageSelector.styles';
import { getClickTrackValue } from '../../../utils/TrackingPropertyUtils';
import { DEFAULT_TRACKING_VALUES } from '../../../utils/constants';

// Use named rather than default exports.
export const LanguageSelector: FC<React.PropsWithChildren<LanguageSelectorProps>> = ({
  restProps,
  renderUtilityLink,
  buttonClassName,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { resourceProperties } = restProps;
  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  type JsonArray = any[];
  type JsonObject = { [key: string]: any };
  const isObject = (o: any): o is object => typeof o === 'object' && o !== null;

  const sortJSON = (o: string): any => {
    if (Array.isArray(o)) {
      return o.sort().map((item: any) => sortJSON(item)) as JsonArray;
    } else if (isObject(o)) {
      return Object.keys(o)
        .sort()
        .reduce((acc: JsonObject, key: string) => {
          acc[key] = sortJSON(o[key]);
          return acc;
        }, {}) as JsonObject;
    }
    return o;
  };

  const regionData = Object.values(sortJSON(restProps?.regions || {}));

  return (
    <>
      <Button
        className={`utility-language-selector-button ${buttonClassName}`}
        callback={toggleHandler}
        custom_click_track_value={getClickTrackValue({
          trackingProperties: {
            trackingContentPosition: restProps.trackingContentPosition,
          },
          url: restProps['cq:panelUrl'],
          fallbacks: {
            description: restProps['cq:panelTitle'],
            position: DEFAULT_TRACKING_VALUES.GLOBAL_NAV,
          },
        })}
      >
        {restProps?.resourceProperties && renderUtilityLink(restProps.resourceProperties, 'globe')}
      </Button>
      {!resourceProperties ? null : (
        <StyledLanguageSelector>
          <Modal
            className="modal-popup-container"
            secondaryClassName="modal-main-container"
            show={isOpen}
            popupOpenState={isOpen}
            handleBlur={true}
            setPopupOpenState={setIsOpen}
          >
            <Modal.Header
              className="modal-header px-4 h-2"
              labelText={restProps?.resourceProperties?.title || ''}
              popupHeaderOnCLoseFunc={setIsOpen}
            ></Modal.Header>
            <div className="row">
              {regionData?.map((coloumn: any, index) => (
                <div key={index} className="col-12 col-sm-4 col-lg-4 custom-header-font">
                  {Object.keys(coloumn)?.map(title => (
                    <LinksCollection
                      {...coloumn}
                      linkListData={coloumn && coloumn[title]}
                      heading={title.replace('-', ' ').toUpperCase()}
                      headingClassName="px-3 pt-4"
                      listCollectionClassname="custom-link px-3"
                      listItemClassName="py-3"
                      hasIconArrow={false}
                    />
                  ))}
                </div>
              ))}
            </div>
          </Modal>
        </StyledLanguageSelector>
      )}
    </>
  );
};
