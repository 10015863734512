import { toRem, baseVariables } from '@marriott/mi-ui-library';
import styled from 'styled-components';

interface CardCarouselContainerProps {
  cardType: string;
}

export const StyledCardCarousel = styled.div<CardCarouselContainerProps>`
  padding: ${props => (props.cardType === 'cardlayered' ? `${toRem(40)} 0 ${toRem(20)}` : `${toRem(20)} 0 0 0`)};
  background: ${baseVariables.color.merch30};

  @media ${baseVariables.mediaQuery.lg} {
    padding: ${props =>
      props.cardType === 'cardlayered'
        ? `${toRem(86)} 0 ${toRem(40)}`
        : props.cardType === 'editorialfeature'
        ? `0 0 ${toRem(40)}`
        : `${toRem(40)} 0 0 0`};
  }
  @media ${baseVariables.mediaQuery.xl} {
    padding: ${props =>
      props.cardType === 'cardlayered'
        ? `${toRem(80)} 0 ${toRem(40)}`
        : props.cardType === 'editorialfeature'
        ? `0 0 ${toRem(28)}`
        : `${toRem(40)} 0 0 0`};
  }
  .editorial-container {
    background: ${baseVariables.color.merch30};
  }

  .glide .controls_wrapper {
    position: relative;
    bottom: 0;
    padding: 0;
    text-align: -webkit-center;
  }

  .glide {
    position: relative;
  }

  .carousal_header .t-overline-normal {
    color: ${baseVariables.color.merch50};
  }

  .t-title-s {
    color: ${baseVariables.color.merch50};
  }

  .home-page-container {
    position: relative;
  }
  .carousal_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .carousal_header--cta {
      display: flex;
      align-items: flex-end;
    }
  }
  .glide {
    &__track {
      overflow: hidden;
      padding-top: ${toRem(24)};
      .glide__slides_authoring {
        width: 100% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        margin: ${props => (props?.cardType === 'cardvertical' ? `${toRem(-8)} 0 0 ${toRem(-8)}` : 0)};
        .aem-container {
          padding: 0;
        }
      }
    }
    .center-align {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-layered-slides {
    column-gap: ${toRem(16)};
  }

  .glide--swipeable {
    cursor: default;
  }

  .glide__bullet {
    color: ${baseVariables.color.merch50};
  }

  .editorial-carousal {
    left: unset;
    position: relative;
  }

  .carouselControlType1 div > button {
    background-color: ${baseVariables.color.merch50};
    &:focus-visible {
      outline: auto;
    }
  }
  .carouselControlType2 div > button:focus-visible {
    outline: auto;
  }

  .carouselControlType1 button[class*='-active']::after {
    background-color: ${baseVariables.color.merch50};
    opacity: 1;
  }
  .card-vertical {
    background-color: ${baseVariables.color.merch50};
  }

  @media ${baseVariables.mediaQuery.allTablets && baseVariables.mediaQuery.largeDesktop} {
    .home-page-container {
      position: relative;
    }

    .glide .controls_wrapper {
      margin-left: ${toRem(368)};
      text-align: none;
      bottom: ${toRem(32)};
    }

    .editorial-carousal {
      padding-left: ${toRem(376)};
    }
  }
`;
