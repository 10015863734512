import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledMerchandisingSmallBanner = styled.div`
  /* clamp (minVal : fallback if scaling value falls below minValue, scaling value, maxValue : fallback if scaling value falls below minValue) */
  /* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))) */
  padding-top: clamp(${toRem(62)}, calc(${toRem(62)} + 24 * ((100vw - ${toRem(365)}) / 211)), ${toRem(86)});
  @media ${baseVariables.mediaQuery.md} {
    padding-top: 0;
  }
  margin: 0 auto;
  .card-container {
    position: relative;
    box-sizing: border-box;
    border: ${baseVariables.border['borderWidth02']} solid ${baseVariables.color['neutral20']};
    border-radius: ${baseVariables.border['borderRadiusDefault']};
    box-shadow: ${baseVariables.shadows['shadowSubtle01']};
    p {
      margin: 0;
    }
    .image-container {
      img {
        width: ${toRem(180)};
      }
    }
    .general-top {
      margin-top: -${toRem(56)};
    }
    .merch-img-loader {
      width: clamp(${toRem(170)}, calc(${toRem(170)} + 130 * ((100vw - ${toRem(365)}) / 211)), ${toRem(300)});
      height: ${toRem(98)};
      @media ${baseVariables.mediaQuery.md} {
        width: ${toRem(122)};
        height: ${toRem(68)};
      }
    }

    .cobrand-math-content-section {
      max-width: 100%;
      margin-left: ${toRem(0)};
      padding-left: 0;
      @media ${baseVariables.mediaQuery.md} {
        padding-left: ${toRem(72)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(240)};
        margin-left: ${toRem(0)};
        padding-left: 0;
      }
      .total-amount {
        color: ${baseVariables.color.merch20};
      }
      .disclaimer-and-cta-section {
        flex-basis: auto;
      }
    }
    .content-section {
      .flexible-card {
        @media ${baseVariables.mediaQuery.md} {
          flex: 1;
        }
      }
    }
    .evergreen-content-section {
      .flexible-card {
        @media ${baseVariables.mediaQuery.md} {
          flex: 1;
          padding-left: ${toRem(48)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          padding-right: ${toRem(192)};
        }
      }
    }
    .flexible-card-content-container {
      display: contents;
    }
    .disclaimer-text {
      @media ${baseVariables.mediaQuery.md} {
        margin-left: ${toRem(80)};
      }
    }
  }
  .card-container-loader {
    width: 80%;
  }
`;
