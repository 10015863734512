import { FC } from 'react';
import clsx from 'clsx';
import { StyledPaginationContainer } from './PaginationPanel.styles';
import { PaginationPanelProps } from './index';
import { useTranslation } from 'react-i18next';
import { usePagination, DOTS } from './usePagination';
import { TRACKING_CONSTANT } from '../../modules/utils/constants';

export const PaginationPanel: FC<PaginationPanelProps> = ({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
  className,
  classList,
  labels = { previous: 'Previous', next: 'Next' },
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });
  const { t } = useTranslation();

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = (): void => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange && paginationRange[paginationRange.length - 1];
  const { SEARCH_RESULTS_PAGINATION, PREVIOUS_BUTTON, NEXT_BUTTON, PAGE_BUTTON, INTERNAL_LINK } = TRACKING_CONSTANT;

  return (
    <StyledPaginationContainer
      data-component-name="m-rnb-PaginationPanel"
      data-testid="rnb-PaginationPanel"
      className={clsx('rnb-pagination-container mt-5', className ?? className, classList?.container)}
    >
      <ul className={`rnb-pagination ${classList?.listParent}`}>
        <li
          className={clsx(
            'rnb-pagination-arrow',
            'rnb-pagination-prev',
            't-font-s',
            currentPage === 1 ? 'disabled' : '',
            'custom_click_track'
          )}
          onClick={onPrevious}
          tabIndex={currentPage === 1 ? -1 : 0}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          aria-label={t('PrevPage')}
          data-custom_click_track_value={`${SEARCH_RESULTS_PAGINATION}|${PREVIOUS_BUTTON}|${INTERNAL_LINK}`}
          data-testid="rnb-pagination-prev"
        >
          <div className="page-arrow-container">
            <span className="icon-arrow-left"></span>
            <span className="pagination-prev-link t-font-xs mr-4 ml-2">{labels.previous}</span>
          </div>
        </li>

        {paginationRange?.map((pageNumber, ind: number) =>
          pageNumber.toString() === DOTS ? (
            <li
              key={`${ind}-list`}
              className="rnb-pagination-dots custom_click_track"
              data-custom_click_track_value={`${SEARCH_RESULTS_PAGINATION}|${PAGE_BUTTON} ${pageNumber}|${INTERNAL_LINK}`}
            >
              &#8230;
            </li>
          ) : (
            <li
              className={clsx(
                'rnb-pagination-item pl-2 pr-2 pb-1 pt-1',
                classList?.listItem,
                pageNumber === currentPage ? 'active t-label-s' : 't-font-s',
                'custom_click_track'
              )}
              key={`${ind}-list`}
              tabIndex={0}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              aria-label={`${pageNumber}`}
              onClick={(): void => onPageChange(pageNumber)}
              data-custom_click_track_value={`${SEARCH_RESULTS_PAGINATION}|${PAGE_BUTTON} ${pageNumber}|${INTERNAL_LINK}`}
              data-testid={`rnb-pagination-${pageNumber}`}
            >
              {pageNumber}
            </li>
          )
        )}
        <li
          className={clsx(
            'rnb-pagination-arrow',
            't-font-s',
            currentPage === lastPage ? 'disabled' : '',
            'custom_click_track'
          )}
          onClick={onNext}
          tabIndex={currentPage === lastPage ? -1 : 0}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          aria-label={t('NextPage')}
          data-custom_click_track_value={`${SEARCH_RESULTS_PAGINATION}|${NEXT_BUTTON}|${INTERNAL_LINK}`}
          data-testid="rnb-pagination-next"
        >
          <div className="page-arrow-container">
            <span className="pagination-next-link t-font-xs ml-4 mr-2">{labels.next}</span>
            <span className="icon-arrow-right"></span>
          </div>
        </li>
      </ul>
    </StyledPaginationContainer>
  );
};
