import { createContext, useContext, useEffect } from 'react';
import { useDatalayer } from '../../hooks/use-datalayer';
import { getDatalayerObject } from '../dataLayer/datalayerUtil';
import { GlobalPageProps, SessionData } from './types';
import { useUserSessionStore, UserSessionState, UserSessionActions } from './user-session-store';
import { logger } from '../logger';
import { getEnvProp, populatePIdataLayer } from '../helpers';
import { getMvpOffersData } from '../mvpOffers';

type UserSessionProvider = {
  pageProps: GlobalPageProps; // @fixme: this is way too much stuff to pass around
  includePiData: boolean; // flag to check if PI data needs to be included in datalayer or not
  /* Children to render */
  children: React.ReactNode;
};

type UserSessionContextType = {
  session: SessionData;
  updateSession: (sessionData: SessionData) => void;
  syncedWithServer: boolean;
};

const UserSessionContext = createContext<UserSessionContextType>({
  session: {},
  updateSession: () => {},
  syncedWithServer: false,
});

UserSessionContext.displayName = 'UserSessionContext';

/**
 * Provider to manage user session data and sync with server on initial load and updates.
 */
export function UserSessionProvider({
  pageProps,
  includePiData,
  children,
}: React.PropsWithChildren<UserSessionProvider>) {
  const { log } = logger({})('UserSessionProvider');
  const session = useUserSessionStore((state: UserSessionState & UserSessionActions) => state.session);
  const updateSessionStore = useUserSessionStore((state: UserSessionState & UserSessionActions) => state.updateSession);
  const syncedWithServer = useUserSessionStore(
    (state: UserSessionState & UserSessionActions) => state.syncedWithServer
  );

  const { pushState } = useDatalayer();

  const updateSession = (sessionData: SessionData) => {
    // push to dataLayer with latest session changes
    pushState({
      event: 'dataLayerUpdated',
      data: getDatalayerObject(sessionData, pageProps, includePiData),
    });
    updateSessionStore(sessionData);
  };

  useEffect(() => {
    const isEnableWebSDK =
      getEnvProp('NEXT_PUBLIC_ENABLE_WEBSDK') === 'true' && String(pageProps?.model?.enableWebSDK) === 'true';
    if (includePiData) {
      populatePIdataLayer(isEnableWebSDK);
    }
  }, []);

  useEffect(() => {
    const loadMvpOffers = async () => {
      const offersData = await getMvpOffersData(pageProps);
      window.mvpOffers = offersData;
      log.info('MVP Offers loaded');
    };
    const isEnableWebSDK =
      getEnvProp('NEXT_PUBLIC_ENABLE_WEBSDK') === 'true' && String(pageProps?.model?.enableWebSDK) === 'true';
    if (isEnableWebSDK && syncedWithServer) {
      log.info('UserSessionProvider init!');
      loadMvpOffers().catch(error => {
        log.error('Error loading MVP offers', error);
      });
      pushState({
        event: 'dataLayerLoaded',
        data: getDatalayerObject(session, pageProps, includePiData),
      });
    }
  }, [syncedWithServer]);

  return (
    <UserSessionContext.Provider value={{ session, updateSession, syncedWithServer }}>
      {children}
    </UserSessionContext.Provider>
  );
}

export function useSession() {
  const context = useContext(UserSessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a UserSessionProvider');
  }
  return context;
}
