/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useRef } from 'react';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.

import {
  PANORAMIC_ASPECT_RATIO,
  OFFER_IMAGE_CACHE_DOMAIN,
  SINGLE_PROPERTY_OFFER,
} from '../../constants/OffersHeroConstants';
import config from '../../lib/config';
import { getFallbackImg } from '../../utils/OfferUtils';
import { Button, Text, Types, Heading, Image } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { OffersHeroSinglePropertyProps } from './OffersHero.types';
import { StyledOffersHeroSingleProperty } from './OffersHero.styles';
import { INTERNAL_CTA_TYPE } from '../../constants/CommonConstants';

// Use named rather than default exports.
export const OffersHeroSingleProperty: FC<OffersHeroSinglePropertyProps> = ({
  offersHeroProps,
  variation,
  modelObj,
}) => {
  const OfferObj = offersHeroProps?.responseObject?.edges[0].node;
  const imageArr = OfferObj?.photos?.images ?? [];
  let desktopImageSrc = '';
  const captionArr = OfferObj?.photos?.captions ?? [];
  const imageAltText = captionArr.find((caption: { length: string }) => caption.length === 'medium')?.caption;
  const fallBackImg = getFallbackImg(config.FALLBACK_IMG_URI, PANORAMIC_ASPECT_RATIO);
  const OffersHeroSPORef = useRef(null);
  const ASPECT_RATIO_UXL_FIELD_MAPPING = {
    PANO: 'panoramicHorizontal',
  };
  const objKey = PANORAMIC_ASPECT_RATIO as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
  const DACImagePath = OfferObj?.media?.primaryImage?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[objKey]];

  const bannerTextClassName = clsx('t-color-white t-font-swiss721');
  if (DACImagePath) {
    desktopImageSrc = OFFER_IMAGE_CACHE_DOMAIN + DACImagePath;
  } else if (imageArr.length) {
    for (const image of imageArr) {
      if (image.aspectRatio.toUpperCase() === PANORAMIC_ASPECT_RATIO) {
        desktopImageSrc = OFFER_IMAGE_CACHE_DOMAIN + (image.url ?? fallBackImg);
        break;
      } else {
        desktopImageSrc = OFFER_IMAGE_CACHE_DOMAIN + fallBackImg;
      }
    }
  } else {
    desktopImageSrc = OFFER_IMAGE_CACHE_DOMAIN + fallBackImg;
  }

  const processOutletName = (outletName: string): string => {
    // Convert special characters to hyphen
    let processedString = outletName.replace(/[^\w\d]+/g, '-');
    //Replace space with hyphen
    processedString = processedString.replace(/\s/g, '-');
    // Replace multiple hyphens with a single hyphen
    processedString = processedString.replace(/-+/g, '-');
    // Remove leading hyphens
    processedString = processedString.replace(/^-+/g, '');
    // Remove trailing hyphens
    processedString = processedString.replace(/-+$/g, '');
    return processedString.toLowerCase();
  };

  const marshaCode = offersHeroProps?.marsha ?? '';
  const hotelName = OfferObj?.seoNickname ?? '';
  const productId = OfferObj?.associatedOutlets ? OfferObj?.associatedOutlets[0]?.outlets[0]?.id : '';
  const rawOutletName = OfferObj?.associatedOutlets ? OfferObj?.associatedOutlets[0]?.outlets[0]?.name : '';
  const outletName = rawOutletName ? processOutletName(rawOutletName) : '';

  const generateOutletUrl = (url: string): string => {
    const replacedUrl = url.replace(/\${(.*?)}/g, (match, group) => {
      if (group === 'marsha') {
        return marshaCode;
      }
      if (group === 'hotelName') {
        return hotelName;
      }
      if (group === 'productID') {
        return productId;
      }
      if (group === 'outletName') {
        return outletName;
      }
      return match;
    });
    return replacedUrl;
  };

  const getCTADetails = (): any => {
    const isOutletNavigation = modelObj?.ctaType === 'outletNavigation';
    const isSingleProperty = OfferObj?.numProperties === 1;
    const isSingleOutlet = OfferObj?.numberOfOutlets === 1;
    const hasMultipleOutlets = OfferObj?.numberOfOutlets > 1;
    const hasMultipleProperties = OfferObj?.numProperties > 1;

    if (isOutletNavigation) {
      if (isSingleProperty) {
        if (isSingleOutlet) {
          // Single Property, Single Outlet
          return {
            isAnchored: false,
            CTALabel: modelObj?.ctatext ?? '',
            CTAUrl: generateOutletUrl(modelObj?.outletCta ?? '#'),
          };
        } else if (hasMultipleOutlets) {
          // Single Property, Multiple Outlets
          return {
            isAnchored: true,
            CTALabel: modelObj?.ctatext ?? '',
            CTAUrl: '',
          };
        } else {
          return {
            isAnchored: false,
            CTALabel: OfferObj?.callToActionLabel ?? modelObj?.ctatext,
            CTAUrl: OfferObj?.callToActionLink ?? '#',
          };
        }
      } else if (hasMultipleProperties) {
        if (hasMultipleOutlets) {
          // Multiple Properties, Multiple Outlets
          return {
            isAnchored: true,
            CTALabel: modelObj?.ctatext ?? '',
            CTAUrl: '',
          };
        } else if (isSingleOutlet) {
          // multi Property, Single Outlet
          return {
            isAnchored: false,
            CTALabel: modelObj?.ctatext ?? '',
            CTAUrl: generateOutletUrl(modelObj?.outletCta ?? '#'),
          };
        }
      } else {
        // Not a valid scenario. Every outlet offer will have single property
        return {
          isAnchored: false,
          CTALabel: OfferObj?.callToActionLabel ?? modelObj?.ctatext,
          CTAUrl: OfferObj?.callToActionLink ?? '#',
        };
      }
    } else {
      // APO offers for SPO and MPO
      return {
        isAnchored: false,
        CTALabel: OfferObj?.callToActionLabel ?? modelObj?.ctatext,
        CTAUrl: OfferObj?.callToActionLink ?? '#',
      };
    }
  };

  const CtaAnchorID = modelObj?.outletComponentAnchor ?? '';
  const CtaURL = getCTADetails().CTAUrl;
  const CtaLabel = getCTADetails().CTALabel;
  const isCtaAnchored = getCTADetails().isAnchored;

  const handleClickNavigation = (event: any): any => {
    event.preventDefault();
    if (isCtaAnchored) {
      const outletTitleId = document.getElementById(CtaAnchorID);
      // document.getElementById(CtaAnchorID)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      window.scrollTo({ top: outletTitleId ? outletTitleId?.offsetTop - 225 : 800, behavior: 'smooth' });
    } else {
      window.open(CtaURL, '_self');
      // window.location.href = CtaURL;
    }
  };
  return (
    <StyledOffersHeroSingleProperty className="brand-css">
      <div className="hero-img-wrapper">
        <div className="hero-img-container">
          <Image
            title={imageAltText ?? `${OfferObj?.title} img`}
            customClass="hero-img"
            defaultImageURL={desktopImageSrc}
            altText={imageAltText ?? `${OfferObj?.title} img`}
          ></Image>
        </div>
        <div className={`left-section-wrapper  ${modelObj?.appliedCssClassNames ?? ''} container color-scheme1`}>
          <div className={`hero-left-section b-cs1`}>
            <Heading titleText={OfferObj?.title} variation={Types.headingType.title} fontSize={Types.size.medium} />
            <div className="m-details-page-teaser">
              <Text
                copyText={OfferObj?.descriptionTeaser}
                fontSize={Types.size.medium}
                customClass={bannerTextClassName}
                element={Types.tags.paragraph}
              />
            </div>
            {variation !== SINGLE_PROPERTY_OFFER ? (
              <div className="m-primary-btn-wrapper">
                <Button
                  target={'_self'}
                  href={isCtaAnchored ? '#' : CtaURL}
                  className={clsx('m-button-external hero-img-button')}
                  buttonCopy={CtaLabel}
                  custom_click_track_value={`${modelObj?.id}|${
                    OfferObj?.callToActionLabel ?? modelObj?.shopNow
                  }|${INTERNAL_CTA_TYPE}`}
                  isLink={true}
                  callback={event => handleClickNavigation(event)}
                  trackingProperties={modelObj?.trackingProperties}
                  ref={OffersHeroSPORef}
                ></Button>
              </div>
            ) : null}
          </div>
        </div>
        <div className="hero-img-gradient"></div>
      </div>
    </StyledOffersHeroSingleProperty>
  );
};
