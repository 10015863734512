import { StateCreator } from 'zustand';
import { createAppStore } from '../zustand';
import { Ref, ReactElement } from 'react';
import { Model } from '@adobe/aem-spa-page-model-manager';
import { EXPERIENCE_FRAGMENT_TYPE } from './constants';

export interface RegisteredComponent {
  componentId: string;
  stickyOnScrollUp: boolean;
  stickyOnScrollDown: boolean;
  componentRef: Ref<ReactElement>; // Ref to outmost div of component so height off current is accurate
}
export interface ScrollState {
  navComponentOrder: string[];
  registeredComponents: (RegisteredComponent & { itemsOrderIndex: number })[];
  setNavComponentOrder: (data: Model) => void;
  setRegisteredComponent: (component: RegisteredComponent) => void;
}
// TODO: change these to booleans
interface ScrollSettings {
  isNavcomponent?: 'true' | 'false';
  scrollDown?: 'true' | 'false';
  scrollUp?: 'true' | 'false';
}
export interface ScrollStoreModel extends Model {
  componentId: string;
  scrollSetting?: ScrollSettings;
  ':children'?: {
    [key: string]: ScrollStoreModel;
  };
  ':items'?: {
    [key: string]: ScrollStoreModel;
  };
}

const initialState = {
  navComponentOrder: [],
  registeredComponents: [],
};

/**
 * returns an array of component IDs in their respective order,
 * including any components nested within experience fragments */
const getNavComponentOrder = (data: ScrollStoreModel): string[] => {
  return (
    data?.[':itemsOrder']?.reduce((order: string[], key: string) => {
      const currentComponent = data?.[':items']?.[key] as ScrollStoreModel;
      const isExperienceFragment = currentComponent?.[':type'] === EXPERIENCE_FRAGMENT_TYPE;
      // don't do anything if this is not a nav component and not an experience fragment
      if (!isExperienceFragment && currentComponent?.scrollSetting?.isNavcomponent !== 'true') {
        return order;
      }
      order.push(
        // if we have an experience fragment, spread its respective component IDs here inline
        ...(isExperienceFragment
          ? getNavComponentOrder(
              currentComponent?.[':items']?.['root']?.[':items']?.['responsivegrid'] as ScrollStoreModel
            )
          : [currentComponent['componentId' as keyof Model] as string])
      );
      return order;
    }, []) ?? []
  );
};

export const clientScrollStore: StateCreator<ScrollState> = (set, get) => {
  return {
    ...initialState,
    setNavComponentOrder: (data: Model) => {
      if (!data) {
        return;
      }
      set({ navComponentOrder: getNavComponentOrder(data as ScrollStoreModel) });
    },
    setRegisteredComponent: (component: RegisteredComponent) => {
      const { registeredComponents, navComponentOrder } = get();

      // if the store hasn't been instantiated do nothing.
      if (!navComponentOrder.length) return;

      // Check if the component is already registered, if so, do nothing
      if (registeredComponents.some(({ componentId: registeredId }) => registeredId === component.componentId)) return;

      // Find the index of the component in the navComponentOrder
      const itemsOrderIndex = navComponentOrder.findIndex(id => id === component.componentId);

      // Find the index where the new component should be inserted to maintain sorting
      const insertIndex = registeredComponents.findIndex(c => c.itemsOrderIndex > itemsOrderIndex);
      const registeredComponentsCopy = [...registeredComponents];

      if (insertIndex === -1) {
        // new component has the highest itemsOrderIndex, add it at the end
        registeredComponentsCopy.push({ ...component, itemsOrderIndex });
      } else {
        // Insert new component at the correct position to maintain sorting
        registeredComponentsCopy.splice(insertIndex, 0, { ...component, itemsOrderIndex });
      }

      // Update the state with the new registeredComponents array
      set({ registeredComponents: registeredComponentsCopy });
    },
  };
};

export const useScrollStore = createAppStore(clientScrollStore, { usePersistentStore: false });
